import {ChangeEvent} from 'react';
import {SwitchForm} from 'components/forms';

type IProps = {
  unitIsOn: boolean;
  onToggle: (value: boolean) => void;
};

function UnitToggle({unitIsOn, onToggle}: IProps) {
  const toggleHandler = () => {
    onToggle(!unitIsOn);
  };

  const unitType = unitIsOn ? 'Celsius' : 'Fahrenheit';

  return (
    <SwitchForm
      name="temp-unit-toggle"
      id="temp-unit-toggle"
      label={unitType}
      labelPosition="left"
      checked={unitIsOn}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        toggleHandler();
      }}
    />
  );
}

export default UnitToggle;
