import styled from 'styled-components';
import {ReactElement} from 'react';

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  > span {
    font-size: 12px;
    color: #b6b4c0;
    cursor: pointer;

    width: max-content;
  }
`;

type IProps = {
  children?: ReactElement | ReactElement[];
};

function LoginScreenInputContainer({children}: IProps) {
  return <Container>{children}</Container>;
}

export default LoginScreenInputContainer;
