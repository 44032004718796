import {ChangeEvent, ReactElement} from 'react';
import {SelectForm} from 'components/forms';
import {ITimeSeries} from 'components/pc/widgets/timeseries/types';

type IProps<T> = {
  flattenKeys: string;
  parallel: number;
  rows: T[];
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  onChange(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void;
};

/**
 * DefaultTable 내부에서 checkbox 변경
 * @param flattenKeys
 * @param parallel
 * @param rows
 * @param width
 * @param height
 * @param disabled
 * @param onChange
 * @constructor
 */
function ParallelSelector<T extends ITimeSeries>({
  flattenKeys,
  parallel,
  rows,
  width = 70,
  height = 30,
  disabled,
  onChange
}: IProps<T>): ReactElement {
  const options = new Array(rows.length).fill(0).map((row, i) => ({label: 'Axis ' + (i + 1), value: i}));

  return (
    <SelectForm
      name={flattenKeys}
      title="parallel"
      value={parallel}
      options={options}
      width={width}
      height={height}
      disabled={disabled}
      onChange={onChange}
    />
  );
}

export default ParallelSelector;
