import styled from 'styled-components';
import * as React from 'react';
import {Dispatch, SetStateAction, useEffect, PropsWithChildren} from 'react';
import CheckboxTree, {Node as TreeNode, OnCheckNode as OnCheckTreeNode} from 'react-checkbox-tree';
import useNodeMenuIcons from 'components/pc/node-selector/parts/useNodeMenuIcons';
import {ICheckBoxTreeOptions} from 'components/pc/node-selector/NodeSelectorRevision';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  // overriding
  .react-checkbox-tree {
    overflow: auto;
    // overriding
    .rct-text {
      white-space: nowrap;
    }
    &::-webkit-scrollbar {
      background: rgba(0, 0, 0, 0.08);
      width: 10px;
      height: 10px;
      cursor: default;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
    }
  }
  .rct-node {
    &.disabled-node {
      background-color: rgba(255, 255, 255, 0.9);
      color: rgba(87, 86, 96, 0.3);
      //pointer-events: none;
      //cursor: not-allowed !important;
      label:hover {
        background-color: white;
      }
      .rct-checkbox {
        opacity: 0.1;
      }
    }
  }
`;
const SearchForm = styled.div`
  height: 40px;
  flex-shrink: 0;
`;

export type ICustomNode = {
  label: React.ReactNode;
  value: string;
  children?: Array<Node>;
};

type IProps = PropsWithChildren & {
  nodes: TreeNode[];
  checkedState: [string[], Dispatch<SetStateAction<string[]>>];
  expandedState: [string[], Dispatch<SetStateAction<string[]>>];
  iconOption?: 'modal' | 'normal';
  beforeOnCheck?(candidate: string[], checkedList: string[]): string[];
  checkBoxTreeOptions?: ICheckBoxTreeOptions;
  isShowNodeSelector?: boolean;
};

function NodeMenu({
  children,
  nodes,
  checkedState,
  expandedState,
  iconOption = 'modal',
  beforeOnCheck,
  checkBoxTreeOptions
}: IProps) {
  const [checked, setChecked] = checkedState;
  const [expanded, setExpanded] = expandedState;
  const icons = useNodeMenuIcons(iconOption);

  useEffect(() => {
    if (checkBoxTreeOptions?.expandUntilSearchString) {
      setExpanded(convertTreeNodeToStringArray(nodes));
    }
  }, [checkBoxTreeOptions?.expandUntilSearchString, nodes]);

  const convertTreeNodeToStringArray = (nodes: TreeNode[]): string[] => {
    const result: string[] = [];

    const traverse = (node: TreeNode) => {
      if (node.children && node.children.length > 0) {
        result.push(node.value);
        node.children.forEach((child) => traverse(child));
      }
    };

    nodes.forEach((node) => traverse(node));

    return result;
  };

  const onCheck = (
    list: string[],
    node: OnCheckTreeNode & {isLeaf: boolean; unit?: string; parent?: {children: TreeNode[]}}
  ): void => {
    const isDisabledNode = [...(node.parent.children || [])].find(
      (item) => item.className === 'disabled-node' && item.value === node.value
    );
    if (isDisabledNode) {
      return;
    }

    if (beforeOnCheck) {
      const valid = beforeOnCheck(list, checked);
      setChecked(valid);
    } else {
      setChecked(list);
    }
  };

  // console.log(checked);

  return (
    <Container>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={onCheck}
        onExpand={setExpanded}
        expandOnClick
        showNodeIcon={false}
        icons={icons}
        onlyLeafCheckboxes={checkBoxTreeOptions?.onlyLeafCheckboxes}
      />
      {children}
    </Container>
  );
}

export default NodeMenu;
