import {DEFAULT_FEATURE, defaultUpdateData} from 'components/pc/widgets/hmb/const';
import {
  ISelectedStream,
  IStreamDescription,
  IStreamHierarchy,
  IStreamValue,
  ITableData,
  streamNameValue
} from 'components/pc/widgets/hmb/type';
import {api} from 'api/api';

type IHmbGetStreamAPIResponse = {
  name: string;
  value?: number;
  subnode?: streamNameValue[];
};
type ComponentClassKeyFeatureType = {ComponentClassName: string; ComponentClassComponents: string[]};

export const hbmWidgetCalcFunc = {
  getStreamValueData: async (selectedStreamList: ISelectedStream, streamHierarchyInfoList: any) => {
    if (!streamHierarchyInfoList) return;

    const selectedStreamString = selectedStreamList.stream;
    const targetStreamHierarchy = streamHierarchyInfoList.filter(
      (item: IStreamHierarchy) => selectedStreamString === item.stream
    );

    if (targetStreamHierarchy?.length === 0) return;

    const result = [] as IHmbGetStreamAPIResponse[][];
    await getStreamData(targetStreamHierarchy[0].streamHierarchyInfo, result);
    if (result?.length > 0) {
      return convertToHMBStreamInfo(result);
    } else {
      return {};
    }
  },
  /**
   * Convert From
   * {
   *   name: 'Feed',
   *   subNode: [
   *    {name:Temperature, value:101},...
   *    {name:Components, subnode:[....]}
   *   ]
   * }
   * To
   * {
   *   Feed:{
   *     Temperature: 101,
   *     MassFlowRate: 202,
   *     MoleFlowRate:1,
   *     Components? : {key:value}[]
   *     Components? : {key:value}[]
   *   }
   * }
   */
  getStreamListFromModelHierarchy: async (fccModel) => {
    try {
      if (!fccModel) return;
      // const fccModel = await api.post('/tags/get_hierarchy', {category: 'model'});
      const data = fccModel[0]?.subnode[0]?.subnode;
      const path_1 = fccModel[0]?.name; // model name
      const path_2 = fccModel[0]?.subnode[0]?.name; // stream
      const rootNodeHierarchy = [path_1, path_2];
      const streamDataWithHierarchyInfo = convertModelToStreamListWithHierarchyInfo(data, [...rootNodeHierarchy]);
      const streamData = streamDataWithHierarchyInfo.map((item) => item.stream);
      return {streamDataWithHierarchyInfo, streamData};
    } catch (e) {
      const streamDataWithHierarchyInfo = [];
      const streamData = [];
      return {streamDataWithHierarchyInfo, streamData};
    }
  },
  checkChangeDescriptionAndReturnValue: <U, T>(
    changes: U[],
    tableData: T[],
    streamDescriptionObject: IStreamDescription
  ) => {
    if (!changes || !tableData) return;
    const descriptionRowChanges = changes.filter((item) => item[0] === 1);
    const row0Data = tableData[0];
    if (descriptionRowChanges.length === 0) {
      return null;
    }

    let newStreamDescriptionObject = {...streamDescriptionObject};

    for (let i = 0; i < descriptionRowChanges.length; i++) {
      const streamName = row0Data[descriptionRowChanges[i][1]];
      const description = descriptionRowChanges[i][3];
      newStreamDescriptionObject = {...newStreamDescriptionObject, [streamName]: description};
    }
    return newStreamDescriptionObject;
  },
  getFilteredData: (tableData: ITableData, hiddenComponent: string[]) => {
    const hiddenRowList = getHiddenRowList(tableData, hiddenComponent);
    return tableData.filter((item, idx) => hiddenRowList.indexOf(idx) === -1);
  },
  getHideComponentList: (selectedRangeArray: number[][], tableData: ITableData, prevHiddenComponentList: string[]) => {
    const selectedRowRange = selectedRangeArray.map((item) => [item[0], item[2]]);
    const selectedRowList_ = selectedRowRange.map((item) => expandArray(item));
    const selectedRowList = [].concat(...selectedRowList_);
    const hideRowComponentList = [];
    for (let i = 0; i < selectedRowList?.length; i++) {
      hideRowComponentList.push(tableData[selectedRowList[i]][0]);
    }

    return Array.from(new Set([...prevHiddenComponentList, ...hideRowComponentList]));
  },
  getDefaultTableData: () => {
    // const DefaultData 를 반환하면 배열항목이 dropdown 선택시에 자동으로 바뀌는 bug
    return [
      ['Name', 'Unit', ''],
      ['Temperature', 'C', ''],
      ['Pressure', 'bar_g', ''],
      ['Molar Flow', 'kmol/hr', ''],
      ['Mass Flow', 'kg/s', ''],
      ['Volume Flow', 'm3/hr', ''],
      ['LiqVolFlow@StdCond', 'm3/h', ''],
      ['CCR', '', ''],
      ['Component', '', '']
    ];
  },
  getHMBData: (searchResult: IStreamValue, streamDescription: IStreamDescription) => {
    const {selectedStreamList, hmbDataPerColumn} = searchResult;
    const newData = [];
    newData.push([
      'Name',
      'Unit',
      ...selectedStreamList.map((item) => item.stream).filter((item_) => item_ !== ''),
      ''
    ]);

    let descriptionRow;
    if (streamDescription) {
      descriptionRow = selectedStreamList.map((item) => streamDescription[item.stream]);
    } else {
      descriptionRow = [''];
    }

    newData.push(['Description', '', ...descriptionRow]);

    /**
     *  result : [Components1, Component2]
     *  result : [ [a,b,c,d,e,] , [a,ss,d,f,g,h,] ]
     */
    const [ComponentClassUniqueKey, , ComponentClassRows] = getComponentInfo(selectedStreamList, hmbDataPerColumn);

    const streamFeatureList = [...DEFAULT_FEATURE, ...ComponentClassRows];

    for (let i = 0; i < streamFeatureList.length; i++) {
      let rowData = [];

      if (i < 7) {
        // Default
        rowData = [...defaultUpdateData[i]]; // 'Temperature', 'T'
      } else {
        rowData = [streamFeatureList[i], '']; // 'MolarFraction_O2', ''
      }
      for (let j = 0; j < selectedStreamList.length; j++) {
        const streamValues = hmbDataPerColumn[selectedStreamList[j]?.stream];

        // :todo index 계산 ComponentClassUniqueKey / ComponentClassKeyUniqueFeatures 통해서
        // if (i > 6) {
        //   searchResult?.hmbDataPerColumn[streamNameList[j]?.stream]
        //   // streamValues = searchResult?.hmbDataPerColumn[ComponentClassUniqueKey[0]].find(
        //   //   (item) => (item.name = streamNameList[j])
        //   // )?.value;
        // }
        if (!streamValues) {
          rowData.push('');
          continue;
        }
        let value;
        if (i < 7) {
          value = streamValues[streamFeatureList[i]];
        } else {
          // console.log(streamValues);
          // console.log(streamValues?.ComponentClass);

          const ComponentClassDataPerStream = streamValues?.ComponentClass.find(
            (item) => item?.name === ComponentClassUniqueKey[0]
          );
          value = ComponentClassDataPerStream?.subnode?.find((item) => item.name === streamFeatureList[i])?.value;
          // console.log(streamValues[ComponentClassUniqueKey[0]].find((item) => item.name === streamFeatureList[i]));
        }
        rowData.push(value);
      }
      // row data add
      newData.push(rowData);

      // separator row add
      // if (i === 5) {
      //   newData.push(['Component', '', ...selectedStreamList.map((item) => ''), '']);
      // }
    }
    // console.log('New Data', newData);
    return newData;
  }
};

export const hbmWidgetUtilFunc = {
  arraysAreEqual: <T>(array1: T[], array2: T[]) => {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      const element1String = JSON.stringify(array1[i]);
      const element2String = JSON.stringify(array2[i]);

      if (element1String !== element2String) {
        return false;
      }
    }
    return true;
  },
  isEmptyObj: (obj: object) => {
    return obj.constructor === Object && Object.keys(obj).length === 0;
  }
};

const getHiddenRowList = (tableData: ITableData, hiddenComponent: string[]) => {
  if (hiddenComponent.length === 0) return tableData; // hidden Component 존재하지않는다면 그대로 return
  const rowHeaders = [...tableData].map((item) => item[0]);
  const hiddenRowList = [];
  for (let i = 0; i < rowHeaders?.length; i++) {
    const rowHeaderValue = rowHeaders[i];
    if (hiddenComponent.includes(String(rowHeaderValue))) {
      hiddenRowList.push(i);
    }
  }
  return hiddenRowList;
};

const convertModelToStreamListWithHierarchyInfo = (node, hierarchy: string[]) => {
  return node.map((item) => ({stream: item.name, streamHierarchyInfo: [...hierarchy, item.name], type: item.type}));
};

const expandArray = (inputArray) => {
  if (inputArray.length !== 2) {
    return;
  }

  const [start, end] = inputArray;
  if (start === end) {
    return [start];
  }

  const expandedArray = [];
  for (let i = start; i <= end; i++) {
    expandedArray.push(i);
  }

  return expandedArray;
};

/**
 *  result : [Components1, Component2]
 *  result : [ [a,b,c,d,e,] , [a,ss,d,f,g,h,] ]
 */
const getComponentInfo = (selectedStreamList: ISelectedStream[], hmbDataPerColumn) => {
  let ComponentClassKeyTemp = [];
  let ComponentClassKeyFeaturesTemp: ComponentClassKeyFeatureType[] = [];
  for (let i = 0; i < selectedStreamList.length; i++) {
    const streamValues = hmbDataPerColumn[selectedStreamList[i]?.stream];
    const componentClassList = streamValues?.ComponentClass;
    for (let j = 0; j < componentClassList?.length; j++) {
      ComponentClassKeyTemp.push(componentClassList[j]?.name);
      ComponentClassKeyFeaturesTemp.push({
        ComponentClassName: componentClassList[j]?.name,
        ComponentClassComponents: componentClassList[j]?.subnode?.map((item) => item.name)
      });
    }
  }
  const ComponentClassKey = new Set(ComponentClassKeyTemp);
  const ComponentClassUniqueKey = Array.from(ComponentClassKey);
  const ComponentClassKeyFeatures = new Array(ComponentClassUniqueKey?.length).fill([]);
  for (let i = 0; i < ComponentClassKeyFeaturesTemp?.length; i++) {
    const componentClassName = ComponentClassKeyFeaturesTemp[i].ComponentClassName;
    const idx = ComponentClassUniqueKey.indexOf(componentClassName);
    if (idx === -1) {
      continue;
    }
    ComponentClassKeyFeatures[idx] = [
      ...ComponentClassKeyFeatures[idx],
      ...ComponentClassKeyFeaturesTemp[i].ComponentClassComponents
    ];
  }

  const ComponentClassKeyUniqueFeatures = ComponentClassKeyFeatures.map(function (item) {
    const set = new Set(item);
    return Array.from(set);
  });
  const ComponentClassRows = [];
  for (let i = 0; i < ComponentClassUniqueKey?.length; i++) {
    ComponentClassRows.push(ComponentClassUniqueKey[i]);
    ComponentClassRows.push(...ComponentClassKeyUniqueFeatures[i]);
  }

  const MolarFractionRow = ComponentClassRows.filter((item) => item.includes('MolarFraction')).sort();
  const MassFractionRow = ComponentClassRows.filter((item) => item.includes('MassFraction')).sort();
  const ExceptRow = ComponentClassRows.filter(
    (item) =>
      !item.includes('MassFraction') && !item.includes('MolarFraction') && ComponentClassUniqueKey.indexOf(item) === -1
  ).sort();

  let ComponentName;
  if (ComponentClassUniqueKey?.length > 0) {
    ComponentName = ComponentClassUniqueKey;
  } else {
    ComponentName = 'Component';
  }
  const reorderedRow = [ComponentName, ...MolarFractionRow, ...MassFractionRow, ...ExceptRow];

  return [ComponentClassUniqueKey, ComponentClassKeyUniqueFeatures, reorderedRow];
};

const getStreamData = async (tag: string[], streamResultTemp) => {
  await api
    .post('/tags/get_stream_data', {stream: tag})
    .then(function (res) {
      // console.log('request end', tag);
      // console.log(res);
      streamResultTemp.push(res);
    })
    .catch((error) => {
      console.log(error);
    });
};

const convertToHMBStreamInfo = (arr: IHmbGetStreamAPIResponse[][]) => {
  const HBMStreamInfo = {};
  if (arr.length === 0) return;
  for (let i = 0; i < arr.length; i++) {
    const [data] = arr[i];
    const streamName = data.name;
    const values = data.subnode;
    const valueDic = {};
    const ComponentClass = [];
    for (let j = 0; j < values?.length; j++) {
      const {name, value, subnode} = values[j];
      if (!subnode) {
        valueDic[name] = value;
      } else {
        ComponentClass.push(values[j]);
      }
    }
    valueDic['ComponentClass'] = ComponentClass;
    HBMStreamInfo[streamName] = valueDic;
  }
  return HBMStreamInfo;
};
