import React, {ReactElement, useContext} from 'react';
import styled from 'styled-components';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {Button} from 'components/forms';
import {mpfdMenuList} from 'components/menu/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowProgress, faFile, faFileImport, faFilePlus} from '@fortawesome/pro-light-svg-icons';

const Container = styled.div`
  background-color: #d9e9ee;
  width: 520px;
  height: 200px;
  border: 1px solid #cae1e8;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  h3 {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    color: #7faab7;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin: auto;

  button {
    position: relative;
    min-width: 150px;
    border-radius: 10px;
    padding: 10px 20px;
    > svg {
      margin: 10px;
    }
  }
`;
const Badge = styled.div`
  min-width: 20px;
  padding: 5px;
  border-radius: 20px;
  background-color: #ff964b;
  position: absolute;
  top: -10px;
  right: -10px;
  line-height: 20px;
  font-weight: bold;
`;

function MetaPfdAppGuide(): ReactElement {
  const {showActionMenu} = useContext(CommonContext);
  const {file, fileList, showMPfdModal, importModalState} = useContext(MetaPfdContext);
  const [, setSetIsShowImportModal] = importModalState;

  const onClickLoadFile = (): void => {
    const [first] = mpfdMenuList;
    showActionMenu(first);
  };

  if (file?.pidName) {
    return null;
  } else {
    return (
      <Container>
        <h3>
          <FontAwesomeIcon icon={faArrowProgress} /> Meta PFD Layouts
        </h3>
        <ButtonGroup>
          <Button onClick={onClickLoadFile}>
            {fileList.length > 0 && <Badge>{fileList.length}</Badge>}
            <FontAwesomeIcon icon={faFile} size="2xl" />
            <br />
            Load Meta PFD
          </Button>
          <Button variant="secondary" onClick={() => setSetIsShowImportModal(true)}>
            <FontAwesomeIcon icon={faFileImport} size="2xl" />
            <br />
            Import Layout File
          </Button>
          <Button variant="info" onClick={() => showMPfdModal('create')}>
            <FontAwesomeIcon icon={faFilePlus} size="2xl" />
            <br />
            New Meta PFD
          </Button>
        </ButtonGroup>
      </Container>
    );
  }
}

export default MetaPfdAppGuide;
