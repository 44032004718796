import {KeyboardEvent, useEffect} from 'react';

type IConstraint = {
  save(): void;
  saveAs(): void;
  create(): void;
  close(): void;
  zoomIn(): void;
  zoomOut(): void;
  zoomTo(zoomLevel: number): void;
  pan?(x: number, y: number): void; // TODO: mpfd pan 기능 구현 이후 ? 삭제
  copy?(): void;
  paste?(): void;
};

function useHotkey<T extends IConstraint>(commands: T, isFileOwner: boolean, isAllowCopy: boolean): void {
  useEffect(() => {
    const onKeyDown = (e: Event | KeyboardEvent) => {
      const {code, ctrlKey, shiftKey} = e as KeyboardEvent;

      // console.log('keyboard event', code, 'ctrlKey:', ctrlKey, 'shiftKey:', shiftKey, e);

      // panning with keyboard
      // if (code === 'ArrowLeft') {
      //   e.preventDefault();
      //   commands.pan(-panInterval, 0);
      // }
      // if (code === 'ArrowRight') {
      //   e.preventDefault();
      //   commands.pan(panInterval, 0);
      // }
      // if (code === 'ArrowUp') {
      //   e.preventDefault();
      //   commands.pan(0, -panInterval);
      // }
      // if (code === 'ArrowDown') {
      //   e.preventDefault();
      //   commands.pan(0, panInterval);
      // }

      // ctrl 로 시작하는 단축키
      if (ctrlKey) {
        // Save
        if (code === 'KeyS') {
          e.preventDefault();
          if (shiftKey) {
            if (isAllowCopy) {
              commands.saveAs();
            }
          } else {
            if (isFileOwner) {
              commands.save();
            }
          }
        }

        //
        if (code === 'KeyE') {
          e.preventDefault();
          commands.close();
        }
        if (code === 'Minus') {
          e.preventDefault();
          commands.zoomOut();
        }
        if (code === 'Equal') {
          e.preventDefault();
          commands.zoomIn();
        }
        if (code === 'Digit0') {
          e.preventDefault();
          commands.zoomTo(1);
        }
        if (code === 'KeyC' && shiftKey) {
          e.preventDefault();
          commands.copy?.();
        }
        if (code === 'KeyV' && shiftKey) {
          e.preventDefault();
          commands.paste?.();
        }
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [commands]);
}

export default useHotkey;
