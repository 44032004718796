// this util functions generates an svg path from a list of points
import {ColorResult} from 'react-color';

export function generatePath(points: number[][]) {
  const path = points.map(([x, y]) => `${x},${y}`).join(' L');
  return `M${path} Z`;
}

export function getRgbaFromColorResult(rgba: ColorResult) {
  if (!rgba?.rgb) {
    return '';
  }
  const {r, g, b, a} = rgba?.rgb;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
