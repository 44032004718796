import styled from 'styled-components';
import ManagementHeader from 'components/auth/management/parts/ManagementHeader';
import ManagementTable from 'components/auth/management/parts/ManagementTable';
import {managementConst} from 'components/auth/management/const';
import {GroupInfo} from 'components/auth/management/management-api-function';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

type IProps = {
  groupList: GroupInfo[];
  openCreateGroupAside(): void;
  onClickGroupManagementRow(rowkey: string): void;
};

function GroupManagement({groupList, openCreateGroupAside, onClickGroupManagementRow}: IProps) {
  return (
    <Container>
      <ManagementHeader
        title={managementConst.group.groupManagementHeader.title}
        description={managementConst.group.groupManagementHeader.description}
      />
      <ManagementTable
        fields={managementConst.group.groupFields}
        list={groupList}
        listType="group"
        onClickCreate={openCreateGroupAside}
        onClickRow={onClickGroupManagementRow}
        rowKeyName="name"
      />
    </Container>
  );
}

export default GroupManagement;
