import React, {
  BaseSyntheticEvent,
  ReactElement,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {
  faArrowPointer,
  faArrowsMaximize,
  faHand,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faShapes
} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import useDraggable from 'utils/useDraggable';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {IPosition} from 'components/common/types';
import {Button} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ShapeItem from 'components/pc/common/shapes/ShapeItem';
import Tooltip from 'components/common/Tooltip';
import {ShapeType} from 'components/pc/common/shapes/type';
import classNames from 'classnames';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {defaultTheme} from 'theme/theme';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: ${META_PFD_zINDEX.TOOLBOX};
  width: 50px;
  background-color: #ffffff;
  border: 2px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  //overflow: hidden;
  overflow: visible;
  user-select: none;
`;
const MoveHandle = styled.div`
  width: 100%;
  height: 20px;
  background-color: #e7e7e7;
  cursor: move;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 86%;
    flex-shrink: 0;
    background-color: #d0cece;
  }
`;
const ToolsContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    width: 40px;
    height: 40px;
    border: none !important;

    &.active {
      background-color: #d7eeff;
    }

    &:not(:disabled, .active):hover {
      background-color: #edf6ff;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
const HorizontalLine = styled.hr`
  width: 100%;
  border: none;
  margin: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
const ShapesGroup = styled.div`
  //background-color: #ffffff;
  color: #000;
`;

type ToolNames = 'Select' | 'Zoom In' | 'Zoom Out' | 'Pan' | 'Fit View' | 'Lock Interactivity' | 'Shapes';

export type ToolIds = 'select' | 'zoomIn' | 'zoomOut' | 'pan' | 'fitView' | 'lock' | 'shapes';

export type IToolbox = {
  name: ToolNames;
  id: ToolIds;
  icon: IconDefinition;
  overlayIcon?: IconDefinition;
  flip?: 'horizontal' | 'vertical';
  underlined?: boolean;
  children?: ShapeType[];
};

const toolList: IToolbox[] = [
  {name: 'Select', id: 'select', icon: faArrowPointer},
  {name: 'Zoom In', id: 'zoomIn', icon: faMagnifyingGlassPlus},
  {name: 'Zoom Out', id: 'zoomOut', icon: faMagnifyingGlassMinus},
  {name: 'Fit View', id: 'fitView', icon: faArrowsMaximize},
  {name: 'Pan', id: 'pan', icon: faHand, underlined: true},
  {
    name: 'Shapes',
    id: 'shapes',
    icon: faShapes,
    children: ['Circle', 'RoundRectangle', 'Rectangle', 'Diamond', 'Cylinder', 'Plus', 'Arrow', 'Text', 'DataBinding']
  }
];

type IProps = {
  boundaryRef: RefObject<HTMLDivElement>;
};

function Toolbox({boundaryRef}: IProps): ReactElement {
  const {panningState, canvasEnteredState, enteredSpacebarState, activeToolState, isProcessCanvasModalOpened} =
    useContext(ProcessCanvasContext);
  const {remoteAppMenu} = useContext(CommonContext);
  const {fitView} = useProcessCanvasCommand();
  const toolboxRef = useRef<HTMLDivElement>(null);
  const defaultPosition = (LocalStorageManager.getItem('PROCESS_CANVAS_TOOLBOX') as IPosition) || {x: 10, y: 50};
  const defaultPadding = {top: 50, right: 10, bottom: 10, left: 10};
  const [toolboxPosition, onMouseDownToolbox, setPosition] = useDraggable(
    boundaryRef,
    toolboxRef,
    defaultPosition,
    defaultPadding
  );
  const [activeTool, setActiveTool] = activeToolState;
  const [canvasEntered] = canvasEnteredState;
  const [enteredSpacebar, setEnteredSpacebar] = enteredSpacebarState;
  const [, setPanning] = panningState;
  const previousTool = useRef<ToolIds | null>(null);
  const [isShapeMenuOpened, setIsShapeMenuOpend] = useState(false);

  // toolbox 의 위치가 변경되면 항상 LocalStorage 에 저장
  useEffect(() => {
    LocalStorageManager.setItem('PROCESS_CANVAS_TOOLBOX', toolboxPosition);
  }, [toolboxPosition]);

  const onClick = (e: BaseSyntheticEvent<HTMLButtonElement>): void => {
    const {name} = e.currentTarget;

    switch (name) {
      case 'zoomIn':
        // zoomIn();
        break;
      case 'zoomOut':
        // zoomOut();
        break;
      case 'pan':
        setPanning(true);
        setActiveTool('pan');
        setEnteredSpacebar(true);
        break;
      case 'fitView':
        fitView();
        return;
      case 'shapes':
        setIsShapeMenuOpend((prev) => !prev);
        return;
      default:
      // do nothing.
    }
    if (name !== 'pan') {
      setPanning(false);
      setEnteredSpacebar(false);
    }

    previousTool.current = name === activeTool ? undefined : name;
    setActiveTool(name);
  };

  useEffect(() => {
    // spacebar 눌렀을 때 pan 모드로 변경
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (!enteredSpacebar) {
          setPanning(true);
          setEnteredSpacebar(true);
          previousTool.current = activeTool;
          setActiveTool('pan');
        }
      }
    };

    // spacebar 놓았을 때 이전 tool로 돌아감
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        setEnteredSpacebar(false);
        setPanning(false);
        if (previousTool.current) {
          setActiveTool(previousTool.current);
          previousTool.current = null;
        }
      }
    };

    //todo: react flow 내에서 mouse evnet를 preventDefaul로 제어하는걸로 의심 => wheel을 통한 pan 기능은 동작하지만, 해당 기능을 사용할 때 wheel을 감지해서 ui 변경은 안되는 상태.

    /*    // wheel 버튼 눌렀을 때 pan 모드로 변경
    const handleMouseDown = (e: MouseEvent) => {
      console.log('add check', e);
      if (e.button === 1) {
        if (!enteredSpacebar) {
          setEnteredSpacebar(true);
          console.log('mouse Down');
          previousTool.current = activeTool;
          setActiveTool('pan');
        }
      }
    };

    // wheel 버튼 놓았을 때 이전 tool로 돌아감
    const handleMouseUp = (e: MouseEvent) => {
      if (e.button === 1) {
        setEnteredSpacebar(false);
        console.log('mouse up');
        if (previousTool.current) {
          setActiveTool(previousTool.current);
          previousTool.current = null;
        }
      }
    };*/

    if (canvasEntered) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      // document.addEventListener('mousedown', handleMouseDown);
      // document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      // document.removeEventListener('mouseup', handleMouseUp);
      // document.removeEventListener('mousedown', handleMouseDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [canvasEntered, activeTool]);

  const escKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setActiveTool('select');
      setPanning(false);
    }
  }, []);

  useEffect(() => {
    if (isProcessCanvasModalOpened) {
      return;
    }
    document.addEventListener('keydown', escKeyPress);
    return () => {
      document.removeEventListener('keydown', escKeyPress);
    };
  }, [escKeyPress, isProcessCanvasModalOpened]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const diff =
        document.body.offsetWidth -
        (Number(defaultTheme.app.menu.width) + toolboxRef.current.offsetLeft + toolboxRef.current.offsetWidth);

      if (diff < 0) {
        setPosition((prev: IPosition) => ({x: document.body.offsetWidth - 130, y: prev.y}));
      }
    });

    if (document.body) {
      observer.observe(document.body);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    const xp = Number(defaultTheme.action.menu.width);
    if (remoteAppMenu) {
      if (toolboxRef.current.offsetLeft < xp) {
        setOffsetX(xp - toolboxRef.current.offsetLeft + 10);
      }
    } else {
      setOffsetX(0);
    }
  }, [remoteAppMenu]);

  return (
    <Container ref={toolboxRef} style={{left: toolboxPosition?.x + offsetX, top: toolboxPosition?.y}}>
      <MoveHandle onMouseDown={onMouseDownToolbox} />
      <ToolsContainer>
        {toolList.map((tool) => (
          <React.Fragment key={tool.id}>
            <Button
              variant="none"
              name={tool.id}
              onClick={onClick}
              className={classNames(activeTool === tool.id ? 'active' : 'none')}
            >
              <Tooltip key={tool.id} place="right" content={tool.name}>
                <FontAwesomeIcon icon={tool.icon} size="xl" />
              </Tooltip>
            </Button>
            {tool.children && tool.id === 'shapes' && isShapeMenuOpened && (
              <ShapesGroup>
                {tool.children.map((shape) => (
                  <Tooltip
                    key={shape}
                    place="right"
                    content={`Drag ${shape.replace(/([A-Z][a-z])/g, ' $1')} to canvas stage`}
                  >
                    <ShapeItem key={shape} type={shape} />
                  </Tooltip>
                ))}
              </ShapesGroup>
            )}
            {tool.underlined && <HorizontalLine key={`${tool.id}-underline`} />}
          </React.Fragment>
        ))}
      </ToolsContainer>
    </Container>
  );
}

export default Toolbox;
