import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-light-svg-icons';
import WeatherIcon from 'components/pc/widgets/weather/WeatherIcon';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getCelsius, getFahrenheit} from 'components/pc/widgets/weather/const';
import {ISizeType} from 'components/common/types';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 30%;
  background-color: #eef2ff;
  border-radius: 1.2rem;
  padding: 1.4rem;
  transition: all 0.2s;

  &.md {
    border-radius: 0.8rem;
    padding: 1rem;
  }
  &.sm {
    border-radius: 0.6rem;
    padding: 0.8rem;
  }
`;

const ResultWrapMainDetail = styled.div`
  .city {
    font-size: 28px;
    font-weight: 600;
  }
`;

const TemperatureWrapper = styled.div`
  font-size: 50px;
  font-weight: 600;
  transition: font-size 0.2s;

  &.md {
    font-size: 30px;
  }
  &.sm {
    font-size: 20px;
  }
`;

const Sky = styled.div`
  font-size: 28px;
  font-weight: 400;
  transition: font-size 0.2s;

  &.md {
    font-size: 20px;
  }

  &.sm {
    display: none;
    font-size: 16px;
  }
`;

const WeatherIconWrapper = styled.div`
  margin-left: 0.7em;
  height: 100px;
  transition: height 0.2s;

  &.md {
    height: 60px;
  }

  &.sm {
    height: 40px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const WeatherTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchIonWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;

  svg {
    transition: all 0.2s;
    width: 20px;
    height: 20px;
  }

  &.sm {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  &.md {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const City = styled.div`
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  transition: font-size 0.2s;

  &.sm {
    font-size: 14px;
  }

  &.md {
    font-size: 16px;
  }
`;

type IProps = {
  darkModeChange: boolean;
  weatherData: IWeatherData;
  isCelsius: boolean;
  size: ISizeType;
  openCheck(): void;
};

function WeatherWidgetHeader({darkModeChange, weatherData, isCelsius, size, openCheck}: IProps) {
  const f = weatherData.main?.temp ? getFahrenheit(weatherData.main?.temp) : undefined;
  const c = weatherData.main?.temp ? getCelsius(weatherData.main?.temp) : undefined;
  const [value, setValue] = useState<number | undefined>();
  const [unit, setUnit] = useState<string | undefined>();

  useEffect(() => {
    setValue(isCelsius ? c : f);
    setUnit(isCelsius ? '°C' : '°F');
  }, [c, f, isCelsius]);

  return (
    <Container className={size.width} style={darkModeChange === false ? {backgroundColor: '#3f425f'} : {}}>
      <ResultWrapMainDetail>
        <WeatherTitleWrap>
          <City className={size.width}>{weatherData.name}</City>
          <SearchIonWrapper className={size.width} onClick={openCheck}>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </SearchIonWrapper>
        </WeatherTitleWrap>
        <TemperatureWrapper className={size.width}>
          {value}
          {unit}
        </TemperatureWrapper>
        <Sky className={size.width}>{weatherData?.weather?.[0].main}</Sky>
      </ResultWrapMainDetail>
      <WeatherIconWrapper className={size.width}>{WeatherIcon(weatherData.weather?.[0].icon)}</WeatherIconWrapper>
    </Container>
  );
}

export default WeatherWidgetHeader;
