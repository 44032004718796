import {MutableRefObject, useContext, useEffect, useRef, useState} from 'react';
import {IDragItem, ITransferDataTypes} from 'components/dnd/types';
import {CommonContext} from 'components/common/CommonProvider';
import {getUniqueKey} from 'utils/commons';

type IReturn = [boolean, boolean, MutableRefObject<any>, string];

export const useDrop = (
  accept: ITransferDataTypes[],
  onDropItem: (dragItem: IDragItem, fileList?: FileList) => void
): IReturn => {
  const [isOver, setIsOver] = useState<boolean>(false);
  const droppableRef = useRef<HTMLDivElement>(null);
  const [id, setId] = useState<string>('');

  const {afterDragEnd, dragItem, onDragOverSettingZoneId, dragOverZoneId} = useContext(CommonContext);
  const isAcceptable = accept?.length === 0 ? true : dragItem && [...(accept || [])].includes(dragItem?.type);

  useEffect(() => {
    const a = getUniqueKey();
    setId(a);
  }, []);

  useEffect(() => {
    const current = droppableRef.current;
    const onDrop = (e: DragEvent) => {
      e.preventDefault();
      if (!isAcceptable) {
        return;
      }
      const dragItemCopy = afterDragEnd();
      const fileList = e.dataTransfer.files;
      onDropItem(dragItemCopy, fileList);
      setIsOver(false);
    };

    const onDragOver = (e: DragEvent) => {
      e.stopPropagation();
      isAcceptable && e.preventDefault();
      isAcceptable && onDragOverSettingZoneId(id);

      // e.preventDefault();
      // console.log(isOver);
      // isAcceptable && e.preventDefault();
      // isAcceptable && e.stopPropagation();
    };

    // const onDragleave = (e: DragEvent) => {
    //   e.preventDefault();
    //   console.log('>>>DragLeave');
    // };
    if (current) {
      current.addEventListener('drop', onDrop);
      current.addEventListener('dragover', onDragOver);
      // current.addEventListener('dragleave', onDragleave);
    }

    return () => {
      if (current) {
        current.removeEventListener('drop', onDrop);
        current.removeEventListener('dragover', onDragOver);
        // current.removeEventListener('dragleave', onDragleave);
      }
    };
  }, [afterDragEnd, isAcceptable, isOver, onDropItem, onDragOverSettingZoneId, id]);

  return [id && dragOverZoneId === id && isAcceptable, isAcceptable, droppableRef, id];
};
