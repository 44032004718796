import {ReactElement} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const ComponentName = styled.div`
  width: 80%;
  font-size: 18px;
`;

const ComponentDeleteBtnWrapper = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: center;
  cursor: pointer;
`;

type IProps = {
  hiddenComponent: string;
  removeHiddenComponent(hiddenComponent: string): void;
};

function HmbHiddenComponent({hiddenComponent, removeHiddenComponent}: IProps): ReactElement {
  return (
    <Container>
      <ComponentName>{hiddenComponent}</ComponentName>
      <ComponentDeleteBtnWrapper>
        <FontAwesomeIcon icon={faTimes} onClick={() => removeHiddenComponent(hiddenComponent)} />{' '}
      </ComponentDeleteBtnWrapper>
    </Container>
  );
}

export default HmbHiddenComponent;
