import {CellValue, RowObject} from 'handsontable/common';
import {api} from 'api/api';
import {TagDataListReturn} from 'components/pc/common/shapes/type';
import {ILatestTag} from 'hooks/useLatesetTagHandler';
import {ITagData} from 'api/data-types';

export type IPeriodOption = {
  type: 'latest_count' | 'time_range';
  latest_count?: number;
  time_range?: [startTimestamp: number, endTimestamp: number];
};

/**
 * @property colHeaders https://handsontable.com/docs/react-data-grid/column-header/
 * @property rowHeaders https://handsontable.com/docs/react-data-grid/row-header/
 * @property colWidth https://handsontable.com/docs/react-data-grid/column-width/
 * @property data https://handsontable.com/docs/react-data-grid/binding-to-data/
 * @property colLengths Column Length
 * @property rendererType Renderer Component
 * @property cellInfo Tag information attached to cell (cell will be read only)
 */

export type IHandsonTableSpreadSheetData = {
  colHeaders?: boolean | string[] | ((index: number) => string);
  rowHeaders?: boolean | string[] | ((index: number) => string);
  colWidth?:
    | number
    | string
    | number[]
    | string[]
    | undefined[]
    | Array<number | string | undefined>
    | ((index: number) => string | number | undefined);
  data: CellValue[][] | RowObject[];
  colLengths?: number;
  rendererType: 'TimestampDataRenderer' | 'NormalDataRenderer' | 'HmbDataRenderer';
  cellInfo?: unknown[][];
  readOnly?: boolean;
  fixedColumnsStart?: number;
  fixedRowsTop?: number;
  initialized?: boolean;
  isDataHierarchy?: boolean; // true if data is in hierarchy structure
};

export type IBlockTableData = {
  colHeaders?: boolean | string[] | ((index: number) => string);
  rowHeaders?: boolean | string[] | ((index: number) => string);
  colWidth?:
    | number
    | string
    | number[]
    | string[]
    | undefined[]
    | Array<number | string | undefined>
    | ((index: number) => string | number | undefined);
  data: string[][];
  selectedBlockNodes?: string[][];
  targetBlockName?: string;
  colLengths?: number;
  rendererType: 'HierarchyDataRenderer';
  // cellInfo?: unknown[][];
  readOnly?: boolean;
  fixedColumnsStart?: number;
  fixedRowsTop?: number;
  initialized?: boolean;
};

export type IHierarchyCellInfo = {
  isLowestChildren: boolean; // false if the cell has children
  childrenDataRows?: number[]; // [a, b] if the cell is the parent of children of rows a~b
  isHideComponent?: boolean;
  isParentExist?: boolean; // true if child has parent
  parentDepth?: number; // depth of parent
};

type IGetTagDataListParam = {
  tag: string[];
  category: string;
  latest_count?: number;
  time_stamp?: number[];
};

export const convertRawToSpreadSheetData = {
  tag: async (tagInfoList: ITagData[], periodOption: IPeriodOption): Promise<IHandsonTableSpreadSheetData> => {
    if (tagInfoList?.length === 0) return;
    const res = await getTagDataListReturnFromITagInfo(tagInfoList, periodOption);
    const colHeaders = ['time'].concat(res.map((tagDataList) => tagDataList?.tag.join('-')));
    let timestampMerged = [];
    res.forEach((item) => {
      timestampMerged = timestampMerged.concat(item?.data.map((n) => n[0]));
    });
    const set = new Set(timestampMerged);
    timestampMerged = Array.from(set).sort();
    let data = timestampMerged.map(() => new Array(res?.length + 1));
    for (let i = 0; i < timestampMerged?.length; i++) {
      const timestamp = timestampMerged[i];
      // data[i][0] = toDateFormat(timestamp * 1000, 'YYYY-MM-DD HH:mm:ss');
      data[i][0] = timestamp * 1000;
      for (let j = 0; j < res?.length; j++) {
        if (res[j]?.data.find((item) => item[0] === timestamp)) {
          data[i][j + 1] = res[j]?.data.find((item) => item[0] === timestamp)[1];
        }
      }
    }

    return {
      data,
      colHeaders,
      colWidth(idx) {
        return idx === 0 ? 150 : 100;
      },
      rowHeaders: [],
      colLengths: colHeaders?.length,
      rendererType: 'TimestampDataRenderer'
    };
  }
};

export const dataConvertUtilFunc = {
  tag2LatestTag: (tags: string[][]) => {
    return tags.map((item) => {
      const [category, ...rest] = item;
      return {
        key: [category.toLowerCase(), ...rest].join('-'),
        tag: rest,
        category: category.toLowerCase()
      };
    });
  },
  tagDataListReturn2LatestTag: (res: TagDataListReturn[]) => {
    const now = Date.now();
    return res.map((item) => {
      const refined = item?.data.map((point) => {
        point[0] = point[0] * 1000;
        point[1] = Number(point[1]);
        return point;
      });
      return {
        tag: item?.tag,
        category: item?.category.toLowerCase(),
        value: refined,
        updateTime: now,
        key: [item?.category.toLowerCase(), ...item?.tag].join('-')
      };
    });
  }
};

export const getTagDataListReturnFromITagInfo = async (tagInfoList: ITagData[], periodOption: IPeriodOption) => {
  let tag_infos: IGetTagDataListParam[];
  switch (periodOption.type) {
    case 'latest_count': {
      tag_infos = tagInfoList.map((item) => {
        const [category, ...rest] = item?.tag;
        return {
          tag: [...rest],
          category: category?.toLowerCase(),
          latest_count: periodOption?.latest_count
        };
      });
      break;
    }
    case 'time_range':
      tag_infos = tagInfoList.map((item) => {
        const [category, ...rest] = item?.tag;
        return {
          tag: [...rest],
          category: category?.toLowerCase(),
          time_range: periodOption?.time_range
        };
      });
      break;
  }
  return await api.post<TagDataListReturn[]>('/tags/get_tag_data_list', {tag_infos});
};

export const getTagDataListReturnFromILatestTags = async (latestTags: ILatestTag[], periodOption: IPeriodOption) => {
  let tag_infos: IGetTagDataListParam[];
  switch (periodOption.type) {
    case 'latest_count': {
      tag_infos = latestTags.map((item) => {
        return {
          tag: item.tag,
          category: item.category?.toLowerCase(),
          latest_count: periodOption?.latest_count
        };
      });
      break;
    }
    case 'time_range':
      tag_infos = latestTags.map((item) => {
        return {
          tag: item.tag,
          category: item.category?.toLowerCase(),
          latest_count: periodOption?.latest_count
        };
      });
      break;
  }
  return await api.post<TagDataListReturn[]>('/tags/get_tag_data_list', {tag_infos});
};
