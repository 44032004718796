import {type NodeProps, NodeResizer, useReactFlow, useStore, useUpdateNodeInternals} from 'reactflow';
import {drag} from 'd3-drag';
import {select} from 'd3-selection';
import {memo, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {IShapeData} from 'components/pc/types';
import ShapeTextBox from 'components/pc/common/shapes/ShapeTextBox';
import Shape from 'components/pc/common/shapes/Shape';
import ShapeNodeToolbar, {ToolName} from 'components/pc/common/shapes/Toolbar';
import {ColorResult} from 'react-color';
import {getRgbaFromColorResult} from 'components/pc/common/shapes/parts/svg/utils';
import {CONST} from 'components/pc/common/shapes/const';
import {getStrokeDashArr, makeHtml, rotateCalc} from 'components/pc/common/shapes/function';
import ShapeDataBindingText from 'components/pc/common/shapes/ShapeDataBindingText';
import {IDataBindingType, IShapeStyle, ShapeType} from 'components/pc/common/shapes/type';
import useShapeDataTimer from 'components/pc/common/shapes/useShapeDataTimer';
import classnames from 'classnames';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';

export type IProps = {
  type: ShapeType;
  color: string;
  metaData: any;
  updateData: any;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  cursor: move;
  &.frozen {
    pointer-events: none;
    cursor: default;
  }
`;

const RotateControl = styled.div<{$rotatable: string}>`
  display: ${(props) => (props.$rotatable === 'true' ? 'block' : 'none')};
  position: absolute;
  width: 10px;
  height: 10px;
  background: #3367d9;
  left: 50%;
  top: -50px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: alias;
  z-index: 5001;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 50px;
    background: #3367d9;
    left: 4px;
    top: 5px;
    z-index: 5001;
  }
`;

const useNodeDimensions = (id: string) => {
  const node = useStore((state) => state.nodeInternals.get(id));
  return {
    ...node
  };
};

function ShapeNode({id, selected, data}: NodeProps<IProps>) {
  const {isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;
  const {color, type, metaData} = data;
  const [rotation, setRotation] = useState<number>(metaData?.rotation || 0);
  const [shapeStyle, setShapeStyle] = useState<IShapeStyle>(
    type === 'Text' ? CONST.textShapeStyle : CONST.normalShapeStyle
  );
  const [dataBindingType, setDataBindingType] = useState<IDataBindingType>(CONST.dataBindingType);
  const [initialText, setInitialText] = useState('');
  const nodeDimensions = useNodeDimensions(id);
  const rotateControlRef = useRef(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const {setNodes} = useReactFlow();
  const [changeOption, tagDataInfo, apiLoading, blink, setTagDataInfo] = useShapeDataTimer(15000, id);

  useEffect(() => {
    if (metaData) {
      const {shapeStyle, rotation, dataBindingType, dataBindingTagDataInfo} = metaData as IShapeData;
      setShapeStyle(shapeStyle);
      setRotation(rotation);
      setInitialText(makeHtml(shapeStyle?.text));
      setDataBindingType(dataBindingType);
      changeOption(dataBindingType, dataBindingTagDataInfo);
      // setTagDataInfo()
    } else {
      if (type === 'Text') {
        setInitialText(makeHtml(['TEXT']));
      } else if (type === 'DataBinding') {
        changeOption(CONST.dataBindingType);
      }
      setNodes((nodes) =>
        nodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: {
                  metaData: {
                    ...node?.data?.metaData,
                    shapeStyle: type === 'Text' ? CONST.textShapeStyle : CONST.normalShapeStyle,
                    rotation: 0,
                    dataBindingType: CONST.dataBindingType
                  },
                  color,
                  type
                }
              }
            : node
        )
      );
    }
  }, []);

  useEffect(() => {
    if (data.updateData) {
      setDataBindingType(data.updateData);
      saveDataBindingType(data.updateData);
      changeOption(data.updateData);
    }
  }, [data]);

  useEffect(() => {}, [apiLoading, blink]);

  const saveShapeStyle = (shapeStyle: IShapeStyle) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? {...node, data: {metaData: {...node?.data?.metaData, shapeStyle}, color, type}} : node
      )
    );
  };

  const saveRotation = (rotation: number) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? {...node, data: {metaData: {...node?.data?.metaData, rotation}, color, type}} : node
      )
    );
  };

  const saveDataBindingType = (dataBindingType: IDataBindingType) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? {...node, data: {metaData: {...node?.data?.metaData, dataBindingType}, color, type}} : node
      )
    );
  };

  useEffect(() => {
    if (!rotateControlRef.current) {
      return;
    }
    const selection = select(rotateControlRef.current);
    const dragHandler = drag().on('drag', (evt) => {
      const rot = rotateCalc(id, evt);
      setRotation(rot);
      saveRotation(rot);
    });
    selection.call(dragHandler);
  }, [id, updateNodeInternals]);

  const onChangeStyle = (key: ToolName, value: string | string[] | number | number[] | ColorResult) => {
    let val: typeof value;
    switch (key) {
      case 'strokeWidth': {
        if (isNaN(value as number)) {
          return;
        }
        val = value;
        break;
      }
      default:
        val = value;
    }

    setShapeStyle((prev) => {
      const newStyle = {...prev, [key]: val};
      setTimeout(saveShapeStyle, 0, newStyle);
      return newStyle;
    });
  };

  const strokeDasharray = getStrokeDashArr(shapeStyle);

  return (
    <>
      <ShapeNodeToolbar type={type} shapeStyle={shapeStyle} id={id} selected={selected} onChangeStyle={onChangeStyle} />
      <Container
        className={classnames('shape' + id, blink && 'blink', {frozen: isFreeze})}
        style={{transform: `rotate(${rotation}deg)`}}
      >
        <RotateControl ref={rotateControlRef} $rotatable={selected.toString()} className="nodrag" />
        <Shape
          type={type}
          width={nodeDimensions.width}
          height={nodeDimensions.height}
          strokeWidth={shapeStyle?.strokeWidth}
          stroke={
            type === 'DataBinding'
              ? getRgbaFromColorResult(tagDataInfo?.borderColor || shapeStyle?.borderColor)
              : getRgbaFromColorResult(shapeStyle?.borderColor)
          }
          fill={
            type === 'DataBinding'
              ? getRgbaFromColorResult(tagDataInfo?.bgColor || shapeStyle?.backgroundColor)
              : getRgbaFromColorResult(shapeStyle?.backgroundColor)
          }
          strokeDasharray={strokeDasharray}
          isNode={true}
        />
        {type === 'DataBinding' ? (
          <ShapeDataBindingText
            selected={selected}
            shapeStyle={shapeStyle}
            dataBindingType={dataBindingType}
            tagDataInfo={tagDataInfo}
            onChangeStyle={onChangeStyle}
            blink={blink}
          />
        ) : (
          <ShapeTextBox
            selected={selected}
            shapeStyle={shapeStyle}
            initialText={initialText}
            onChangeStyle={onChangeStyle}
          />
        )}
      </Container>
      <NodeResizer color="rgb(255, 0, 113)" isVisible={selected} />
    </>
  );
}

export default memo(ShapeNode, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected && prevProps?.data?.updateData === nextProps?.data?.updateData;
});
