import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {faFolderOpen} from '@fortawesome/pro-light-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const Title = styled.div``;

type IProps = {
  title: string;
  onClick(): void;
};

function ProjectItem({title, onClick}: IProps): ReactElement {
  return (
    <Container onClick={onClick}>
      <FontAwesomeIcon icon={faFolderOpen as IconProp} size="2x" />
      <Title>{title}</Title>
    </Container>
  );
}

export default ProjectItem;
