import React, {ChangeEvent, ReactElement, KeyboardEvent, useContext, useEffect, useRef, useState} from 'react';
import Button from 'components/forms/Button';
import {getImage} from 'assets/images/svg-image';
import styled from 'styled-components';
import {ICopilotMessage} from 'components/menu/copilot/types';
import {UiOpenTypes} from './ChatHistory';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import VoiceCommand from 'components/menu/copilot/VoiceCommand';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  position: relative;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  //background-color: #964d4d;
  background-color: #f8f8f8;

  &.disabled {
    background-color: #c7c7c7ff;
  }

  .send-button {
    margin: auto 5px 4px 5px;
    width: 44px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    img {
      width: 25px;
      height: 35px;
      margin: 0;
    }
  }
`;

const VoiceModeBtn = styled.button`
  margin-top: auto;
  //margin-left: 5px;
  margin-bottom: 12px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;

const VoiceArea = styled.div`
  display: none;
  height: 100%;
  width: 334px;
  align-items: center;
  position: absolute;
  background: linear-gradient(to bottom, rgba(248, 248, 248, 0.9) 0%, rgba(248, 248, 248, 1) 100%);

  box-sizing: border-box;
  bottom: 0;
  right: 0;
  padding-bottom: 4px;

  &.show {
    display: flex;
    align-items: flex-end;
  }
`;

const InputText = styled.textarea`
  box-sizing: border-box;
  max-height: 300px;
  min-height: 54px;
  width: 100%;
  //padding: 15px 0 15px 15px; // voice 모드 해제. 일반 모드 일 때
  //padding: 15px 0 15px 4px; // voice mode 일 때
  font-size: 16px;
  border: none;
  resize: none;

  &.on {
    padding: 15px 0 15px 15px;
  }
  &.off {
    padding: 15px 0 15px 4px;
  }

  &::placeholder {
    color: #9f9f9f;
  }

  &:disabled {
    background-color: #c7c7c7ff;
  }
`;

const WaitingMessage = styled.div`
  padding: 8px 16px;
  color: #d5a96f;
  font-size: 14px;
`;

type IProps = {
  isLoading: boolean;
  onSend(payload: ICopilotMessage): void;
  setCheckHistoryBtn(checkHistoryBtn: boolean): void;
};

function ChatInput({isLoading, onSend, setCheckHistoryBtn}: IProps): ReactElement {
  const {showRemoteTooltip} = useContext(CommonContext);
  const {currentThread, isWaiting, voiceIsOn} = useContext(CopilotContext);
  const [isVoiceMode, setIsVoiceMode] = useState(false); //mode 변경 state
  const [isShowVoiceCommand, setIsShowVoiceCommand] = useState(false); // ui 변경 state
  const [isRecording, setIsRecording] = useState(false);
  const [text, setText] = useState('');
  const [point, setPoint] = useState(0);
  const textInputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (isWaiting) {
      const loadingPoint = setInterval(() => {
        setPoint((prevPoint) => (prevPoint + 1) % 4);
      }, 600);

      return () => clearInterval(loadingPoint);
    }
  }, [isWaiting]);

  const handleInputResizeHeight = () => {
    textInputRef.current.style.height = '0';
    textInputRef.current.style.height = textInputRef.current.scrollHeight + 'px';
  };

  useEffect(() => {
    handleInputResizeHeight();
  }, [text]);

  const onClickSend = () => {
    onSend({
      role: 'user',
      create_at: Date.now() / 1000,
      text: text,
      image: ''
    });
    if (isVoiceMode) {
      setIsShowVoiceCommand(true);
    }
    setText('');
  };

  const handleKey = (e: KeyboardEvent) => {
    if (isWaiting === false && text?.length > 0 && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
      onClickSend();
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const {value} = e.target;
    // enter 만 입력되었다면 입력하지 않음 (조건 추가할 수 있음)
    if (value === '\n') return;

    setText(value);
  };

  const onClickInputText = () => {
    setCheckHistoryBtn(false);
    // setMode('open');
  };

  const onClickVoiceMode = () => {
    try {
      navigator.permissions.query({name: 'microphone' as PermissionName}).then((result) => {
        if (result?.state !== 'granted') {
          // showRemoteTooltip('microphone');
          navigator.mediaDevices.getUserMedia({audio: true});
          throw new Error();
        }
        const bool = !isVoiceMode;
        setIsShowVoiceCommand(bool);
        setIsVoiceMode(bool);
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const onCompleteRecord = (message: string) => {
    setIsShowVoiceCommand(false);
    setText(`${text && text + '\n'}${message}`);
    textInputRef.current.focus();
  };

  // disabled 이후 focus 를 다시 부여
  useEffect(() => {
    if (!isLoading && currentThread) {
      textInputRef.current.focus();
    }
  }, [isLoading, currentThread]);

  return (
    <Container>
      {isWaiting && <WaitingMessage>Copilot is thinking {'.'.repeat(point)}</WaitingMessage>}
      <InputArea className={isLoading || currentThread === null ? 'disabled' : ''}>
        {voiceIsOn && (
          <VoiceModeBtn
            onClick={onClickVoiceMode}
            disabled={isWaiting || isRecording || isLoading || currentThread === null}
          >
            <img
              src={getImage('voiceMode', {color: isVoiceMode ? '#ee5c5c' : '#D0D3D4'})}
              className="voice-mode"
              alt="voice mode button"
            />
          </VoiceModeBtn>
        )}
        <InputText
          autoFocus
          rows={1}
          ref={textInputRef}
          className={voiceIsOn ? 'off' : 'on'}
          placeholder="Enter Message..."
          disabled={isLoading || currentThread === null}
          onChange={onChangeInput}
          value={text}
          onKeyDown={handleKey}
          onClick={onClickInputText}
        />
        <Button
          className="send-button"
          variant={isVoiceMode && voiceIsOn ? 'record' : 'primary'}
          disabled={isLoading || isWaiting || currentThread === null || text?.length === 0}
          onClick={onClickSend}
        >
          <img src={getImage(isLoading ? 'loading' : 'paperPlane', {color: 'white'})} alt="message button" />
        </Button>
        <VoiceArea className={isShowVoiceCommand && isWaiting === false && voiceIsOn === true && 'show'}>
          <VoiceCommand onCompleteRecord={onCompleteRecord} onRecording={setIsRecording} />
        </VoiceArea>
      </InputArea>
    </Container>
  );
}

export default ChatInput;
