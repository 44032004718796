/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/VesselVertical/VesselVertical.gltf 
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function VesselVertical() {
  const {nodes, materials} = useGltfCustom('/gltf/VesselVertical/VesselVertical.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._VesselVertical_1.geometry}
        material={materials.Simacro_VesselVertical}
        position={[12.8, 21.343, -58.706]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[0.511, 0.511, 0.526]}
      />
      <mesh
        geometry={nodes._VesselVertical_1.geometry}
        material={materials.Simacro_VesselVertical}
        position={[-9.152, 21.343, -72.359]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[0.511, 0.511, 0.526]}
      />
      <mesh
        geometry={nodes._VesselVertical_1.geometry}
        material={materials.Simacro_VesselVertical}
        position={[-39.5, 17, -64.3]}
        rotation={[Math.PI / 2, 0, -Math.PI]}
        scale={[0.311, 0.311, 0.326]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/VesselVertical/VesselVertical.gltf');
