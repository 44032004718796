import {
  Axis,
  AxisTickStrategies,
  ChartXY,
  ColorHEX,
  DashedLine,
  emptyFill,
  FillStyle,
  LineStyle,
  PointLineAreaSeries,
  PointSeries,
  SolidFill,
  StipplePatterns
} from '@lightningchart/lcjs';
import {IProfileSeries} from 'components/pc/widgets/profile-chart/types';
import {ITimeSeries} from 'components/pc/widgets/timeseries/types';

/**
 * chart series 등의 색상을 설정할 수 있는 객체 반환
 * @param color
 */
export const getSolidFill = (color: string): FillStyle => {
  return new SolidFill({color: ColorHEX(color)}) as unknown as FillStyle;
};

/**
 * graphType 에 따라 chart 에 표현하는 Series 를 반환
 * @param chart
 * @param axisY
 * @param channel
 */
export const getSeries = (
  chart: ChartXY,
  axisY: Axis,
  channel: ITimeSeries | IProfileSeries
): PointLineAreaSeries | PointSeries => {
  const {color, graphType, name} = channel;
  const lineStyle = getSolidFill(color) as FillStyle;
  let series: PointLineAreaSeries | PointSeries;

  switch (graphType) {
    case 'dashed':
      series = chart
        .addPointLineAreaSeries({axisY, dataPattern: 'ProgressiveX'})
        .setPointSize(0)
        .setPointFillStyle(getSolidFill(color))
        .setStrokeStyle(
          new DashedLine({
            thickness: 2,
            fillStyle: lineStyle,
            pattern: StipplePatterns.DottedDense,
            patternScale: 4
          }) as unknown as LineStyle
        )
        .setAreaFillStyle(emptyFill as FillStyle);
      break;

    case 'point':
      series = chart.addPointSeries({axisY}).setPointSize(5).setPointFillStyle(lineStyle);
      break;

    case 'area':
      series = chart
        .addPointLineAreaSeries({axisY, dataPattern: 'ProgressiveX'})
        .setPointSize(4)
        .setPointFillStyle(getSolidFill(color))
        .setStrokeStyle((stroke: LineStyle) => stroke.setFillStyle(emptyFill as FillStyle))
        .setAreaFillStyle(lineStyle);
      break;

    // case 'bar':
    // series = chart.addSegmentSeries({axisY});
    // break;

    case 'line':
    default:
      series = chart
        .addPointLineAreaSeries({axisY, dataPattern: 'ProgressiveX'})
        .setPointSize(4)
        .setPointFillStyle(getSolidFill(color))
        .setStrokeStyle((stroke: LineStyle) => stroke.setFillStyle(lineStyle))
        .setAreaFillStyle(emptyFill as FillStyle);
  }

  return series.setName(name).setEffect(undefined);
};

/**
 * yAxis 의 이름을 반환
 * @param nodes
 * @param channelMinHeight
 */
export const getYAxisName = (nodes: string[], channelMinHeight?: number): string => {
  let str = nodes[nodes.length - 1];
  if (channelMinHeight < 100) {
    str = str.substring(0, Math.floor(channelMinHeight / 10)) + '⋯';
  }
  return str;
};

/**
 * chart 의 뒷 배경색 설정
 * @param chartObject
 * @param backgroundColor
 */
export const applyBackground = (chartObject: ChartXY, backgroundColor: string): void => {
  const backgroundStyle = getSolidFill(backgroundColor);
  chartObject.setBackgroundFillStyle(backgroundStyle);
};

/**
 * axisX 축의 text 색상 3가지 설정
 * @param axis
 * @param textColor
 */
export const setAxisXTextColor = (axis: Axis, textColor: string): Axis => {
  const textStyle = getSolidFill(textColor);
  return axis.setTickStrategy(AxisTickStrategies.DateTime, (ticks) =>
    ticks
      .setMajorTickStyle((major) => major.setLabelFillStyle(textStyle))
      .setMinorTickStyle((minor) => minor.setLabelFillStyle(textStyle))
      .setGreatTickStyle((great) => great.setLabelFillStyle(textStyle))
  );
};

/**
 * axisY 축의 text 색상 2가지 설정
 * @param axis
 * @param textColor
 */
export const setAxisYTextColor = (axis: Axis, textColor: string): Axis => {
  const textStyle = getSolidFill(textColor);
  return axis.setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
    ticks
      .setMajorTickStyle((major) => major.setLabelFillStyle(textStyle))
      .setMinorTickStyle((minor) => minor.setLabelFillStyle(textStyle))
  );
};
