import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import WeatherForecastDailyResult from 'components/pc/widgets/weather/WeatherForecastDailyResult';
import WeatherForeCastHourlyResult from 'components/pc/widgets/weather/WeatherForecastHourlyResult';
import {ISizeType} from 'components/common/types';
import classNames from 'classnames';

const Container = styled.div`
  margin-top: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.Blue {
    color: #fff;
  }
  &.White {
    color: #525f7f;
  }
  &.Pink {
    color: #fff;
  }
  &.Yellow {
    color: #fff;
  }

  &.Green {
    color: #fff;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: 2%;
  margin-bottom: 0.8%;

  &.md {
    font-size: 16px;
  }
  &.sm {
    font-size: 14px;
  }
`;

const TitleButton = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: 2%;
  margin-bottom: 0.8%;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    color: #1e56e4;
  }

  &.md {
    font-size: 16px;
  }
  &.sm {
    font-size: 14px;
  }
`;

const WeatherForcastHourlyContent = styled.div`
  display: flex;
  width: 100%;
  color: #525f7f;
  overflow-x: auto;
  gap: 10px;
  padding-bottom: 10px;

  &.Blue {
    color: #fff;
  }

  &.White {
    color: #525f7f;
  }

  &.Pink {
    color: #fff;
  }

  &.Yellow {
    color: #fff;
  }

  &.Green {
    color: #fff;
  }
`;

const WeatherForecastDailyWrap = styled.div`
  margin-bottom: 2%;
  margin-top: 2%;
`;

const WeatherForeCastDailyContent = styled.div`
  width: 100%;
  height: 88%;
  color: #525f7f;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.md {
    gap: 10px;
  }
  &.sm {
    gap: 8px;
  }

  &.Blue {
    color: #fff;
  }
  &.White {
    color: #525f7f;
  }
  &.Pink {
    color: #fff;
  }
  &.Yellow {
    color: #fff;
  }
  &.Green {
    color: #fff;
  }
`;

type IProps = {
  color: string;
  hourlyCheck(): void;
  hourlyOpen: boolean;
  darkModeChange: boolean;
  forecastData: IWeatherForcastData;
  fontSize: string;
  isCelsius: boolean;
  size: ISizeType;
  dailyOpen: boolean;
  dailyCheck(): void;
};
function WeatherForeCast({
  color,
  hourlyCheck,
  hourlyOpen,
  darkModeChange,
  forecastData,
  fontSize,
  isCelsius,
  size,
  dailyOpen,
  dailyCheck
}: IProps) {
  const WeatherForeCastHourlyResultLength = new Array(14).fill(0);
  const WeatherForeCastDailyResultLength = new Array(7).fill(0);

  return (
    <Container className={color}>
      <div>
        <TitleWrap>
          <Title className={size.width}>Today</Title>
          <TitleButton className={size.width} onClick={hourlyCheck}>
            {hourlyOpen ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
          </TitleButton>
        </TitleWrap>
        {hourlyOpen && (
          <WeatherForcastHourlyContent className={classNames('thin-scrollbar')}>
            {WeatherForeCastHourlyResultLength.map((item, idx) => (
              <WeatherForeCastHourlyResult
                idx={idx}
                forecastData={forecastData}
                fontSize={fontSize}
                isCelsius={isCelsius}
                size={size}
                key={idx}
              />
            ))}
          </WeatherForcastHourlyContent>
        )}
      </div>
      <WeatherForecastDailyWrap style={!dailyOpen ? {height: '40%'} : {}}>
        <TitleWrap>
          <Title className={size.width}>Next 7 days</Title>
          <TitleButton className={size.width} onClick={dailyCheck}>
            {dailyOpen ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
          </TitleButton>
        </TitleWrap>
        {dailyOpen && (
          <WeatherForeCastDailyContent className={size.width} style={!darkModeChange ? {color: '#FFF'} : {}}>
            {WeatherForeCastDailyResultLength.map((item, idx) => (
              <WeatherForecastDailyResult
                i={idx}
                forecastData={forecastData}
                fontSize={fontSize}
                isCelsius={isCelsius}
                darkModeChange={darkModeChange}
                key={idx}
                size={size}
              />
            ))}
          </WeatherForeCastDailyContent>
        )}
      </WeatherForecastDailyWrap>
    </Container>
  );
}

export default WeatherForeCast;
