import styled from 'styled-components';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Button} from 'components/forms';
import {capitalize} from 'utils/commons';
import classnames from 'classnames';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {IManagementAsideInfo, IEditRoleInfo} from 'components/auth/management/type';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;

const InputContainer = styled.div`
  input,
  textarea {
    border: 1px solid #b6b4c0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px 12px;
    &::placeholder {
      color: #b6b4c0;
    }
    &.disabled {
      cursor: not-allowed;
      filter: brightness(0.92);
    }
  }

  input {
    height: 40px;
  }
  textarea {
    height: 160px;
    resize: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  .common-button {
    font-weight: 600;
    color: white;
  }
`;

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.color.invalid} !important;
  font-weight: 400;
  font-size: 15px;
`;

type IProps = {
  asideInfo: IManagementAsideInfo;
  activateCancelButton?: boolean;
  onClose(): void;
  onClickCreate(name: string, description: string): void;
  onClickDelete?(name: string, description: string): void;
};

function RoleManagementAside({asideInfo, activateCancelButton = true, onClickCreate, onClose, onClickDelete}: IProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const textareaRef = useRef(null);

  useModalKeyBoardEvent({
    onConfirm: () => {
      const activeElement = document.activeElement;
      if (textareaRef.current !== activeElement) {
        onClickCreateBtn();
      }
    },
    onCancel: () => onClose()
  });

  useEffect(() => {
    if (asideInfo?.info) {
      const groupInfo = asideInfo.info as IEditRoleInfo;
      if ('roleName' in groupInfo) setName(groupInfo.roleName || '');
      if ('description' in groupInfo) setDescription(groupInfo.description || '');
    }
  }, [asideInfo]);

  const onClickCreateBtn = () => {
    if (!name) return;
    onClickCreate(name, description);
  };

  const onClickDeleteBtn = () => {
    if (!name) return;
    onClickDelete(name, description);
  };

  const onClickCancelBtn = () => {
    onClose();
  };

  const onChangeName = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setName(value);
  };

  const onChangeDescription = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setDescription(value);
  };

  return (
    <>
      <Wrapper>
        <InputContainer>
          <input
            className={classnames(Boolean(asideInfo?.type === 'edit') && 'disabled')}
            onChange={onChangeName}
            value={name}
            placeholder="Role name"
            disabled={Boolean(asideInfo?.type === 'edit')}
          />
        </InputContainer>
        <InputContainer>
          <textarea
            ref={textareaRef}
            onChange={onChangeDescription}
            value={description}
            placeholder="Role description"
          />
        </InputContainer>
        <ErrorMessage>{asideInfo?.errorMsg}</ErrorMessage>
      </Wrapper>
      <ButtonContainer>
        {asideInfo?.deleteLabel && (
          <Button variant="danger" className={asideInfo?.deleteLabel} onClick={onClickDeleteBtn}>
            {capitalize(asideInfo?.deleteLabel)}
          </Button>
        )}
        <Button
          width={108}
          variant={asideInfo?.createVariant}
          className={asideInfo?.createLabel}
          onClick={onClickCreateBtn}
        >
          {capitalize(asideInfo?.createLabel)}
        </Button>
        {activateCancelButton && (
          <Button variant={'cancel'} className={'cancel'} onClick={onClickCancelBtn}>
            {capitalize('Cancel')}
          </Button>
        )}
      </ButtonContainer>
    </>
  );
}

export default RoleManagementAside;
