import styled from 'styled-components';
import {ReactNode} from 'react';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import classnames from 'classnames';

type StyleProps = {
  $imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  $iw: number;
  $ih: number;
  $mat?: any;
};

const Container = styled.div<StyleProps>`
  position: absolute;
  top: ${(props) => props.$imagePosition.topLeft.y}px;
  left: ${(props) => props.$imagePosition.topLeft.x}px;

  //z-index: ${META_PFD_zINDEX.TABLE_LAYER};
  width: ${(props) => props.$iw}px;
  height: ${(props) => props.$ih}px;
`;

const ViewerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type IProps = {
  children: ReactNode;
  mat?: any;
  layoutChanging?: boolean;
};

function MetaPfdWidgetLayerWrapper({children, layoutChanging}: IProps) {
  return (
    <ViewerContainer className={classnames(layoutChanging && 'layoutChanging', 'layer-wrapper')}>
      {children}
    </ViewerContainer>
  );
}

export default MetaPfdWidgetLayerWrapper;
