import {RadioFormGroup} from 'components/forms';
import {PortType} from 'components/mpfd/panel/const';
import PropertyRow from 'components/mpfd/panel/parts/property/PropertyRow';
import {ChangeEvent, ReactNode, useContext} from 'react';
import {IAnnotation} from 'components/mpfd/type';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

type IProps = {
  selectedAnnot: IAnnotation;
  children: ReactNode;
};

function PortProperty({selectedAnnot, children}: IProps) {
  const {dispatchToReducer} = useContext(MetaPfdContext);

  const onChangePortType = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    dispatchToReducer({type: 'CHANGE_REGION', region: {...selectedAnnot, portType: target.value}});
  };

  return (
    <>
      <PropertyRow rowHeader="Name">{children}</PropertyRow>
      <PropertyRow rowHeader="Port type">
        <RadioFormGroup
          name="Port type"
          options={PortType}
          value={selectedAnnot?.portType}
          onChange={onChangePortType}
        />
      </PropertyRow>
    </>
  );
}

export default PortProperty;
