import {Dispatch, MouseEvent, SetStateAction, useState} from 'react';

type IReturn = {
  isFrameless: boolean;
  isWidgetMouseEnter: boolean;
  setIsFrameless: Dispatch<SetStateAction<boolean>>;
  onMouseEnter(e: MouseEvent): void;
  onMouseLeave(e: MouseEvent): void;
  onFrameless?(bool: boolean): void;
};

function useFrameless(defaultValue = false): IReturn {
  const [isFrameless, setIsFrameless] = useState(defaultValue);
  const [isWidgetMouseEnter, setIsWidgetMouseEnter] = useState(defaultValue);

  const onMouseEnter = (e: MouseEvent): void => {
    setIsWidgetMouseEnter(true);
  };

  const onMouseLeave = (e: MouseEvent): void => {
    setIsWidgetMouseEnter(false);
  };

  const onFrameless = (bool: boolean): void => {
    setIsFrameless(bool);
  };

  return {isFrameless, isWidgetMouseEnter, setIsFrameless, onMouseEnter, onMouseLeave, onFrameless};
}

export default useFrameless;
