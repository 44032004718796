import {PropsWithChildren} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import AccordionItem from 'components/common/arrocdion/AccordionItem';
import {IAccordionItem} from 'components/pc/widgets/modelRunner/ModelRunnerResult';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #f2f2f2;
  overflow-y: auto;
`;

type IProps = PropsWithChildren & {
  options?: IAccordionItem[];
};

function NewAccordion({options}: IProps) {
  return (
    <Container className={classnames('thin-scrollbar md')}>
      {options?.map((item, index) => <AccordionItem key={item?.title} data={item} />)}
    </Container>
  );
}

export default NewAccordion;
