import React, {ChangeEvent, PropsWithChildren, ReactElement} from 'react';
import {IProfileChartLoader, ISeriesPointData} from 'components/pc/widgets/profile-chart/types';
import styled from 'styled-components';
import ProfileChartSeriesItem from 'components/pc/widgets/profile-chart/ProfileChartSeriesItem';
import {Button} from 'components/forms';
import {INode} from 'api/data-types';
import {IChartStackInfo, SeriesGraphTypes} from 'components/pc/widgets/common/chart/types';
import {faLayerPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Container = styled.div`
  height: 100%;
  //background-color: #b0b3b7;
  background-color: #cbced2;
  display: flex;
  flex-direction: column;
`;
const ListHeader = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: #eceff1;
  box-sizing: border-box;
  padding: 10px;
  box-shadow:
    0 3px 10px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3);
`;
const ListBody = styled.div`
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
`;
const EmptySeries = styled.div`
  background-color: rgba(227, 228, 236, 0.5);
  border: 1px solid #a5a7b9;
  margin: auto;
  padding: 5px 20px;
  font-size: 12px;
  color: #9496a6;
`;

type IProps = PropsWithChildren & {
  loader: IProfileChartLoader;
};

function ProfileChartSeriesList({loader}: IProps): ReactElement {
  const onClickAddSeries = (): void => {
    loader.addSeries();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value, title, name, id, checked} = e.target;
    const {series, refreshSeries, setStackInfo} = loader;
    const found = series.find((item) => item.key === name);

    console.log('>>>', value, title, name, found);
    switch (title) {
      case 'active':
        found.active = checked;
        break;
      case 'name':
        found.name = value;
        break;
      case 'color':
        found.color = value;
        break;
      case 'parallel':
        found.parallel = Number(value);
        const reduced: IChartStackInfo[] = series.reduce((acc, cur) => {
          if (!acc.some((info) => info.parallel === cur.parallel)) {
            acc.push({parallel: cur.parallel, stacked: true, checked: true});
          }
          return acc;
        }, []);
        setStackInfo(reduced);
        break;
      case 'graph':
        found.graphType = value as SeriesGraphTypes;
        break;
      default:
        // case 가 없으면 변경이 없는 것으로 간주하고 바로 중단. 즉 updateSeries() 를 호출하지 않음
        return;
    }
    refreshSeries();
  };

  const onChangeSeriesData = (id: string, seriesData: ISeriesPointData[], nodeList: INode[]): void => {
    const {series, refreshSeries} = loader;
    const found = series.find((item) => item.key === id);
    if (found) {
      found.data = seriesData;
      found.nodeList = nodeList;
      refreshSeries();
    }
  };

  const onDelete = (id: string): void => {
    loader.removeSeries(id);
  };

  const onOpen = (id: string, bool: boolean): void => {
    const {series, refreshSeries} = loader;
    const found = series.find((item) => item.key === id);
    if (found) {
      found.opened = bool;
      refreshSeries();
    }
  };

  return (
    <Container>
      <ListHeader>
        <Button width={180} height={34} onClick={onClickAddSeries}>
          <FontAwesomeIcon icon={faLayerPlus} size="lg" /> Add Y Axis Series
        </Button>
      </ListHeader>
      <ListBody className="thin-scrollbar md">
        {loader.series.map((item) => (
          <ProfileChartSeriesItem
            key={item.key}
            series={item}
            loader={loader}
            onOpen={onOpen}
            onChange={onChange}
            onChangeSeriesData={onChangeSeriesData}
            onDelete={onDelete}
          />
        ))}
        {loader.series.length === 0 && <EmptySeries>Empty Series</EmptySeries>}
      </ListBody>
    </Container>
  );
}

export default ProfileChartSeriesList;
