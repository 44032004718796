import React from 'react';
import {useParams} from 'react-router-dom';
import PasswordRecoverRequest from 'components/auth/login/PasswordRecoverRequest';
import PasswordReset from 'components/auth/login/PasswordReset';
import PasswordResetComplete from 'components/auth/login/PasswordResetComplete';

const PasswordComponents = {
  recover: PasswordRecoverRequest,
  reset: PasswordReset,
  complete: PasswordResetComplete
};

function PasswordForm() {
  const {detail} = useParams();
  const PasswordComponent = PasswordComponents[detail];
  if (PasswordComponent) {
    return <PasswordComponent />;
  } else {
    return null;
  }
}

export default PasswordForm;
