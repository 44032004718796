import styled from 'styled-components';
import DataConnectorTagSelector from 'components/pc/node-selector/DataConnectorNodeSelector';
import {ChangeEvent, useEffect, useState} from 'react';
import {IDataBindingType} from 'components/pc/common/shapes/type';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 38px;
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
`;
const Half = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
  font-size: 15px;
  font-weight: 400;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  input {
    border: 1px solid #b6b4c0;
    border-radius: 5px;
    height: 20px;
    width: 292px;
    padding: 5px 9px;
    background-color: transparent;
  }
`;

type IProps = {
  copy: IDataBindingType;
  onChangeCopy(copyD: Partial<IDataBindingType>): void;
};

function TagData({copy, onChangeCopy}: IProps) {
  const [selectedTag, setSelectedTag] = useState<string[][]>([copy?.tagData?.hierarchyInfo]);

  const onClose = () => {};

  const onSelect = (tag: string[][]) => {
    const singleTag = tag[0];
    if (singleTag) {
      onChangeCopy({tagData: {hierarchyInfo: singleTag, name: singleTag.join('-')}});
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    onChangeCopy({tagData: {...copy?.tagData, name: value}});
  };

  return (
    <Container>
      <Half>
        <DataConnectorTagSelector
          selectedTags={selectedTag}
          categoryList={['Model', 'Plant', 'Weather', 'Commodity']}
          onClose={onClose}
          onSelect={onSelect}
        />
      </Half>
      <Half>
        <Row>
          <div>Tag data info :</div>
          <div>{[...(copy?.tagData?.hierarchyInfo || [])].join('-')}</div>
        </Row>
        <Row>
          <div>Tag name :</div>
          <div>
            <input onChange={onChange} value={copy?.tagData?.name || ''} />
          </div>
        </Row>
      </Half>
    </Container>
  );
}

export default TagData;
