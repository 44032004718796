import {ReactElement} from 'react';
import styled from 'styled-components';
import NameTag from 'components/auth/management/parts/NameTag';
import {faTrashCan} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export type NameTypes = 'group' | 'role';

type IProps = {
  parentName?: string;
  name: string;
  nameType: NameTypes;
  description?: string;
  isEdit: boolean;
  onClickDeleteAssigned?: (role_name: string, group_name: string) => void;
};

const Container = styled.div`
  height: fit-content;
  box-sizing: border-box;
  border: 1px solid #b6b4c0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 10px;
`;

const Description = styled.p`
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 0;
`;

const DeleteAssignBtn = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 40%;
  svg {
    cursor: pointer;
    color: #575660;
  }
`;

function AssignedItem({
  parentName,
  name,
  nameType,
  description,
  isEdit = false,
  onClickDeleteAssigned
}: IProps): ReactElement {
  const onClickDeleteAssignedItem = () => {
    onClickDeleteAssigned(name, parentName);
  };

  return (
    <Container>
      <NameTag type={nameType}> {name} </NameTag>
      <Description> {description} </Description>
      {isEdit && (
        <DeleteAssignBtn>
          <FontAwesomeIcon icon={faTrashCan} size="lg" onClick={onClickDeleteAssignedItem} />
        </DeleteAssignBtn>
      )}
    </Container>
  );
}

export default AssignedItem;
