import React, {useContext, useRef} from 'react';
import styled, {useTheme} from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';
import ApiHostTypeDisplay from 'components/common/ApiHostTypeDisplay';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/pro-light-svg-icons';
import {Button} from 'components/forms';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const Wrapper = styled.div`
  width: 800px;
  height: 400px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.1);
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 34px;

  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 32px;
    margin-bottom: 10px;
  }
`;

const Content = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: ${({theme}) => theme.color.dark};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 0;

  span {
    color: ${({theme}) => theme.color.public};
    margin-bottom: 20px;

    &.water-mark {
      margin-top: 12px;
      font-size: 14px;
      color: #760aec;
      margin-bottom: 0;
    }
  }
`;

const RightSide = styled.div`
  width: 467px;
  height: 400px;
  display: flex;
  align-items: flex-start;

  img {
    border-radius: 0 10px 10px 0;
    position: absolute;
  }

  .close-button {
    z-index: 10;
    position: relative;
    margin-left: auto;
    padding: 10px;
    font-size: 16px;
  }
`;

function SplashModal() {
  const theme = useTheme();
  const {splashModal, hideSplashModal, version} = useContext(CommonContext);
  const modalRef = useRef<HTMLDivElement>(null);

  const onCLoseModal = (e) => {
    if (modalRef.current === e.target) {
      hideSplashModal();
    }
  };

  if (splashModal) {
    return (
      <Container ref={modalRef} onClick={(e) => onCLoseModal(e)}>
        <Wrapper>
          <LeftSide>
            <h2>{splashModal?.title}</h2>
            <Content className="thin-scrollbar">{splashModal?.content}</Content>
            <Footer>
              <span>ver {version}</span>
              <ApiHostTypeDisplay />
              <span className="water-mark">© All rights reserved by SIMACRO</span>
            </Footer>
          </LeftSide>
          <RightSide>
            {splashModal?.image}
            <Button variant="none" className="close-button" onClick={hideSplashModal}>
              <FontAwesomeIcon icon={faClose} size="xl" color={theme.color.light} />
            </Button>
          </RightSide>
        </Wrapper>
      </Container>
    );
  } else {
    return undefined;
  }
}

export default SplashModal;
