import {ForwardedRef, forwardRef, PropsWithChildren} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Container = styled.div`
  max-height: calc(100vh - 140px);
  //overflow-y: auto;

  padding: 10px 0;
  color: #575660;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1.6em;
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
`;
const Contents = styled.div`
  margin: auto;

  &.top {
    margin-top: 0;
  }
  &.full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &.default {
    max-width: 520px;
  }
  &.depends-contents {
    max-width: initial;
  }
`;

type IProps = PropsWithChildren & {
  align?: 'center' | 'top';
  size?: 'default' | 'full-width' | 'depends-contents';
  className?: string;
  height?: number;
};

const ModalBody = forwardRef(function ModalBody(
  {children, align = 'center', size = 'default', className, height}: IProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  return (
    <Container ref={ref}>
      <Contents style={{height: height}} className={classNames(align, size, className)}>
        {children}
      </Contents>
    </Container>
  );
});

ModalBody.displayName = 'ModalBody';
export default ModalBody;
