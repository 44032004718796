import React, {ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CommonContext, INotice} from 'components/common/CommonProvider';
import NoticeItem from 'components/common/notice/NoticeItem';

const Container = styled.div`
  background-color: rgba(0, 0, 100, 0);
  width: 400px;
  height: 100%;
  margin-left: auto;
  overflow: hidden;
  transition: background-color 0.4s;

  &.active {
    background-color: rgba(0, 0, 100, 0.2);
  }
`;

function NoticeDisplay(): ReactElement {
  const {notice} = useContext(CommonContext);
  const [noticeList, setNoticeList] = useState<INotice[]>([]);

  useEffect(() => {
    if (!notice) return;
    // local 목록에 추가
    setNoticeList((prevState) => [...prevState, notice]);
  }, [notice]);

  const onFinish = (id: string): void => {
    // console.log('onFinish,', id);
    setNoticeList((prevState) => prevState.filter((item) => item.id !== id));
  };

  return (
    <Container className={noticeList.length > 0 && 'active'}>
      {noticeList.map((item, index) => (
        <NoticeItem key={item.id} data={item} isShow={index === 0} onFinish={onFinish} />
      ))}
    </Container>
  );
}

export default NoticeDisplay;
