import React from 'react';
import {useParams} from 'react-router-dom';
import RegisterRequest from 'components/auth/login/RegisterRequest';
import RegistrationComplete from 'components/auth/login/RegistrationComplete';

export const RegisterComponents = {
  register: RegisterRequest,
  complete: RegistrationComplete
};

function RegisterForm() {
  const {detail} = useParams();
  const RegisterComponent = RegisterComponents[detail];

  if (RegisterComponent) {
    return <RegisterComponent />;
  } else {
    return <RegisterRequest />;
  }
}

export default RegisterForm;
