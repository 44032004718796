import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import WeatherHistoryDailyResult from 'components/pc/widgets/weather/WeatherHistoryDailyResult';
import {ISizeType} from 'components/common/types';

const Container = styled.div`
  margin-bottom: 2%;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: 2%;
  margin-bottom: 0.8%;
  &.md {
    font-size: 16px;
  }
  &.sm {
    font-size: 14px;
  }
`;
const TitleBtn = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: 2%;
  margin-bottom: 0.8%;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    color: #1e56e4;
  }
  &.md {
    font-size: 16px;
  }
  &.sm {
    font-size: 14px;
  }
`;
const List = styled.div`
  width: 100%;
  height: 88%;
  color: #525f7f;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.md {
    gap: 10px;
  }
  &.sm {
    gap: 8px;
  }
`;

type IProps = {
  historyDailyCheck(): void;
  historyDailyOpen: boolean;
  historyData: IWeatherHistoryData;
  fontSize: string;
  isCelsius: boolean;
  size: ISizeType;
  darkModeChange: boolean;
};

function WeatherHistory({
  historyDailyCheck,
  historyDailyOpen,
  historyData,
  fontSize,
  isCelsius,
  size,
  darkModeChange
}: IProps) {
  const weatherHistoryLength = new Array(7).fill(0);
  return (
    <Container>
      <TitleWrap>
        <Title className={size.width}>Previous 7 days</Title>
        <TitleBtn className={size.width} onClick={historyDailyCheck}>
          {historyDailyOpen === true ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
        </TitleBtn>
      </TitleWrap>
      {historyDailyOpen && (
        <List className={size.width}>
          {weatherHistoryLength.map((item, idx) => (
            <WeatherHistoryDailyResult
              idx={idx}
              darkModeChange={darkModeChange}
              historyData={historyData}
              fontSize={fontSize}
              isCelsius={isCelsius}
              size={size}
              key={idx}
            />
          ))}
        </List>
      )}
    </Container>
  );
}

export default WeatherHistory;
