import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/pro-light-svg-icons';

const Container = styled.div`
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(140, 178, 250, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  border: 4px dashed #8cb2fa;
  box-sizing: border-box;
  color: #5d7cb7;
  gap: 0;
`;

const Text = styled.div`
  font-size: 20px;
`;

type IProps = {
  isOver: boolean;
};

function DropZoneOverlay({isOver}: IProps) {
  if (isOver) {
    return (
      <Container className="dropzone">
        <FontAwesomeIcon icon={faDownload} size="2xl" />
        <Text>Drop Here</Text>
      </Container>
    );
  } else {
    return undefined;
  }
}

export default DropZoneOverlay;
