import React, {ReactElement, useState} from 'react';
import {SwitchForm} from 'components/forms';
import ConfirmModal from 'components/common/ConfirmModal';

type IProps = {
  username: string;
  isActive: boolean;
  useConfirmModal?: boolean;
  setIsActive(isActive: boolean): void;
};

function AdminActivateUserSwitchFrom({username, isActive, useConfirmModal = true, setIsActive}: IProps): ReactElement {
  const [isActivated, setIsActivated] = useState(isActive || false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onChangeSwitchForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenConfirmModal(true);
  };

  const onChangeAdminActivateUserSwitchBtn = () => {
    setIsActive(!isActivated);
    setIsActivated(!isActivated);
    setOpenConfirmModal(false);
  };

  return (
    <>
      {openConfirmModal && (
        <ConfirmModal
          confirmHeaderMessage={'Activate User'}
          confirmBodyMessage={'Click confirm to ' + (isActivated ? 'deactivate' : 'activate') + ' user'}
          onConfirm={onChangeAdminActivateUserSwitchBtn}
          setOpenConfirmModal={setOpenConfirmModal}
        />
      )}
      <SwitchForm
        name={'activate-user-switch-' + username}
        id={'activate-user-switch-' + username}
        label={''}
        onChange={onChangeSwitchForm}
        checked={isActivated}
      ></SwitchForm>
    </>
  );
}
export default AdminActivateUserSwitchFrom;
