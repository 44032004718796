import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div``;

type IProps = PropsWithChildren & {
  width?: number;
  height?: number;
};

function TabContent({children, width, height}: IProps): ReactElement {
  return <Container style={{width, height}}>{children}</Container>;
}

export default TabContent;
