import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {IDataBindingTagDataInfo, IDataBindingType} from 'components/pc/common/shapes/type';
// import {getTagInfo} from 'components/pc/common/shapes/function';
import {useReactFlow} from 'reactflow';
import useApi from 'api/useApi';
import {IApiReturnBasic} from 'api/data-types';

type IApiInfo = {
  apiFunc(): Promise<IDataBindingTagDataInfo>;
};

function useShapeDataTimer(
  initialInterval: number,
  id: string
): [
  (newOption: IDataBindingType, loadedTagDataInfo?: IDataBindingTagDataInfo) => void,
  IDataBindingTagDataInfo,
  boolean,
  boolean,
  Dispatch<SetStateAction<IDataBindingTagDataInfo>>
] {
  const [option, setOption] = useState<IDataBindingType>();
  const [singleQue, setSingleQue] = useState<IApiInfo>();
  const [activate, setActivate] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(initialInterval);
  const [callComplete, setCallComplete] = useState({});
  const [tagDataInfo, setTagDataInfo] = useState<IDataBindingTagDataInfo>();
  const [apiLoading, setApiLoading] = useState(true);
  const [flash, setFlash] = useState(false);
  const api = useApi();

  const apiQueueRef = useRef(singleQue);
  apiQueueRef.current = singleQue;
  const optionRef = useRef(option);
  optionRef.current = option;

  const {setNodes} = useReactFlow();

  useEffect(() => {
    if (activate) {
      const timer = setTimeout(execute, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, activate, callComplete, singleQue]);

  const updateNodeMetaData = (dataBindingTagDataInfo: IDataBindingTagDataInfo) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {...node?.data?.metaData, dataBindingTagDataInfo}
              }
            }
          : node
      )
    );
  };

  const getNodeInfo = async (dbt: IDataBindingType) => {
    const [database, ...rest] = dbt?.tagData?.hierarchyInfo;

    return api
      .post<IApiReturnBasic>('/node_manage/get_node_data', {
        node: rest,
        database,
        latest_count: 1
      })
      .then(function (res) {
        const d = res.data as any;
        const now = Math.floor(+new Date() / 1000);
        let tagValue = Number(d?.records?.[0]?.[1]);
        let bgColor = null;
        let fontColor = null;
        let borderColor = null;
        for (let i = 0; i < dbt.liveDisplay.list.length; i++) {
          const condition = dbt.liveDisplay.list[i];
          if (
            tagValue <
              Number(condition?.max === 0 ? Number.MAX_VALUE : condition?.max ? condition?.max : Number.MAX_VALUE) &&
            tagValue > (Number(condition?.min) || 0)
          ) {
            bgColor = condition?.bgColor;
            fontColor = condition?.fontColor;
            borderColor = condition?.borderColor;
            break;
          }
        }

        const tdi: IDataBindingTagDataInfo = {
          tagHierarchy: dbt?.tagData?.hierarchyInfo,
          tagName: dbt?.tagData?.name,
          // tagUnit: unit as string,
          tagUnit: null, // 나중에 살려야함 현재 node unit  계산 하는 부분 없음
          tagValue: tagValue,
          bgColor: bgColor,
          fontColor: fontColor,
          borderColor: borderColor,
          updateTime: now
        };
        return tdi;
      });
  };

  const fetchAndSetTagDataInfo = async (newOption: IDataBindingType) => {
    try {
      const res = await getNodeInfo(newOption);
      setTagDataInfo(res);
      updateNodeMetaData(res);
      setApiLoading(false);
      return res;
    } catch (error) {
      console.error('Failed to fetch tag data info', error);
      setApiLoading(false);
      return null;
    }
  };

  const handleTagInfoChange = async (newOption: IDataBindingType, loadedTagDataInfo?: IDataBindingTagDataInfo) => {
    const apiFunc = async () => getNodeInfo(optionRef.current);
    setApiLoading(true);
    setSingleQue({apiFunc});
    setOption(newOption);

    if (loadedTagDataInfo) {
      setTagDataInfo(loadedTagDataInfo);
      updateNodeMetaData(loadedTagDataInfo);
      setApiLoading(false);
      if (newOption.options.isAutoUpdate) {
        return fetchAndSetTagDataInfo(newOption);
      }
      return loadedTagDataInfo;
    } else {
      return fetchAndSetTagDataInfo(newOption);
    }
  };

  const changeOption = async (newOption: IDataBindingType, loadedTagDataInfo?: IDataBindingTagDataInfo) => {
    if (
      newOption?.options?.updateIntervalUnit * newOption?.options?.updateIntervalVal !==
      option?.options?.updateIntervalUnit * option?.options?.updateIntervalVal
    ) {
      setDuration(newOption?.options?.updateIntervalUnit * newOption?.options?.updateIntervalVal);
    }

    if (newOption?.options?.isAutoUpdate !== option?.options?.isAutoUpdate) {
      setActivate(newOption?.options?.isAutoUpdate);
    }

    if (newOption?.tagData?.hierarchyInfo?.join('-') !== option?.tagData?.hierarchyInfo?.join('-')) {
      if (newOption?.tagData?.hierarchyInfo?.join('-') === '') {
        setSingleQue(null);
        setApiLoading(false);
        return;
      }

      const tagDataInfoTemp = await handleTagInfoChange(newOption, loadedTagDataInfo);

      if (newOption?.options?.isBlinkWhenUpdate) {
        setFlash(true);
        setTimeout(() => setFlash(false), 200);
      }

      const tagValue = tagDataInfoTemp?.tagValue ? Number(tagDataInfoTemp?.tagValue) : Number(tagDataInfo?.tagValue);
      if (isNaN(tagValue)) return;

      let bgColor = null;
      let fontColor = null;
      let borderColor = null;
      for (let i = 0; i < newOption.liveDisplay.list.length; i++) {
        const condition = newOption.liveDisplay.list[i];
        if (tagValue < Number(condition?.max || Number.MAX_VALUE) && tagValue > (Number(condition?.min) || 0)) {
          bgColor = condition?.bgColor;
          fontColor = condition?.fontColor;
          borderColor = condition?.borderColor;
          break;
        }
      }

      setTagDataInfo((prev) => ({
        ...prev,
        tagName: newOption?.tagData?.name,
        bgColor: bgColor,
        fontColor: fontColor,
        borderColor: borderColor
      }));
    } else {
      setOption(newOption);
      setApiLoading(false);
    }
  };

  const execute = async () => {
    if (!apiQueueRef.current?.apiFunc) return;
    setApiLoading(true);
    try {
      const res = await apiQueueRef.current.apiFunc();
      setTagDataInfo(res);
      updateNodeMetaData(res);
      setCallComplete({});
      if (option?.options?.isBlinkWhenUpdate) {
        setFlash(true);
        setTimeout(() => setFlash(false), 200);
      }
      setApiLoading(false);
    } catch (error) {
      setApiLoading(false);
    }
  };

  return [changeOption, tagDataInfo, apiLoading, flash, setTagDataInfo];
}

export default useShapeDataTimer;
