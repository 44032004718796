import {ChangeEvent, ReactElement} from 'react';
import {SelectForm} from 'components/forms';
import {IOption} from 'components/common/types';
import {SeriesGraphTypes} from 'components/pc/widgets/common/chart/types';

const options: IOption[] = [
  {label: 'Line', value: 'line'},
  {label: 'Area', value: 'area'},
  {label: 'Point', value: 'point'},
  {label: 'Dashed', value: 'dashed'}
  // {label: 'Bar', value: 'bar'}
];

type IProps = {
  flattenKeys: string;
  graphType: SeriesGraphTypes;
  onChange(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void;
};

/**
 * DefaultTable 내부에서 checkbox 변경
 * @param flattenKeys
 * @param graphType
 * @param onChange
 * @constructor
 */
function SeriesGraphTypeSelector({flattenKeys, graphType, onChange}: IProps): ReactElement {
  return (
    <SelectForm
      name={flattenKeys}
      title="graph"
      value={graphType}
      options={options}
      width={80}
      height={30}
      onChange={onChange}
    />
  );
}

export default SeriesGraphTypeSelector;
