import {IDataBindingType, IShapeStyle} from 'components/pc/common/shapes/type';

export const pt = 1 + 1 / 3;

const strokeWidthOptions = [
  {label: '0pt', value: 0},
  {label: '1pt', value: pt},
  {label: '2pt', value: 2 * pt},
  {label: '4pt', value: 4 * pt},
  {label: '6pt', value: 6 * pt},
  {label: '12pt', value: 12 * pt}
  // {label: '24pt', value: 24 * pt}
];

const strokeStyleOptions = [
  {label: 'solid line', value: [0]},
  {label: 'dotted line', value: [1, 1]},
  {label: 'dashed line', value: [3, 4]}
];

const fontSizeOptions = [
  {label: '10', value: 10},
  {label: '15', value: 15},
  {label: '20', value: 20},
  {label: '40', value: 40},
  {label: '60', value: 60},
  {label: '80', value: 80},
  {label: '100', value: 100},
  {label: '150', value: 150},
  {label: '200', value: 200}
];

export const shapeStyleOptions = {
  strokeWidth: strokeWidthOptions,
  strokeStyle: strokeStyleOptions,
  fontSize: fontSizeOptions
};

export const compactColors = [
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FE9200',
  // '#FCDC00',
  // '#DBDF00',
  // '#A4DD00',
  // '#68CCCA',
  // '#73D8FF',
  // '#AEA1FF',
  // '#FDA1FF',
  // '#333333',
  // '#808080',
  // '#cccccc',
  // '#D33115',
  // '#E27300',
  // '#FCC400',
  // '#B0BC00',
  // '#68BC00',
  // '#16A5A5',
  // '#009CE0',
  // '#7B64FF',
  // '#FA28FF',
  // '#000000',
  // '#666666',
  // '#B3B3B3',
  // '#9F0500',
  // '#C45100',
  // '#FB9E00',
  // '#808900',
  // '#194D33',
  // '#0C797D',
  '#0062B1',
  '#653294',
  'transparent'
];

export const defaultColorBlack = {
  hsl: {
    h: 240,
    s: 0,
    l: 0.026600000000000002,
    a: 1
  },
  hex: '#070707',
  rgb: {
    r: 7,
    g: 7,
    b: 7,
    a: 1
  }
};

export const defaultColorLightBlue = {
  hsl: {
    h: 214.66666666666663,
    s: 1,
    l: 0.9225,
    a: 1
  },
  hex: '#d7e8ff',
  rgb: {
    r: 215,
    g: 232,
    b: 255,
    a: 1
  }
};

export const defaultColorLightGoldenrodYellow = {
  hsl: {
    h: 60,
    s: 0.8000000000000002,
    l: 0.9019607843137254,
    a: 1
  },
  hex: '#fafad2',
  rgb: {
    r: 250,
    g: 250,
    b: 210,
    a: 1
  }
};

export const defaultColorTransparent = {
  hsl: {
    h: 0,
    s: 0,
    l: 1,
    a: 1
  },
  hex: '#ffffff',
  rgb: {
    r: 255,
    g: 255,
    b: 255,
    a: 1
  }
};

export const CONST = {
  normalShapeStyle: {
    borderColor: defaultColorBlack,
    backgroundColor: defaultColorLightBlue,
    fontColor: defaultColorBlack,
    fontSize: 20,
    strokeWidth: 1 + 1 / 3,
    strokeStyle: [0],
    opacity: 0,
    text: ['']
  } as IShapeStyle,
  textShapeStyle: {
    borderColor: defaultColorBlack,
    backgroundColor: defaultColorTransparent,
    fontColor: defaultColorBlack,
    fontSize: 20,
    strokeWidth: 0,
    strokeStyle: [0],
    opacity: 0,
    text: ['']
  } as IShapeStyle,
  dataBindingType: {
    tagData: {
      hierarchyInfo: [],
      name: ''
    },
    options: {
      isAutoUpdate: true,
      updateIntervalUnit: 15,
      updateIntervalVal: 1000,
      isBlinkWhenUpdate: true,
      isDisplayTagInformation: true,
      isDisplayUnit: true
    },
    liveDisplay: {
      list: []
    }
  } as IDataBindingType
};
