import styled from 'styled-components';
import TaskbarItem from 'components/pc/taskbar/TaskbarItem';
import {useNodes, useReactFlow} from 'reactflow';
import {useContext, useEffect, useState} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import classNames from 'classnames';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {IWidgetNodeData} from 'components/pc/types';

const Container = styled.div`
  position: absolute;
  z-index: 10;
  pointer-events: auto;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;

  &.process-canvas,
  &.mpfd,
  &.widget,
  &.copilot,
  &.mpfd-files {
    width: calc(100% - ${({theme}) => theme.action.menu.width}px);
    left: ${({theme}) => theme.action.menu.width}px;
  }
`;

const TaskBar = styled.div`
  display: flex;
  font-size: 15px;
  padding: 5px;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: rgba(195, 195, 195, 0.3);

  &:hover {
    background-color: rgba(195, 195, 195, 0.6);
  }

  /*@keyframes slideOut {
    to {
      opacity: 0;
      transform: translateX(15px);
    }
  }
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-15px);
    }
  }*/
  /*::view-transition-old(view-main-thumb),
  ::view-transition-new(view-main-thumb) {
    animation-name: none;
  }*/
`;

type IProps = {
  onClose?(): void;
};

function Taskbar({onClose}: IProps) {
  const {remoteAppMenu} = useContext(CommonContext);
  const {file} = useContext(ProcessCanvasContext);
  const nodes = useNodes();
  const reactFlow = useReactFlow();

  // const [nodeIdList, setNodeIdList] = nodeIdListState;
  // const [nodeList, setNodeList] = nodeListState;
  const [nodeIdList, setNodeIdList] = useState<string[]>([]);
  const [nodeList, setNodeList] = useState([]);

  // new file 에서 new file 을 다시 open할 경우 node list 초기화
  useEffect(() => {
    if (file?._id === 'new-file') {
      setNodeIdList([]);
      setNodeList([]);
    }
  }, [file]);

  // 현재 파일이 바뀔 경우 node list 초기화
  useEffect(() => {
    // todo: 추후에 첫번째 useEffect와 두번째 useEffect의 순서 관계를 조정해야할 가능성 있음
    if (!file?._id) {
      return;
    }
    setNodeIdList([]);
    setNodeList([]);
  }, [file?._id]);

  // minimize 순서대로 nodelist 재정렬
  useEffect(() => {
    //todo: setNodeIdList, setNodeList state ProcessCanvasProvider로 이동 필요! 저장용 state를 하나로 관리하도록 변경
    const newNodeIdList = [...nodeIdList];
    const newNodeList = [...nodeList];

    nodes.forEach((node) => {
      if (!nodeIdList.includes(node.id) && node.hidden && !(node?.data as IWidgetNodeData)?.docked) {
        newNodeIdList.push(node.id);
        newNodeList.push(node);
      } else if (nodeIdList.includes(node.id) && !node.hidden) {
        const index = newNodeIdList.indexOf(node.id);
        if (index > -1) {
          newNodeIdList.splice(index, 1);
          newNodeList.splice(index, 1);
        }
      }
    });

    setNodeIdList(newNodeIdList);
    setNodeList(newNodeList);
  }, [nodes]);

  //close item taskbar nodeList 에서 삭제처리
  const handleNodeClose = (nodeId) => {
    setNodeIdList((prev) => prev.filter((id) => id !== nodeId));
    setNodeList((prev) => prev.filter((node) => node.id !== nodeId));
  };

  return (
    <Container className={classNames('taskbar', remoteAppMenu?.id)}>
      <TaskBar>
        {nodeList
          .filter((node) => node.hidden)
          .map((item) => (
            <TaskbarItem
              key={item.id}
              data={item.data}
              onClose={() => {
                onClose?.();
                handleNodeClose(item.id);
              }}
              id={item.id}
            />
          ))}
      </TaskBar>
    </Container>
  );
}

export default Taskbar;
