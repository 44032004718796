import React, {ReactNode, MouseEvent} from 'react';

type IProps = {
  x: number;
  y: number;
  iw: number;
  ih: number;
  onMouseDown?: (event: MouseEvent<SVGGElement>) => void;
  children?: ReactNode;
};

function AnnotationGroup({x, y, iw, ih, onMouseDown, children}: IProps) {
  const onMouseDownAnnotationGroup = (e: MouseEvent<SVGGElement>) => {
    onMouseDown?.(e);
  };
  return (
    <g transform={`translate(${x * iw} ${y * ih})`} onMouseDown={onMouseDownAnnotationGroup} className={'annotation'}>
      {children}
    </g>
  );
}

export default AnnotationGroup;
