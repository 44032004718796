import {Dispatch, ReactElement, SetStateAction} from 'react';
import styled from 'styled-components';
import ServerItem from 'components/pc/widgets/modelRunner/initialize-modal-parts/ServerItem';
import {IModelRunnerServer} from 'components/pc/widgets/modelRunner/types';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 180px;
  overflow-y: auto;
  gap: 16px;
`;

type IProps = {
  stepState: [number, Dispatch<SetStateAction<number>>];
  serverListState: [IModelRunnerServer[], Dispatch<SetStateAction<IModelRunnerServer[]>>];
  selectedServerState: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
  customServerState: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
};

function ServerItemList({stepState, serverListState, selectedServerState, customServerState}: IProps): ReactElement {
  const [, setStep] = stepState;
  const [serverList] = serverListState;
  const [, setSelectedServer] = selectedServerState;
  const [customServer] = customServerState;

  const onClick = (server: IModelRunnerServer) => {
    setSelectedServer(server.name === 'custom server' ? {name: 'custom server', ip: null, port: null} : server);
    setStep(2);
  };

  return (
    <Container className="thin-scrollbar">
      {serverList.map((item) => (
        <ServerItem key={item.name} title={item.name} onClick={() => onClick(item)} />
      ))}
      <ServerItem title="custom server" onClick={() => onClick(customServer)} />
    </Container>
  );
}

export default ServerItemList;
