import {IPfdState, IPfdAction, IPfdCfg} from 'components/mpfd/type';
import {annotation, CONST} from 'components/mpfd/hooks/functions/action-calculation-functions';
import {ToolIds} from 'components/mpfd/panel/Toolbox';

export type IMpfdUnChangedActionType =
  | 'Initialize'
  | 'onLoad'
  | 'SET_IMAGE_INFO'
  | 'CANCEL'
  | 'SELECT_TOOL'
  | 'SELECT_REGION'
  | 'SELECT_REGIONS'
  | 'UNSELECT_REGION'
  | 'INITIALIZE_DETECTION_INFO'
  | 'ON_SAVE';

export const IMpfdUnChangedActionTypes: IMpfdUnChangedActionType[] = [
  'Initialize',
  'UNSELECT_REGION',
  'onLoad',
  'SET_IMAGE_INFO',
  'CANCEL',
  'SELECT_TOOL',
  'SELECT_REGION',
  'SELECT_REGIONS',
  'INITIALIZE_DETECTION_INFO',
  'ON_SAVE'
];

const unChangedAction = (state: IPfdState, action: IPfdAction) => {
  switch (action.type as IMpfdUnChangedActionType) {
    case 'Initialize': {
      return CONST.state as IPfdState;
    }
    case 'onLoad': {
      const detectionInfo = action.fileData?.detectionInfo ? action.fileData?.detectionInfo : CONST.detectionInfo;
      const regions = action.fileData?.regions ? action.fileData?.regions : [];
      const cfg = {
        isDisplayLabels: action.fileData?.cfg?.isDisplayDataValues
          ? Boolean(action.fileData?.cfg?.isDisplayDataValues)
          : true,
        isDisplayDataValues: action.fileData?.cfg?.isDisplayDataValues
          ? Boolean(action.fileData?.cfg?.isDisplayDataValues)
          : true,
        isLiveUpdateDataValues: Boolean(action.fileData?.cfg?.isLiveUpdateDataValues)
      } as IPfdCfg;
      return {...state, regions, detectionInfo, cfg};
    }
    case 'SET_IMAGE_INFO': {
      state = {...state, images: action.images};
      return state;
    }
    case 'UNSELECT_REGION': {
      const regions = annotation.resetFocus(state.regions, null);
      return {...state, regions};
    }
    case 'SELECT_REGION': {
      const {region} = action;
      const {idx} = annotation.annot2DefaultInfo(state.regions, region);
      if (idx === -1) return state;
      const regions = annotation.resetFocus(state.regions, region);
      return {...state, regions};
    }
    case 'SELECT_REGIONS': {
      const regions = state.regions.map((item) => ({
        ...item,
        highlighted: action.regions.some((selectedTarget) => selectedTarget.id === item.id)
      }));
      return {...state, regions};
    }
    case 'CANCEL': {
      const {mode} = state;
      if (mode) {
        switch (mode.mode) {
          case 'MOVE_REGION': {
            return {...state, mode: null};
          }
          case 'DRAW_EXPANDING_LINE': {
            const {region} = state.mode;
            const {annot, idx, points} = annotation.annot2DefaultInfo(state.regions, region);
            if (points?.length === 0 || points?.length === 1) {
              const tempRegions = [...state.regions];
              tempRegions.splice(idx);
              return {...state, mode: null, regions: tempRegions};
            }
            if (points.length % 2 !== 0) {
              points.splice(points.length - 1, 1);
            }
            state = {
              ...state,
              mode: null,
              regions: state.regions.with(idx, {...annot, points, candidatePoint: undefined, unfinished: false})
            };
            return state;
          }
          case 'SELECT_POINT_OF_LINE': {
            return {...state, mode: null, selectedTool: 'select' as ToolIds};
          }
          default:
            return state;
        }
      }
      return {...state, selectedTool: 'select' as ToolIds};
    }
    case 'SELECT_TOOL': {
      /**
       * Drawing, Create Something mode
       */
      if (state?.mode?.mode) {
        switch (state?.mode?.mode) {
          case 'SELECT_POINT_OF_LINE': {
            break;
          }
          default:
            return state;
        }
      }

      return {...state, selectedTool: action.selectedTool, mode: null};
    }
    case 'INITIALIZE_DETECTION_INFO': {
      return {
        ...state,
        detectionInfo: action.detectionInfo,
        selectedTool: action.selectedTool ? action.selectedTool : 'select'
      };
    }
    case 'ON_SAVE': {
      return {...state, isChanged: false};
    }
    default:
      break;
  }
  return state;
};

export default unChangedAction;
