import {PropsWithChildren} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

const Container = styled.div`
  padding: 13px 25px;
  position: relative;
  display: flex;
  height: 44px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f2f2f2;
  color: #373737;

  font-weight: 600;
  font-size: 13px;
  border-bottom: 1px solid #a6a6a6;
  &.active {
    border-bottom: 0;
  }
`;

const UpdateDisplay = styled.div`
  margin-left: 2px;
  margin-top: 2px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${({theme}) => theme.color.warning};
`;

const IconContainer = styled.div`
  margin-left: auto;
`;

type AccordionSummaryProps = PropsWithChildren & {
  isActive?: boolean;
  updated: boolean;
};

function NewAccordionHeader({children, isActive, updated}: AccordionSummaryProps) {
  return (
    <Container className={classnames(isActive && 'active')}>
      {children}
      {updated && !isActive && <UpdateDisplay></UpdateDisplay>}
      <IconContainer>
        <FontAwesomeIcon icon={isActive ? faChevronDown : faChevronRight} />
      </IconContainer>
    </Container>
  );
}

export default NewAccordionHeader;
