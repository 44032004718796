import {ReactElement} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowDown, faComputerClassic} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {IApiServer} from 'api/data-types';

const Container = styled.div`
  display: inline-block;
  text-transform: capitalize;
  background-color: rgba(0, 166, 203, 0.1);
  color: ${({theme}) => theme.color.info};
  padding: 10px 20px;
  border-radius: 5px;

  > svg {
    margin-right: 10px;
  }
`;

function ApiHostTypeDisplay(): ReactElement {
  const apiServer = LocalStorageManager.getItem<IApiServer>('API_SERVER');
  const area = apiServer?.type === 'cloud' && apiServer?.area?.toUpperCase();

  return (
    <Container>
      <FontAwesomeIcon icon={apiServer?.type === 'cloud' ? faCloudArrowDown : faComputerClassic} size="lg" />
      {area} {apiServer?.type} server
    </Container>
  );
}

export default ApiHostTypeDisplay;
