
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function HeatExchanger() {
  const { nodes, materials } = useGLTF('./3D/HeatExchanger/HeatExchanger.gltf')
  return (
    <group dispose={null} scale={0.4}>
      <mesh geometry={nodes._HeatExchanger_1.geometry} material={materials.Simacro_HeatExchanger} position={[4,1,0]} rotation={[0, -1.571, 0]} />
    </group>
  )
}

useGLTF.preload('./3D/HeatExchanger/HeatExchanger.gltf')
