import {Button} from 'components/forms';
import {AnnotationTypes, IAnnotation} from 'components/mpfd/type';
import {Dispatch, SetStateAction, useContext} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTrash, faWandMagicSparkles} from '@fortawesome/pro-light-svg-icons';
import {faMerge} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import Tooltip from 'components/common/Tooltip';
import {alreadyDetectedModalOptions, onlySvgFileDetectableModalOptions} from 'components/menu/constants';
import {CommonContext} from 'components/common/CommonProvider';

const DetectButtonContainer = styled.div`
  margin-left: auto;
  button {
    > svg {
      margin-right: 6px;
    }
  }
`;

type IProps = {
  type: AnnotationTypes;
  entireRegions: IAnnotation[];
  selectedTargetRegionState: [string[], Dispatch<SetStateAction<string[]>>];
};

function AnnotationButtons({type, entireRegions, selectedTargetRegionState}: IProps) {
  const {showModal} = useContext(CommonContext);
  const {state, isDetectProgress, showMPfdModal, annotationAction} = useContext(MetaPfdContext);
  const [selectedTargetRegions, setSelectedTargetRegions] = selectedTargetRegionState;
  const targetRegions = entireRegions.filter((item) => selectedTargetRegions.includes(item.id));
  const isDetected = state?.detectionInfo?.detectedFileName;

  const onClickDelete = () => {
    annotationAction.deleteAnnotations(targetRegions);
  };

  const onClickMerge = () => {
    if (targetRegions.length > 1) {
      annotationAction.mergeAnnotations(targetRegions);
      setSelectedTargetRegions([]);
    }
  };

  const onClickDetect = () => {
    if (!!state?.detectionInfo?.detectedFileName) {
      showModal(alreadyDetectedModalOptions);
    } else if ((state?.images?.imgExtension || '').toLowerCase() !== 'svg') {
      showModal(onlySvgFileDetectableModalOptions);
    } else {
      showMPfdModal('detection');
    }
  };

  return (
    <>
      <Button variant="none" onClick={onClickDelete} disabled={targetRegions?.length === 0}>
        <Tooltip content="Delete" place="top">
          <FontAwesomeIcon icon={faTrash} size="lg" />
        </Tooltip>
      </Button>
      {type === 'expanding-line' && (
        <>
          <Button variant="none" onClick={onClickMerge} disabled={targetRegions?.length < 2}>
            <Tooltip content="Merge" place="top">
              <FontAwesomeIcon icon={faMerge} size="lg" />
            </Tooltip>
          </Button>
          <DetectButtonContainer>
            <Button variant={isDetected ? 'info' : 'primary'} onClick={onClickDetect} disabled={isDetectProgress}>
              <FontAwesomeIcon icon={isDetected ? faCheck : faWandMagicSparkles} />
              Line Detect{isDetected && 'ed'}
            </Button>
          </DetectButtonContainer>
        </>
      )}
    </>
  );
}

export default AnnotationButtons;
