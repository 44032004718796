import {TextForm} from 'components/forms';
import * as React from 'react';
import styled from 'styled-components';
import {ChangeEvent, Dispatch, SetStateAction} from 'react';
import {getAllValuesFromNodes, keywordFilter} from 'components/pc/node-selector/function';
import {Node as TreeNode} from 'react-checkbox-tree';

const Container = styled.div`
  height: 40px;
  flex-shrink: 0;
`;

type IProps = {
  nodesState: [TreeNode[], Dispatch<SetStateAction<TreeNode[]>>];
  checkedState: [string[], Dispatch<SetStateAction<string[]>>];
  expandedState: [string[], Dispatch<SetStateAction<string[]>>];
  keywordState: [string, Dispatch<SetStateAction<string>>];
  localDatabase?: unknown;
  originNodes: TreeNode[];
};
function NodeSelectorSearch({nodesState, checkedState, expandedState, keywordState, originNodes}: IProps) {
  const [checked] = checkedState;
  const [, setExpanded] = expandedState;
  const [keyword, setKeyword] = keywordState;
  const [, setNodes] = nodesState;

  const onChange = (event: ChangeEvent) => {
    const {value} = event.target as HTMLInputElement;
    if (!originNodes || originNodes?.length === 0) return;
    setKeyword(value);
    if (value?.trim().length > 1) {
      const searchedNodes = keywordFilter([...(originNodes || [])], value, checked);
      const expandedNodes = getAllValuesFromNodes(searchedNodes, true);
      setNodes(searchedNodes);
      const restrictExpandedNodes = [...expandedNodes].slice(0, 100);
      setExpanded(restrictExpandedNodes);
    } else {
      setNodes([...(originNodes || [])]);
      setExpanded([]);
    }
  };

  return (
    <Container>
      <TextForm name="keyword" value={keyword} placeholder="Search" onChange={onChange} />
    </Container>
  );
}

export default NodeSelectorSearch;
