import {ReactElement, useContext} from 'react';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  width: 320px;
  padding: 0;
  display: flex;
  flex-direction: column;
  pointer-events: auto;

  h2 {
    font-weight: 400;
    margin: 10px 0;
  }
`;
const Message = styled.div`
  white-space: pre-line;
  line-height: 1.6em;
  padding: 10px 0;
`;
const Footer = styled.div`
  margin: 10px 0;
`;

type IProps = {
  title?: string;
  message: string;
  src?: string;
};

function DefaultBrowserPermission({title = 'Please Allow Permission', message, src}: IProps): ReactElement {
  const {showRemoteTooltip} = useContext(CommonContext);

  const onClickConfirm = (): void => {
    showRemoteTooltip(undefined);
  };

  return (
    <Container>
      <h2>{title}</h2>
      <Message>{message}</Message>
      {Boolean(src) && <img src={src} alt="" />}
      <Footer>
        <Button width="100%" onClick={onClickConfirm}>
          Confirm
        </Button>
      </Footer>
    </Container>
  );
}

export default DefaultBrowserPermission;
