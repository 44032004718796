import {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import PopupHeader from 'components/popup/PopupHeader';
import PopupBody from 'components/popup/PopupBody';

const Container = styled.div`
  margin: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  box-shadow:
    0 2px 20px rgba(0, 0, 0, 0.1),
    0 1px 5px rgba(0, 0, 0, 0.3);

  &.default {
    width: 560px;
    height: 300px;
  }
  &.tag {
    width: 340px;
    height: 90%;
  }
  &.doubleModal {
    width: 360px;
    height: 90%;
  }
`;

type IProps = {
  type: 'default' | 'tag' | 'doubleModal';
  title?: string;
  children?: ReactNode | ReactNode[];
  onClose(): void;
};

function Popup({type, title, children, onClose}: IProps): ReactElement {
  return (
    <Container className={type}>
      <PopupHeader title={title} onClose={onClose} />
      <PopupBody>{children}</PopupBody>
    </Container>
  );
}

export default Popup;
