import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TextForm} from 'components/forms';
import {faSearch, faXmark} from '@fortawesome/pro-light-svg-icons';
import React, {ChangeEvent, Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 16px;
  padding: 0.2rem;
  position: relative;
  height: 50px;
  flex-shrink: 0;

  svg {
    position: absolute;
    top: calc(50% - 14px);
    z-index: 3;
    color: #86868b;
    font-size: 18px;
  }
  .search-icon {
    left: 16px;
  }

  .xMark-icon {
    right: 16px;
    cursor: pointer;
  }

  input {
    padding-inline: 38px 50px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    outline: none;

    &:focus {
      background-color: rgba(0, 100, 200, 0.2);
    }
  }
`;

type IProps = {
  searchInputState: [string, Dispatch<SetStateAction<string>>];
};

function ActionMenuFileSearchField({searchInputState}: IProps) {
  const [searchInput, setSearchInput] = searchInputState;
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchInput(value);
  };

  const onClickReset = () => {
    setSearchInput('');
  };

  return (
    <Container>
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      <TextForm value={searchInput} onChange={onChange} />
      {searchInput && <FontAwesomeIcon icon={faXmark} className="xMark-icon" onClick={onClickReset} />}
    </Container>
  );
}

export default ActionMenuFileSearchField;
