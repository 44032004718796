import {IShapeProps} from 'components/pc/common/shapes/type';

function DataBinding({width, height, ...svgAttributes}: IShapeProps) {
  return (
    <path
      d="M1.15335 6.80357C4.32175 3.48214 8.68411 1.42857 13.5021 1.42857C18.3201 1.42857 22.6825 3.48214 25.8509 6.80357C26.1167 7.08036 26.5428 7.08036 26.8043 6.79911C27.0659 6.51786 27.0659 6.06696 26.8001 5.79018C23.387 2.20982 18.6872 0 13.5021 0C8.31706 0 3.61719 2.20982 0.199874 5.79018C-0.0659174 6.06696 -0.0659174 6.51786 0.195655 6.79911C0.457228 7.08036 0.883338 7.08036 1.14913 6.80357H1.15335ZM13.5021 8.57143C16.4764 8.57143 19.1723 9.84375 21.1257 11.9063C21.3915 12.183 21.8176 12.183 22.0792 11.9063C22.3407 11.6295 22.3407 11.1741 22.0792 10.8973C19.8811 8.58036 16.8519 7.14732 13.5021 7.14732C10.1523 7.14732 7.12311 8.58036 4.92506 10.8973C4.65927 11.1741 4.65927 11.6295 4.92506 11.9063C5.19085 12.183 5.61696 12.1875 5.87853 11.9063C7.83189 9.84375 10.5278 8.57143 13.5021 8.57143ZM13.5021 15.7143C13.8602 15.7143 14.2036 15.8648 14.4567 16.1327C14.7099 16.4006 14.8522 16.764 14.8522 17.1429C14.8522 17.5217 14.7099 17.8851 14.4567 18.153C14.2036 18.4209 13.8602 18.5714 13.5021 18.5714C13.1441 18.5714 12.8007 18.4209 12.5475 18.153C12.2943 17.8851 12.1521 17.5217 12.1521 17.1429C12.1521 16.764 12.2943 16.4006 12.5475 16.1327C12.8007 15.8648 13.1441 15.7143 13.5021 15.7143ZM13.5021 20C14.2182 20 14.905 19.699 15.4114 19.1632C15.9177 18.6273 16.2022 17.9006 16.2022 17.1429C16.2022 16.3851 15.9177 15.6584 15.4114 15.1226C14.905 14.5867 14.2182 14.2857 13.5021 14.2857C12.786 14.2857 12.0992 14.5867 11.5928 15.1226C11.0865 15.6584 10.802 16.3851 10.802 17.1429C10.802 17.9006 11.0865 18.6273 11.5928 19.1632C12.0992 19.699 12.786 20 13.5021 20Z"
      fill="black"
    />
  );
}

export default DataBinding;
