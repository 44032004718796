import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {IToolbox} from 'components/pc/common/shapes/Toolbar';
import {shapeStyleOptions} from 'components/pc/common/shapes/const';
import StrokeSvg from 'components/pc/common/shapes/StrokeSvg';
import {IShapeStyle} from 'components/pc/common/shapes/type';
import {isNumberOrStringArraySame} from 'utils/commons';
import {getImage} from 'assets/images/svg-image';
import {getRgbaFromColorResult} from 'components/pc/common/shapes/parts/svg/utils';

type IconWrapperProps = {
  $isColorBar: boolean;
  $rightLines?: boolean;
};

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  flex-direction: column;
  //border-right: ${(props) => (props.$rightLines ? '0.5px solid #aaaaaa' : '')};

  > label {
    height: 50px;
    display: flex;
    align-items: center;
  }

  > input {
    width: 36px;
    height: 34px;
    border-radius: 5px;
    border: 1px solid black;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
  }

  > button {
    width: 36px;

    height: ${(props) => (props.$isColorBar ? 36 : 36)}px;
    border: none !important;
    padding: 0 !important;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    svg {
      width: 20px;
      height: 20px;
      transition: all 0.5s ease;
    }
    &.active {
      background-color: #d7eeff;
    }

    &:not(:disabled, .active):hover {
      background-color: #edf6ff;
    }

    &:disabled {
      cursor: not-allowed;
    }
    img {
      -webkit-user-drag: none;
    }
  }
`;

const NoneHeaderSelect = styled.div`
  position: absolute;
  top: 40px;
  z-index: 3;
  font-size: 12px;
  box-shadow: 0 2px 10px 0 #00000033;
  border: 1px solid #aaaaaa;
`;

type divOptionsProps = {
  $highlighted: boolean;
};

const DivOption = styled.div<divOptionsProps>`
  //z-index: 3;
  background-color: ${(props) => (props.$highlighted ? '#d7eeff' : 'white')};
  border: none;
  width: 32px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  gap: 4px;
  cursor: pointer;
  font-size: 10px;
  &:hover {
    background-color: ${(props) => (props.$highlighted ? '' : ' #edf6ff')};
  }
`;

type colorBarProps = {
  $color: string;
};

const ColorBar = styled.div<colorBarProps>`
  background-color: ${(props) => (props.$color ? props.$color : '')};
  height: 3px;
  width: 22px;
  //margin: auto;
  border: 1px solid #0001;
`;

const SelectedIcon = styled.div`
  //padding-right: 4px;
`;

const RightLine = styled.div`
  height: 28px;
  border-right: 0.5px solid #00000033;
`;

type IProps = {
  tool: IToolbox;
  selectedTool: IToolbox;
  shapeStyle: IShapeStyle;
  onClick?(item: IToolbox): void;
  onChangeFontSize?(e: ChangeEvent<HTMLInputElement>): void;
  onChangeStrokeWidthOrStyle?(val: number[] | number): void;
  onClickFontSizePlusMinusButton?(factor: number): void;
};

function ToolbarItem({
  tool,
  selectedTool,
  shapeStyle,
  onClick,
  onChangeFontSize,
  onChangeStrokeWidthOrStyle,
  onClickFontSizePlusMinusButton
}: IProps) {
  const {type, icon, name, rightLines, icon2} = tool;
  const isColorBar = type === 'colorPicker';

  const [tempObj, seTTempObj] = useState<null | {speed: number; num: number}>();
  const ref = useRef(null);
  ref.current = tempObj;

  useEffect(() => {
    let timer;
    if (ref.current && ref.current?.num && ref.current?.speed) {
      if (1000 / (tempObj.speed * 2) < 100) {
        timer = setTimeout(() => seTTempObj((prev) => ({...prev})), 20);
      } else {
        timer = setTimeout(() => seTTempObj((prev) => ({...prev, speed: tempObj.speed * 2})), 2000 / tempObj.speed);
      }
      onClickFontSizePlusMinusButton(tempObj.num);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [tempObj]);

  const onMouseDown = (num: number) => {
    seTTempObj({speed: 2, num});
  };

  const cancelMouseDown = () => {
    seTTempObj(null);
  };

  switch (name) {
    case 'fontColor':
    case 'backgroundColor':
    case 'borderColor':
      return (
        <>
          <IconWrapper $isColorBar={isColorBar} $rightLines={rightLines}>
            <Button
              key={type}
              variant="none"
              className={selectedTool?.name === name ? 'active' : 'none'}
              name={name}
              onClick={() => onClick(tool)}
            >
              <img src={getImage(icon)} className="close-button" alt="close button" />
              <ColorBar $color={getRgbaFromColorResult(shapeStyle[name])} />
            </Button>
          </IconWrapper>
          {rightLines && <RightLine />}
        </>
      );
    case 'fontSize':
      return (
        <>
          <IconWrapper
            $isColorBar={isColorBar}
            onClick={() => onClick(tool)}
            onMouseDown={() => onMouseDown(-1)}
            onMouseUp={cancelMouseDown}
            onMouseLeave={cancelMouseDown}
          >
            <Button variant="none">
              <img src={getImage('shapeToolBoxFontMinus')} className="close-button" alt="close button" />
            </Button>
          </IconWrapper>

          <IconWrapper $isColorBar={isColorBar} onClick={() => onClick(tool)}>
            <input value={shapeStyle?.fontSize} onChange={onChangeFontSize} />
          </IconWrapper>
          <IconWrapper
            $isColorBar={isColorBar}
            $rightLines={rightLines}
            onClick={() => onClick(tool)}
            onMouseDown={() => onMouseDown(1)}
            onMouseUp={cancelMouseDown}
            onMouseLeave={cancelMouseDown}
          >
            <Button variant="none">
              <img src={getImage('shapeToolBoxFontPlus')} className="close-button" alt="close button" />
            </Button>
          </IconWrapper>
          {rightLines && <RightLine />}
        </>
      );
    case 'strokeWidth':
      return (
        <>
          <IconWrapper $isColorBar={isColorBar} $rightLines={rightLines}>
            <Button
              key={name}
              variant="none"
              className={selectedTool?.name === name ? 'active' : 'none'}
              name={name}
              onClick={() => onClick(tool)}
            >
              <img src={getImage(icon)} className="close-button" alt="close button" />
            </Button>
            {selectedTool?.name === name && (
              <NoneHeaderSelect>
                {shapeStyleOptions.strokeWidth.map((i) => (
                  <React.Fragment key={i.label}>
                    <DivOption
                      $highlighted={i?.value === shapeStyle?.strokeWidth}
                      onClick={() => onChangeStrokeWidthOrStyle(i.value)}
                    >
                      <SelectedIcon>
                        {i?.value === shapeStyle?.strokeWidth && (
                          <img src={getImage('shapeToolBoxChecked')} className="close-button" alt="close button" />
                        )}
                      </SelectedIcon>
                      {i.label}
                    </DivOption>
                  </React.Fragment>
                ))}
              </NoneHeaderSelect>
            )}
          </IconWrapper>
          {rightLines && <RightLine />}
        </>
      );
    case 'strokeStyle':
      return (
        <>
          <IconWrapper $isColorBar={isColorBar} $rightLines={rightLines}>
            <Button
              key={type}
              variant="none"
              className={selectedTool?.name === name ? 'active' : 'none'}
              name={name}
              onClick={() => onClick(tool)}
            >
              <img src={getImage(icon)} className="close-button" alt="close button" />
            </Button>
            {selectedTool?.name === name && (
              <NoneHeaderSelect>
                {shapeStyleOptions.strokeStyle.map((i) => (
                  <DivOption
                    key={i.label}
                    $highlighted={isNumberOrStringArraySame(i.value, shapeStyle.strokeStyle)}
                    onClick={() => onChangeStrokeWidthOrStyle(i.value)}
                  >
                    <SelectedIcon>
                      {isNumberOrStringArraySame(i.value, shapeStyle.strokeStyle) && (
                        <img src={getImage('shapeToolBoxChecked')} className="close-button" alt="close button" />
                      )}
                    </SelectedIcon>
                    <StrokeSvg type={i.label} />
                  </DivOption>
                ))}
              </NoneHeaderSelect>
            )}
          </IconWrapper>
          {rightLines && <RightLine />}
        </>
      );
    case 'close':
      return (
        <IconWrapper $isColorBar={isColorBar} $rightLines={rightLines}>
          <Button
            key={type}
            variant="none"
            className={selectedTool?.name === name ? 'active' : 'none'}
            name={name}
            onClick={(e) => {
              e.stopPropagation();
              onClick(tool);
            }}
          >
            <img src={getImage(icon)} className="close-button" alt="close button" />
          </Button>
        </IconWrapper>
      );
    case 'dataBinding':
      return (
        <IconWrapper $isColorBar={isColorBar} $rightLines={rightLines}>
          <Button
            key={type}
            variant="none"
            className={selectedTool?.name === name ? 'active' : 'none'}
            name={name}
            onClick={(e) => {
              e.stopPropagation();
              onClick(tool);
            }}
          >
            <img src={getImage(icon ? icon : icon2)} className="close-button" alt="close button" />
          </Button>
        </IconWrapper>
      );
    default:
  }
}

export default ToolbarItem;
