import {MouseEvent, PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #5a5a5a;
  transition: background-color 0.8s;

  &:last-child {
    border-bottom: 1px solid #5a5a5a;
  }

  &:hover {
    transition: background-color 0.1s;
    background-color: rgba(121, 108, 111, 0.2);
  }
  &.disabled {
    &:hover {
      background-color: transparent;
    }
  }
`;

type IProps = PropsWithChildren & {
  id?: string;
  disabled?: boolean;
  onMouseEnter?(e: MouseEvent): void;
  onMouseLeave?(e: MouseEvent): void;
};

function ActionMenuItem({children, id, disabled, onMouseEnter, onMouseLeave}: IProps): ReactElement {
  return (
    <Container id={id} className={classNames({disabled})} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </Container>
  );
}

export default ActionMenuItem;
