import styled from 'styled-components';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import {useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {MetaPfdContext} from 'components/mpfd';

const ModalBodyMessage = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  display: flex;
  justify-content: center;
`;

function MetaPfdAllowCopyModal() {
  const {allowCopyModalState, saveLoadingModalState, isAllowCopy, allowCopy, setIsAllowCopy, getFileList} =
    useContext(MetaPfdContext);
  const {showModal} = useContext(CommonContext);

  const [, setIsShowAllowCopyModal] = allowCopyModalState;
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onClose()
  });

  const onClose = (): void => {
    setIsShowAllowCopyModal(false);
  };

  const onClickConfirm = async () => {
    const successStatus = await allowCopy();
    if (successStatus) {
      setIsShowSaveLoadingModal(true);
      setIsAllowCopy(!isAllowCopy);
      setIsShowAllowCopyModal(false);
    } else {
      showModal({
        title: `Response Error`,
        content: `Response is failed`
      });
    }
    getFileList();
  };

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>{!isAllowCopy ? 'Allow' : 'Prevent'} Copy</ModalHeader>
      <ModalBody align="center" size="full-width">
        <ModalBodyMessage>
          Save this file {!isAllowCopy ? 'allow' : 'prevent'} other users to create a copy.
          <br />
        </ModalBodyMessage>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onClickConfirm}>
          {!isAllowCopy ? 'Allow Save As Copy' : 'Prevent Save As Copy'}
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default MetaPfdAllowCopyModal;
