import {PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useId, useRef} from 'react';
import styled from 'styled-components';
import {IProfileChartLoader} from 'components/pc/widgets/profile-chart/types';
import {LightningChartContext} from 'components/pc/widgets/common/chart/LightningChartProvider';
import {
  AxisOptions,
  ChartXY,
  ColorHEX,
  FillStyle,
  PointLineAreaSeries,
  PointSeries,
  SolidFill
} from '@lightningchart/lcjs';
import {getSeries, getSolidFill} from 'components/pc/widgets/common/chart/common-chart-functions';

const Container = styled.div`
  background-color: #e9eff3;
  min-height: 50px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Chart = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
`;

type IProps = PropsWithChildren & {
  docked?: boolean;
  height: number | string;
  loader: IProfileChartLoader;
};

function ProfileChartContainer({height, loader}: IProps): ReactElement {
  const ref = useRef(null);

  const id = useId();
  const lc = useContext(LightningChartContext);
  // const [chartState, setChartState] = useState<{chart: ChartXY; seriesList: PointLineAreaSeries[]}>();
  const chartRef = useRef<undefined | ChartXY>(null);
  const lineSeriesRef = useRef<(PointLineAreaSeries | PointSeries)[]>([]);
  // 받은 라이브 데이터를 누적해서 쌓아둬야 함 -> color, active, YAxis 가 변경 되면 chart 를 다시 그리는데 이때 최초 받은 데이터와 이 데이터를 합쳐 차트를 표현 한다.
  const liveRecordsRef = useRef({});
  const firstTimestampRef = useRef<number>(0);

  const createChart = useCallback((): ChartXY => {
    chartRef.current?.dispose();
    const container = document.getElementById(id) as HTMLDivElement;
    if (!container || !lc) {
      return;
    }
    const defaultAxisX: AxisOptions = {type: 'linear-highPrecision'};
    const chart = lc.ChartXY({container, defaultAxisX});

    const backgroundStyle = new SolidFill({color: ColorHEX('#292d3e')}) as unknown as FillStyle;
    chart.setTitle('').setBackgroundFillStyle(backgroundStyle).setSeriesBackgroundFillStyle(backgroundStyle);

    return chart;
  }, [id, lc]);

  useEffect(() => {
    return () => {
      // Destroy chart when component lifecycle ends.
      chartRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    chartRef.current = createChart();
  }, [createChart]);

  useEffect(() => {
    // console.log('>>> lo', loader.series);
    if (!chartRef.current || loader.series.length === 0 || chartRef.current.isDisposed()) return;

    chartRef.current = createChart();
    // const axisX = chartRef.current.getDefaultAxisX().setTickStrategy(AxisTickStrategies.Empty);

    lineSeriesRef.current = loader.series.map((channel) => {
      if (!channel.active) return null;
      // console.log('>>> ch', channel);

      // yAxis 객체 묶음
      const yAxisList = [];
      let stackCount = 0;
      const parallelList = loader.series.map((ch) => ch.parallel);

      const lineStyle = getSolidFill(channel.color);
      const axisY = chartRef.current
        .addAxisY({iParallel: -channel.parallel, iStack: stackCount})
        .setChartInteractionZoomByWheel(false)
        .setMargins(10, 10)
        .setTitle(channel.name)
        .setTitleFillStyle(lineStyle)
        .setThickness(80);

      return getSeries(chartRef.current, axisY, channel).add(channel.data);
    });
    chartRef.current.getDefaultAxisY().setVisible(false);
  }, [loader.series, createChart]);

  return (
    <Container style={{height}} ref={ref}>
      <Chart id={id} />
    </Container>
  );
}

export default ProfileChartContainer;
