import {ReactElement} from 'react';
import styled from 'styled-components';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common/index';
import BasicSpinner from 'components/common/BasicSpinner';
import Button from 'components/forms/Button';
import {VariantTypes} from 'components/forms/types';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const LoadingSymbolWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

type IProps = {
  variant?: VariantTypes;
  confirmButtonLabel?: string;
  confirmHeaderMessage?: string;
  confirmBodyMessage?: string;
  onConfirm(): void;
  setOpenConfirmModal(openConfirmModal: boolean): void;
  isLoading?: boolean;
  showCancelButton?: boolean;
};

function ConfirmModal({
  variant = 'primary',
  confirmButtonLabel = 'Confirm',
  confirmHeaderMessage = 'Confirm',
  confirmBodyMessage = 'Click the below button to confirm',
  onConfirm,
  setOpenConfirmModal,
  isLoading = false,
  showCancelButton = true
}: IProps): ReactElement {
  const closeOpenConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  useModalKeyBoardEvent({
    onConfirm: onConfirm,
    onCancel: closeOpenConfirmModal
  });

  return (
    <Container>
      <ModalWrapper type="confirm">
        <ModalHeader onClose={closeOpenConfirmModal}>{confirmHeaderMessage}</ModalHeader>
        <ModalBody>{confirmBodyMessage}</ModalBody>
        <ModalFooter>
          <LoadingSymbolWrapper>
            {isLoading && <BasicSpinner size="md" isShow={isLoading} margin={0} />}
          </LoadingSymbolWrapper>
          <Button className="modal-btn" variant={variant} size="sm" onClick={onConfirm}>
            {confirmButtonLabel}
          </Button>
          {showCancelButton && (
            <Button className="modal-btn" variant="cancel" size="sm" onClick={closeOpenConfirmModal}>
              Cancel
            </Button>
          )}
        </ModalFooter>
      </ModalWrapper>
    </Container>
  );
}

export default ConfirmModal;
