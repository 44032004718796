import styled from 'styled-components';
import Button from 'components/forms/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowProgress} from '@fortawesome/pro-light-svg-icons';
import {getImage} from 'assets/images/svg-image';
import {MouseEvent, useState} from 'react';
import {useReactFlow} from 'reactflow';
import {IWidgetNodeData} from 'components/pc/types';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: #ffffff;
  opacity: 90%;
  //min-width: 150px;
  width: 100%;
  max-width: 300px;
  height: 40px;
  padding: 5px 10px;
  margin: 0 2px;
  border-radius: 5px;
  align-items: center;
  color: #575660;
  cursor: pointer;

  &:hover {
    color: #171523;
  }

  .widget-icon {
    flex-shrink: 0;
    margin-right: 10px;
  }

  .widget-title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //margin-right: 50px;
  }

  .close-button {
    padding-top: 3px;
  }
`;

type IProps = {
  onClose(): void;
  // onMaximize(): void;
  id: string;
  data: IWidgetNodeData;
};

function TaskbarItem({onClose, id, data}: IProps) {
  const reactFlow = useReactFlow();
  const nodes = reactFlow.getNodes();
  const [currentViewItem, setCurrentViewItem] = useState(null);

  const onClickMaximize = () => {
    document.startViewTransition(() => {
      setCurrentViewItem(nodes.map((item) => item.id === id));
      reactFlow.setNodes((nodes) => nodes.map((item) => (item.id === id ? {...item, hidden: false} : item)));
    });
  };

  const removeThisWidget = (): void => {
    reactFlow.deleteElements({nodes: [{id}]});
    onClose?.();
  };

  const onClickClose = (e: MouseEvent): void => {
    e.stopPropagation();
    removeThisWidget();
  };

  return (
    <Container onClick={onClickMaximize}>
      <FontAwesomeIcon className="widget-icon" icon={data?.icon} />
      <div className="widget-title">
        {/*{data?.mPfdFileData?.fileName ? `Meta PFD - ${data?.mPfdFileData?.fileName}` : data?.title}*/}
        {data?.title}
      </div>

      <Button onClick={onClickClose} variant="none">
        <img src={getImage('close', {color: '#575660'})} className="close-button" alt="close button" />
      </Button>
    </Container>
  );
}
export default TaskbarItem;
