export const dayList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const changeTime = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // const period = hours >= 12 ? "PM" : "AM";
  const formatHours = hours % 12 || 12;
  const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formatHours}:${formatMinutes}`;
};

export const historyTimeChange = (time: any) => {
  const now = new Date();
  const dates: string[] = [];
  for (let i = 0; i < parseInt(time, 10); i++) {
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - i - 1);
    const year = yesterday.getFullYear();
    const month = ('0' + (yesterday.getMonth() + 1)).slice(-2);
    const day = ('0' + yesterday.getDate()).slice(-2);
    const merge = `${year}-${month}-${day}`;
    dates.push(merge);
  }
  return dates;
};

export const getFahrenheit = (f) => {
  return Math.round(f * 10) / 10;
};

export const getCelsius = (f) => {
  return Math.round((((f - 32) * 5) / 9) * 10) / 10;
};

export const API_KEY = '3f38cfdbb83e2d20e9236ea18db3dd5d';
