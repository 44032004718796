import {Handle} from 'reactflow';
import {Position} from '@reactflow/core';
import {WidgetTypes} from 'components/menu/types';
import {IWidgetNodeData} from 'components/pc/types';

type IProps = {
  type: WidgetTypes;
  data?: IWidgetNodeData;
};
function LocalDatabaseWidgetHandle({type, data}: IProps) {
  if (data?.docked) {
    return undefined;
  }

  if (
    type === 'DatasheetLocalDbWidget' ||
    type === 'PythonEditorWidget' ||
    type === 'MetaPfdWidget' ||
    type === 'TimeSeriesWidget' ||
    type === 'ModelRunnerWidget'
  ) {
    return (
      <>
        <Handle id="widget-edge-target" type="target" position={Position.Left} />
        <Handle id="widget-edge-source" type="source" position={Position.Right} />
      </>
    );
  }
  return undefined;
}
export default LocalDatabaseWidgetHandle;
