import React, {ChangeEvent} from 'react';
import styled from 'styled-components';
import {SelectForm, SwitchForm, TextForm} from 'components/forms';
import {IDataBindingOptionType, IDataBindingType} from 'components/pc/common/shapes/type';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div``;
const Content = styled.div`
  display: flex;
  .common-text-form {
    input {
      background-color: transparent;
    }
  }
`;

type IProps = {
  copy: IDataBindingType;
  onChangeCopy(copyD: Partial<IDataBindingType>): void;
};
function Option({copy, onChangeCopy}: IProps) {
  const {options} = copy;
  const {
    isDisplayTagInformation,
    isDisplayUnit,
    isBlinkWhenUpdate,
    isAutoUpdate,
    updateIntervalUnit,
    updateIntervalVal
  } = options;

  const onChangeBooleanValue = (e: ChangeEvent<HTMLInputElement>, optionName: keyof IDataBindingOptionType) => {
    const bool = options[optionName];
    onChangeCopy({options: {...copy.options, [optionName]: !bool}});
  };

  const onChangeNumberValue = (e: ChangeEvent<HTMLInputElement>, optionName: keyof IDataBindingOptionType) => {
    const {value} = e.target;
    if (isNaN(Number(value))) return;
    onChangeCopy({options: {...copy.options, [optionName]: Number(value)}});
  };

  return (
    <Container>
      <Row>
        <Title>Live update</Title>
        <Content>
          <SwitchForm
            name="live-update"
            id="live-update"
            checked={isAutoUpdate}
            onChange={(e) => onChangeBooleanValue(e, 'isAutoUpdate')}
          />
        </Content>
      </Row>
      <Row>
        <Title>Update interval</Title>
        <Content>
          <TextForm
            value={String(updateIntervalUnit)}
            onChange={(e) => onChangeNumberValue(e as ChangeEvent<HTMLInputElement>, 'updateIntervalUnit')}
          />
          <SelectForm
            options={TimeUnitOptions}
            name="update-interval"
            id="update-interval"
            value={updateIntervalVal}
            onChange={(e) => onChangeNumberValue(e as ChangeEvent<HTMLInputElement>, 'updateIntervalVal')}
          />
        </Content>
      </Row>
      <Row>
        <Title>Blink when update value</Title>
        <Content>
          <SwitchForm
            name="blink-when-update-value"
            id="blink-when-update-value"
            checked={isBlinkWhenUpdate}
            onChange={(e) => onChangeBooleanValue(e, 'isBlinkWhenUpdate')}
          />
        </Content>
      </Row>
      <Row>
        <Title>Display tag information</Title>
        <Content>
          <SwitchForm
            name="display-tag-information"
            id="display-tag-information"
            checked={isDisplayTagInformation}
            onChange={(e) => onChangeBooleanValue(e, 'isDisplayTagInformation')}
          />
        </Content>
      </Row>
      <Row>
        <Title>Display unit</Title>
        <Content>
          <SwitchForm
            name="display-unit"
            id="display-unit"
            checked={isDisplayUnit}
            onChange={(e) => onChangeBooleanValue(e, 'isDisplayUnit')}
          />
        </Content>
      </Row>
    </Container>
  );
}

export default Option;
