import {LocalStorageManager} from 'utils/local-storage-manager';
import {getApiHost} from 'api/function';

type ContentTypes = 'application/json' | undefined;
const API_HOST = getApiHost();

type IOptions<T> = {
  type?: 'default' | 'binary' | 'blob' | 'formData'; // binary 는 2024-06-18 현재 VoiceCommand 에서만 사용
  errorReturn?: T;
};

const getHeaders = <T>(withToken?: boolean, options?: IOptions<T>, contentType: ContentTypes = 'application/json') => {
  const token = LocalStorageManager.getItem<string>('PROCESSMETAVERSE_LOGIN_TOKEN');
  const Authorization = withToken ? `Bearer ${token}` : undefined;
  switch (options?.type) {
    case 'binary':
      return {
        Authorization
      };
    case 'formData':
      return {
        Authorization
      };
  }
  return {
    Authorization,
    'Content-Type': contentType
  };
};

/**
 * api 응답 성공
 * @param response
 * @param options
 */
const onSuccess = <T>(response: Response, options?: IOptions<T>) => {
  if (response.status >= 400 && response.status < 600) {
    if (options.errorReturn) return options.errorReturn as T;

    const event = new CustomEvent('ServerEvent', {detail: `${response.status} Error : Bad response from server`});
    document.dispatchEvent(event);
    throw new Error(`${response.status} Error : Bad response from server`);
  }
  return response.json() as T;
};

/**
 * api 응답 실패
 * @param error
 */
const onError = (error: Error) => {
  const event = new CustomEvent('ServerEvent', {detail: 'API Error.'});
  document.dispatchEvent(event);
  throw new Error('API Error.', error);
};

export const api = {
  /**
   * API Get
   * @param url API 주소
   * @param options
   */
  async get<T>(url: string, options?: IOptions<T>): Promise<T> {
    return fetch(`${API_HOST}${url}`, {method: 'GET', headers: getHeaders()})
      .then((response) => onSuccess(response, options))
      .then((response) => response)
      .catch(onError);
  },

  /**
   * API Post
   * @param url API 주소
   * @param payload 전송할 정보 객체
   * @param options
   */
  async post<T>(url: string, payload: object, options?: IOptions<T>): Promise<T> {
    const headers = getHeaders(true, options);
    const body =
      options?.type === 'binary' || options?.type === 'formData' ? (payload as BodyInit) : JSON.stringify(payload);
    return fetch(`${API_HOST}${url}`, {method: 'POST', headers, body})
      .then((response) => onSuccess(response, options))
      .then((response) => response)
      .catch(onError);
  },

  /**
   * API img Get
   * @param url API 주소
   */
  async getImgBlob(url: string): Promise<Blob> {
    return fetch(`${API_HOST}${url}`, {method: 'GET', headers: getHeaders()})
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          const event = new CustomEvent('ServerEvent', {detail: `${response.status} Error : Bad response from server`});
          document.dispatchEvent(event);
          throw new Error(`${response.status} Error : Bad response from server`);
        }
        return response.blob();
      })
      .then((response) => response)
      .catch(onError);
  },

  async exportFile(url: string, payload: object): Promise<Response> {
    return fetch(`${API_HOST}${url}`, {method: 'POST', headers: getHeaders(true), body: JSON.stringify(payload)}).then(
      async (response) => response
    );
  }
};
