import React from 'react';
import {getImage} from 'assets/images/svg-image';
function StrokeSvg({type}) {
  switch (type) {
    case 'solid line':
      return <img src={getImage('shapeToolBoxLineShapeOption1')} className="close-button" alt="close button" />;
    case 'dotted line':
      return <img src={getImage('shapeToolBoxLineShapeOption2')} className="close-button" alt="close button" />;
    case 'dashed line':
      return <img src={getImage('shapeToolBoxLineShapeOption3')} className="close-button" alt="close button" />;
    default:
      return <></>;
  }
}

export default StrokeSvg;
