import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

type StyleProps = {
  $formLabelWidth: number;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  padding: 10px 0;
  height: 30px;

  .common-select-form {
    height: 30px;
    padding: 0 10px;
  }

  &.multiline {
    width: 100%;
    flex: 1 1;
  }

  &.multiLine-Header {
    width: 100%;
    flex-direction: column;
  }

  &.zero {
    padding: 0;
  }
`;

const FormLabel = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  width: ${(props) => props.$formLabelWidth + '%'};
`;

const FormData = styled.div<StyleProps>`
  display: flex;
  justify-content: right;
  width: ${(props) => 100 - props.$formLabelWidth + '%'};
  gap: 10px;

  &.multiline {
    width: 100%;
  }

  &.multiLine-Header {
    width: 100%;
    margin-top: 10px;
  }

  &.double {
    width: 60%;
  }
`;

type IProps = PropsWithChildren & {
  header?: string;
  headerWidth?: number; // in percent
  type?: 'default' | 'multiline' | 'double' | 'multiLine-Header';
  padding?: 'basic' | 'zero';
};

function FormRow({header, headerWidth = 50, children, type = 'default', padding = 'basic'}: IProps): ReactElement {
  return (
    <Container className={classNames('common-form-row', type, padding)}>
      {(type === 'default' || type === 'double' || type === 'multiLine-Header') && (
        <FormLabel $formLabelWidth={headerWidth}>{header}</FormLabel>
      )}
      <FormData $formLabelWidth={headerWidth} className={classNames('common-form-data', type)}>
        {children}
      </FormData>
    </Container>
  );
}

export default FormRow;
