import React from 'react';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import {getMouseHoverType, getRegionColor} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import MetaPfdWidgetPoint from 'components/pc/widgets/metaPfd/annot/MetaPfdWidgetPoint';
import MetaPfdWidgetBox from 'components/pc/widgets/metaPfd/annot/MetaPfdWidgetBox';
import MetaPfdWidgetLine from 'components/pc/widgets/metaPfd/annot/MetaPfdWidgetLine';

const annotationComponents = {
  'expanding-line': MetaPfdWidgetLine,
  point: MetaPfdWidgetPoint,
  box: MetaPfdWidgetBox,
  port: MetaPfdWidgetBox
};

type IProps = {
  type: string;
  region: IAnnotation;
  iw: number;
  ih: number;
  cfg: IPfdCfg;
};

function MetaPfdWidgetAnnotation({type, region, iw, ih, cfg}: IProps) {
  const color = getRegionColor(region);
  const drawLineMode = false;
  const {pointHoverType, boxHoverType, polyLineHoverType, pointOfLineHoverType} = getMouseHoverType('select');
  const AnnotationComponent = annotationComponents[type];
  if (!AnnotationComponent) return null;

  return (
    <AnnotationComponent
      iw={iw}
      ih={ih}
      region={region}
      drawLineMode={drawLineMode}
      cfg={cfg}
      color={color}
      pointHoverType={pointHoverType}
      polyLineHoverType={polyLineHoverType}
      boxHoverType={boxHoverType}
      pointOfLineHoverType={pointOfLineHoverType}
    />
  );
}

export default MetaPfdWidgetAnnotation;
