import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: calc(50% - 26px);
  left: 10px;
  width: 60px;
  height: 55px;
  cursor: pointer;
  display: flex;
  color: ${({theme}) => theme.color.secondary};
  opacity: 0.5;
  transition: opacity 0.2s;

  > span {
    margin: auto 10px;
    font-size: 13px;
    font-weight: 600;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: 3px;
    height: 30px;
    background-color: #292d39;
    position: absolute;
    left: 0;
  }
  &:before {
    transform: rotate(25deg);
  }
  &:after {
    transform: rotate(-25deg);
    top: 26px;
  }
  &:hover {
    opacity: 1;
    &:before,
    &:after {
    }
  }
`;

type IProps = PropsWithChildren & {
  onClick(): void;
};

function PrevButton({children, onClick}: IProps): ReactElement {
  return (
    <Container onClick={onClick}>
      <span>{children}</span>
    </Container>
  );
}

export default PrevButton;
