import {ChangeEvent, ReactElement, useEffect, useState, FocusEvent, useContext} from 'react';
import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import {SelectForm, TextForm} from 'components/forms';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/pro-light-svg-icons';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  padding: 5px 7px;
  box-sizing: border-box;
  .common-text-form {
    position: static;
  }
  input[type='text'] {
    border: 1px solid #b6b4c0;
    height: 24px;
    background-color: #ffffff;
    font-size: 14px;
    box-sizing: border-box;
  }

  select {
    border: 1px solid #b6b4c0;
    height: 24px;
    background-color: #ffffff;
    font-size: 14px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  &.value {
    justify-content: flex-end;
  }

  &.path {
    color: #394add;
    span,
    svg {
      cursor: pointer;
    }
  }
`;

const BtnContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 9px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #373737;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #373737;
  cursor: pointer;
`;

const BtnContainer_2 = styled.div`
  position: absolute;
  right: 16px;
  top: 9px;

  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #373737;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #373737;
  cursor: pointer;
`;

type IProps = {
  field: ITableField;
  th?: boolean;
  keys: string;
  onChange(e: ChangeEvent): void;
  onClick?(rowKey: string, columnKey?: string, key?: string, value?: string[]): void;
  value: string;
};

function PythonVariableTableParts({
  keys,
  field,
  onChange,
  th,
  onClick,
  value: headerValue,
  ...rest
}: IProps): ReactElement {
  const {globalSettingsState} = useContext(DataContext);
  const {significantDigit} = globalSettingsState[0];
  const value = rest[field?.key];
  const [str, setStr] = useState<string>(value);
  const [strArr] = useState<string[]>(value);

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    setStr(e.target.value);
    onChange?.(e);
    return;
  };

  const onBlur = (e) => {
    // e.target.value = str;
    // onChange?.(e);
  };

  switch (field?.key) {
    case 'variableName': {
      return (
        <Container>
          <TextForm id={keys} name={field?.key} onChange={onChangeValue} value={str} onBlur={onBlur} />
        </Container>
      );
    }
    case 'flowType': {
      return (
        <Container>
          <SelectForm
            id={keys}
            name={field?.key}
            value={str}
            onChange={onChangeValue}
            options={[
              {
                label: 'Inflow',
                value: 'Inflow'
              },
              {
                label: 'Outflow',
                value: 'Outflow'
              }
            ]}
          />
        </Container>
      );
    }
    case 'variableValue': {
      return <Container className={classnames(field?.key)}>{toSignificantDigit(value, significantDigit)}</Container>;
    }
    case 'path': {
      if (th) {
        return (
          <>
            <div>{headerValue}</div>
            <BtnContainer onClick={() => onClick('', '')}>
              <FontAwesomeIcon icon={faPlus} />
            </BtnContainer>
          </>
        );
      } else {
        return (
          <>
            <Container className={classnames(field?.key, rest['flowType'])}>
              <span onClick={() => onClick(field?.key, 'change_path', keys, strArr)}>
                {value?.join('.') ? value?.join('.') : 'Select Path'}
              </span>
              <BtnContainer_2
                onClick={() => {
                  onClick(field?.key, 'delete_row', keys);
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </BtnContainer_2>
            </Container>
          </>
        );
      }
    }
    default: {
      return <Container></Container>;
    }
  }
}

export default PythonVariableTableParts;
