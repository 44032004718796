import {useEffect} from 'react';
import {useThree} from '@react-three/fiber';
import * as THREE from 'three';

const WhiteBackground = () => {
  const {scene} = useThree();

  useEffect(() => {
    scene.background = new THREE.Color('#e7edf6');
  }, [scene]);

  return null; // No need to render any additional components here
};

export default WhiteBackground;
