import React, {ChangeEvent, useState} from 'react';
import {Button} from 'components/forms';
import AssignedItem, {NameTypes} from 'components/auth/management/parts/AssignedItem';
import SelectForm from 'components/forms/SelectForm';
import {GroupInfo, RoleInfo} from '../management-api-function';

type IProps = {
  title: string;
  currentList?: RoleInfo[] | GroupInfo[];
  currentNameList: {value: string; label: string}[];
  parentName?: string;
  nameType: NameTypes;
  placeholderName: string;
  selectedName: string;
  setSelectedName: (selectedName: string) => void;
  items: string[];
  setItems: (items: string[]) => void;
  descriptionOfItem: (item: string) => string;
};

function AssignedItemsList({
  title,
  currentList,
  currentNameList,
  parentName,
  nameType,
  placeholderName,
  selectedName,
  setSelectedName,
  items,
  setItems,
  descriptionOfItem
}: IProps) {
  const [isShowList, setIsShowList] = useState<boolean>(false);

  const onClickShowListBtn = () => {
    setIsShowList(true);
  };

  const onClickDeleteAssignedBtn = (item_name: string) => {
    setItems(items.filter((item) => item !== item_name));
  };

  const onChangeSelectForm = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setSelectedName(value);
    if (!items.includes(value)) {
      setItems([...items, value]);
    }
    setIsShowList(false);
  };

  return (
    <>
      <>{title}</>
      <Button variant="light" width="60px" onClick={onClickShowListBtn}>
        +
      </Button>
      {isShowList && currentList && currentList.length > 0 && (
        <SelectForm
          name={placeholderName}
          placeholder={placeholderName}
          disabledPlaceholder={true}
          options={currentNameList}
          value={selectedName}
          onChange={onChangeSelectForm}
        ></SelectForm>
      )}
      {items &&
        items.map((item) => (
          <AssignedItem
            key={item}
            parentName={parentName}
            name={item}
            nameType={nameType}
            description={descriptionOfItem(item)}
            isEdit={true}
            onClickDeleteAssigned={() => onClickDeleteAssignedBtn(item)}
          />
        ))}
    </>
  );
}

export default AssignedItemsList;
