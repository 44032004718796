import React, {useCallback, useContext, useRef} from 'react';
import {BaseEdge, useReactFlow, useStore, Edge, EdgeProps, XYPosition, EdgeLabelRenderer} from 'reactflow';

import {ControlPoint, IControlPointData} from './ControlPoint';
import {getPath, getControlPoints} from './path';
import {Algorithm, COLORS} from './constants';
import styled from 'styled-components';
import {LocalDatabaseContext} from 'api/LocalDatabaseProvider';

const EdgeButton = styled.button`
  width: 20px;
  height: 20px;
  background: #fff3b8;
  border: 2px solid #00616e;
  color: #00616e;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bolder;
  line-height: 1em;
  //z-index: 9999;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
`;

const useIdsForInactiveControlPoints = (points: IControlPointData[]) => {
  const ids = useRef<string[]>([]);

  if (ids.current.length === points.length) {
    return points.map((point, i) => (point.id ? point : {...point, id: ids.current[i]}));
  } else {
    ids.current = [];

    return points.map((point, i) => {
      if (!point.id) {
        const id = window.crypto.randomUUID();
        ids.current[i] = id;
        return {...point, id: id};
      } else {
        ids.current[i] = point.id;
        return point;
      }
    });
  }
};

export type EditableEdgeData = {
  algorithm?: Algorithm;
  points: IControlPointData[];
};

export function EditableEdge({
  id,
  selected,
  source,
  sourceX,
  sourceY,
  sourcePosition,
  target,
  targetX,
  targetY,
  targetPosition,
  markerEnd,
  markerStart,
  style,
  data = {points: []},

  ...delegated
}: EdgeProps<EditableEdgeData>) {
  const sourceOrigin = {x: sourceX, y: sourceY} as XYPosition;
  const targetOrigin = {x: targetX, y: targetY} as XYPosition;

  const color = COLORS[data.algorithm ?? Algorithm.Linear];

  const {afterEdgesChange} = useContext(LocalDatabaseContext);
  const {setEdges, getNodes} = useReactFlow();

  const shouldShowPoints = useStore((store) => {
    const sourceNode = store.nodeInternals.get(source)!;
    const targetNode = store.nodeInternals.get(target)!;

    return selected || sourceNode.selected || targetNode.selected;
  });

  const setControlPoints = useCallback(
    (update: (points: IControlPointData[]) => IControlPointData[]) => {
      setEdges((edges) =>
        edges.map((e) => {
          if (e.id !== id) return e;
          if (!isEditableEdge(e)) return e;

          const points = e.data?.points ?? [];
          const data = {...e.data, points: update(points)};

          return {...e, data};
        })
      );
    },
    [id, setEdges]
  );

  const pathPoints = [sourceOrigin, ...data.points, targetOrigin];
  const controlPoints = getControlPoints(pathPoints, Algorithm.Linear, {
    fromSide: sourcePosition,
    toSide: targetPosition
  });
  const path = getPath(pathPoints, Algorithm.Linear, {
    fromSide: sourcePosition,
    toSide: targetPosition
  });

  const controlPointsWithIds = useIdsForInactiveControlPoints(controlPoints);

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    setEdges(function (prevEdges) {
      const newEdges = prevEdges.filter((edge) => edge.id !== id);
      afterEdgesChange(getNodes(), newEdges);
      return newEdges;
    });
  };
  return (
    <>
      <BaseEdge
        id={id}
        path={path}
        {...delegated}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeWidth: 5,
          stroke: color
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-10%, -140%) translate(${sourceX}px,${sourceY}px)`,

            fontSize: 12,
            pointerEvents: 'all',
            zIndex: style?.zIndex as number
          }}
          className="nodrag nopan"
        >
          <EdgeButton onClick={onEdgeClick}>×</EdgeButton>
        </div>
      </EdgeLabelRenderer>
      {shouldShowPoints &&
        controlPointsWithIds.map((point, index) => (
          <ControlPoint key={point.id} index={index} setControlPoints={setControlPoints} color={color} {...point} />
        ))}
    </>
  );
}

const isEditableEdge = (edge: Edge): edge is Edge<EditableEdgeData> => edge.type === 'editableEdge';
