import DefaultTable, {ITableField} from 'components/table/DefaultTable';
import {ISelectedRowState} from 'components/pc/widgets/timeseries/types';
import styled from 'styled-components';
import {defaultColorBlack, defaultColorLightGoldenrodYellow} from 'components/pc/common/shapes/const';
import ReactColorPicker from 'components/table/parts/ReactColorPicker';
import Icon from 'components/table/parts/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import InputTextValue from 'components/table/parts/InputTextValue';
import {IDataBindingType, IDataDisplay} from 'components/pc/common/shapes/type';
import {ChangeEvent, useEffect, useState} from 'react';
import {getUniqueKey} from 'utils/commons';
import {ColorResult} from 'react-color';
import InputMinMaxValue from 'components/table/parts/InputMinMaxValue';

const Container = styled.div`
  width: 761px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border: 1px solid rgba(54, 55, 121, 0.12);
  background-color: rgba(54, 55, 121, 0.05);
  margin: 0 9px;
  box-sizing: border-box;
`;

const AddBtnContainer = styled.div``;
const AddBtn = styled.div`
  width: 60px;
  height: 20px;
  border: 1px solid #575660;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 15px auto;
  cursor: pointer;
  &:hover {
    background-color: #edf6ff;
  }
`;

const fields: ITableField[] = [
  {
    key: 'name',
    label: 'Name',
    style: {header: {width: 150}},
    css: {body: 'ellipsis'},
    component: InputTextValue
  },
  {
    key: 'max',
    label: 'Max',
    style: {header: {width: 114}},
    css: {body: 'ellipsis'},
    component: InputMinMaxValue,
    keyOfMinMax: {
      min: 'min',
      max: 'max'
    }
  },
  {
    key: 'min',
    label: 'Min',
    style: {header: {width: 114}},
    css: {body: 'ellipsis'},
    component: InputMinMaxValue,
    keyOfMinMax: {
      min: 'min',
      max: 'max'
    }
  },
  {
    key: 'fontColor',
    label: 'Font',
    style: {header: {width: 79}},
    css: {body: 'ellipsis'},
    component: ReactColorPicker
  },
  {
    key: 'bgColor',
    label: 'BG',
    style: {header: {width: 79}},
    css: {body: 'ellipsis'},
    component: ReactColorPicker
  },
  {
    key: 'borderColor',
    label: 'Border',
    style: {header: {width: 79}},
    css: {body: 'ellipsis'},
    component: ReactColorPicker
  },
  {
    key: 'icon',
    label: '',
    style: {header: {width: 52}},
    css: {body: 'ellipsis'},
    icon: {
      component: <FontAwesomeIcon icon={faTrashCan} />,
      actionType: 'delete'
    },
    component: Icon
  }
];

type IProps = {
  copy: IDataBindingType;
  // selectedRowState: ISelectedRowState;
  onChangeCopy(copyD: Partial<IDataBindingType>): void;
};

function Display({copy, onChangeCopy}: IProps) {
  const {liveDisplay} = copy;
  const {list} = liveDisplay;
  const replication = [...list];
  const [row, setRow] = useState<IDataDisplay[]>([]);

  useEffect(() => {
    const updatedRows: IDataDisplay[] = replication.map((item) => ({
      ...item,
      flattenKeys: item.keys,
      onChange,
      onClick,
      onChangeColorPicker
    }));
    setRow(updatedRows);
  }, [copy, replication]);

  const onAddLiveDisplay = () => {
    const newList = replication.concat([
      {
        name: '',
        max: 0,
        min: 0,
        borderColor: defaultColorBlack,
        bgColor: defaultColorLightGoldenrodYellow,
        fontColor: defaultColorBlack,
        keys: getUniqueKey()
      } as IDataDisplay
    ]);
    onChangeCopy({
      liveDisplay: {
        list: newList
      }
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value, title, name} = e.target;
    let updatedItem = replication.find((item) => item.keys === name);
    if (!updatedItem) return;
    if (title === 'min' || title === 'max') {
      updatedItem = {...updatedItem, [title]: value === undefined || value === '' ? value : Number(value)};
    } else {
      updatedItem = {...updatedItem, [title]: value};
    }
    const updatedList = replication.map((item) => (item.keys === name ? updatedItem : item));
    onChangeCopy({liveDisplay: {list: updatedList}});
  };

  const onClick = (name: string, title: string, actionType: string) => {
    if (actionType === 'delete') {
      const updatedList = replication.filter((item) => item.keys !== name);
      onChangeCopy({liveDisplay: {list: updatedList}});
    }
  };

  const onChangeColorPicker = (name: string, title: string, color: ColorResult) => {
    let updatedItem = replication.find((item) => item.keys === name);
    if (!updatedItem) return;
    updatedItem = {...updatedItem, [title]: color};
    const updatedList = replication.map((item) => (item.keys === name ? updatedItem : item));
    onChangeCopy({liveDisplay: {list: updatedList}});
  };

  return (
    <Container>
      <DefaultTable fields={fields} fieldKey="key" fieldLabelKey="label" rows={row} rowKey="keys">
        <AddBtnContainer>
          <AddBtn onClick={onAddLiveDisplay}>
            <FontAwesomeIcon icon={faPlus} />
          </AddBtn>
        </AddBtnContainer>
      </DefaultTable>
    </Container>
  );
}

export default Display;
