import {ShapeComponents} from 'components/pc/common/shapes/parts/svg';
import styled from 'styled-components';
import {IShapeComponentProps} from 'components/pc/common/shapes/type';

const SvgContainer = styled.svg`
  overflow: visible;
  display: block;
  margin: auto;
`;

function Shape({type, width, height, isNode = false, ...svgAttributes}: IShapeComponentProps) {
  const ShapeComponent = ShapeComponents[type];

  if (!ShapeComponent || !width || !height) {
    return null;
  }

  const strokeWidth = svgAttributes.strokeWidth ? (svgAttributes.strokeWidth as number) : 0;
  const innerWidth = width - strokeWidth > 0 ? width - strokeWidth : 0;
  const innerHeight = height - strokeWidth > 0 ? height - strokeWidth : 0;
  const stokeW = strokeWidth * 0.6;

  if (isNode && type === 'DataBinding') {
    const TextComponent = ShapeComponents.Rectangle;
    if (!TextComponent || !width || !height) {
      return null;
    }
    return (
      <SvgContainer width={width} height={height}>
        <g transform={`translate(${stokeW ?? 0}, ${stokeW ?? 0})`}>
          <TextComponent width={innerWidth} height={innerHeight} {...svgAttributes} />
        </g>
      </SvgContainer>
    );
  } else if (isNode && type === 'Text') {
    const TextComponent = ShapeComponents.Rectangle;
    if (!TextComponent || !width || !height) {
      return null;
    }
    return (
      <SvgContainer width={width} height={height}>
        <g transform={`translate(${stokeW ?? 0}, ${stokeW ?? 0})`}>
          <TextComponent width={innerWidth} height={innerHeight} {...svgAttributes} />
        </g>
      </SvgContainer>
    );
  } else {
    return (
      <SvgContainer width={width} height={height}>
        <g transform={`translate(${stokeW ?? 0}, ${stokeW ?? 0})`}>
          <ShapeComponent width={innerWidth} height={innerHeight} {...svgAttributes} />
        </g>
      </SvgContainer>
    );
  }
}

export default Shape;
