import React, {useRef} from 'react';
import {useFrame} from '@react-three/fiber';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

// Define your animated texture parameters
const textureSpeed = 0.1; // Adjust the speed of the texture animation
const initialTextureOffsetY = 0; // Initial Y offset of the texture
const reverseFlow = true; // Set to true for bottom-to-top animation

export function ModelPipe2Line() {
  const {nodes, materials} = useGltfCustom('/gltf/Pipe_2_Line/Pipes_Pipe_2_Line.gltf');
  // Access the material of the pipes
  const pipesMaterial = materials.Pipe_2_Line;

  // Reference to the material's map (texture)
  const texture = useRef(pipesMaterial.emissiveMap);

  pipesMaterial.emissiveIntensity = 1;

  // Use a reference to store the current texture offset
  const textureOffsetY = useRef(initialTextureOffsetY);

  // Animate the texture offset in the useFrame callback
  useFrame(({clock}) => {
    // Calculate the new texture offset based on time, speed, and flow direction
    const directionMultiplier = reverseFlow ? -1 : 1;
    textureOffsetY.current = (initialTextureOffsetY + clock.elapsedTime * textureSpeed * directionMultiplier) % 1;

    // Update the texture offset
    texture.current.offset.y = textureOffsetY.current;

    // Trigger a material update to apply the new texture offset
    pipesMaterial.needsUpdate = true;
  });
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        material-transparent
        material-opacity={0.75}
        geometry={nodes.Pipe_2_Line_1.geometry}
        material={pipesMaterial}
        position={[44.084, 11.151, 41.372]}
        rotation={[0, 1.571, 0]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/Pipe_2_Line/Pipes_Pipe_2_Line.gltf');
