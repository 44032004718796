import styled from 'styled-components';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Button} from 'components/forms';
import {capitalize} from 'utils/commons';
import classnames from 'classnames';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {IManagementAsideInfo, IEditGroupInfo} from 'components/auth/management/type';
import AssignedItemsList from 'components/auth/management/parts/AssignedItemsList';
import {RoleInfo} from 'components/auth/management/management-api-function';
import ConfirmModal from 'components/common/ConfirmModal';

type StyleProps = {
  $isMiddle: boolean;
};

const Wrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: ${(props) => (props.$isMiddle ? 0 : 1)};
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  input,
  textarea {
    border: 1px solid #b6b4c0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 13px 12px;
    &::placeholder {
      color: #b6b4c0;
    }
    &.disabled {
      cursor: not-allowed;
      filter: brightness(0.92);
    }
  }

  input {
    height: 40px;
  }
  textarea {
    height: 160px;
    resize: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  .common-button {
    font-weight: 600;
    color: white;
  }
`;

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.color.invalid} !important;
  font-weight: 400;
  font-size: 15px;
`;

type IProps = {
  currentRoleList?: RoleInfo[];
  asideInfo: IManagementAsideInfo;
  activateCancelButton?: boolean;
  onClose(): void;
  onClickCreate(name: string, description: string, roles: string[]): void;
  onClickDelete?(name: string, description: string, roles: string[]): void;
};

function GroupManagementAside({
  currentRoleList,
  asideInfo,
  activateCancelButton = true,
  onClose,
  onClickCreate,
  onClickDelete
}: IProps) {
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [roles, setRoles] = useState<string[]>([]);
  const textareaRef = useRef(null);
  const [roleName, setRoleName] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useModalKeyBoardEvent({
    onConfirm: () => {
      const activeElement = document.activeElement;
      if (textareaRef.current !== activeElement) {
        onClickCreateBtn();
      }
    },
    onCancel: () => onClose()
  });

  useEffect(() => {
    if (asideInfo?.info) {
      const groupInfo = asideInfo.info as IEditGroupInfo;
      if ('groupName' in groupInfo) setGroupName(groupInfo.groupName || '');
      if ('description' in groupInfo) setDescription(groupInfo.description || '');
      if ('roles' in groupInfo) setRoles(groupInfo.roles || []);
    }
  }, [asideInfo]);

  const onClickCreateBtn = () => {
    if (!groupName) return;
    onClickCreate(groupName, description, roles);
  };

  const onClickDeleteBtn = () => {
    if (!groupName) return;
    setOpenConfirmModal(true);
  };

  const onClickDeleteConfirmBtn = () => {
    onClickDelete(groupName, description, roles);
  };

  const onClickCancelBtn = () => {
    onClose();
  };

  const onChangeName = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setGroupName(value);
  };

  const onChangeDescription = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setDescription(value);
  };

  const currentRoleNameList = currentRoleList?.map((role) => ({value: role.name, label: role.name}));

  const descriptionOfRole = (targetRole: string) => {
    if (!currentRoleList || currentRoleList.length === 0) return;
    const matchingRole = currentRoleList.find((role) => role.name === targetRole);
    return matchingRole?.description;
  };

  return (
    <>
      {openConfirmModal && (
        <ConfirmModal
          confirmHeaderMessage={'Delete Group'}
          confirmBodyMessage={'Click to delete group'}
          onConfirm={onClickDeleteConfirmBtn}
          setOpenConfirmModal={setOpenConfirmModal}
        ></ConfirmModal>
      )}
      <Wrapper $isMiddle={false}>
        <InputContainer>
          <input
            className={classnames(Boolean(asideInfo?.type === 'edit') && 'disabled')}
            onChange={onChangeName}
            value={groupName}
            placeholder="Group name"
            disabled={Boolean(asideInfo?.type === 'edit')}
          />
        </InputContainer>
        <InputContainer>
          <textarea
            ref={textareaRef}
            onChange={onChangeDescription}
            value={description}
            placeholder="Group description"
          />
        </InputContainer>
        {asideInfo?.type && (asideInfo?.type === 'create' || asideInfo?.type === 'edit') && (
          <Wrapper $isMiddle={true}>
            <AssignedItemsList
              title="Assign roles"
              currentList={currentRoleList as RoleInfo[]}
              currentNameList={currentRoleNameList}
              parentName={groupName}
              nameType="role"
              placeholderName="Assign Role To Group"
              selectedName={roleName}
              setSelectedName={setRoleName}
              items={roles}
              setItems={setRoles}
              descriptionOfItem={descriptionOfRole}
            />
          </Wrapper>
        )}
        <ErrorMessage>{asideInfo?.errorMsg}</ErrorMessage>
      </Wrapper>
      <ButtonContainer>
        {asideInfo?.deleteVariant && asideInfo?.deleteLabel && (
          <Button variant={asideInfo?.deleteVariant} className={asideInfo?.deleteLabel} onClick={onClickDeleteBtn}>
            {capitalize(asideInfo?.deleteLabel)}
          </Button>
        )}
        <Button
          width={108}
          variant={asideInfo?.createVariant}
          className={asideInfo?.createLabel}
          onClick={onClickCreateBtn}
        >
          {capitalize(asideInfo?.createLabel)}
        </Button>
        {activateCancelButton && (
          <Button variant={'cancel'} className={'cancel'} onClick={onClickCancelBtn}>
            {capitalize('Cancel')}
          </Button>
        )}
      </ButtonContainer>
    </>
  );
}

export default GroupManagementAside;
