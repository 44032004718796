import React, {useContext} from 'react';
import {EdgeLabelRenderer, EdgeProps, getBezierPath, StepEdge, useReactFlow} from 'reactflow';
import styled from 'styled-components';
import {LocalDatabaseContext} from 'api/LocalDatabaseProvider';

const EdgeButton = styled.button`
  width: 20px;
  height: 20px;
  background: #fff3b8;
  border: 2px solid #00616e;
  color: #00616e;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bolder;
  line-height: 1em;
  //z-index: 9999;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }
`;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd
}: EdgeProps) {
  const {afterEdgesChange} = useContext(LocalDatabaseContext);
  const {setEdges, getNodes} = useReactFlow();
  const [, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
    setEdges(function (prevEdges) {
      const newEdges = prevEdges.filter((edge) => edge.id !== id);
      afterEdgesChange(getNodes(), newEdges);
      return newEdges;
    });
  };

  return (
    <>
      <StepEdge
        id={id}
        source={source}
        target={target}
        sourceX={sourceX}
        sourceY={sourceY}
        targetX={targetX}
        targetY={targetY}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        style={style}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
            zIndex: style?.zIndex as number
          }}
          className="nodrag nopan"
        >
          <EdgeButton onClick={onEdgeClick}>×</EdgeButton>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
