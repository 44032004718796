import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import React, {useContext, useState, useEffect} from 'react';

type IToolbox = {
  name: 'ArrowNormal';
  icon: string | null;
  type: 'ArrowNormal';
  rightLines?: boolean;
  active?: boolean;
};

type IToolInfo = {
  x: number;
  y: number;
  toolList: IToolbox[];
};

function FormToolBox() {
  const {state} = useContext(MetaPfdContext);
  const [, setToolInfo] = useState<IToolInfo>();

  useEffect(() => {
    switch (state?.mode?.mode) {
      case 'SELECT_POINT_OF_LINE': {
        break;
      }
      default:
        setToolInfo(null);
    }
  }, [state?.mode]);

  return <></>;
}

export default FormToolBox;
