import styled from 'styled-components';
import ManagementHeader from 'components/auth/management/parts/ManagementHeader';
import {managementConst} from 'components/auth/management/const';
import ManagementTable from 'components/auth/management/parts/ManagementTable';
import {UserInfo} from 'components/auth/management/management-api-function';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

type IProps = {
  userList: UserInfo[];
  openCreateUserAside(): void;
  onClickUserManagementRow(rowkey: string, columnKey?: string): void;
};

function UserManagement({userList, openCreateUserAside, onClickUserManagementRow}: IProps) {
  return (
    <Container>
      <ManagementHeader
        title={managementConst.user.userManagementHeader.title}
        description={managementConst.user.userManagementHeader.description}
      />
      <ManagementTable
        fields={managementConst.user.userFields}
        list={userList}
        listType="user"
        onClickCreate={openCreateUserAside}
        onClickRow={onClickUserManagementRow}
        rowKeyName="username"
        needColumnKey={true}
      />
    </Container>
  );
}

export default UserManagement;
