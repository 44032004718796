import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDownToLine} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import {ReactElement} from 'react';

const Container = styled.div`
  padding: 10px;
  border-radius: 20px;
  position: fixed;
  bottom: 10px;
  left: ${({theme}) => parseInt(theme.app.menu.width) + parseInt(theme.action.menu.width) / 2 - 10 + 'px'};
  backdrop-filter: blur(10px);
  opacity: 0.8;
  color: #cb7faa;
`;

function ScrollLoader(): ReactElement {
  return (
    <Container>
      <FontAwesomeIcon icon={faArrowDownToLine} />
    </Container>
  );
}

export default ScrollLoader;
