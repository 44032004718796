import {ReactElement, useContext, useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisVertical} from '@fortawesome/pro-light-svg-icons';
import HistoryOption from 'components/menu/copilot/HistoryOption';
import {ICopilotThread} from 'components/menu/copilot/types';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';

const Container = styled.div`
  flex: 1;
  position: relative;
  height: calc(100% - 50px);
  overflow-y: scroll;
  //background-color: #c7a600;
  //height: calc(100% - 100px);
  //height: 100%;
`;

const History = styled.div`
  list-style: none;
  font-size: 15px;
  font-weight: 400;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 16px 5px 10px;
  color: #b1b1b1;
  &:hover {
    color: #ffffff;
  }

  li {
    cursor: pointer;
    padding: 0 6px;
  }

  li[aria-disabled='true'] {
    cursor: default;
  }

  &.active {
    color: #ffffff;
    background-color: #545454ff;
    //opacity: 20%;
    padding: 5px 16px 5px 10px;
    //border-radius: 3px;
  }

  .history-option {
    padding-left: 8px;
    padding-right: 8px;
    color: #939393;
    margin-right: -10px;
    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }
`;

const OptionIcon = styled.div`
  &:hover {
    color: #fff;
    cursor: pointer;
  }
  [aria-disabled='true'] {
    cursor: default;
  }
`;

/*const OptionIcon = styled.div`
    &:hover {
        color: #fff;
        cursor: ${({aria-disabled}) => (aria-disabled ? 'default' : 'pointer')};
    }
`;*/

export function formatDate(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')} 
    ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
}

type IItemProps = {
  item: ICopilotThread;
  isActive: boolean;
  onClickThreadItem(thread_id: string): void;
};

function HistoryItem({item, isActive, onClickThreadItem}: IItemProps): ReactElement {
  const {isWaiting, isNewChat, isPlaying} = useContext(CopilotContext);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (selectRef.current && selectRef.current.contains(event.target)) {
        return false;
      }
      setIsOpen(false);
      // setHistoryOption(false);
    }
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleHistory = () => {
    setIsOpen(!isOpen);
  };

  let lastChat = item.chat_history[item.chat_history?.length - 1];
  let dateFormat: string | Date = '';
  if (lastChat?.create_at === undefined) {
    dateFormat = new Date();
  } else {
    dateFormat = new Date(lastChat?.create_at * 1000);
  }
  let lastUpdate = formatDate(dateFormat);

  return (
    <History className={isActive && 'active'} key={item.id} ref={selectRef}>
      {/*<li onClick={() => onClickThreadItem(item.id)} id={item.id}>*/}
      <li onClick={() => onClickThreadItem(item.id)} id={item.id} aria-disabled={isWaiting || isNewChat || isPlaying}>
        {item.title}
        {/*{lastUpdate}*/}
      </li>
      <div className="history-option" onClick={handleHistory}>
        <OptionIcon aria-disabled={isWaiting || isNewChat || isPlaying}>
          {/*<OptionIcon aria-disabled={isWaiting || isNewChat}>*/}
          <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
        </OptionIcon>
        {isOpen && isWaiting === false && isNewChat === false && isPlaying === false && (
          <HistoryOption setIsOpen={setIsOpen} item={item} />
        )}
      </div>
    </History>
  );
}

function HistoryList(): ReactElement {
  const {changeCurrentThread, getThreadList, threadList, currentThread, isWaiting, isNewChat, isPlaying} =
    useContext(CopilotContext);

  const onClickThreadItem = (thread_id: string) => {
    if (isWaiting === false && isNewChat === false && isPlaying === false) {
      changeCurrentThread(thread_id);
      console.log(currentThread);
    }
  };

  useEffect(() => {
    getThreadList();
  }, []);

  /*  useEffect(() => {
    console.log(copilotContext.threadList);
  }, [copilotContext.threadList]);*/

  return (
    <Container className="thin-scrollbar dark">
      {threadList?.map((item) => (
        <HistoryItem
          key={item.id}
          isActive={item.id === currentThread?.id}
          item={item}
          onClickThreadItem={onClickThreadItem}
        />
      ))}
    </Container>
  );
}

export default HistoryList;
