import {Button} from 'components/forms';
import Tooltip from 'components/common/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDatabase as faDatabaseSolid} from '@fortawesome/pro-solid-svg-icons';
import {faDatabase} from '@fortawesome/pro-light-svg-icons';
import React, {Dispatch, SetStateAction} from 'react';
import {useReactFlow} from 'reactflow';
import {IPfdCfg} from 'components/mpfd/type';
import {faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type IProps = {
  cfgState: [IPfdCfg, Dispatch<SetStateAction<IPfdCfg>>];
  id: string;
};

function MetaPfdWidgetHeader({cfgState, id}: IProps) {
  const [cfg, setCfg] = cfgState;

  const reactFlow = useReactFlow();

  const onClick = (key: string, value: boolean) => {
    const metaData = {
      cfg: {
        ...cfg,
        [key]: value
      }
    };
    setCfg(metaData.cfg);
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
    );
  };

  return (
    <Container>
      <Button
        variant="none"
        onClick={(e) => {
          e.stopPropagation();
          onClick('isDisplayLabels', !cfg?.isDisplayLabels);
        }}
      >
        <Tooltip content="Label Toggle" place="bottom">
          <>
            <FontAwesomeIcon icon={cfg?.isDisplayLabels ? faEye : faEyeSlash} size="xl" color="#7C8D91" />
          </>
        </Tooltip>
      </Button>
      <Button
        variant="none"
        onClick={(e) => {
          e.stopPropagation();
          onClick('isDisplayDataValues', !cfg?.isDisplayDataValues);
        }}
      >
        <Tooltip content="Data Toggle" place="bottom">
          <FontAwesomeIcon icon={cfg?.isDisplayDataValues ? faDatabaseSolid : faDatabase} size="xl" color="#7C8D91" />
        </Tooltip>
      </Button>
    </Container>
  );
}

export default MetaPfdWidgetHeader;
