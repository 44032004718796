import {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: end;
  gap: 8px;
  padding-top: 10px;
  &.multi {
    padding-bottom: 12px;
  }
`;

type IProps = {
  children: ReactNode;
  type?: 'default' | 'multi';
};

function ModalFooter({children, type = 'default'}: IProps): ReactElement {
  return <Container className={type}>{children}</Container>;
}

export default ModalFooter;
