import React from 'react';
import useEventCallback from 'use-event-callback';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import classnames from 'classnames';

const Container = styled.div`
  background-color: white;
  z-index: ${META_PFD_zINDEX.IMAGE_BACKGROUND} !important;
  img {
    user-select: none;
  }
`;

type imageSize = {naturalWidth: number; naturalHeight: number};

type IProps = {
  image: {imageSrc?: string};
  mouseEvents?: any;
  onLoad(imageSizeInfo: imageSize): void;
};

function ImageLayer({mouseEvents, image, onLoad}: IProps) {
  const onImageLoaded = useEventCallback((event) => {
    const imageElm = event.currentTarget;
    if (onLoad) onLoad({naturalWidth: imageElm.naturalWidth, naturalHeight: imageElm.naturalHeight});
  });

  const stylePosition = {
    zIndex: 1,
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  };

  return (
    <>
      <img
        {...mouseEvents}
        src={image?.imageSrc}
        style={stylePosition}
        onLoad={onImageLoaded}
        alt="..."
        draggable={false}
      />
      <Container className={classnames()} style={stylePosition} />
    </>
  );
}

export default ImageLayer;
