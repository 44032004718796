import {ILoadSeriesParams, IProfileChartLoader, IProfileSeries} from 'components/pc/widgets/profile-chart/types';
import useApi from 'api/useApi';
import {useState} from 'react';
import {INode, INodeDataReturn, INodeParams, INodeRecordParams} from 'api/data-types';
import {colors} from 'common/colors';
import {getUniqueKey} from 'utils/commons';
import {IChartStackInfo} from 'components/pc/widgets/common/chart/types';

const getNodeParamList = (nodes: string[][]): INodeParams[] => {
  // param 목록 구성
  return nodes?.map((node) => {
    const [database, ...rest] = node;
    const merged: INodeParams = {
      node: [...rest],
      database,
      latest_count: 1
    };
    return merged;
  });
};

const getSeriesList = (nodeList: INode[], prevSeriesList?: IProfileSeries[]): IProfileSeries[] => {
  return nodeList.map((item, index) => {
    const prevItem = prevSeriesList?.[index];
    // const {node, records} = item;

    // const tagStart = node.length < 3 ? 0 : 2;
    const color = prevItem?.color ?? colors.chart.series[index % colors.chart.series.length];
    const parallel = prevSeriesList ? prevItem?.parallel : 0;
    const active = prevSeriesList ? prevItem?.active : true;

    console.log('>>>> color', color, index, colors.chart.series[index % colors.chart.series.length], colors.chart);
    const refined: IProfileSeries = {
      key: getUniqueKey(),
      name: 'Untitled',
      color,
      graphType: 'line',
      active: true,
      parallel,
      nodeList: [],
      data: []
    };
    return refined;
  });
};

function useProfileChartLoader(): IProfileChartLoader {
  const api = useApi();

  const [nodes, setNodes] = useState<string[][]>([]);

  // isLoading 상태 default 설정
  const [isLoading, setIsLoading] = useState(false);
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);

  // series 상태 default 설정 (empty array)
  const [series, setSeries] = useState<IProfileSeries[]>([]);
  // yAxis 의 stack 상태를 저장
  const [stackInfo, setStackInfo] = useState<IChartStackInfo[]>([{parallel: 0, stacked: true, checked: true}]);

  const clearSeries = (): void => {
    setSeries([]);
  };

  const getNodeList = async (params: INodeRecordParams): Promise<INode[]> => {
    const result = await api.post<INodeDataReturn>('/node_manage/get_node_data_list', params);
    const {success, data} = result;
    if (success) {
      return data;
    }
    return null;
  };

  const load = ({nodeList, withWidgetSpinner, savedSeries, localNodeData}: ILoadSeriesParams) => {
    const n = nodeList || nodes || null;
    if (n === null) return;

    setIsLoading(true);
    if (withWidgetSpinner) setIsWidgetLoading(true);

    const params = {node_infos: getNodeParamList(n)};
    getNodeList(params).then((resultNodeList) => {
      // setNodeList(resultNodeList);
      const list = getSeriesList(resultNodeList.concat(localNodeData ?? []), series);
      setSeries(list);
      setIsLoading(false);
    });
  };

  const refreshSeries = (): void => {
    setSeries((prev) => [...prev]);
  };

  const addSeries = (profileSeries?: IProfileSeries): void => {
    const color = colors.chart.series[(series?.length ?? 0) % colors.chart.series.length];
    const ch = profileSeries || {
      name: 'Untitled Series',
      key: getUniqueKey(),
      active: true,
      color,
      nodeList: [],
      data: []
    };
    setSeries((prev) => [...prev, ch]);
  };

  const updateSeries = (profileSeries: IProfileSeries): void => {
    const found = series.find((item) => item.key === profileSeries.key);
    if (found) {
      refreshSeries();
    }
  };

  const removeSeries = (id: string): void => {
    setSeries(series.filter((item) => item.key !== id));
  };

  const reorder = (list: string[]): void => {
    const obj = {};
    series.forEach((item) => {
      obj[item.key] = item;
    });
    const ordered = list.map((id) => obj[id]);
    setSeries(ordered);
  };

  return {
    isLoading,
    isWidgetLoading,
    nodes,
    series,
    stackInfo,
    addSeries,
    clearSeries,
    load,
    refreshSeries,
    removeSeries,
    reorder,
    setNodes,
    setSeries,
    setStackInfo,
    updateSeries
  };
}

export default useProfileChartLoader;
