import {IPfdState, IPfdAction, IAnnotation} from 'components/mpfd/type';
import {annotation} from 'components/mpfd/hooks/functions/action-calculation-functions';

export type IMpfdUtilActionType =
  | 'CHANGE_REGION'
  | 'DELETE_REGIONS'
  | 'DELETE_SELECTED_REGION'
  | 'RESET_SELECTED'
  | 'CHANGE_CFG'
  | 'SAVE_CUSTOM_NODES';

export const IMpfdUtilActionTypes: IMpfdUtilActionType[] = [
  'CHANGE_REGION',
  'DELETE_REGIONS',
  'DELETE_SELECTED_REGION',
  'RESET_SELECTED',
  'CHANGE_CFG',
  'SAVE_CUSTOM_NODES'
];

const utilAction = (state: IPfdState, action: IPfdAction) => {
  switch (action.type as IMpfdUtilActionType) {
    case 'CHANGE_REGION': {
      const defaultInfo = annotation.annot2DefaultInfo(state.regions, action.region);
      if (defaultInfo.idx === -1) return state;
      const oldRegion = state.regions[defaultInfo.idx];
      if (oldRegion.cls !== action.region.cls) {
        const clsIndex = state.regionClsList.indexOf(action.region.cls);
        if (clsIndex !== -1) {
          state = {...state, selectedCls: action.region.cls};
        }
      }
      state = {...state, isChanged: true, regions: state.regions.with(defaultInfo.idx, action.region)};
      return state;
    }
    case 'DELETE_REGIONS': {
      let delTargetRegions = [...action.regions];
      let newRegions = [...state.regions];
      let newDetectionLines = [...(state?.detectionInfo?.detectedLines || [])];
      for (const r of delTargetRegions) {
        const {idx} = annotation.annot2DefaultInfo(state.regions, r);
        if (idx === -1) return state;
        newRegions = newRegions.filter((item: IAnnotation) => item.id !== r.id);
        newDetectionLines = newDetectionLines.filter((item) => item.id !== r.id);
      }
      state = {
        ...state,
        regions: newRegions,
        detectionInfo: {...state.detectionInfo, detectedLines: newDetectionLines}
      };
      return state;
    }
    case 'DELETE_SELECTED_REGION': {
      state = {...state, regions: (state.regions || []).filter((r: IAnnotation) => !r.highlighted)};
      return state;
    }
    case 'RESET_SELECTED': {
      return {...state, regions: annotation.resetFocus(state.regions, null)};
    }
    case 'SAVE_CUSTOM_NODES': {
      return {
        ...state,
        customNodes: action.customNodes
      };
    }
    case 'CHANGE_CFG': {
      return {
        ...state,
        cfg: {
          ...state?.cfg,
          [action.cfgChangeAction.key]: action.cfgChangeAction.value
        }
      };
    }
    default:
      break;
  }
  return state;
};

export default utilAction;
