import {useEffect, useState} from 'react';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {managementApi, GroupInfo} from 'components/auth/management/management-api-function';
import {managementConst} from 'components/auth/management/const';
import {IManagementAsideInfo} from 'components/auth/management/type';

type IReturn = {
  groupAsideInfo: IManagementAsideInfo;
  groupList: GroupInfo[];
  resetGroupAsideInfo(): void;
  openCreateGroupAside(): void;
  createGroup(name: string, description: string, roles: string[]): void;
  deleteGroup(name: string, description: string, roles: string[]): void;
  assignRoleToGroup(role_name: string, group_name: string): void;
  removeRoleFromGroup(role_name: string, group_name: string): void;
  onClickGroupTable(rowKey: string): void;
  editGroup(name: string, description: string, roles: string[]): void;
};

function useGroupManagementHandler(): IReturn {
  const [groupAsideInfo, setGroupAsideInfo] = useState<IManagementAsideInfo>();
  const [groupList, setGroupList] = useState<GroupInfo[]>([]);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;

  useEffect(() => {
    managementApi.group.getGroupList(token).then((res) => {
      if (res?.success) {
        setGroupList(res?.data.groups);
      }
    });
  }, []);

  const resetGroupAsideInfo = () => {
    setGroupAsideInfo(null);
  };

  const openCreateGroupAside = () => {
    setGroupAsideInfo(managementConst.group.groupAsideInfo.create);
    document.addEventListener('mouseup', resetGroupAsideInfo, {once: true});
  };

  const createGroup = (name: string, description: string, roles: string[]) => {
    managementApi.group.createGroup(token, name, description, roles).then((res) => {
      if (res?.success) {
        managementApi.group.getGroupList(token).then((res) => {
          if (res?.success) {
            setGroupList(res?.data.groups);
            setGroupAsideInfo(null);
          }
        });
      } else {
        setGroupAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to create group',
          info: {
            groupName: name,
            description: description,
            roles: roles
          }
        }));
      }
    });
  };

  const deleteGroup = (name: string, description: string, roles: string[]) => {
    managementApi.group.deleteGroup(token, name).then((res) => {
      if (res?.success) {
        managementApi.group.getGroupList(token).then((res) => {
          if (res?.success) {
            setGroupList(res?.data.groups);
            setGroupAsideInfo(null);
          }
        });
      } else {
        setGroupAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to delete group',
          info: {
            groupName: name,
            description: description,
            roles: roles
          }
        }));
      }
    });
  };

  const assignRoleToGroup = (role_name: string, group_name: string) => {
    managementApi.group.assignRoleToGroup(token, role_name, group_name).then((res) => {
      if (res?.success) {
        managementApi.group.getGroupList(token).then((res) => {
          if (res?.success) {
            setGroupList(res?.data.groups);
          }
        });
      } else {
        setGroupAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to assign role to group'
        }));
      }
    });
  };

  const removeRoleFromGroup = (role_name: string, group_name: string) => {
    managementApi.group.removeRoleFromGroup(token, role_name, group_name).then((res) => {
      if (res?.success) {
        managementApi.group.getGroupList(token).then((res) => {
          if (res?.success) {
            setGroupList(res?.data.groups);
          }
        });
      } else {
        setGroupAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to remove assigned role from group'
        }));
      }
    });
  };

  const onClickGroupTable = (rowKey: string) => {
    const selectedGroup = groupList.find((item) => item.name === rowKey);
    if (selectedGroup) {
      setGroupAsideInfo({
        ...managementConst.group.groupAsideInfo.edit,
        info: {
          description: selectedGroup.description,
          groupName: selectedGroup.name,
          roles: selectedGroup.roles
        }
      });
      document.addEventListener('mouseup', resetGroupAsideInfo, {once: true});
    }
  };

  const editGroup = (name: string, description: string, roles: string[]) => {
    managementApi.group.updateGroup(token, name, description, roles).then((res) => {
      if (res?.success) {
        managementApi.group.getGroupList(token).then((res) => {
          if (res?.success) {
            setGroupList(res?.data.groups);
            setGroupAsideInfo(null);
          }
        });
      } else {
        setGroupAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to edit group',
          info: {
            groupName: name,
            description: description,
            roles: roles
          }
        }));
      }
    });
  };

  return {
    groupAsideInfo,
    groupList,
    resetGroupAsideInfo,
    openCreateGroupAside,
    createGroup,
    deleteGroup,
    assignRoleToGroup,
    removeRoleFromGroup,
    onClickGroupTable,
    editGroup
  };
}

export default useGroupManagementHandler;
