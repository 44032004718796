export const checkStream = (tags: string[], checked: string[]) => {
  if (tags?.length === 0) {
    return tags;
  }
  const exceptCheckedTags = tags.filter((item) => !checked.includes(item));
  const getTagCategory = (tag: string) => {
    const arrayTag = JSON.parse(tag);
    if (!arrayTag) {
      return '';
    }
    // todo: db 상의 카테고리명이 변경이 필요할 경우 개선 필요.
    const categories = ['Streams', 'Blocks'];
    for (let category of categories) {
      const index = arrayTag.findIndex((item) => item === category);
      if (index !== -1) {
        return arrayTag[index + 1];
      }
    }
    return '';
  };

  const getCategories = (tags: string[]) => {
    return Array.from(new Set(tags.map((tag) => getTagCategory(tag))));
  };

  const tagCategory = getCategories(exceptCheckedTags);
  const checkedTagsCategory = getCategories(checked);
  const isCategoryUnique = tagCategory.some((category) => checkedTagsCategory.includes(category));
  const a =
    isCategoryUnique || checked.length > tags.length || (tagCategory.length === 1 && checkedTagsCategory.length === 0);
  if (a) {
    return tags;
  } else if (tagCategory.length === 1) {
    return exceptCheckedTags;
  } else {
    return [];
  }
};

export const getDetailInfoOfTag = (tag: string[], modelHierarchy) => {
  try {
    const [, ...hierarchy] = tag; // ['FCC', 'Stream' , 'Feed' , 'Temperature']
    let node = modelHierarchy.find((item) => item.name === hierarchy[0]); // {name: 'FCC', subnode : [{},{},{}...]}
    for (let i = 1; i < hierarchy.length; i++) {
      node = node.subnode.find((item) => item.name === hierarchy[i]); // {name: 'Temperature', subnode : []}
    }
    if (node) {
      return node;
    } else {
      return {
        name: tag?.join('-')
      };
    }
  } catch (e) {
    return {name: tag?.join('-')};
  }
};
