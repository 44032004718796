type IReturn = {
  checkPaste(): Promise<boolean>;
  checkTagPaste(): Promise<boolean>;
};
function useProcessCanvasMenuCheckUsable(): IReturn {
  const checkPaste = async () => {
    try {
      const clipboardData = await navigator?.clipboard?.readText();
      let isNotExistCopiedWidget = true;
      if (clipboardData) {
        try {
          const target = JSON.parse(clipboardData);
          if (target?.selectedNode) {
            isNotExistCopiedWidget = false;
          }
        } catch (e) {
          return true;
        }
      }
      return isNotExistCopiedWidget;
    } catch (e) {
      return true;
    }
  };

  const checkTagPaste = async () => {
    try {
      const clipboardData = await navigator?.clipboard?.readText();
      let disabled = true;
      if (clipboardData) {
        try {
          const target = JSON.parse(clipboardData);
          if (target?.tag) {
            disabled = false;
          }
        } catch (e) {
          return true;
        }
      }
      return disabled;
    } catch (e) {
      return true;
    }
  };

  return {checkPaste, checkTagPaste};
}

export default useProcessCanvasMenuCheckUsable;
