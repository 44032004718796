import styled from 'styled-components';

// Time Series, Profile Chart 하단 테이블
const ChartTableArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  height: 0; // 임시처리
  //overflow: hidden;
  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-top: 1px solid #fff;
`;

export {ChartTableArea};
