import {memo, ReactElement, useContext, useEffect, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetBody, WidgetContainer, WidgetHeader, WidgetActionPanel} from 'components/pc/widgets/parts';
import {IBlockTableData} from 'components/spreadsheet/spreadsheet-adapter';
import {IWidgetNodeData} from 'components/pc/types';
import BlockTable from 'components/pc/widgets/blockTable/BlockTable';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import BasicSpinner from 'components/common/BasicSpinner';
import {Button} from 'components/forms';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import useApi from 'api/useApi';
import {getBlockValues, parseCheckedListData} from 'components/pc/widgets/blockTable/block-functions';
import {checkStream} from 'components/mpfd/panel/parts/property/function';
import {DataContext} from 'api/DataProvider';

const Wrapper = styled.div`
  width: 100%;
  height: 100%; //calc(100% - 1px);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const SelectNodesButton = styled(Button)`
  white-space: nowrap;
`;

const SelectTagButtonLabel = styled.span`
  margin-left: 6px;
`;

function BlockTableWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const [blockTableMetaData, setBlockTableMetaData] = useState<IBlockTableData>({
    colHeaders: false,
    rowHeaders: false,
    colWidth: 200,
    data: null,
    colLengths: 5,
    rendererType: 'HierarchyDataRenderer'
  });
  const [isNodeSelectorOpen, setIsNodeSelectorOpen] = useState<boolean>(false);
  const [selectedNodes, setSelectedNodes] = useState<string[][]>([]);
  const [targetBlockName, setTargetBlockName] = useState<string>();
  const [isBlockTableLoading, setIsBlockTableLoading] = useState<boolean>(false);
  const api = useApi();
  const {setNodes} = useReactFlow();
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;

  useEffect(() => {
    setIsBlockTableLoading(true);
    if (data?.metaData) {
      const loadData: IBlockTableData = data?.metaData as IBlockTableData;
      setBlockTableMetaData(loadData);
      if (loadData?.selectedBlockNodes) {
        setSelectedNodes(loadData?.selectedBlockNodes);
      }
      if (loadData?.targetBlockName) {
        setTargetBlockName(loadData?.targetBlockName);
      }
    } else {
      setBlockTableMetaData({
        colHeaders: false,
        rowHeaders: false,
        colWidth: 200,
        data: null,
        colLengths: 5,
        rendererType: 'HierarchyDataRenderer'
      });
    }
    setIsBlockTableLoading(false);
  }, [data]);

  const onClickOpenNodeSelector = (e) => {
    e.stopPropagation();
    setIsNodeSelectorOpen(true);
  };

  const onCloseNodeSelector = () => {
    setIsNodeSelectorOpen(false);
  };

  const onSelectNodeSelector = async (checkedList: string[]) => {
    if (checkedList && checkedList.length > 0) {
      setIsBlockTableLoading(true);
      const parsed = parseCheckedListData(checkedList);
      setSelectedNodes(parsed);
      setTargetBlockName(parsed[0][parsed[0].indexOf('Blocks') + 1]);
      const valueAddedParsed = await getBlockValues(
        parsed,
        api,
        parsed[0][parsed[0].indexOf('Blocks') + 1] || '',
        globalSettings?.significantDigit || 4
      );
      setBlockTableMetaData({...blockTableMetaData, data: valueAddedParsed});
      setIsBlockTableLoading(false);
    }
  };

  const beforeOnCheckNodeSelector = (nodes: string[], checked: string[]) => {
    return checkStream(nodes, checked);
  };

  useEffect(() => {
    if (blockTableMetaData && selectedNodes && targetBlockName)
      setNodes((nodes) =>
        nodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: {
                  ...node?.data,
                  metaData: {
                    ...blockTableMetaData,
                    selectedBlockNodes: selectedNodes || null,
                    targetBlockName: targetBlockName || 'Undefined Label'
                  }
                }
              }
            : node
        )
      );
  }, [blockTableMetaData]);

  return (
    <WidgetContainer {...rest} data={data} type="BlockTableWidget">
      <WidgetHeader
        {...rest}
        type="BlockTableWidget"
        icon={data.icon}
        id={id}
        title={targetBlockName}
        onClose={() => console.log()}
      />
      <WidgetActionPanel>
        <Wrapper>
          <Left>
            <SelectNodesButton variant="dark" height={34} onClick={onClickOpenNodeSelector}>
              <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
              <SelectTagButtonLabel>Select Block Nodes</SelectTagButtonLabel>
            </SelectNodesButton>
            <BasicSpinner isShow={isBlockTableLoading} margin="0 10px" size="md" type="inline" />
          </Left>
          <Right>{/*  TODO: live 기능 추가*/}</Right>
          {isNodeSelectorOpen && (
            <NodeSelectorRevision
              title="Select Blocks"
              onClose={onCloseNodeSelector}
              onSelect={onSelectNodeSelector}
              selectedNodes={selectedNodes}
              options={{
                searchStrings: ['Blocks']
              }}
              beforeOnCheck={beforeOnCheckNodeSelector}
            />
          )}
        </Wrapper>
      </WidgetActionPanel>
      <WidgetBody actionMenuHeight={60}>
        <BlockTable tableData={blockTableMetaData.data} />
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(BlockTableWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
