import {ChangeEvent, KeyboardEvent, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faCheck} from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

type StyleProps = {
  $isWidthFull: boolean;
  $isCursorNotAllowed: boolean;
};

const Container = styled.div`
  position: relative;
`;
const InputContainer = styled.div<StyleProps>`
  position: relative;
  //width: 312px;
  width: ${(props) => (props.$isWidthFull ? '100%' : '312px')};
  height: 40px;
  input {
    width: 100%;
    height: 100%;
    font-size: 15px;
    padding: 13px 12px;
    box-sizing: border-box;
    border: 1px solid #b6b4c0;
    border-radius: 5px;
    background-color: white;
    cursor: ${(props) => (props.$isCursorNotAllowed ? 'not-allowed' : 'auto')};
    filter: ${(props) => (props.$isCursorNotAllowed ? 'brightness(0.92)' : 'none')};
    &::placeholder {
      color: #b6b4c0;
    }
  }
`;
const WarningMessageContainer = styled.div`
  position: absolute;
  height: 20px;
  color: ${({theme}) => theme.color.invalid} !important;
  font-size: 12px;
`;

const VisibleIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 14px;
  height: 100%;
  color: #b6b4c0;
  svg {
    cursor: pointer;
  }
`;

const ValidIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 14px;
  height: 100%;
  color: #b6b4c0;
  &.valid {
    color: #00a6cb !important;
  }

  &.passwordMismatch {
    color: red !important;
  }
`;

export type ILoginInputState = {
  [key: string]: ITextFormCheckValidateState;
};

export type ITextFormCheckValidateState = {
  value: string;
  valid: boolean;
  validLength?: boolean;
  type?: string;
  placeholder?: string;
  warningMessage?: string;
  warningMessageLength?: string;
  validateOnChange?: boolean;
  passwordMismatch?: boolean;
  onBlurFnc?: boolean;
  isDisabled?: boolean;
};

type IProps = {
  value: string;
  placeholder?: string;
  warningMessage?: string;
  warningMessageLength?: string;
  type?: 'password' | 'email' | string;
  regex?: RegExp;
  validCheckIcon?: boolean;
  validateOnChange?: boolean;
  passwordMismatch?: boolean;
  isWidthFull?: boolean;
  isValid?: boolean;
  isValidLength?: boolean;
  isDisabled?: boolean;

  onChangeValue(e: ChangeEvent, valid?: boolean): void;
  updateValidate?(valid: boolean): void;
  updateValidateLength?(validLength: boolean): void;
  onKeyPressDownEnter?(): void;
  onBlur?(): void;
};

function TextFormCheckValidate({
  value,
  placeholder,
  warningMessage,
  warningMessageLength,
  type,
  validateOnChange,
  passwordMismatch,
  isWidthFull = false,
  isValid,
  isValidLength,
  regex,
  validCheckIcon,
  onChangeValue,
  updateValidate,
  updateValidateLength,
  onKeyPressDownEnter,
  onBlur,
  isDisabled = false
}: IProps) {
  const [valid, setValid] = useState(isValid || false);
  const [validLength, setValidLength] = useState(isValidLength || false);
  const [visible, setVisible] = useState(false);

  const onChangeInput = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;

    if (validateOnChange) {
      const valid = checkValidate(value);
      setValid(valid);
      updateValidate(valid);
      onChangeValue(e, valid);
      if (type === 'id') {
        const validLength = checkValidateLength(value);
        setValidLength(validLength);
        setValid(validLength);
        updateValidateLength(validLength);
        onChangeValue(e, validLength);
      }
      if (type === 'password') {
        const validLength = checkValidPassword(value);
        setValidLength(validLength);
        setValid(validLength);
        updateValidateLength(validLength);
        onChangeValue(e, validLength);
      }
    } else {
      onChangeValue(e);
    }
  };

  const checkValidate = (val: string) => {
    if (val.includes(' ')) {
      return false;
    }
    if (type === 'id') {
      const idRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]/;
      return idRegex.test(val);
    } else if (type === 'email') {
      const emailRegex =
        /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:(?!-)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,63})(?<!\d)$/;
      return emailRegex.test(val);
    } else {
      return !!val;
    }
  };

  const checkValidateLength = (val: string) => {
    return val.length >= 4;
  };

  const checkValidPassword = (val: string) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={}[\]:;"'<>,.?/~`\\|]).{8,}$/;
    return passwordRegex.test(val);
  };

  const onBlurInput = () => {
    onBlur?.();
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onKeyPressDownEnter?.();
    }
  };

  return (
    <Container>
      <InputContainer $isWidthFull={isWidthFull} $isCursorNotAllowed={isDisabled}>
        <input
          value={value}
          type={!visible ? type : ''}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          onKeyDown={onKeyPress}
        />
        {type === 'password' && !validCheckIcon && (
          <VisibleIconWrapper onClick={() => setVisible((prev) => !prev)}>
            <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />
          </VisibleIconWrapper>
        )}
        {validCheckIcon && (
          <ValidIconWrapper
            className={classnames(
              valid && 'valid',
              passwordMismatch && 'passwordMismatch',
              validLength && 'validLength'
            )}
          >
            <FontAwesomeIcon icon={faCheck} />
          </ValidIconWrapper>
        )}
      </InputContainer>
      {(warningMessage || warningMessageLength) && value && (
        <WarningMessageContainer>
          {!valid ? warningMessage : !validLength ? warningMessageLength : ''}
        </WarningMessageContainer>
      )}
    </Container>
  );
}

export default TextFormCheckValidate;
