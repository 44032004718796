import {ModelParts} from 'components/pc/widgets/three/renderer/block/Model_Parts';
import {AirCooler} from 'components/pc/widgets/three/renderer/block/AirCooler';
import {COBoiler} from 'components/pc/widgets/three/renderer/block/COBoiler';
import {Compressor} from 'components/pc/widgets/three/renderer/block/Compressor';
import {HeatExchanger} from 'components/pc/widgets/three/renderer/block/HeatExchanger';
import {Pump} from 'components/pc/widgets/three/renderer/block/Pump';
import {VesselWithoutBoot} from 'components/pc/widgets/three/renderer/block/VesselWithoutBoot';
import {VesselWithBoot} from 'components/pc/widgets/three/renderer/block/VesselWithBoot';
import {VesselVertical} from 'components/pc/widgets/three/renderer/block/VesselVertical';

type IProps = {
  blockVisibility: boolean;
};

function Blocks({blockVisibility}: IProps) {
  return (
    <>
      <ModelParts blockVisibility={blockVisibility} />
      <AirCooler />
      <COBoiler />
      <Compressor />
      <HeatExchanger />
      <Pump />
      <VesselWithoutBoot />
      <VesselWithBoot />
      <VesselVertical />
    </>
  );
}

export default Blocks;
