import React from 'react';
import styled from 'styled-components';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import {hexToRGBA} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';

type BoxRectProps = {
  $color: string;
  $highlighted: boolean;
};

const BoxRect = styled.rect<BoxRectProps>`
  fill: ${(props) => hexToRGBA(props.$color, 0.25)};
  &:hover {
    fill: ${(props) => (props.$highlighted ? hexToRGBA(props.$color, 0.25) : hexToRGBA(props.$color, 0.45))};
  }
`;

type IProps = {
  iw: number;
  ih: number;
  color: string;
  region: IAnnotation;
  boxHoverType: boolean;
  cfg: IPfdCfg;
};

function MetaPfdWidgetBox({iw, ih, color, region, boxHoverType, cfg}: IProps) {
  return (
    <g transform={`translate(${region?.x * iw} ${region?.y * ih})`}>
      {region?.labelVisible && cfg?.isDisplayLabels && (
        <text
          x={Math.max(region?.w * iw, 0) / 2}
          y={-Math.floor(iw * 0.007)}
          fill={color}
          fontSize={Math.floor(iw * 0.014)}
          fontWeight={700}
          textAnchor="middle"
          className="annot-label"
        >
          {region?.name}
        </text>
      )}
      <BoxRect
        strokeWidth={1}
        x={0}
        y={0}
        width={Math.max(region?.w * iw, 0)}
        height={Math.max(region?.h * ih, 0)}
        stroke={hexToRGBA(color, 0.8)}
        $color={color}
        $highlighted={region?.highlighted}
      />
    </g>
  );
}

export default MetaPfdWidgetBox;
