import {BaseSyntheticEvent, ReactElement} from 'react';
import {IPullDownMenu} from 'components/menu/constants';
import styled from 'styled-components';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';

const Container = styled.li`
  height: 35px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #ccc;
  white-space: nowrap;

  &.checked {
    color: #87f8ec;

    > .fa-check {
      margin-left: 5px;
    }

    &:not(.disabled):hover {
      color: #87f8ec;
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:not(.disabled):hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
`;
const Hotkey = styled.span`
  color: #c59936;
  font-size: 0.8em;
  margin-left: auto;
  padding-left: 10px;
`;
const HorizontalLine = styled.hr`
  border: none;
  margin: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

type IProps = {
  data: IPullDownMenu;
  disabled?: boolean;
  onClick(e: BaseSyntheticEvent, menu: IPullDownMenu): void;
};

function PullDownMenuItem({data, disabled, onClick}: IProps): ReactElement {
  const onClickMenu = (e: BaseSyntheticEvent): void => {
    // data.disabled : 메뉴 데이터에 하드코딩 되어 있는 비활성화
    // disabled : 현재 app 상태에 따른 비활성화
    if (data.disabled || disabled) return;
    onClick(e, data);
  };
  return (
    <>
      <Container
        key={data.id}
        className={classNames({disabled: data.disabled || disabled}, {checked: data.checked})}
        onClick={onClickMenu}
      >
        {data.title}
        {data.checked && <FontAwesomeIcon icon={faCheck} />}
        <Hotkey>{data.hotkey}</Hotkey>
      </Container>
      {data.underlined && <HorizontalLine />}
    </>
  );
}

export default PullDownMenuItem;
