import {DatabaseHierarchy, INodeSubNode} from 'api/data-types';
import {Node as TreeNode} from 'react-checkbox-tree';

export type IConvertHierarchyOptions = {
  searchStrings?: string[]; // ['Streams', 'seosan'] 이라면 둘 중 하나라도 포함된 leaf들을 리턴
  useEndPoint?: boolean; // searchString 가 등장하면 그의 자식 까지만 children 으로 받고 children 의 children 은 더이상 받지 않음
  selectableUntilBelowSearchString?: boolean;
  selectableOnlySearchStringLevel?: boolean;
  selectSingleNode?: boolean;
};

export const convertHierarchyToTreeNode = (
  hierarchyList: DatabaseHierarchy[],
  option?: IConvertHierarchyOptions,
  additionalHierarchy?: DatabaseHierarchy[]
): TreeNode[] => {
  const t = [...hierarchyList, ...(additionalHierarchy || [])]
    .filter((item) => item)
    .map((item) => {
      const children = item.data.map((d) => convertToNode(d, [item.database], option)).filter((item) => item);
      if (children.filter((item) => item).length === 0) {
        return null; // Exclude this node as it does not contain the search string and has no valid children
      }
      return {
        label: item.database,
        value: JSON.stringify([item.database]),
        children
      };
    });
  const tt = t.filter((item) => item);
  return tt;
};

// ['Streams', 'seosan'];
const convertToNode = (
  node: INodeSubNode,
  hierarchy: string[],
  option?: IConvertHierarchyOptions,
  isEndPoint = false
): TreeNode => {
  const {subnode, name} = node;
  const newHierarchy = [...hierarchy, name];
  const searchStrings: string[] = option?.searchStrings;
  const containsSearchString = searchStrings?.some((searchString) => newHierarchy.includes(searchString));
  const isDisabled =
    containsSearchString &&
    option?.selectableUntilBelowSearchString &&
    newHierarchy.some((str, idx) => searchStrings.includes(str) && idx < newHierarchy.length - 2);
  // option?.selectableOnlySearchStringLevel &&
  // newHierarchy.some((str, idx) => searchStrings.includes(str) && idx < newHierarchy.length - 2);

  if (subnode === null || subnode === undefined || isEndPoint) {
    if (searchStrings && !containsSearchString) {
      return null; // Exclude this node as it does not contain the search string
    }
    return {
      value: JSON.stringify([...newHierarchy]),
      label: name,
      className: isDisabled && 'disabled-node'
      // disabled: isDisabled
    };
  } else {
    const children = subnode
      .map((node) => convertToNode(node, newHierarchy, option, searchStrings?.includes(name)))
      .filter((item) => item); // Filter out null items

    if (children.length === 0 && !containsSearchString) {
      return null;
    }

    return {
      value: JSON.stringify(newHierarchy),
      label: name,
      children,
      className: isDisabled && 'disabled-node'
      // disabled: isDisabled
    };

    // return {
    //   value: JSON.stringify(newHierarchy),
    //   label: name,
    //   children: subnode
    //     .map((node) => convertToNode(node, newHierarchy))
    //     .filter((item) => !hbmWidgetUtilFunc.isEmptyObj(item)) // 빈 Object (weather 항목 제거하는 filter method)
    // };
  }
};
