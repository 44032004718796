import {MouseEvent, KeyboardEvent, useContext} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';

const CellWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 4px;

  &.tag-connected-cell {
    color: #4e61e1;
  }

  &.selected {
    background-color: #d6dfff;
  }
`;

type IProps = {
  TD?: HTMLTableCellElement;
  col?: number;
  row?: number;
  value?: string | null;
  cellInfo?: ISpreadSheetCellInfo[][];
  selectedRowIdx?: number;
  onContextMenuPane?(event: MouseEvent, row: number, col: number): void;
  onKeyDownCell?(event: KeyboardEvent): void;
};

function NormalDataRenderer(props: IProps) {
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;

  let value;
  if (props?.cellInfo?.[props?.row]?.[props?.col]) {
    value = toSignificantDigit(props?.value, globalSettings.significantDigit);
  } else {
    value = props?.value || '';
  }

  return (
    <CellWrapper
      className={classnames(
        props?.cellInfo?.[props?.row]?.[props?.col] && 'tag-connected-cell',
        props?.row === props?.selectedRowIdx && 'selected'
      )}
      onContextMenu={(e) => props?.onContextMenuPane(e, props.row, props.col)}
      title={props?.cellInfo?.[props?.row]?.[props?.col]?.name || ''}
    >
      {value}
    </CellWrapper>
  );
}

export default NormalDataRenderer;

// export default memo(NormalDataRenderer, (prevProps, nextProps) => {
//   return prevProps.value === nextProps.value;
// });
