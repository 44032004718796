import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Compressor() {
  const { nodes, materials } = useGLTF('./3D/Compressor/Compressor.gltf')
  return (
    <group dispose={null} scale={0.2}>
      <mesh geometry={nodes._Compressor_1.geometry} material={materials.Simacro_Compressor} position={[0,10,0]} rotation={[0, 1.571, 0]} scale={1.764} />
    </group>
  )
}

useGLTF.preload('./3D/Compressor/Compressor.gltf')
