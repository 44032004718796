import React, { useState } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

export function ModelShells({ transparent, wireframes, visibility }) {
  const modelScale = 0.2
  const { nodes, materials } = useGLTF('/3D/Shells/Shells.gltf');
  const shellOpacity = transparent ? 0.5 : 1;
  const wireframesMaterial = new THREE.MeshBasicMaterial({ color: '#0f0f0f', wireframe: true });
  const materialShell = wireframes ? wireframesMaterial : materials['Simacro_Shells'];

  return (
    <group dispose={null} scale={modelScale}>
      <mesh
        key={visibility}
        name={`Shell_${visibility}`}
        geometry={nodes[`${visibility}_Shell_1`].geometry}
        material-transparent
        material-opacity={shellOpacity}
        material={materialShell}
        position={[0,16,0]}
        rotation={nodes[`${visibility}_Shell`].rotation}
        scale={nodes[`${visibility}_Shell`].scale}
      />
    </group>
  );
}

useGLTF.preload('/3D/Shells/Shells.gltf');
