import {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ModalBody} from 'components/common';
import {SelectForm} from 'components/forms';
import ModalFooter from 'components/common/modal/ModalFooter';
import Button from 'components/forms/Button';

type IProps = {
  isModelSelected: boolean;
  onCancel(isShowConfig: boolean): void;
  onClick(): void;
  onChangeModelType(value: string): void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 88%;
`;

// todo: mapping 제작 예정
const modelOptions = [
  {company: 'HDO', label: 'FCC', value: 'model_fcc'},
  {company: 'AGC', label: 'VCM', value: 'model_vcm'}
];

const COMPANY_CODE = process.env.REACT_APP_COMPANY;

function ThreeLandscapeSetting({isModelSelected, onCancel, onClick, onChangeModelType}: IProps): ReactElement {
  const [value, setValue] = useState('');

  const noSelectModel = value === '';

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const filtered = modelOptions.filter((option) => option.company === COMPANY_CODE || 'SIMACRO' === COMPANY_CODE);
    setOptions(filtered);
    // 모델이 하나 뿐이라면 자동 선택 해줌
    if (filtered.length === 1) {
      const [first] = filtered;
      setValue(first.value);
    }
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
  };

  const onClickConfirm = () => {
    onChangeModelType(value);
    onClick();
  };

  return (
    <Container>
      <ModalBody align="center" size="full-width">
        <SelectForm
          placeholder="Select Model"
          className="model-select"
          name="model-select"
          width={300}
          height={40}
          style={{margin: 'auto'}}
          options={options}
          value={value}
          onChange={onChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button width={100} onClick={onClickConfirm} disabled={noSelectModel}>
          Ok
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default ThreeLandscapeSetting;
