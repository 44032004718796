import {create} from 'zustand';
import {XYPosition} from 'reactflow';

// This global state is needed because we need transfer the
// control points that have been added while drawing the connection
// to the new edge that is created onConnect
type IAppState = {
  connectionLinePath: XYPosition[];
  setConnectionLinePath: (connectionLinePath: XYPosition[]) => void;
};

export const useAppStore = create<IAppState>((set) => ({
  connectionLinePath: [],
  setConnectionLinePath: (connectionLinePath: XYPosition[]) => {
    set({connectionLinePath});
  }
}));
