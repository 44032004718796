import React, {ReactElement, useContext} from 'react';
import {CommonContext, IToast} from 'components/common/CommonProvider';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faFlag, faLightbulb, faMessageCheck, faMessageExclamation} from '@fortawesome/pro-light-svg-icons';

const Container = styled.div`
  position: fixed;
  width: 100%;
  //width: fit-content;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;
  padding-top: 20px;
  pointer-events: none;
  .toast-wrapper {
    padding: 10px 20px 10px 0;
    //width: 360px;
    border-radius: 10px;
    background-color: #ffffff;
    opacity: 0.8;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 96px;
    span {
      color: #575660;
      font-size: 15px;
      margin-right: 5px;
    }
  }
`;
const WrapperSide = styled.div`
  display: flex;
  width: 5px;
  height: 60px;
  padding-left: 4px;
  background-color: #9d9d9d;
  border-radius: 10px 0 0 10px;

  &.error {
    background-color: tomato;
  }

  &.success {
    background-color: #32499a;
  }

  &.warning {
    background-color: #19accc;
  }
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
  padding: 2px;
  margin: 0 14px;
  border-radius: 24px;
  background-color: #9d9d9d;
  color: #fff;
  display: flex;
  > svg {
    margin: auto;
  }

  &.error {
    background-color: tomato;
  }

  &.success {
    background-color: #32499a;
  }

  &.warning {
    background-color: #19accc;
  }
`;

function Toast(): ReactElement {
  const {toastList} = useContext(CommonContext);

  const getIcon = (toast: IToast): IconDefinition => {
    switch (toast.type) {
      case 'success':
        return faMessageCheck;
      case 'error':
        return faMessageExclamation;
      case 'warning':
        return faFlag;
      case 'info':
        return faLightbulb;
    }
  };

  return (
    <Container>
      {toastList.map((item) => (
        <div className="toast-wrapper" key={item.id}>
          <WrapperSide className={item.type} />
          <Icon className={item.type}>
            <FontAwesomeIcon icon={getIcon(item)} />
          </Icon>
          <span>{item.text}</span>
        </div>
      ))}
    </Container>
  );
}

export default Toast;
