import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPenToSquare} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {IAnnotation} from 'components/mpfd/type';
import {ChangeEvent, KeyboardEvent, MouseEvent, useContext, useEffect, useRef, useState} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import Tooltip from 'components/common/Tooltip';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 10px;
  color: #999;

  svg {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  max-width: 382px;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
const InputWrapper = styled.div`
  width: 100%;
  padding-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  > svg {
    position: absolute;
    right: 0;
  }
`;
const NameInput = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #9eb0c0;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: inherit;
  color: inherit;
  padding: 5px 10px;
  border-radius: 4px;

  &:focus {
    background-color: rgba(255, 255, 255, 0.9);
  }
  &:disabled {
    border-color: transparent;
  }
  &::placeholder {
    color: #ddd;
  }
`;

type IProps = {
  selectedAnnot: IAnnotation;
  editTarget: IAnnotation;
  onChangeEditTarget(target: Partial<IAnnotation>): void;
};

function AnnotationName({selectedAnnot, editTarget, onChangeEditTarget}: IProps) {
  const {dispatchToReducer} = useContext(MetaPfdContext);
  const {name} = selectedAnnot;
  const [currentName, setCurrentName] = useState<string>();
  const inputRef = useRef(null);

  useEffect(() => {
    setCurrentName(name);
  }, [name]);

  const onClickEditBtn = (e: MouseEvent<SVGSVGElement>) => {
    if (selectedAnnot?.id === editTarget?.id) {
      e.stopPropagation();
      dispatchToReducer({type: 'CHANGE_REGION', region: editTarget});
      onChangeEditTarget(null);
    } else {
      onChangeEditTarget(selectedAnnot);
      setTimeout(() => {
        inputRef.current.focus();
      }, 10);
    }
  };

  const onClickDelBtn = () => {
    dispatchToReducer({type: 'DELETE_REGIONS', regions: [{...selectedAnnot}]});
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setCurrentName(value);
    onChangeEditTarget({name: value});
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatchToReducer({type: 'CHANGE_REGION', region: editTarget});
      onChangeEditTarget(null);
    }
  };

  return (
    <NameWrapper>
      <InputWrapper>
        <NameInput
          ref={inputRef}
          value={currentName || ''}
          placeholder="Unnamed"
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={!editTarget}
        />
      </InputWrapper>
      <IconWrapper>
        <Tooltip content={!editTarget ? 'Rename' : 'Confirm'}>
          <FontAwesomeIcon icon={!editTarget ? faPenToSquare : faCheck} onClick={onClickEditBtn} />
        </Tooltip>
        <Tooltip content="Delete">
          <FontAwesomeIcon icon={faTrashCan} onClick={onClickDelBtn} />
        </Tooltip>
      </IconWrapper>
    </NameWrapper>
  );
}

export default AnnotationName;
