import React, {useState} from 'react';
import styled from 'styled-components';
import LoginScreenHeader from 'components/auth/login/parts/LoginScreenHeader';
import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import LoginInputComponent from 'components/auth/login/LoginInput';
import {Button} from 'components/forms';
import {areAllFieldsValid, registerRequest} from 'components/auth/login/login-api-functions';
import {getImage} from 'assets/images/svg-image';
import SHA256 from 'crypto-js/sha256';
import {LoginConst} from 'components/auth/login/const';
import LoginScreenContainer from 'components/auth/login/parts/LoginScreenContainer';
import LoginScreenInputContainer from 'components/auth/login/parts/LoginScreenInputContainer';
import {Link, useNavigate} from 'react-router-dom';
import ApiHostTypeDisplay from 'components/common/ApiHostTypeDisplay';

const RegisterLinkContainer = styled.div`
  font-size: 14px;
  display: flex;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b6b4c0;
    font-size: 12px;
    cursor: pointer;
    &:nth-of-type(1) {
      margin-right: 5px;
    }
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
`;

const RegisterValidationMessageContainer = styled.div`
  position: absolute;
  top: -25px;
  font-size: 12px;
  color: red;
`;

function RegisterRequest() {
  const registerInfoState = useState<ILoginInputState>(LoginConst.registerRequestInitialState);
  const [registerInfo] = registerInfoState;
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const createAccount = () => {
    const payLoad = {
      lastName: registerInfo?.lastName?.value,
      firstName: registerInfo?.firstName?.value,
      username: registerInfo?.userName?.value,
      password: SHA256(registerInfo?.password?.value).toString(),
      email: registerInfo?.email?.value
    };
    registerRequest(payLoad).then((res) => {
      if (res?.status === 201 && res?.body?.success) {
        navigate('/login/create/complete');
      } else {
        if (res?.body?.detail) {
          alert(res?.body?.detail);
          setErrorMessage(res?.body?.detail);
        } else {
          setErrorMessage('Register failed');
        }
      }
    });
  };

  const onKeyPressDownEnter = () => {
    if (
      areAllFieldsValid(registerInfo) &&
      Boolean(
        registerInfo?.userName?.value &&
          registerInfo?.email?.value &&
          registerInfo?.firstName?.value &&
          registerInfo?.lastName?.value &&
          registerInfo?.password?.value
      )
    ) {
      createAccount();
    }
  };

  return (
    <LoginScreenContainer>
      <LoginScreenHeader title="Welcome to ProcessMetaverse™" />
      <LoginScreenInputContainer>
        <ApiHostTypeDisplay />
        <LoginInputComponent
          loginInputState={registerInfoState}
          validCheckIcon={true}
          onKeyPressDownEnter={onKeyPressDownEnter}
        />
      </LoginScreenInputContainer>
      <ButtonContainer>
        {errorMessage && <RegisterValidationMessageContainer>{errorMessage}</RegisterValidationMessageContainer>}
        <Button width="100%" height="40px" disabled={!areAllFieldsValid(registerInfo)} onClick={createAccount}>
          Create Account
        </Button>
      </ButtonContainer>

      <RegisterLinkContainer>
        <Link to={'/login/password/recover'}>
          <img src={getImage('loginPageForgetPasswordIcon', {color: '#b6b4c0'})} alt="" />
          Forget your password?
        </Link>
      </RegisterLinkContainer>
    </LoginScreenContainer>
  );
}

export default RegisterRequest;
