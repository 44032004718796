import {ChangeEvent, ReactElement, useState, useContext} from 'react';
import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import classnames from 'classnames';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  padding: 5px 7px;
  box-sizing: border-box;
  .common-text-form {
    position: static;
  }
  input[type='text'] {
    border: 1px solid #b6b4c0;
    height: 24px;
    background-color: #ffffff;
    font-size: 14px;
    box-sizing: border-box;
  }

  select {
    border: 1px solid #b6b4c0;
    height: 24px;
    background-color: #ffffff;
    font-size: 14px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  &.value {
    justify-content: flex-end;
  }

  &.path {
    color: #394add;
    span,
    svg {
      cursor: pointer;
    }
  }
  &.Outflow {
    span {
      pointer-events: none;
    }
  }
`;

const BtnContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 9px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #373737;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #373737;
  cursor: pointer;
`;

const BtnContainer_2 = styled.div`
  position: absolute;
  right: 16px;
  top: 9px;

  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #373737;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #373737;
  cursor: pointer;
`;

type IProps = {
  field: ITableField;
  th?: boolean;
  keys: string;
  onChange(e: ChangeEvent): void;
  onClick?(rowKey: string, columnKey?: string, key?: string, value?: string[]): void;
  value: string;
};

function ModelRunnerVariableTableParts({
  keys,
  field,
  onChange,
  th,
  onClick,
  value: headerValue,
  ...rest
}: IProps): ReactElement {
  const {globalSettingsState} = useContext(DataContext);
  const {significantDigit} = globalSettingsState[0];
  const value = rest[field?.key];
  const [str, setStr] = useState<string>(value);
  const [strArr] = useState<string[]>(value);

  switch (field?.key) {
    case 'variableName': {
      return (
        <Container>
          <div id={keys}>{str}</div>
        </Container>
      );
    }
    case 'flowType': {
      return (
        <Container>
          <div id={keys}>{str}</div>
        </Container>
      );
    }
    case 'variableValue': {
      return (
        <Container className={classnames(field?.key)}>
          {isNaN(value) ? (value === 'NaN' ? '' : value) : toSignificantDigit(value, significantDigit)}
        </Container>
      );
    }
    case 'path': {
      if (th) {
        return (
          <>
            <div>{headerValue}</div>
          </>
        );
      } else {
        return (
          <>
            <Container className={classnames(field?.key, rest['flowType'])}>
              <span onClick={() => onClick(field?.key, 'change_path', keys, strArr)}>
                {rest['flowType'] === 'Import'
                  ? value?.join('.') || 'Select Path' // Import일 때 value가 있으면 join된 값을, 없으면 'Select Path'
                  : rest['flowType'] === 'Export' && value?.join(',')
                    ? value?.join('.') // Export일 때는 항상 이 값을 전달
                    : 'Select Path'}
              </span>
              {/* <BtnContainer_2
                onClick={() => {
                  onClick(field?.key, 'delete_row', keys);
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </BtnContainer_2>*/}
            </Container>
          </>
        );
      }
    }
    default: {
      return <Container></Container>;
    }
  }
}

export default ModelRunnerVariableTableParts;
