import React, {ChangeEvent, useState} from 'react';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';

import {Button, TextForm} from 'components/forms';
import styled from 'styled-components';
import classNames from 'classnames';

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.color.invalid};
  margin-top: 4px;
  height: 50px;
  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
  }
`;

type IProps = {
  onCreateCustomNode(): void;
  onChangeRenameModal(bool: boolean): void;
  onChangeCustomNodeName(customNodeName: string): void;
  showError: boolean;
};

function CustomNodeRenameModal({onCreateCustomNode, onChangeRenameModal, onChangeCustomNodeName, showError}: IProps) {
  const [name, setName] = useState<string>('');

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onCancel()
  });

  const onCancel = (): void => {
    onChangeRenameModal(false);
  };

  const onClickConfirm = () => {
    onCreateCustomNode();
  };

  const onChangeFileName = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setName(value);
    onChangeCustomNodeName(value);
  };

  return (
    <ModalWrapper type="inner">
      <ModalHeader onClose={onCancel}>Custom Node</ModalHeader>
      <ModalBody>
        <TextForm autoFocus value={name} width={320} placeholder="Input custom node name" onChange={onChangeFileName} />
        <ErrorMessage className={classNames(showError ? 'show' : 'hide')}>
          A custom node with this name already exists.
          <br /> Please choose a different name.
        </ErrorMessage>
      </ModalBody>
      <ModalFooter>
        <Button width={100} disabled={showError} onClick={onClickConfirm}>
          Create
        </Button>
        <Button variant="cancel" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default CustomNodeRenameModal;
