import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  margin: auto;
`;

function Step({children}: PropsWithChildren): ReactElement {
  return <Container>{children}</Container>;
}

export default Step;
