import {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import NodeNameDisplay from 'components/common/node/NodeNameDisplay';

const Container = styled.div`
  cursor: default;
`;

export type INodeNameMenuAction = {
  type: 'CHANGE' | 'REMOVE' | 'SPREADSHEET';
  path: string[];
  x?: number;
  y?: number;
};

type IProps = {
  database: string;
  keys: string[];
  onMenu(action: INodeNameMenuAction): void;
  onChangeNodeInfoModal(bool: boolean, key: string[]): void;
};

function NodeName({database, keys, onMenu, onChangeNodeInfoModal}: IProps): ReactElement {
  const [isShowMenu, setIsShowMenu] = useState(false);

  useEffect(() => {
    if (isShowMenu) {
      document.addEventListener('mousedown', () => setIsShowMenu(false), {once: true});
    }
  }, [isShowMenu]);

  return (
    <Container>
      <NodeNameDisplay
        hasContextMenu
        database={database}
        nodes={keys}
        onMenu={onMenu}
        onChangeNodeInfoModal={onChangeNodeInfoModal}
      />
    </Container>
  );
}

export default NodeName;
