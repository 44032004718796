import {IAnnotation, IMetaPfdTagInfo, IPfdAction, IPfdState} from 'components/mpfd/type';
import {getUniqueKey} from 'utils/commons';
import {
  findMinValueAndIndex,
  mergeCommonArrays,
  pointDistance
} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {annotation, CONST} from 'components/mpfd/hooks/functions/action-calculation-functions';

export type IMpfdMouseActionType =
  | 'onMouseUpOnOutOfCanvas'
  | 'MOUSE_MOVE'
  | 'MOUSE_DOWN'
  | 'MOUSE_UP'
  | 'MOUSE_DOWN_RIGHT_BTN'
  | 'KEYPRESS_DELETE';

export const IMpfdMouseActionTypes: IMpfdMouseActionType[] = [
  'onMouseUpOnOutOfCanvas',
  'MOUSE_MOVE',
  'MOUSE_DOWN',
  'MOUSE_UP',
  'MOUSE_DOWN_RIGHT_BTN',
  'KEYPRESS_DELETE'
];

const mouseAction = (state: IPfdState, action: IPfdAction) => {
  const activeImage = state.images;
  switch (action.type as IMpfdMouseActionType) {
    case 'onMouseUpOnOutOfCanvas': {
      return {...state, mode: null};
    }
    case 'MOUSE_MOVE': {
      let {x, y} = action;
      if (!state.mode) return state;
      if (!activeImage) return state;

      switch (state.mode.mode) {
        case 'MOVE_REGION': {
          const {region} = state.mode;
          const {idx, annot} = annotation.annot2DefaultInfo(state.regions, region);
          if (idx === -1) return state;
          // 이미지 밖으로 나가지 말아야함
          if (0.01 < x && x < 0.99 && y > 0.01 && y < 0.99) {
            state = {...state, regions: state.regions.with(idx, annotation.movePointOrBlock(annot, x, y))};
          } else {
            let newX = x;
            let newY = y;
            if (x < 0.01) {
              newX = 0.01;
            }
            if (x > 0.99) {
              newX = 0.99;
            }
            if (y < 0.01) {
              newY = 0.01;
            }
            if (y > 0.99) {
              newY = 0.99;
            }
            state = {
              ...state,
              isChanged: true,
              regions: state.regions.with(idx, annotation.movePointOrBlock(annot, newX, newY))
            };
          }
          return state;
        }
        case 'RESIZE_BOX': {
          const {
            freedom: [xFree, yFree],
            original: {x: ox, y: oy, w: ow, h: oh},
            region
          } = state.mode;

          const dx = xFree === 0 ? ox : xFree === -1 ? Math.min(ox + ow, x) : ox;
          const dw = xFree === 0 ? ow : xFree === -1 ? Math.abs(ox + ow - dx) : Math.max(0, x - ox);

          const dy = yFree === 0 ? oy : yFree === -1 ? Math.min(oy + oh, y) : oy;
          const dh = yFree === 0 ? oh : yFree === -1 ? Math.abs(oy + oh - dy) : Math.max(0, y - oy);

          if (dw <= 0.001) {
            state = {...state, mode: {...state.mode, freedom: [xFree * -1, yFree]}};
          }
          if (dh <= 0.001) {
            state = {...state, mode: {...state.mode, freedom: [xFree, yFree * -1]}};
          }

          const {idx, annot} = annotation.annot2DefaultInfo(state.regions, region);
          if (idx === -1) return state;

          state = {
            ...state,
            isChanged: true,
            regions: state.regions.with(idx, {...annot, x: dx, w: dw, y: dy, h: dh})
          };
          return state;
        }
        case 'DRAW_EXPANDING_LINE': {
          const {region} = state.mode;
          const {annot, idx} = annotation.annot2DefaultInfo(state.regions, region);
          if (!annot || idx === -1) return state;
          state = {
            ...state,
            regions: state.regions.with(idx, {...annot, candidatePoint: {x, y}})
          };
          return state;
          // }
        }
        case 'MOVE_POINT_OF_LINE': {
          const {targetPointsIdx, arrowPointsIdx, region, moveTargetPointIsEndPoint, canMergedTargetPoints} =
            state.mode; // :todo mode 의 targetPointsIdx 와 그냥 targetPointsIdx 는 별개...!
          const defaultInfo = annotation.annot2DefaultInfo(state.regions, region);

          if (x < 0.01) {
            x = 0.01;
          }
          if (x > 0.99) {
            x = 0.99;
          }
          if (y < 0.01) {
            y = 0.01;
          }
          if (y > 0.99) {
            y = 0.99;
          }

          for (let i of targetPointsIdx) {
            defaultInfo.points.splice(i, 1, {x, y});
          }
          for (let i of arrowPointsIdx) {
            defaultInfo.arrowPoints.splice(i, 1, {x, y});
          }

          let targetRegionPoint;
          if (targetPointsIdx.length > 0) {
            targetRegionPoint = defaultInfo.points[targetPointsIdx[0]];
          } else {
            return {...state, mode: null};
          }

          let GroupOfLine = [];
          let GroupOfLineTemp = [];
          let gli = 0;
          while (gli < defaultInfo.points.length - 1) {
            if (defaultInfo.points.length === 0) break;
            const p1 = defaultInfo.points[gli];
            const p2 = defaultInfo.points[gli + 1];

            if (gli === 0) {
              GroupOfLineTemp.push([p1, p2]);
              gli = gli + 2;
            } else {
              const lineGroupIdx = GroupOfLineTemp.findIndex((item) =>
                item.some((p) => (p.x === p1.x && p.y === p1.y) || (p.x === p2.x && p.y === p2.y))
              );

              if (lineGroupIdx === -1) {
                GroupOfLineTemp.push([p1, p2]);
              } else {
                GroupOfLineTemp[lineGroupIdx] = GroupOfLineTemp[lineGroupIdx].concat([p1, p2]);
              }
              gli = gli + 2;
            }
          }
          GroupOfLine = mergeCommonArrays(GroupOfLineTemp);

          let minDistance = 1;
          let mergeTargetPoint: {x: number; y: number};
          let mergeTargetPointAnnotId: string;

          for (let i = 0; i < canMergedTargetPoints?.length; i++) {
            // cnaMergetargetPoints : {mergeCandidatePoints:annot.points, id:annot.id}
            if (canMergedTargetPoints.id === defaultInfo.annot.id) {
              continue;
            }
            const distance = [...(canMergedTargetPoints[i].mergeCandidatePoints || [])].map((item) =>
              pointDistance(item?.x, item?.y, targetRegionPoint.x, targetRegionPoint.y)
            );
            const minD = Math.min(...distance);
            if (minD < minDistance) {
              minDistance = minD;
              const minValAndIdx = distance.findIndex((item) => item === minD);
              mergeTargetPoint = canMergedTargetPoints[i].mergeCandidatePoints[minValAndIdx];
              mergeTargetPointAnnotId = canMergedTargetPoints[i].id;
            }
          }

          /**
           * mergeTargetpoint는 Endpoint들 중에서 가져왔으므로 무조건 끝점
           * 같은 annot => 같은 그룹에 없어야함
           * 다른 annot => 검증할 조건 없음
           */
          if (region.id === mergeTargetPointAnnotId && minDistance < 0.008 && moveTargetPointIsEndPoint) {
            const delTargetPoint = mergeTargetPoint;
            const delTargetPointIsEndPoint = annotation.isEndpoints(region.points, mergeTargetPoint);

            let lineGroupIdx = GroupOfLine.findIndex((item) =>
              item.some((p) => p?.x === delTargetPoint.x && p?.y === delTargetPoint.y)
            );
            const originPForCheck = defaultInfo.points[targetPointsIdx[0]];
            const annotationPointCenter = delTargetPointIsEndPoint
              ? GroupOfLine.findIndex((item) =>
                  item.some((p) => p.x === originPForCheck.x && p.y === originPForCheck.y)
                ) !== lineGroupIdx && {
                  x: (delTargetPoint.x + x) / 2,
                  y: (delTargetPoint.y + y) / 2
                }
              : undefined;
            return {
              ...state,
              mode: {...state.mode, annotationPointCenter, mergeTargetPoint, mergeTargetPointAnnotId},
              regions: state.regions.with(defaultInfo.idx, {
                ...defaultInfo.annot,
                points: defaultInfo.points,
                arrowPoints: defaultInfo.arrowPoints
              })
            };
          } else if (minDistance < 0.008 && moveTargetPointIsEndPoint) {
            const annotationPointCenter = mergeTargetPoint
              ? {
                  x: (mergeTargetPoint.x + x) / 2,
                  y: (mergeTargetPoint.y + y) / 2
                }
              : undefined;

            return {
              ...state,
              mode: {...state.mode, annotationPointCenter, mergeTargetPoint, mergeTargetPointAnnotId},
              regions: state.regions.with(defaultInfo.idx, {
                ...defaultInfo.annot,
                points: defaultInfo.points,
                arrowPoints: defaultInfo.arrowPoints
              })
            };
          }

          return {
            ...state,
            mode: {...state.mode, annotationPointCenter: undefined, mergeTargetPoint, mergeTargetPointAnnotId},
            regions: state.regions.with(defaultInfo.idx, {
              ...defaultInfo.annot,
              points: defaultInfo.points,
              arrowPoints: defaultInfo.arrowPoints
            })
          };
        }
        case 'MOVE_DATALAYER_POSITION': {
          const {region, startPosition, objectType} = state.mode;
          const {idx, annot} = annotation.annot2DefaultInfo(state.regions, region);
          const deltaX = x - startPosition.x;
          const deltaY = y - startPosition.y;
          const regionX = region?.type === 'expanding-line' ? region?.points?.[0].x : region?.x;
          const regionY = region?.type === 'expanding-line' ? region?.points?.[0].y : region?.y;
          let newPosX;
          let newPosY;
          if (objectType === 'chart') {
            newPosX = (region?.chartPosition?.x || regionX) + deltaX;
            newPosY = (region?.chartPosition?.y || regionY) + deltaY;
          } else if (objectType === 'table') {
            newPosX = (region?.tablePosition?.x || regionX) + deltaX;
            newPosY = (region?.tablePosition?.y || regionY) + deltaY;
          }

          if (newPosX < 0.01) {
            newPosX = 0.01;
          }
          if (newPosX > 0.99) {
            newPosX = 0.99;
          }
          if (newPosY < 0.01) {
            newPosY = 0.01;
          }
          if (newPosY > 0.99) {
            newPosY = 0.99;
          }
          let newAnnot;
          if (objectType === 'chart') {
            newAnnot = {
              ...annot,
              chartPosition: {
                ...annot.chartPosition,
                x: newPosX,
                y: newPosY
              }
            };
          } else if (objectType === 'table') {
            newAnnot = {
              ...annot,
              tablePosition: {
                ...annot.tablePosition,
                x: newPosX,
                y: newPosY
              }
            };
          }

          state = {
            ...state,
            isChanged: true,
            regions: state.regions.with(idx, newAnnot)
          };
          return state;
        }
        case 'RESIZE_DATALAYER_SCALE': {
          const {region, startPosition, objectType} = state.mode;
          const {idx, annot} = annotation.annot2DefaultInfo(state.regions, region);
          const deltaX = x - startPosition.x;
          const deltaY = y - startPosition.y;
          let newAnnot;
          if (objectType === 'chart') {
            newAnnot = {
              ...annot,
              chartPosition: {
                ...annot.chartPosition,
                w: startPosition.w + deltaX > 0.1 ? startPosition.w + deltaX : 0.1,
                h: startPosition.h + deltaY > 0.1 ? startPosition.h + deltaY : 0.1
              }
            };
          } else if (objectType === 'table') {
            newAnnot = {
              ...annot,
              tablePosition: {
                ...annot.tablePosition,
                w: startPosition.w + deltaX > 0.1 ? startPosition.w + deltaX : 0.1,
                h: startPosition.h + deltaY > 0.1 ? startPosition.h + deltaY : 0.1
              }
            };
          }
          state = {
            ...state,
            isChanged: true,
            regions: state.regions.with(idx, newAnnot)
          };
          return state;
        }
        default:
          return state;
      }
    }
    case 'MOUSE_DOWN': {
      if (!activeImage) return state;

      const {x, y} = action;
      state = {...state, mouseDownAt: {x, y}};

      if (state.mode) {
        switch (state.mode.mode) {
          case 'DRAW_EXPANDING_LINE': {
            const {annot, idx, points} = annotation.annot2DefaultInfo(state.regions, state.mode.region);
            if (!annot) break;
            const lastPoint = points.slice(-1)[0];
            if (points.length > 0 && Math.sqrt((lastPoint.x - x) ** 2 + (lastPoint.y - y) ** 2) < 0.002) {
              if (points?.length === 1) {
                const tempRegions = [...state.regions];
                tempRegions.splice(idx);
                return {...state, mode: null, regions: tempRegions};
              }
              if (points.length % 2 !== 0) {
                points.splice(points.length - 1, 1);
              }
              state = {
                ...state,
                mode: null,
                regions: state.regions.with(idx, {...annot, points, candidatePoint: undefined, unfinished: false})
              };
              return state;
            }

            let newPoints = [];
            if (points.length === 0) {
              newPoints = points.concat([{x, y}]);
            } else {
              newPoints = points.concat([{x, y}]).concat([{x, y}]);
            }

            state = {
              ...state,
              isChanged: true,
              regions: state.regions.with(idx, {
                ...annot,
                // points: expandingLine.points.concat([{x, y}])
                points: newPoints
              })
            };
            return state;
          }

          default:
            break;
        }
      }

      let newRegion: IAnnotation;
      let defaultRegionCls = state.selectedCls === undefined ? state.regionClsList[0] : state.selectedCls;
      let defaultRegionColor = '#CF4C2C';

      switch (state.selectedTool) {
        case 'create-point': {
          const {newAnnotName, newAnnotNum} = annotation.getAnnotName(state.regions, 'point');
          newRegion = {
            ...CONST.point,
            id: getUniqueKey(),
            x,
            y,
            cls: defaultRegionCls,
            name: newAnnotName + '-' + newAnnotNum
          };
          break;
        }
        case 'create-box': {
          const {newAnnotName, newAnnotNum} = annotation.getAnnotName(state.regions, 'region');
          newRegion = {
            ...CONST.box,
            id: getUniqueKey(),
            x,
            y,
            cls: defaultRegionCls,
            name: newAnnotName + '-' + newAnnotNum
          };
          state = {
            ...state,
            mode: {
              mode: 'RESIZE_BOX',
              editLabelEditorAfter: true,
              regionId: newRegion.id,
              freedom: [1, 1],
              original: {x, y, w: newRegion.w, h: newRegion.h},
              isNew: true,
              region: newRegion
            }
          };

          break;
        }
        case 'create-port': {
          const {newAnnotName, newAnnotNum} = annotation.getAnnotName(state.regions, 'port');
          newRegion = {
            ...CONST.port,
            id: getUniqueKey(),
            x,
            y,
            cls: defaultRegionCls,
            name: newAnnotName + '-' + newAnnotNum,
            labelVisible: true,
            portType: 'From'
          };
          state = {
            ...state,
            mode: {
              mode: 'RESIZE_BOX',
              editLabelEditorAfter: true,
              regionId: newRegion.id,
              freedom: [1, 1],
              original: {x, y, w: newRegion.w, h: newRegion.h},
              isNew: true,
              region: newRegion
            }
          };

          break;
        }
        case 'create-expanding-line': {
          const {newAnnotName, newAnnotNum} = annotation.getAnnotName(state.regions, 'line');
          newRegion = {
            type: 'expanding-line',
            unfinished: true,
            points: [{x, y}],
            // open: true,
            highlighted: false,
            color: defaultRegionColor,
            cls: defaultRegionCls,
            id: getUniqueKey(),
            name: newAnnotName + '-' + newAnnotNum,
            labelVisible: true
          };
          state = {...state, mode: {mode: 'DRAW_EXPANDING_LINE', regionId: newRegion.id, region: newRegion}};
          break;
        }
        default:
          break;
      }

      const regions = [...state.regions]
        .map((r) => ({...r, editingLabels: false, highlighted: false}) as IAnnotation)
        .concat(
          newRegion
            ? [
                {
                  ...newRegion,
                  isShowTableHeader: true,
                  chartPosition: {
                    w: 0.1,
                    h: 0.1
                  },
                  tablePosition: {
                    w: 0.1,
                    h: 0.1
                  }
                }
              ]
            : []
        );

      const regions_ = regions.filter((item) => item?.type); // 빈 쓰레기 제거
      if (newRegion) {
        return {...state, isChanged: true, regions: regions_};
      } else {
        return {...state, regions: regions_};
      }
    }
    case 'MOUSE_UP': {
      const {x, y} = action;
      if (!state.mode) return state;
      state = {...state, mouseDownAt: null};
      switch (state.mode.mode) {
        case 'RESIZE_BOX':
          if (state.mode.isNew) {
            if (Math.abs(state.mode.original.x - x) < 0.002 || Math.abs(state.mode.original.y - y) < 0.002) {
              return {
                ...state,
                regions: annotation.modifyRegion(
                  state.regions,
                  {
                    ...state.mode.region,
                    w: Math.abs(state.mode.original.x - x),
                    h: Math.abs(state.mode.original.y - y)
                  },
                  null
                ),
                mode: null
              };
            } else {
            }
          }
          if (state.mode.editLabelEditorAfter) {
            return {
              ...state,
              regions: annotation.modifyRegion(state.regions, state.mode.region, {editingLabels: true}),
              mode: null
            };
          }
          state = {...state, mode: null};
          return state;
        case 'MOVE_REGION':
        case 'MOVE_POLYGON_POINT':
        case 'MOVE_LINE_OF_LINE': {
          state = {...state, mode: null};
          return state;
        }
        case 'MOVE_POINT_OF_LINE': {
          const {targetPointsIdx, region, annotationPointCenter, mergeTargetPoint, mergeTargetPointAnnotId} =
            state.mode; // :todo mode 의 targetPointsIdx 와 그냥 targetPointsIdx 는 별개...!
          if (!annotationPointCenter) {
            return {...state, mode: null};
          }
          const defaultInfo = annotation.annot2DefaultInfo(state.regions, region);

          if (region.id === mergeTargetPointAnnotId) {
            let targetRegionPoint;
            if (targetPointsIdx.length > 0) {
              targetRegionPoint = defaultInfo.points[targetPointsIdx[0]];
            } else {
              return {...state, mode: null};
            }
            const distance = [...(defaultInfo.points || [])].map((item) =>
              pointDistance(item?.x, item?.y, targetRegionPoint.x, targetRegionPoint.y)
            );
            const minValAndIdx = findMinValueAndIndex(distance);
            if (minValAndIdx === null) {
              return {...state, mode: null};
            }
            state = {...state, mode: null};
            if (minValAndIdx.minValue < 0.008) {
              // 반지름이 0.04인데 (모드변경시) 그것보다 가까우면 겹친것
              const delTargetPoint = defaultInfo.points[minValAndIdx.idx];
              const newPoints = defaultInfo.points.map(function (item) {
                if (item.x === delTargetPoint.x && item.y === delTargetPoint.y) {
                  return targetRegionPoint;
                } else {
                  return item;
                }
              });
              const duplicateExcept = [];
              for (let i = 0; i < newPoints.length; i = i + 2) {
                const p1 = newPoints[i];
                const p2 = newPoints[i + 1];
                if (p1.x !== p2.x || p1.y !== p2.y) {
                  duplicateExcept.push(p1);
                  duplicateExcept.push(p2);
                }
              }

              const arrowPoints = defaultInfo.arrowPoints.filter(
                (item) =>
                  !(item.x === delTargetPoint.x && item.y === delTargetPoint.y) &&
                  !(item.x === targetRegionPoint.x && item.y === targetRegionPoint.y)
              );

              return {
                ...state,
                regions: state.regions.with(defaultInfo.idx, {
                  ...defaultInfo.annot,
                  points: duplicateExcept,
                  arrowPoints
                })
              };
            } else {
            }
          } else {
            const mergeTargetAnnot = state.regions.find((item) => item.id === mergeTargetPointAnnotId);
            const movePointBelongAnnot = region;

            let targetRegionPoint;
            if (targetPointsIdx.length > 0) {
              targetRegionPoint = defaultInfo.points[targetPointsIdx[0]];
            } else {
              return {...state, mode: null};
            }
            const arrowPoints = defaultInfo.arrowPoints.filter(
              (item) =>
                !(item.x === mergeTargetPoint.x && item.y === mergeTargetPoint.y) &&
                !(item.x === targetRegionPoint.x && item.y === targetRegionPoint.y)
            );
            // const points
            const newPoints = annotation.reorderPoints([
              ...mergeTargetAnnot.points,
              ...movePointBelongAnnot.points.with(targetPointsIdx, mergeTargetPoint)
            ]);
            const newMetaPfdTagInfo = [
              ...(mergeTargetAnnot?.metaPfdTagInfo || []),
              ...(movePointBelongAnnot?.metaPfdTagInfo || [])
            ];

            const delDuplicate: IMetaPfdTagInfo[] = [];
            for (let i = 0; i < newMetaPfdTagInfo?.length; i++) {
              const checkTarget = newMetaPfdTagInfo?.[i];
              if (!delDuplicate.some((item) => item?.tag?.join(', ') === checkTarget?.tag?.join(', '))) {
                delDuplicate.push(checkTarget);
              }
            }

            const newRegions = state.regions
              .filter((item) => item.id !== mergeTargetPointAnnotId)
              .map((annot) =>
                annot.id === region.id
                  ? {
                      ...movePointBelongAnnot,
                      isTableVisible: mergeTargetAnnot?.isTableVisible || movePointBelongAnnot?.isTableVisible,
                      isShowTableHeader: mergeTargetAnnot?.isShowTableHeader || movePointBelongAnnot?.isShowTableHeader,
                      isNormalChartVisible:
                        mergeTargetAnnot?.isNormalChartVisible || movePointBelongAnnot?.isNormalChartVisible,
                      points: newPoints,
                      arrowPoints,
                      metaPfdTagInfo: delDuplicate
                    }
                  : annot
              );

            return {...state, regions: newRegions, mode: null};
          }
          return state;
        }
        default:
          return state;
      }
      // break;
    }
    case 'MOUSE_DOWN_RIGHT_BTN': {
      const {mode} = state;
      if (mode) {
        switch (mode.mode) {
          case 'DRAW_EXPANDING_LINE': {
            const {region} = state.mode;
            const {annot, idx, points} = annotation.annot2DefaultInfo(state.regions, region);
            if (points?.length === 0 || points?.length === 1) {
              const tempRegions = [...state.regions];
              tempRegions.splice(idx);
              return {...state, mode: null, regions: tempRegions};
            }
            if (points.length % 2 !== 0) {
              points.splice(points.length - 1, 1);
            }
            state = {
              ...state,
              mode: null,
              regions: state.regions.with(idx, {...annot, points, candidatePoint: undefined, unfinished: false})
            };
            return state;
          }
          default:
            return state;
        }
      }
      break;
    }
    case 'KEYPRESS_DELETE': {
      if ([...(state.regions || [])].some((item) => item.highlighted)) {
        return {...state, regions: state.regions.filter((item) => !item.highlighted)};
      }
      return state;
    }
    default:
      break;
  }
  return state;
};

export default mouseAction;
