import {useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div`
  display: none;
  &.visible {
    display: block;
  }
`;

const Label = styled.div`
  white-space: nowrap;
  padding: 2px 5px;
  border-radius: 10px 10px 10px 0;
  color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  &.equipment {
    background-color: rgba(10, 168, 157, 0.85);
  }

  &.streams {
    background-color: rgba(236, 62, 175, 0.85);
  }

  &.selected {
    background-color: #3d74fe;
  }
`;

const Description = styled.div`
  text-align: left;
  background: rgba(29, 29, 29, 0.75);
  color: #d1d1d1;
  padding: 10px 15px;
  border-radius: 5px;
  width: 200px;
  font-size: 13px;
  cursor: pointer;
`;

type IProps = {
  visible: boolean;
  type: string;
  title: string;
  description: string;
  selected?: boolean;
};

function ThreeLandscapeLabel({title, description, visible, type, selected}: IProps) {
  const [isActive, setIsActive] = useState(false);
  let visibleDescription = false;

  return (
    <Container className={classnames({visible}, 'three-label-container')}>
      <Label className={`label ${type} ${selected && 'selected'}`} onClick={() => setIsActive(!isActive)}>
        {title}
        {isActive && visibleDescription && (
          <Description
            dangerouslySetInnerHTML={{__html: description}}
            onClick={() => setIsActive(!isActive)}
          ></Description>
        )}
      </Label>
    </Container>
  );
}

export default ThreeLandscapeLabel;
