import React from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function ModelPipes() {
  const {nodes, materials} = useGltfCustom('/gltf/Pipes/Pipes.gltf');

  return (
    <>
      <group dispose={null} scale={0.2}>
        <mesh
          geometry={nodes.pipes.geometry}
          material={materials.Simacro_Pipes}
          position={[2.927, 41.419, -0.333]}
          rotation={[0, 0, 0]}
        />
      </group>
    </>
  );
}

// useGLTF.preload('/gltf/Pipes/Pipes.gltf');
