import React, {useContext, useState} from 'react';
import useEventCallback from 'use-event-callback';
import RemoveScrollWrapper from 'components/mpfd/etc/RemoveScrollWrapper';
import AnnotationLayer from 'components/mpfd/layer/AnnotationLayer';
import {IPfdAction, IPfdCfg, IPfdState} from 'components/mpfd/type';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import LayerWrapper from 'components/mpfd/layer/LayerWrapper';
import {applyToPoint, inverse} from 'transformation-matrix';
import {getDefaultMat} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import MetaPfdWidgetImageLayer from 'components/pc/widgets/metaPfd/layer/MetaPfdWidgetImageLayer';
import MetaPfdWidgetDataLayer from 'components/pc/widgets/metaPfd/layer/MetaPfdWidgetDataLayer';
import MetaPfdWidgetAnnotationLayer from 'components/pc/widgets/metaPfd/layer/MetaPfdWidgetAnnotationLayer';
import MetaPfdWidgetLayerWrapper from 'components/pc/widgets/metaPfd/layer/MetaPfdWidgetLayerWrapper';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import {ILatestNode, ILatestNodeHandlerReturn} from 'hooks/useLatestNodeHandler';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  overflow: hidden;
  cursor: auto;
  display: block;
`;

const SnapShotContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

type IProps = {
  state: IPfdState;
  id: string;
  cfg: IPfdCfg;
  layoutChanging: boolean;
  widgetRef: any;
  snapShotImage: any;
  selected: boolean;
  imagePositionTest: any;
  metaPfdWidgetScale: number;
  dispatchToReducer(action: IPfdAction): void;
  latestTagHandlerForTable?: ILatestTagHandlerReturn;
  latestTagHandlerForChart?: ILatestTagHandlerReturn;
  latestNodeHandlerForTable?: ILatestNodeHandlerReturn;
  latestNodeHandlerForChart?: ILatestNodeHandlerReturn;
};

function MetaPfdViewer({
  state,
  cfg,
  dispatchToReducer,
  id,
  layoutChanging,
  widgetRef,
  snapShotImage,
  selected,
  imagePositionTest,
  metaPfdWidgetScale,
  latestTagHandlerForTable,
  latestTagHandlerForChart,
  latestNodeHandlerForTable,
  latestNodeHandlerForChart
}: IProps) {
  const {regions, images} = state;
  const [imageDimensions, changeImageDimensions] = useState({naturalWidth: 0, naturalHeight: 0});

  const onImageLoaded = useEventCallback(({naturalWidth, naturalHeight}) => {
    const dims = {naturalWidth, naturalHeight};
    changeImageDimensions(dims);
    setTimeout(() => changeImageDimensions(dims), 10);
  });
  let imagePosition = imagePositionTest;
  /**
   * MIGRATION-1 :
   * data : {stateData: IMPfdData} :2.12 version
   * data : {mPfdFileData : {stateData: IMPfdData}} : 2.13 version
   */
  if (!imagePosition && imageDimensions?.naturalWidth > 0 && imageDimensions?.naturalHeight > 0) {
    const [iw, ih] = [imageDimensions.naturalWidth, imageDimensions.naturalHeight];
    let tempMat = getDefaultMat();
    imagePosition = {
      topLeft: applyToPoint(inverse({...tempMat}), {x: 0, y: 0}),
      bottomRight: applyToPoint(inverse({...tempMat}), {x: iw, y: ih})
    };
    metaPfdWidgetScale = 1;
  }

  return (
    <Container>
      <RemoveScrollWrapper style={{width: '100%', height: '100%'}} id={id}>
        <MetaPfdWidgetLayerWrapper layoutChanging={layoutChanging}>
          {layoutChanging && (
            <MetaPfdWidgetImageLayer
              image={images}
              snapShotImage={snapShotImage}
              selected={selected}
              layoutChanging={layoutChanging}
              onLoad={onImageLoaded}
            />
          )}
          <SnapShotContainer ref={widgetRef}>
            {!layoutChanging && (
              <>
                <MetaPfdWidgetImageLayer
                  image={images}
                  snapShotImage={snapShotImage}
                  selected={selected}
                  layoutChanging={layoutChanging}
                  onLoad={onImageLoaded}
                />
                {(selected || !snapShotImage) && images?.imageSrc && (
                  <MetaPfdWidgetAnnotationLayer
                    imagePosition={imagePosition}
                    regions={regions}
                    id={id}
                    isViewer={true}
                    dispatchToReducer={dispatchToReducer}
                    cfg={cfg}
                  />
                )}
              </>
            )}
          </SnapShotContainer>
          {!layoutChanging && cfg?.isDisplayDataValues && (
            <>
              <MetaPfdWidgetDataLayer
                mat={{a: metaPfdWidgetScale}}
                imageDimensions={imageDimensions}
                imagePosition={imagePosition}
                regions={regions}
                cfg={cfg}
                isViewer={true}
                id={id}
                tableLatestTagHandler={latestTagHandlerForTable}
                chartLatestTagHandler={latestTagHandlerForChart}
                tableLatestNodeHandler={latestNodeHandlerForTable}
                chartLatestNodeHandler={latestNodeHandlerForChart}
              />
            </>
          )}
        </MetaPfdWidgetLayerWrapper>
      </RemoveScrollWrapper>
    </Container>
  );
}

export default MetaPfdViewer;
