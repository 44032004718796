import {IPfdState, IPfdAction, IMetaPfdTagInfo} from 'components/mpfd/type';
import {getUniqueKey} from 'utils/commons';
import {annotation, CONST} from 'components/mpfd/hooks/functions/action-calculation-functions';

export type IMpfdToolActionType =
  | 'MERGE_LINE'
  | 'REMOVE_PARTS_OF_REGION'
  | 'SPLIT_LINE_VIA_LINE'
  | 'CREATE_BREAK_POINT_REGION'
  | 'BEGIN_MOVE_POINT_OF_LINE'
  | 'SPLIT_LINE_VIA_POINT'
  | 'BEGIN_MOVE_LINE_OF_LINE'
  | 'BEGIN_SELECT_POINT_OF_LINE'
  | 'END_SELECT_POINT_OF_LINE'
  | 'APPLY_NORMAL_ARROW_TO_POINT'
  | 'APPLY_NORMAL_SHAPE_TO_POINT'
  | 'BEGIN_MOVE_POINT'
  | 'BEGIN_BOX_TRANSFORM'
  | 'MODIFY_DETECTION_LINES'
  | 'BEGIN_MOVE_DATALAYER_POSITION'
  | 'END_MOVE_DATALAYER_POSITION'
  | 'BEGIN_RESIZE_DATALAYER_SCALE'
  | 'END_RESIZE_DATALAYER_SCALE'
  | 'REMOVE_BREAK_POINT_ON_LINE';

export const IMpfdToolActionTypes: IMpfdToolActionType[] = [
  'MERGE_LINE',
  'REMOVE_PARTS_OF_REGION',
  'SPLIT_LINE_VIA_LINE',
  'CREATE_BREAK_POINT_REGION',
  'BEGIN_MOVE_POINT_OF_LINE',
  'SPLIT_LINE_VIA_POINT',
  'BEGIN_MOVE_LINE_OF_LINE',
  'BEGIN_SELECT_POINT_OF_LINE',
  'END_SELECT_POINT_OF_LINE',
  'APPLY_NORMAL_ARROW_TO_POINT',
  'APPLY_NORMAL_SHAPE_TO_POINT',
  'BEGIN_MOVE_POINT',
  'BEGIN_BOX_TRANSFORM',
  'MODIFY_DETECTION_LINES',
  'BEGIN_MOVE_DATALAYER_POSITION',
  'END_MOVE_DATALAYER_POSITION',
  'BEGIN_RESIZE_DATALAYER_SCALE',
  'END_RESIZE_DATALAYER_SCALE',
  'REMOVE_BREAK_POINT_ON_LINE'
];

const toolAction = (state: IPfdState, action: IPfdAction) => {
  switch (action.type as IMpfdToolActionType) {
    case 'MERGE_LINE': {
      const {regions} = action;
      let newPoints = [];
      let newArowPoints = [];
      let newMetaPfdTagInfo = [];
      let newIsTableVisible = false;
      let newIsShowTableHeader = false;
      let newIsNormalChartVisible = false;
      for (let r of regions) {
        newIsTableVisible = newIsTableVisible || r.isTableVisible;
        newIsShowTableHeader = newIsShowTableHeader || r.isShowTableHeader;
        newIsNormalChartVisible = newIsNormalChartVisible || r.isNormalChartVisible;
        newMetaPfdTagInfo = [...newMetaPfdTagInfo, ...(r?.metaPfdTagInfo || [])];
      }

      const points = regions.map((item) => (item.points ? item.points : []));
      for (let p of points) {
        newPoints = newPoints.concat(p);
      }
      newPoints = annotation.reorderPoints(newPoints);
      const arrowPoints = regions.map((item) => (item?.arrowPoints ? item?.arrowPoints : []));
      for (let p of arrowPoints) {
        newArowPoints = newArowPoints.concat(p);
      }

      const {regionNameAndId, standardId} = annotation.name2nameAndIdx('line', state.regions, 'expanding-line');

      const delDuplicate: IMetaPfdTagInfo[] = [];
      for (let i = 0; i < newMetaPfdTagInfo?.length; i++) {
        const checkTarget = newMetaPfdTagInfo?.[i];
        if (!delDuplicate.some((item) => item?.tag?.join(', ') === checkTarget?.tag?.join(', '))) {
          delDuplicate.push(checkTarget);
        }
      }

      const newLine = {
        ...CONST.line,
        id: getUniqueKey(),
        points: newPoints,
        arrowPoints: newArowPoints,
        name: regionNameAndId.name + '-' + (standardId + 1),
        labelVisible: true,
        isTableVisible: newIsTableVisible,
        isShowTableHeader: newIsShowTableHeader,
        isNormalChartVisible: newIsNormalChartVisible,
        // metaPfdTagInfo: newMetaPfdTagInfo,
        metaPfdTagInfo: delDuplicate,
        chartPosition: {
          w: 0.1,
          h: 0.1
        },
        tablePosition: {
          w: 0.1,
          h: 0.1
        }
      };
      const deleteMergedRegions = [...state.regions].filter((item) => !regions.some((item_) => item_.id === item.id));
      const newRegions = [...deleteMergedRegions].concat([newLine]);
      return {
        ...state,
        regions: newRegions
      };
    }
    case 'REMOVE_PARTS_OF_REGION': {
      const {region, targetPointsIdx} = action;
      const defaultInfo = annotation.annot2DefaultInfo(state.regions, region);
      defaultInfo.points.splice(targetPointsIdx, 2);
      const newRegions = state.regions.with(defaultInfo.idx, {...region, points: defaultInfo.points});
      if (defaultInfo.points?.length === 0) {
        newRegions.splice(defaultInfo.idx, 1);
      }
      state = {...state, regions: newRegions};
      return {...state};
    }
    case 'SPLIT_LINE_VIA_LINE': {
      /**
       * targetPointsIdx 는 line idx * 2 이므로 항상 짝수
       * 따라서 pointsCopy.splice(targetPointsIdx + 1, 0, insertedPoint, insertedPoint)는 알맞은 곳에 point 생성
       */
      const {region, targetPointsIdx, point} = action;
      const {pointsCopy, insertedPoint} = annotation.pointIdx2Point(region, targetPointsIdx, point);
      pointsCopy.splice(targetPointsIdx + 1, 0, insertedPoint, insertedPoint);
      const splitLine1 = pointsCopy.slice(0, targetPointsIdx + 2);
      const splitLine2 = pointsCopy.slice(targetPointsIdx + 2);
      const defaultInfo = annotation.annot2DefaultInfo(state.regions, region);

      const {regionNameAndId, standardId} = annotation.name2nameAndIdx(
        defaultInfo.annot.name,
        state.regions,
        'expanding-line'
      );

      const arrowPointsOriginal = [...(region?.arrowPoints || [])];
      const arrowPoints1 = [];
      const arrowPoints2 = [];
      for (let p of arrowPointsOriginal) {
        if (splitLine1.some((item) => item.x === p.x && item.y === p.y)) {
          arrowPoints1.push(p);
        } else if (splitLine2.some((item) => item.x === p.x && item.y === p.y)) {
          arrowPoints2.push(p);
        }
      }

      const regionsList = [...state.regions];
      regionsList.splice(
        defaultInfo.idx,
        1,
        {
          ...defaultInfo.annot,
          id: getUniqueKey(),
          name: regionNameAndId.name + '-' + (standardId + 1),
          points: splitLine1,
          color: '#8CB2FA',
          arrowPoints: arrowPoints1,
          labelVisible: true,
          metaPfdTagInfo: defaultInfo?.annot?.metaPfdTagInfo
        },
        {
          ...defaultInfo.annot,
          id: getUniqueKey(),
          name: regionNameAndId.name + '-' + (standardId + 2),
          points: splitLine2,
          color: '#A293FF',
          arrowPoints: arrowPoints2,
          labelVisible: true,
          metaPfdTagInfo: []
        }
      );
      state = {...state, regions: regionsList};
      return {...state};
    }
    case 'SPLIT_LINE_VIA_POINT': {
      const defaultInfo = annotation.annot2DefaultInfo(state.regions, action.region);
      /**
       * [a,b,b,c,c,d,f,g] del target point b
       * 먼저 선택된 point 와 연결된 선[a,b] 제외시킨다.
       * 남겨진 point [b,c,c,d,f,g]  라인 그리기 시행 => [b,c,c,d]
       * 나머지 전부 하나의 라인에 할당 [a,b,f,g]
       */
      let exceptSingleLine = [];
      let singleLine = [];

      for (let i = 0; i < action.region.points.length; i = i + 2) {
        const p1 = action.region.points[i];
        const p2 = action.region.points[i + 1];
        if (
          (p1.x === action.point.x && p1.y === action.point.y) ||
          (p2.x === action.point.x && p2.y === action.point.y)
        ) {
          singleLine.push(p1);
          singleLine.push(p2);
        } else {
          exceptSingleLine.push(p1);
          exceptSingleLine.push(p2);
        }
      }

      if (singleLine.length !== 4) {
        return state;
      }

      let segments = [];
      for (let i = 0; i < exceptSingleLine.length - 1; i += 2) {
        segments.push([exceptSingleLine[i], exceptSingleLine[i + 1]]);
      }

      /**
       * 라인 그리기 시작점이 split target 포인트면 안됨
       */
      let onStrokeDrawingLine =
        singleLine[1] === singleLine[2] ? [[singleLine[1], singleLine[0]]] : [[singleLine[0], singleLine[1]]];
      let restLines = [[singleLine[2], singleLine[3]]];
      const pointsEqual = (p1, p2) => p1.x === p2.x && p1.y === p2.y;

      while (segments.length > 0) {
        let lastPoint = onStrokeDrawingLine[onStrokeDrawingLine.length - 1][1];
        let foundConnection = false;

        for (let i = 0; i < segments.length; i++) {
          let currentSegment = segments[i];

          if (pointsEqual(lastPoint, currentSegment[0])) {
            onStrokeDrawingLine.push(currentSegment);
            segments.splice(i, 1);
            foundConnection = true;
            break;
          } else if (pointsEqual(lastPoint, currentSegment[1])) {
            onStrokeDrawingLine.push([currentSegment[1], currentSegment[0]]);
            segments.splice(i, 1);
            foundConnection = true;
            break;
          }
        }

        if (!foundConnection) {
          restLines.push(segments[0]);
          segments.splice(0, 1);
        }
      }

      let points_1 = [];
      let points_2 = [];
      for (let l of onStrokeDrawingLine) {
        points_1.push(l[0]);
        points_1.push(l[1]);
      }
      for (let l of restLines) {
        points_2.push(l[0]);
        points_2.push(l[1]);
      }

      const {regionNameAndId, standardId} = annotation.name2nameAndIdx(
        defaultInfo?.annot?.name,
        state?.regions,
        'expanding-line'
      );
      if (!regionNameAndId?.name) {
        return state;
      }

      const regionsList = [...state.regions];
      regionsList.splice(
        defaultInfo.idx,
        1,
        {
          ...defaultInfo.annot,
          metaPfdTagInfo: defaultInfo?.annot?.metaPfdTagInfo,
          id: getUniqueKey(),
          name: regionNameAndId?.name + '-' + standardId,
          points: points_1,
          color: '#8CB2FA'
        },
        {
          ...defaultInfo.annot,
          id: getUniqueKey(),
          metaPfdTagInfo: [],
          name: regionNameAndId?.name + '-' + (standardId + 1),
          points: points_2,
          color: '#A293FF'
        }
      );
      return {...state, regions: regionsList};
    }
    case 'CREATE_BREAK_POINT_REGION': {
      const {region, targetPointsIdx, point} = action;
      const {pointsCopy, insertedPoint} = annotation.pointIdx2Point(region, targetPointsIdx, point);
      pointsCopy.splice(targetPointsIdx + 1, 0, insertedPoint, insertedPoint);
      const targetRegionIdx = state.regions.findIndex((r) => r.id === region.id);
      const regionsList = [...state.regions].with(targetRegionIdx, {
        ...region,
        points: pointsCopy
      });

      state = {...state, regions: regionsList};
      return {...state};
    }
    case 'REMOVE_BREAK_POINT_ON_LINE': {
      /**
       * [a, del], [del, c] 에서 del 삭제, [a, c] 병합,
       * del 개수가 3개라면 함수 실행 취소
       * End point 경우에도 (del 개수가 1개라면) 함수 실행 취소,
       * => delPoint 2개가 아닐 경우에는 실행 취소
       */
      const delPoints = action.region.points.filter((item) => item.x === action.point.x && item.y === action.point.y);
      if (delPoints?.length !== 2) {
        return state;
      }
      const newLine = [];
      const newPoints = [];
      for (let i = 0; i < action.region.points.length; i = i + 2) {
        const p1 = action.region.points[i];
        const p2 = action.region.points[i + 1];
        if (p1.x === action.point.x && p1.y === action.point.y) {
          newLine.push(p2);
        } else if (p2.x === action.point.x && p2.y === action.point.y) {
          newLine.push(p1);
        } else {
          newPoints.push(p1);
          newPoints.push(p2);
        }
      }
      const points = annotation.reorderPoints(newPoints.concat(newLine));
      if (points?.length % 2 !== 0) {
        return state;
      }
      const targetRegionIdx = state.regions.findIndex((r) => r.id === action.region.id);
      return {...state, regions: state.regions.with(targetRegionIdx, {...action.region, points})};
    }
    case 'BEGIN_MOVE_POINT_OF_LINE': {
      const {idxArrOfTargetPoint, idxArrOfArrowPoint} = annotation.singlePoint2SamePointIdxArr(
        state.regions,
        action.point,
        action.region.id
      );
      const moveTargetPointIsEndPoint = annotation.isEndpoints(action.region.points, action.point);
      const canMergedTargetPoints = state.regions
        .filter((item) => item.type === 'expanding-line')
        .map(function (annot) {
          const points = annot?.points;
          const mergeCandidatePoints = points.filter(
            (item) =>
              annotation.isEndpoints(points, item) &&
              /**  move 하고있는 포인트제외 **/
              !(item.x === action.point.x && item.y === action.point.y)
          );

          return {id: annot.id, mergeCandidatePoints};
        });

      return {
        ...state,
        mode: {
          mode: 'MOVE_POINT_OF_LINE',
          targetPoint: action.point,
          regionId: action.region.id,
          region: action.region,
          targetPointsIdx: idxArrOfTargetPoint,
          arrowPointsIdx: idxArrOfArrowPoint,
          annotationPointCenter: undefined,
          moveTargetPointIsEndPoint,
          canMergedTargetPoints
        }
      };
    }
    case 'BEGIN_SELECT_POINT_OF_LINE': {
      // 중간점이면 toolbox 띄우지말자
      const targetPoint = action.point;
      let count = 0;
      action.region.points.map((item) => item.x === targetPoint.x && item.y === targetPoint.y && count++);
      if (count > 1) {
        return state;
      }
      return {
        ...state,
        mode: {
          mode: 'SELECT_POINT_OF_LINE',
          region: action.region,
          regionId: action.region.id,
          targetPoint,
          clientXYCoord: action.clientXY
        }
      };
    }
    case 'END_SELECT_POINT_OF_LINE': {
      return {...state, mode: null};
    }
    case 'APPLY_NORMAL_ARROW_TO_POINT': {
      // merge 되면 화살표 어떻게 할건지 / 처음 begin에서 해당되는 화살표 array idx point와 비슷한 방식으로 가지고 있기
      const {targetPoint, region} = state.mode;
      if (!region || !targetPoint) return state;
      const defaultInfo = annotation.annot2DefaultInfo(state.regions, region);
      if (defaultInfo.idx === -1) {
        return state;
      }
      const arrowPoints = [...(defaultInfo.annot.arrowPoints || [])];
      let arrowPointsFiltered = [];
      const arrowPointIdx = arrowPoints.findIndex((item) => item.x === targetPoint.x && item.y === targetPoint.y);
      if (arrowPointIdx === -1) {
        arrowPointsFiltered = [...arrowPoints];
        arrowPointsFiltered.push(targetPoint);
      } else {
        arrowPoints.splice(arrowPointIdx, 1);
        arrowPointsFiltered = arrowPoints;
      }
      return {
        ...state,
        regions: state.regions.with(defaultInfo.idx, {...region, arrowPoints: arrowPointsFiltered}),
        mode: null
      };
    }
    case 'APPLY_NORMAL_SHAPE_TO_POINT': {
      const {targetPoint, region} = state.mode;
      if (!region || !targetPoint) return state;
      const defaultInfo = annotation.annot2DefaultInfo(state.regions, region);
      if (defaultInfo.idx === -1) {
        return state;
      }
      const arrowPoints = [...(defaultInfo.annot.arrowPoints || [])].filter(
        (item) => !(item.x === targetPoint.x && item.y === targetPoint.y)
      );
      return {...state, regions: state.regions.with(defaultInfo.idx, {...region, arrowPoints}), mode: null};
    }
    case 'BEGIN_MOVE_POINT': {
      const {point} = action;
      const regions = annotation.resetFocus(state.regions, point);
      state = {...state, regions, mode: {mode: 'MOVE_REGION', region: action.point, regionId: action.point.id}};
      return state;
    }
    case 'BEGIN_BOX_TRANSFORM': {
      const {box, directions} = action;
      const regions = annotation.resetFocus(state.regions, box);
      if (directions[0] === 0 && directions[1] === 0) {
        state = {...state, regions, mode: {mode: 'MOVE_REGION', region: box}};
        return state;
      } else {
        state = {
          ...state,
          regions,
          mode: {
            mode: 'RESIZE_BOX',
            regionId: box.id,
            region: box,
            freedom: directions,
            original: {x: box.x, y: box.y, w: box.w, h: box.h}
          }
        };
        return state;
      }
    }
    case 'MODIFY_DETECTION_LINES': {
      const regions = [...state.regions].concat([action.region]);
      return {
        ...state,
        regions
      };
    }
    case 'BEGIN_MOVE_DATALAYER_POSITION': {
      return {
        ...state,
        mode: {
          mode: 'MOVE_DATALAYER_POSITION',
          regionId: action.region.id,
          region: action.region,
          startPosition: {
            x: action.x,
            y: action.y
          },
          objectType: action.objectType
        }
      };
    }
    case 'BEGIN_RESIZE_DATALAYER_SCALE': {
      let prevW;
      let prevH;
      if (action.objectType === 'chart') {
        prevW = action.region.chartPosition.w ? action.region.chartPosition.w : 0;
        prevH = action.region.chartPosition.h ? action.region.chartPosition.h : 0;
      } else if (action.objectType === 'table') {
        prevW = action.region.tablePosition.w ? action.region.tablePosition.w : 0;
        prevH = action.region.tablePosition.h ? action.region.tablePosition.h : 0;
      }
      return {
        ...state,
        mode: {
          mode: 'RESIZE_DATALAYER_SCALE',
          regionId: action.region.id,
          region: action.region,
          startPosition: {
            x: action.x,
            y: action.y,
            w: prevW,
            h: prevH
          },
          objectType: action.objectType
        }
      };
    }
    case 'END_MOVE_DATALAYER_POSITION':
    case 'END_RESIZE_DATALAYER_SCALE': {
      return {
        ...state,
        mode: null
      };
    }
    default:
      break;
  }
  return state;
};

export default toolAction;
