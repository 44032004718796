import {BaseSyntheticEvent, CSSProperties, MouseEvent, ReactElement, ReactNode, useRef} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {VariantTypes} from 'components/forms/types';
import {SizeTypes} from 'components/common/types';

const Container = styled.button<{$variant: VariantTypes}>`
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  ${({theme, $variant}) =>
    `&.${$variant} {
      background-color: ${theme.color[$variant]};
    }`};

  &.light,
  &.highlight {
    border: 1px solid #a2a2a2;
    color: #1f1f1f;
  }

  &.dark {
    color: #eeeeee;
  }

  &.optional {
    color: #1f1f1f;
  }

  &.reset {
    color: ${({theme}) => theme.color.info};
    border: 1px solid;
  }

  &.create {
    background-color: #74adff;
  }

  &.none {
    background-color: transparent;
    color: #1f1f1f;
    padding: 5px 5px;
  }

  &.sm {
    width: 120px;
    height: 38px;
    font-size: 18px;
  }

  &.lg {
    width: 100%;
  }
`;

type IProps<T> = {
  children?: ReactNode | ReactNode[];
  className?: string;
  disabled?: boolean;
  name?: string;
  title?: string;
  value?: T;
  variant?: VariantTypes;
  size?: SizeTypes;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
  onClick?(e: BaseSyntheticEvent, value?: T): void;
  onMouseDown?(e: MouseEvent): void;
};

function Button<T>({
  children,
  value,
  name,
  title,
  disabled,
  variant = 'primary',
  className,
  width,
  height,
  style,
  onClick,
  onMouseDown
}: IProps<T>): ReactElement {
  const buttonRef = useRef(null);

  const onClickButton = (e: BaseSyntheticEvent): void => {
    onClick?.(e, value);
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  return (
    <Container
      ref={buttonRef}
      name={name}
      disabled={disabled}
      title={title}
      style={{...style, width, height}}
      $variant={variant}
      className={classNames('common-button', variant, className)}
      onClick={onClickButton}
      onMouseDown={onMouseDown}
    >
      {children}
    </Container>
  );
}

export default Button;
