import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';

type StyleProps = {
  $isName: boolean;
};

const Container = styled.div<StyleProps>`
  width: 100%;
  display: flex;
  // justify-content: ${(props) => (props.$isName ? 'left' : 'center')};
  //justify-content: 'left';
  padding: 14px 20px 14px 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: ${(props) => (props.$isName ? 500 : 100)};
  box-sizing: border-box;
  flex-wrap: wrap;
  word-break: break-all;
  white-space: pre-wrap;
`;

type IProps = {
  keys: string;
  field: ITableField;
  value: string | undefined;
};

function ManagementTableCell({keys, field, value}: IProps) {
  const isName = ['name', 'firstName', 'lastName'].includes(field?.key || '');

  return <Container $isName={isName}>{value}</Container>;

  // return null;
}

export default ManagementTableCell;
