import {NodeToolbar, useReactFlow} from 'reactflow';
import styled from 'styled-components';
import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from 'react';
import {Position} from '@reactflow/core';
import {ColorResult, SketchPicker} from 'react-color';
import ToolbarItem from 'components/pc/common/shapes/ToolbarItem';
import {IShapeStyle, ShapeType} from 'components/pc/common/shapes/type';
import {compactColors} from 'components/pc/common/shapes/const';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import Tooltip from 'components/common/Tooltip';

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 5px;
  gap: 4px;
  pointer-events: auto;
  user-select: none;
  box-shadow: 0 2px 10px 0 #00000033;
  border: 2px solid #aaaaaa;
  background-color: #ffffff;
  border-radius: 5px;
`;

const ColorPickerContainer = styled.div`
  user-select: none;
  .colorPicker {
    pointer-events: auto;
  }
`;

type ToolTitles =
  | 'Font Color'
  | 'Font Size'
  | 'Background Color'
  | 'Opacity'
  | 'Stroke Width'
  | 'Stroke Style'
  | 'Border Color'
  | 'Delete'
  | 'Data Binding'
  | 'Text';

export type ToolName =
  | 'fontSize'
  | 'fontColor'
  | 'backgroundColor'
  | 'opacity'
  | 'strokeWidth'
  | 'strokeStyle'
  | 'borderColor'
  | 'close'
  | 'dataBinding'
  | 'text';

export type IToolbox = {
  title: ToolTitles;
  name: ToolName;
  icon: string | null;
  icon2?: string | null;
  type: ToolType;
  rightLines?: boolean;
};

type ToolType = 'selectForm-1' | 'colorPicker' | 'selectForm-2' | 'delBtn';

const toolCandidate: IToolbox[] = [
  {title: 'Font Color', name: 'fontColor', icon: 'shapeToolBoxAlphabetA', type: 'colorPicker', rightLines: true},
  {title: 'Font Size', name: 'fontSize', icon: null, type: 'selectForm-1', rightLines: true},
  {title: 'Background Color', name: 'backgroundColor', icon: 'shapeToolBoxFontBgColor', type: 'colorPicker'},
  {title: 'Border Color', name: 'borderColor', icon: 'shapeToolBoxFontBorderColor', type: 'colorPicker'},
  {title: 'Stroke Width', name: 'strokeWidth', icon: 'shapeToolBoxLineWidth', type: 'selectForm-2'},
  {title: 'Stroke Style', name: 'strokeStyle', icon: 'shapeToolBoxLineShape', type: 'selectForm-2'},
  {title: 'Data Binding', name: 'dataBinding', icon: 'dataBindingActive', icon2: 'dataBindingInActive', type: 'delBtn'},
  {title: 'Delete', name: 'close', icon: 'shapeToolBoxTrashCan', type: 'delBtn'}
];

type IProps = {
  type: ShapeType;
  shapeStyle: IShapeStyle;
  id: string;
  selected: boolean;
  onChangeStyle?(key: ToolName, value: string | number | number[] | ColorResult): void;
};

function ShapeNodeToolbar({type, shapeStyle, id, selected, onChangeStyle}: IProps) {
  const {dataSettingModalState} = useContext(ProcessCanvasContext);
  const [selectedTool, setSelectedTool] = useState<IToolbox | null>();
  const reactFlow = useReactFlow();
  const [, setDataSettingModalState] = dataSettingModalState;
  const [toolList, setToolList] = useState<IToolbox[]>([]);

  useEffect(() => {
    if (type === 'DataBinding') {
      setToolList([...toolCandidate]);
    } else {
      setToolList(toolCandidate.filter((item) => item.name !== 'dataBinding'));
    }
    return () => {
      setDataSettingModalState(null);
    };
  }, [type]);

  useEffect(() => {
    if (!selected) {
      setSelectedTool(null);
    }
  }, [selected]);

  const onClickToolBarItem = (tool: IToolbox) => {
    if (tool?.name === 'close') {
      reactFlow.deleteElements({nodes: [{id}]});
      return;
    }
    if (tool?.name === 'dataBinding' && selectedTool?.name !== 'dataBinding') {
      setDataSettingModalState(id);
      setSelectedTool(null);
      return;
    }
    if (tool === selectedTool) {
      setSelectedTool(null);
    } else {
      setSelectedTool(tool);
    }
  };

  const onChangeColor = useCallback(
    (color: ColorResult) => {
      if (selectedTool) {
        onChangeStyle(selectedTool?.name, color);
      }
    },
    [onChangeStyle, selectedTool]
  );

  const onChangeFontSize = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let num = parseInt(value);
    if (isNaN(num) || num < 0) {
      num = 0;
    } else if (num > 999) {
      num = 999;
    }
    onChangeStyle('fontSize', num);
  };

  const onClickFontSizePlusMinusButton = (factor: number) => {
    const fs = shapeStyle.fontSize;
    let num = fs + factor;
    if (isNaN(num) || num < 1) {
      num = 1;
    }
    onChangeStyle('fontSize', num);
  };

  const onChangeStrokeWidthOrStyle = (value: number[] | number) => {
    onChangeStyle(selectedTool.name, value);
  };

  return (
    <NodeToolbar offset={10} position={Position.Bottom} style={{pointerEvents: 'none'}}>
      <ToolsContainer className="nodrag nopan" onClick={(e) => e.stopPropagation()}>
        {toolList.map((item) => (
          <Tooltip key={item.name} content={item.title} place="top">
            <ToolbarItem
              key={item.name}
              tool={item}
              shapeStyle={shapeStyle}
              selectedTool={selectedTool}
              onClick={onClickToolBarItem}
              onChangeFontSize={onChangeFontSize}
              onChangeStrokeWidthOrStyle={onChangeStrokeWidthOrStyle}
              onClickFontSizePlusMinusButton={onClickFontSizePlusMinusButton}
            />
          </Tooltip>
        ))}
      </ToolsContainer>
      <ColorPickerContainer className="nodrag nopan">
        {selectedTool?.type === 'colorPicker' && (
          <SketchPicker
            className="colorPicker"
            presetColors={compactColors}
            color={shapeStyle[selectedTool?.name]?.rgb}
            onChange={onChangeColor}
          />
        )}
      </ColorPickerContainer>
    </NodeToolbar>
  );
}

export default ShapeNodeToolbar;

// export default memo(ShapeNodeToolbar, (prevProps, nextProps) => {
//   return prevProps.shapeStyle === nextProps.shapeStyle;
// });
