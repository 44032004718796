import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCloud,
  faCloudBolt,
  faCloudFog,
  faClouds,
  faCloudShowers,
  faCloudSun,
  faCloudSunRain,
  faSnowflake,
  faSunBright
} from '@fortawesome/pro-light-svg-icons';

function WeatherIcon(icon: string) {
  switch (icon) {
    case '01d':
      return <FontAwesomeIcon icon={faSunBright} />;
    case '02d':
      return <FontAwesomeIcon icon={faCloudSun} />;
    case '03d':
      return <FontAwesomeIcon icon={faCloud} />;
    case '04d':
      return <FontAwesomeIcon icon={faClouds} />;
    case '09d':
      return <FontAwesomeIcon icon={faCloudShowers} />;
    case '10d':
      return <FontAwesomeIcon icon={faCloudSunRain} />;
    case '11d':
      return <FontAwesomeIcon icon={faCloudBolt} />;
    case '13d':
      return <FontAwesomeIcon icon={faSnowflake} />;
    case '50d':
      return <FontAwesomeIcon icon={faCloudFog} />;
    case '01n':
      return <FontAwesomeIcon icon={faSunBright} />;
    case '02n':
      return <FontAwesomeIcon icon={faCloudSun} />;
    case '03n':
      return <FontAwesomeIcon icon={faCloud} />;
    case '04n':
      return <FontAwesomeIcon icon={faClouds} />;
    case '09n':
      return <FontAwesomeIcon icon={faCloudShowers} />;
    case '10n':
      return <FontAwesomeIcon icon={faCloudSunRain} />;
    case '11n':
      return <FontAwesomeIcon icon={faCloudBolt} />;
    case '13n':
      return <FontAwesomeIcon icon={faSnowflake} />;
    case '50n':
      return <FontAwesomeIcon icon={faCloudFog} />;
    default:
      return <></>;
  }
}

export default WeatherIcon;
