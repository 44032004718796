export enum META_PFD_zINDEX {
  REGION_COMPONENTS_POLYLINE = 0,
  IMAGE_BACKGROUND = 0,
  REGION_SHAPE_LAYER = 2,
  REGION_SHAPE = 3,
  HIGHLIGHT_BOX = 5,
  ZOOM_ACTION_LAYER = 9,
  TABLE_LAYER = 8,
  REGION_SELECT_AND_TRANSFORM_BOX_GRABBER = 7,
  CURSOR_IMG = 200,
  TOOLBOX = 9
}

export enum MOUSE_BUTTON {
  LEFT = 0,
  WHEEL = 1,
  RIGHT = 2
}
