import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {toDateFormat} from 'utils/commons';
import styled from 'styled-components';
import {IFile} from 'api/data-types';

const Container = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.2s;
  box-sizing: border-box;
  padding: 0 0.75rem 0.75rem 1rem;

  &.active {
    height: 140px;

    &.hover {
      height: 100px;
    }
  }
`;
const List = styled.dl`
  font-size: 13px;
  margin: 10px 0 10px 54px;

  dt {
    float: left;
    width: 80px;
    color: #a2a2a2;
  }
`;
const UnitSign = styled.span`
  color: #aaa;
`;
const ButtonGroup = styled.div`
  margin-left: 54px;
  display: flex;
  column-gap: 5px;
  height: 30px;

  button {
    height: 30px;
    width: 100%;

    &.danger {
      width: 100px;
    }
  }
`;

type IProps = {
  children?: ReactElement | ReactElement[];
  item: IFile;
  isActive?: boolean;
  activeType?: 'click' | 'hover';
};

function FileInfo({children, item, isActive, activeType = 'click'}: IProps): ReactElement {
  return (
    <Container className={classNames('action-item-info', {active: isActive}, activeType)}>
      <List>
        <dt>Created at</dt>
        <dd>{toDateFormat(item.createdTime, 'YYYY-MM-DD HH:mm:ss')}</dd>
        <dt>Updated at</dt>
        <dd>{toDateFormat(item.updatedTime, 'YYYY-MM-DD HH:mm:ss')}</dd>
        <dt>Owner</dt>
        <dd>{item.owner}</dd>
        <dt>File Size</dt>
        <dd>
          {item.fileSize?.toFixed(2)}
          <UnitSign>Mb</UnitSign>
        </dd>
      </List>
      <ButtonGroup>{children}</ButtonGroup>
    </Container>
  );
}

export default FileInfo;
