import React, {MouseEvent, useCallback} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import {hexToRGBA} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import AnnotationGroup from 'components/mpfd/annotation/parts/AnnotationGroup';

type PointRectProps = {
  $color: string;
  $highlighted: boolean;
  $createMode: boolean;
};

const PointRect = styled.rect<PointRectProps>`
  fill: transparent;
  stroke: ${(props) => props.$color};
  pointer-events: ${(props) => (props.$createMode ? 'none' : '')};
  &.pointHoverType:hover {
    fill: ${(props) => (props.$highlighted ? 'transparent' : hexToRGBA(props.$color, 0.45))};
  }
`;

enum SymbolType {
  Temperature = 'T',
  Pressure = 'P',
  MassFlow = 'Fm',
  MoleFlow = 'F',
  VolumeFlow = 'Fv'
}

type IProps = {
  iw: number;
  ih: number;
  color: string;
  region: IAnnotation;
  drawLineMode: boolean;
  pointHoverType: boolean;
  cfg: IPfdCfg;
  onMouseDown(e: MouseEvent, idx?: number): void;
};

function Point({iw, ih, color, region, drawLineMode, pointHoverType, cfg, onMouseDown}: IProps) {
  const {x, y, labelVisible, name, dataType, highlighted, id} = region;

  const handleMouseDown = useCallback((e: MouseEvent) => onMouseDown(e), [onMouseDown]);

  return (
    <AnnotationGroup onMouseDown={handleMouseDown} x={x} y={y} iw={iw} ih={ih}>
      {labelVisible && cfg?.isDisplayLabels && (
        <text
          x="0"
          y={-Math.floor(iw * 0.014)}
          fill={color}
          fontSize={Math.floor(iw * 0.014)}
          fontWeight={700}
          textAnchor="middle"
          className="annot-label"
        >
          {name}
        </text>
      )}
      <PointRect
        id={id}
        x={-iw * 0.007}
        y={-iw * 0.007}
        width={iw * 0.014}
        height={iw * 0.014}
        strokeWidth="2"
        className={classnames(pointHoverType && 'pointHoverType')}
        $color={color}
        $highlighted={highlighted}
        $createMode={drawLineMode}
      />
      <text
        x="0"
        y={Math.floor(iw * 0.01) / 3}
        fill={color}
        fontSize={Math.floor(iw * 0.01)}
        fontWeight={700}
        textAnchor="middle"
      >
        {SymbolType[dataType]}
      </text>
    </AnnotationGroup>
  );
}

export default Point;
