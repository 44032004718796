import {IModelRunnerRunConfig} from 'components/pc/widgets/modelRunner/types';

export const modelRunnerDefaultStateValue = {
  variableTableState: [
    {
      variableName: 'Inflow1',
      flowType: 'Inflow',
      variableValue: '',
      path: ['plant_fcc', 'FCC', 'E19FC305'],
      keys: 'key_45487742_1722315123250',
      variableDbType: 'cloud'
    },
    {
      variableName: 'Outflow1',
      flowType: 'Outflow',
      variableValue: '',
      path: [],
      keys: 'key_25084688_1722315224134'
    },
    {
      variableName: 'Outflow2',
      flowType: 'Outflow',
      variableValue: '',
      path: [],
      keys: 'key_8529194_1722315234460'
    }
  ],
  runConfigState: {
    autoRun: false,
    batchRun: false,
    intervalUnit: 1000,
    intervalValue: 1,
    environment: ''
  } as IModelRunnerRunConfig
};
