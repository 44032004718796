import TextFormCheckValidate, {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import React, {ChangeEvent, Dispatch, ReactNode, SetStateAction} from 'react';
import styled from 'styled-components';

type IProps = {
  loginInputState: [ILoginInputState, Dispatch<SetStateAction<ILoginInputState>>];
  validCheckIcon?: boolean;
  isInputBoxWidthFull?: boolean;
  onKeyPressDownEnter?(): void;
  children?: ReactNode;
};

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  flex-direction: column;
  > span {
    font-size: 12px;
    color: #b6b4c0;
    cursor: pointer;

    width: max-content;
  }
`;

function LoginInputComponent({
  loginInputState,
  validCheckIcon,
  isInputBoxWidthFull = false,
  onKeyPressDownEnter,
  children
}: IProps) {
  const [loginInput, setLoginInput] = loginInputState;

  return (
    <Container>
      {children}
      {Object.keys(loginInput).map((item, idx) => {
        const inputData = loginInput[item];

        const onChangeValue = (e: ChangeEvent, valid: boolean) => {
          const target = e.target as HTMLInputElement;
          const value = target.value as string;
          if (inputData?.validateOnChange) {
            if (item === 'passwordCheck') {
              if (value === loginInput['password']?.value) {
                setLoginInput((prev) => ({
                  ...prev,
                  [item]: {...inputData, value, valid, passwordMismatch: false}
                }));
              } else {
                setLoginInput((prev) => ({
                  ...prev,
                  [item]: {...inputData, value, valid, passwordMismatch: true}
                }));
              }
            } else if (item === 'password') {
              if (value === loginInput['passwordCheck']?.value) {
                setLoginInput((prev) => ({
                  ...prev,
                  [item]: {...inputData, value, valid},
                  passwordCheck: {...prev?.passwordCheck, passwordMismatch: false}
                }));
              } else {
                setLoginInput((prev) => ({
                  ...prev,
                  [item]: {...inputData, value, valid},
                  passwordCheck: {...prev?.passwordCheck, passwordMismatch: true}
                }));
              }
            } else {
              setLoginInput((prev) => ({
                ...prev,
                [item]: {...inputData, value, valid}
              }));
            }
          } else {
            setLoginInput((prev) => ({
              ...prev,
              [item]: {...inputData, value}
            }));
          }
        };

        const updateValidate = (valid: boolean) => {
          setLoginInput((prev) => ({
            ...prev,
            [item]: {
              ...inputData,
              valid
            }
          }));
        };

        const updateValidateLength = (validLength: boolean) => {
          setLoginInput((prev) => ({
            ...prev,
            [item]: {
              ...inputData,
              validLength
            }
          }));
        };

        const onBlur = () => {};

        return (
          <React.Fragment key={idx}>
            <TextFormCheckValidate
              type={inputData?.type}
              value={inputData?.value}
              passwordMismatch={inputData?.passwordMismatch}
              isWidthFull={isInputBoxWidthFull}
              isDisabled={inputData?.isDisabled}
              isValid={inputData?.valid}
              isValidLength={inputData?.validLength}
              placeholder={inputData?.placeholder}
              validCheckIcon={validCheckIcon}
              warningMessage={inputData?.warningMessage}
              warningMessageLength={inputData?.warningMessageLength}
              validateOnChange={inputData?.validateOnChange}
              onKeyPressDownEnter={onKeyPressDownEnter}
              onChangeValue={onChangeValue}
              updateValidate={updateValidate}
              updateValidateLength={updateValidateLength}
              onBlur={onBlur}
            />
          </React.Fragment>
        );
      })}
    </Container>
  );
}

export default LoginInputComponent;
