import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {IComponentTableField} from 'components/table/ComponentTable';
import ModelRunnerVariableTableParts from 'components/table/parts/ModelRunnerVariableTableParts';
import {IValueGroupObj} from 'components/pc/widgets/pythonEditor/types';
import {ILatestNode} from 'hooks/useLatestNodeHandler';
import {IModelRunnerProject, IModelRunnerVariable} from 'components/pc/widgets/modelRunner/types';
import useDraggable from 'utils/useDraggable';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import ModelRunnerImportTable from 'components/pc/widgets/modelRunner/ModelRunnerImportTable';
import ModelRunnerExportTable from 'components/pc/widgets/modelRunner/ModelRunnerExportTable';

const Container = styled.div``;

const ResizeArea = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3;

  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #fff;
`;

const fields: IComponentTableField[] = [
  {
    key: 'variableName',
    label: 'Variable Name',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '244px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        //todo
        // backgroundColor: type === 'Import' ?  '#f2f2f2',
        // padding: '5px 7px',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: ModelRunnerVariableTableParts
  },
  {
    key: 'flowType',
    label: 'Type',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '131px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: ModelRunnerVariableTableParts
  },
  {
    key: 'variableValue',
    label: 'Value',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '172px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        padding: 0,
        boxSizing: 'border-box'
      }
    },

    component: ModelRunnerVariableTableParts
  },
  {
    key: 'path',
    label: 'Definition / Path Information',
    css: {body: 'ellipsis'},
    style: {
      header: {
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: ModelRunnerVariableTableParts,
    customHeaderComponent: true
  }
];

type IProps = {
  variableTableState?: [IModelRunnerVariable[], Dispatch<SetStateAction<IModelRunnerVariable[]>>];
  valueGroupObjectState?: [IValueGroupObj, Dispatch<SetStateAction<IValueGroupObj>>];
  valueFromDatasheet?: {
    [key: string]: string;
  };
  selectedProject: IModelRunnerProject;
  onSetNodeSelectorTargetInfo(key: string, path: string[]): void;
};

function ModelRunnerVariableTable({
  variableTableState,
  valueGroupObjectState,
  valueFromDatasheet,
  selectedProject,
  onSetNodeSelectorTargetInfo
}: IProps): ReactElement {
  const [variableTable, setVariableTable] = variableTableState;
  const [valueGroupObject, setValueGroupObject] = valueGroupObjectState;
  const [importRows, setImportRows] = useState<IModelRunnerVariable[]>([]);
  const [exportRows, setExportRows] = useState<IModelRunnerVariable[]>([]);

  const importList = selectedProject?.ioInfo.find((item) => item.Type === 'Import')?.Data;
  const exportList = selectedProject?.ioInfo.find((item) => item.Type === 'Export')?.Data;

  const boundaryRef = useRef(null);
  const dragHandleRef = useRef(null);

  const defaultPosition = {x: 0, y: 226};
  const defaultPadding = {top: 44, right: 0, bottom: 1, left: 0};
  const [editorBodyPosition, onMouseDownResizeHandle, setEditorBodySize] = useDraggable(
    boundaryRef,
    dragHandleRef,
    defaultPosition,
    defaultPadding
  );

  const openState = useState<boolean>(true);

  const [isOpen, setIsOpen] = openState;
  const prevPositionY = useRef(editorBodyPosition.y);

  useEffect(() => {
    if (!isOpen) {
      prevPositionY.current = editorBodyPosition.y;
      setEditorBodySize({x: 0, y: 44});
    } else {
      setEditorBodySize({x: 0, y: prevPositionY.current});
    }
  }, [isOpen]);

  useEffect(() => {
    if (editorBodyPosition.y > 44) {
      setIsOpen(true);
      prevPositionY.current = editorBodyPosition.y;
    } else {
      setIsOpen(false);
    }
  }, [editorBodyPosition.y]);

  useEffect(() => {
    if (!variableTable) return;

    const newRows = variableTable?.map(function (item) {
      const searchResult = valueGroupObject[item.keys] as ILatestNode;
      const searchResultFormDatasheet = valueFromDatasheet?.[item?.path?.[1]];
      if (item.flowType === 'Export') {
        return {...item, variableValue: ''};
      }
      if (searchResult) {
        return {...item, variableValue: searchResult?.value?.[0]?.[1]};
      } else if (searchResultFormDatasheet) {
        return {...item, variableValue: searchResultFormDatasheet};
      } else {
        return item;
      }
    });

    setImportRows(newRows.filter((row) => row.flowType === 'Import'));
    setExportRows(newRows.filter((row) => row.flowType === 'Export'));
  }, [variableTable, valueGroupObject, valueFromDatasheet, exportList, importList]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, id, name: keyValue} = e.target;
    switch (keyValue) {
      case 'variableName': {
        const targetRow = variableTable.find((item) => item.keys === id);
        if (targetRow) {
          targetRow[keyValue] = value;
        }
        break;
      }
      case 'flowType': {
        // setVariableTable((prev) => prev.map((item) => (item.keys === id ? {...item, [keyValue]: value} : item)));
        break;
      }
      case 'variableValue': {
        break;
      }
      case 'path': {
        break;
      }
    }
  };

  /*  const addRow = (flowType: 'Import' | 'Export') => {
    setVariableTable((prev) =>
      prev.concat({
        variableName: '',
        flowType: flowType,
        variableValue: '',
        path: [],
        keys: getUniqueKey()
      })
    );
  };*/

  /*  const deleteRow = (key: string) => {
    setVariableTable((prev) => prev.filter((item) => item.keys !== key));
  };*/

  const onClick = (rowKey: string, columnKey?: string, key?: string, path?: string[]) => {
    // addRow(rowKey === 'Import' ? 'Import' : 'Export');
    if (rowKey === 'path') {
      if (columnKey === 'change_path') {
        onSetNodeSelectorTargetInfo(key, path);
      } else if (columnKey === 'delete_row') {
        // deleteRow(key);
      }
    }
  };

  const tableBodyHeight = boundaryRef.current?.offsetHeight || 0;
  const exportTableHeight = tableBodyHeight - editorBodyPosition.y;

  return (
    <Container ref={boundaryRef}>
      <ModelRunnerImportTable
        height={editorBodyPosition.y}
        title="Import"
        fields={fields}
        rows={importRows}
        onChange={onChange}
        onClick={onClick}
        openState={openState}
      />
      <ResizeArea>
        <ResizeHandle ref={dragHandleRef} onMouseDown={onMouseDownResizeHandle} />
        <ModelRunnerExportTable
          height={exportTableHeight}
          title="Export"
          fields={fields}
          rows={exportRows}
          onChange={onChange}
          onClick={onClick}
        />
      </ResizeArea>
    </Container>
  );
}

export default ModelRunnerVariableTable;
