import {CSSProp} from 'styled-components';

type ITheme = {
  color: {
    [key: string]: CSSProp;
  };
  app: {
    menu: IMenuTheme;
  };
  action: {
    menu: IMenuTheme;
  };
  table: {
    [key: string]: {
      [key: string]: CSSProp;
    };
  };
  infoPanel: {
    [key: string]: CSSProp;
  };
};
type IMenuTheme = {
  width: string | number;
  transition?: CSSProp;
};

export const defaultTheme: ITheme = {
  color: {
    company: '#EE5C84',
    primary: '#32499A',
    secondary: '#6e6e6e',
    light: '#ececec',
    optional: '#bebebe',
    black: '#171523',
    dark: '#3f3f3f',
    gray: '#B6B4C0',
    info: '#00a6cb',
    danger: '#C73535',
    warning: 'orangered',
    record: '#ee5c5c',
    confirm: '#32499A',
    cancel: '#999999',
    new: '#7f6adf',
    send: '#574687',
    iceBlue: '#e9eff8',
    invalid: '#ff2d2d',
    public: '#0aa8ec',
    owner: '#2ac07b',
    reset: '#F0F0F0',
    focus: '#3D51FE',
    highlight: '#dbe8f9'
  },
  app: {
    menu: {
      width: 60
    }
  },
  action: {
    menu: {
      width: 360,
      transition: 'all 0.2s'
    }
  },
  table: {
    border: {
      color: '#92a0b9'
    },
    background: {
      color: '#92a0b9'
    }
  },
  infoPanel: {
    width: 400
  }
};
