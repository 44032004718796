import React, {ReactNode} from 'react';
import styled from 'styled-components';

type IProps = {
  children: ReactNode;
  ref?: React.MutableRefObject<null>;
  height?: number;
};

const Container = styled.div`
  width: 100%;

  display: flex;
`;
function PythonEditorBodyBottom({children, ref, height}: IProps) {
  return (
    <Container style={{height}} ref={ref}>
      {children}
    </Container>
  );
}

export default PythonEditorBodyBottom;
