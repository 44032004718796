import styled, {css} from 'styled-components';
import {IHierarchyCellInfo} from 'components/spreadsheet/spreadsheet-adapter';
import {useState} from 'react';

type StyledProps = {
  $row: number;
  $column: number;
  // $isHideComponent: boolean;
  $parentExists: boolean;
  $isLowestChildren: boolean;
  $parentDepth: number;
};

const CellWrapper = styled.div<StyledProps>`
  width: calc(100% - 8px);
  height: 100%;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;

  ${(props) =>
    props.$row % 2 === 0 &&
    props.$isLowestChildren &&
    css`
      background: #f4f9fd;
    `}

  ${(props) =>
    !props.$isLowestChildren &&
    css`
      font-weight: 400;
      background: hsl(0, 0%, ${95 - props.$parentDepth * 8}%);
    `}
  
  ${(props) =>
    props.$isLowestChildren &&
    props.$column === 1 &&
    css`
      color: #544fff;
    `};

  ${(props) =>
    props.$isLowestChildren &&
    props.$parentExists &&
    props.$column === 0 &&
    css`
      padding-left: 16px;
      width: calc(100% - 16px);
    `};
`;

type IProps = {
  TD?: HTMLTableCellElement;
  col?: number;
  row?: number;
  value?: string | null;
  cellInfo?: IHierarchyCellInfo[];
};

function HierarchyDataRenderer(props: IProps) {
  // const [isHideComponentRows, setIsHideComponentRows] = useState<boolean>(props?.cellInfo[props?.row].isHideComponent);
  const onClickHideComponentRowsBtn = () => {
    // setIsHideComponentRows((prev) => !prev);
  };

  return (
    <CellWrapper
      $row={props?.row}
      $column={props?.col}
      // $isHideComponent={isHideComponentRows}
      $parentExists={props?.cellInfo[props?.row]?.isParentExist}
      $isLowestChildren={props?.cellInfo[props?.row]?.isLowestChildren}
      $parentDepth={props?.cellInfo[props?.row]?.parentDepth}
      onClick={(e) => {
        e.stopPropagation();
        onClickHideComponentRowsBtn();
      }}
      style={{display: 'flex', justifyContent: 'space-between'}}
    >
      {props?.value || ''}
      <div></div>
    </CellWrapper>
  );
}

export default HierarchyDataRenderer;
