import {changeTime, getCelsius, getFahrenheit} from 'components/pc/widgets/weather/const';
import WeatherIcon from 'components/pc/widgets/weather/WeatherIcon';
import React from 'react';
import styled from 'styled-components';
import {ISizeType} from 'components/common/types';

const Container = styled.div`
  display: flex;
  font-size: 21px;
  padding: 0.8rem 1.2rem;
  border-radius: 1.2rem;
  background-color: #dde5ff;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &.md {
    border-radius: 0.8rem;
    font-size: 17px;
    padding: 0.6rem 1rem;
    gap: 7px;
  }
  &.sm {
    border-radius: 0.6rem;
    font-size: 14px;
    padding: 0.4rem 0.8rem;
    gap: 4px;
  }
`;

const WeatherIconWrapper = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }

  &.md {
    svg {
      width: 35px;
      height: 35px;
    }
  }
  &.sm {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const TemperatureWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Temperature = styled.div`
  font-size: 21px;

  &.md {
    font-size: 18px;
  }
  &.sm {
    font-size: 14px;
  }
`;

type IProps = {
  idx: number;
  forecastData: IWeatherForcastData;
  fontSize: string;
  isCelsius: boolean;
  size: ISizeType;
};

function WeatherForeCastHourlyResult({idx, forecastData, fontSize, isCelsius, size}: IProps) {
  const f = getFahrenheit(forecastData.hourly?.[idx].temp);
  const c = getCelsius(forecastData.hourly?.[idx].temp);
  return (
    <Container className={size.width}>
      {changeTime(forecastData.hourly?.[idx].dt)}
      <WeatherIconWrapper className={size.width}>
        {WeatherIcon(forecastData.hourly?.[idx].weather[0].icon)}
      </WeatherIconWrapper>
      <TemperatureWrapper className={fontSize}>
        <Temperature className={size.width}>{isCelsius ? <>{c}°</> : <>{f}°</>}</Temperature>
      </TemperatureWrapper>
    </Container>
  );
}

export default WeatherForeCastHourlyResult;
