import {ReactElement} from 'react';
import HmbHiddenComponent from 'components/pc/widgets/hmb/HmbHiddenComponent';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid #dddddd;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type IProps = {
  hiddenComponentList: string[];
  removeHiddenComponent(hiddenComponent: string): void;
};

function HmbHiddenComponentWrapper({hiddenComponentList, removeHiddenComponent}: IProps): ReactElement {
  return (
    <Container className="hmb-hidden-component-wrapper">
      {hiddenComponentList.map((item) => (
        <HmbHiddenComponent key={item} hiddenComponent={item} removeHiddenComponent={removeHiddenComponent} />
      ))}
    </Container>
  );
}

export default HmbHiddenComponentWrapper;
