import {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';

const Container = styled.div`
  width: 100%;
  display: flex;

  input[type='text'] {
    width: 100%;
    border: none;
    border-bottom: 2px solid #769da9;
    background-color: #f8f8ff;
    outline: none;
    text-align: right;
    padding: 2px 5px;
    font-size: 15px;
  }
`;

type IProps = {
  field: ITableField;
  keys: string;
  onChange(e: ChangeEvent): void;
};

function InputTextValue({keys, field, onChange, ...rest}: IProps): ReactElement {
  const value = rest[field?.key];
  const [str, setStr] = useState<string>(value);

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    setStr(value);
    onChange(e);
    return;
  };

  useEffect(() => {
    setStr(value);
  }, [value]);

  return (
    <Container>
      <input
        type="text"
        placeholder={'name'}
        title={field?.key}
        name={keys as string}
        value={str}
        onChange={onChangeValue}
        style={{textAlign: 'left'}}
      />
    </Container>
  );
}

export default InputTextValue;
