import {ReactElement, useState, useRef, useEffect} from 'react';
import {Canvas} from '@react-three/fiber';
import {OrthographicCamera, OrbitControls, Environment, Grid, ContactShadows} from '@react-three/drei';

import {NodeProps, useReactFlow} from 'reactflow';
import {ModelShells} from './components/modelComponents/Model_Shells';
import {ModelParts} from './components/modelComponents/Model_Parts';
import {AirCooler} from './components/modelComponents/AirCooler';
import {COBoiler} from './components/modelComponents/COBoiler';
import {Compressor} from './components/modelComponents/Compressor';
import {HeatExchanger} from './components/modelComponents/HeatExchanger';
import {Pump} from './components/modelComponents/Pump';
import {VesselWithoutBoot} from './components/modelComponents/VesselWithoutBoot';
import {VesselVertical} from './components/modelComponents/VesselVertical';
import {VesselWithBoot} from './components/modelComponents/VesselWithBoot';
import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCube} from '@fortawesome/pro-light-svg-icons';
import {IThreeWidgetData, IWidgetNodeData} from 'components/pc/types';

type Option = {
  label: string;
  value: string;
};

type SelectProps = {
  options: Option[];
  value: string;
  placeholder: string;
  onChange: (option: Option) => void;
  disabled?: boolean;
};

const Container = styled.div`
  width: 200px;
  height: 100%;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  background-color: #fff;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  left: -200px;
  transition: left 0.2s;
  display: flex;
  flex-direction: column;

  &.open {
    left: 0;
  }

  h3 {
    flex-shrink: 0;
    height: 40px;
    background-color: #f3f3f3;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 400;
    box-sizing: border-box;
  }
`;
const Scroller = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
`;
const Tab = styled.div`
  content: '';
  background-color: #f3f3f3;
  border: 1px solid #a4a3a3;
  border-left: none;
  display: block;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  z-index: 2;
  right: -40px;
  top: -1px; //calc(50% - 30px);
  color: #a6b3b7;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
`;
const MenuItem = styled.div`
  line-height: 1.6em;
  padding: 3px 10px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: #d2e4fc;
  }

  &.active {
    background-color: #d6e4f6;
  }
`;

function SelectMenu({options, value, placeholder, onChange}: SelectProps): ReactElement {
  const [open, setOpen] = useState(false);

  const onSelect = (option: Option): void => {
    onChange(option);
    setOpen(false);
  };

  return (
    <Container className={open && 'open'}>
      <h3>Model List</h3>
      <Scroller className="thin-scrollbar">
        {options.map((option) => (
          <MenuItem key={option.label} className={value === option.label && 'active'} onClick={() => onSelect(option)}>
            &middot; {option.label}
          </MenuItem>
        ))}
      </Scroller>
      <Tab onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faCube} />
      </Tab>
    </Container>
  );
}

const CanvasCantainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

type IProps = NodeProps<IWidgetNodeData> & {
  zoom: number;
};
function ThreeWidget({data, id, ...rest}: IProps): ReactElement {
  const [config, setConfig] = useState({selectedModel: 'Reactor'});
  const options = [
    {value: 'Reactor', label: 'Reactor'},
    {value: 'Main Column', label: 'Main Column'},
    {value: 'Primary Absorber', label: 'Primary Absorber'},
    {value: 'Sponge Absorber', label: 'Sponge Absorber'},
    {value: 'HN Stripper/LCO Stripper', label: 'HN Stripper/LCO Stripper'},
    {value: 'Stripper', label: 'Stripper'},
    {value: 'Debutanizer', label: 'Debutanizer'},
    {value: 'Air Cooler', label: 'Air Cooler'},
    {value: 'CO Boiler', label: 'CO Boiler'},
    {value: 'Compressor', label: 'Compressor'},
    {value: 'Heat Exchanger', label: 'Heat Exchanger'},
    {value: 'Pump', label: 'Pump'},
    {value: 'Vessel Without Boot', label: 'Vessel Without Boot'},
    {value: 'Vessel Vertical', label: 'Vessel Vertical'}
  ];

  const gridConfig = {
    cellSize: 1,
    cellThickness: 1,
    cellColor: '#3b3b3b',
    sectionSize: 10,
    sectionThickness: 1,
    sectionColor: '#e62424',
    fadeDistance: 100,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true
  };

  // const {data, id} = props;
  // const canvasRatio = zoom > 1 ? '100%' : '100%';

  const {setNodes} = useReactFlow();
  useEffect(() => {
    const metaData = {config};
    setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
    );
  }, [config]);

  useEffect(() => {
    if (data?.metaData) {
      const {config} = data?.metaData as IThreeWidgetData;
      setConfig(config);
    }
  }, []);

  function ModelComponent({visibility}) {
    return (
      <>
        <ModelShells transparent={false} wireframes={false} visibility={visibility} />
        <ModelParts visibility={visibility} />
      </>
    );
  }

  const MODEL_COMPONENTS = {
    Reactor: <ModelComponent visibility="01" />,
    'Main Column': <ModelComponent visibility="02" />,
    'Primary Absorber': <ModelComponent visibility="03" />,
    'Sponge Absorber': <ModelComponent visibility="04" />,
    'HN Stripper/LCO Stripper': <ModelComponent visibility="05" />,
    Stripper: <ModelComponent visibility="06" />,
    Debutanizer: <ModelComponent visibility="07" />,
    'Air Cooler': <AirCooler />,
    'CO Boiler': <COBoiler />,
    Compressor: <Compressor />,
    'Heat Exchanger': <HeatExchanger />,
    Pump: <Pump />,
    'Vessel Without Boot': <VesselWithoutBoot />,
    'Vessel Vertical': <VesselVertical />,
    'Vessel With Boot': <VesselWithBoot />
  };

  const onClose = (): void => {
    // todo:
  };

  return (
    <WidgetContainer {...rest} type="ThreeWidget">
      <WidgetHeader icon={data.icon} id={id} title={data.title} onClose={onClose} />
      <WidgetBody type="nowheel">
        <CanvasCantainer>
          <Canvas
            style={{height: '100%', width: '100%', overflow: 'hidden'}}
            shadows
            eventSource={document.getElementById('root')}
            eventPrefix="client"
          >
            <OrthographicCamera position={[0, 0, 100]} near={0.1} far={100} zoom={200} />
            <color attach="background" args={['#f0f0f0']} />
            <OrbitControls
              target={[0, 5, 0]}
              minPolarAngle={0}
              autoRotate={true}
              maxPolarAngle={Math.PI / 1.9}
              makeDefault
              zoomToCursor={true}
            />
            <Environment files="./images/environment.hdr" background blur={0.5} />
            <ContactShadows
              position={[0, -0.4, 0]}
              opacity={1}
              scale={50}
              blur={0.2}
              far={3}
              resolution={256}
              color="#000000"
            />
            {MODEL_COMPONENTS[config.selectedModel] || <></>}
            <Grid position={[0, -0.3, 0]} args={[10.5, 10.5]} {...gridConfig} />
          </Canvas>
        </CanvasCantainer>
        <SelectMenu
          onChange={(o) => setConfig({...config, selectedModel: o.value})}
          placeholder="Select Model"
          value={config.selectedModel}
          options={options}
        />
      </WidgetBody>
    </WidgetContainer>
  );
}
export default ThreeWidget;
