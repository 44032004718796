import {ChangeEvent, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faCirclePlus, faPenLine, faSquareDashedCirclePlus} from '@fortawesome/pro-light-svg-icons';
import {faSquareDashedCirclePlus as portIcon} from '@fortawesome/pro-regular-svg-icons';
import {AnnotationTypes} from 'components/mpfd/type';
import AnnotationList from 'components/mpfd/panel/AnnotationList';
import AnnotationButtons from 'components/mpfd/panel/parts/annotation/AnnotationButtons';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import Tab from 'components/common/Tab';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  height: 50px;
  display: flex;
  box-sizing: border-box;
  gap: 5px;
  padding: 10px;
  align-items: center;
`;

type AnnotationLabel = 'Point' | 'Region' | 'Line' | 'Port';
export type IAnnotationTab = {
  type: AnnotationTypes;
  label: AnnotationLabel;
  icon: IconDefinition;
  blankIcon?: IconDefinition;
  relatedTools: ToolIds[];
};

export const annotationTab: IAnnotationTab[] = [
  {
    type: 'expanding-line',
    label: 'Line',
    icon: faPenLine,
    blankIcon: faPenLine,
    relatedTools: ['create-expanding-line', 'line-detection', 'line-eraser', 'line-splitter', 'line-splitter-2']
  },
  {type: 'point', label: 'Point', icon: faCirclePlus, blankIcon: faCirclePlus, relatedTools: ['create-point']},
  {
    type: 'box',
    label: 'Region',
    icon: faSquareDashedCirclePlus,
    blankIcon: faSquareDashedCirclePlus,
    relatedTools: ['create-box']
  },
  {type: 'port', label: 'Port', icon: portIcon, blankIcon: portIcon, relatedTools: ['create-port']}
];

function AnnotationListView() {
  const {state} = useContext(MetaPfdContext);
  const [tab, setTab] = useState<AnnotationTypes>('expanding-line');
  const selectedTargetRegionState = useState<string[]>([]);
  const [selectedTargetRegions, setSelectedTargetRegions] = selectedTargetRegionState;
  const filteredRegions = state.regions.filter((item) => item.type === (tab || 'expanding-line'));

  useEffect(() => {
    const tabRelatedSelectedTool = annotationTab.find((item) =>
      item.relatedTools.some((item) => item === state.selectedTool)
    );
    if (tabRelatedSelectedTool) {
      setTab(tabRelatedSelectedTool.type);
    }
  }, [state.selectedTool]);

  const onClickTab = (annotTab: AnnotationTypes) => {
    setTab(annotTab);
  };

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    const {checked, name} = e.target;
    setSelectedTargetRegions((prev) => (checked ? prev.concat(name) : prev.filter((item) => item !== name)));
  };

  return (
    <Container>
      <Tab<IAnnotationTab> options={annotationTab} valueKey="type" labelKey="label" value={tab} onSelect={onClickTab} />
      <ButtonWrapper>
        <AnnotationButtons
          type={tab}
          entireRegions={filteredRegions}
          selectedTargetRegionState={selectedTargetRegionState}
        />
      </ButtonWrapper>
      <AnnotationList
        entireRegions={filteredRegions}
        selectedTargetRegions={selectedTargetRegions}
        type={tab}
        onChangeCheckbox={onChangeCheckbox}
      />
    </Container>
  );
}

export default AnnotationListView;
