import {ReactElement, useContext, useState} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common/index';
import {Button} from 'components/forms';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import useServerEvent from 'hooks/useServerEvent';

function DefaultModal(): ReactElement {
  const {modal, hideModal} = useContext(CommonContext);
  const [isBusy, setIsBusy] = useState(undefined);

  useModalKeyBoardEvent(
    {
      onConfirm: () => onConfirmModal(),
      onCancel: () => onCancelModal()
    },
    Boolean(modal)
  );

  const onCloseModal = () => {
    hideModal();
    modal?.onClose?.();
  };

  const onConfirmModal = () => {
    hideModal();
    modal?.onConfirm?.();
    if (modal.hasConfirmToBusy) setIsBusy(true);
  };

  const onCancelModal = () => {
    hideModal();
    modal?.onCancel?.();
  };

  // 서버에서 400~600 을 보내면 여기서 처리
  useServerEvent();

  if (modal) {
    return (
      <ModalWrapper>
        <ModalHeader onClose={onCancelModal}>{modal?.title}</ModalHeader>
        <ModalBody>
          {modal.content} <br />
          {modal.message && modal?.message}
        </ModalBody>
        <ModalFooter>
          {modal?.isShowConfirm && (
            <Button width={100} variant={modal?.confirmVariant} disabled={isBusy} onClick={onConfirmModal}>
              {modal.confirmLabel || 'Ok'}
            </Button>
          )}
          {modal?.isShowClose && (
            <Button variant="dark" disabled={isBusy} onClick={onCloseModal}>
              {modal.closeLabel || 'Close'}
            </Button>
          )}
          {modal?.isShowCancel && (
            <Button variant="cancel" disabled={isBusy} onClick={onCancelModal}>
              {modal.cancelLabel || 'Cancel'}
            </Button>
          )}
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default DefaultModal;
