import {memo, ReactElement} from 'react';
import {NodeProps} from 'reactflow';
import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import styled from 'styled-components';

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

function LineDetectionWidget({data, id, ...rest}: NodeProps): ReactElement {
  return (
    <WidgetContainer {...rest} type="WebWidget">
      <WidgetHeader {...rest} type="WebWidget" icon={data.icon} title={data.title} id={id} />
      <WidgetBody>
        <IFrame src="http://localhost:8100" title=""></IFrame>
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(LineDetectionWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
