import {useEffect, useRef, useState} from 'react';
import {IPeriodOption} from 'components/spreadsheet/spreadsheet-adapter';
import {IApiReturnBasic, INodeInfo} from 'api/data-types';
import useApi from 'api/useApi';

export type ILatestNode = {
  hierarchy?: string[];
  name?: string;
  database?: string;
  value?: string[][] | number[][];
  updateTime?: number;
  key: string;
};
export type ILatestNodeResult = {
  [key: string]: ILatestNode[];
};

type ISubscribeInfo = {
  targetList: INodeInfo[];
  activate: boolean;
};

type ILatestNodeSubscribe = {
  [key: string]: ISubscribeInfo;
};

type IParams = {
  type: 'latest_count' | 'time_range';
  latest_count?: number;
  time_range?: [startTimestamp: number, endTimestamp: number];
  duration?: number;
};

export type ILatestNodeHandlerReturn = {
  subscribe: ILatestNodeSubscribe;
  latestResult: ILatestNodeResult;
  disconnectSubscribe(id: string): void;
  renewSubscribe(id: string, nodeInfos: INodeInfo[], activate: boolean): number;
  changeDuration(newDuration: number): void;
  onLoadLatestNodeResult(tagResult: ILatestNodeResult): void;
};

function useLatestNodeHandler(option: IParams): ILatestNodeHandlerReturn {
  const [subscribe, setSubscribe] = useState<ILatestNodeSubscribe>({});
  const [result, setResult] = useState<ILatestNodeResult>({});
  const [duration, setDuration] = useState(option?.duration || 15 * 1000);
  const resultRef = useRef(null);
  const subscribeRef = useRef(null);
  subscribeRef.current = subscribe;
  resultRef.current = result;
  const api = useApi();

  useEffect(() => {
    const fnc = async () => {
      const currentSubscribe = {...subscribeRef.current} as ISubscribeInfo;
      const idArr = Object.keys(currentSubscribe);
      const now = Date.now();
      let node_infos = [];

      for (let i = 0; i < idArr.length; i++) {
        const uniqueId = idArr[i];
        const subScribeInfo = currentSubscribe[uniqueId] as ISubscribeInfo;
        if (subScribeInfo?.activate) {
          const t = subScribeInfo?.targetList.map((item) => ({
            database: item.database,
            path: [...item.hierarchy, item.name],
            timestamp: {
              "specific_point": Math.floor(now / 1000)
            },
            option:{
              direction: "before"
            },
            limit:{
              count:option?.latest_count
            }
          }));
          node_infos = node_infos.concat(t);
        }
      }
      if (node_infos?.length === 0) {
        return;
      }
      const res = await api.post<IApiReturnBasic>('/node_manage/get_node_data_list', {node_infos});

      let nodeDataList:any[] = []
      let data = res?.data as any[]

      for(let i=0; i<data?.length; i++){
        if(data[i]?.records){
          nodeDataList.push(data[i])
        }else if(data[i]?.children){
          let refinedChildren = data[i]?.children.map(item => ({...item, database:data[i]?.database}))
          nodeDataList = [...nodeDataList, ...refinedChildren]
        }

      }

      const flat = nodeDataList.map((item) => {
        const db = item.database;
        const stringArray = item.node;
        return {
          ...item,
          key: [db, ...stringArray].join(', '),
          value:item.records,
          updateTime:now
        }
      });

      let newResult = {...resultRef?.current};
      const idArr_ = Object.keys(newResult);
      for (let i = 0; i < idArr_.length; i++) {
        const uniqueId = idArr[i];
        const subScribeInfo = currentSubscribe[uniqueId] as ISubscribeInfo;
        const isActivate = subScribeInfo?.activate;

        const prevResult = newResult[uniqueId] || ([] as ILatestNode[]);
        const nextResult = prevResult.map(function (prevRes) {
          const nextRes = flat.find((n) => n.key === prevRes.key);
          if (nextRes && isActivate) {
            return nextRes;
          } else {
            return prevRes;
          }
        });
        newResult = {...newResult, [uniqueId]: nextResult};
      }
      setResult(newResult);
    };
    fnc();
    let id = setInterval(fnc, duration);
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [duration]);

  const addNodes = async (id: string, nodeInfos: INodeInfo[]) => {
    const now = Date.now();
    const node_infos = nodeInfos
      .filter((nodeInfo) => nodeInfo.database !== 'custom_nodes')
      .map((item) => ({
        database: item.database,
        path: [...item.hierarchy, item.name],
        timestamp: {
          "specific_point": Math.floor(now / 1000)
        },
        options:{
          direction: "before"
        },
        limit:{
          count:option?.latest_count
        }
      }));


    // console.log(node_infos);
    if (node_infos?.length === 0) {
      return;
    }
    const res = await api.post<IApiReturnBasic>('/node_manage/get_node_data_list', {node_infos});

    let nodeDataList:any[] = []
    let data = res?.data as any[]
    for(let i=0; i<data?.length; i++){
      if(data[i]?.records){
        nodeDataList.push(data[i])
      }else if(data[i]?.children){
        let refinedChildren = data[i]?.children.map(item => ({...item, database:data[i]?.database}))
        nodeDataList = [...nodeDataList, ...refinedChildren]
      }

    }

    const newInfo = nodeDataList.map((item) => {
      const db = item.database;
      const stringArray = item.node;
      return {
        ...item,
        key: [db, ...stringArray].join(', '),
        value:item.records,
        updateTime:now
      }
    });

    // customNodes 구조 설립
    const customNodesRefined = nodeInfos
      .filter((nodeInfo) => nodeInfo.database === 'custom_nodes')
      .map((item) => {
        const customDb = item.database;
        const stringArray = item.name.split(', ');
        return {
          key: [customDb, ...stringArray].join(', '),
          value: null,
          updateTime: now
        } ;
      })

    // const isCustomNodes = nodeInfos.filter((nodeInfo) => nodeInfo.database !== 'custom_nodes') ? true : false;


    const customNodeInfo = customNodesRefined.flat(1) as ILatestNode[]; //customNodes 의 newInfo
    const combinedInfo:any[] = [...newInfo, ...customNodeInfo]; // 위의 두개를 결합

    const prevResult = {...resultRef.current};
    if (prevResult[id]) {
      const targetIdResult = prevResult[id] as ILatestNode[];
      const delDuplicate = targetIdResult.filter(
        (prevRes) => !combinedInfo.some((newRes) => newRes.key === prevRes.key)
      );
      const renewInfo = [...(delDuplicate || []), ...(combinedInfo || [])];
      setResult((prev) => ({
        ...prev,
        [id]: renewInfo
      }));
    } else {
      setResult((prev) => ({
        ...prev,
        [id]: combinedInfo
      }));
    }
  };

  /**
   *
   * @param id
   * @param nodeInfos
   * @param activate
   */
  const renewSubscribe = (id: string, nodeInfos: INodeInfo[], activate: boolean): number => {
    const prevResult = {...resultRef?.current} as ILatestNodeResult;
    const info = prevResult[id] as ILatestNode[];
    const tagsExcludeBlank = nodeInfos.filter((item) => item?.name);
    const refined = tagsExcludeBlank;
    // 처음 구독
    setSubscribe((prev) => ({
      ...prev,
      [id]: {
        targetList: refined,
        activate
      }
    }));
    let isNeedFetch: boolean;

    if (!subscribe[id] || subscribe[id]?.targetList?.length === 0) {
      isNeedFetch = true;
    } else {
      /**
       * 갱신된 subscription 정보가 prevSubscription 에 포함관계라면,
       * 그리고 autoUpdate가 false라면 api fetch 가 필요없다
       * api => autoUpdate || 최초 load 시에만 호출
       *
       * 이미 존재하는 노드리스트 에 포함되어있지 않은것이
       * 새롭게 갱신된 리스트에 잇다
       */
      isNeedFetch = refined.some((item) =>
        [...(subscribe[id].targetList || [])].some(
          (subscriptionInfo) =>
            [subscriptionInfo.database, ...subscriptionInfo.hierarchy, subscriptionInfo.name].join(', ') !==
            [item.database, ...item.hierarchy, item.name].join(', ')
        )
      );
    }
    if (!activate && !isNeedFetch) {
      return;
    }
    if (!info) {
      addNodes(id, refined);
      return refined.length;
    }
    // 구독이 되어있지만 새로 정보를 가져올 필요가 있음
    else if (info) {
      const newSubscribeItem = refined.filter(
        (newTags) =>
          !info.some((item) => item.key === [newTags.database, ...newTags.hierarchy, newTags.name].join(', '))
      );
      if (newSubscribeItem?.length > 0) {
        addNodes(id, newSubscribeItem);
        return newSubscribeItem.length;
      }
    }
    return 0;
  };

  const afterUnMountListener = (id: string) => {
    setSubscribe(function (prev) {
      const copy = {...prev};
      delete copy[id];
      return copy;
    });
  };

  const changeDuration = (newDuration: number) => {
    setDuration(newDuration);
  };

  const onLoadLatestNodeResult = (tagResult: ILatestNodeResult) => {
    setResult(tagResult);
  };

  return {
    subscribe,
    latestResult: result,
    disconnectSubscribe: afterUnMountListener,
    renewSubscribe,
    changeDuration,
    onLoadLatestNodeResult
  };
}

export default useLatestNodeHandler;
