import styled from 'styled-components';
import {IDataBindingTagDataInfo, IDataBindingType, IShapeStyle} from 'components/pc/common/shapes/type';
import {getRgbaFromColorResult} from 'components/pc/common/shapes/parts/svg/utils';
import {ColorResult} from 'react-color';
import {ToolName} from 'components/pc/common/shapes/Toolbar';
import {getImage} from 'assets/images/svg-image';
import {useContext, useEffect, useState} from 'react';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';
import {toDateFormat} from 'utils/commons';
import classnames from 'classnames';

type ContainerProps = {
  $shapeStyle: IShapeStyle;
  $dataBindingType: IDataBindingType;
};

const Container = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;
  padding: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  overflow: hidden;
  top: 0;
  color: #575660;
  box-sizing: border-box;
  font-size: ${(props) => props.$shapeStyle.fontSize}px;
  img {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.2em;
  }
  .unit {
    font-size: 0.4em;
  }

  .live {
    animation: blinkAnimation 0.8s ease-in-out 3;
    animation-fill-mode: forwards;
  }

  @keyframes blinkAnimation {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

const LiveDisplay = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
  width: 4px;
  height: 4px;
  border-radius: 1px;
  background-color: #00a6cb;
  opacity: 0;

  &.live {
    opacity: 1;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TagName = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: 0.4em;
  white-space: nowrap;

  .info-text {
    width: calc(100% - 1em);
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const UpdateTime = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: 0.4em;
  white-space: nowrap;

  .info-text {
    display: inline;
    overflow: hidden;
    width: calc(100% - 1em);
    text-overflow: ellipsis;
  }
`;

type cssType = {
  $fontColor: ColorResult;
};

const DataDisplay = styled.div<cssType>`
  flex: 1;
  margin: auto;
  display: flex;
  align-items: center;
  color: ${(props) => getRgbaFromColorResult(props.$fontColor)};
  > div {
    display: inline;
  }
  //transition: opacity 0s;
  .blink {
    .data,
    .unit {
      opacity: 0.1;
      transition: opacity 0.1s;
    }
  }
  .data,
  .unit {
    transition: opacity 1.5s;
  }
`;

type IProps = {
  selected: boolean;
  shapeStyle: IShapeStyle;
  dataBindingType: IDataBindingType;
  tagDataInfo: IDataBindingTagDataInfo;
  blink: boolean;
  onChangeStyle?(key: ToolName, value: string | number | number[] | ColorResult): void;
};

function ShapeDataBindingText({selected, blink, shapeStyle, dataBindingType, tagDataInfo}: IProps) {
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettingsS] = globalSettingsState;
  const {options} = dataBindingType;
  const {isDisplayTagInformation, isDisplayUnit} = options;
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    if (blink) {
      setIsLive(false);
      setTimeout(() => {
        setIsLive(true);
        const timer = setTimeout(() => {
          setIsLive(false);
        }, 2000);
        return () => clearTimeout(timer);
      }, 0);
    }
  }, [blink]);

  if (!tagDataInfo) return;
  const {tagName, tagUnit, tagValue, updateTime} = tagDataInfo;

  return (
    <Container $shapeStyle={shapeStyle} $dataBindingType={dataBindingType}>
      {tagName && isDisplayTagInformation && (
        <TagName>
          <ImgWrapper>
            <img className="info-img" src={getImage('dataBindingTextBoxIconWifi')} alt="" />
          </ImgWrapper>
          <div className="info-text">{tagName}</div>
        </TagName>
      )}

      {updateTime && isDisplayTagInformation && (
        <UpdateTime>
          <ImgWrapper>
            <img className="info-img" src={getImage('dataBindingTextBoxIconClock')} alt="" />
          </ImgWrapper>
          <div className="info-text">{toDateFormat(updateTime * 1000, 'YYYY-MM-DD HH:mm:ss')}</div>
        </UpdateTime>
      )}

      {isLive && <LiveDisplay className={classnames(isLive && 'live')} />}

      <DataDisplay $fontColor={tagDataInfo?.fontColor || shapeStyle?.fontColor}>
        <div className={classnames(blink && 'blink')}>
          {tagValue && (
            <span className="data">{toSignificantDigit(tagValue as number, globalSettingsS?.significantDigit)}</span>
          )}
          {tagUnit && isDisplayUnit && <span className="unit">{tagUnit}</span>}
        </div>
      </DataDisplay>
    </Container>
  );
}

export default ShapeDataBindingText;
