import {ChangeEvent, ReactElement, useState} from 'react';
import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import classnames from 'classnames';

const Container = styled.div`
  width: 100%;
  display: flex;
  input[type='text'] {
    width: 100%;
    border: none;
    border-bottom: 2px solid #769da9;
    background-color: #f8f8ff;
    outline: none;
    text-align: right;
    padding: 2px 5px;
    font-size: 15px;
    &.invalid {
      border-color: red;
      color: red;
    }
  }
`;

type IProps = {
  keys: string[] | string;
  title: string;
  value: number | undefined;
  field?: ITableField;
  onChange(e: ChangeEvent): void;
  beforeOnChange?(e: ChangeEvent): boolean;
};

function InputValue({keys, title, value, field, onChange, beforeOnChange}: IProps): ReactElement {
  const [num, setNum] = useState<string>(value?.toString() || '');
  const [inValidMessage, setInvalidMessage] = useState<string>('');

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    // 숫자가 아니면 입력 안해줌
    if (isNaN(Number(value))) return;
    if (beforeOnChange) {
      if (beforeOnChange(e)) {
        setInvalidMessage(undefined);
        onChange(e);
      } else {
        setInvalidMessage('invalid...');
      }
    } else {
      onChange(e);
    }
    setNum(value);
  };

  const onBlur = () => {
    if (beforeOnChange && value !== undefined) {
      setNum(String(value));
      setInvalidMessage(undefined);
    }
  };

  return (
    <Container>
      <input
        type="text"
        placeholder={'auto'}
        title={title || field?.key}
        name={typeof keys === 'string' ? keys : JSON.stringify(keys)}
        value={num ?? ''}
        onChange={onChangeValue}
        onBlur={onBlur}
        className={classnames(inValidMessage && 'invalid')}
      />
    </Container>
  );
}

export default InputValue;
