import {getImage} from 'assets/images/svg-image';
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ICopilotMessage} from 'components/menu/copilot/types';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faVolume, faVolumeSlash} from '@fortawesome/pro-solid-svg-icons';
import {getApiHost} from 'api/function';

const API_HOST = getApiHost();

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const PlayerBtn = styled.button`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  .record-player {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    cursor: auto;
  }
`;

const PauseBtn = styled.button`
  display: flex;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;

  .volume-icon {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    color: #cbc9a6;
  }
  .volume-pause-icon {
    width: 20px;
    height: 20px;
    color: #cbc9a6;
    margin-left: 10px;
    display: none;
  }

  &:hover .volume-pause-icon {
    display: block;
    transition: 0.2s;
  }
  &:hover .volume-icon {
    display: none;
    transition: 0.2s;
  }
`;

type IProps = {
  message: ICopilotMessage;
  isShowPlaying: boolean;
  setIsShowPlaying(isShowPlaying: boolean): void;
};

function RecordPlayer({setIsShowPlaying, message, isShowPlaying}: IProps) {
  const {isWaiting, setIsPlaying, isPlaying} = useContext(CopilotContext);

  const [audio] = useState(new Audio(`${API_HOST}/gpt/voices/${message.voice}`));

  useEffect(() => {
    return () => {
      onPause();
    };
  }, []);

  const onClick = () => {
    if (!isShowPlaying && !isWaiting) {
      audio.play();
      setIsShowPlaying(true);
      setIsPlaying(true);
      if (isPlaying) {
        audio.pause();
      }
    }
  };

  const onPause = () => {
    audio.pause();
    setIsPlaying(false);
    setIsShowPlaying(false);
  };

  audio.onended = () => {
    setIsShowPlaying(false);
    setIsPlaying(false);
  };

  return (
    <Container>
      <PlayerBtn onClick={onClick} disabled={isWaiting || isShowPlaying || isPlaying}>
        <img src={getImage('recordPlayBtn')} className="record-player" alt="voice mode button" />
      </PlayerBtn>
      {isShowPlaying && (
        <PauseBtn onClick={onPause}>
          <FontAwesomeIcon icon={faVolume} className="volume-icon" />
          <FontAwesomeIcon icon={faVolumeSlash} className="volume-pause-icon" />
        </PauseBtn>
      )}
    </Container>
  );
}

export default RecordPlayer;
