import React from 'react';
import styled from 'styled-components';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import {hexToRGBA} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';

type PointRectProps = {
  $color: string;
};

const PointRect = styled.rect<PointRectProps>`
  fill: transparent;
  stroke: ${(props) => props.$color};
  &:hover {
    fill: ${(props) => hexToRGBA(props.$color, 0.45)};
  }
`;

enum SymbolType {
  Temperature = 'T',
  Pressure = 'P',
  MassFlow = 'Fm',
  MoleFlow = 'F',
  VolumeFlow = 'Fv'
}

type IProps = {
  iw: number;
  ih: number;
  color: string;
  region: IAnnotation;
  cfg: IPfdCfg;
};

function MetaPfdWidgetPoint({iw, ih, color, region, cfg}: IProps) {
  const {x, y, labelVisible, name, dataType, highlighted, id} = region;

  return (
    <g transform={`translate(${x * iw} ${y * ih})`}>
      {labelVisible && cfg?.isDisplayLabels && (
        <text
          x="0"
          y={-Math.floor(iw * 0.014)}
          fill={color}
          fontSize={Math.floor(iw * 0.014)}
          fontWeight={700}
          textAnchor="middle"
          className="annot-label"
        >
          {name}
        </text>
      )}
      <PointRect
        id={id}
        x={-iw * 0.007}
        y={-iw * 0.007}
        width={iw * 0.014}
        height={iw * 0.014}
        strokeWidth="2"
        $color={color}
      />
      <text
        x="0"
        y={Math.floor(iw * 0.01) / 3}
        fill={color}
        fontSize={Math.floor(iw * 0.01)}
        fontWeight={700}
        textAnchor="middle"
      >
        {SymbolType[dataType]}
      </text>
    </g>
  );
}

export default MetaPfdWidgetPoint;
