import {useEffect, useState} from 'react';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {managementApi, UserInfo, UserInfoWithoutUsername} from 'components/auth/management/management-api-function';
import {managementConst} from 'components/auth/management/const';
import {IManagementAsideInfo} from 'components/auth/management/type';

type IReturn = {
  userAsideInfo: IManagementAsideInfo;
  userList: UserInfo[];
  resetUserAsideInfo(): void;
  openCreateUserAside(): void;
  createUser(username: string, firstName: string, lastName: string, email: string): void;
  updateUser(username: string, firstName: string, lastName: string, email: string): void;
  deleteUser(
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    groups?: string[],
    roles?: string[]
  ): void;
  assignGroupsAndRolesToUser(groups: string[], roles: string[], user_name: string): void;
  removeGroupsAndRolesFromUser(groups: string[], roles: string[], user_name: string): void;
  assignRoleToUser(role_name: string, user_name: string): void;
  removeRoleFromUser(role_name: string, user_name: string): void;
  assignGroupToUser(group_name: string, user_name: string): void;
  removeGroupFromUser(group_name: string, user_name: string): void;
  onClickUserTable(rowKey: string, columnKey: string): void;
  activateUser(user_name: string): void;
  deactivateUser(user_name: string): void;
};

function useUserManagementHandler(): IReturn {
  const [userAsideInfo, setUserAsideInfo] = useState<IManagementAsideInfo>();
  const [userList, setUserList] = useState<UserInfo[]>([]);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;
  const [isApiCallFail, setIsApiCallFail] = useState<boolean>(false);

  useEffect(() => {
    managementApi.user.getUserList(token).then((res) => {
      if (res?.success) {
        setUserList(res?.data.user_list);
      }
    });
  }, []);

  const resetUserAsideInfo = () => {
    setUserAsideInfo(null);
  };

  const openCreateUserAside = () => {
    setUserAsideInfo(managementConst.user.userAsideInfo.create);
    document.addEventListener('mouseup', resetUserAsideInfo, {once: true});
  };

  const createUser = (username: string, firstName: string, lastName: string, email: string) => {
    managementApi.user.createUser(username, firstName, lastName, email).then((res) => {
      if (res?.success) {
        managementApi.user.getUserList(token).then((res) => {
          if (res?.success) {
            setUserList(res?.data.user_list);
            setUserAsideInfo(null);
          }
        });
      } else {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to create user',
          isResponseSuccessTrue: res?.success,
          info: {
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            groups: [],
            roles: []
          }
        }));
      }
    });
  };

  const updateUser = (username: string, firstName: string, lastName: string, email: string) => {
    const updateInfo = {
      firstName: firstName,
      lastName: lastName,
      email: email
    } as UserInfoWithoutUsername;

    managementApi.user.updateUser(token, username, updateInfo).then((res) => {
      if (res?.success) {
        managementApi.user.getUserList(token).then((res) => {
          if (res?.success) {
            setUserList(res?.data.user_list);
            setUserAsideInfo(null);
          }
        });
      } else {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to edit user',
          info: {
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email
          }
        }));
      }
    });
  };

  const deleteUser = (
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    groups?: string[],
    roles?: string[]
  ) => {
    managementApi.user.deleteUser(token, username).then((res) => {
      if (res?.success) {
        managementApi.user.getUserList(token).then((res) => {
          if (res?.success) {
            setUserList(res?.data.user_list);
            setUserAsideInfo(null);
          }
        });
      } else {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to delete user',
          info: {
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            groups: groups ? groups : [],
            roles: roles ? roles : []
          }
        }));
      }
    });
  };

  const assignGroupsAndRolesToUser = async (groups: string[], roles: string[], user_name: string) => {
    try {
      for (const group of groups) {
        await assignGroupToUser(group, user_name);
      }
      for (const role of roles) {
        await assignRoleToUser(role, user_name);
      }
    } catch (error) {
      setIsApiCallFail(true);
      console.error('Error in assigning groups and roles: ', error);
    }
    if (!isApiCallFail) {
      const userListRes = await managementApi.user.getUserList(token);
      if (userListRes?.success) {
        setUserList(userListRes.data.user_list);
      }
    }
  };

  const assignRoleToUser = async (role_name: string, user_name: string) => {
    try {
      const res = await managementApi.user.assignRole(token, role_name, user_name);
      if (!res?.success) {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res.detail : 'Failed to assign role to user'
        }));
      }
      return res;
    } catch (error) {
      console.error('Error assigning role: ', role_name, '\n', error);
      throw error;
    }
  };

  const assignGroupToUser = async (group_name: string, user_name: string) => {
    try {
      const res = await managementApi.user.assignGroup(token, group_name, user_name);
      if (!res?.success) {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res.detail : 'Failed to assign group to user'
        }));
      }
      return res;
    } catch (error) {
      console.error('Error assigning group: ', group_name, '\n', error);
      throw error;
    }
  };

  const removeGroupsAndRolesFromUser = async (groups: string[], roles: string[], user_name: string) => {
    try {
      for (const group of groups) {
        await removeGroupFromUser(group, user_name);
      }
      for (const role of roles) {
        await removeRoleFromUser(role, user_name);
      }
    } catch (error) {
      setIsApiCallFail(true);
      console.error('Error in removing groups and roles: ', error);
    }
    if (!isApiCallFail) {
      const userListRes = await managementApi.user.getUserList(token);
      if (userListRes?.success) {
        setUserList(userListRes.data.user_list);
      }
    }
  };

  const removeRoleFromUser = async (role_name: string, user_name: string) => {
    try {
      const res = await managementApi.user.removeRole(token, role_name, user_name);
      if (!res?.success) {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res.detail : 'Failed to remove role from user'
        }));
      }
      return res;
    } catch (error) {
      console.error('Error removing role: ', role_name, '\n', error);
      throw error;
    }
  };

  const removeGroupFromUser = async (group_name: string, user_name: string) => {
    try {
      const res = await managementApi.user.removeGroup(token, group_name, user_name);
      if (!res?.success) {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res.detail : 'Failed to remove group to user'
        }));
      }
      return res;
    } catch (error) {
      console.error('Error removing group: ', group_name, '\n', error);
      throw error;
    }
  };

  const onClickUserTable = (rowKey: string, columnKey: string) => {
    const selectedUser = userList.find((item) => item.username === rowKey);
    if (selectedUser) {
      // TODO: fix
      if (
        columnKey === 'firstName' ||
        columnKey === 'lastName' ||
        columnKey === 'username' ||
        columnKey === 'email' ||
        columnKey === 'is_active'
      ) {
        setUserAsideInfo({
          ...managementConst.user.userAsideInfo.edit,
          info: {
            username: selectedUser.username,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
            groups: selectedUser.groups,
            roles: selectedUser.roles,
            is_active: selectedUser.is_active
          }
        });
        document.addEventListener('mouseup', resetUserAsideInfo, {once: true});
      } else if (columnKey === 'roles' || columnKey === 'groups') {
        setUserAsideInfo({
          ...managementConst.user.userAsideInfo.detail,
          info: {
            username: selectedUser.username,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
            groups: selectedUser.groups,
            roles: selectedUser.roles,
            is_active: selectedUser.is_active
          }
        });
      }
      document.addEventListener('mouseup', resetUserAsideInfo, {once: true});
    }
  };

  const activateUser = (user_name: string) => {
    managementApi.user.activateUser(token, user_name).then((res) => {
      if (res?.success) {
        managementApi.user.getUserList(token).then((res) => {
          if (res?.success) {
            setUserList(res?.data.user_list);
          }
        });
      } else {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to activate user'
        }));
      }
    });
  };

  const deactivateUser = (user_name: string) => {
    managementApi.user.deactivateUser(token, user_name).then((res) => {
      if (res?.success) {
        managementApi.user.getUserList(token).then((res) => {
          if (res?.success) {
            setUserList(res?.data.user_list);
          }
        });
      } else {
        setUserAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to deactivate user'
        }));
      }
    });
  };

  return {
    userAsideInfo,
    userList,
    resetUserAsideInfo,
    openCreateUserAside,
    createUser,
    updateUser,
    deleteUser,
    assignRoleToUser,
    removeRoleFromUser,
    assignGroupsAndRolesToUser,
    removeGroupsAndRolesFromUser,
    assignGroupToUser,
    removeGroupFromUser,
    onClickUserTable,
    activateUser,
    deactivateUser
  };
}

export default useUserManagementHandler;
