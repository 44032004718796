import {DragEvent, PropsWithChildren} from 'react';
import styled from 'styled-components';

import gridImage from 'assets/images/grid-square.png';

type StyleProps = {
  $isFreeze: boolean;
};

const Container = styled.div<StyleProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  //z-index: 1; // Toast Transparent 공간보다 위에 하기 위해서 z-index 추가

  &.home {
    display: flex;
    background:
      url(${gridImage}) center,
      radial-gradient(ellipse at center, #0a80cc 50%, #0b66af 90%, #1e5799 100%, #7db9e8 100%);
  }
  &.process-canvas {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      box-shadow: ${(props) => (props.$isFreeze ? '0 0 10px 6px rgba(0, 200, 200, 0.6) inset' : 'none')};
    }
    //border: ${({$isFreeze, theme}) => ($isFreeze ? `1px solid ${theme.color.iceBlue};` : 'none;')};
  }
  &.meta-pfd {
    //z-index: 3; // canvas : 1 / svg : 2
  }
`;

type IProps = PropsWithChildren & {
  type?: 'home' | 'process-canvas' | 'meta-pfd' | 'demo';
  onClick?(): void;
  onDragOver?(evt: DragEvent<HTMLDivElement>): void;
  isFreeze?: boolean;
};

function AppContainer({children, type, onClick, onDragOver, isFreeze = false}: IProps): JSX.Element {
  return (
    <Container id="pm-app-container" className={type} onClick={onClick} onDragOver={onDragOver} $isFreeze={isFreeze}>
      {children}
    </Container>
  );
}

export default AppContainer;
