import React, {MouseEvent, ReactElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import {getImage} from 'assets/images/svg-image';
import CopilotPrompt from 'components/menu/copilot/CopilotPrompt';
import ChatHistory from 'components/menu/copilot/ChatHistory';
import {ICopilotThread} from 'components/menu/copilot/types';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGear} from '@fortawesome/pro-light-svg-icons';
import CopilotSettingModal from 'components/menu/copilot/CopilotSettingModal';
import useDraggable from 'utils/useDraggable';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {IPosition} from 'components/common/types';
import Button from 'components/forms/Button';
import NewChatModal from 'components/menu/copilot/NewChatModal';
import {AuthContext} from 'components/auth/AuthProvider';

/*function generateRandom16DigitNumber() {
  let result = '';
  const digits = '0123456789';
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    result += digits.charAt(randomIndex);
  }
  return result;
}*/

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  hr {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    border: 1px solid #797979;
  }
`;

const HeaderButtonWrap = styled.div`
  width: 126px;
  display: flex;
  justify-content: space-between;
`;

const ConfigBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
  .copilot-config {
    font-size: 18px;
    color: #bebebeff;
  }
`;

const StyledImage = styled.div`
  position: absolute;
  top: -9px;
  width: 100%;
  height: 15px;
  z-index: 1;
  text-align: center;
  cursor: ns-resize;
  user-select: none;
  filter: brightness(0.8);
  transition: filter 0.6s;

  img {
    cursor: pointer;
  }

  &:hover {
    transition: filter 0.2s;
    filter: brightness(1);
    color: #575660;
  }
`;

const BOTTOM_OFFSET: number = 411;

function ActionMenuCopilot(): ReactElement {
  const {userProfile} = useContext(AuthContext);
  const [userID, setUserID] = useState(null);
  const [threadList, setThreadList] = useState<ICopilotThread[]>([]);
  const [selectedThread, setSelectedThread] = useState('');
  const [checkHistoryBtn, setCheckHistoryBtn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewChatOpen, setIsNewChatOpen] = useState(false);

  const copilotContext = useContext(CopilotContext);

  //
  // const bottom;
  const boundaryRef = useRef(null);
  const dividerRef = useRef(null);
  const defaultPosition = (LocalStorageManager.getItem('COPILOT_LAYOUT') as IPosition) || {x: 0, y: 60};
  const defaultPadding = {top: 100, right: 0, bottom: BOTTOM_OFFSET, left: 0};
  const [dividerPosition, onMouseDownDivider, setDividerPosition] = useDraggable(
    boundaryRef,
    dividerRef,
    defaultPosition,
    defaultPadding
  );

  useEffect(() => {
    LocalStorageManager.setItem('COPILOT_LAYOUT', dividerPosition);
  }, [dividerPosition]);

  useEffect(() => {
    if (userProfile?.username) {
      logIn();
    }
  }, []);

  // let user_id = 'create0206';

  let user_id = userProfile?.username;

  const logIn = () => {
    copilotContext.sendRequest(user_id, 'login', {}, '').then((res) => {
      if (res.result === 'success') {
        setUserID(res.user_id);
        getThreadList(res.user_id);
      }
    });
  };

  const getThreadList = (user_id: string) => {
    copilotContext.sendRequest(user_id, 'get_thread_list', {}, '').then((res) => {
      setThreadList(res);
    });
  };

  const onClickDivider = () => {
    let y = boundaryRef.current.offsetHeight - dividerRef.current.offsetHeight - BOTTOM_OFFSET;
    if (y === dividerPosition.y) {
      y = 100;
    }
    setDividerPosition({x: 0, y});
    // console.log('>>>>>>', dividerPosition.y, boundaryRef.current.offsetHeight, dividerRef);
  };

  const [isDragging, setIsDragging] = useState(false);
  const onDragStartDivider = (e: MouseEvent): void => {
    onMouseDownDivider(e);
    setIsDragging(true);
  };

  const onDragEndDivider = (): void => {
    setIsDragging(false);
  };

  const getButtonImage = useCallback((): string => {
    if (boundaryRef.current === null || dividerRef.current === null) return 'copilotDownBtn';
    return getImage(
      boundaryRef.current.offsetHeight - dividerRef.current.offsetHeight - BOTTOM_OFFSET === dividerPosition.y
        ? 'copilotUpBtn'
        : 'copilotDownBtn'
    );
  }, [dividerPosition, boundaryRef, dividerRef]);

  const onClick = () => {
    if (copilotContext.isWaiting === false) {
      setIsOpen(true);
    }
  };

  const onClickNewChat = () => {
    setIsNewChatOpen(true);
    // todo: timeRange값 가져오기.
  };

  const onClose = () => {
    setIsOpen(false);
    setIsNewChatOpen(false);
  };

  return (
    <Container className="container" ref={boundaryRef}>
      <ActionMenuHeader main="Copilot" description="Use Process Canvas with Copilot">
        <HeaderButtonWrap>
          <Button
            variant="info"
            disabled={copilotContext.isWaiting || copilotContext.isNewChat === true || copilotContext.isPlaying}
            onClick={onClickNewChat}
          >
            New Chat
          </Button>
          <ConfigBtn
            onClick={onClick}
            disabled={copilotContext.isPlaying || copilotContext.isWaiting || copilotContext.isNewChat}
          >
            <FontAwesomeIcon icon={faGear} className="copilot-config" />
          </ConfigBtn>
        </HeaderButtonWrap>
      </ActionMenuHeader>
      <ChatHistory height={dividerPosition.y} userID={userID} threadList={threadList} />
      <CopilotPrompt
        isDragging={isDragging}
        setSelectedThread={setSelectedThread}
        setCheckHistoryBtn={setCheckHistoryBtn}
      >
        <StyledImage
          ref={dividerRef}
          className={isDragging && 'dragging'}
          onMouseDown={onDragStartDivider}
          onMouseUp={onDragEndDivider}
        >
          <img src={getButtonImage()} alt="copliot change layout handle" onClick={onClickDivider} />
        </StyledImage>
      </CopilotPrompt>
      {isOpen && <CopilotSettingModal onClose={onClose} />}
      {isNewChatOpen && <NewChatModal onClose={onClose} />}
    </Container>
  );
}

export default ActionMenuCopilot;
