import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function ModelPipeText() {
  const {nodes, materials} = useGltfCustom('/gltf/PipeText/Pipes_Text.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes.Text001_1.geometry}
        material={materials.Pipe_Text}
        position={[-2.275, 7.319, 46.962]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Text002_1.geometry}
        material={materials.Pipe_Text}
        position={[28.097, 7.319, 55.672]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Text003_1.geometry}
        material={materials.Pipe_Text}
        position={[-39.291, 7.319, -84.591]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
      />
      <mesh
        geometry={nodes.Text004_1.geometry}
        material={materials.Pipe_Text}
        position={[52.856, 7.319, -68.918]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
      />
      <mesh
        geometry={nodes.Text005_1.geometry}
        material={materials.Pipe_Text}
        position={[66.849, 7.319, -13.997]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
      />
      <mesh
        geometry={nodes.Text006_1.geometry}
        material={materials.Pipe_Text}
        position={[81.934, 7.319, -2.924]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
      />
      <mesh
        geometry={nodes.Text007_1.geometry}
        material={materials.Pipe_Text}
        position={[-63.207, 7.319, -88.352]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
      />
      <mesh
        geometry={nodes.Text008_1.geometry}
        material={materials.Pipe_Text}
        position={[-100.6, 7.319, -12.692]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

//useGLTF.preload('/gltf/PipeText/Pipes_Text.gltf');
