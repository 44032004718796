import React from 'react';
import {convertPoint2String, hexToRGBA} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';

type PolyLineSvgProps = {
  $createWithPrimary?: boolean;
  $selectedTool?: string;
  $createMode?: boolean;
};

const PolyLine = styled.polyline<PolyLineSvgProps>`
  position: absolute;
  z-index: ${META_PFD_zINDEX.REGION_COMPONENTS_POLYLINE};
  fill: none;
  transition: all 0.5s ease;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

type IProps = {
  iw: number;
  ih: number;
  region: IAnnotation;
  cfg: IPfdCfg;
};

function MetaPfdWidgetLine({iw, ih, region, cfg}: IProps) {
  const [pointStringList, polyLineList, uniquePointList] = convertPoint2String(region, iw, ih);
  const startArrowId = 'start-arrow-' + region.id;
  const endArrowId = 'end-arrow-' + region.id;

  if (pointStringList.length % 2 !== 0) {
    return null;
  }

  return (
    <>
      <defs>
        <marker
          id={startArrowId}
          viewBox="0 0 20 20"
          refX="0"
          refY="10"
          markerWidth="9"
          markerHeight="3"
          orient="auto"
          fill={hexToRGBA(region?.color, 1)}
        >
          <path d="M 0 0 L -20 10 L 0 20 Z" />
        </marker>
      </defs>
      <defs>
        <marker
          id={endArrowId}
          viewBox="0 0 20 20"
          refX="0"
          refY="10"
          markerWidth="3"
          markerHeight="3"
          orient="auto"
          fill={hexToRGBA(region?.color, 1)}
        >
          <path d="M 0 0 L 20 10 L 0 20 Z" />
        </marker>
      </defs>
      {polyLineList.map((item, idx) => (
        <PolyLine
          key={idx}
          points={item?.polyLineString}
          stroke={hexToRGBA(region?.color, 1)}
          strokeWidth={iw * 0.004}
          pointerEvents="visibleStroke"
          markerStart={`url(#${item.isArrow && item.startArrow && startArrowId})`}
          markerEnd={`url(#${item.isArrow && item.endArrow && endArrowId})`}
        />
      ))}
      {uniquePointList.map(({x, y}, i) => {
        return (
          <g key={i} transform={`translate(${x * iw} ${y * ih})`}>
            {i === 0 && region?.labelVisible && cfg?.isDisplayLabels && (
              <text
                x={0}
                y={-Math.floor(iw * 0.007)}
                fill={region?.color}
                fontSize={Math.floor(iw * 0.014)}
                fontWeight={700}
                textAnchor="middle"
                className="annot-label"
              >
                {region?.name}
              </text>
            )}
          </g>
        );
      })}
    </>
  );
}

export default MetaPfdWidgetLine;
