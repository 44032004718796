import {useState, type Dispatch, type SetStateAction, type ReactNode, HTMLAttributes} from 'react';
import {NodeChange, Panel} from 'reactflow';
import ViewportLogger from 'components/pc/common/devTool/ViewportLogger';
import NodeInspector from 'components/pc/common/devTool/NodeInspector';
import ChangeLogger from 'components/pc/common/devTool/ChangeLogger';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 4px;
  font-size: 11px;
  button {
    border: none;
    padding: 5px 15px;
    color: #222;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    background-color: #f4f4f4;
    border-right: 1px solid #ddd;
    &:hover {
      background: rgba(238, 58, 115, 1);
      opacity: 0.8;
      color: white;
    }
    &.active {
      background: rgba(238, 58, 115, 1);
      color: white;
    }
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
      border-right: none;
    }
  }
`;

type IProps = {
  changes: NodeChange[];
};
export default function DevTools({changes}: IProps) {
  const [nodeInspectorActive, setNodeInspectorActive] = useState(true);
  const [changeLoggerActive, setChangeLoggerActive] = useState(true);
  const [viewportLoggerActive, setViewportLoggerActive] = useState(true);

  return (
    <Container>
      <Panel position="bottom-center">
        <div style={{marginBottom: '100px'}}>
          <DevToolButton setActive={setNodeInspectorActive} active={nodeInspectorActive} title="Toggle Node Inspector">
            Node Inspector
          </DevToolButton>
          <DevToolButton setActive={setChangeLoggerActive} active={changeLoggerActive} title="Toggle Change Logger">
            Change Logger
          </DevToolButton>
          <DevToolButton
            setActive={setViewportLoggerActive}
            active={viewportLoggerActive}
            title="Toggle Viewport Logger"
          >
            Viewport Logger
          </DevToolButton>
        </div>
      </Panel>
      {changeLoggerActive && <ChangeLogger changes={changes} />}
      {nodeInspectorActive && <NodeInspector />}
      {viewportLoggerActive && <ViewportLogger />}
    </Container>
  );
}

function DevToolButton({
  active,
  setActive,
  children,
  ...rest
}: {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
} & HTMLAttributes<HTMLButtonElement>) {
  return (
    <button onClick={() => setActive((a) => !a)} className={active ? 'active' : ''} {...rest}>
      {children}
    </button>
  );
}
