type LocalStorageKeys =
  | 'GLOBAL_SETTINGS'
  | 'PROCESS_CANVAS_TOOLBOX'
  | 'META_PFD_TOOLBOX'
  | 'META_PFD_INFO'
  | 'COPILOT_SETTINGS'
  | 'COPILOT_LAYOUT'
  | 'PROCESSMETAVERSE_LOGIN_TOKEN'
  | 'API_HOST_TYPE'
  | 'API_SERVER'
  | 'CLIENT_VERSION'
  | 'FILE_VIEW_OPTION'
  | string;

export type ServerTypes = 'cloud' | 'local';

export const LocalStorageManager = {
  getItem<T>(key: LocalStorageKeys): T {
    const value = localStorage.getItem(key);
    return JSON.parse(value);
  },
  setItem<T>(key: LocalStorageKeys, data: T) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  removeItem(key: LocalStorageKeys) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  }
};
