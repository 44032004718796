import {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalFooter from 'components/common/modal/ModalFooter';
import Button from 'components/forms/Button';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import CommoditySelectItemList from 'components/pc/widgets/commodity/CommoditySelectItemList';
import ModalBody from 'components/common/modal/ModalBody';
import {ICommodityBasic} from 'components/pc/widgets/commodity/types';
import NodeSelector from 'components/pc/node-selector/NodeSelector';
import {ICommodityCfg} from 'components/pc/widgets/CommodityWidget';
import {FormRow, SelectForm, TextForm} from 'components/forms';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import {getUniqueKey} from 'utils/commons';
import {CommonContext} from 'components/common/CommonProvider';
import {chemicalPreset, energyPreset, defaultPreset} from 'components/pc/widgets/commodity/const';
import NodesSelectButton from 'components/pc/common/NodesSelectButton';

const Container = styled.div`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const TextFormWrap = styled.div`
  display: flex;
  width: 26%;
  flex-shrink: 0;
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PresetBtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .preset-select {
    flex: 1;
    margin-left: 5px;
  }
`;

type IProps = {
  id: string;
  defaultList: ICommodityBasic[];
  cfg: ICommodityCfg;
  onChangeCfg(newCfg: ICommodityCfg, action?: string): void;
  onConfirm(list: ICommodityBasic[], newCfg: ICommodityCfg): void;
  onCancel(isShowConfig: boolean): void;
};

function CommoditySetting({id, defaultList, onConfirm, onCancel, cfg, onChangeCfg}: IProps): ReactElement {
  const [selectedItemList, setSelectedItemList] = useState<ICommodityBasic[]>(defaultList);
  const [isShowTagSelector, setIsShowTagSelector] = useState(false);
  const [preset, setPreset] = useState('');
  const [isValidNumber, setIsValidNumber] = useState(false);
  const {addToast} = useContext(CommonContext);

  const {autoUpdate, updateIntervalUnit, updateIntervalVal} = cfg;
  const [copy, setCopy] = useState<ICommodityCfg>({
    autoUpdate,
    updateIntervalUnit,
    updateIntervalVal
  });

  useEffect(() => {
    setCopy(cfg);
  }, [cfg]);

  useModalKeyBoardEvent({
    onConfirm: () => {
      onClickConfirm();
    },
    onCancel: () => {
      onClickCancel();
    }
  });

  //todo: nodeSelector useLatestTagHandler nodeHandler로 적용할 시 interval 단위 설정 구현

  const onChangeTimeIntervalVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCopy((prev) => ({...prev, updateIntervalVal: target.value}));
    if (target.value < '1') {
      setIsValidNumber(true);
    } else {
      setIsValidNumber(false);
    }
  };

  const onChangeTimeIntervalUnit = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    Number(target.value);
    setCopy((prev) => ({...prev, updateIntervalUnit: Number(target.value)}));
  };

  const onClickConfirm = () => {
    const {autoUpdate, updateIntervalUnit, updateIntervalVal} = copy;
    let dic = {};
    let action = '';

    if (
      autoUpdate !== cfg.autoUpdate ||
      updateIntervalUnit !== cfg.updateIntervalUnit ||
      updateIntervalVal !== cfg.updateIntervalVal
    ) {
      if (Number(updateIntervalVal) > 0) {
        dic = {...dic, autoUpdate, updateIntervalUnit, updateIntervalVal};
        action = 'updateTimer';
      } else {
        addToast({id: getUniqueKey(), text: 'Update interval value must be greater than 0'});
      }
    }
    onConfirm(selectedItemList, copy);
    onChangeCfg({...cfg, ...dic}, action);
  };

  const onClickCancel = () => {
    onCancel(false);
  };

  const onClickAddTagButton = (): void => {
    setIsShowTagSelector(true);
  };

  const onCloseTagPopup = (): void => {
    setIsShowTagSelector(false);
  };

  const onSort = (list: ICommodityBasic[]): void => {
    setSelectedItemList(list);
  };

  const onSelectTag = (tag: string[][], tagInfos: ICommodityBasic[]): void => {
    // tagSelector에서 전달해주는 tag 배열의 태그 목록에서 첫번째 인덱스 값인 "Commodity"를 제거해주는 함수
    const filteredTags: ICommodityBasic[] = tagInfos.map(({tag, unit}) => ({
      tag: tag.slice(1),
      unit
    }));

    setSelectedItemList(filteredTags);
    setIsShowTagSelector(false);
  };

  const onChangePreset = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedPreset = e.target.value;
    setPreset(selectedPreset);

    if (selectedPreset === 'default') {
      setSelectedItemList(defaultPreset);
    } else if (selectedPreset === 'chemical') {
      setSelectedItemList(chemicalPreset);
    } else if (selectedPreset === 'energy') {
      setSelectedItemList(energyPreset);
    }
  };

  const presetOption = [
    {label: 'Default Preset', value: 'default'},
    {label: 'Chemical', value: 'chemical'},
    {label: 'Energy', value: 'energy'}
  ];

  // NodeSelector selectedTags 형식에 맞게 변환
  const selectedItemTag = selectedItemList.map((item) => ['Commodity', item.tag[0], item.tag[1]]);

  return (
    <Container className="thin-scrollbar">
      <ModalBody align="top" size="full-width">
        <ListWrap>
          <PresetBtnWrap>
            <NodesSelectButton onClick={onClickAddTagButton} />
            <SelectForm
              name="preset-select"
              className="preset-select"
              placeholder="Select Preset"
              disabledPlaceholder
              options={presetOption}
              value={preset}
              onChange={onChangePreset}
            />
          </PresetBtnWrap>
          <CommoditySelectItemList selectedItemList={selectedItemList} onSort={onSort} />
        </ListWrap>
        <FormRow header="Update interval">
          <TextFormWrap className="text-form-wrap">
            <TextForm
              type="number"
              name="time-value"
              value={copy?.updateIntervalVal || ''}
              onChange={(e) => onChangeTimeIntervalVal(e)}
            />
          </TextFormWrap>
          <SelectForm
            name={'time-unit'}
            options={TimeUnitOptions}
            value={copy?.updateIntervalUnit}
            onChange={(e) => onChangeTimeIntervalUnit(e)}
          />
        </FormRow>
      </ModalBody>
      {isShowTagSelector && (
        <NodeSelector
          selectedTags={selectedItemTag}
          onSelect={onSelectTag}
          onClose={onCloseTagPopup}
          categoryList={['Commodity']}
        />
      )}
      <ModalFooter>
        <Button disabled={isValidNumber} width={100} onClick={onClickConfirm}>
          Confirm
        </Button>
        <Button variant="cancel" onClick={onClickCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default CommoditySetting;
