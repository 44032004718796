import {IAnnotation, IPfdAction, IPfdCfg} from 'components/mpfd/type';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import React from 'react';
import classnames from 'classnames';
import Annotation from 'components/mpfd/annotation/Annotation';
import MetaPfdWidgetAnnotation from 'components/pc/widgets/metaPfd/annot/MetaPfdWidgetAnnotation';

type IProps = {
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  regions: IAnnotation[];
  isViewer?: boolean;
  createWithPrimary?: boolean;
  mode?: any;
  dragWithPrimary?: boolean;
  id?: string;
  selectedTool?: string;
  cfg: IPfdCfg;
  dispatchToReducer(action: IPfdAction): void;
};

const StyledSvg = styled.svg`
  position: absolute;
  z-index: ${META_PFD_zINDEX.REGION_SHAPE};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  g {
    pointer-events: all;
  }
  polyline,
  rect,
  path,
  circle,
  text,
  path {
    z-index: 3;
  }
  .annot-label {
    pointer-events: none;
  }
`;

type IMetaPfdWidgetAnnotationListProps = {
  regions: IAnnotation[];
  iw: number;
  ih: number;
  cfg: IPfdCfg;
};

function MetaPfdWidgetAnnotationList({regions, ...rest}: IMetaPfdWidgetAnnotationListProps) {
  return (
    <>
      {regions.map((r) => (
        <MetaPfdWidgetAnnotation key={r?.id} type={r?.type} region={r} {...rest} />
      ))}
    </>
  );
}

function MetaPfdWidgetAnnotationLayer({imagePosition, regions = [], id = 'none-widget', cfg}: IProps) {
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;

  if (isNaN(iw) || isNaN(ih)) return null;

  return (
    <StyledSvg className={classnames(`regionWrapper${id}`, 'annotation-layer')}>
      <MetaPfdWidgetAnnotationList regions={regions} iw={iw} ih={ih} cfg={cfg} />
    </StyledSvg>
  );
}

export default MetaPfdWidgetAnnotationLayer;
