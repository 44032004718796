import BasicSpinner from 'components/common/BasicSpinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExclamation} from '@fortawesome/pro-light-svg-icons';
import React from 'react';

function ImageLoadStep({imgLoadStatus}) {
  switch (imgLoadStatus) {
    case 'loading':
      return <BasicSpinner isShow={true} type="overlay" position="center-center" />;
    case 'fail':
      return <FontAwesomeIcon icon={faFileExclamation} size={'5x'} />;
    default:
      return <></>;
  }
}

export default ImageLoadStep;
