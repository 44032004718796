import {ChangeEvent, ReactElement} from 'react';
import InputValue from 'components/table/parts/InputValue';
import {ITableField} from 'components/table/DefaultTable';

type IProps = {
  field: ITableField;
  keys: string;
  onChange(e: ChangeEvent): void;
};

function InputMinMaxValue({keys, field, onChange, ...rest}: IProps): ReactElement {
  const value = rest[field?.key];

  const beforeOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const stringValue = e.target.value;
    const checkValue = Number(e.target.value);

    // auto
    if (stringValue?.replaceAll(' ', '') === '') {
      return true;
    }
    if (field?.keyOfMinMax) {
      const minValue = Number(rest[field?.keyOfMinMax?.min]);
      const maxValue = Number(rest[field?.keyOfMinMax?.max]);
      if (field?.key === field?.keyOfMinMax?.min && rest[field?.keyOfMinMax?.max] === '') {
        return true;
      }
      if (field?.key === field?.keyOfMinMax?.max && rest[field?.keyOfMinMax?.min] === '') {
        return true;
      }
      if (field?.key === field?.keyOfMinMax?.min && maxValue > checkValue) {
        return true;
      }
      if (field?.key === field?.keyOfMinMax?.max && minValue < checkValue) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  return (
    <InputValue
      keys={keys}
      title={field?.key}
      value={value}
      onChange={onChange}
      beforeOnChange={beforeOnChange}
      {...rest}
    />
  );
}

export default InputMinMaxValue;
