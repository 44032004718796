import React, {useRef} from 'react';
import {useGLTF, useAnimations} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

const positions: [number, number, number][] = [
  [25.658, 10, -13.917],
  [63.152, 10, -2.856],
  [2.6699, 10, -45.765],
  [7.199, 10, -74.65],
  [-63.311, 10, -65.294],
  [-116.032, 10, -40.03]
];

function AirCoolerMesh({position}) {
  const groupRef = useRef();
  const {nodes, materials, animations} = useGltfCustom('/gltf/AirCooler/AirCooler.gltf');
  const {actions} = useAnimations(animations, groupRef);

  React.useEffect(() => {
    Object.values(actions).forEach((action) => action.play());
  }, [actions]);

  return (
    <group ref={groupRef} position={position}>
      <mesh
        name="_AirCooler"
        geometry={nodes._AirCooler.geometry}
        material={materials.Simacro_AirCooler}
        position={[0, 0, 0]}
      />
      <mesh
        name="_AirCooler_Fan1"
        geometry={nodes._AirCooler_Fan1.geometry}
        material={materials.Simacro_AirCooler}
        position={[2.4, 1.25, 0]}
      />
      <mesh
        name="_AirCooler_Fan2"
        geometry={nodes._AirCooler_Fan2.geometry}
        material={materials.Simacro_AirCooler}
        position={[-2.3, 1.25, 0]}
      />
    </group>
  );
}

export function AirCooler() {
  return (
    <group dispose={null} scale={0.2}>
      {positions.map((position, i) => (
        <AirCoolerMesh key={i} position={position} />
      ))}
    </group>
  );
}

// useGLTF.preload('/gltf/AirCooler/AirCooler.gltf');
