import {memo, ReactElement, useContext, useEffect, useState} from 'react';
import YouTube from 'react-youtube';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import UrlSearchBar from './parts/UrlSearchBar';
import {IWidgetNodeData, IYoutubeWidgetData} from 'components/pc/types';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const YoutubeBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #525f7f;
  background-color: #000000;
`;

const Browser = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  .youtube-iframe {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    iframe {
      margin-top: 3%;
    }
  }
`;

const opts = {
  height: '90%',
  width: '90%',
  playerVars: {
    autoplay: 1
  }
};

function YoutubeWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const [currentVideo, setCurrentVideo] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('https://www.youtube.com/watch?v=VhesCnmqWUQ&ab_channel=SIMACROSupport');
  const [videoTitle, setVideoTitle] = useState('');
  const {setNodes} = useReactFlow();
  const {onCanvasChange} = useContext(ProcessCanvasContext);

  const getVideoTitle = () => {
    const title = fetch(`https://noembed.com/embed?dataType=json&url=${youtubeURL}`)
      .then((res) => res.json())
      .then((data) => setVideoTitle(data.title));
    return title;
  };

  const getVideoId = (url: string) => {
    if (!url) return;
    if (url.startsWith('https://youtu.be/')) {
      return url.replace('https://youtu.be/', '');
    }
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^&\n?#]+)/
    );
    if (match && match[1]) {
      setYoutubeURL(url);
      return match[1];
    }
    return null;
  };

  useEffect(() => {
    if (data?.metaData) {
      const {currentVideo, url} = data.metaData as IYoutubeWidgetData;
      setCurrentVideo(currentVideo);
      console.log(url);
      setYoutubeURL(url);
    }
  }, []);

  const saveData = (cv: string, path: string) => {
    const metaData = {currentVideo: cv, url: path};
    setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
    );
    onCanvasChange();
  };

  const onNavigate = (path: string): void => {
    const cv = getVideoId(path);
    if (!cv) return;
    getVideoTitle();
    setCurrentVideo(cv);
    saveData(cv, path);
  };

  const onClose = (): void => {
    // todo:
  };

  const onReady = () => {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  };

  // const titleData = videoTitle === '' ? '' : `${videoTitle} - `;

  return (
    <WidgetContainer {...rest} data={data} type="YoutubeWidget">
      <WidgetHeader
        {...rest}
        type="YoutubeWidget"
        icon={data.icon}
        id={id}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'YoutubeWidget', titleData: videoTitle, data})}
        suffix="- Youtube"
      />
      <WidgetActionPanel>
        <UrlSearchBar defaultUrl={youtubeURL} onConfirm={onNavigate} />
      </WidgetActionPanel>
      <WidgetBody>
        <YoutubeBody id={id}>
          {/*<div className="youtube-body" id={id}>*/}
          {/*<div className="browser">*/}
          <Browser>
            <YouTube
              videoId={currentVideo}
              opts={opts}
              onReady={onReady}
              style={{height: '100%'}}
              className="youtube-iframe"
            />
            {/*</div>*/}
          </Browser>
          {/*</div>*/}
        </YoutubeBody>
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(YoutubeWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
