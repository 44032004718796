import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, SelectForm, TextForm} from 'components/forms';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ICopilotSettings} from 'components/menu/copilot/CopilotSettingModal';
import {LocalStorageManager} from 'utils/local-storage-manager';
import styled from 'styled-components';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import {ICopilotNodeDataParams} from 'components/menu/copilot/types';
import {getUniqueKey} from 'utils/commons';
import Tooltip from 'components/common/Tooltip';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import {IDatasheetWidgetAutoRunConfig} from 'components/pc/widgets/datasheet/types';
import NodesSelectButton from 'components/pc/common/NodesSelectButton';

const DatePickerWrap = styled.div`
  flex: 1;

  .date-picker-input {
    background-color: #eaeffa;
    border: 1px solid #c7cede;
    color: #475885;
    width: 210px;
    margin: 0 5px 0 auto;
    padding: 0 10px;
    height: 32px;
    outline: none;
    text-align: center;
    font-size: 15px;

    &:focus {
      background-color: #eaeffa;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;

const RowLabel = styled.div`
  display: flex;
  width: 162px;
  align-items: center;
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  margin-right: 4px;
  justify-content: flex-end;
`;

const SelectNodeBtnWrap = styled.div`
  gap: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: stretch;

  .rmdp-calendar-container-mobile {
    position: relative !important;
  }

  .rmdp-mobile {
    position: absolute;
  }
`;

const NodeListContainer = styled.div`
  display: flex;
  max-height: 400px;
  min-height: 400px;
  flex: 1;
  border: 1px solid #8696b9;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: #5556a9;
  color: #b0b1ea;
  white-space: nowrap;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
`;

const SelectNodeList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  &.center {
    align-items: center;
    justify-content: center;
    height: 370px;
    svg {
      margin-right: 5px;
    }
  }
`;

const SelectNodeItem = styled.div`
  display: flex;
  padding: 8px 14px;
  border-top: 1px solid #8696b9;
`;

// data range 형태 변환 함수
export const generateMonthlyArray = (startPoint: number, endPoint: number) => {
  const startYear = Math.floor(startPoint / 100);
  const startMonth = startPoint % 100;
  const endYear = Math.floor(endPoint / 100);
  const endMonth = endPoint % 100;

  const result = [];

  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonth : 1;
    const end = year === endYear ? endMonth : 12;

    for (let month = start; month <= end; month++) {
      result.push(`${year}${String(month).padStart(2, '0')}`);
    }
  }
  return result;
};

const dateToString = (startPoint: number, endPoint: number): [string, string] => {
  const startString = String(startPoint);
  const endString = String(endPoint);
  return [startString, endString];
};

const defaultSettings: ICopilotSettings = {dataIsOn: false};

type IProps = {
  onClose(): void;
};

function NewChatModal({onClose}: IProps) {
  const {setDataList, isWaiting, changeDataRange, dataList, dataRange} = useContext(CopilotContext);

  const savedSettings = LocalStorageManager.getItem<ICopilotSettings>('COPILOT_SETTINGS') || defaultSettings;
  const [settings] = useState<ICopilotSettings>(savedSettings);
  const [selectedStartDate, setSelectedStartDate] = useState<number>();
  const [selectedEndDate, setSelectedEndDate] = useState<number>();
  const intervalTimeState = useState<IDatasheetWidgetAutoRunConfig>({
    intervalValue: 60,
    intervalUnit: 1000 * 60
  });
  const [intervalTime, setIntervalTime] = intervalTimeState;
  const [refinedIntervalTime, setRefinedIntervalTime] = useState<number>(60);
  const [isValidNum, setIsValidNum] = useState<boolean>(true);

  const [selectedNodes, setSelectedNodes] = useState<string[][]>([]);
  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);

  const now = new DateObject();
  const end = now.unix;
  const start = now.subtract(7, 'day').unix;
  const rangeState = useState<number[]>([start, end]);
  const [range, setRange] = rangeState;

  useEffect(() => {
    setDataList(dateToString(selectedStartDate, selectedEndDate));
  }, [selectedStartDate, selectedEndDate]);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  const onConfirm = () => {
    LocalStorageManager.setItem('COPILOT_SETTINGS_NEWCHAT', settings);
    setDataList(dataList);

    if (isWaiting === false) {
      changeDataRange(dataInfo); // todo : ()안에 인자 값으로 start select date 전달
    }
    /*    const r = rawData as unknown;
    console.log(JSON.parse(r as string));*/ // todo: rawData json.parse 처리 필요
    onClose();
  };

  const startRange = parseFloat(dataRange?.[0]);
  const endRange = parseFloat(dataRange?.[1]);

  //todo: 202401을 available_data_ranged return 배열[0]으로 변경

  //todo: selectedStartDate가 selectedEndDate보다 큰 경우는 conFirm을 막거나 선택지 변화 주기
  const dateOptions = generateMonthlyArray(startRange, endRange).map((date) => ({
    value: date,
    label: `${date.slice(0, 4)}-${date.slice(4)}`
  }));

  useEffect(() => {
    if (dateOptions.length > 0 && selectedStartDate === undefined && selectedEndDate === undefined) {
      setSelectedStartDate(Number(dateOptions[0].value));
      setSelectedEndDate(Number(dateOptions[dateOptions.length - 1].value));
    }
  }, [dateOptions, selectedStartDate, selectedEndDate]);

  const onChangeRange = (range: DateObject[]): void => {
    const [start, end] = range;
    if (start && end) {
      // DatePicker 에 적용하고, 다른 객체에도 전달
      setRange([start.unix, end.unix]);
    }
  };

  const onChange = (e: ChangeEvent) => {
    const {name, value} = e.target as HTMLInputElement;
    switch (name) {
      case 'intervalValue': {
        let n = Number(value);
        if (isNaN(n)) {
          n = null;
        }
        setIntervalTime((prev) => ({...prev, [name]: n}));
        break;
      }
      case 'intervalUnit': {
        const n = Number(value);
        if (!isNaN(n)) {
          setIntervalTime((prev) => ({...prev, [name]: n}));
        }
        break;
      }
    }
  };

  useEffect(() => {
    if (intervalTime.intervalUnit === 1000) {
      //seconds to minutes
      setRefinedIntervalTime(intervalTime.intervalValue / 60);
    } else if (intervalTime.intervalUnit === 60000) {
      //minutes
      setRefinedIntervalTime(intervalTime.intervalValue);
    } else if (intervalTime.intervalUnit === 3600000) {
      //seconds to hours
      setRefinedIntervalTime(intervalTime.intervalValue * 60);
    }

    if (intervalTime.intervalValue < 1) {
      setIsValidNum(false);
    } else {
      setIsValidNum(true);
    }
  }, [intervalTime.intervalUnit, intervalTime.intervalValue]);

  const onSelectNode = (checkedList: string[]): void => {
    const parsed = checkedList.map((item) => JSON.parse(item));
    setSelectedNodes(parsed);
  };

  const dataInfo: ICopilotNodeDataParams = {
    time_range: range,
    node_infos: selectedNodes.map((nodes) => ({
      node: nodes.slice(1),
      database: nodes[0]
    })),
    interval: intervalTime.intervalValue === 0 ? 60 : refinedIntervalTime
  };
  const noSelectNodes = selectedNodes.length === 0 ? 'center' : '';

  return (
    <ModalWrapper type="md">
      <ModalHeader onClose={onClose}>New Chat Settings</ModalHeader>
      <ModalBody align="top" size="full-width">
        <SelectNodeBtnWrap>
          <NodesSelectButton onClick={() => setIsShowNodeSelector(true)} />
          <DatePickerWrap>
            <DatePicker
              className="rmdp-mobile"
              value={range?.map((t) => t * 1000)}
              range
              inputClass="date-picker-input"
              format="YYYY-MM-DD"
              showOtherDays
              disabled={selectedNodes.length === 0}
              onChange={onChangeRange}
            />
          </DatePickerWrap>
          <RowLabel>Time interval</RowLabel>
          <Tooltip
            type="guide"
            isOpen={!isValidNum}
            content={<span style={{color: '#ff2d2d'}}>Please enter a value greater than 0</span>}
          >
            <TextForm
              name="intervalValue"
              value={intervalTime.intervalValue}
              onChange={(e) => onChange(e)}
              disabled={selectedNodes.length === 0}
              width={40}
              height={35}
            />
          </Tooltip>
          <SelectForm
            name="intervalUnit"
            options={TimeUnitOptions}
            value={intervalTime.intervalUnit}
            onChange={(e) => onChange(e)}
            disabled={selectedNodes.length === 0}
            width={120}
            height={35}
          />
        </SelectNodeBtnWrap>
        <NodeListContainer>
          <Header>Selected Node List</Header>
          <SelectNodeList className={classNames('thin-scrollbar md', noSelectNodes)}>
            {selectedNodes.map((item) => (
              <SelectNodeItem key={getUniqueKey()}>{item.join('.')}</SelectNodeItem>
            ))}
            {selectedNodes.length === 0 && (
              <span>
                <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
                Create New Chat without specific dataset
              </span>
            )}
          </SelectNodeList>
        </NodeListContainer>

        {isShowNodeSelector && (
          <NodeSelectorRevision
            title="Select Nodes"
            selectedNodes={selectedNodes}
            onClose={() => setIsShowNodeSelector(false)}
            onSelect={onSelectNode}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button width={150} disabled={isShowNodeSelector || !isValidNum} onClick={onConfirm}>
          Create New Chat
        </Button>
        <Button variant="cancel" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default NewChatModal;
