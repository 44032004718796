import {IPfdState, IPfdAction} from 'components/mpfd/type';
import mouseAction, {IMpfdMouseActionTypes} from 'components/mpfd/hooks/reducer/mouseAction';
import toolAction, {IMpfdToolActionTypes} from 'components/mpfd/hooks/reducer/toolAction';
import utilAction, {IMpfdUtilActionTypes} from 'components/mpfd/hooks/reducer/utilAction';
import unChangedAction, {IMpfdUnChangedActionTypes} from 'components/mpfd/hooks/reducer/unChangedAction';

const actionReducer = (state: IPfdState, action: IPfdAction) => {
  if (IMpfdMouseActionTypes.includes(action.type)) {
    if (state?.mode) {
      state = {...state, isChanged: true};
    }
    return mouseAction(state, action);
  } else if (IMpfdToolActionTypes.includes(action.type)) {
    state = {...state, isChanged: true};
    return toolAction(state, action);
  } else if (IMpfdUtilActionTypes.includes(action.type)) {
    state = {...state, isChanged: true};
    return utilAction(state, action);
  } else if (IMpfdUnChangedActionTypes.includes(action.type)) {
    return unChangedAction(state, action);
  }
  return state;
};

export default actionReducer;
