import {PropsWithChildren, ReactElement, Children} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  //overflow: hidden;
`;

type IProps<T> = PropsWithChildren & {
  tab: T;
};

function TabContentsContainer<T>({tab, children}: IProps<T>): ReactElement {
  if (!children) {
    return null;
  } else {
    return (
      <Container>
        {Children.map(children, function (child, index) {
          const c = child as ReactElement;
          const key = c.key;
          return key === tab && child;
        })}
      </Container>
    );
  }
}

export default TabContentsContainer;
