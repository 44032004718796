import React, {useContext, useRef, useState} from 'react';
import useEventCallback from 'use-event-callback';
import styled from 'styled-components';
import {applyToPoint, compose, inverse, rotate, scale, translate} from 'transformation-matrix';
import {layoutParamType} from 'components/mpfd/type';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import FormToolBox from 'components/mpfd/panel/FormToolBox';
import {useProjectRegionBox} from 'components/mpfd/hooks';
import {AnnotationLayer, ImageLayer, SelectedAnnotationLayer} from 'components/mpfd/layer';
import RemoveScrollWrapper from 'components/mpfd/etc/RemoveScrollWrapper';
import LayerWrapper from 'components/mpfd/layer/LayerWrapper';
import ProcessImageViewerContainer from 'components/mpfd/layer/parts/ProcessImageViewerContainer';
import ZoomLayer from 'components/mpfd/layer/ZoomActionLayer';
import DataLayer from 'components/mpfd/layer/DataLayer';
import ConnectionLineLayer from 'components/mpfd/layer/ConnectionLineLayer';

const StandardCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  opacity: 0.25;
`;

export const ProcessImageViewer = () => {
  const {
    state,
    dispatchToReducer,
    matrix,
    changeMatrix,
    latestTagHandlerForTable,
    latestTagHandlerForChart,
    latestNodeHandlerForTable,
    latestNodeHandlerForChart
  } = useContext(MetaPfdContext);
  const {regions, selectedTool, images} = state;
  const layoutParams = useRef<layoutParamType>({});
  const canvasEl = useRef(null);
  // const latestTagHandlerForTable = useLatestNodeHandler({type: 'latest_count', latest_count: 1});
  // const latestTagHandlerForChart = useLatestNodeHandler({type: 'latest_count', latest_count: 10});

  // const [dragging, changeDragging] = useRafState(false);
  // const [zoomStart, changeZoomStart] = useRafState(false);

  const projectRegionBox = useProjectRegionBox({layoutParams, mat: matrix});
  const [imageDimensions, changeImageDimensions] = useState({naturalWidth: 0, naturalHeight: 0});
  const imageLoaded = Boolean(imageDimensions.naturalWidth > 0);

  const onImageLoaded = useEventCallback(({naturalWidth, naturalHeight}) => {
    const dims = {naturalWidth, naturalHeight};
    changeImageDimensions(dims);
    setTimeout(() => changeImageDimensions(dims), 10);
  });

  const canvas = canvasEl.current;
  if (canvas && imageLoaded) {
    const {clientWidth, clientHeight} = canvas;
    const [iw, ih] = [imageDimensions.naturalWidth, imageDimensions.naturalHeight];
    layoutParams.current = {
      iw,
      ih,
      canvasWidth: clientWidth,
      canvasHeight: clientHeight
    };
  }

  const imagePosition = {
    topLeft: applyToPoint(inverse({...matrix}), {x: 0, y: 0}),
    bottomRight: applyToPoint(inverse({...matrix}), {
      x: imageDimensions?.naturalWidth,
      y: imageDimensions?.naturalHeight
    })
  };

  const zoomToPoint = (e: React.MouseEvent) => {
    const scaleNum = state.selectedTool === 'zoom-in' ? 0.8 : state.selectedTool === 'zoom-out' ? 1.2 : 1;
    const canvasEl = document.getElementById('standard-canvas');
    const [mx, my] = [
      e.clientX - canvasEl.getBoundingClientRect().left,
      e.clientY - canvasEl.getBoundingClientRect().top
    ];
    let newMat;

    newMat = compose(matrix, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum)));
    newMat = compose([newMat, translate(-mx, -my)]);
    if ((newMat.a < 0.1 && state.selectedTool === 'zoom-in') || (newMat.a > 5 && state.selectedTool === 'zoom-out')) {
      return;
    }

    changeMatrix({...newMat});
  };

  const zoomViaArea = (area: {x: number; y: number; w: number; h: number}) => {
    let newMat = compose([matrix, translate(+area.x, +area.y)]);
    const canvasEl = document.getElementById('standard-canvas');
    let scaleN: number;
    if (area.w > area.h) {
      scaleN = area.w > 0 ? area.w / canvasEl.getBoundingClientRect().width : 1;
      newMat = compose([newMat, scale(scaleN, scaleN)]);
    } else {
      scaleN = area.h > 0 ? area.h / canvasEl.getBoundingClientRect().height : 1;
      newMat = compose([newMat, scale(scaleN, scaleN)]);
    }

    const newWidthAndHeight = applyToPoint(inverse({...newMat, e: 0, f: 0}), {
      x: imageDimensions?.naturalWidth,
      y: imageDimensions?.naturalHeight
    });
    const diffH = canvasEl.getBoundingClientRect().height - newWidthAndHeight.y;
    if (diffH > 0) {
      newMat = compose([newMat, translate(0, -diffH / 2 - newMat.f)]);
    }
    const diffW = canvasEl.getBoundingClientRect().width - newWidthAndHeight.x;
    if (diffW > 0) {
      newMat = compose([newMat, translate(-diffW / 2 - newMat.e, 0)]);
    }

    if (newMat.a > 5 || newMat.a < 0.1) return;
    changeMatrix(newMat);
  };

  const checkCollision = (area: {x: number; y: number; w: number; h: number}) => {
    const backgroundEl = document.getElementById('layer-wrapper');
    const {height, width} = backgroundEl.getBoundingClientRect();
    const ratioStart = {x: area.x / width, y: area.y / height};
    const ratioEnd = {x: (area.x + area.w) / width, y: (area.y + area.h) / height};
    const isInRange = (value: number, start: number, end: number) => {
      return value >= start && value <= end;
    };

    const isPointInRange = (x: number, y: number) => {
      return isInRange(x, ratioStart.x, ratioEnd.x) && isInRange(y, ratioStart.y, ratioEnd.y);
    };

    const matchingRegions = state.regions.filter((item) => {
      if (item.type === 'point') {
        return isPointInRange(item.x, item.y);
      } else if (item.type === 'box' || item.type === 'port') {
        const xInRange = isPointInRange(item.x, item.y);
        const xEndInRange = isPointInRange(item.x + item.w, item.y + item.h);
        return xInRange && xEndInRange;
      } else if (item.type === 'expanding-line' && item.points) {
        return item.points.every((point) => isPointInRange(point.x, point.y));
      }
      return false;
    });
    dispatchToReducer({type: 'SELECT_REGIONS', regions: matchingRegions});
  };

  return (
    <ProcessImageViewerContainer>
      <FormToolBox />
      <RemoveScrollWrapper style={{width: '100%', height: '100%'}}>
        <StandardCanvas ref={canvasEl} id="standard-canvas" />
        <LayerWrapper imagePosition={imagePosition} checkCollision={checkCollision}>
          <ImageLayer image={images} onLoad={onImageLoaded} />
          {imageLoaded && (
            <SelectedAnnotationLayer
              imagePosition={imagePosition}
              key="regionSelectAndTransformBoxes"
              projectRegionBox={projectRegionBox}
            />
          )}
          {imageLoaded && (
            <>
              <AnnotationLayer imagePosition={imagePosition} />
              {state?.cfg?.isDisplayDataValues && imageLoaded && (
                <>
                  <DataLayer
                    imageDimensions={imageDimensions}
                    imagePosition={imagePosition}
                    regions={regions}
                    cfg={state?.cfg}
                    tableLatestTagHandler={latestTagHandlerForTable}
                    chartLatestTagHandler={latestTagHandlerForChart}
                    tableLatestNodeHandler={latestNodeHandlerForTable}
                    chartLatestNodeHandler={latestNodeHandlerForChart}
                    dispatchToReducer={dispatchToReducer}
                  />
                  <ConnectionLineLayer
                    imagePosition={imagePosition}
                    regions={regions}
                    dispatchToReducer={dispatchToReducer}
                  />
                </>
              )}
            </>
          )}
        </LayerWrapper>
        {(selectedTool === 'zoom-in' || selectedTool === 'zoom-out') && (
          <ZoomLayer zoomViaPoint={zoomToPoint} zoomViaArea={zoomViaArea} />
        )}
      </RemoveScrollWrapper>
    </ProcessImageViewerContainer>
  );
};
