import React, {ChangeEvent, ReactElement} from 'react';
import {CheckboxForm} from 'components/forms';

type IProps = {
  flattenKeys: string;
  active: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
};

/**
 * DefaultTable 내부에서 checkbox 변경
 * @param flattenKeys
 * @param active
 * @param onChange
 * @constructor
 */
function ActiveCheckbox({flattenKeys, active, onChange}: IProps): ReactElement {
  return <CheckboxForm name={flattenKeys} title="active" checked={active} onChange={onChange} />;
}

export default ActiveCheckbox;
