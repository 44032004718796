import styled from 'styled-components';

const Divider = styled.div`
  background-color: #acbcc0;
  width: 1px;
  margin: 0 10px;
  height: 66%;
`;

function GroupDivider() {
  return <Divider></Divider>;
}

export default GroupDivider;
