import React, {useContext, useState} from 'react';
import {AudioRecorder, useAudioRecorder} from 'react-audio-voice-recorder';
import styled from 'styled-components';
import VoiceModeButton, {UiModeTypes} from 'components/menu/copilot/VoiceModeButton';
import useApi from 'api/useApi';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  .audio-recorder {
    background-color: #f8f8f8;
    box-shadow: none;
    width: 285px;

    .audio-recorder-mic {
      display: none;
    }

    .audio-recorder-timer {
      display: flex;
    }

    .audio-recorder-visualizer {
      &.display-none {
        //border-top: dashed #f76565;
        border-top: dashed #a0c6ff;
        border-width: 1px;
        opacity: 50%;
        margin-right: 34px;
      }
    }

    &.recording {
      background-color: #f8f8f8;
      width: 285px;

      .audio-recorder-timer {
      }

      .audio-recorder-visualizer {
        canvas {
          width: 204px;
          height: 28px;
        }
      }
    }

    .audio-recorder-options {
      display: none;
    }
  }

  .send-button {
    margin-top: 4px;
  }
`;

type IProps = {
  onCompleteRecord(message: string, fileId: string): void;
  onRecording(record: boolean): void;
};

type IVoiceResponse = {
  file_id: string;
  text: string;
};
function VoiceCommand({onCompleteRecord, onRecording}: IProps) {
  const api = useApi();
  const {setFileId} = useContext(CopilotContext);
  // const {showRemoteTooltip} = useContext(CommonContext);
  const recorderControls = useAudioRecorder();
  const [mode, setMode] = useState<UiModeTypes>('record');

  const onRecordingComplete = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob);
    const response = await api.post<IVoiceResponse>('/gpt/recognize_voice', formData, {type: 'binary'});
    // console.log('>>>>>sadfasd', response);
    // console.log(response.text);
    // console.log(typeof response.text);
    onCompleteRecord(response.text, response.file_id);
    onRecording(false);
    setMode('record');
    setFileId(response.file_id);
  };

  const handleStartRecording = () => {
    recorderControls.startRecording();
    onRecording(true);
    setMode('stop');
  };

  const handleStopRecording = () => {
    recorderControls.stopRecording();
    setMode('loading');
  };

  const onClick = () => {
    if (mode === `record`) {
      handleStartRecording();
    } else if (mode === `stop`) {
      handleStopRecording();
    }
  };

  return (
    <Container>
      <AudioRecorder
        showVisualizer={true}
        onRecordingComplete={onRecordingComplete}
        recorderControls={recorderControls}
      />
      <VoiceModeButton mode={mode} onClick={onClick} />
    </Container>
  );
}

export default VoiceCommand;
