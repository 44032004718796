import {ReactElement, useRef} from 'react';
import {
  IHmbConfig,
  IHmbContent,
  IHmbResult,
  IHmbStatus,
  ISelectedStream,
  IStreamInfo,
  ITableData
} from 'components/pc/widgets/hmb/type';
import {HotTable} from '@handsontable/react';
import {registerAllModules} from 'handsontable/registry';
import styled from 'styled-components';
import 'handsontable/dist/handsontable.full.min.css';
import useHmbTableHandler from 'components/pc/widgets/hmb/useHmbTableHandler';

const Container = styled.div`
  cursor: default;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;

  textarea {
    text-align: right;
  }
  .arrow-up-green {
    right: 0;
    width: 0;
    height: 0;
    float: right;
    position: relative;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid #bbb;
    margin-top: 6px;
  }
  .arrow-down-green {
    right: 0;
    width: 0;
    height: 0;
    float: right;
    position: relative;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #bbb;
    margin-top: 6px;
  }
  .htMiddle {
    transition:
      color 1.2s ease-out,
      opacity 1.2s ease-out;
  }

  .update-color {
    color: #32499a !important;
    font-weight: 500;
    opacity: 0.5;
    transition:
      color 0.4s ease,
      opacity 0.4s ease !important;
  }
`;
const LoadingMessage = styled.div`
  margin: auto;
`;

type IProps = {
  streamInfo: IStreamInfo;
  content: IHmbContent;
  tableData: ITableData;
  config: IHmbConfig;
  status: IHmbStatus;
  hmbResults: IHmbResult;
  onChangeConfig(cfg: IHmbConfig, action?: string): void;
  onChangeContent(ctt: IHmbContent, action?: string): void;
  onChangeStream(newStreamList: ISelectedStream[]): void;
};

registerAllModules();

function HmbTable({
  content,
  streamInfo,
  tableData,
  config,
  status,
  hmbResults,
  onChangeContent,
  onChangeConfig,
  onChangeStream
}: IProps): ReactElement {
  const hmbRef = useRef(null);

  const {beforeRemoveCol, afterOnCellMouseUp, afterChange, cells, hmbContextMenu} = useHmbTableHandler(
    content,
    streamInfo,
    tableData,
    config,
    status,
    hmbResults,
    hmbRef,
    onChangeContent,
    onChangeConfig,
    onChangeStream
  );

  if (!tableData) return <LoadingMessage>Table Data Loading ... </LoadingMessage>;
  if (!streamInfo?.streamList) return <LoadingMessage>Stream List Loading ... </LoadingMessage>;

  return (
    <Container className="nowheel">
      <HotTable
        height="100%"
        width="100%"
        colWidths={(index) => {
          if (index === 0) {
            return 180;
          } else if (index === 1) {
            return 100;
          } else {
            return 150;
          }
        }}
        ref={hmbRef}
        data={tableData}
        autoWrapCol
        autoWrapRow={false}
        fixedColumnsStart={2}
        fixedRowsTop={1}
        licenseKey="non-commercial-and-evaluation"
        readOnly={true}
        contextMenu={hmbContextMenu}
        cells={cells}
        beforeRemoveCol={beforeRemoveCol}
        afterOnCellMouseUp={afterOnCellMouseUp}
        afterChange={afterChange}
      ></HotTable>
    </Container>
  );
}

export default HmbTable;
