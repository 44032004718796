import {memo, useContext} from 'react';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';
import styled from 'styled-components';
import {toDateFormat} from 'utils/commons';

const ValueContainer = styled.div`
  text-align: right;
`;

const HeaderContainer = styled.div`
  text-align: center;
`;

const TimeStampContainer = styled.div`
  text-align: center;
`;

type IProps = {
  TD?: HTMLTableCellElement;
  col?: number;
  row?: number;
  value?: string | null;
  timestampCol?: number;
  headerRow?: number;
};

function TimestampDataRenderer(props: IProps) {
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  // the available renderer-related props are:
  // - `row` (row index)
  // - `col` (column index)
  // - `prop` (column property name)
  // - `TD` (the HTML cell element)
  // - `cellProperties` (the `cellProperties` object for the edited cell)
  /**
   * 구버전
   */
  if (!props?.timestampCol && !props?.headerRow) {
    if (props?.col > 0) {
      return (
        <ValueContainer>
          {props?.value && toSignificantDigit(props?.value, globalSettings?.significantDigit)}
        </ValueContainer>
      );
    } else {
      return (
        <TimeStampContainer>
          {props?.value && toDateFormat(Number(props?.value), 'YYYY-MM-DD HH:mm:ss')}
        </TimeStampContainer>
      );
    }
  }

  /**
   * 신버전
   */
  if (props?.headerRow >= props?.row) {
    return <HeaderContainer>{props?.value}</HeaderContainer>;
  } else if (props?.col === props?.timestampCol) {
    return (
      <TimeStampContainer>
        {props?.value && toDateFormat(Number(props?.value), 'YYYY-MM-DD HH:mm:ss')}
      </TimeStampContainer>
    );
  } else {
    return (
      <ValueContainer>
        {props?.value && toSignificantDigit(props?.value, globalSettings?.significantDigit)}
      </ValueContainer>
    );
  }
}

export default TimestampDataRenderer;

// export default memo(TimestampDataRenderer, (prevProps, nextProps) => {
//   return prevProps.value === nextProps.value;
// });
