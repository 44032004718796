import {ReactElement} from 'react';
import {getImage} from 'assets/images/svg-image';
import styled from 'styled-components';
import classNames from 'classnames';

const Container = styled.div`
  display: flex;
  height: 18px;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 14px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 1em;
  //color: #525f7f;
  color: #575660;
`;

const CloseButton = styled.img`
  margin-left: auto;
  cursor: pointer;
  &.disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

type IProps = {
  title?: string;
  disabled?: 'disabled' | 'enabled';
  onClose(): void;
};

function WidgetConfigLayerHeader({title, onClose, disabled}: IProps): ReactElement {
  return (
    <Container>
      <Title>{title}</Title>
      <CloseButton
        src={getImage('close')}
        className={classNames('close-button', disabled)}
        alt="close button"
        onClick={disabled && disabled === 'disabled' ? null : onClose}
      />
    </Container>
  );
}

export default WidgetConfigLayerHeader;
