/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/gltf/VesselWithBoot/VesselWithBoot.gltf
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function VesselWithBoot() {
  const {nodes, materials} = useGltfCustom('/gltf/VesselWithBoot/VesselWithBoot.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._VesselWithBoot_1.geometry}
        material={materials.Simacro_VesselWithBoot}
        position={[17, 19.761, -31.15]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
      />
      <mesh
        geometry={nodes._VesselWithBoot_1.geometry}
        material={materials.Simacro_VesselWithBoot}
        position={[-11.8, 17.9, 30]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        scale={0.9}
      />
      <mesh
        geometry={nodes._VesselWithBoot_1.geometry}
        material={materials.Simacro_VesselWithBoot}
        position={[-19.7, 19.7, -34.8]}
        rotation={[0, 3.15, -1.571]}
      />
      <mesh
        geometry={nodes._VesselWithBoot_1.geometry}
        material={materials.Simacro_VesselWithBoot}
        position={[-109, 16.5, -38]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        scale={0.75}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/VesselWithBoot/VesselWithBoot.gltf');
