import dayjs from 'dayjs';
import {SwitchForm} from 'components/forms';
import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const UpdateTimeDisplay = styled.div`
  font-size: 14px;
  color: #888;
  align-self: center;
  margin-right: 10px;
  flex-shrink: 0;
`;

const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #98a9d5;

  &.active {
    color: #6287e8;
  }
`;

type IProps = {
  id: string;
  updateTime: number;
  isApiCallFail?: boolean;
  isLiveUpdate?: boolean;
  onChangeLiveUpdate(flag: boolean): void;
};

function WidgetLiveUpdateStatus({id, updateTime, isApiCallFail, isLiveUpdate, onChangeLiveUpdate}: IProps) {
  return (
    <>
      <UpdateTimeDisplay>
        {'Last updated '}
        {updateTime !== 0 && updateTime && dayjs(updateTime).format('YYYY-MM-DD HH:mm:ss')}
      </UpdateTimeDisplay>
      <SwitchForm
        name={'weather-widget-live-switch-' + id}
        id={'weather-widget-live-switch-' + id}
        label={<SwitchLabel className={classnames(isLiveUpdate && 'active')}>Live</SwitchLabel>}
        onChange={() => onChangeLiveUpdate(!isLiveUpdate)}
        checked={isLiveUpdate}
        disabled={isApiCallFail}
        // checkedValue={true}
        // uncheckedValue={false}
      />
    </>
  );
}

export default WidgetLiveUpdateStatus;
