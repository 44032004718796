import React, {useContext} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

function useMetaPfdMouseEvents(imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation}) {
  const {dispatchToReducer} = useContext(MetaPfdContext);

  const iw = imagePosition?.bottomRight.x - imagePosition?.topLeft.x;
  const ih = imagePosition?.bottomRight.y - imagePosition?.topLeft.y;

  const onMouseDown = (e: React.MouseEvent, fnc: () => void) => {
    e.stopPropagation();
    const c = document.getElementById('layer-wrapper');
    // if (!containerRef.current) return;
    fnc();

    const onMouseMove = (e: MouseEvent): void => {
      const currentX = e.clientX - c.getBoundingClientRect().left;
      const currentY = e.clientY - c.getBoundingClientRect().top;
      dispatchToReducer({type: 'MOUSE_MOVE', x: currentX / iw, y: currentY / ih});
    };

    const onMouseUp = (e: MouseEvent) => {
      e.stopPropagation();
      document.removeEventListener('mousemove', onMouseMove);
      const currentX = e.clientX - c.getBoundingClientRect().left;
      const currentY = e.clientY - c.getBoundingClientRect().top;
      console.log(e);
      dispatchToReducer({type: 'MOUSE_UP', x: currentX / iw, y: currentY / ih});
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp, {once: true});
    e.preventDefault();
  };

  return {onMouseDown};
}

export default useMetaPfdMouseEvents;
