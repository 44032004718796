import {ForwardedRef, forwardRef, PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ReactFlowStage = forwardRef(function ReactFlowStageLayout(
  {children}: PropsWithChildren,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  return <Container ref={ref}>{children}</Container>;
});

ReactFlowStage.displayName = 'ReactFlowStage';
export default ReactFlowStage;
