import {MouseEvent, PropsWithChildren, ReactElement} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import {BasicSpinner} from 'components/common';
import styled from 'styled-components';
import {Button} from 'components/forms';

// Action Panel 내부의 Select Nodes 버튼, 라벨
const Container = styled(Button)`
  white-space: nowrap;

  .fa-tags {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.loading {
    background-color: #565b60;
    color: #999c9f;
    cursor: default;

    .fa-tags {
      opacity: 0;
    }
  }
`;
const SelectNodeButtonLabel = styled.span`
  margin-left: 6px;
`;

type IProps = PropsWithChildren & {
  width?: number | string;
  height?: number | string;
  isLoading?: boolean;
  onClick(e: MouseEvent): void;
};

function NodesSelectButton({children, width = 'auto', height = 'auto', isLoading, onClick}: IProps): ReactElement {
  return (
    <Container
      variant="dark"
      width={width}
      height={height}
      className={classNames({loading: isLoading})}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTags} color="#aaa" size="lg" />
      <BasicSpinner isShow={isLoading} margin="0" size="sm" position="center-left" type="overlay" />
      <SelectNodeButtonLabel>{children || 'Select Nodes'}</SelectNodeButtonLabel>
    </Container>
  );
}

export default NodesSelectButton;
