import React, {ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  height: 65px;
  z-index: 1;

  .create-btn {
    margin-top: 5px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    backdrop-filter: blur(5px);
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      margin-left: 5px;
      font-size: 0.7em;
      color: rgba(255, 255, 255, 0.28);
    }
  }
`;

type IProps = {
  main: string;
  sub?: string;
  description: string;
  children?: ReactElement;
};

function ActionMenuHeader({children, main, sub, description}: IProps): ReactElement {
  return (
    <Container>
      <h2>
        <span>
          {main}
          {sub && <small>{sub}</small>}
        </span>
        {children}
      </h2>
      <h3>{description}</h3>
    </Container>
  );
}

export default ActionMenuHeader;
