import styled from 'styled-components';
import LoginScreenHeader from 'components/auth/login/parts/LoginScreenHeader';
import React, {useEffect, useState} from 'react';
import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import {Button} from 'components/forms';
import LoginInputComponent from 'components/auth/login/LoginInput';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {resetPasswordRequest, verifyTokenRequest} from 'components/auth/login/login-api-functions';
import SHA256 from 'crypto-js/sha256';
import {LoginConst} from 'components/auth/login/const';
import LoginScreenContainer from 'components/auth/login/parts/LoginScreenContainer';
import LoginScreenInputContainer from 'components/auth/login/parts/LoginScreenInputContainer';

const PassWordValidationMessageContainer = styled.div`
  position: absolute;
  top: 100px;
  font-size: 12px;
  color: red;
`;

function PasswordReset() {
  const passwordResetState = useState<ILoginInputState>(LoginConst.passwordResetInitialState);
  const [passwordReset] = passwordResetState;
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [verified, setVerified] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isPasswordAndConfirmPasswordDiff = passwordReset?.password?.value !== passwordReset?.confirmPassword?.value;
  const isPasswordResetButtonDisabled =
    !passwordReset?.password?.value || !passwordReset?.confirmPassword?.value || !verified;

  const navigate = useNavigate();

  useEffect(() => {
    verifyTokenRequest(token).then((valid) => {
      setVerified(valid);
    });
  }, []);

  const onClickResetPasswordButton = () => {
    if (isPasswordAndConfirmPasswordDiff) {
      setPasswordErrorMessage('The passwords do not match. Please try again.');
    } else {
      setPasswordErrorMessage('');
      resetPasswordRequest({token, password: SHA256(passwordReset?.password?.value).toString()}).then((res) => {
        if (res?.body?.success) {
          navigate('/login/password/complete');
        } else {
          setPasswordErrorMessage('Error occur while reset password');
        }
      });
    }
  };

  const onKeyPressDownEnter = () => {
    if (isPasswordResetButtonDisabled) {
      setPasswordErrorMessage('The passwords do not match. Please try again.');
    } else {
      onClickResetPasswordButton();
    }
  };

  return (
    <LoginScreenContainer>
      <LoginScreenHeader title="New Password" />
      <LoginScreenInputContainer>
        <LoginInputComponent loginInputState={passwordResetState} onKeyPressDownEnter={onKeyPressDownEnter}>
          {(!passwordErrorMessage || !verified) && (
            <PassWordValidationMessageContainer>
              {!verified ? "The link's validity period has expired." : passwordErrorMessage ? passwordErrorMessage : ''}
            </PassWordValidationMessageContainer>
          )}
        </LoginInputComponent>
        <Button
          height={40}
          disabled={isPasswordResetButtonDisabled || isPasswordAndConfirmPasswordDiff}
          onClick={onClickResetPasswordButton}
        >
          Reset Password
        </Button>
      </LoginScreenInputContainer>
    </LoginScreenContainer>
  );
}
export default PasswordReset;
