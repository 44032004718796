import React, {ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  min-height: 50px;
  flex-shrink: 0;
`;

type IProps = {
  children?: ReactNode;
  height?: number;
};
function PythonEditorBodyTop({children, height}: IProps) {
  return <Container style={{height}}>{children}</Container>;
}

export default PythonEditorBodyTop;
