import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useContext, useState} from 'react';
import {IHmbConfig} from 'components/pc/widgets/hmb/type';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import {Button, SelectForm, TextForm} from 'components/forms';
import HmbHiddenComponentWrapper from 'components/pc/widgets/hmb/HmbHiddenComponentWrapper';
import styled from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';
import {getUniqueKey} from 'utils/commons';
import {hbmWidgetUtilFunc} from 'components/pc/widgets/hmb/hmb-functions';
import FormRow from 'components/forms/FormRow';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import ModalFooter from 'components/common/modal/ModalFooter';
import {useReactFlow} from 'reactflow';

const Container = styled.div`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .switch-wrap {
    label {
      top: 1px;
    }
  }
`;

const TextFormWrap = styled.div`
  display: flex;
  width: 26%;
`;

type IProps = {
  config: IHmbConfig;
  id: string;
  hiddenFeatureState: [string[], Dispatch<SetStateAction<string[]>>];
  onChangeConfig(config: IHmbConfig, action?: string): void;
  closeConfig(): void;
};

function HmbSettingPanelRevision({config, id, hiddenFeatureState, onChangeConfig, closeConfig}: IProps): ReactElement {
  const {autoUpdate, updateIntervalUnit, updateIntervalVal, significantLevel, hiddenComponentList} = config;
  const [configTemp, setConfigTemp] = useState({
    autoUpdate,
    updateIntervalUnit,
    updateIntervalVal,
    hiddenComponentList,
    significantLevel
  });
  const [hiddenFeature, setHiddenFeature] = hiddenFeatureState;
  const [hiddenFeatureListTemp, setHiddenFeatureListTemp] = useState<string[]>(hiddenFeature);
  const {addToast} = useContext(CommonContext);
  const {setNodes} = useReactFlow();

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirmButton(),
    onCancel: () => onClickCancelButton()
  });

  const onChangeTimeIntervalVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setConfigTemp((prev) => ({...prev, updateIntervalVal: target.value}));
  };

  const onChangeTimeIntervalUnit = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    Number(target.value);
    setConfigTemp((prev) => ({...prev, updateIntervalUnit: Number(target.value)}));
  };

  const resetHiddenComponentsList = () => {
    setHiddenFeatureListTemp([]);
  };

  const onClickCancelButton = () => {
    closeConfig?.();
  };

  const onClickConfirmButton = () => {
    const {autoUpdate, updateIntervalUnit, updateIntervalVal, hiddenComponentList, significantLevel} = configTemp;
    let dic = {};
    let action = '';
    if (
      autoUpdate !== config.autoUpdate ||
      updateIntervalUnit !== config.updateIntervalUnit ||
      updateIntervalVal !== config.updateIntervalVal
    ) {
      if (Number(updateIntervalVal) > 0) {
        dic = {...dic, autoUpdate, updateIntervalUnit, updateIntervalVal};
        action = 'updateTimer';
      } else {
        addToast({id: getUniqueKey(), text: 'Update interval value must be greater than 0'});
      }
    }
    if (
      significantLevel !== config.significantLevel ||
      !hbmWidgetUtilFunc.arraysAreEqual(hiddenComponentList, config.hiddenComponentList)
    ) {
      dic = {...dic, significantLevel, hiddenComponentList};
    }
    onChangeConfig({...config, ...dic}, action);
    closeConfig();
    setHiddenFeature(hiddenFeatureListTemp);
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {
                  ...node.data.metaData,
                  hiddenFeature: hiddenFeatureListTemp,
                  config: {...config, ...dic}
                }
              }
            }
          : node
      )
    );
  };

  const removeHiddenComponent = (hiddenComponent: string) => {
    if (!hiddenComponent) return;
    const newHiddenComponentList = hiddenFeatureListTemp.filter((item) => item !== hiddenComponent);
    setHiddenFeatureListTemp(newHiddenComponentList);
  };

  return (
    <Container className="hmb-setting-panel">
      <FormRow header="Update interval">
        <TextFormWrap className="text-form-wrap">
          <TextForm
            type="number"
            name="time-value"
            value={configTemp.updateIntervalVal}
            onChange={(e) => onChangeTimeIntervalVal(e)}
          />
        </TextFormWrap>
        <SelectForm
          name={'time-unit'}
          options={TimeUnitOptions}
          value={configTemp.updateIntervalUnit}
          onChange={(e) => onChangeTimeIntervalUnit(e)}
        />
      </FormRow>
      <FormRow header="Hidden components">
        <Button onClick={() => resetHiddenComponentsList()}>Reset</Button>
      </FormRow>
      <FormRow type="multiline">
        <HmbHiddenComponentWrapper
          hiddenComponentList={hiddenFeatureListTemp}
          removeHiddenComponent={removeHiddenComponent}
        />
      </FormRow>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" onClick={() => onClickConfirmButton()}>
          Confirm
        </Button>
        <Button className="modal-btn" variant="cancel" onClick={() => onClickCancelButton()}>
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default HmbSettingPanelRevision;
