import {ReactElement} from 'react';
import {HotTable} from '@handsontable/react';
import {refineTableData} from 'components/pc/widgets/blockTable/block-functions';
import HierarchyDataRenderer from 'components/pc/widgets/blockTable/HierarchyDataRenderer';
import styled from 'styled-components';

type IProps = {
  tableData: string[][];
};

export type IMergeCells = {
  row: number;
  col: number;
  rowspan: number;
  colspan: number;
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .handsontable {
    .wtHolder {
      &::-webkit-scrollbar {
        background: rgba(0, 0, 0, 0.08);
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
      }
    }
    td {
      padding: 0;
      > div {
        width: 100%;
        height: 100%;
      }
      &:before {
        pointer-events: none;
      }
    }
  }
`;

function BlockTable({tableData}: IProps): ReactElement {
  const {refinedTableData, cellInfo, mergeCellsInfo} = refineTableData(tableData) || {
    refinedTableData: [],
    cellInfo: []
  };

  return (
    <Wrapper>
      <HotTable
        height="100%"
        colWidths={200}
        readOnly={true}
        data={refinedTableData}
        // mergeCells={mergeCellsInfo}
        licenseKey="non-commercial-and-evaluation"
      >
        <HierarchyDataRenderer hot-renderer cellInfo={cellInfo} />
      </HotTable>
    </Wrapper>
  );
}

export default BlockTable;
