import React, {useState, useEffect} from 'react';
import {LoginConst} from 'components/auth/login/const';
import LoginInputComponent from 'components/auth/login/LoginInput';
import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import {areAllFieldsValid} from 'components/auth/login/login-api-functions';
import styled from 'styled-components';
import {IEditUserInfo} from 'components/auth/management/type';
import AdminResetPasswordButton from 'components/auth/management/parts/AdminResetPasswordButton';
import AdminActivateUserSwitchForm from 'components/auth/management/parts/AdminActivateUserSwitchForm';

type IProps = {
  setIsCreateSuccess(isCreateSuccess: boolean): void;
  setUserName?(username: string): void;
  setFirstName(firstName: string): void;
  setLastName(lastName: string): void;
  setEmail(email: string): void;
  setIsActive(isActive: boolean): void;
  isEdit: boolean;
  currentInfo?: IEditUserInfo;
};

const Container = styled.div`
  input.disabled,
  textarea:disabled {
    cursor: not-allowed;
    filter: brightness(0.92);
  }
`;

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Setting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
`;

function ManagementRegister({
  setIsCreateSuccess,
  setUserName,
  setFirstName,
  setLastName,
  setEmail,
  setIsActive,
  isEdit,
  currentInfo
}: IProps) {
  // TODO: 아래 initialEditInfo 상수 코드 정리 필요
  const initialEditInfo = {
    userName: {
      value: currentInfo?.username,
      type: 'id',
      valid: true,
      validLength: true,
      placeholder: currentInfo?.username,
      warningMessage: 'Invalid user Id',
      warningMessageLength: 'User ID must be at least 4 characters long.',
      validateOnChange: true,
      isDisabled: true
    },
    firstName: {
      value: currentInfo?.firstName,
      valid: true,
      placeholder: currentInfo?.firstName,
      warningMessage: 'Invalid first name',
      validateOnChange: true
    },
    lastName: {
      value: currentInfo?.lastName,
      valid: true,
      placeholder: currentInfo?.lastName,
      warningMessage: 'Invalid last name',
      validateOnChange: true
    },
    email: {
      value: currentInfo?.email,
      type: 'email',
      valid: true,
      placeholder: currentInfo?.email,
      warningMessage: 'Invalid email address',
      validateOnChange: true
    }
  } as ILoginInputState;

  let managementRegisterInfoState = useState<ILoginInputState>(
    isEdit ? initialEditInfo : LoginConst.managementRegisterInitialState
  );

  const [managementRegisterInfo] = managementRegisterInfoState;

  function setInfo() {
    if (
      areAllFieldsValid(managementRegisterInfo) &&
      Boolean(
        managementRegisterInfo?.userName?.value &&
          managementRegisterInfo?.email?.value &&
          managementRegisterInfo?.firstName?.value &&
          managementRegisterInfo?.lastName?.value
      )
    ) {
      setUserName?.(managementRegisterInfo?.userName?.value);
      setFirstName(managementRegisterInfo?.firstName?.value);
      setLastName(managementRegisterInfo?.lastName?.value);
      setEmail(managementRegisterInfo?.email?.value);
      setIsCreateSuccess(true);
    } else {
      setIsCreateSuccess(false);
    }
  }

  const onKeyPressDownEnter = () => {
    setInfo();
  };

  useEffect(() => {
    setInfo();
  }, [managementRegisterInfo]);

  return (
    <Container>
      <LoginInputComponent
        loginInputState={managementRegisterInfoState}
        isInputBoxWidthFull={true}
        validCheckIcon={!isEdit}
        onKeyPressDownEnter={onKeyPressDownEnter}
      ></LoginInputComponent>
      {isEdit && (
        <Wrapper>
          <Setting>
            <div>Reset Password</div>
            <AdminResetPasswordButton email={currentInfo?.email} />
          </Setting>
          <Setting>
            <div>User Activate</div>
            <AdminActivateUserSwitchForm
              username={currentInfo?.username}
              isActive={currentInfo?.is_active}
              setIsActive={setIsActive}
            />
          </Setting>
        </Wrapper>
      )}
    </Container>
  );
}

export default ManagementRegister;
