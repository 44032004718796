import React from 'react'
import { useGLTF } from '@react-three/drei'

export function VesselVertical() {
  const { nodes, materials } = useGLTF('./3D/VesselVertical/VesselVertical.gltf')
  return (
    <group dispose={null} scale={0.2}>
      <mesh geometry={nodes._VesselVertical_1.geometry} material={materials.Simacro_VesselVertical} position={[0,14,0]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={[0.511, 0.511, 0.526]} />
    </group>
  )
}

useGLTF.preload('./3D/VesselVertical/VesselVertical.gltf')
