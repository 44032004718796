import React, {memo, ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {
  WidgetActionPanel,
  WidgetBody,
  WidgetConfigLayer,
  WidgetContainer,
  WidgetHeader
} from 'components/pc/widgets/parts';
import DateTimeSelectBar from 'components/pc/widgets/timeseries/DateTimeSelectBar';
import {MessageSpinner, ResizeHandle} from 'components/common';
import useDraggable from 'utils/useDraggable';
import TimeSeriesTable from 'components/pc/widgets/timeseries/TimeSeriesTable';
import TimeSeriesChartContainer from 'components/pc/widgets/timeseries/TimeSeriesChartContainer';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import useTimeSeriesLoader from 'components/pc/widgets/timeseries/useTimeSeriesLoader';
import {ITimeSeriesWidgetData} from 'components/pc/types';
import {DateObject} from 'react-multi-date-picker';
import LightningChartProvider from 'components/pc/widgets/common/chart/LightningChartProvider';
import useLocalDatabase from 'components/pc/widgets/datasheet/useLocalDatabase';
import {INode} from 'api/data-types';
import dayjs from 'dayjs';
import NodeInfoModal from 'components/common/NodeInfoModal';
import {ChartTableArea} from 'components/pc/widgets/common/shared-styled-components';
import useFrameless from 'components/pc/widgets/common/useFrameless';
import TimeSeriesConfigs, {ITimeSeriesConfigs} from 'components/pc/widgets/timeseries/TimeSeriesConfigs';
import {colors} from 'common/colors';
import {IChartAttrs} from 'components/pc/widgets/common/chart/types';
import NodesSelectButton from 'components/pc/common/NodesSelectButton';

function TimeSeriesWidget({data, id, ...rest}: NodeProps): ReactElement {
  const {setNodes} = useReactFlow();

  const [isShowNodeInfoModal, setIsShowNodeInfoModal] = useState(false);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState<string[]>([]);

  // drag 관련 ref 설정
  const boundaryRef = useRef(null);
  const dragHandleRef = useRef(null);
  const defaultPosition = {x: 0, y: 250};
  const defaultPadding = {top: 60, right: 0, bottom: 0, left: 0};
  const [dateTimePosition, onMouseDownResizeHandle, setInitialChartSize] = useDraggable(
    boundaryRef,
    dragHandleRef,
    defaultPosition,
    defaultPadding
  );

  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);
  const loader = useTimeSeriesLoader(id);
  const {localDatabase, dynamicHierarchy} = useLocalDatabase(id);

  const {isFrameless, isWidgetMouseEnter, setIsFrameless, onMouseEnter, onMouseLeave, onFrameless} = useFrameless();
  const [isShowConfig, setIsShowConfig] = useState(false);
  const [configs, setConfigs] = useState<ITimeSeriesConfigs>({
    chartBackground: colors.chart.background,
    chartTextColor: colors.chart.textColor
  });

  // File 에 저장된 데이터로 최초 데이터 세팅
  useEffect(() => {
    // console.log('time series widget >> data?.metaData', data?.metaData);
    if (data?.metaData) {
      const {tags, range, isLive, nodes, chartHeight, series, stackInfo, frameless, chartBackground, chartTextColor} =
        data.metaData as ITimeSeriesWidgetData;

      setInitialChartSize({x: 0, y: chartHeight || 0});
      setIsFrameless(Boolean(frameless));
      setConfigs({
        chartBackground: chartBackground || colors.chart.background,
        chartTextColor: chartTextColor || colors.chart.textColor
      });

      if (isLive) {
        // live 라면 nodes, isLive, range 정보를 가지고 현시점 데이터를 로드 함
        const now = new DateObject();
        const end = now.unix;
        const start = now.subtract(10, 'minutes').unix;
        loader.load({nodeList: nodes || tags, isLive, range: [start, end], savedSeries: series});
      } else if (series) {
        // live 가 아니라면 저장된 데이터를 이용해 화면 표시
        loader.setIsLive(isLive);
        loader.setRange(range);
        loader.setNodes(nodes || tags);
        loader.setSeries(series || []);
        loader.setStackInfo(stackInfo);
      } else {
        // live 도 아니고 저장된 series 도 없다면 이전 버전의 File
        loader.load({nodeList: nodes || tags, isLive, range});
      }
      /**
       * MIGRATION-4
       * 호환성을 유지할 필요가 적으므로 제거함 - 2024-09-08
       */
    }
  }, []); // data.selected 가 변경되는 경우에도 재호출 되므로 현재는 빈배열로 처리하고 차후 처리 필요

  // 상태 변경에 의한 react flow 데이터 세팅
  useEffect(() => {
    const {nodes, range, isLive, series, stackInfo} = loader;
    const metaData = {
      nodes,
      range,
      isLive,
      series,
      chartHeight: dateTimePosition.y,
      stackInfo,
      frameless: isFrameless,
      chartBackground: configs.chartBackground,
      chartTextColor: configs.chartTextColor
    };
    setNodes((nodes) => nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node)));
  }, [id, dateTimePosition, loader.nodes, loader.range, loader.series, loader.stackInfo, isFrameless, configs]);

  useEffect(() => {
    return () => {
      loader.clearSeries();
      loader.stopLive();
    };
  }, []);

  /**
   * node selector 로부터 선택
   * @param checkedList
   */
  const onSelectNode = useCallback(
    (checkedList: string[]): void => {
      const parsed = checkedList.map((item) => JSON.parse(item));

      // console.log('>>> checkedList', checkedList, parsed);
      const localNodeData: INode[] = [];
      const server = [];
      // key_12345678_1234567890123 의 형식을 가지고 있다면 datasheet 로 판정
      const widgetKeyRegex = /^key_\d{8}_\d{13,}$/;
      // local database 를 조회하여 node 에 해당하는 데이터를 get_node_data_list 형태로 리턴
      parsed.forEach((node) => {
        const [database, nodeKey] = node;
        if (widgetKeyRegex.test(database)) {
          // node key(id) 를 받아 records 를 반환
          const getNodeData = (nodeKey: string): any[][] => {
            const sheetData = localDatabase.ref.current.dbData;
            const [fields] = sheetData;
            const nodeIndex = fields.findIndex((col) => col === nodeKey);
            const records = [];
            for (let i = 1; i < sheetData.length; ++i) {
              const row = sheetData?.[i];
              // timestamp 가 없으면 포함시키지 않음
              if (row?.[0]) {
                const timestamp = dayjs(row?.[0])?.unix();
                const value = Number(row?.[nodeIndex]);
                records.push([timestamp, value]);
              }
            }
            return records;
          };

          // node name 에 (unit) 형태가 포함되어 있으면 unit 으로 인식하고 추출
          const regex = /^([A-Za-z0-9_]+)\s*\(*([\w\s\/&;#-]+)\)*$/;
          const match = nodeKey.match(regex);

          localNodeData.push({
            database,
            node: [nodeKey],
            records: getNodeData(nodeKey),
            unit: match?.[2],
            description: localDatabase?.name
          });
        } else {
          server.push(node);
        }
      });
      const {range} = loader;
      loader.load({nodeList: server, isLive: false, range, localNodeData});
      // console.log('>> localDatabase', localDatabase, localNodeData);
    },
    [loader.range, localDatabase]
  );

  const onChangeNodeInfoModal = (bool: boolean, keys: string[]) => {
    setIsShowNodeInfoModal(bool);
    setSelectedNodeKeys(keys);
  };

  const onConfirmConfigs = (configs: ITimeSeriesConfigs, action?: 'updateTimer'): void => {
    setConfigs(configs);
    console.log('configs = ', configs);
    setIsShowConfig(false);
  };

  const onChangeChartAttrs = (attrs: IChartAttrs): void => {
    // console.log('attrs = ', attrs);
  };

  return (
    <WidgetContainer {...rest} data={data} type={data.type} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <WidgetHeader
        {...rest}
        type="TimeSeriesWidget"
        icon={data.icon}
        id={id}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'TimeSeriesWidget', titleData: [], data})}
        suffix="- Time Series"
        // hasDocking
        // docked={data.docked}
        frameless={isFrameless}
        isWidgetMouseEnter={isWidgetMouseEnter}
        onFrameless={onFrameless}
        onConfig={() => setIsShowConfig(true)}
      />
      {!isFrameless && (
        <WidgetActionPanel disabled={isShowNodeSelector || isShowNodeInfoModal || loader.isWidgetLoading}>
          <DateTimeSelectBar
            id={id}
            loader={loader}
            direction={boundaryRef.current?.clientHeight - dateTimePosition.y > dateTimePosition.y ? 'bottom' : 'top'}
          >
            <NodesSelectButton height={34} isLoading={loader?.isLoading} onClick={() => setIsShowNodeSelector(true)} />
          </DateTimeSelectBar>
        </WidgetActionPanel>
      )}
      <WidgetBody ref={boundaryRef} actionMenuHeight={60}>
        <LightningChartProvider>
          <TimeSeriesChartContainer
            docked={data.docked}
            background={configs.chartBackground}
            textColor={configs.chartTextColor}
            height={isFrameless ? '100%' : dateTimePosition.y}
            loader={loader}
            onChangeAttrs={onChangeChartAttrs}
          />
        </LightningChartProvider>
        {!isFrameless && (
          <ChartTableArea>
            <ResizeHandle ref={dragHandleRef} onMouseDown={onMouseDownResizeHandle} />
            <TimeSeriesTable onChangeNodeInfoModal={onChangeNodeInfoModal} loader={loader} />
            {isShowNodeSelector && (
              <NodeSelectorRevision
                title="Select Nodes"
                selectedNodes={loader.nodes}
                hierarchyInfos={[dynamicHierarchy]}
                onClose={() => setIsShowNodeSelector(false)}
                onSelect={onSelectNode}
              />
            )}
          </ChartTableArea>
        )}
        <MessageSpinner isShow={loader.isWidgetLoading} type="overlay" size="lg" />
        {isShowNodeInfoModal && (
          <NodeInfoModal
            loader={loader}
            selectedNodeKeys={selectedNodeKeys}
            onChangeNodeInfoModal={onChangeNodeInfoModal}
          />
        )}
      </WidgetBody>
      <WidgetConfigLayer title="Settings" isShow={isShowConfig} onClose={() => setIsShowConfig(false)}>
        <TimeSeriesConfigs configs={configs} onConfirm={onConfirmConfigs} onCancel={() => setIsShowConfig(false)} />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}

export default memo(TimeSeriesWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
