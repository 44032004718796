import styled from 'styled-components';
import {ReactNode} from 'react';

const InfoBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-height: 40px;
`;

const RowHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 120px;
  align-items: center;
  color: #aaa;
  font-size: 15px;
`;

const RowBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 10px;
`;

type IProps = {
  rowHeader?: ReactNode;
  children?: ReactNode;
};
function PropertyRow({rowHeader, children}: IProps) {
  return (
    <InfoBoxRow>
      <RowHeader>{rowHeader}</RowHeader>
      <RowBody>{children}</RowBody>
    </InfoBoxRow>
  );
}

export default PropertyRow;
