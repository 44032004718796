import styled from 'styled-components';
import {ReactNode} from 'react';

const HeaderContainer = styled.div`
  flex-shrink: 0;
  height: 30px;
  background-color: #e2e7e8;
  display: flex;
  align-items: center;
  cursor: move;
  z-index: 10;

  span {
    padding: 0 10px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #8a9496;
  }
`;
type IProps = {
  children?: ReactNode;
  title?: string;
};

function WidgetIconModeHeader({children, title}: IProps) {
  return (
    <HeaderContainer>
      <span>{title}</span>
      {children}
    </HeaderContainer>
  );
}

export default WidgetIconModeHeader;
