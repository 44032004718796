import {ChangeEvent, ReactElement} from 'react';
import RadioForm from 'components/forms/RadioForm';
import classNames from 'classnames';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  &.multiline {
    flex-wrap: wrap;
    line-height: 40px;
  }
`;

type RadioOption<T> = {[K in keyof T]: T[K] | unknown} & {
  disabled?: boolean;
  readOnly?: boolean;
};

type IProps<T> = {
  name: string;
  options: RadioOption<T>[];
  value: string | number | null | undefined;
  valueKey?: string;
  labelKey?: string;
  disabled?: boolean;
  gap?: number;
  type?: 'default' | 'multiline';
  onChange(e: ChangeEvent<HTMLInputElement>): void;
};

function RadioFormGroup<T>({
  name,
  onChange,
  value,
  valueKey = 'value',
  labelKey = 'label',
  disabled = false,
  type = 'default',
  gap = 0,
  options
}: IProps<T>): ReactElement {
  return (
    <Container className={classNames('common-radio-form-group', type)} style={{gap}}>
      {options.map((item: RadioOption<T>) => (
        <RadioForm
          name={name}
          key={item[valueKey as keyof T] as string}
          label={item[labelKey as keyof T] as string}
          id={item[valueKey as keyof T] as string}
          value={item[valueKey as keyof T] as string}
          checked={Boolean(value) && item[valueKey as keyof T] === value}
          onChange={onChange}
          disabled={disabled || Boolean(item.disabled)}
          readOnly={Boolean(item.readOnly)}
        />
      ))}
    </Container>
  );
}

export default RadioFormGroup;
