import {ReactElement, useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import {MessageSpinner} from 'components/common/index';

/**
 * 전체 화면을 가리는 spinner
 * @constructor
 */
function LoadingSpinner(): ReactElement {
  const {spinnerList} = useContext(CommonContext);

  const onClick = (): void => {
    console.log('spinner list', spinnerList);
  };
  return <MessageSpinner isShow={spinnerList.length > 0} onClick={onClick} />;
}

export default LoadingSpinner;
