import styled from 'styled-components';

const Container = styled.div`
  font-size: 32px;
  font-weight: 100;
`;

type IProps = {
  title: string;
  fontSize?: '32px' | '24px';
};

function LoginScreenHeader({title, fontSize}: IProps) {
  return <Container style={{fontSize}}>{title}</Container>;
}

export default LoginScreenHeader;
