import {MouseEvent, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import {CommonContext} from 'components/common/CommonProvider';
import {INodeNameMenuAction} from 'components/table/parts/NodeName';

const Container = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  > em {
    font-style: normal;
  }
`;
const NodePath = styled.span`
  color: #aaa;
  font-size: 0.9em;
`;

type IProps = {
  database: string;
  nodes: string[];
  hasContextMenu?: boolean;
  onMenu(action: INodeNameMenuAction): void;
  onChangeNodeInfoModal(bool: boolean, key: string[]): void;
};

function NodeNameDisplay({database, nodes, hasContextMenu, onMenu, onChangeNodeInfoModal}: IProps): ReactElement {
  const [createContextMenu] = useContextMenu();
  const {addNotice} = useContext(CommonContext);

  const [nodePath, setNodePath] = useState('');
  const [nodeName, setNodeName] = useState('');
  const ref = useRef(null);

  const onContextMenu = (event: MouseEvent): void => {
    if (!hasContextMenu) return;

    const list = [
      {
        label: 'Show As ...',
        value: 'add-widget',
        children: [
          {
            label: 'Spreadsheet Widget',
            value: 'spreadsheet-widget',
            callback() {
              const {pageX, pageY} = event;
              const {clientWidth} = ref.current;
              onMenu({type: 'SPREADSHEET', path: [database, ...nodes], x: pageX + clientWidth * 2, y: pageY + 50});
            }
          },
          {label: '3D Model Widget', value: '3d-model-widget', disabled: true}
        ]
      },
      /*{
        label: 'Get Tag',
        value: 'get-tag',
        callback() {
          console.log('===> get tag', nodeName);
        }
      },
      {
        label: 'Set Tag',
        value: 'set-tag',
        callback() {
          console.log('===> set tag', nodeName);
        }
      },*/
      {
        label: 'Copy Node ID',
        value: 'copy-node-id',
        callback() {
          navigator.clipboard
            .writeText(nodeName)
            .then(() => {
              addNotice({text: `Copied node id ${nodeName}`});
            })
            .catch((err) => {});
          console.log('===> copy node id', nodeName);
        }
      },
      /*{
        label: 'Paste Tag ID',
        value: 'paste-tag-id',
        callback() {
          console.log('===> paste tag id', nodeName);
        }
      },*/
      {
        label: 'Remove Node',
        value: 'remove-node',
        // disabled: true,
        callback() {
          onMenu({type: 'REMOVE', path: [database, ...nodes]});
          console.log('===> delete tag', database, nodeName, [database, ...nodes]);
        }
      },
      {
        label: 'Information',
        value: 'tag-information',
        callback() {
          onChangeNodeInfoModal(true, nodes);
        }
      }
    ];
    createContextMenu({event, list, title: nodeName});
  };

  useEffect(() => {
    const cloneKey = [...nodes];
    setNodeName(cloneKey.pop());
    setNodePath(cloneKey.join('-'));
  }, [nodes]);

  return (
    <Container ref={ref} title={nodes.join('-')} onContextMenu={onContextMenu}>
      {nodePath && <NodePath>{nodePath}-</NodePath>}
      <em>{nodeName}</em>
    </Container>
  );
}

export default NodeNameDisplay;
