import {useNodes, EdgeLabelRenderer} from 'reactflow';
import styled from 'styled-components';
import NodeInfo from 'components/pc/common/devTool/NodeInfo';

const Container = styled.div`
  pointer-events: none;
  font-family: monospace, sans-serif;
  font-size: 10px;
`;

export default function NodeInspector() {
  const nodes = useNodes();

  return (
    <EdgeLabelRenderer>
      <Container>
        {nodes.map((node) => {
          const x = node.positionAbsolute?.x || 0;
          const y = node.positionAbsolute?.y || 0;
          const width = node.width || 0;
          const height = node.height || 0;

          return (
            <NodeInfo
              key={node.id}
              id={node.id}
              selected={node.selected}
              type={node.type || 'default'}
              x={x}
              y={y}
              width={width}
              height={height}
              data={node.data}
              style={node?.style}
              zIndex={node?.zIndex}
            />
          );
        })}
      </Container>
    </EdgeLabelRenderer>
  );
}
