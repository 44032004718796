import {ReactElement, useState} from 'react';
import {NodeProps} from 'reactflow';
import {WidgetBody, WidgetConfigLayer, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';

function SampleWidget({data, id, ...rest}: NodeProps): ReactElement {
  const [isShowConfig, setIsShowConfig] = useState(false);

  const onConfig = (): void => {
    setIsShowConfig(!isShowConfig);
  };

  const onClose = (): void => {
    console.log('onClose');
  };

  const onSelect = () => {};

  return (
    <WidgetContainer {...rest} data={data} type="WebWidget">
      <WidgetHeader
        {...rest}
        type="WebWidget"
        icon={data.icon}
        id={id}
        title="Sample"
        onLock={() => {}}
        onConfig={onConfig}
        onClose={onClose}
      />
      <WidgetBody>
        <NodeSelectorRevision onClose={onClose} onSelect={onSelect} />
      </WidgetBody>
      <WidgetConfigLayer isShow={isShowConfig} onClose={() => setIsShowConfig(false)}>
        Config items
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}
export default SampleWidget;
