import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import {CellValue} from 'handsontable/common';
import Handsontable from 'handsontable';

export const spreadsheetUtilFnc = {
  getArr(row: number, col: number, initialData: unknown): unknown[][] {
    let arr = [];
    for (let i = 0; i < row; i++) {
      arr[i] = [];
      for (let j = 0; j < col; j++) {
        arr[i][j] = initialData;
      }
    }
    return arr as unknown[][];
  },
  deleteDuplicatesInSelectedRanges(ranges: number[]) {
    let selectedRows = new Set<number>();

    for (let i = 0; i < ranges.length; i += 2) {
      let start = Math.min(ranges[i], ranges[i + 1]);
      let end = Math.max(ranges[i], ranges[i + 1]);
      for (let j = start; j <= end; j++) {
        selectedRows.add(j);
      }
    }

    let selectedArray = Array.from(selectedRows)
      .sort((a, b) => a - b)
      .filter((item) => item > -1);

    let result = [];
    for (let i = 0; i < selectedArray.length; i++) {
      if (i === 0 || selectedArray[i] !== selectedArray[i - 1] + 1) {
        result.push(selectedArray[i]);
      }
      if (i === selectedArray.length - 1 || selectedArray[i] !== selectedArray[i + 1] - 1) {
        result.push(selectedArray[i]);
      }
    }

    return [result, selectedArray];
  },
  getFromToFromSelectedRangeArr(ranges: number[]) {
    const fromRow = Math.max(Math.min(ranges[0], ranges[2]), 0);
    const toRow = Math.max(ranges[0], ranges[2]);
    const fromCol = Math.max(Math.min(ranges[1], ranges[3]), 0);
    const toCol = Math.max(ranges[1], ranges[3]);
    return [fromRow, toRow, fromCol, toCol];
  }
};

export const dataSheetContextMenuFnc = {
  getTag(selectedCellTagInfos: ISpreadSheetCellInfo[]) {
    navigator.clipboard
      .writeText(JSON.stringify(selectedCellTagInfos[0]))
      .then(() => {})
      .catch(() => {});
  },

  async setTag(row: number, col: number, newCellInfo: ISpreadSheetCellInfo[][], setCellInfo) {
    const clipboardData = await navigator?.clipboard?.readText();
    newCellInfo[row][col] = JSON.parse(clipboardData);
    setCellInfo(newCellInfo);
  },

  async copyTag(selectedCellTagInfos: ISpreadSheetCellInfo[]) {
    navigator.clipboard
      .writeText(JSON.stringify(selectedCellTagInfos[0]))
      .then(() => {})
      .catch(() => {});
  },

  async pasteTagId(
    row: number,
    col: number,
    newData: CellValue[][],
    newCellInfo: ISpreadSheetCellInfo[][],
    setSpreadsheetMetaData,
    setCellInfo
  ) {
    const clipboardData = await navigator?.clipboard?.readText();
    newData[row][col] = JSON.parse(clipboardData).tag.join('-');
    setSpreadsheetMetaData((prev) => ({...prev, data: newData}));
    newCellInfo[row][col] = null;
    setCellInfo(newCellInfo);
  },
  addRow(
    rowQue: number[],
    newData: CellValue[][],
    newCellInfo: ISpreadSheetCellInfo[][],
    setSpreadsheetMetaData,
    setCellInfo,
    handsontableInstance: Handsontable,
    direction: 'above' | 'below',
    afterChangeCellInfo
  ) {
    let insertedRowsNumber = direction === 'below' ? 1 : 0;
    for (let i = 0; i < rowQue.length; i += 2) {
      const newRow = Array.from({length: newData[0].length}, () => '');
      const newCellInfoRow = Array.from({length: newData[0].length}, () => null);
      const insertRowCalc = rowQue[i + 1] - rowQue[i] + 1;
      const newRows = Array.from({length: insertRowCalc}, () => newRow);
      const newCellInfoRows = Array.from({length: insertRowCalc}, () => newCellInfoRow);
      const insertIdx =
        insertedRowsNumber +
        (direction === 'below' ? Math.max(rowQue[i], rowQue[i + 1]) : Math.min(rowQue[i], rowQue[i + 1]));
      newData.splice(insertIdx, 0, ...newRows);
      newCellInfo.splice(insertIdx, 0, ...newCellInfoRows);
      insertedRowsNumber += insertRowCalc;
    }
    setSpreadsheetMetaData((prev) => ({...prev, data: newData, colLengths: newData[0].length}));
    setCellInfo(newCellInfo);
    handsontableInstance.deselectCell();
    afterChangeCellInfo(newCellInfo);
  },

  addColumn(
    columnQue: number[],
    newData: CellValue[][],
    newCellInfo: ISpreadSheetCellInfo[][],
    setSpreadsheetMetaData,
    setCellInfo,
    handsontableInstance: Handsontable,
    direction: 'left' | 'right',
    afterChangeCellInfo
  ) {
    let insertedColumnsNumber = direction === 'right' ? 1 : 0;
    for (let i = 0; i < columnQue.length; i += 2) {
      const insertColumnCalc = columnQue[i + 1] - columnQue[i] + 1;
      const insertArrayData = Array.from({length: insertColumnCalc}, () => '');
      const insertArrayCellInfo = Array.from({length: insertColumnCalc}, () => null);
      const insertIdx =
        insertedColumnsNumber +
        (direction === 'right' ? Math.max(columnQue[i], columnQue[i + 1]) : Math.min(columnQue[i], columnQue[i + 1]));
      newData.map((item) => item.splice(insertIdx, 0, ...insertArrayData));
      newCellInfo.map((item) => item.splice(insertIdx, 0, ...insertArrayCellInfo));
      insertedColumnsNumber += insertColumnCalc;
    }
    setSpreadsheetMetaData((prev) => ({...prev, data: newData, colLengths: newData[0].length}));
    setCellInfo(newCellInfo);
    handsontableInstance.deselectCell();
    afterChangeCellInfo(newCellInfo);
  },

  deleteColumn(
    newData: CellValue[][],
    newCellInfo: ISpreadSheetCellInfo[][],
    selectedColumnsIdx: number[],
    setSpreadsheetMetaData,
    setCellInfo,
    afterChangeCellInfo
  ) {
    const updatedData = newData.map((item) => item.filter((_, idx) => !selectedColumnsIdx.includes(idx)));
    const updatedCellInfo = newCellInfo.map((item) => item.filter((_, idx) => !selectedColumnsIdx.includes(idx)));
    setSpreadsheetMetaData((prev) => ({...prev, data: updatedData}));
    setCellInfo(updatedCellInfo);
    afterChangeCellInfo(updatedCellInfo);
  },

  deleteRow(
    newData: CellValue[][],
    newCellInfo: ISpreadSheetCellInfo[][],
    selectedRowsIdx: number[],
    setSpreadsheetMetaData,
    setCellInfo,
    afterChangeCellInfo
  ) {
    const updatedData = newData.filter((_, idx) => !selectedRowsIdx.includes(idx));
    const updatedCellInfo = newCellInfo.filter((_, idx) => !selectedRowsIdx.includes(idx));
    setSpreadsheetMetaData((prev) => ({...prev, data: updatedData}));
    setCellInfo(updatedCellInfo);
    afterChangeCellInfo(updatedCellInfo);
  }
};
