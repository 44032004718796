import {toDateFormat} from 'utils/commons';
import {toSignificantDigit} from 'utils/number-utils';

const dateTimeTags = ['sunrise', 'sunset'];

/**
 *  지역에 따라 다른 설정 필요 (향후 함수화)
 */
export const KoreaTimeZoneDiff = 9 * 3600;

export type IDataTypes = 'linear' | 'datetime';
export const getDataType = (featureArray: string[]): IDataTypes => {
  if (!featureArray || featureArray?.length === 0) return 'linear';
  if (dateTimeTags.includes(featureArray?.[featureArray.length - 1])) {
    return 'datetime';
  }
  return 'linear';
};

export const formatter = {
  sharedTooltip(points, significantNumber: number) {
    if (!(points?.length > 0)) return;
    let dateInfo = '';
    let seriesInfo = '';
    for (let i = 0; i < points.length; i++) {
      const dataType = getDataType(points[i].series?.userOptions?.featureArray);
      if (dataType === 'linear') {
        dateInfo = toDateFormat(points[i].x, 'YYYY-MM-DD HH:mm:ss');
        seriesInfo =
          seriesInfo +
          '<span style="color:' +
          points[i].series.color +
          ';">\u25CF ' +
          '</span> ' +
          points[i].series.name +
          ': ' +
          toSignificantDigit(points[i]?.y, significantNumber) +
          '<br/>';
      } else if (dataType === 'datetime') {
        const yVal = points[i]?.y * 1000;
        const dateValue = toDateFormat(yVal, 'HH:mm:ss');
        dateInfo = toDateFormat(points[i].x, 'YYYY-MM-DD HH:mm:ss');
        seriesInfo =
          seriesInfo +
          '<span style="color:' +
          points[i].series.color +
          ';">\u25CF ' +
          '</span> ' +
          points[i].series.name +
          ': ' +
          dateValue +
          '<br/>';
      }
    }
    return dateInfo + '<br/>' + seriesInfo;
  },
  separatedTooltip(point) {
    const dataType = getDataType(point?.series?.userOptions?.featureArray);
    if (dataType === 'linear') {
      return (
        toDateFormat(point.x, 'YYYY-MM-DD HH:mm:ss') +
        '<br/>' +
        point.series.name +
        ': ' +
        toSignificantDigit(point.y, 4)
      );
    } else if (dataType === 'datetime') {
      const yVal = point?.y * 1000;
      const dateValue = toDateFormat(yVal, 'HH:mm:ss');
      return toDateFormat(point.x, 'YYYY-MM-DD HH:mm:ss') + '<br/>' + point.series.name + ': ' + dateValue;
    }
  }
};
