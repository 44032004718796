import {Handle, Position, useUpdateNodeInternals} from 'reactflow';
import classNames from 'classnames';
import classnames from 'classnames';
import styled from 'styled-components';
import {IFile, IMPfdData} from 'api/data-types';

const Wrapper = styled.div`
  position: absolute;
  top: 96px;
  left: 11px;
  width: 100%;
  height: calc(100% - 40px);
  pointer-events: none;
  overflow: hidden;
  transition: all 0.2s;
`;
const WrapperInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const HandleDivWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export type IPortHandle = {
  x: number;
  y: number;
  id: string;
  portType: string;
  width?: number;
  height?: number;
};

type styleProps = {
  $top: number;
  $left: number;
  $zIndex: number;
};

const HandleDiv = styled.div<styleProps>`
  position: absolute;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  box-sizing: border-box;
  z-index: ${(props) => props.$zIndex};

  .react-flow__handle {
    width: 5px;
    height: 5px;

    box-sizing: border-box;
    margin-top: 5px;

    &.target {
      border: none;
      margin-left: 0;
    }

    &.source {
      border: none;
    }
  }

  &.dimensionChange {
    .react-flow__handle {
      background: transparent !important;
      border-color: transparent;
    }
  }
`;

type IProps = {
  data?: {
    portHandle?: IPortHandle[];
    refresh?: Object;
    mPfdFileData?: IFile;
    imagePosition?: any;
  };
  id: string;
  zIndex: number;
  resizing: boolean;
};

function MetaPfdWidgetHandle({data, id, zIndex, resizing}: IProps) {
  const notifyHandelUpdate = useUpdateNodeInternals();
  const div = document.getElementById(id);
  if (!div || !data.mPfdFileData || !data?.imagePosition) return;
  let state = data.mPfdFileData.stateData as IMPfdData;
  const fitScale = Math.max(
    state.imageInfo.naturalWidth / div.offsetWidth,
    state.imageInfo.naturalHeight / div.offsetHeight
  );
  // const [iw, ih] = [state.imageInfo.naturalWidth / fitScale, state.imageInfo.naturalHeight / fitScale];
  const iw = data?.imagePosition.bottomRight.x - data?.imagePosition.topLeft.x;
  const ih = data?.imagePosition.bottomRight.y - data?.imagePosition.topLeft.y;
  let portHandle: IPortHandle[];
  const port = state.regions.filter((item) => item.type === 'port');
  if (port && iw && ih) {
    portHandle = port.map((item) => ({
      x: (item?.x + item?.w) * iw,
      y: (item?.y + item?.h) * ih + 10, // label 크기
      width: item?.w * iw,
      height: item?.h * ih + 10, // label 크기
      id: item?.id,
      portType: item?.portType
    }));
  }
  notifyHandelUpdate(id);

  return (
    <Wrapper>
      <WrapperInner>
        {portHandle &&
          portHandle.map((item) => {
            const uniqueId = id + item?.id; // node id + port id
            const handleType = item?.portType === 'From' ? 'source' : 'target';
            return (
              <HandleDiv
                style={{width: Math.max(item?.width, 0), height: Math.max(item?.height, 0)}}
                $top={item?.y - item?.height - 10}
                $left={item?.x - item?.width - 10}
                $zIndex={zIndex}
                id={uniqueId}
                className={classnames('handle-div', resizing && 'dimensionChange')}
                key={uniqueId}
              >
                <HandleDivWrapper>
                  <Handle
                    key={uniqueId}
                    id={'port-edge-' + handleType + uniqueId}
                    className={classNames(handleType, uniqueId)}
                    type={handleType}
                    position={Position.Bottom}
                  />
                </HandleDivWrapper>
              </HandleDiv>
            );
          })}
      </WrapperInner>
    </Wrapper>
  );
}

export default MetaPfdWidgetHandle;
