import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';

export const LoginConst = {
  loginInfoInitialState: {
    userName: {
      value: '',
      valid: false,
      placeholder: 'user id'
    },
    password: {
      value: '',
      valid: false,
      placeholder: 'password',
      type: 'password'
    }
  } as ILoginInputState,
  passwordRecoverInitialState: {
    email: {
      value: '',
      valid: false,
      placeholder: 'Enter your email address',
      validateOnChange: true,
      type: 'email'
    }
  } as ILoginInputState,
  passwordResetInitialState: {
    password: {
      value: '',
      valid: false,
      placeholder: 'password',
      type: 'password'
    },
    confirmPassword: {
      value: '',
      valid: false,
      placeholder: 'confirm password',
      type: 'password'
    }
  } as ILoginInputState,
  registerRequestInitialState: {
    userName: {
      value: '',
      type: 'id',
      valid: false,
      validLength: false,
      placeholder: 'User ID (case sensitive)',
      warningMessage: 'Invalid user ID',
      warningMessageLength: 'User ID must be at least 4 characters long.',
      validateOnChange: true
    },
    email: {
      value: '',
      type: 'email',
      valid: false,
      placeholder: 'Email Address',
      warningMessage: 'Invalid email address',
      validateOnChange: true
    },
    firstName: {
      value: '',
      valid: false,
      placeholder: 'First Name',
      warningMessage: 'Invalid first name',
      validateOnChange: true
    },
    lastName: {
      value: '',
      valid: false,
      placeholder: 'Last Name',
      warningMessage: 'Invalid last name',
      validateOnChange: true
    },
    password: {
      value: '',
      type: 'password',
      valid: false,
      validLength: false,
      placeholder: 'Password',
      warningMessage: 'Password: 8+ characters, letters, numbers, symbols.',
      validateOnChange: true
    },
    passwordCheck: {
      value: '',
      type: 'password',
      valid: false,
      placeholder: 'Password Check',
      validateOnChange: true,
      passwordMismatch: undefined,
      onBlurFnc: true
    }
  } as ILoginInputState,
  managementRegisterInitialState: {
    userName: {
      value: '',
      type: 'id',
      valid: false,
      validLength: false,
      placeholder: 'ID',
      warningMessage: 'Invalid user ID',
      warningMessageLength: 'User ID must be at least 4 characters long.',
      validateOnChange: true
    },
    firstName: {
      value: '',
      valid: false,
      placeholder: 'First Name',
      warningMessage: 'Invalid first name',
      validateOnChange: true
    },
    lastName: {
      value: '',
      valid: false,
      placeholder: 'Last Name',
      warningMessage: 'Invalid last name',
      validateOnChange: true
    },
    email: {
      value: '',
      type: 'email',
      valid: false,
      placeholder: 'Email',
      warningMessage: 'Invalid email address',
      validateOnChange: true
    }
  } as ILoginInputState
};
