import styled from 'styled-components';
import {PropsWithChildren, ReactElement} from 'react';
import classNames from 'classnames';

const Container = styled.span`
  font-size: 16px;
  height: 24px;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  line-height: 24px;
  align-items: center;

  &.group {
    background-color: #d9efff;
  }

  &.role {
    background-color: #ead9ff;
  }
`;

type INameTagProps = PropsWithChildren & {
  type: 'group' | 'role';
  className?: string;
};

function NameTag({type, children, className}: INameTagProps): ReactElement {
  return <Container className={classNames(type, className)}>{children}</Container>;
}

export default NameTag;
