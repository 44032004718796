import Tooltip from 'components/common/Tooltip';
import DefaultBrowserPermission from 'components/common/tooltip/DefaultBrowserPermission';
import React, {useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';

type BrowserPermissionType = 'clipboard' | 'location' | 'microphone';
const validPermissions: BrowserPermissionType[] = ['clipboard', 'location', 'microphone'];
enum permissionRelatedAction {
  clipboard = 'Copy and Paste',
  location = 'Weather Widget',
  microphone = 'Voice record'
}

function ProcessCanvasDefaultBrowserPermissionTooltip() {
  const {remoteTooltip} = useContext(CommonContext);
  if (!remoteTooltip || !validPermissions.includes(remoteTooltip as BrowserPermissionType)) return;
  const message = `The Process Canvas does not have ${remoteTooltip} permission.\n To use ${permissionRelatedAction[remoteTooltip as string]}, please allow browser ${remoteTooltip} permission.`;
  const src = require(`assets/images/guide/${remoteTooltip}-permission.gif`);
  return <Tooltip type="browser-permission" content={<DefaultBrowserPermission message={message} src={src} />} />;
}

export default ProcessCanvasDefaultBrowserPermissionTooltip;
