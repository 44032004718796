import React, {ChangeEvent, ReactElement} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 6px;
  }

  input[type='radio'] {
    width: 1.4em;
    height: 1.4em;
    margin: 0 6px 0 0;
  }

  label {
    white-space: nowrap;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
    }
  }
`;

type IProps = {
  name: string;
  label: string;
  id: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
};

function RadioForm({id, label, name, onChange, value, checked, disabled, readOnly}: IProps): ReactElement {
  return (
    <Container>
      <input
        className={classNames(readOnly && 'read-only')}
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        disabled={readOnly || disabled}
        onChange={onChange}
      />
      <label htmlFor={id} className={classNames({disabled})}>
        {label}
      </label>
    </Container>
  );
}

export default RadioForm;
