import {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div`
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #d3d3d3;
  font-size: 12px;
  font-weight: 400;
  transition: none;
  &.active {
    height: auto;
    margin-right: 2px;
    padding: 13px 25px;
    border-bottom: 1px solid #a6a6a6;
    transition: max-height 0.2s;
    max-height: 300px;
    overflow-y: auto;
  }
`;

type AccordionDetailsProps = {
  children: ReactNode;
  isActive?: boolean;
};

export function AccordionDetails({children, isActive}: AccordionDetailsProps): ReactElement {
  return <Container className={classnames(isActive && 'active', 'thin-scrollbar md')}>{children}</Container>;
}

export default AccordionDetails;
