import styled from 'styled-components';
import RoleManagement from 'components/auth/management/role/RoleManagement';
import UserManagement from 'components/auth/management/user/UserManagement';
import GroupManagement from 'components/auth/management/group/GroupManagement';
import ManagementAside from 'components/auth/management/parts/ManagementAside';

import RoleManagementAside from './role/RoleManagementAside';
import UserManagementAside from './user/UserManagementAside';
import GroupManagementAside from 'components/auth/management/group/GroupManagementAside';

import {useParams} from 'react-router-dom';
import useRoleManagementHandler from 'components/auth/management/hooks/useRoleManagementHandler';
import useGroupManagementHandler from 'components/auth/management/hooks/useGroupManagementHandler';
import useUserManagementHandler from 'components/auth/management/hooks/useUserManagementHandler';

const Container = styled.div<{$isBlur: boolean}>`
  padding: 40px;
  font-weight: 400;
  position: relative;
  width: 100%;
  background-color: white;
  height: 100%;
  box-sizing: border-box;
  filter: ${(props) => (props.$isBlur ? 'brightness(0.9)' : '')};
  * {
    color: #575660;
  }
`;

const ManagementScreen = () => {
  const {id} = useParams();
  const roleHandler = useRoleManagementHandler();
  const groupHandler = useGroupManagementHandler();
  const userHandler = useUserManagementHandler();

  const handlers = {
    role: {
      asideInfo: roleHandler.roleAsideInfo,
      list: roleHandler.roleList,
      resetAsideInfo: roleHandler.resetRoleAsideInfo,
      openCreateAside: roleHandler.openCreateRoleAside,
      create: roleHandler.createRole,
      delete: roleHandler.deleteRole,
      onClickTable: roleHandler.onClickRoleTable,
      edit: roleHandler.editRole,
      createView: (
        <RoleManagementAside
          activateCancelButton={true}
          onClickCreate={roleHandler.createRole}
          onClose={roleHandler.resetRoleAsideInfo}
          asideInfo={roleHandler.roleAsideInfo}
        />
      ),
      editView: (
        <RoleManagementAside
          activateCancelButton={true}
          onClickCreate={roleHandler.editRole}
          onClickDelete={roleHandler.deleteRole}
          onClose={roleHandler.resetRoleAsideInfo}
          asideInfo={roleHandler.roleAsideInfo}
        />
      )
    },
    group: {
      asideInfo: groupHandler.groupAsideInfo,
      list: groupHandler.groupList,
      resetAsideInfo: groupHandler.resetGroupAsideInfo,
      openCreateAside: groupHandler.openCreateGroupAside,
      create: groupHandler.createGroup,
      delete: groupHandler.deleteGroup,
      onClickTable: groupHandler.onClickGroupTable,
      edit: groupHandler.editGroup,
      createView: (
        <GroupManagementAside
          currentRoleList={roleHandler.roleList}
          activateCancelButton={true}
          onClickCreate={groupHandler.createGroup}
          onClose={groupHandler.resetGroupAsideInfo}
          asideInfo={groupHandler.groupAsideInfo}
        />
      ),
      editView: (
        <GroupManagementAside
          currentRoleList={roleHandler.roleList}
          activateCancelButton={true}
          onClickCreate={groupHandler.editGroup}
          onClickDelete={groupHandler.deleteGroup}
          onClose={groupHandler.resetGroupAsideInfo}
          asideInfo={groupHandler.groupAsideInfo}
        />
      )
    },
    user: {
      asideInfo: userHandler.userAsideInfo,
      list: userHandler.userList,
      resetAsideInfo: userHandler.resetUserAsideInfo,
      openCreateAside: userHandler.openCreateUserAside,
      create: userHandler.createUser,
      delete: userHandler.deleteUser,
      onClickTable: userHandler.onClickUserTable,
      edit: userHandler.updateUser,
      activate: userHandler.activateUser,
      deactivate: userHandler.deactivateUser,
      assignGroupsAndRoles: userHandler.assignGroupsAndRolesToUser,
      removeGroupsAndRoles: userHandler.removeGroupsAndRolesFromUser,
      createView: (
        <UserManagementAside
          activateCancelButton={true}
          onClickCreate={userHandler.createUser}
          onClose={userHandler.resetUserAsideInfo}
          asideInfo={userHandler.userAsideInfo}
        />
      ),
      editView: (
        <UserManagementAside
          currentRoleList={roleHandler.roleList}
          currentGroupList={groupHandler.groupList}
          activateCancelButton={true}
          onClickCreate={userHandler.updateUser}
          onClose={userHandler.resetUserAsideInfo}
          asideInfo={userHandler.userAsideInfo}
          activateUser={userHandler.activateUser}
          deactivateUser={userHandler.deactivateUser}
        />
      ),
      detailView: (
        <UserManagementAside
          currentRoleList={roleHandler.roleList}
          currentGroupList={groupHandler.groupList}
          activateCancelButton={true}
          onClose={userHandler.resetUserAsideInfo}
          asideInfo={userHandler.userAsideInfo}
          assignGroupsAndRolesToUser={userHandler.assignGroupsAndRolesToUser}
          removeGroupsAndRolesFromUser={userHandler.removeGroupsAndRolesFromUser}
        />
      )
    }
  };

  const currentHandler = handlers[id] || {};
  const {asideInfo, list, resetAsideInfo, createView, editView, detailView, onClickTable} = currentHandler;

  const onClickRow = (rowkey: string, columnKey?: string) => {
    if (asideInfo) {
      resetAsideInfo();
    } else {
      onClickTable(rowkey, columnKey);
    }
  };

  if (!handlers[id]) return null;

  return (
    <>
      <Container $isBlur={Boolean(asideInfo)}>
        {id === 'role' && <RoleManagement roleList={list} onClickRoleManagementRow={onClickRow} />}
        {id === 'user' && (
          <UserManagement
            userList={list}
            openCreateUserAside={currentHandler.openCreateAside}
            onClickUserManagementRow={onClickRow}
          />
        )}
        {id === 'group' && (
          <GroupManagement
            groupList={list}
            openCreateGroupAside={currentHandler.openCreateAside}
            onClickGroupManagementRow={onClickRow}
          />
        )}
      </Container>
      <ManagementAside asideInfo={asideInfo} createView={createView} editView={editView} detailView={detailView} />
    </>
  );
};

export default ManagementScreen;
