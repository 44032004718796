import {memo, ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {DataContext, TreeCategory, treeCategoryList} from 'api/DataProvider';
import TreeMenu from 'components/pc/node-selector/TreeMenu';
import {Node as TreeNode} from 'react-checkbox-tree';
import NodeMenu from 'components/pc/node-selector/NodeMenu';
import {convertHierarchyToTreeNode} from 'components/pc/node-selector/parts/convertHierarchyToTreeNode';

const Container = styled.div`
  width: 100%;
  height: 100%;
  //position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  color: #575660;
  &.default {
    margin: auto;
  }
  &.time-series {
    margin: auto auto auto 300px;
  }
  .rct-checkbox {
    padding: 0;
    &[aria-checked='true'] + .rct-title {
      background-color: rgba(51, 51, 204, 0.2);
    }
  }
  .rct-node-leaf {
    .rct-collapse {
      //padding: 0;
      //width: 0;
    }
  }
  .rct-node {
    &:not(.rct-node-leaf) {
      > .rct-text {
        label {
          background-color: white;
        }
        //&:hover {
        //  background-color: white;
        //}
      }
    }
  }

  background-color: white;
`;

const TreeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  width: 100%;
`;

type IProps = {
  type?: 'default' | 'time-series' | 'stream';
  categoryList?: TreeCategory[];
  selectedTags: string[][];
  limit?: number;
  onSelect?(tags: string[][]): void;
  onClose(): void;
};

/**
 * Tag 를 선택하고 결과를 호스트코드로 전송
 * @param type
 * @param tagList
 * @param onSelect
 * @param onClose
 * @constructor
 */
function DataConnectorNodeSelector({
  type = 'default',
  selectedTags,
  categoryList = ['Model', 'Plant', 'Weather', 'Commodity'],
  onSelect,
  onClose
}: IProps): ReactElement {
  const {tagList, getTree} = useContext(DataContext);
  const {availableDatabaseHierarchyList} = useContext(DataContext);
  const checkedState = useState<string[]>([]);
  const [checked, setChecked] = checkedState;
  const expandedState = useState<string[]>([]);
  const [nodes, setNodes] = useState<TreeNode[]>([]);
  useEffect(() => {
    if (tagList.length === treeCategoryList.length && nodes?.length === 0) {
      const targetNodes = [];
      for (let i = 0; i < categoryList.length; i++) {
        targetNodes.push(getTree(categoryList[i]));
      }
      setNodes(targetNodes);
    }
  }, [categoryList, tagList]);

  const originNodes = useMemo(
    () => convertHierarchyToTreeNode(availableDatabaseHierarchyList),
    [availableDatabaseHierarchyList]
  );

  useEffect(() => {
    setNodes(originNodes);
  }, [originNodes]);

  useEffect(() => {
    onSelect(checked.map((item: string) => JSON.parse(item)));
  }, [checkedState]);

  useEffect(() => {
    if (!selectedTags) return;
    const checkedTagList = [...(selectedTags || [])]?.map((tag) => JSON.stringify(tag));
    setChecked(checkedTagList);
  }, [selectedTags]);

  return (
    <Container className={type}>
      <TreeMenuContainer>
        {/*<TreeMenu*/}
        {/*  nodes={nodes}*/}
        {/*  selected={selectedTags}*/}
        {/*  checkedState={checkedState}*/}
        {/*  expandedState={expandedState}*/}
        {/*  // beforeOnCheck={beforeOnCheck}*/}
        {/*  iconOption="normal"*/}
        {/*  isSingleCheck={true}*/}
        {/*/>*/}
        <NodeMenu nodes={nodes} checkedState={checkedState} expandedState={expandedState} />
      </TreeMenuContainer>
    </Container>
  );
}

export default memo(DataConnectorNodeSelector, (prevProps, nextProps) => {
  return prevProps.selectedTags === nextProps.selectedTags;
});
