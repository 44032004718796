import React, {ReactNode} from 'react';
import {Button} from 'components/forms';
import {getImage} from 'assets/images/svg-image';
import styled from 'styled-components';

const Container = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
`;

const HeaderText = styled.h4`
  margin: 0;
  //font-size: 30px;
  font-size: 18px;
  font-weight: 400;
  color: #575660;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

type IProps = {
  children?: ReactNode;
  disableClose?: boolean;
  isBusy?: boolean;
  onClose?(): void;
};

function ModalHeader({children, disableClose, isBusy, onClose}: IProps): JSX.Element {
  return (
    <Container>
      <HeaderText>{children}</HeaderText>
      <ButtonWrapper>
        {onClose && (
          <Button variant="none" disabled={disableClose || isBusy} onClick={onClose}>
            <img src={getImage('close')} className="close-button" alt="close button" />
          </Button>
        )}
      </ButtonWrapper>
    </Container>
  );
}

export default ModalHeader;
