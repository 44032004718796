import {useEffect, useState} from 'react';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {managementApi, RoleInfo} from 'components/auth/management/management-api-function';
import {managementConst} from 'components/auth/management/const';
import {IManagementAsideInfo} from 'components/auth/management/type';

type IReturn = {
  roleAsideInfo: IManagementAsideInfo;
  roleList: RoleInfo[];
  resetRoleAsideInfo(): void;
  openCreateRoleAside(): void;
  createRole(name: string, description: string): void;
  deleteRole(name: string, description: string): void;
  onClickRoleTable(rowKey: string): void;
  editRole(name: string, description: string): void;
};

function useRoleManagementHandler(): IReturn {
  const [roleAsideInfo, setRoleAsideInfo] = useState<IManagementAsideInfo>();
  const [roleList, setRoleList] = useState<RoleInfo[]>([]);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;

  useEffect(() => {
    managementApi.role.getRoleList(token).then((res) => {
      if (res?.success) {
        setRoleList(res?.data.roles);
      }
    });
  }, []);

  const resetRoleAsideInfo = () => {
    setRoleAsideInfo(null);
  };

  const openCreateRoleAside = () => {
    setRoleAsideInfo(managementConst.role.roleAsideInfo.create);
    document.addEventListener('mouseup', resetRoleAsideInfo, {once: true});
  };

  const createRole = (name: string, description: string) => {
    managementApi.role.createRole(token, name, description).then((res) => {
      if (res?.success) {
        managementApi.role.getRoleList(token).then((res) => {
          if (res?.success) {
            setRoleList(res?.data.roles);
            setRoleAsideInfo(null);
          }
        });
      } else {
        setRoleAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to create role',
          info: {
            roleName: name,
            description
          }
        }));
      }
    });
  };

  const deleteRole = (name: string, description: string) => {
    managementApi.role.deleteRole(token, name).then((res) => {
      if (res?.success) {
        managementApi.role.getRoleList(token).then((res) => {
          if (res?.success) {
            setRoleList(res?.data.roles);
            setRoleAsideInfo(null);
          }
        });
      } else {
        setRoleAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Failed to delete role',
          info: {
            roleName: name,
            description
          }
        }));
      }
    });
  };

  const onClickRoleTable = (rowKey: string) => {
    const selectedRole = roleList.find((item) => item.name === rowKey);
    if (selectedRole) {
      setRoleAsideInfo({
        ...managementConst.role.roleAsideInfo.edit,
        info: {
          description: selectedRole.description,
          roleName: selectedRole.name
        }
      });
      document.addEventListener('mouseup', resetRoleAsideInfo, {once: true});
    }
  };

  const editRole = (name: string, description: string) => {
    managementApi.role.updateRole(token, name, description).then((res) => {
      if (res?.success) {
        managementApi.role.getRoleList(token).then((res) => {
          if (res?.success) {
            setRoleList(res?.data.roles);
            setRoleAsideInfo(null);
          }
        });
      } else {
        setRoleAsideInfo((prev) => ({
          ...prev,
          errorMsg: res?.detail ? res?.detail : 'Fail to edit role',
          info: {
            roleName: name,
            description
          }
        }));
      }
    });
  };

  return {
    roleAsideInfo,
    roleList,
    resetRoleAsideInfo,
    openCreateRoleAside,
    createRole,
    deleteRole,
    onClickRoleTable,
    editRole
  };
}

export default useRoleManagementHandler;
