import {memo, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, useOnViewportChange, useReactFlow, Viewport} from 'reactflow';
import {WidgetBody, WidgetConfigLayer, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {getWidgetTitle, pcFunc} from 'utils/processCanvas-functions';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {ContactShadows, Environment, Grid, OrbitControls, OrbitControlsChangeEvent} from '@react-three/drei';
import ModelShells from 'components/pc/widgets/three/renderer/block/Model_Shells';
import {Camera, Canvas, useThree} from '@react-three/fiber';
import {LeftMenu} from 'components/pc/widgets/three/renderer/ui';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import Pipes from 'components/pc/widgets/three/Pipes';
import ThreeLandscapeLabelsWrapper from 'components/pc/widgets/three/renderer/label/ThreeLandscapeLabelsWrapper';
import Blocks from 'components/pc/widgets/three/Blocks';
import {
  canvasConfig,
  controlsConfig,
  gltfModelsPaths,
  gridConfig,
  shadowConfig
} from 'components/pc/widgets/three/constants/config';
import {getApiHost} from 'api/function';
import WhiteBackground from 'components/pc/widgets/three/WhiteBackground';
import * as THREE from 'three';
import {Vector3Like} from 'three';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {getUniqueKey} from 'utils/commons';
import useApi from 'api/useApi';
import {IBlockTableData} from 'components/spreadsheet/spreadsheet-adapter';
import {faTable} from '@fortawesome/pro-light-svg-icons';
import {getBlockValues, parseCheckedListData} from 'components/pc/widgets/blockTable/block-functions';
import {DataContext} from 'api/DataProvider';
import ThreeLandscapeSetting from 'components/pc/widgets/three/ThreeLandscapeSetting';
import AgcColumn from 'components/pc/widgets/three/renderer/block/AGC_Column';
import annotationsData from 'components/pc/widgets/three/constants/annotationsData.json';
import {INodeSubNode} from 'api/data-types';
import useFrameless from 'components/pc/widgets/common/useFrameless';
import {MessageSpinner} from 'components/common';
import styled from 'styled-components';
import {Vector3} from '@react-three/fiber/dist/declarations/src/three-types';

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

export type ISelectedLabelInfo = {
  labelName: string;
  labelType: 'streams' | 'blocks' | 'undefined';
  labelPosition: {
    x: number;
    y: number;
    z: number;
  };
  leftMenuClicked?: boolean;
  clickedPosition?: {
    // 클릭으로 label이 선택되었을 경우 캔버스에서의 x, y 위치를 저장
    x: number;
    y: number;
  };
};

export type IManualCamera = Camera & {
  manual?: boolean;
};

export type ModelTypes = 'model_fcc' | 'model_vcm';

function ThreeLandscapeWidget({data, id, ...rest}: NodeProps): ReactElement {
  const {isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;
  const [title, setTitle] = useState('');
  const [isShowConfig, setIsShowConfig] = useState(false);
  const [isModelSelected, setIsModelSelected] = useState(false);
  const [modelTitle, setModelTitle] = useState('');
  const [modelType, setModelType] = useState<ModelTypes>('model_fcc');
  const [isLoading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isTransparent, updateTransparent] = useState(true);
  const [isWire, updateWire] = useState(false);
  const [labelsVisible, updateLabels] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState<ISelectedLabelInfo>(null);
  const [isShowNodeSelector, setIsShowNodeSelector] = useState<boolean>(false);
  const [selectedBlockNodes, setSelectedBlockNodes] = useState<string[][]>([]);
  const reactFlow = useReactFlow();
  const api = useApi();
  const {globalSettingsState, availableDatabaseHierarchyList} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;

  const [blockVisibility, setBlockVisibility] = useState<any>({
    '01': true,
    '02': true,
    '03': true,
    '04': true,
    '05': true,
    '06': true,
    '07': true
  });

  const [streamVisibility, setStreamVisibility] = useState<any>({
    '01': true,
    '02': true,
    '03': true
  });

  const containerRef = useRef(null);
  const controlsRef = useRef(null);

  const onChangeModelType = (value: string): void => {
    if (value === 'model_fcc') {
      setModelType('model_fcc');
      setModelTitle('FCC');
    } else if (value === 'model_vcm') {
      setModelType('model_vcm');
      setModelTitle('VCM');
      updateLabels(!labelsVisible);
    }
  };

  const onClickConfig = (): void => {
    setIsShowConfig(!isShowConfig);
  };

  const onClickConfirm = () => {
    setIsModelSelected(true);
    setIsShowConfig(false);
    setLoading(true);
  };

  const onCancel = (isShowConfig: boolean): void => {
    setIsShowConfig(false);
  };

  useEffect(() => {
    if (isModelSelected) {
      if (modelType === 'model_fcc') {
        setTitle('FCC');
      } else if (modelType === 'model_vcm') {
        setTitle('VCM');
      }
      const API_HOST = getApiHost();
      const loader = new GLTFLoader();
      const loadModelPromises = gltfModelsPaths.map((pathForGltf) => {
        return loader
          .loadAsync(API_HOST + pathForGltf)
          .then((response) => {
            response.parser.json.meshes.map((meshGroup) => {
              setLoadingMessage(`Loading ${meshGroup.name}...`);
            });
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });

      Promise.all(loadModelPromises).then(() => {
        setLoading(false);
      });
    }
  }, [isModelSelected, modelType]);

  const onToggleBlockVisibility = (buttonName: string) => {
    setBlockVisibility((prevBlockVisibility) => {
      const areAllButtonsPressed = Object.values(prevBlockVisibility).every((value) => value === true);
      const updatedBlockVisibility = {};
      if (areAllButtonsPressed) {
        Object.keys(prevBlockVisibility).forEach((key) => {
          updatedBlockVisibility[key] = key === buttonName;
        });
      } else {
        Object.keys(prevBlockVisibility).forEach((key) => {
          updatedBlockVisibility[key] = key === buttonName ? !prevBlockVisibility[key] : prevBlockVisibility[key];
        });
      }
      return updatedBlockVisibility;
    });
  };

  const onToggleStreamVisibility = (buttonName: string) => {
    setStreamVisibility((prevStreamVisibility) => {
      const areAllButtonsPressed = Object.values(prevStreamVisibility).every((value) => value === true);
      const updatedStreamVisibility = {};
      if (areAllButtonsPressed) {
        Object.keys(prevStreamVisibility).forEach((key) => {
          updatedStreamVisibility[key] = key === buttonName;
        });
      } else {
        Object.keys(prevStreamVisibility).forEach((key) => {
          updatedStreamVisibility[key] = key === buttonName ? !prevStreamVisibility[key] : prevStreamVisibility[key];
        });
      }
      return updatedStreamVisibility;
    });
  };

  const modelScale = 0.2;

  const moveCamera = (
    position: Vector3Like,
    camera: IManualCamera,
    scene: THREE.Scene,
    targetSize?: 'big' | 'small'
  ) => {
    if (targetSize === null) targetSize = 'small';

    // factors that decide the camera position: distance, elevationFactor, modelScale
    let distance = 40;
    let elevationFactor = 30;
    switch (targetSize) {
      case 'big':
        distance = 80;
        elevationFactor = 60;
        break;
      case 'small':
        distance = 1;
        elevationFactor = 25;
        break;
    }

    // Calculate the direction from the camera to the mesh
    const direction = new THREE.Vector3().subVectors(position, camera.position).normalize();

    // Final position for the camera
    const finalPosition = new THREE.Vector3()
      .addVectors(position, direction.multiplyScalar(-distance))
      .multiplyScalar(modelScale);
    finalPosition.y += elevationFactor * modelScale;

    // Check for occlusion and adjust camera rotation if necessary
    const raycaster = new THREE.Raycaster(camera.position, direction);
    const intersects = raycaster.intersectObjects(scene.children, true);

    let occlusionDetected = false;
    for (let i = 0; i < intersects.length; i++) {
      // if (intersects[i].object !== mesh) {
      //   occlusionDetected = true;
      //   break;
      // }
    }

    if (occlusionDetected) {
      // Rotate the camera 45 degrees around the Y-axis of the mesh
      const yAxis = new THREE.Vector3(0, 1, 0);
      const angle = 45 * (Math.PI / 180); // Converting degrees to radians

      // Calculate the new camera position after rotation
      const rotatedPosition = new THREE.Vector3().subVectors(camera.position, position);
      rotatedPosition.applyAxisAngle(yAxis, angle);
      rotatedPosition.add(position);

      // Update the final position to the new rotated position
      finalPosition.copy(rotatedPosition);
    }

    // Animate camera movement
    const duration = 1000;
    const startTime = Date.now();

    function animate() {
      const elapsed = Date.now() - startTime;
      const fraction = elapsed / duration;

      if (fraction < 1) {
        // Interpolate camera position
        camera.position.lerpVectors(camera.position, finalPosition, fraction);

        // Update OrbitControls target
        if (controlsRef && controlsRef.current) {
          const currentTarget = controlsRef.current.target;
          const newTarget = new THREE.Vector3(
            position.x * modelScale,
            position.y * modelScale,
            position.z * modelScale
          );
          currentTarget.lerpVectors(currentTarget, newTarget, fraction);
          controlsRef.current.update();
        }

        requestAnimationFrame(animate);
      } else {
        // Finalize camera position
        camera.position.copy(finalPosition);

        if (controlsRef && controlsRef.current) {
          controlsRef.current.target.set(position.x * modelScale, position.y * modelScale, position.z * modelScale);
          controlsRef.current.update();
        }
      }
    }
    animate();
  };

  const onClickResetView = () => {
    if (controlsRef && controlsRef.current) {
      setSelectedLabel(null);

      const controls = controlsRef.current;
      const {fov, position} = canvasConfig.camera;
      controls.object.fov = fov;
      controls.object.position.set(position[0], position[1], position[2]);

      const defaultTarget = new THREE.Vector3(0, 0, 0); // Replace with your default target position
      controls.target.copy(defaultTarget);

      controls.update();
    }
  };

  const onSelectBlockNode = async (checkedList: string[]) => {
    if (checkedList && checkedList.length > 0) {
      const parsed = parseCheckedListData(checkedList);
      setSelectedBlockNodes(parsed);
      const valueAddedParsed = await getBlockValues(
        parsed,
        api,
        selectedLabel?.labelName,
        globalSettings?.significantDigit || 4
      );
      openBlockDatasheet(valueAddedParsed, parsed);
    }
  };

  const openBlockDatasheet = (tableData: string[][], selectedNodes: string[][]) => {
    const newDatasheetId = getUniqueKey();
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    const metaData: IBlockTableData = {
      colHeaders: false,
      rowHeaders: false,
      colWidth: 200,
      data: tableData,
      selectedBlockNodes: selectedNodes,
      targetBlockName: selectedLabel?.labelName,
      rendererType: 'HierarchyDataRenderer',
      initialized: true
    };
    reactFlow.addNodes({
      id: newDatasheetId,
      type: 'BlockTableWidget',
      data: {
        title: selectedLabel?.labelName || 'Undefined Label',
        customizedTitle: false,
        icon: faTable,
        metaData: metaData,
        minWidth: 200,
        minHeight: 200
      },
      style: {
        width: 600,
        height: 500,
        minWidth: 200,
        minHeight: 200
      },
      zIndex: highestZIndex,
      position: {
        x: selectedLabel?.clickedPosition.x + 50 || 400,
        y: selectedLabel?.clickedPosition.y + 50 || 50
      }
    });
  };

  const filterStreamsList = (originalList: any[]) => {
    const streamData = annotationsData.filter((annotation) => annotation.labelType === 'streams');
    const streamTitles = streamData.map((stream) => stream.title);
    return originalList.filter((item) => streamTitles.includes(item.name));
  };

  const filterBlocksList = (originalList: any[]) => {
    const blockData = annotationsData.filter((annotation) => annotation.labelType === 'equipment');
    const blockTitles = blockData.map((block) => block.title);
    return originalList.filter((item) => blockTitles.includes(item.name));
  };

  const sortList = (originalList: any[]) => {
    return originalList.sort((a, b) => a.name.localeCompare(b.name));
  };

  const [streamList, setStreamList] = useState<INodeSubNode[]>([]);
  const [blockList, setBlockList] = useState<INodeSubNode[]>([]);

  useEffect(() => {
    const found = availableDatabaseHierarchyList.find((db) => db.database === modelType);
    if (!found) return;
    const [streams, blocks] = found?.data?.[0]?.subnode;
    if (streams) setStreamList(sortList(filterStreamsList(streams.subnode)));
    if (blocks) setBlockList(sortList(filterBlocksList(blocks.subnode)));
  }, [availableDatabaseHierarchyList, modelType]);

  const {isFrameless, isWidgetMouseEnter, setIsFrameless, onMouseEnter, onMouseLeave, onFrameless} = useFrameless();

  useEffect(() => {
    const metaData = {modelType, modelTitle, frameless: isFrameless};
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node))
    );
  }, [id, modelType, modelTitle, reactFlow, isFrameless]);

  useEffect(() => {
    if (data?.metaData) {
      const {modelType, modelTitle, frameless} = data.metaData;
      setModelTitle(modelTitle);
      setModelType(modelType);
      setIsModelSelected(true);
      setIsFrameless(frameless);
    } else {
      setIsShowConfig(true);
    }
  }, []);

  const {getViewport} = useReactFlow();
  const scaleToMultiplier = (scale: number): number => {
    return scale <= 1 ? 1 / scale : 1 / scale;
  };

  // ReactFlow 의 viewport 가 변경되면 (ex: zoom, pan ...) 라벨을 껐다 다시 켜줌
  useOnViewportChange({
    onStart: () => setLoading(true),
    onEnd: () => setLoading(false)
  });

  return (
    <WidgetContainer {...rest} data={data} type={data.type} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <WidgetHeader
        {...rest}
        type="ThreeLandscapeWidget"
        icon={data.icon}
        title={
          data.customizedTitle ? data.title : getWidgetTitle({type: 'ThreeLandscapeWidget', titleData: title, data})
        }
        suffix="- 3D Landscape"
        id={id}
        frameless={isFrameless}
        isWidgetMouseEnter={isWidgetMouseEnter}
        onFrameless={onFrameless}
        onConfig={onClickConfig}
      />
      <WidgetBody>
        <CanvasContainer ref={containerRef} style={{zoom: scaleToMultiplier(getViewport().zoom)}}>
          <Canvas {...canvasConfig}>
            <WhiteBackground />
            <ambientLight intensity={Math.PI} />
            <Environment preset="city" blur={0.5} />
            <OrbitControls ref={controlsRef} domElement={containerRef?.current} {...controlsConfig} />
            <ContactShadows {...shadowConfig} />
            <Grid {...gridConfig} />
            {!isLoading && isModelSelected && modelType === 'model_fcc' && (
              <ModelShells
                modelScale={modelScale}
                transparent={isTransparent}
                wireframes={isWire}
                blockVisibility={blockVisibility}
                moveCamera={moveCamera}
                setSelectedLabel={setSelectedLabel}
              />
            )}
            {!isLoading && modelType === 'model_vcm' && (
              <AgcColumn
                modelScale={modelScale}
                transparent={isTransparent}
                wireframes={isWire}
                blockVisibility={blockVisibility}
                moveCamera={moveCamera}
                setSelectedLabel={setSelectedLabel}
              />
            )}
            {!isLoading && isModelSelected && (
              <ThreeLandscapeLabelsWrapper
                streamList={streamList}
                blockList={blockList}
                labelsVisible={modelType !== 'model_vcm' && labelsVisible}
                moveCamera={moveCamera}
                setIsShowNodeSelector={setIsShowNodeSelector}
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
              />
            )}
            {!isLoading && isModelSelected && modelType !== 'model_vcm' && <Blocks blockVisibility={blockVisibility} />}
            {!isLoading && isModelSelected && modelType !== 'model_vcm' && (
              <Pipes streamVisibility={streamVisibility} />
            )}
          </Canvas>
        </CanvasContainer>
        {isShowNodeSelector && (
          <NodeSelectorRevision
            title="Select Nodes"
            selectedNodes={selectedBlockNodes}
            onClose={() => {
              setIsShowNodeSelector(false);
              setSelectedBlockNodes(null);
            }}
            options={{searchStrings: [selectedLabel?.labelName]}}
            onSelect={onSelectBlockNode}
            checkBoxTreeOptions={{
              onlyLeafCheckboxes: false,
              expandUntilSearchString: true
            }}
          />
        )}
        <WidgetConfigLayer
          title="Settings"
          onClose={onClickConfig}
          isShow={isShowConfig}
          disabled={isModelSelected ? 'enabled' : 'disabled'}
          size="small"
        >
          <ThreeLandscapeSetting
            isModelSelected={isModelSelected}
            onCancel={onCancel}
            onClick={onClickConfirm}
            onChangeModelType={onChangeModelType}
          />
        </WidgetConfigLayer>
        <LeftMenu
          title={modelTitle}
          streamList={streamList}
          blockList={blockList}
          blockVisibility={modelType === 'model_fcc' ? blockVisibility : blockVisibility['02']}
          streamVisibility={modelType === 'model_fcc' ? streamVisibility : ''}
          // onToggleBlock={onToggleBlockVisibility}
          // onToggleStream={onToggleStreamVisibility}
          isModelSelected={isModelSelected}
          modelType={modelType}
          isShow={!isFrameless}
          isShowWireFrame={isWire}
          isShowLabel={labelsVisible}
          onClickResetView={onClickResetView}
          onToggleWireFrame={() => updateWire(!isWire)}
          onToggleShowLabel={() => updateLabels(!labelsVisible)}
          selectedLabel={selectedLabel}
          setSelectedLabel={setSelectedLabel}
        />
        <MessageSpinner isShow={isLoading} />
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(ThreeLandscapeWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
