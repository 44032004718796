import {memo, ReactElement, useEffect, useState} from 'react';
import {NodeProps} from 'reactflow';
import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import BasicSpreadsheet from 'components/spreadsheet/BasicSpreadsheet';
import BasicSpreadsheetWithHierarchy from 'components/spreadsheet/BasicSpreadsheetWithHierarchy';
import {IWidgetNodeData} from 'components/pc/types';

// reactFlow.addNodes({
//   id,
//   type: 'SpreadSheetWidget',
//   data: {
//     metaData: {
//       colHeaders: null,
//       rowHeaders: null,
//       colWidth: 100,
//       data: [],
//       colLengths: 0,
//       rendererType: 'NormalDataRenderer'
//     }
//   },
//   style,
//   position,
//   zIndex: highestZIndex
// });

function SpreadsheetWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const [spreadsheetMetaData, setSpreadsheetMetaData] = useState<IHandsonTableSpreadSheetData>({
    colHeaders: null,
    rowHeaders: null,
    colWidth: 100,
    data: [],
    colLengths: 0,
    rendererType: 'NormalDataRenderer'
  });

  useEffect(() => {
    if (data?.metaData) {
      const loadData = data?.metaData as IHandsonTableSpreadSheetData;
      setSpreadsheetMetaData(loadData);
    } else {
      // convertRawToSpreadSheetData.tag(TEST_TAG_DATA, {type: 'latest_count', latest_count: 100}).then((d) => {
      //   setSpreadsheetMetaData(d);
      // });
      setSpreadsheetMetaData({
        colHeaders: null,
        rowHeaders: null,
        colWidth: 100,
        data: [[], [], [], [], []],
        colLengths: 5,
        rendererType: 'NormalDataRenderer'
      });
    }
  }, []);

  const afterGetColHeader = (column: number, TH: HTMLTableCellElement, headerLevel: number) => {
    if (column > 0 && TH) {
      TH.setAttribute('title', TH.querySelector('span').textContent);
    }
  };

  return (
    <WidgetContainer {...rest} data={data} type="SpreadsheetWidget">
      <WidgetHeader
        {...rest}
        type="SpreadsheetWidget"
        icon={data.icon}
        id={id}
        title={data.title}
        onClose={() => console.log()}
      />
      <WidgetBody>
        {(spreadsheetMetaData?.isDataHierarchy === null || !spreadsheetMetaData?.isDataHierarchy) && (
          <BasicSpreadsheet spreadsheetMetaData={spreadsheetMetaData} afterGetColHeader={afterGetColHeader} />
        )}
        {spreadsheetMetaData?.isDataHierarchy !== null && spreadsheetMetaData?.isDataHierarchy && (
          <BasicSpreadsheetWithHierarchy
            spreadsheetMetaData={spreadsheetMetaData}
            afterGetColHeader={afterGetColHeader}
          />
        )}
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(SpreadsheetWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
