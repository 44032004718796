import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';

const ApiCallStatus = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.color.warning};
  align-self: center;
  margin-right: 10px;
  flex-shrink: 0;
  font-weight: bold;
  display: flex;
  gap: 2px;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

type IProps = {
  isApiCallFail?: boolean;
};

function WidgetApiCallFailStatus({isApiCallFail}: IProps) {
  return (
    <>
      {isApiCallFail && (
        <ApiCallStatus>
          <span>
            <FontAwesomeIcon icon={faCircleExclamation} />
          </span>

          <span>Fail</span>
        </ApiCallStatus>
      )}
    </>
  );
}

export default WidgetApiCallFailStatus;
