import styled from 'styled-components';
import {META_PFD_zINDEX, MOUSE_BUTTON} from 'components/mpfd/const';
import React, {useContext, useRef, useState} from 'react';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {transform, translate} from 'transformation-matrix';
import classnames from 'classnames';
import {Pos} from 'components/mpfd/type';
import SelectedAreaRectangle from 'components/mpfd/layer/parts/SelectedAreaRectangle';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  g {
    pointer-events: all;
  }
  z-index: ${META_PFD_zINDEX.REGION_SHAPE_LAYER};
  &.selected {
    z-index: ${META_PFD_zINDEX.ZOOM_ACTION_LAYER};
  }
`;

type IProps = {
  zoomViaPoint(e: React.MouseEvent): void;
  zoomViaArea(area: {x: number; y: number; w: number; h: number}): void;
};

function ZoomActionLayer({zoomViaPoint, zoomViaArea}: IProps) {
  const {dispatchToReducer, matrix, changeMatrix, state} = useContext(MetaPfdContext);
  const [zoomStartPos, setZoomStartPos] = useState<Pos>(null);
  const [zoomEndPos, setZoomEndPos] = useState<Pos>(null);
  const zoomStartPosRef = useRef(null);
  const zoomEndPosRef = useRef(null);
  const containerRef = useRef(null);
  const selectedTool = state.selectedTool;
  zoomStartPosRef.current = zoomStartPos;
  zoomEndPosRef.current = zoomEndPos;

  const previousTool = useRef<ToolIds | null>(null);

  const onMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const zoomActionLayerContainer = containerRef.current as HTMLDivElement;
    if (!zoomActionLayerContainer) return;

    switch (e.button) {
      case MOUSE_BUTTON.LEFT: {
        const startX = e.clientX - zoomActionLayerContainer.getBoundingClientRect().left;
        const startY = e.clientY - zoomActionLayerContainer.getBoundingClientRect().top;
        setZoomStartPos({x: startX, y: startY});
        setZoomEndPos({x: startX, y: startY});
        const onMouseMove = (e: MouseEvent): void => {
          const x = e.clientX - zoomActionLayerContainer.getBoundingClientRect().left;
          const y = e.clientY - zoomActionLayerContainer.getBoundingClientRect().top;
          setZoomEndPos({x, y});
        };
        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          if (zoomStartPosRef.current && zoomEndPosRef.current) {
            const w = Math.abs(zoomStartPosRef.current.x - zoomEndPosRef.current.x);
            const h = Math.abs(zoomStartPosRef.current.y - zoomEndPosRef.current.y);
            const area = {
              x: Math.min(zoomStartPosRef.current.x, zoomEndPosRef.current.x),
              y: Math.min(zoomStartPosRef.current.y, zoomEndPosRef.current.y),
              w,
              h
            };
            setZoomStartPos(null);
            setZoomEndPos(null);
            console.log(w, h);
            if (w > 1 && h > 1) {
              zoomViaArea(area);
            } else {
              zoomViaPoint(e);
            }

            console.log(w, h);
            if (w > 1 && h > 1) {
            }
          }
        };
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {once: true});
        break;
      }
      case MOUSE_BUTTON.WHEEL: {
        previousTool.current = selectedTool;
        const startX = e.clientX - zoomActionLayerContainer.getBoundingClientRect().left;
        const startY = e.clientY - zoomActionLayerContainer.getBoundingClientRect().top;
        const onMouseMove = (e: MouseEvent): void => {
          const x = e.clientX - zoomActionLayerContainer.getBoundingClientRect().left;
          const y = e.clientY - zoomActionLayerContainer.getBoundingClientRect().top;
          setZoomEndPos({x, y});
          const translateMatrix = translate(startX - x, startY - y);
          const newMat = transform([matrix, translateMatrix]);
          changeMatrix({...newMat});
        };

        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
        };
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {once: true});

        e.preventDefault();

        break;
      }
      case MOUSE_BUTTON.RIGHT: {
        break;
      }
      default: {
      }
    }
  };

  return (
    <Container onMouseDown={onMouseDown} ref={containerRef} className={classnames('selected')}>
      <SelectedAreaRectangle startPos={zoomStartPos} endPos={zoomEndPos} />
    </Container>
  );
}

export default ZoomActionLayer;
