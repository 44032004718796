import {useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import {IContextMenu} from 'components/common/context-menu/ContextMenu';

type IReturn = [(info: IContextMenu) => void];

function useContextMenu(): IReturn {
  const {showContextMenu} = useContext(CommonContext);

  const createContextMenu = (info: IContextMenu): void => {
    info.event.preventDefault();
    showContextMenu(info);
  };

  return [createContextMenu];
}

export default useContextMenu;
