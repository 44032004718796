import {AppContainer} from 'components/layout';
import splashImage from 'assets/images/LoginImage.png';
import React from 'react';
import styled from 'styled-components';
import LoginForm from 'components/auth/login/LoginForm';
import {useParams} from 'react-router-dom';
import PasswordForm from 'components/auth/login/PasswordForm';
import RegisterForm from 'components/auth/login/RegisterForm';

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 404px;
  height: 680px;
  justify-content: center;
  align-items: center;
`;

const RightSide = styled.div`
  width: 596px;
  height: 680px;
  img {
    border-radius: 0 10px 10px 0;
  }
`;

const AppSplash = styled.div`
  width: 1000px;
  height: 680px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.1);
  > a {
    margin: auto;
  }
`;

const LoginComponents = {
  main: LoginForm,
  password: PasswordForm,
  create: RegisterForm
};

function LoginScreen() {
  const {type} = useParams();
  const LoginComponent = LoginComponents[type || 'main'];

  return (
    <AppContainer type="home">
      <AppSplash>
        <LeftSide>
          <LoginComponent />
        </LeftSide>
        <RightSide>
          <img src={splashImage} alt="process metaverse" />
        </RightSide>
      </AppSplash>
    </AppContainer>
  );
}

export default LoginScreen;
