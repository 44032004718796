import {IPfdState, IAnnotation, AnnotationTypes} from 'components/mpfd/type';
import {getMatchNumber} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';

const defaultRegionColor = '#CF4C2C';

export const annotation = {
  annot2DefaultInfo: function (regions: IAnnotation[], targetRegion: IAnnotation) {
    const idx = regions.findIndex((item) => item?.id === targetRegion?.id);
    if (idx === -1) return {idx, annot: {type: 'null'} as IAnnotation, points: [], arrowPoints: []};
    const annot = {...regions[idx]};
    const points = [...(annot.points || [])];
    const arrowPoints = [...(annot?.arrowPoints || [])];
    return {idx, annot, points, arrowPoints};
  },
  pointIdx2Point: function (region: IAnnotation, pointIdx: number, point: {x: number; y: number}) {
    const pointsCopy = [...region.points];
    const p1 = pointsCopy[pointIdx];
    const p2 = pointsCopy[pointIdx + 1];
    let insertedPoint = {x: 0, y: 0};
    if (p1.x < p2.x) {
      insertedPoint = {...insertedPoint, x: p1.x + (p2.x - p1.x) * point.x};
    } else {
      insertedPoint = {...insertedPoint, x: p2.x + (p1.x - p2.x) * point.x};
    }
    if (p1.y < p2.y) {
      insertedPoint = {...insertedPoint, y: p1.y + (p2.y - p1.y) * point.y};
    } else {
      insertedPoint = {...insertedPoint, y: p2.y + (p1.y - p2.y) * point.y};
    }
    return {p1, p2, pointsCopy, insertedPoint};
  },
  singlePoint2SamePointIdxArr: function (regions: IAnnotation[], point: {x: number; y: number}, id: string) {
    const idxArrOfTargetPoint = [];
    const targetRegion = regions.find((item) => item.id === id);
    targetRegion.points.map((item, idx) => item.x === point.x && item.y === point.y && idxArrOfTargetPoint.push(idx));

    const idxArrOfArrowPoint = [];
    [...(targetRegion?.arrowPoints || [])].map(
      (item, idx) => item.x === point.x && item.y === point.y && idxArrOfArrowPoint.push(idx)
    );

    return {idxArrOfTargetPoint, idxArrOfArrowPoint};
  },
  name2nameAndIdx: function (annotName: string, regions: IAnnotation[], type: AnnotationTypes) {
    const regionNameAndId = getMatchNumber(annotName, type);
    let standardId = 0;
    const sameOriginSortedRegion = regions
      .map((r) => getMatchNumber(r.name, type))
      .filter(({name}) => name === regionNameAndId.name)
      .sort((a, b) => a.id - b.id);
    if (sameOriginSortedRegion.length === 0) {
      return {regionNameAndId, standardId};
    }
    const standard = sameOriginSortedRegion[sameOriginSortedRegion.length - 1];
    standardId = standard.id;
    if (standard.id === regionNameAndId.id) {
      standardId = regionNameAndId.id;
    }
    return {regionNameAndId, standardId};
  },
  modifyRegion: function (regions: IAnnotation[], targetRegion: IAnnotation, changeContents: Partial<IAnnotation>) {
    const defaultInfo = annotation.annot2DefaultInfo(regions, targetRegion);
    if (defaultInfo.idx === -1) return regions;
    if (changeContents !== null) {
      return regions.with(defaultInfo.idx, {...defaultInfo.annot, ...changeContents} as IAnnotation);
    } else {
      return (regions || []).filter((r: IAnnotation) => r?.id !== targetRegion?.id);
    }
  },
  movePointOrBlock: function (region: IAnnotation, x: number, y: number) {
    switch (region.type) {
      case 'point': {
        return {...region, x, y};
      }
      case 'box': {
        return {...region, x: x - region.w / 2, y: y - region.h / 2};
      }
      case 'port': {
        return {...region, x: x - region.w / 2, y: y - region.h / 2};
      }
      // case 'block': {
      //   return {...region, x, y};
      // }
    }
    return region;
  },
  resetFocus: function (regions: IAnnotation[], targetRegion: IAnnotation | null) {
    if (targetRegion === null) {
      return [...(regions || [])].map((r) => ({...r, editingLabels: false, highlighted: false}));
    } else {
      return [...(regions || [])].map((r) => ({
        ...r,
        highlighted: r?.id === targetRegion?.id,
        editingLabels: r?.id === targetRegion?.id
      }));
    }
  },
  getAnnotName: function (regions: IAnnotation[], defaultName: string) {
    let dName = defaultName;
    let dNum = 0;
    const sameOriginSortedRegion = regions
      .map((r) => getMatchNumber(r.name, r.type))
      .filter(({name}) => name === dName)
      .sort((a, b) => a.id - b.id);

    if (sameOriginSortedRegion.length > 0) {
      const standard = sameOriginSortedRegion[sameOriginSortedRegion.length - 1];
      dNum = standard.id + 1;
    }
    return {newAnnotName: dName, newAnnotNum: dNum};
  },
  reorderPoints: function (points: {x: number; y: number}[]) {
    let segments = [];
    for (let i = 0; i < points.length - 1; i += 2) {
      segments.push([points[i], points[i + 1]]);
    }

    const pointsEqual = (p1, p2) => p1.x === p2.x && p1.y === p2.y;

    let orderedSegments = [segments[0]];
    segments.splice(0, 1);

    while (segments.length > 0) {
      let lastPoint = orderedSegments[orderedSegments.length - 1][1];
      let foundConnection = false;

      for (let i = 0; i < segments.length; i++) {
        let currentSegment = segments[i];

        if (pointsEqual(lastPoint, currentSegment[0])) {
          orderedSegments.push(currentSegment);
          segments.splice(i, 1);
          foundConnection = true;
          break;
        } else if (pointsEqual(lastPoint, currentSegment[1])) {
          orderedSegments.push([currentSegment[1], currentSegment[0]]);
          segments.splice(i, 1);
          foundConnection = true;
          break;
        }
      }

      if (!foundConnection) {
        orderedSegments.push(segments[0]);
        segments.splice(0, 1);
      }
    }

    let orderedPoints = [];
    for (let segment of orderedSegments) {
      orderedPoints.push(segment[0]);
      orderedPoints.push(segment[1]);
    }

    return orderedPoints;
  },
  isEndpoints: function (points: {x: number; y: number}[], target: {x: number; y: number}) {
    const pointCount = new Map();

    points.forEach((point) => {
      const key = JSON.stringify(point);
      if (pointCount.has(key)) {
        pointCount.set(key, pointCount.get(key) + 1);
      } else {
        pointCount.set(key, 1);
      }
    });

    // Check if the target point is an endpoint
    const targetKey = JSON.stringify(target);
    return pointCount.get(targetKey) === 1;
  }
};

export const CONST = {
  state: {
    images: {},
    regions: [],
    selectedTool: 'select',
    regionClsList: ['Soft Sensor', 'Physical Sensor'],
    mode: null,
    history: [],
    showHighlightBox: true,
    saveName: '',
    isChanged: false,
    detectionInfo: {
      viewport: {width: 0, height: 0},
      detectedLines: [],
      detectedFileName: ''
    },
    customNodes: [],
    cfg: {
      isDisplayLabels: true,
      isDisplayDataValues: true,
      isLiveUpdateDataValues: false
    }
  } as IPfdState,
  detectionInfo: {
    viewport: {width: 0, height: 0},
    detectedLines: [],
    detectedFileName: ''
  },
  line: {
    type: 'expanding-line',
    unfinished: false,
    open: true,
    highlighted: false,
    color: '#803DEC',
    labelVisible: false
    // cls: defaultRegionCls,
  } as IAnnotation,
  point: {
    type: 'point',
    highlighted: true,
    editingLabels: true,
    dataType: undefined,
    color: defaultRegionColor,
    labelVisible: false
  } as IAnnotation,
  box: {
    type: 'box',
    w: 0,
    h: 0,
    highlighted: true,
    editingLabels: false,
    color: defaultRegionColor,
    labelVisible: false
  } as IAnnotation,
  port: {
    type: 'port',
    w: 0,
    h: 0,
    highlighted: true,
    editingLabels: false,
    color: '#19CC81',
    labelVisible: false
  } as IAnnotation
};

export const file = {};
