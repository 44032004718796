import {ReactElement} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import styled from 'styled-components';

const Container = styled.div``;

type IProps = {
  onSelect(): void;
  onClose(): void;
  // item: any;
};

function OptionDeleteAllModal({onSelect, onClose}: IProps): ReactElement {
  const onConfirm = (): void => {};
  return (
    <Container>
      <ModalWrapper type="sm">
        <ModalHeader onClose={onClose}>Confirm Delete</ModalHeader>
        <ModalBody>This will delete all these chat history?</ModalBody>
        <ModalFooter>
          <Button width={100} variant="danger" onClick={onConfirm}>
            Delete
          </Button>
          <Button variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </Container>
  );
}

export default OptionDeleteAllModal;
