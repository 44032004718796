import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    cursor: pointer;
  }
`;

type IProps = {
  keys: string;
  field: ITableField;
  onClick(name: string, title: string, actionType: string): void;
};

function Icon({keys, field, onClick}: IProps) {
  return (
    <Container onClick={() => onClick(keys, field?.key, field?.icon?.actionType)}>{field?.icon?.component}</Container>
  );
}

export default Icon;
