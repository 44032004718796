import useEventCallback from 'use-event-callback';
import {IAnnotation, layoutParamType} from 'components/mpfd/type';
import {applyToPoint, inverse} from 'transformation-matrix';

type IProps = {
  layoutParams: {current?: layoutParamType};
  mat: any;
};

function useProjectRegionBox({layoutParams, mat}: IProps) {
  const getEnclosingBox = (region: IAnnotation) => {
    // console.log(annotation)
    switch (region?.type) {
      case 'box': {
        return {x: region.x, y: region.y, w: region.w, h: region.h};
      }
      case 'port': {
        return {x: region.x, y: region.y, w: region.w, h: region.h};
      }

      case 'point': {
        return {x: region.x, y: region.y, w: 0, h: 0};
      }
      // case 'block': {
      //   return {x: region.x, y: region.y, w: 0, h: 0};
      // }
      case 'expanding-line': {
        return {x: region.x, y: region.y, w: region.w, h: region.h};
      }
      default: {
        return {x: 0, y: 0, w: 0, h: 0};
      }
    }
  };

  return useEventCallback((r: IAnnotation) => {
    const {iw, ih} = layoutParams.current;
    const bbox = getEnclosingBox(r);
    const margin = r?.type === 'point' ? 15 : 2;
    const cBox = {
      x: bbox.x * iw - margin,
      y: bbox.y * ih - margin,
      w: bbox.w * iw + margin * 2,
      h: bbox.h * ih + margin * 2
    };
    return {
      // ...mat.clone().inverse().applyToPoint(cBox.x, cBox.y),
      ...applyToPoint(inverse({...mat}), {x: cBox.x, y: cBox.y}),
      w: cBox.w / mat.a,
      h: cBox.h / mat.d
    };
  });
}

export default useProjectRegionBox;
