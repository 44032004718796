import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.ol`
  li {
    display: flex;
    //flex-flow: row nowrap;
    flex-direction: column;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    //justify-content: space-between;
    align-items: center;
    border-top: 1px solid #5a5a5a;
    transition: background-color 0.8s;

    &:last-child {
      border-bottom: 1px solid #5a5a5a;
    }

    &:hover {
      transition: background-color 0.1s;
      background-color: rgba(121, 108, 111, 0.2);
    }
  }
`;

function ActionMenuBody({children}: PropsWithChildren): ReactElement {
  return <Container>{children}</Container>;
}

export default ActionMenuBody;
