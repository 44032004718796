import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import {IApiReturnBasic} from 'api/data-types';
import {getApiHost} from 'api/function';

// const API_SERVER = process.env.REACT_APP_API_HOST;
const API_HOST = getApiHost();

const headers = {'Content-Type': 'application/json'};

export type IReturnLoginAPI = IApiReturnBasic & {
  status: string;
  data: {
    token: string;
  };
};

const authPost = async <T>(url: string, payLoad: object) => {
  const res = await fetch(API_HOST + url, {
    method: 'POST',
    headers,
    body: JSON.stringify(payLoad)
  });
  const data = await res.json();
  return {status: res.status, body: data as T};
};

export const areAllFieldsValid = (state: ILoginInputState): boolean => {
  console.log(Object.values(state).every((field) => field.value !== '' && field.valid));
  return (
    Object.values(state).every((field) => field.value !== '' && field.valid) &&
    !Object.values(state).some((item) => item?.passwordMismatch)
  );
};

export type ILoginRequestParams = {
  username: string;
  password: string;
};

export const loginRequest = async (payLoad: ILoginRequestParams) => {
  return authPost<IReturnLoginAPI>('/auth/login', payLoad);
};

export type IRegisterRequestParams = {
  lastName: string;
  firstName: string;
  username: string;
  password: string;
  email: string;
};

export const registerRequest = async (payLoad: IRegisterRequestParams) => {
  return authPost<IReturnLoginAPI>('/auth/register', payLoad);
};

export const verifyTokenRequest = async (token: string) => {
  return fetch(API_HOST + '/auth/verify_token', {
    method: 'GET',
    headers: {Authorization: `Bearer ${token}`}
  })
    .then((res) => res.json())
    .then((res) => res?.success);
};

export type IRequestResetPasswordParams = {
  email: string;
  domain: string;
};

export const resetPasswordEmailRequest = async (payLoad: IRequestResetPasswordParams) => {
  return authPost<IReturnLoginAPI>('/auth/request_reset_password', payLoad);
};

export type IResetPasswordParams = {
  token: string;
  password: string;
};

export const resetPasswordRequest = async (payLoad: IResetPasswordParams) => {
  return authPost<IReturnLoginAPI>('/auth/reset_password', payLoad);
};
