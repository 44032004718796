/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/Parts/Parts.gltf 
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';
import {Vector3} from '@react-three/fiber/dist/declarations/src/three-types';

export function ModelParts({blockVisibility}) {
  const {nodes, materials} = useGltfCustom('/gltf/Parts/Parts.gltf');

  console.log();

  return (
    <group dispose={null} scale={0.2}>
      {Object.keys(blockVisibility).map((key) =>
        blockVisibility[key] && nodes[`${key}_Parts_1`] ? (
          <mesh
            key={key}
            name={`Parts_${key}`}
            visible={blockVisibility[key]}
            geometry={nodes[`${key}_Parts_1`].geometry}
            material={materials.Simacro_Parts}
            position={nodes[`${key}_Parts`].position as Vector3}
            rotation={nodes[`${key}_Parts`].rotation}
            scale={nodes[`${key}_Parts`].scale}
          />
        ) : null
      )}
    </group>
  );
}

// useGLTF.preload('/gltf/Parts/Parts.gltf');
