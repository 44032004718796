import styled from 'styled-components';
import classnames from 'classnames';
import React, {ReactElement} from 'react';
import ManagementHeader from 'components/auth/management/parts/ManagementHeader';
import {IEditUserInfo, IManagementAsideInfo} from 'components/auth/management/type';

type StyleProps = {
  $isShow: boolean;
};

const Container = styled.div<StyleProps>`
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  pointer-events: ${(props) => (props.$isShow ? 'all' : 'none')};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  background-color: #f4f4f4;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.04);
  border-left: 1px solid #bbbbbb;
  transition: transform 0.2s ease-in-out;
  pointer-events: all;
  width: 478px;
  transform: translateX(984px);
  padding: 0;
  //* {
  //color: #575660;
  //}

  &.sm {
    width: 478px;
    padding: 40px;
  }
  &.lg {
    width: 984px;
    padding: 40px;
  }
  &.show {
    transform: translateX(0) !important;
  }
`;

const ManagementBody = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  box-sizing: border-box;
  padding-top: 106px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
  }
`;

type IProps = {
  asideInfo: IManagementAsideInfo;
  createView?: ReactElement;
  editView?: ReactElement;
  detailView?: ReactElement;
};
function ManagementAside({asideInfo, createView, editView, detailView}: IProps) {
  const headerTitle = () => {
    if (asideInfo?.header?.isDynamicTitle !== null && asideInfo?.header?.isDynamicTitle) {
      const fullName =
        (asideInfo?.info as IEditUserInfo).firstName + ' ' + (asideInfo?.info as IEditUserInfo).lastName || '';
      if (asideInfo?.type === 'edit' && editView) return asideInfo?.header?.title + fullName;
      else if (asideInfo?.type === 'detail' && detailView) return fullName + asideInfo?.header?.title;
    } else {
      return asideInfo?.header?.title;
    }
  };

  const headerDescription = () => {
    if (asideInfo?.header?.isDynamicDescription !== null && asideInfo?.header?.isDynamicDescription) {
      const userId = (asideInfo?.info as IEditUserInfo).username || '';
      if (asideInfo?.type === 'detail' && detailView) return '(' + userId + ')';
    } else {
      return asideInfo?.header?.description;
    }
  };

  const isShow = Boolean(asideInfo);

  return (
    <Container $isShow={isShow}>
      <Wrapper className={classnames(asideInfo?.size, isShow && 'show')} onMouseUp={(e) => e.stopPropagation()}>
        <ManagementHeader title={headerTitle()} description={headerDescription()} />
        <ManagementBody>
          {asideInfo?.type === 'create' && createView}
          {asideInfo?.type === 'edit' && editView}
          {asideInfo?.type === 'detail' && detailView}
        </ManagementBody>
      </Wrapper>
    </Container>
  );
}

export default ManagementAside;
