import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import Accordion from 'components/common/arrocdion/Accordian';
import {IPythonEditorCodRunResult} from 'components/pc/widgets/pythonEditor/types';

const Container = styled.div`
  width: 100%;
  height: 100%;

  pre {
    font-family: consolas;
    font-size: 14px;
    white-space: pre-line;
  }
`;

export type IUpdateResultStates = {
  installResultsUpdated: boolean;
  printOutUpdated: boolean;
  errorUpdated: boolean;
  outflowResultsUpdated: boolean;
};

type IProps = {
  codRunResultState?: [IPythonEditorCodRunResult, Dispatch<SetStateAction<IPythonEditorCodRunResult>>];
};
function PythonCodeResult({codRunResultState}: IProps) {
  const [codeRunResult] = codRunResultState;

  const [updateStates, setUpdateStates] = useState<IUpdateResultStates>({
    installResultsUpdated: false,
    printOutUpdated: false,
    errorUpdated: false,
    outflowResultsUpdated: false
  });

  const onClickOffUpdateDisplay = (index: number) => {
    setUpdateStates((prevStates) => {
      const newStates = {...prevStates};
      switch (index) {
        case 0:
          newStates.installResultsUpdated = false;
          break;
        case 1:
          newStates.printOutUpdated = false;
          break;
        case 2:
          newStates.errorUpdated = false;
          break;
        case 3:
          newStates.outflowResultsUpdated = false;
          break;
        default:
          break;
      }
      return newStates;
    });
  };

  useEffect(() => {
    // install result 조건
    if (codeRunResult?.install_results) {
      setUpdateStates((prev) => ({...prev, installResultsUpdated: true}));
    } else if (codeRunResult?.install_results.length === 0) {
      setUpdateStates((prev) => ({...prev, installResultsUpdated: false}));
    }

    // print out 조건
    if (codeRunResult?.print_out) {
      setUpdateStates((prev) => ({...prev, printOutUpdated: true}));
    } else if (codeRunResult?.print_out.length === 0) {
      setUpdateStates((prev) => ({...prev, printOutUpdated: false}));
    }

    // error 조건
    if (codeRunResult?.error) {
      setUpdateStates((prev) => ({...prev, errorUpdated: true}));
    } else if (codeRunResult?.error.length === 0) {
      setUpdateStates((prev) => ({...prev, errorUpdated: false}));
    }

    // outflow result 조건
    if (codeRunResult?.outflow_results) {
      setUpdateStates((prev) => ({...prev, outflowResultsUpdated: true}));
    } else if (codeRunResult?.outflow_results.length === 0) {
      setUpdateStates((prev) => ({...prev, outflowResultsUpdated: false}));
    }
  }, [codeRunResult?.install_results, codeRunResult?.print_out, codeRunResult?.error, codeRunResult?.outflow_results]);

  return (
    <Container>
      <Accordion
        updateResultStates={updateStates}
        headers={['Install Output', 'Output', 'Error', 'Outflow Result']}
        onClickOffUpdateDisplay={onClickOffUpdateDisplay}
      >
        <pre>{codeRunResult?.install_results}</pre>
        <pre>{codeRunResult?.print_out}</pre>
        <pre>{codeRunResult?.error}</pre>
        <pre>{codeRunResult?.outflow_results}</pre>
      </Accordion>
    </Container>
  );
}

export default PythonCodeResult;
