import {IShapeProps} from 'components/pc/common/shapes/type';
import {generatePath} from 'components/pc/common/shapes/parts/svg/utils';

function Diamond({width, height, ...svgAttributes}: IShapeProps) {
  const diamondPath = generatePath([
    [0, height / 2],
    [width / 2, 0],
    [width, height / 2],
    [width / 2, height]
  ]);

  return <path d={diamondPath} {...svgAttributes} />;
}

export default Diamond;
