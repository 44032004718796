import FormRow from 'components/forms/FormRow';
import {Button, SelectForm, SwitchForm, TextForm} from 'components/forms';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import ModalFooter from 'components/common/modal/ModalFooter';
import React, {ChangeEvent, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 91%;
  padding: 10px 0 0 0;
  color: #525f7f;
  flex-direction: column;
`;

const TextFormWrap = styled.div`
  display: flex;
  width: 26%;
`;

const ToggleWrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  transition: all 0.5s ease-in-out;
  justify-content: space-between;
  flex-shrink: 0;
`;

const defaultCfg = {
  rowIndexVal: '1',
  colIndexFirstVal: '1',
  colIndexTimeVal: '1',
  dataFeedingIntervalVal: '1',
  dataFeedingIntervalUnit: 1000 * 60,
  autoRepeat: true
};

export type IDataSheetCfg = {
  rowIndexVal?: string;
  colIndexFirstVal?: string;
  colIndexTimeVal?: string;
  dataFeedingIntervalVal?: string;
  dataFeedingIntervalUnit?: number;
  autoRepeat?: boolean;
};

type IProps = {
  setSettingModalOpen(b: boolean): void;
};

function DataSheetSetting({setSettingModalOpen}: IProps) {
  const [cfg, setCfg] = useState<IDataSheetCfg>(defaultCfg);

  const onChangeRowIndexVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCfg((prev) => ({...prev, rowIndexVal: target.value}));
  };

  const onChangeColIndexFirstVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCfg((prev) => ({...prev, colIndexFirstVal: target.value}));
  };

  const onChangeColIndexTimeVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCfg((prev) => ({...prev, colIndexTimeVal: target.value}));
  };

  const onChangeIntervalVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCfg((prev) => ({...prev, dataFeedingIntervalVal: target.value}));
  };

  const onChangeIntervalUnit = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    Number(target.value);
    setCfg((prev) => ({...prev, dataFeedingIntervalUnit: Number(target.value)}));
  };

  const onChangeAutoRepeat = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCfg((prev) => ({...prev, autoRepeat: target.checked}));
  };

  const onCancel = () => {
    setSettingModalOpen(false);
  };

  const onConfirm = () => {
    setSettingModalOpen(false);
  };
  return (
    <Container className="data-sheet-setting">
      <FormRow header="Row index for first data">
        <TextFormWrap className="text-form-wrap">
          <TextForm
            type="number"
            name="time-value"
            value={cfg?.rowIndexVal || ''}
            onChange={(e) => onChangeRowIndexVal(e)}
          />
        </TextFormWrap>
      </FormRow>
      <FormRow header="Column index for first data">
        <TextFormWrap className="text-form-wrap">
          <TextForm
            type="number"
            name="time-value"
            value={cfg?.colIndexFirstVal || ''}
            onChange={(e) => onChangeColIndexFirstVal(e)}
          />
        </TextFormWrap>
      </FormRow>
      <FormRow header="Column index for time data">
        <TextFormWrap className="text-form-wrap">
          <TextForm
            type="number"
            name="time-value"
            value={cfg?.colIndexTimeVal || ''}
            onChange={(e) => onChangeColIndexTimeVal(e)}
          />
        </TextFormWrap>
      </FormRow>
      <FormRow header="Data feeding interval">
        <TextFormWrap className="text-form-wrap">
          <TextForm
            type="number"
            name="time-value"
            value={cfg?.dataFeedingIntervalVal || ''}
            onChange={(e) => onChangeIntervalVal(e)}
          />
        </TextFormWrap>
        <SelectForm
          name={'time-unit'}
          options={TimeUnitOptions}
          value={cfg?.dataFeedingIntervalUnit}
          onChange={(e) => onChangeIntervalUnit(e)}
        />
      </FormRow>
      <FormRow header="Auto repeat">
        <ToggleWrap className="toggle-wrap-auto-repeat">
          <div style={{marginRight: '-20px'}}>
            <SwitchForm
              name="data-sheet-toggle"
              id="auto-repeat-switch"
              onChange={(e) => onChangeAutoRepeat(e)}
              checked={cfg?.autoRepeat}
              checkedValue={true}
              uncheckedValue={false}
            />
          </div>
        </ToggleWrap>
      </FormRow>
      <ModalFooter type="multi">
        <Button className="modal-btn" onClick={onConfirm} variant="primary">
          Confirm
        </Button>
        <Button className="modal-btn" onClick={onCancel} variant="cancel">
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default DataSheetSetting;
