import React, {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import {Edge, Node, ReactFlowProvider} from 'reactflow';
import useApi from 'api/useApi';
import {CommonContext} from 'components/common/CommonProvider';
import {ISaveFile} from 'components/menu/pulldown/ProcessCanvasSaveAsModal';
import {NEW_FILE_ROUTE, processCanvasMenu} from 'components/menu/constants';
import {
  IApiReturnBasic,
  IFile,
  IGetFileListReturn,
  IImportFileReturn,
  IImportFileReturnData,
  IReactFlowLayout
} from 'api/data-types';
import {ICloseWarningProcessCanvas} from 'components/menu/pulldown/ProcessCanvasCloseWarningModal';
import {AuthContext} from 'components/auth/AuthProvider';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {ToolIds} from 'components/pc/common/Toolbox';
import {ISaveStatusModalState} from 'components/menu/pulldown/SaveLoadingModal';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import {IContextMenu} from 'components/common/context-menu/ContextMenu';
import {ISocketFileManage} from 'socket/types';
import {IBusyFile} from 'components/common/types';
import useBusyFileReducer from 'api/useBusyFileReducer';

type FileListActionType = {type: 'ON_LOAD'; list: IFile[]};

function reduceFileList(state: IFile[], action: FileListActionType): IFile[] {
  switch (action.type) {
    case 'ON_LOAD':
      return action.list;
    default:
      return [];
  }
}

type FileActionTypes =
  | {type: 'SAVE_AS'; fileName: string}
  | {type: 'RENAME'; newFileName: string}
  | {type: 'CLOSE'}
  | {type: 'CREATE'; owner: string}
  | {type: 'LOAD'; file: IFile}
  | {type: 'ON_CANVAS_CHANGE'}
  | {type: 'ON_SAVE'}
  | {type: 'ON_SAVE_AS'; file: IFile}
  | {type: 'LOCAL_SAVE'; file: IFile; nodes: Node[]; edges: Edge[]; isFreeze: boolean}
  | {type: 'TRANSFER_OWNERSHIP'; owner: string}
  | {type: 'CHANGE_ACCESSIBILITY'}
  | {type: 'ALLOW_COPY'};

function reduceFile(state: IFile, action: FileActionTypes): IFile | null {
  switch (action.type) {
    case 'SAVE_AS':
      return {...state, fileName: action.fileName};
    case 'RENAME':
      return {...state, fileName: action.newFileName, public: state.public};
    case 'CLOSE':
      return null;
    case 'CREATE':
      return {
        fileName: undefined,
        fileType: 'process_canvas',
        owner: action.owner,
        pidName: '',
        stateData: [],
        localInfo: {},
        _id: 'new-file'
      };
    case 'LOAD':
      return {
        ...action.file,
        localInfo: {
          ...action?.file?.localInfo
        }
      };
    case 'ON_CANVAS_CHANGE':
      if (state) {
        return {
          ...state,
          localInfo: {
            ...state?.localInfo,
            isChanged: true
          }
        };
      } else {
        return state;
      }
    case 'ON_SAVE':
      return {
        ...state,
        localInfo: {
          ...state?.localInfo,
          isChanged: false
        }
      };
    case 'ON_SAVE_AS':
      return {
        ...action.file,
        localInfo: {
          ...state?.localInfo,
          isChanged: false
        }
      };
    case 'LOCAL_SAVE':
      return {
        ...action.file,
        stateData: {nodes: action.nodes, edges: action.edges, frozen: action?.isFreeze},
        localInfo: {
          ...state.localInfo
        }
      };
    case 'TRANSFER_OWNERSHIP':
      return {...state, owner: action.owner};
    case 'CHANGE_ACCESSIBILITY':
      return {...state, public: !state.public};
    case 'ALLOW_COPY':
      return {...state, allow_copy: !state.allow_copy};
    default:
      return;
  }
}

const defaultFileListState: IFile[] = [];
const defaultFileState: IFile | null = null;

export type IProcessCanvasContext = {
  accessibilityModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  activeToolState: [ToolIds, React.Dispatch<React.SetStateAction<ToolIds>>];
  allowCopyModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  busyFileList: IBusyFile[];
  canvasEnteredState: [boolean, Dispatch<SetStateAction<boolean>>];
  closeModalState: [ICloseWarningProcessCanvas, Dispatch<SetStateAction<ICloseWarningProcessCanvas>>];
  dataSettingModalState: [string, Dispatch<SetStateAction<string>>];
  dockState: [string[], Dispatch<SetStateAction<string[]>>];
  enteredSpacebarState: [boolean, Dispatch<SetStateAction<boolean>>];
  exportModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  file: IFile | null;
  fileList: IFile[];
  hasMiniMapState: [boolean, Dispatch<SetStateAction<boolean>>];
  hasSmartAlignState: [boolean, Dispatch<SetStateAction<boolean>>];
  hasZoomScaleState: [boolean, Dispatch<SetStateAction<boolean>>];
  importModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  isAllowCopy: boolean;
  isFileOwner: boolean;
  isFilePrivate: boolean;
  isFreezeState: [boolean, Dispatch<SetStateAction<boolean>>];
  isNewNameState: [boolean, Dispatch<SetStateAction<boolean>>];
  isProcessCanvasModalOpened: boolean;
  isPublic: boolean;
  isRename: boolean;
  isShowOwnerState: [boolean, Dispatch<SetStateAction<boolean>>];
  isShowPublicState: [boolean, Dispatch<SetStateAction<boolean>>];
  isTransferOwnerState: [boolean, Dispatch<SetStateAction<boolean>>];
  ownershipModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  panningState: [boolean, Dispatch<SetStateAction<boolean>>];
  prevOwner: string;
  saveAsModalState: [ISaveFile, Dispatch<SetStateAction<ISaveFile>>];
  saveLoadingModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  saveState: [ISaveStatusModalState, Dispatch<SetStateAction<ISaveStatusModalState>>];
  socketMessageState: [ISocketFileManage, React.Dispatch<React.SetStateAction<ISocketFileManage>>];
  addBusyFile(busyFile: IBusyFile): void;
  allowCopy(): Promise<boolean>;
  changeAccessibility(): Promise<boolean>;
  checkIsChanged(
    confirmAction: () => void,
    actionType: string,
    afterActionAddress?: string,
    contents?: string | ReactNode,
    confirmLabel?: string
  ): boolean;
  close(): void;
  create(): void;
  createProcessCanvasContextMenu(info: IContextMenu): void;
  exportFile(fileName: string): Promise<Blob | null>;
  getFileById(id: string): IFile;
  getFileList(): Promise<IFile[]>;
  importFile(targetFile: File): Promise<IImportFileReturnData | null>;
  load(file: IFile): void;
  localSave(nodes: Node[], edges: Edge[]): void;
  onCanvasChange(): void;
  remove(id: string): Promise<IApiReturnBasic>;
  removeBusyFile(id: string): void;
  rename(newFileName: string): Promise<void>;
  save(fileName: string, nodes: Node[], edges: Edge[], isSaveAs: boolean): Promise<IFile>;
  setIsAllowCopy(isAllowCopy: boolean): void;
  setIsPublic(isPublic: boolean): void;
  setIsRename(isRename: boolean): void;
  setPrevOwner(prevOwner: string): void;
  transferOwnership(): Promise<boolean>;
};

export const ProcessCanvasContext: Context<IProcessCanvasContext> = createContext(null);

function ProcessCanvasProvider({children}: PropsWithChildren) {
  const api = useApi();
  const common = useContext(CommonContext);
  const {userProfile, ownerName} = useContext(AuthContext);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;
  const [fileList, dispatchFileList] = useReducer(reduceFileList, defaultFileListState);
  const [busyFileList, addBusyFile, removeBusyFile] = useBusyFileReducer();
  const saveLoadingModalState = useState<boolean>(false);
  const [isShowSaveLoadingModal, setIsShowSaveLoadingModal] = saveLoadingModalState;
  const saveState = useState<ISaveStatusModalState>();
  const [, setSaveLoadingState] = saveState;
  const saveAsModalState = useState<ISaveFile>();
  const dataSettingModalState = useState<string>();
  const isFreezeState = useState(false);
  const [isFreeze, setIsFreeze] = isFreezeState;
  const hasSmartAlignState = useState(true);
  const viewPullDownMenu = processCanvasMenu.find((menu) => menu.id === 'view').children;
  const hasMiniMapState = useState(viewPullDownMenu.find((menu) => menu.id === 'minimap').checked);
  const hasZoomScaleState = useState(viewPullDownMenu.find((menu) => menu.id === 'zoom-panel').checked);
  const closeModalState = useState<ICloseWarningProcessCanvas>();
  const importModalState = useState<boolean>(false);
  const allowCopyModalState = useState<boolean>(false);
  const ownershipModalState = useState<boolean>(false);
  const accessibilityModalState = useState<boolean>(false);
  const exportModalState = useState<boolean>(false);
  const canvasEnteredState = useState(false);
  const panningState = useState<boolean>(false);
  const enteredSpacebarState = useState<boolean>(false);
  const activeToolState = useState<ToolIds>('select');
  const [, setCloseModal] = closeModalState;
  // const latestTagHandlerForTable = useLatestTagHandler({type: 'latest_count', latest_count: 1});
  // const latestTagHandlerForChart = useLatestTagHandler({type: 'latest_count', latest_count: 10});
  const [prevOwner, setPrevOwner] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [isAllowCopy, setIsAllowCopy] = useState(true);
  const isShowPublicState = useState<boolean>(true);
  const isShowOwnerState = useState<boolean>(true);
  const isTransferOwnerState = useState<boolean>(false);
  const isNewNameState = useState<boolean>(false);
  const socketMessageState = useState<ISocketFileManage>(null);
  const dockState = useState<string[]>([]);
  const [dockedIdList, setDockedIdList] = dockState;
  const [file, dispatchFile] = useReducer(reduceFile, defaultFileState);
  const isFileOwner = Boolean(file?.owner && file?.owner === userProfile?.username);
  const isFilePrivate = Boolean(file?.owner && file?.owner === userProfile?.username && file.public === true);
  const processCanvasFileRef = useRef(null);
  const isFreezeRef = useRef(null);
  const isProcessCanvasModalOpened =
    allowCopyModalState[0] ||
    ownershipModalState[0] ||
    exportModalState[0] ||
    Boolean(closeModalState[0]) ||
    Boolean(saveAsModalState[0]) ||
    importModalState[0] ||
    accessibilityModalState[0];

  processCanvasFileRef.current = file;
  isFreezeRef.current = isFreeze;

  const getFileList = async (): Promise<IFile[]> => {
    const response = await api.post<IGetFileListReturn>('/file_manage/get_file_list', {
      fileType: 'process_canvas',
      exceptState: true
    });

    if (response) {
      const list = response?.data as IFile[];
      dispatchFileList({type: 'ON_LOAD', list});
      return list;
    }
  };

  const getFileById = (id: string): IFile => {
    return fileList.find((file) => file._id === id);
  };

  const remove = (_id: string) => {
    return api.post<IApiReturnBasic>('/file_manage/delete_file', {_id, fileType: 'process_canvas'});
  };

  const load = (file: IFile) => {
    dispatchFile({type: 'LOAD', file});
    if (window.location.pathname !== `/pc/${NEW_FILE_ROUTE}`) {
      common.addNotice({text: 'Load complete Process Canvas file.'});
    }
    setIsFreeze(Boolean((file?.stateData as IReactFlowLayout)?.frozen));
    setIsPublic(Boolean(file?.public));
    // MIGRATION-5 : allow_copy 값이 없는 예전 저장 파일은 기본적으로 true 로 설정
    file.allow_copy = file?.allow_copy === undefined ? true : Boolean(file?.allow_copy);
    setIsAllowCopy(file.allow_copy);
    setDockedIdList((file?.stateData as IReactFlowLayout)?.dockedIdList || []);
  };

  const onCanvasChange = (): void => {
    const isChangedFlag = processCanvasFileRef.current?.localInfo?.isChanged;
    if (!isChangedFlag && file?.stateData) {
      setTimeout(() => {
        dispatchFile({type: 'ON_CANVAS_CHANGE'});
      }, 0);
    }
  };

  const rename = async (newFileName: string): Promise<void> => {
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});

    const updateData = {
      file_id: file._id,
      file_type: 'process_canvas',
      update: {
        fileName: newFileName
      }
    };
    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((res) => {
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);
      if (res.success) {
        dispatchFile({type: 'RENAME', newFileName});
      }
    });
  };

  const save = async (fileName: string, nodes: Node[], edges: Edge[], isSaveAs: boolean): Promise<IFile> => {
    setSaveLoadingState(
      !isSaveAs
        ? {title: 'Save', stepMessage1: 'Saving current file'}
        : {title: 'Save As', stepMessage1: 'Saving current file'}
    );
    const fileData = {
      ...file,
      owner: userProfile?.username,
      public: isPublic,
      allow_copy: isAllowCopy,
      fileName,
      stateData: {
        nodes: [...(nodes || [])].map((item) => ({...item, selected: false})),
        edges,
        frozen: Boolean(isFreeze),
        dockedIdList
      }
    } as IFile;
    if (isSaveAs || fileData._id === 'new-file') {
      delete fileData._id;
    }
    const formData = new FormData();
    formData.append('data', JSON.stringify(fileData));

    return api.post<IApiReturnBasic>('/file_manage/save_file', formData, {type: 'formData'}).then((response) => {
      setTimeout(
        () =>
          setSaveLoadingState(
            !isSaveAs
              ? {title: 'Save', stepMessage2: 'Save Completed'}
              : {title: 'Save As', stepMessage2: 'Save Completed'}
          ),
        1000
      ); // modal이 1초 이상 보이도록 하기 위함

      common.addNotice({text: 'Save complete Process Canvas file.'});

      return getFileList().then(() => {
        const file = response?.data as IFile;
        if (isSaveAs) {
          dispatchFile({type: 'ON_SAVE_AS', file});
        } else {
          dispatchFile({type: 'ON_SAVE'});
        }
        return file;
      });
    });
  };

  const localSave = (nodes: Node[], edges: Edge[]) => {
    if (processCanvasFileRef.current) {
      dispatchFile({
        type: 'LOCAL_SAVE',
        nodes: [...(nodes || [])].map((item) => ({...item, selected: false})),
        edges,
        isFreeze: isFreezeRef.current,
        file: processCanvasFileRef.current
      });
    }
  };

  const create = () => {
    setIsFreeze(false);
    dispatchFile({type: 'CREATE', owner: userProfile?.username});
    common.addNotice({text: 'Created Process Canvas file.'});
  };

  const close = (): void => {
    dispatchFile({type: 'CLOSE'});
  };

  const checkIsChanged = (
    confirmAction: () => void,
    actionType: string,
    afterActionAddress?: string,
    contents?: string | ReactNode,
    confirmLabel?: string
  ): boolean => {
    const isChangedFlag = processCanvasFileRef.current?.localInfo?.isChanged;
    if (isChangedFlag) {
      setCloseModal({
        title: 'File Changed',
        content: contents
          ? contents
          : !isFileOwner
            ? `This layout file has unsaved changes!
            However, you cannot save to this specific layout file 
            because your user ID does not match the file owner.
            Please save your changes as a new and distinct layout file.`
            : `There are unsaved changes! 
            Do you want to save this file?`,
        fileName: file?.fileName,
        isSaveAs: false,
        actionType,
        confirmAction,
        confirmLabel: !isFileOwner ? 'Save As New File' : confirmLabel,
        confirmLabelWidth: 130
      });
      return false;
    } else {
      confirmAction();
      return true;
    }
  };

  /**
   * Export 기능 구현
   * export 가 성공하면 Blob 객체를 반환하고 실패하면 null 을 반환
   * @param fileName
   */
  const exportFile = async (fileName: string): Promise<Blob | null> => {
    const fileData = {
      fileName,
      fileType: file.fileType,
      _id: file._id,
      version: common.version
    };

    const response = await api.exportFile('/file_manage/export_file', fileData);
    if (response) {
      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');
      // 응답 blob 이 뭔가 시원찮으면 실패 리턴
      if (!contentDisposition) return null;

      let filename = '';
      const filenameRegex = /filename\*?=(?:UTF-8'')?([^;]*)/i;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = decodeURIComponent(matches[1]);
      }

      if (!filename) return null;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return blob;
    }

    return null;
  };

  /**
   * Import 기능 구현
   * 요청 성공하면 IImportFileReturnData 를 반환
   * 실패하면 null 반환
   * @param targetFile
   */
  const importFile = async (targetFile: File): Promise<IImportFileReturnData | null> => {
    const file = targetFile;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('info', JSON.stringify({owner: userProfile?.username}));
    const response = await api.post<IImportFileReturn>('/file_manage/import_file', formData, {type: 'formData'});

    if (response) {
      return response.data;
    }
    // 데이터가 올바르게 받지 못했다면 null 을 반환
    return null;
  };

  const allowCopy = async (): Promise<boolean> => {
    // how to use: update isPublic after calling changeAccessibility function
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});

    const updateData = {
      file_id: file._id,
      file_type: 'process_canvas',
      update: {
        allow_copy: !isAllowCopy
      }
    };

    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((response) => {
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);
      if (response.success) {
        dispatchFile({type: 'ALLOW_COPY'});
        return response.success;
      }
    });
  };

  const transferOwnership = async (): Promise<boolean> => {
    const updateData = {
      file_id: file._id,
      file_type: 'process_canvas',
      update: {
        owner: ownerName
      }
    };

    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((response) => {
      if (response.success) {
        dispatchFile({type: 'TRANSFER_OWNERSHIP', owner: updateData.update.owner});
        return response.success;
      }
    });
  };

  const changeAccessibility = async (): Promise<boolean> => {
    // how to use: update isPublic after calling changeAccessibility function
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});

    const updateData = {
      file_id: file._id,
      file_type: 'process_canvas',
      update: {public: !isPublic}
    };
    return api.post<IApiReturnBasic>('/file_manage/update_file_data', updateData).then((response) => {
      setTimeout(
        () =>
          setSaveLoadingState({
            title: 'Save Successful',
            stepMessage2: `This file has successfully been saved and set as ${!isPublic ? 'public' : 'private'}`
          }),
        1000
      );
      // common.addNotice({text: `Change complete to ${isPublic ? 'private' : 'public'}`});
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);

      if (response.success) {
        dispatchFile({type: 'CHANGE_ACCESSIBILITY'});
        return response.success;
      }
    });
  };

  const [createContextMenu] = useContextMenu();
  const createProcessCanvasContextMenu = (info: IContextMenu) => {
    if (activeToolState[0] === 'select') {
      createContextMenu(info);
    }
  };

  //token이 변경되거나, private 파일의 ownership이 이전됐을 때 변경 사항 적용
  useEffect(() => {
    if (token && userProfile) {
      getFileList().then(() => {});
    }
  }, [token, userProfile]);

  const collection = {
    accessibilityModalState,
    activeToolState,
    allowCopyModalState,
    busyFileList,
    canvasEnteredState,
    closeModalState,
    dataSettingModalState,
    dockState,
    enteredSpacebarState,
    exportModalState,
    file,
    fileList,
    hasMiniMapState,
    hasSmartAlignState,
    hasZoomScaleState,
    importModalState,
    isAllowCopy,
    isFileOwner,
    isFilePrivate,
    isFreezeState,
    isNewNameState,
    isProcessCanvasModalOpened,
    isPublic,
    isRename,
    isShowOwnerState,
    isShowPublicState,
    isTransferOwnerState,
    ownershipModalState,
    panningState,
    prevOwner,
    saveAsModalState,
    saveLoadingModalState,
    saveState,
    socketMessageState,
    // onLoad,
    addBusyFile,
    allowCopy,
    changeAccessibility,
    checkIsChanged,
    close,
    create,
    createProcessCanvasContextMenu,
    exportFile,
    getFileById,
    getFileList,
    importFile,
    load,
    localSave,
    onCanvasChange,
    remove,
    removeBusyFile,
    rename,
    save,
    setIsAllowCopy,
    setIsPublic,
    setIsRename,
    setPrevOwner,
    transferOwnership
  };

  return (
    <ProcessCanvasContext.Provider value={collection}>
      <ReactFlowProvider>{children}</ReactFlowProvider>
    </ProcessCanvasContext.Provider>
  );
}

export default ProcessCanvasProvider;
