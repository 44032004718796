import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common/index';
import {Button, TextForm} from 'components/forms';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ITimeSeriesLoader} from 'components/pc/widgets/timeseries/types';
import FormRow from 'components/forms/FormRow';
import {toSignificantDigit} from 'utils/number-utils';
import {DataContext} from 'api/DataProvider';
import dayjs from 'dayjs';
import {IApiReturnBasic} from 'api/data-types';
import {api} from 'api/api';
import styled from 'styled-components';

const TextValue = styled.span`
  color: #005da9;
`;

type IProps = {
  loader: ITimeSeriesLoader;
  onChangeNodeInfoModal: (bool: boolean, keys: string[]) => void;
  selectedNodeKeys: string[];
};

function NodeInfoModal({loader, selectedNodeKeys, onChangeNodeInfoModal}: IProps) {
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  const [unit, setUnit] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onCancel()
  });

  const nodeInfo = loader.series.find((i) => i.keys === selectedNodeKeys);

  useEffect(() => {
    setUnit(nodeInfo?.unit);
    setDescription(nodeInfo?.description);
  }, [nodeInfo]);

  const onCancel = (): void => {
    onChangeNodeInfoModal(false, []);
  };

  const onClickConfirm = () => {
    const updateNodeInfo = {
      unit: unit,
      description: description,
      db_name: nodeInfo?.database,
      feature: nodeInfo?.keys
    };

    return api.post<IApiReturnBasic>('/node_manage/update_node_info', updateNodeInfo).then((response) => {
      if (response.success) {
        loader.series.forEach((item) => {
          if (item.keys === selectedNodeKeys) {
            item.unit = unit;
            item.description = description;
            loader.refreshSeries();
          }
        });
        onChangeNodeInfoModal(false, []);
      }
    });
  };

  const onChangeUnit = (e: ChangeEvent<HTMLInputElement>) => {
    setUnit(e.target.value);
  };

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const currentValue = toSignificantDigit(
    nodeInfo?.data[nodeInfo?.data.length - 1][1],
    globalSettings?.significantDigit
  ).toString();

  const lastUpdate = dayjs(nodeInfo?.data[nodeInfo?.data.length - 1][0]).toString();

  const formattedKeys = nodeInfo?.keys?.join('-');

  return (
    <ModalWrapper type="node-info">
      <ModalHeader onClose={onCancel}>Node Information</ModalHeader>
      <ModalBody align="top">
        <FormRow type="double" header="Name">
          <TextValue>{nodeInfo?.database + '-' + formattedKeys}</TextValue>
        </FormRow>
        <FormRow type="double" header="Current Value">
          <TextValue>{currentValue}</TextValue>
        </FormRow>
        <FormRow type="double" header="Last Update">
          <TextValue>{lastUpdate}</TextValue>
        </FormRow>
        <FormRow type="double" header="Unit">
          <TextForm value={unit} width={280} onChange={onChangeUnit} />
        </FormRow>
        <FormRow header="Description" type="multiLine-Header">
          <TextForm
            type="textarea"
            value={description}
            width={518}
            height={100}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={onChangeDescription}
          />
        </FormRow>
      </ModalBody>
      <ModalFooter>
        <Button width={100} onClick={onClickConfirm}>
          Update
        </Button>
        <Button variant="cancel" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default NodeInfoModal;
