import React, {useCallback, useContext} from 'react';
import {MouseEvent} from 'react';
import {convertPoint2String, getModeInfo, hexToRGBA} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import classnames from 'classnames';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {annotation} from 'components/mpfd/hooks/functions/action-calculation-functions';
import AnnotationGroup from 'components/mpfd/annotation/parts/AnnotationGroup';

type PolyLineSvgProps = {
  $createWithPrimary?: boolean;
  $selectedTool?: string;
  $createMode?: boolean;
};

const PolyLine = styled.polyline<PolyLineSvgProps>`
  cursor: ${(props) =>
    props.$createWithPrimary
      ? ''
      : props.$selectedTool === 'line-eraser'
        ? ''
        : props.$selectedTool === 'line-splitter'
          ? ''
          : ''};
  position: absolute;
  z-index: ${META_PFD_zINDEX.REGION_COMPONENTS_POLYLINE};
  fill: none;
  //transition: all 0.5s ease;
  opacity: 0.5;
  pointer-events: ${(props) => (props.$createMode ? 'none' : '')};
  &.polyLineHoverType {
    &:hover {
      opacity: 1;
    }
  }
  &.highlighted {
    opacity: 1;
  }
  &.isDetected {
    stroke: #eea7c1;
  }
`;

const LinePointCircle = styled.circle`
  z-index: 1;
  &.draw-expanding-line-mode {
    pointer-events: none;
  }
  &.eraserMode {
    pointer-events: none;
  }
`;

type IProps = {
  iw: number;
  ih: number;
  region: IAnnotation;
  drawLineMode: boolean;
  polyLineHoverType: boolean;
  pointOfLineHoverType: boolean;
  createWithPrimary: boolean;
  selectedTool?: string;
  mode: any;
  cfg: IPfdCfg;
  onMouseDown(e: MouseEvent, idx?: number): void;
  onMouseDownPointsOfLine(e: MouseEvent, idx?: number, point?: {x: number; y: number}): void;
  onMouseUpPointsOfLine(e: MouseEvent, idx?: number, point?: {x: number; y: number}): void;
};

function Line({
  iw,
  ih,
  mode,
  region,
  drawLineMode,
  polyLineHoverType,
  pointOfLineHoverType,
  createWithPrimary,
  selectedTool,
  cfg,
  onMouseDown,
  onMouseDownPointsOfLine,
  onMouseUpPointsOfLine
}: IProps) {
  const [pointStringList, polyLineList, uniquePointList] = convertPoint2String(region, iw, ih);
  const [createContextMenu] = useContextMenu();
  const {dispatchToReducer} = useContext(MetaPfdContext);

  const {
    pointMoveMode,
    drawExpandingLine,
    annotationCircle,
    lineGroupIdx,
    GroupOfLine,
    moveTargetIsEndPoint,
    mergeCandidatePointBorderColor,
    annotationCircleBorderColor
  } = getModeInfo(mode, region);
  const startArrowId = 'start-arrow-' + region.id;
  const endArrowId = 'end-arrow-' + region.id;

  const handleMouseDown = useCallback(
    (e: MouseEvent<SVGGElement>, idx: number) => {
      onMouseDown(e, idx);
    },
    [onMouseDown]
  );

  const handleMouseDownPoints = useCallback(
    (e: MouseEvent<SVGGElement>, idx: number, point: {x: number; y: number}) => {
      onMouseDownPointsOfLine(e, idx, point);
    },
    [onMouseDownPointsOfLine]
  );

  const handleMouseUpPoints = useCallback(
    (e: MouseEvent<SVGGElement>, idx: number, point: {x: number; y: number}) => {
      onMouseUpPointsOfLine(e, idx, point);
    },
    [onMouseUpPointsOfLine]
  );
  if (pointStringList.length % 2 !== 0) {
    return null;
  }

  /**
   * 관련 정보를 state 저장해야하기에 dispatch reducer는 여전히 존재
   * context menu 띄우는것은 onContextMenu를 쓰는곳에서
   */

  const onContextMenuPane = (event: MouseEvent, idx: number, point: {x: number; y: number}) => {
    // console.log('>>> ', event.pageX, event.pageY, event);
    event.preventDefault();
    const targetPoint = point;
    let count = 0;
    region.points.map((item) => item.x === targetPoint.x && item.y === targetPoint.y && count++);
    if (count > 1) {
      return;
    }

    const active = [...(region.arrowPoints || [])].some((item) => item.x === targetPoint.x && item.y === targetPoint.y);
    const list = [
      {
        label: `${active ? 'Remove' : 'Add'} arrow head`,
        value: `${active ? 'Remove' : 'Add'}-arrow-head`,
        callback: () => {
          dispatchToReducer({type: 'APPLY_NORMAL_ARROW_TO_POINT'});
        }
      }
    ];
    onMouseUpPointsOfLine(event, idx, point);
    createContextMenu({event, list});
  };

  /**
   * Line 드로잉 모드가 끝났는데 (!mode)
   * region candidate point 가 존재한다 =>  비정상 종료 추측
   * region?.points?.length 가 홀수이다 => 비정상 종료 추측 (항상짝수여야함)
   * 삭제 조치
   */
  if (region?.candidatePoint && region?.points?.length % 2 !== 0 && !mode) {
    dispatchToReducer({type: 'DELETE_REGIONS', regions: [region]});
  }

  return (
    <>
      <defs>
        <marker
          id={startArrowId}
          viewBox="0 0 20 20"
          refX="0"
          refY="10"
          markerWidth="9"
          markerHeight="3"
          orient="auto"
          fill={hexToRGBA(region?.color, 1)}
        >
          <path d="M 0 0 L -20 10 L 0 20 Z" />
        </marker>
      </defs>
      <defs>
        <marker
          id={endArrowId}
          viewBox="0 0 20 20"
          refX="0"
          refY="10"
          markerWidth="3"
          markerHeight="3"
          orient="auto"
          fill={hexToRGBA(region?.color, 1)}
        >
          <path d="M 0 0 L 20 10 L 0 20 Z" />
        </marker>
      </defs>
      {polyLineList.map((item, idx) => (
        <PolyLine
          key={idx}
          points={item?.polyLineString}
          stroke={hexToRGBA(region?.color, 1)}
          strokeWidth={iw * 0.004}
          pointerEvents="visibleStroke"
          onMouseDown={(e) => handleMouseDown(e, idx)}
          className={classnames(
            region?.highlighted && 'highlighted',
            !region?.highlighted && region?.isDetected && 'isDetected',
            polyLineHoverType && 'polyLineHoverType',
            'annotation',
            'annotation-polyline'
          )}
          $createWithPrimary={createWithPrimary}
          $selectedTool={selectedTool}
          $createMode={drawLineMode}
          markerStart={`url(#${item.isArrow && item.startArrow && startArrowId})`}
          markerEnd={`url(#${item.isArrow && item.endArrow && endArrowId})`}
        />
      ))}
      {uniquePointList.map(({x, y}, i) => {
        const isNotExistInSameLine =
          GroupOfLine.findIndex((item) => item.some((p) => p.x === x && p.y === y)) !== lineGroupIdx;
        const isEndPoints = annotation.isEndpoints(region.points, {x, y});

        return (
          <AnnotationGroup x={x} y={y} iw={iw} ih={ih}>
            {i === 0 && region?.labelVisible && cfg?.isDisplayLabels && (
              <text
                x={0}
                y={-Math.floor(iw * 0.007)}
                fill={region?.color}
                fontSize={Math.floor(iw * 0.014)}
                fontWeight={700}
                textAnchor="middle"
                className="annot-label"
              >
                {region?.name}
              </text>
            )}
            <LinePointCircle
              className={classnames(
                pointMoveMode && 'point-move-mode',
                pointOfLineHoverType && 'pointOfLineHoverType',
                drawExpandingLine && 'draw-expanding-line-mode',
                selectedTool === 'line-eraser' && 'eraserMode',
                'annotation',
                'annotation-circle'
              )}
              r={pointMoveMode && isNotExistInSameLine && isEndPoints && moveTargetIsEndPoint ? iw * 0.004 : iw * 0.002}
              strokeWidth={2}
              stroke={hexToRGBA(
                pointMoveMode && isNotExistInSameLine && isEndPoints && moveTargetIsEndPoint
                  ? mergeCandidatePointBorderColor
                  : region?.color,
                0.75
              )}
              fill={hexToRGBA(pointMoveMode || region?.highlighted ? '#FFFFFF' : region?.color, 1)}
              onMouseDown={(e) => handleMouseDownPoints(e, i, {x, y})}
              onMouseUp={(e) => handleMouseUpPoints(e, i, {x, y})}
              onContextMenu={(e) => onContextMenuPane(e, i, {x, y})}
            />
          </AnnotationGroup>
        );
      })}
      {annotationCircle && (
        <AnnotationGroup x={annotationCircle.x} y={annotationCircle.y} iw={iw} ih={ih}>
          <circle
            r={iw * 0.008}
            stroke={hexToRGBA(pointMoveMode ? annotationCircleBorderColor : region?.color, 0.75)}
            strokeWidth={2}
            fill="transparent"
          />
        </AnnotationGroup>
      )}
    </>
  );
}

export default Line;

// export default memo(Line, (prevProps, nextProps) => {
//   return prevProps.iw === nextProps.iw;
// });
