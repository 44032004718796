import styled from 'styled-components';
import React, {ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {MOUSE_BUTTON} from 'components/mpfd/const';
import classnames from 'classnames';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import {transform, translate} from 'transformation-matrix';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import SelectedAreaRectangle from 'components/mpfd/layer/parts/SelectedAreaRectangle';
import {Pos} from 'components/mpfd/type';

type StyleProps = {
  $imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  $iw: number;
  $ih: number;
  $mat?: any;
};

const Container = styled.div<StyleProps>`
  position: absolute;
  top: ${(props) => props.$imagePosition.topLeft.y}px;
  left: ${(props) => props.$imagePosition.topLeft.x}px;
  width: ${(props) => props.$iw}px;
  height: ${(props) => props.$ih}px;
  &.pan {
    * {
      pointer-events: none;
    }
  }
`;

type IProps = {
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  children: ReactNode;
  layoutChanging?: boolean;
  checkCollision(area: {x: number; y: number; w: number; h: number}): void;
};

function LayerWrapper({imagePosition, children, layoutChanging, checkCollision}: IProps) {
  const {dispatchToReducer, matrix, changeMatrix, state, detectLine, stageEnteredState} = useContext(MetaPfdContext);
  const [zoomStartPos, setZoomStartPos] = useState<Pos>(null);
  const [zoomEndPos, setZoomEndPos] = useState<Pos>(null);
  const zoomStartPosRef = useRef(null);
  const zoomEndPosRef = useRef(null);
  zoomStartPosRef.current = zoomStartPos;
  zoomEndPosRef.current = zoomEndPos;

  const selectedTool = state.selectedTool;
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  const previousTool = useRef<ToolIds | null>(null);
  const containerRef = useRef(null);
  const [stageEntered] = stageEnteredState;
  const onMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const layerWrapperContainer = document.getElementById('layer-wrapper');
    if (!containerRef.current) return;
    const startXInImage = e.clientX - layerWrapperContainer.getBoundingClientRect().left;
    const startYInImage = e.clientY - layerWrapperContainer.getBoundingClientRect().top;
    const xPercentageOfImage = startXInImage / iw;
    const yPercentageOfImage = startYInImage / ih;
    const standardCanvasContainer = document.getElementById('standard-canvas');

    switch (e.button) {
      case MOUSE_BUTTON.LEFT: {
        dispatchToReducer({type: 'MOUSE_DOWN', x: xPercentageOfImage, y: yPercentageOfImage});
        break;
      }
      case MOUSE_BUTTON.WHEEL: {
        if (selectedTool === 'create-expanding-line') {
          dispatchToReducer({type: 'SELECT_TOOL', selectedTool: 'select'});
        }

        if (!standardCanvasContainer) return;
        if (!previousTool.current) {
          previousTool.current = selectedTool;
        }

        const startX = e.clientX - standardCanvasContainer.getBoundingClientRect().left;
        const startY = e.clientY - standardCanvasContainer.getBoundingClientRect().top;

        const onMouseMove = (e: MouseEvent): void => {
          const x = e.clientX - standardCanvasContainer.getBoundingClientRect().left;
          const y = e.clientY - standardCanvasContainer.getBoundingClientRect().top;
          const translateMatrix = translate(startX - x, startY - y);
          const newMat = transform([matrix, translateMatrix]);
          changeMatrix({...newMat});
        };

        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
          previousTool.current = null;
        };
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {once: true});
        e.preventDefault();
        return;
      }
      case MOUSE_BUTTON.RIGHT: {
        dispatchToReducer({type: 'MOUSE_DOWN_RIGHT_BTN', x: xPercentageOfImage, y: yPercentageOfImage});
        break;
      }
      default:
    }

    switch (selectedTool) {
      case 'line-detection': {
        detectLine(xPercentageOfImage, yPercentageOfImage).then();
        break;
      }
      case 'pan': {
        if (!standardCanvasContainer) return;
        const startX = e.clientX - standardCanvasContainer.getBoundingClientRect().left;
        const startY = e.clientY - standardCanvasContainer.getBoundingClientRect().top;

        const onMouseMove = (e: MouseEvent): void => {
          const x = e.clientX - standardCanvasContainer.getBoundingClientRect().left;
          const y = e.clientY - standardCanvasContainer.getBoundingClientRect().top;
          const translateMatrix = translate(startX - x, startY - y);
          const newMat = transform([matrix, translateMatrix]);
          changeMatrix({...newMat});
        };

        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          if (previousTool.current) {
            dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
            previousTool.current = null;
          }
        };
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {once: true});

        e.preventDefault();
        break;
      }
      case 'create-expanding-line': {
        e.preventDefault();
        break;
      }
      case 'select': {
        const c = containerRef.current as HTMLDivElement;
        const startX = e.clientX - c.getBoundingClientRect().left;
        const startY = e.clientY - c.getBoundingClientRect().top;
        setZoomStartPos({x: startX, y: startY});
        setZoomEndPos({x: startX, y: startY});
        const onMouseMove = (e: MouseEvent): void => {
          const currentX = e.clientX - c.getBoundingClientRect().left;
          const currentY = e.clientY - c.getBoundingClientRect().top;

          setZoomEndPos({x: currentX, y: currentY});
          dispatchToReducer({type: 'MOUSE_MOVE', x: currentX / iw, y: currentY / ih});
        };

        const onMouseUp = (e: MouseEvent) => {
          document.removeEventListener('mousemove', onMouseMove);
          const currentX = e.clientX - c.getBoundingClientRect().left;
          const currentY = e.clientY - c.getBoundingClientRect().top;
          dispatchToReducer({type: 'MOUSE_UP', x: currentX / iw, y: currentY / ih});
          if (zoomStartPosRef.current && zoomEndPosRef.current) {
            const w = Math.abs(zoomStartPosRef.current.x - zoomEndPosRef.current.x);
            const h = Math.abs(zoomStartPosRef.current.y - zoomEndPosRef.current.y);
            const area = {
              x: Math.min(zoomStartPosRef.current.x, zoomEndPosRef.current.x),
              y: Math.min(zoomStartPosRef.current.y, zoomEndPosRef.current.y),
              w,
              h
            };
            setZoomStartPos(null);
            setZoomEndPos(null);
            /**
             * 계산
             */

            if (w > 1 && h > 1) {
              checkCollision(area);
            }
          }
        };
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {once: true});
        e.preventDefault();
        break;
      }
      default: {
        const onMouseMove = (e: MouseEvent): void => {
          const currentX = e.clientX - layerWrapperContainer.getBoundingClientRect().left;
          const currentY = e.clientY - layerWrapperContainer.getBoundingClientRect().top;
          dispatchToReducer({type: 'MOUSE_MOVE', x: currentX / iw, y: currentY / ih});
        };

        const onMouseUp = (e: MouseEvent) => {
          document.removeEventListener('mousemove', onMouseMove);
          const currentX = e.clientX - layerWrapperContainer.getBoundingClientRect().left;
          const currentY = e.clientY - layerWrapperContainer.getBoundingClientRect().top;
          dispatchToReducer({type: 'MOUSE_UP', x: currentX / iw, y: currentY / ih});
        };
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp, {once: true});
        e.preventDefault();
        break;
      }
    }
  };

  const onMouseMove = (e: React.MouseEvent): void => {
    if (selectedTool !== 'create-expanding-line') return;
    selectedTool === 'create-expanding-line' && e.stopPropagation();
    const c = document.getElementById('layer-wrapper');
    if (!containerRef.current) return;
    const currentX = e.clientX - c.getBoundingClientRect().left;
    const currentY = e.clientY - c.getBoundingClientRect().top;
    dispatchToReducer({type: 'MOUSE_MOVE', x: currentX / iw, y: currentY / ih});
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (!previousTool.current) {
          previousTool.current = state.selectedTool;
        }
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: 'pan'});
        document.removeEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (previousTool.current) {
          dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
          previousTool.current = null;
          document.addEventListener('keydown', handleKeyDown);
          document.removeEventListener('keyup', handleKeyUp);
        }
      }
    };

    if (stageEntered) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [stageEntered]);

  return (
    <Container
      className={classnames(layoutChanging && 'layoutChanging', 'layer-wrapper', selectedTool)}
      $imagePosition={imagePosition}
      $iw={iw}
      $ih={ih}
      $mat={matrix}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      ref={containerRef}
      id="layer-wrapper"
    >
      {selectedTool === 'select' && <SelectedAreaRectangle startPos={zoomStartPos} endPos={zoomEndPos} />}
      {children}
    </Container>
  );
}

export default LayerWrapper;
