import {createContext, Context, PropsWithChildren, useState, RefObject} from 'react';
import {DatabaseHierarchy} from 'api/data-types';
import {WidgetTypes} from 'components/menu/types';
import {IPythonEditorOutflowResult} from 'components/pc/widgets/PythonEditorWidget';
import {IPythonEditorLog, IPythonEditorSimulationResult} from 'components/pc/widgets/pythonEditor/types';
import {Edge} from '@reactflow/core/dist/umd/types/edges';
import {Node} from 'reactflow';

export type ILocalDatabaseContext = {
  connectionInfo: IWidgetConnectionInfo;
  contentInfo: IWidgetContentInfo<IPythonEditorSubject | IDatasheetSubject>;
  updateSubjectInfo(
    id: string,
    type: WidgetTypes,
    ref: RefObject<IPythonEditorSubject | IDatasheetSubject>,
    name?: string
  ): void;
  afterEdgesChange(nodes: Node[], edges: Edge[]): void;
};
export const LocalDatabaseContext: Context<ILocalDatabaseContext> = createContext(null);

export const localDatabaseRelatedWidgetList: WidgetTypes[] = [
  'DatasheetLocalDbWidget',
  'PythonEditorWidget',
  'ModelRunnerWidget',
  'MetaPfdWidget',
  'TimeSeriesWidget'
];

export type ILocalDatabase = {
  [hashKey: string]: ILocalDatabaseEntry[];
};

type LocalDatabaseType = 'PythonEditor' | 'Datasheet' | 'MetaPfd';

export type ILocalDatabaseInfo = {
  db: ILocalDatabase;
  type: LocalDatabaseType;
  hierarchy: DatabaseHierarchy;
  createAt?: number;
  updateAt?: number;
  name: string;
};

type ILocalDatabaseEntry = {
  timestamp?: number;
  value: string | number;
  type?: 'Inflow' | 'Outflow' | string;
};

type IWidgetConnectionInfo = {
  [subscriberId: string]: {id: string; type: WidgetTypes}[];
};

type IWidgetContentInfo<T> = {
  [subjectWidgetId: string]: IWidgetSubject<T>;
};

export type IWidgetSubject<T> = {
  id: string;
  name: string;
  type: WidgetTypes;
  ref: RefObject<T>;
};

export type IPythonEditorSubject = {
  pythonRunOutflowResult: IPythonEditorOutflowResult;
  pythonEditorWidgetLogHistory: IPythonEditorLog[];
  pythonEditorSimulationResultHistory: IPythonEditorSimulationResult[];
  clearLogHistory(): void;
  afterRemovePythonLogWidget(): void;
  afterRemovePythonSimulationWidget(): void;
};

export type IDatasheetSubject = {
  getOutflowResult(arg0: {outflowResult: {}}): void;
  dbData: any[][];
  focusedRowIdx: number;
  next(): Promise<boolean>;
  changeReadOnly(readOnly: boolean): Promise<boolean>;
};

function LocalDatabaseProvider({children}: PropsWithChildren) {
  const [connectionInfo, setConnectionInfo] = useState<IWidgetConnectionInfo>();
  const [contentInfo, setContentInfo] = useState<IWidgetContentInfo<IPythonEditorSubject | IDatasheetSubject>>();

  const updateSubscriptionInfo = (subscription: IWidgetConnectionInfo) => {
    setConnectionInfo(subscription);
  };

  const updateSubjectInfo = <T,>(id: string, type: WidgetTypes, ref: RefObject<T>, name?: string) => {
    // const refObject = ref as RefObject<PythonEditorSubjectInfo | DatasheetSubjectInfo>;
    setContentInfo(
      (prev) =>
        ({...prev, [id]: {type, ref, id, name: name || id}}) as IWidgetContentInfo<
          IPythonEditorSubject | IDatasheetSubject
        >
    );
  };

  const afterEdgesChange = (nodes: Node[], edges: Edge[]) => {
    const subscript = {};
    for (let i = 0; i < edges?.length; i++) {
      const edge = edges[i] as Edge;
      const sourceNodeId = edge.source;
      const targetNodeId = edge.target;

      const sourceNode = nodes.find((item) => item.id === sourceNodeId);
      const targetNode = nodes.find((item) => item.id === targetNodeId);

      if (
        localDatabaseRelatedWidgetList.includes(targetNode?.type as WidgetTypes) &&
        localDatabaseRelatedWidgetList.includes(sourceNode?.type as WidgetTypes)
      ) {
        const targetSubscript = subscript[targetNodeId];
        if (targetSubscript) {
          subscript[targetNodeId] = [...targetSubscript, {id: sourceNodeId, type: sourceNode.type}];
        } else {
          subscript[targetNodeId] = [{id: sourceNodeId, type: sourceNode.type}];
        }
        // other widget to datasheet database 설정
        const sourceSubscript = subscript[sourceNodeId];
        if (sourceSubscript) {
          subscript[sourceNodeId] = [...sourceSubscript, {id: targetNodeId, type: targetNode.type}];
        } else {
          subscript[sourceNodeId] = [{id: targetNodeId, type: targetNode.type}];
        }
      }
    }
    updateSubscriptionInfo(subscript);
  };

  const collection = {
    connectionInfo,
    contentInfo,
    updateSubjectInfo,
    afterEdgesChange
  };

  return <LocalDatabaseContext.Provider value={collection}> {children}</LocalDatabaseContext.Provider>;
}

export default LocalDatabaseProvider;
