import styled from 'styled-components';
import ManagementHeader from 'components/auth/management/parts/ManagementHeader';
import {managementConst} from 'components/auth/management/const';
import ManagementTable from 'components/auth/management/parts/ManagementTable';
import {RoleInfo} from 'components/auth/management/management-api-function';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

type IProps = {
  roleList: RoleInfo[];
  onClickRoleManagementRow(rowkey: string, columnKey?: string): void;
};

function RoleManagement({roleList, onClickRoleManagementRow}: IProps) {
  return (
    <Container>
      <>
        <ManagementHeader
          title={managementConst.role.roleManagementHeader.title}
          description={managementConst.role.roleManagementHeader.description}
        />
        <ManagementTable
          fields={managementConst.role.roleFields}
          list={roleList}
          listType="role"
          // onClickCreate={openCreateRoleAside}
          onClickRow={onClickRoleManagementRow}
          rowKeyName="name"
        />
      </>
    </Container>
  );
}

export default RoleManagement;
