import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {CSSProperties, ReactElement, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {IModelRunnerWidgetData, IWidgetNodeData} from 'components/pc/types';
import ModelRunnerBodyTop from 'components/pc/widgets/modelRunner/ModelRunnerBodyTop';
import ModelRunnerBottom from 'components/pc/widgets/modelRunner/ModelRunnerBottom';
import ModelRunnerActionPanel from 'components/pc/widgets/modelRunner/ModelRunnerActionPanel';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {
  INodeSelectorTargetInfo,
  IModelRunnerRunConfig,
  IModelRunnerVariable,
  IValueGroupObj,
  IModelRunnerServer,
  IModelRunnerProject
} from 'components/pc/widgets/modelRunner/types';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';
import {modelRunnerDefaultStateValue} from 'components/pc/widgets/modelRunner/constants';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import WidgetIconModeHeader from 'components/pc/widgets/parts/WidgetIconModeHeader';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import useDraggable from 'utils/useDraggable';
import styled from 'styled-components';
import useLocalDatabase from 'components/pc/widgets/datasheet/useLocalDatabase';
import ModelRunnerResult from 'components/pc/widgets/modelRunner/ModelRunnerResult';
import ModelRunnerVariableTable from 'components/pc/widgets/modelRunner/ModelRunnerVariableTable';
import useModelRunnerLoader from 'components/pc/widgets/modelRunner/useModelRunnerLoader';
import ModelRunnerInitializeModal from 'components/pc/widgets/modelRunner/ModelRunnerInitializeModal';
import {getUniqueKey} from 'utils/commons';

const TopResizeArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #fff;
  z-index: 20;
`;
function ModelRunnerWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const variableTableState = useState<IModelRunnerVariable[]>([]);

  const runConfigState = useState<IModelRunnerRunConfig>(modelRunnerDefaultStateValue.runConfigState);
  const valueGroupObjectState = useState<IValueGroupObj>({});
  const nodeSelectorTargetInfoState = useState<INodeSelectorTargetInfo>(null);

  const [, setValueGroupObject] = valueGroupObjectState;
  const [variableTable, setVariableTable] = variableTableState;

  const initializeModalState = useState<boolean>(true);
  const [isShowInitializeModalState] = initializeModalState;

  const serverListState = useState<IModelRunnerServer[]>([]);
  const selectedServerState = useState<IModelRunnerServer>(null);
  const customServerState = useState<IModelRunnerServer>({
    name: 'custom server',
    ip: null,
    port: null
  });

  const selectedProjectState = useState<IModelRunnerProject>(null);
  const [selectedProject] = selectedProjectState;

  const serverConnectedState = useState<boolean>(false);
  const projectConnectedState = useState<boolean>(false);

  const latestNodeHandler = useLatestNodeHandler({type: 'latest_count', latest_count: 1});
  const reactFlow = useReactFlow();

  const boundaryTopRef = useRef(null);
  const dragHandleTopRef = useRef(null);

  const defaultTopPosition = {x: 0, y: 534};
  const defaultTopPadding = {top: 90, right: 0, bottom: 1, left: 0};
  const [editorBodyTopPosition, onMouseDownTopResizeHandle, setEditorBodyTopSize] = useDraggable(
    boundaryTopRef,
    dragHandleTopRef,
    defaultTopPosition,
    defaultTopPadding
  );

  const {localDatabase, sourceDatabase, dynamicHierarchy} = useLocalDatabase(id);

  const isRunState = useState(false);
  const [isRun] = isRunState;
  const isRunRef = useRef(null);
  isRunRef.current = isRun;

  const [stopRequest, setSopRequest] = useState(null);
  const stopRequestRef = useRef(null);
  stopRequestRef.current = stopRequest;

  const [focusedRowData, setFocusedRowData] = useState({});
  const focusedRowDataRef = useRef(focusedRowData);
  const variableTableRef = useRef(variableTable);
  const sourceDataBaseRef = useRef(sourceDatabase);

  const runModel = async (projectName) => {
    setProjectList((prevProjects) =>
      prevProjects.map((project) => {
        if (project.projectName === projectName) {
          if (project.seqInfo[0]?.Status === 'Idle' || project.seqInfo[0]?.Status === 'Completed') {
            startSequence(projectName);
          }
          if (project.seqInfo[0].Command === 'WAIT_IMPORT') {
            sendProjectInput(projectName, project.seqInfo[0].Name);
          }
        }
        return {...project, isRun: true};
      })
    );
  };

  const {
    logData,
    messages,
    projectList,
    serverName,
    sendMessage,
    setProjectList,
    sendProjectInput,
    connectToServer,
    disconnectFromServer,
    getServerList
  } = useModelRunnerLoader({
    focusedRowDataRef,
    variableTableRef,
    sourceDataBaseRef,
    isRunState,
    valueGroupObjectState,
    selectedServerState,
    serverConnectedState,
    // projectConnectedState,
    serverListState,
    runModel
  });

  useEffect(() => {
    focusedRowDataRef.current = focusedRowData;
  }, [focusedRowData]);

  useEffect(() => {
    variableTableRef.current = variableTable;
  }, [variableTable]);

  useEffect(() => {
    sourceDataBaseRef.current = sourceDatabase;
  }, [sourceDatabase]);

  const projectNameList = projectList.map((item) => item.projectName);

  const startSequence = (projectName) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'SendProjectAction',
      receiver: serverName + '::' + projectName,
      data: {ProjectName: projectName, Action: 'RunSequence', Data: {Name: 'ASDT', Parameters: [-1]}}
    });
  };

  //todo 추후에 기능이 필요할 경우 다시 복원 필요
  const stopModel = async (projectName) => {
    setProjectList((prevProjects) =>
      prevProjects.map((project) => {
        if (project.projectName === projectName) {
          return {...project, isRun: false};
        }
      })
    );
  };

  const setIsBatch = (projectName, val) => {
    setProjectList((prevProjects) =>
      prevProjects.map((project) => {
        if (project.projectName === projectName) {
          return {...project, isBatch: val};
        }
      })
    );
  };

  const iconModeState = useState<CSSProperties>(null);
  const [iconMode, setIconMode] = iconModeState;

  const [runConfig, setRunCfg] = runConfigState;
  const [nodeSelectorTargetInfo, setNodeSelectorTargetInfo] = nodeSelectorTargetInfoState;

  useEffect(() => {
    if (data?.metaData) {
      const {runConfig, variableTable, iconMode, resizeHeight} = data?.metaData as IModelRunnerWidgetData;
      setRunCfg(runConfig || modelRunnerDefaultStateValue.runConfigState);
      setVariableTable(variableTable || []);
      setIconMode(iconMode || null);
      setEditorBodyTopSize({x: 0, y: resizeHeight || 0});
    }
  }, []);

  useEffect(() => {
    /**
     *  resizeHeight, resizeWidth 바뀌는 callback으로 옮겨서
     *  useEffect 내에서 setNodes 제거 필요
     */
    setTimeout(() => {
      reactFlow.setNodes((nodes) =>
        nodes.map((node) => {
          if (node?.id !== id) return node;
          const {data} = node;
          const {metaData} = data || {};
          return {
            ...node,
            data: {
              ...data,
              metaData: {
                ...(metaData || {}),
                resizeHeight: editorBodyTopPosition.y
                // resizeWidth: editorBodyBottomPosition.x
              }
            }
          };
        })
      );
    });
  }, [editorBodyTopPosition]);

  useEffect(() => {
    if (!id || !variableTable || variableTable?.length === 0) return;

    const cloudDbVariable = variableTable?.filter((item) => item.variableDbType === 'cloud');
    const cloudNodeInfos = cloudDbVariable.map((item) => {
      const path = item.path;
      if (path.length > 1) {
        const [database, ...rest] = path;
        const hierarchy = rest.slice(0, -1);
        const name = rest[rest.length - 1];
        return {
          name,
          database,
          hierarchy
        };
      }
    });

    latestNodeHandler.renewSubscribe(id, cloudNodeInfos, false);
  }, [id, variableTable, localDatabase]);

  useEffect(() => {
    const data = localDatabase?.ref?.current?.dbData;
    const result = {};
    if (data) {
      let headersRowIndex = -1;
      for (let i = 0; i < data.length; i++) {
        if (data[i].some((cell) => cell !== undefined && cell !== null && cell !== '')) {
          headersRowIndex = i;
          break;
        }
      }

      const headers = data[headersRowIndex];
      const rows = data?.slice(1); // header row slice

      const row = rows?.[localDatabase?.ref?.current?.focusedRowIdx - 1];

      for (let i = 0; i < headers?.length; i++) {
        const tag = headers[i];
        if (!tag) continue;
        result[tag] = row?.[i] !== undefined ? row?.[i] : null;
      }
      setFocusedRowData(result);
    }
  }, [localDatabase?.ref?.current?.dbData, localDatabase?.ref?.current?.focusedRowIdx]);

  // project의 ioinfo를 variableTable 형식에 맞게 데이터 가공
  const initializeVariableTableState = (selectedProject: IModelRunnerProject) => {
    return selectedProject?.ioInfo.flatMap((ioInfo) =>
      ioInfo.Data.map((ioInfoData) => ({
        variableName: ioInfoData.Tag,
        flowType: ioInfo.Type,
        variableValue: ioInfoData.Value,
        path: [],
        keys: getUniqueKey()
      }))
    );
  };

  useEffect(() => {
    setTimeout(() => setVariableTable(initializeVariableTableState(selectedProject)), 1500);
  }, [selectedProject]);

  useEffect(() => {
    if (!latestNodeHandler?.latestResult?.[id]) return;

    const cloudRes = [...(latestNodeHandler?.latestResult[id] || [])];
    let newValueGroupObject = {};
    setVariableTable(function (prevState) {
      let newState = prevState;
      if (cloudRes?.length > 0) {
        newState.map(function (row) {
          const targetRes = cloudRes.find((latestNode) => latestNode.key === row.path.join(', '));
          if (targetRes) {
            newValueGroupObject = {...newValueGroupObject, [row.keys]: targetRes};
          }
        });
      }
      setValueGroupObject(newValueGroupObject);
      afterChangeMetaData('variableTable', newState);
      return newState;
    });
  }, [id, latestNodeHandler?.latestResult]);

  const onChangeRunConfig = (key: string, value: string) => {
    // todo: setState 의 순차 실행에 대한 refactoring 필요.
    setTimeout(() => {
      setRunCfg((prev) => {
        const next = {...prev, [key]: value};
        afterChangeMetaData('runConfig', next);
        return next;
      });
    }, 100);
  };

  const onSelectNodeSelector = (checked: string[]) => {
    const parsedData = checked.map((item) => JSON.parse(item));

    if (parsedData?.[0]) {
      const path = parsedData?.[0];
      let variableDbType = 'cloud';
      if (dynamicHierarchy?.database === path?.[0]) {
        variableDbType = 'local';
      }
      setVariableTable((prev) => {
        const updatedTable = prev.map((row) =>
          row.keys === nodeSelectorTargetInfo.key ? {...row, variableDbType, path: parsedData?.[0]} : row
        );
        afterChangeMetaData('variableTable', updatedTable);

        return updatedTable;
      });
    }
    setNodeSelectorTargetInfo(null);
  };

  const onSetNodeSelectorTargetInfo = (key: string, path: string[]) => {
    setNodeSelectorTargetInfo({key, path});
  };

  const onCloseNodeSelector = () => {
    setNodeSelectorTargetInfo(null);
  };

  const stopModelRunnerCodeRunning = () => {
    const now = Date.now();

    setSopRequest(now);
  };

  const onClickIconModeIcon = () => {
    setIconMode(function (prevState) {
      let prevStyle = null;
      if (prevState) {
        reactFlow.setNodes((nodes) =>
          nodes.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: {
                    ...item.data,
                    metaData: {...item.data.metaData, iconMode: null}
                  },
                  style: prevState
                }
              : item
          )
        );
      } else {
        reactFlow.setNodes((nodes) => {
          prevStyle = nodes.find((item) => item.id === id).style;
          return nodes.map((item) =>
            item.id === id
              ? {
                  ...item,
                  data: {
                    ...item.data,
                    metaData: {...item.data.metaData, iconMode: prevStyle}
                  },
                  style: {
                    width: 186,
                    height: 144,
                    maxWidth: 186,
                    maxHeight: 144,
                    minWidth: 186,
                    minHeight: 144
                  }
                }
              : item
          );
        });
      }
      return prevStyle;
    });
  };

  const afterChangeMetaData = (params: string, value: any) => {
    setTimeout(() => {
      reactFlow.setNodes((nodes) =>
        nodes.map((node) => {
          if (node?.id !== id) return node;
          const {data} = node;
          const {metaData} = data || {};
          return {
            ...node,
            data: {
              ...data,
              metaData: {
                ...(metaData || {}),
                [params]: value
              }
            }
          };
        })
      );
    }, 0);
  };

  const widgetBodyHeight = boundaryTopRef.current?.offsetHeight || 0;
  const widgetBottomHeight = widgetBodyHeight - editorBodyTopPosition.y - 7;

  return (
    <WidgetContainer {...rest} data={data} type="ModelRunnerWidget" iconMode={Boolean(iconMode)}>
      <WidgetHeader
        type="ModelRunnerWidget"
        icon={data.icon}
        id={id}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'ModelRunnerWidget', titleData: '', data})}
        suffix="- Model Runner"
      />
      {Boolean(iconMode) && (
        <WidgetIconModeHeader
          title={data.customizedTitle ? data.title : getWidgetTitle({type: 'ModelRunnerWidget', titleData: '', data})}
        />
      )}
      <WidgetActionPanel padding={0} height={!iconMode ? 60 : 123} align="center">
        <ModelRunnerActionPanel
          iconMode={Boolean(iconMode)}
          isRunState={isRunState}
          runConfigState={runConfigState}
          projectNameList={projectNameList}
          selectedProject={selectedProject}
          connectedDatasheetSubjectInfo={localDatabase}
          onChangeRunConfig={onChangeRunConfig}
          runCode={() => runModel(selectedProject?.projectName)}
          stopModelRunnerCodeRunning={stopModelRunnerCodeRunning}
          onClickIconModeIcon={onClickIconModeIcon}
        />
      </WidgetActionPanel>
      <WidgetBody ref={boundaryTopRef} actionMenuHeight={42}>
        {Boolean(nodeSelectorTargetInfoState[0]) && (
          <NodeSelectorRevision
            onSelect={onSelectNodeSelector}
            onClose={onCloseNodeSelector}
            hierarchyInfos={[dynamicHierarchy]}
            options={{selectSingleNode: true}}
          />
        )}
        <ModelRunnerBodyTop height={editorBodyTopPosition.y}>
          <ModelRunnerVariableTable
            variableTableState={variableTableState}
            valueFromDatasheet={focusedRowData}
            valueGroupObjectState={valueGroupObjectState}
            selectedProject={selectedProject}
            onSetNodeSelectorTargetInfo={onSetNodeSelectorTargetInfo}
          />
        </ModelRunnerBodyTop>
        <TopResizeArea>
          <ResizeHandle ref={dragHandleTopRef} onMouseDown={onMouseDownTopResizeHandle} />
          <ModelRunnerBottom height={widgetBottomHeight}>
            <ModelRunnerResult messages={messages} logData={logData} />
          </ModelRunnerBottom>
        </TopResizeArea>
        {isShowInitializeModalState && (
          <ModelRunnerInitializeModal
            serverListState={serverListState}
            selectedServerState={selectedServerState}
            customServerState={customServerState}
            initializeModalState={initializeModalState}
            projectList={projectList}
            selectedProjectState={selectedProjectState}
            serverConnectedState={serverConnectedState}
            projectConnectedState={projectConnectedState}
            getServerList={getServerList}
            connectToServer={connectToServer}
            disconnectFromServer={disconnectFromServer}
          />
        )}{' '}
      </WidgetBody>
      <WidgetConfigLayer
        title="ModelRunner Environment Package List"
        isShow={false}
        onClose={() => {}}
      ></WidgetConfigLayer>
    </WidgetContainer>
  );
}
export default ModelRunnerWidget;
