import {useEffect, useRef, useState} from 'react';
import {
  dataConvertUtilFunc,
  getTagDataListReturnFromITagInfo,
  getTagDataListReturnFromILatestTags,
  IPeriodOption
} from 'components/spreadsheet/spreadsheet-adapter';

export type ILatestTag = {
  tag: string[];
  category: string;
  value?: string[][] | number[][];
  updateTime?: number;
  key: string;
};
export type ILatestTagResult = {
  [key: string]: ILatestTag[];
};

type ISubscribeInfo = {
  targetList: ILatestTag[];
  activate: boolean;
};

type ILatestTagSubscribe = {
  [key: string]: ISubscribeInfo;
};

export type ILatestTagHandlerReturn = {
  subscribe: ILatestTagSubscribe;
  latestResult: ILatestTagResult;
  disconnectSubscribe(id: string): void;
  renewSubscribe(id: string, tags: string[][], activate: boolean): void;
  onLoadLatestTagResult(tagResult: ILatestTagResult): void;
};

function useLatestTagHandler(option: IPeriodOption): ILatestTagHandlerReturn {
  const [subscribe, setSubscribe] = useState<ILatestTagSubscribe>({});
  const [result, setResult] = useState<ILatestTagResult>({});
  const resultRef = useRef(null);
  const subscribeRef = useRef(null);
  subscribeRef.current = subscribe;
  resultRef.current = result;

  useEffect(() => {
    const fnc = async () => {
      const currentSubscribe = {...subscribeRef.current} as ISubscribeInfo;
      const idArr = Object.keys(currentSubscribe);
      let tag_infos = [];
      for (let i = 0; i < idArr.length; i++) {
        const uniqueId = idArr[i];
        const subScribeInfo = currentSubscribe[uniqueId] as ISubscribeInfo;
        if (subScribeInfo?.activate) {
          const t = subScribeInfo?.targetList.map((item) => ({tag: [item.category, ...item.tag]}));
          tag_infos = tag_infos.concat(t);
        }
      }
      if (tag_infos?.length === 0) {
        return;
      }
      const res = await getTagDataListReturnFromITagInfo(tag_infos, option);
      const newInfo = dataConvertUtilFunc.tagDataListReturn2LatestTag(res);
      let newResult = {...resultRef?.current};
      const idArr_ = Object.keys(newResult);
      for (let i = 0; i < idArr_.length; i++) {
        const uniqueId = idArr[i];
        const subScribeInfo = currentSubscribe[uniqueId] as ISubscribeInfo;
        const isActivate = subScribeInfo?.activate;

        const prevResult = newResult[uniqueId] || ([] as ILatestTag[]);
        const nextResult = prevResult.map(function (prevRes) {
          const nextRes = newInfo.find((n) => n.key === prevRes.key);
          if (nextRes && isActivate) {
            return nextRes;
          } else {
            return prevRes;
          }
        });
        newResult = {...newResult, [uniqueId]: nextResult};
      }
      setResult(newResult);
    };

    let id = setInterval(fnc, 15 * 1000);
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, []);

  const onLoadLatestTagResult = (tagResult: ILatestTagResult) => {
    setResult(tagResult);
  };

  const addTags = async (id: string, latestTags: ILatestTag[]) => {
    const res = await getTagDataListReturnFromILatestTags(latestTags, option);
    const newInfo = dataConvertUtilFunc.tagDataListReturn2LatestTag(res);
    const prevResult = {...resultRef.current};
    if (prevResult[id]) {
      const targetIdResult = prevResult[id] as ILatestTag[];
      const delDuplicate = targetIdResult.filter((prevRes) => !newInfo.some((newRes) => newRes.key === prevRes.key));
      const renewInfo = [...(delDuplicate || []), ...(newInfo || [])];
      setResult((prev) => ({
        ...prev,
        [id]: renewInfo
      }));
    } else {
      setResult((prev) => ({
        ...prev,
        [id]: newInfo
      }));
    }
  };

  /**
   *
   * @param id
   * @param tags
   * @param activate
   */
  const renewSubscribe = (id: string, tags: string[][], activate: boolean) => {
    const prevResult = {...resultRef?.current} as ILatestTagResult;
    const info = prevResult[id] as ILatestTag[];
    const tagsExcludeBlank = tags.filter((item) => item.length !== 0);
    const refined = dataConvertUtilFunc.tag2LatestTag(tagsExcludeBlank);
    // 처음 구독
    if (!info) {
      addTags(id, refined);
    }
    // 구독이 되어있음
    else if (info) {
      const newSubscribeItem = refined.filter((newTags) => !info.some((item) => item.key === newTags.key));
      // 새로 정보를 가져올 필요가 있음

      if (newSubscribeItem?.length > 0) {
        addTags(id, newSubscribeItem);
      } else if (activate) {
        // live 이지만 최근에 update 되지 않았을 경우 addTags
        let diff = 0;
        const now = Date.now();
        for (let i = 0; i < info?.length; i++) {
          const item = refined.find((newTags) => info[i].key === newTags.key);
          // 새로 갱신된 목록에 없다면 최근에 update했는지 검사할 필요 없음
          if (!item) {
            continue;
          }
          const slope = info[i]?.updateTime ? now - info[i].updateTime : 0;
          if (diff < slope) diff = slope;
        }
        // 1분 이상 차이가 나는가?
        if (diff > 60 * 1000) {
          addTags(id, refined);
        }
      }
    }
    setSubscribe((prev) => ({
      ...prev,
      [id]: {
        targetList: refined,
        activate
      }
    }));
  };

  const afterUnMountListener = (id: string) => {
    setSubscribe(function (prev) {
      const copy = {...prev};
      delete copy[id];
      return copy;
    });
  };

  return {
    subscribe,
    latestResult: result,
    disconnectSubscribe: afterUnMountListener,
    renewSubscribe,
    onLoadLatestTagResult
  };
}

export default useLatestTagHandler;
