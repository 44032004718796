import {ChangeEvent, useContext} from 'react';
import {SwitchForm} from 'components/forms';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';

type IProps = {
  name: string;
  userIsOn: boolean;
  onToggle: (name: string, value: boolean) => void;
};

function UserModeToggle({name, userIsOn, onToggle}: IProps) {
  const {setUserIsOn, userTypeChange} = useContext(CopilotContext);

  const toggleHandler = () => {
    onToggle(name, !userIsOn);
    setUserIsOn(!userIsOn);
    userTypeChange(userIsOn);
  };

  const userType = userIsOn ? 'General' : 'Admin';

  return (
    <SwitchForm
      name="user-mode-toggle"
      id="user-mode-toggle"
      label={userType}
      labelPosition="left"
      checked={userIsOn}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        toggleHandler();
      }}
    />
  );
}

export default UserModeToggle;
