export const SensorType = [
  {value: 'Soft Sensor', label: 'Soft Sensor'},
  {value: 'Physical Sensor', label: 'Physical Sensor'}
];

export const DataType = [
  {value: 'Temperature', label: 'Temperature'},
  {value: 'Pressure', label: 'Pressure'},
  {value: 'MassFlow', label: 'MassFlow'},
  {value: 'MoleFlow', label: 'MoleFlow'},
  {value: 'VolumeFlow', label: 'VolumeFlow'}
];

export const PortType = [
  {value: 'From', label: 'From'},
  {value: 'To', label: 'To'}
];

export const colors = ['#CF4C2C', '#EA9C41', '#EBC347', '#438D57', '#3F8AE2', '#803DEC'];
