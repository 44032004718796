// import {SVGAttributes} from 'react';
import Circle from 'components/pc/common/shapes/parts/svg/Circle';
import RoundRectangle from 'components/pc/common/shapes/parts/svg/RoundRectangle';
import Rectangle from 'components/pc/common/shapes/parts/svg/Rectangle';
import Diamond from 'components/pc/common/shapes/parts/svg/Diamond';
import Cylinder from 'components/pc/common/shapes/parts/svg/Cylinder';
import Plus from 'components/pc/common/shapes/parts/svg/Plus';
import Arrow from 'components/pc/common/shapes/parts/svg/Arrow';
import Text from 'components/pc/common/shapes/parts/svg/Text';
import DataBinding from 'components/pc/common/shapes/parts/svg/DataBinding';

export const ShapeComponents = {
  Circle,
  RoundRectangle,
  Rectangle,
  Diamond,
  Cylinder,
  Plus,
  Arrow,
  Text,
  DataBinding
  // 'arrow-rectangle': ArrowRectangle,
  // hexagon: Hexagon,
  // triangle: Triangle,
  // parallelogram: Parallelogram,
};
