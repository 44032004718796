import styled from 'styled-components';
import {Pos} from 'components/mpfd/type';

const SelectedArea = styled.div<{startPos: Pos | null; endPos: Pos | null}>`
  position: absolute;
  border: 1px dotted rgba(0, 89, 220, 0.8);
  background-color: rgba(0, 89, 220, 0.08);
  z-index: 1000;
  ${({startPos, endPos}) => {
    if (!startPos || !endPos) return '';
    const x = Math.min(startPos.x, endPos.x);
    const y = Math.min(startPos.y, endPos.y);
    const width = Math.abs(startPos.x - endPos.x);
    const height = Math.abs(startPos.y - endPos.y);
    return `
      left: ${x}px;
      top: ${y}px;
      width: ${width}px;
      height: ${height}px;
    `;
  }}
`;

type IProps = {
  startPos: Pos;
  endPos: Pos;
};

function SelectedAreaRectangle({startPos, endPos}: IProps) {
  const w = Math.abs(startPos?.x - endPos?.x);
  const h = Math.abs(startPos?.y - endPos?.y);
  if (w * h > 0) {
    return <SelectedArea startPos={startPos} endPos={endPos} />;
  }
}

export default SelectedAreaRectangle;
