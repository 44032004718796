import {INode, INodeDataReturn, INodeRecordParams, INodeParams} from 'api/data-types';
import {ITimeSeries} from 'components/pc/widgets/timeseries/types';
import {IApi} from 'api/useApi';
import {IHierarchyCellInfo} from 'components/spreadsheet/spreadsheet-adapter';
import {IMergeCells} from 'components/pc/widgets/blockTable/BlockTable';
import {toSignificantDigit} from 'utils/number-utils';

export const parseCheckedListData = (checkedList: string[]): string[][] => {
  const parsed = checkedList.map((item) => JSON.parse(item));
  return parsed;
};

export const getBlockValues = async (
  parsed: string[][],
  api: IApi,
  targetString: string,
  digit: number
): Promise<string[][]> => {
  const params = {node_infos: getNodeParamList(parsed)};
  const valueAddedParsed: string[][] = parsed.map((arr) => [...arr]);

  if (params) {
    const list = await getBlockList(params, api);

    list.forEach((eachList, index) => {
      valueAddedParsed[index].push(toSignificantDigit(eachList.data[0][1], digit).toString());
    });

    return trimParsedData(valueAddedParsed, targetString);
  }
  return [];
};

const getNodeParamList = (nodes: string[][]): INodeParams[] => {
  return nodes.map((node) => {
    const [database, ...rest] = node;
    const merged = {
      node: [...rest],
      database
    };
    merged['latest_count'] = 1; //FIXME
    return merged;
  });
};

const getBlockList = async (nodeListParams: INodeRecordParams, api: IApi): Promise<ITimeSeries[]> => {
  const result = await api.post<INodeDataReturn>('/node_manage/get_node_data_list', nodeListParams);
  const {success, data} = result;

  if (success) {
    return data.map((item: INode, index) => {
      const tagStart = item.node.length < 3 ? 0 : 2;
      const refined = {
        keys: item.node,
        flattenKeys: item.node.join('-'),
        name: [...item.node].splice(tagStart, 3).join('-'),
        data: item.records ?? []
      };
      return refined as ITimeSeries;
    });
  }
  return [];
};

const trimParsedData = (parsedData: string[][], targetString: string): string[][] => {
  const targetIndex = parsedData[0].indexOf(targetString);
  if (targetIndex === -1) return [];
  return parsedData.map((eachParsedData) => eachParsedData.slice(targetIndex + 1));
};

export const refineTableData = (
  tableData: string[][]
): {refinedTableData: string[][]; cellInfo: IHierarchyCellInfo[]; mergeCellsInfo: IMergeCells[]} => {
  const refinedTableData: string[][] = [];
  let refinedTableDataIdx: number = 0;
  if (!tableData) return;
  const cellInfo: IHierarchyCellInfo[] = [];
  let previousParentRowIdx: number = 0;
  let previousChildrenRowIdx: number = -1;
  let mergeCellsInfo: IMergeCells[] = [];
  tableData.map((eachTableData, eachTableDataIdx) => {
    if (eachTableData.length === 2) {
      // 부모가 없는 가장 하위 데이터
      refinedTableData.push(eachTableData);
      cellInfo.push({isLowestChildren: true, isParentExist: false});
      previousChildrenRowIdx = refinedTableDataIdx;
      refinedTableDataIdx++;
    } else if (eachTableData.length > 2) {
      // 부모가 있는 데이터
      eachTableData.map((element, elementIdx) => {
        if (elementIdx < eachTableData.length - 2) {
          // 부모 데이터일 때
          if (!refinedTableData.some((existingArray) => arraysEqual(existingArray, [element, '']))) {
            // 이미 refinedTableData에 존재하지 않으면 부모 데이터를 refinedTableData에 넣어준다.
            refinedTableData.push([element, '']);
            const parentDepth = eachTableData.length - elementIdx - 2;
            cellInfo.push({
              isLowestChildren: false,
              childrenDataRows: [],
              isParentExist: false,
              parentDepth: parentDepth
            });
            if (refinedTableDataIdx > 0 && previousChildrenRowIdx > 0) {
              // 이전 부모 다음 ~ 현재 자식까지를 모두 이전 부모의 자식이므로 childrenDataRows에 넣어준다.
              cellInfo[previousParentRowIdx]?.childrenDataRows?.push(previousParentRowIdx + 1);
              cellInfo[previousParentRowIdx]?.childrenDataRows?.push(previousChildrenRowIdx);
            }
            previousParentRowIdx = refinedTableDataIdx;
            // 부모 (=title)을 하나의 row로 표현하고 싶을 때를 위해 mergeCellInfo를 만든다.
            mergeCellsInfo.push({
              row: refinedTableDataIdx,
              col: 0,
              rowspan: 1,
              colspan: 2
            });
            refinedTableDataIdx++;
          }
        } else if (elementIdx === eachTableData.length - 2) {
          //자식 데이터일 때
          refinedTableData.push([element, eachTableData[eachTableData.length - 1]]);
          cellInfo.push({isLowestChildren: true, isParentExist: true});
          previousChildrenRowIdx = refinedTableDataIdx;
          refinedTableDataIdx++;
        }
      });
    }
    if (eachTableDataIdx === tableData.length - 1) {
      // 데이터의 마지막 원소일 때, 그 전까지의 자식을 모두 직전의 부모의 자식으로 인식한다.
      if (refinedTableDataIdx > 0 && previousChildrenRowIdx > 0) {
        cellInfo[previousParentRowIdx].childrenDataRows?.push(previousParentRowIdx + 1);
        cellInfo[previousParentRowIdx].childrenDataRows?.push(previousChildrenRowIdx);
      }
    }
  });
  return {refinedTableData, cellInfo, mergeCellsInfo};
};

const arraysEqual = (array1: string[], array2: string[]): boolean => {
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
};
