import React from 'react';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import {IAnnotation, IPfdAction} from 'components/mpfd/type';
import {hexToRGBA, pointDistance} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import classnames from 'classnames';

type SvgProps = {
  $iw: number;
  $ih: number;
  $imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
};

const StyledSvg = styled.svg<SvgProps>`
  position: absolute;
  z-index: ${META_PFD_zINDEX.REGION_SHAPE};
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: visible;
`;

type IProps = {
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  regions: IAnnotation[];
  isViewer?: boolean;
  id?: string;
  dispatchToReducer(action: IPfdAction): void;
};

function ConnectionLineLayer({imagePosition, id, regions}: IProps) {
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;

  const getAnnotPos = (item: IAnnotation) => {
    let annotPos;
    switch (item?.type) {
      case 'point': {
        annotPos = {
          left: item?.x,
          top: item?.y,
          right: item?.x,
          bottom: item?.y
        };
        break;
      }
      case 'box': {
        annotPos = {
          left: item?.x,
          top: item?.y,
          right: item?.x + Math.max(item?.w, 0),
          bottom: item?.y + Math.max(item?.h, 0)
        };
        break;
      }
      case 'expanding-line': {
        annotPos = {
          left: item?.points?.[0].x,
          top: item?.points?.[0].y
        };
        break;
      }
    }
    return annotPos;
  };

  const connectionLinePointsRelatedTable = regions?.map((item) => {
    const annotPos = getAnnotPos(item);
    return {
      annotPos,
      tablePos: {
        x: item?.tablePosition?.x,
        y: item?.tablePosition?.y
      },
      isTableVisible: item?.isTableVisible,
      isDragging: item?.isTableDragging,
      regionType: item?.type,
      metaPfdTagInfo: item?.metaPfdTagInfo
    };
  });

  const connectionLinePointsRelatedChart = regions?.map((item) => {
    const annotPos = getAnnotPos(item);
    return {
      annotPos,
      chartPos: {
        x: item?.chartPosition?.x,
        y: item?.chartPosition?.y
      },
      isChartVisible: item?.isNormalChartVisible,
      isDragging: item?.isChartDragging,
      regionType: item?.type,
      metaPfdTagInfo: item?.metaPfdTagInfo
    };
  });

  return (
    <StyledSvg
      $iw={iw}
      $ih={ih}
      $imagePosition={imagePosition}
      className={classnames('connection-line-layer' + id, 'connection-line-layer')}
    >
      {connectionLinePointsRelatedTable.map(function (item, idx) {
        if (
          pointDistance(item?.annotPos?.left, item?.annotPos?.top, item?.tablePos.x, item?.tablePos?.y) > 0.05 &&
          item?.isTableVisible &&
          !item?.isDragging &&
          [...(item?.metaPfdTagInfo || [])].filter((item) => item?.isTableDisplay)?.length > 0
        ) {
          let isLeft = false;
          let isTop = false;
          if (item?.annotPos?.left > item?.tablePos?.x || item?.regionType === 'expanding-line') {
            isLeft = true;
          }
          if (item?.annotPos?.top > item?.tablePos?.y || item?.regionType === 'expanding-line') {
            isTop = true;
          }

          const annotX = isLeft ? item?.annotPos?.left : item?.annotPos?.right;
          const annotY = isTop ? item?.annotPos?.top : item?.annotPos?.bottom;
          const points = `${annotX * iw}, ${annotY * ih},  ${item?.tablePos?.x * iw},  ${item?.tablePos?.y * ih}`;
          if (
            isNaN(annotX * iw) ||
            isNaN(annotY * ih) ||
            isNaN(item?.tablePos?.x * iw) ||
            isNaN(item?.tablePos?.y * ih)
          ) {
            return null;
          }

          return <polyline key={idx} points={points} stroke={hexToRGBA('#5556ff', 0.8)} strokeWidth={2}></polyline>;
        } else {
          return <React.Fragment key={idx}></React.Fragment>;
        }
      })}
      {connectionLinePointsRelatedChart.map(function (item, idx) {
        if (
          pointDistance(item?.annotPos?.left, item?.annotPos?.top, item?.chartPos.x, item?.chartPos?.y) > 0.05 &&
          item?.isChartVisible &&
          !item?.isDragging &&
          [...(item?.metaPfdTagInfo || [])].filter((item) => item?.isChartDisplay)?.length > 0
        ) {
          let isLeft = false;
          let isTop = false;
          if (item?.annotPos?.left > item?.chartPos?.x || item?.regionType === 'expanding-line') {
            isLeft = true;
          }
          if (item?.annotPos?.top > item?.chartPos?.y || item?.regionType === 'expanding-line') {
            isTop = true;
          }

          const annotX = isLeft ? item?.annotPos?.left : item?.annotPos?.right;
          const annotY = isTop ? item?.annotPos?.top : item?.annotPos?.bottom;
          const points = `${annotX * iw}, ${annotY * ih},  ${item?.chartPos?.x * iw},  ${item?.chartPos?.y * ih}`;
          if (
            isNaN(annotX * iw) ||
            isNaN(annotY * ih) ||
            isNaN(item?.chartPos?.x * iw) ||
            isNaN(item?.chartPos?.y * ih)
          ) {
            return null;
          }

          return <polyline key={idx} points={points} stroke={hexToRGBA('#5556aa', 0.8)} strokeWidth={2}></polyline>;
        } else {
          return <React.Fragment key={idx}></React.Fragment>;
        }
      })}
    </StyledSvg>
  );
}

export default ConnectionLineLayer;
