/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/COBoiler/COBoiler.gltf 
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function COBoiler() {
  const {nodes, materials} = useGltfCustom('/gltf/COBoiler/COBoiler.gltf');

  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._COBoiler_1.geometry}
        material={materials.Simacro_COBoiler}
        position={[-69.2, 16.236, 26.2]}
        rotation={[Math.PI, 0, -Math.PI]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/COBoiler/COBoiler.gltf');
