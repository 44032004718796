import {useEffect} from 'react';

type IModalKeyBoardEvent = {
  onConfirm?(): void;
  onCancel?(): void;
};

function useModalKeyBoardEvent(callbacks: IModalKeyBoardEvent, activate = true) {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      switch (e.key) {
        case 'Enter':
          if (callbacks.onConfirm) callbacks.onConfirm();
          break;
        case 'Escape':
          if (callbacks.onCancel) callbacks.onCancel();
          break;
        default:
          break;
      }
    };
    if (activate) {
      document.addEventListener('keydown', onKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [callbacks, activate]);
}

export default useModalKeyBoardEvent;
