import {ICommodityBasic} from 'components/pc/widgets/commodity/types';

export const defaultList: ICommodityBasic[] = [
  {
    tag: ['energy', 'crude oil'],
    unit: 'usd/bbl'
  },
  {
    tag: ['energy', 'gasoline'],
    unit: 'usd/gal'
  },
  {
    tag: ['energy', 'naphtha'],
    unit: 'usd/t'
  },
  {
    tag: ['energy', 'natural gas'],
    unit: 'usd/mmbtu'
  },
  {
    tag: ['energy', 'propane'],
    unit: 'usd/gal'
  },
  {
    tag: ['metals', 'lithium'],
    unit: 'cny/t'
  },
  {
    tag: ['metals', 'steel'],
    unit: 'cny/t'
  }
];

export const defaultPreset: ICommodityBasic[] = [
  {
    tag: ['energy', 'crude oil'],
    unit: 'usd/bbl'
  },
  {
    tag: ['energy', 'gasoline'],
    unit: 'usd/gal'
  },
  {
    tag: ['energy', 'naphtha'],
    unit: 'usd/t'
  },
  {
    tag: ['energy', 'natural gas'],
    unit: 'usd/mmbtu'
  },
  {
    tag: ['energy', 'propane'],
    unit: 'usd/gal'
  },
  {
    tag: ['metals', 'lithium'],
    unit: 'cny/t'
  },
  {
    tag: ['metals', 'steel'],
    unit: 'cny/t'
  }
];

export const chemicalPreset = [
  {tag: ['energy', 'naphtha'], unit: 'usd/t'},
  {tag: ['energy', 'natural gas'], unit: 'usd/mmbtu'},
  {tag: ['energy', 'propane'], unit: 'usd/gal'}
];

export const energyPreset = [
  {tag: ['energy', 'brent'], unit: 'usd/bbl'},
  {tag: ['energy', 'crude oil'], unit: 'usd/bbl'},
  {tag: ['energy', 'gasoline'], unit: 'usd/gal'}
];
