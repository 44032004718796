/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/gltf/Pump/Pump.gltf
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function Pump() {
  const {nodes, materials} = useGltfCustom('/gltf/Pump/Pump.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[-104.3, 10.806, -31.6]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[-30.3, 10.806, -72.6]}
        rotation={[0, 3.142, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[-25.8, 10.806, -36.55]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[22.4, 10.55, -68.2]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[38.81, 10.806, -16.5]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[38.81, 10.806, -11.8]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[38.81, 10.806, -7.1]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[38.81, 10.806, -2.5]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[28.15, 11.1, 35.3]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[-2.15, 10.45, 20]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[28.15, 11.1, 7]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[14.25, 10.806, 1.6]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[38.81, 10.806, -25.13]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[-12.6, 10.55, -62.2]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[-15.5, 10.55, -58.5]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[31.2, 14.6, -26.9]}
        rotation={[0, 1.571, 0]}
        scale={[0.506, 0.506, 1]}
      />
      <mesh
        geometry={nodes._Pump_1.geometry}
        material={materials.Simacro_Pump}
        position={[27.72, 8.3, -41]}
        rotation={[0, 0, 0]}
        scale={[0.506, 0.506, 1]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/Pump/Pump.gltf');
