import {BaseSyntheticEvent, ReactElement, useContext, useEffect, useState} from 'react';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import {Button} from 'components/forms';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {faDiagramProject} from '@fortawesome/pro-light-svg-icons';
import FileInfo from 'components/menu/FileInfo';
import {ActionMenuBody, ActionMenuItem, ActionMenuItemLabel} from 'components/menu/action';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import ActionMenuFileSearchField from 'components/menu/ActionMenuFileSearchField';
import {IFile} from 'api/data-types';
import useRollActionMenu from 'components/menu/action/useRollActionMenu';
import FileViewOption from 'components/menu/FileViewOption';
import {AuthContext} from 'components/auth/AuthProvider';
import {LocalStorageManager} from 'utils/local-storage-manager';

type IProps = {
  scrollToBottom?(): void;
};

/**
 * ProcessCanvas 의 저장 파일 목록을 표시
 * @constructor
 */

export type IFileViewOption = {
  isShowPublic: boolean;
  isShowOwner: boolean;
};

function ProcessCanvasFileList({scrollToBottom}: IProps): ReactElement {
  const [selectedList, setSelectedList] = useState<IFile[]>([]);
  const [selectedItem, setSelectedItem] = useState<IFile>();
  const searchInputState = useState<string>('');
  const [searchInput] = searchInputState;
  const {userProfile} = useContext(AuthContext);
  const {fileList, file, isShowPublicState, isShowOwnerState, busyFileList} = useContext(ProcessCanvasContext);
  const {load, create, remove} = useProcessCanvasCommand();
  const [expandedItemId, onMouseEnter, onMouseLeave] = useRollActionMenu();
  const [isShowPublic, setIsShowPublic] = isShowPublicState;
  const [isShowOwner, setIsShowOwner] = isShowOwnerState;

  const storedFileViewOption = LocalStorageManager.getItem('FILE_VIEW_OPTION') as IFileViewOption;

  // localStorage에 저장된 public, owner view 상태를 처음 렌더링시 set해줌
  useEffect(() => {
    if (storedFileViewOption) {
      setIsShowPublic(storedFileViewOption.isShowPublic);
      setIsShowOwner(storedFileViewOption.isShowOwner);
    }
  }, []);

  useEffect(() => {
    const updatedFileViewOption = {
      isShowOwner: isShowOwner,
      isShowPublic: isShowPublic
    };
    LocalStorageManager.setItem('FILE_VIEW_OPTION', updatedFileViewOption);

    if (fileList?.length) {
      const canvasFile = fileList.filter((item) => item.fileType === 'process_canvas');

      const publicFiles = isShowPublic ? canvasFile.filter((item) => item.public === true) : [];
      const ownerFiles = isShowOwner ? canvasFile.filter((item) => item.owner === userProfile.username) : [];

      // 중복을 제거하면서 publicFiles와 ownerFiles를 합치기
      const mergedFiles = new Map(publicFiles.concat(ownerFiles).map((file) => [file._id, file]));

      let filteredFile = Array.from(mergedFiles.values()).sort(
        (a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()
      );

      // 검색어 필터링
      if (searchInput) {
        filteredFile = filteredFile.filter((item) => item.fileName.toLowerCase().includes(searchInput.toLowerCase()));
      }

      setSelectedList(filteredFile);
    }
  }, [fileList, searchInput, isShowPublic, isShowOwner, userProfile.username]);

  const onSelectItem = (item: IFile, index: number): void => {
    // 마지막 item 일 경우 하단으로 스크롤
    if (index + 1 === selectedList.length) scrollToBottom?.();

    setSelectedItem(item);
  };

  /**
   * ProcessCanvas 로드
   * @param item
   */
  const onClickLoad = (item: IFile): void => {
    load(item._id);
  };

  const onClickDelete = (e: BaseSyntheticEvent, item: IFile): void => {
    e.currentTarget.blur();
    remove(item, item._id);
  };

  const onClickCreateFile = (): void => {
    create();
  };

  const onChangePublic = () => {
    setIsShowPublic(!isShowPublic);
  };

  const onChangeOwner = () => {
    setIsShowOwner(!isShowOwner);
  };

  return (
    <>
      <ActionMenuHeader main="Process Canvas" sub="(Files)" description="Select a Process Canvas Layout">
        <Button variant="info" onClick={onClickCreateFile}>
          New
        </Button>
      </ActionMenuHeader>
      <ActionMenuFileSearchField searchInputState={searchInputState} />
      <FileViewOption
        isCheckedPublic={isShowPublic}
        isCheckedOwner={isShowOwner}
        onChangePublic={onChangePublic}
        onChangeOwner={onChangeOwner}
      />
      <ActionMenuBody>
        {selectedList.map((item, index) => (
          <ActionMenuItem key={item._id} id={item._id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ActionMenuItemLabel
              isActive={file?._id === item._id}
              icon={faDiagramProject}
              title={item.fileName}
              userName={item.createdUser}
              expanded={expandedItemId === item._id}
              onClick={() => onSelectItem(item, index)}
              item={item}
            />
            <FileInfo item={item} isActive={selectedItem?._id === item._id}>
              <Button
                variant="danger"
                disabled={file?._id === item._id || busyFileList.some((f) => f.id === item._id)}
                onClick={(e) => onClickDelete(e, item)}
              >
                Delete
              </Button>
              <Button disabled={busyFileList.some((f) => f.id === item._id)} onClick={() => onClickLoad(item)}>
                Load
              </Button>
            </FileInfo>
          </ActionMenuItem>
        ))}
      </ActionMenuBody>
    </>
  );
}

export default ProcessCanvasFileList;
