import React, {ReactElement} from 'react';
import {Route, Routes} from 'react-router-dom';
import 'assets/scss/app.scss';
import Layout from 'Layout';
import Home from 'pages/Home';
import MetaPfd from 'pages/MetaPfd';
import ProcessCanvas from 'pages/ProcessCanvas';
import ProcessCanvasProvider from 'components/pc/ProcessCanvasProvider';
import {CommonProvider, DefaultModal, LoadingSpinner} from 'components/common/index';
import MetaPfdProvider from 'components/mpfd/MetaPfdProvider';
import CopilotProvider from './components/menu/copilot/CopilotProvider';
import DataProvider from 'api/DataProvider';
import LoginScreen from 'components/auth/login/LoginScreen';
import AnonymousRoute from 'components/auth/AnonymousRoute';
import ProtectedRoute from 'components/auth/ProtectedRoute';
import ManagementScreen from 'components/auth/management/ManagementScreen';
import ContextMenu from 'components/common/context-menu/ContextMenu';
import AuthProvider from 'components/auth/AuthProvider';
import LocalDatabaseProvider from 'api/LocalDatabaseProvider';
import SplashModal from 'components/common/modal/SplashModal';

function App(): ReactElement {
  return (
    <Layout>
      <AuthProvider>
        <LocalDatabaseProvider>
          <CommonProvider>
            <DataProvider>
              <ProcessCanvasProvider>
                <CopilotProvider>
                  <MetaPfdProvider>
                    <Routes>
                      <Route element={<AnonymousRoute />}>
                        <Route path="/login" element={<LoginScreen />} />
                      </Route>
                      <Route element={<ProtectedRoute />}>
                        <Route path="/mpfd/:id?" element={<MetaPfd />} />
                        <Route path="/pc/:id?" element={<ProcessCanvas />} />
                        <Route path="/manage/:id?" element={<ManagementScreen />} />
                        <Route path="*" element={<Home />} />
                      </Route>
                      {/*<Route path="/demo" element={<Demo />} />*/}
                      <Route path="/login/:type?/:detail?" element={<LoginScreen />} />
                    </Routes>
                    <ContextMenu />
                    <SplashModal />
                    <DefaultModal />
                    <LoadingSpinner />
                  </MetaPfdProvider>
                </CopilotProvider>
              </ProcessCanvasProvider>
            </DataProvider>
          </CommonProvider>
        </LocalDatabaseProvider>
      </AuthProvider>
    </Layout>
  );
}

export default App;
