import {CheckboxForm} from 'components/forms';
import React, {ChangeEvent} from 'react';
import {ITableField} from 'components/table/DefaultTable';

type IProps = {
  field: ITableField;
  keys: string;
  onChange(e: ChangeEvent, th?: boolean): void;
  th?: boolean;
};

function InputCheckbox({field, keys, onChange, th, ...rest}: IProps) {
  const onChangeCheckboxAll = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e, true);
  };

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange?.(e, false);
  };
  if (th) {
    const rows = rest['rows'];
    if (!rows) {
      return null;
    }
    const isFalseExist = rows.map((item) => item[field?.key]).some((item) => !item);
    const isBlank = rows?.length === 0;

    return (
      <CheckboxForm
        id={field?.key}
        name={'th-' + field?.key}
        checked={isBlank ? false : !isFalseExist}
        checkedValue={true}
        uncheckedValue={false}
        onChange={onChangeCheckboxAll}
      />
    );
  } else {
    const value = Boolean(rest[field?.key]);

    return (
      <CheckboxForm
        id={field?.key}
        name={keys}
        checked={value}
        checkedValue={true}
        uncheckedValue={false}
        onChange={onChangeCheckbox}
      />
    );
  }
}

export default InputCheckbox;
