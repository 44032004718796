import {type DragEvent, useContext, useRef} from 'react';
import Shape from 'components/pc/common/shapes/Shape';
import styled from 'styled-components';
import {ShapeType} from 'components/pc/common/shapes/type';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {frozenLayoutModalOptions} from 'components/menu/constants';

const Container = styled.div`
  opacity: 0.5;
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.disabled) {
    cursor: grab;
  }

  > svg {
    stroke: #6a84a6;
  }

  :hover {
    opacity: 1;
    //background-color: #c2d2e1;
  }
`;

const DragImage = styled.div`
  transform: translate(0, 0);
  position: absolute;
  top: -100000px;
  left: -1000000px;
`;

type IProps = {
  type: ShapeType;
};

function ShapeItem({type}: IProps) {
  const dragImageRef = useRef<HTMLDivElement>(null);
  const {showModal} = useContext(CommonContext);
  const {isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    if (isFreeze) {
      showModal(frozenLayoutModalOptions);
      return;
    }

    event.dataTransfer?.setData('application/reactflow', type);
    if (dragImageRef.current) {
      event.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
    }
  };

  return (
    <Container draggable className={isFreeze && 'disabled'} onDragStart={onDragStart}>
      <Shape type={type} fill="transparent" strokeWidth={2} width={28} height={28} />
      <DragImage ref={dragImageRef}>
        <Shape type={type} width={80} height={80} fill="#3F8AE2" fillOpacity={0.7} stroke="#3F8AE2" strokeWidth={2} />
      </DragImage>
    </Container>
  );
}

export default ShapeItem;
