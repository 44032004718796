import {Dispatch, ReactElement, SetStateAction} from 'react';
import styled from 'styled-components';
import {IModelRunnerProject} from 'components/pc/widgets/modelRunner/types';
import ProjectItem from 'components/pc/widgets/modelRunner/initialize-modal-parts/ProjectItem';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 180px;
  overflow-y: auto;
  gap: 16px;
`;

type IProps = {
  stepState: [number, Dispatch<SetStateAction<number>>];
  projectList: IModelRunnerProject[];
  selectedProjectState: [IModelRunnerProject, Dispatch<SetStateAction<IModelRunnerProject>>];
  projectConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
};

function ProjectItemList({stepState, projectList, selectedProjectState, projectConnectedState}: IProps): ReactElement {
  const [, setStep] = stepState;
  const [, setSelectedProject] = selectedProjectState;
  const [, setProjectConnected] = projectConnectedState;

  const onClick = (project: IModelRunnerProject) => {
    setSelectedProject(project);
    setProjectConnected(true);
    setStep(6);
  };

  return (
    <Container className="thin-scrollbar">
      {projectList.map((item) => (
        <ProjectItem key={item.projectName} title={item.projectName} onClick={() => onClick(item)} />
      ))}
    </Container>
  );
}

export default ProjectItemList;
