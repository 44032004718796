import {Dispatch, ReactElement, SetStateAction} from 'react';
import styled from 'styled-components';
import {FormRow, TextForm} from 'components/forms';
import {IModelRunnerServer} from 'components/pc/widgets/modelRunner/types';

const Container = styled.div`
  width: 280px;
`;

type IProps = {
  selectedServerState: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
};
function ServerInfo({selectedServerState}: IProps): ReactElement {
  const [selectedServer, setSelectedServer] = selectedServerState;

  const disabled = selectedServer?.name !== 'custom server';

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setSelectedServer((prev) => ({
      ...prev,
      [name]: value
    }));
  };
  return (
    <Container>
      <FormRow header="Server Name">
        <TextForm
          disabled={disabled}
          name="name"
          type="text"
          position="right"
          value={selectedServer?.name || ''}
          onChange={onChange}
        />
      </FormRow>
      <FormRow header="Address">
        <TextForm
          disabled={disabled}
          name="ip"
          type="text"
          position="right"
          value={selectedServer?.ip || ''}
          onChange={onChange}
        />
      </FormRow>
      <FormRow header="Port">
        <TextForm
          disabled={disabled}
          name="port"
          type="text"
          position="right"
          value={selectedServer?.port || ''}
          onChange={onChange}
        />
      </FormRow>
    </Container>
  );
}

export default ServerInfo;
