import React, {BaseSyntheticEvent, ReactElement, RefObject, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition, RotateProp} from '@fortawesome/fontawesome-svg-core';
import {faCircleMinus, faCirclePlus} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowPointer,
  faCodeCommit,
  faEraser,
  faHand,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faObjectGroup,
  faPenLine,
  faScissors,
  faSquare,
  faUpload,
  faWandMagicSparkles
} from '@fortawesome/pro-light-svg-icons';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {IPosition} from 'components/common/types';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import useDraggable from 'utils/useDraggable';
import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import {onlySvgFileDetectableModalOptions} from 'components/menu/constants';
import {CommonContext} from 'components/common/CommonProvider';
import {defaultTheme} from 'theme/theme';

const Container = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: ${META_PFD_zINDEX.TOOLBOX};
  width: 50px;
  background-color: #ffffff;
  border: 2px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: visible;
  user-select: none;
`;
const MoveHandle = styled.div`
  width: 100%;
  height: 20px;
  background-color: #e7e7e7;
  cursor: move;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 86%;
    flex-shrink: 0;
    background-color: #d0cece;
  }
`;
const ToolsContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    width: 40px;
    height: 40px;
    border: none !important;

    &.active {
      background-color: #d7eeff;
    }

    &:not(:disabled, .active):hover {
      background-color: #edf6ff;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
const HorizontalLine = styled.hr`
  width: 100%;
  border: none;
  margin: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

type ToolNames =
  | 'Select'
  | 'Select Part'
  | 'Zoom In'
  | 'Zoom Out'
  | 'Fit View'
  | 'Move'
  | 'Point'
  | 'Region'
  | 'Port'
  | 'Draw Line'
  | 'Detect Line'
  | 'Eraser'
  | 'Add Break Point'
  | 'Scissor'
  | 'Remove Break Point';
export type ToolIds =
  | 'select'
  | 'select-2'
  | 'pan'
  | 'zoom'
  | 'zoom-in'
  | 'zoom-out'
  | 'fit-view'
  | 'create-point'
  | 'create-box'
  | 'create-port'
  | 'create-expanding-line'
  | 'line-detection'
  | 'line-eraser'
  | 'line-splitter'
  | 'line-splitter-2'
  | 'remove-joint-of-line';

export type IToolbox = {
  name: ToolNames;
  icon: IconDefinition;
  overlayIcon?: IconDefinition;
  overlayIconColor?: string;
  id: ToolIds;
  flip?: 'horizontal' | 'vertical';
  rotation?: RotateProp;
  underlined?: boolean;
};

export const toolList: IToolbox[] = [
  {name: 'Select', icon: faArrowPointer, id: 'select'},
  {name: 'Zoom In', icon: faMagnifyingGlassPlus, id: 'zoom-in'},
  {name: 'Zoom Out', icon: faMagnifyingGlassMinus, id: 'zoom-out'},
  // {name: 'Select Part', icon: faArrowPointerSolid, selectedTool: 'select-2'},
  // {name: 'zoom', icon: faMagnifyingGlassPlus, selectedTool: 'zoom'}, //:todo 버그 픽스
  // {name: 'Fit View', icon: faArrowsMaximize, id: 'fit-view'},
  {name: 'Move', icon: faHand, id: 'pan', underlined: true},
  {name: 'Detect Line', icon: faWandMagicSparkles, id: 'line-detection'},
  {name: 'Draw Line', icon: faPenLine, id: 'create-expanding-line'},
  {
    name: 'Add Break Point',
    icon: faCodeCommit,
    overlayIcon: faCirclePlus,
    overlayIconColor: '#999',
    id: 'line-splitter-2'
  },
  {
    name: 'Remove Break Point',
    icon: faCodeCommit,
    overlayIcon: faCircleMinus,
    overlayIconColor: '#999',
    id: 'remove-joint-of-line'
  },
  {name: 'Scissor', icon: faScissors, id: 'line-splitter', flip: 'horizontal', underlined: true},
  {name: 'Point', icon: faSquare, id: 'create-point'},
  {name: 'Region', icon: faObjectGroup, id: 'create-box'},
  {name: 'Port', icon: faUpload, id: 'create-port', rotation: 90, underlined: true},
  {name: 'Eraser', icon: faEraser, id: 'line-eraser'}
];

type IProps = {
  boundaryRef: RefObject<HTMLDivElement>;
};

function Toolbox({boundaryRef}: IProps): ReactElement {
  const {remoteAppMenu, showModal} = useContext(CommonContext);
  const {state, isShowPanelLayout, showMPfdModal, dispatchToReducer} = useContext(MetaPfdContext);

  const onClick = (e: BaseSyntheticEvent<HTMLButtonElement>): void => {
    const {name} = e.currentTarget;
    const targetTool = toolList.find((item) => item.name === name);
    const selectedTool = targetTool?.id as ToolIds;
    if (selectedTool === 'line-detection') {
      if (!!state?.detectionInfo?.detectedFileName) {
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool});
      } else if ((state?.images?.imgExtension || '').toLowerCase() !== 'svg') {
        showModal(onlySvgFileDetectableModalOptions);
      } else {
        showMPfdModal('detection');
      }
    } else {
      dispatchToReducer({type: 'SELECT_TOOL', selectedTool});
    }
  };

  const toolboxRef = useRef<HTMLDivElement>(null);
  const defaultPosition = (LocalStorageManager.getItem('META_PFD_TOOLBOX') as IPosition) || {x: 10, y: 50};
  const defaultPadding = {top: 50, right: 10, bottom: 10, left: 10};
  const [toolboxPosition, onMouseDownToolbox, setPosition] = useDraggable(
    boundaryRef,
    toolboxRef,
    defaultPosition,
    defaultPadding
  );

  // toolbox 의 위치가 변경되면 항상 LocalStorage 에 저장
  useEffect(() => {
    LocalStorageManager.setItem('META_PFD_TOOLBOX', toolboxPosition);
  }, [toolboxPosition]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const infoWidth = isShowPanelLayout ? Number(defaultTheme.infoPanel.width) : 30;
      const diff =
        document.body.offsetWidth - (toolboxRef.current.offsetLeft + toolboxRef.current.offsetWidth + infoWidth);

      if (diff < 0) {
        setPosition((prev: IPosition) => ({
          x: document.body.offsetWidth - infoWidth - 130,
          y: prev.y
        }));
      }
    });

    if (document.body) {
      observer.observe(document.body);
    }

    return () => {
      observer.disconnect();
    };
  }, [isShowPanelLayout]);

  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    const xp = Number(defaultTheme.action.menu.width);
    if (remoteAppMenu) {
      if (toolboxRef.current.offsetLeft < xp) {
        setOffsetX(xp - toolboxRef.current.offsetLeft + 10);
      }
    } else {
      setOffsetX(0);
    }
  }, [remoteAppMenu]);

  return (
    <Container ref={toolboxRef} style={{left: toolboxPosition?.x + offsetX, top: toolboxPosition?.y}}>
      <MoveHandle onMouseDown={onMouseDownToolbox} />
      <ToolsContainer>
        {toolList.map((tool) => (
          <React.Fragment key={tool.name}>
            <Button
              variant="none"
              className={classNames(state?.selectedTool === tool.id ? 'active' : 'none', {
                'fa-layers': tool.overlayIcon
              })}
              name={tool.name}
              onClick={onClick}
            >
              <Tooltip key={tool.name} content={tool.name} place="right">
                <FontAwesomeIcon icon={tool.icon} size="xl" flip={tool.flip} rotation={tool.rotation} />
                {tool.overlayIcon && (
                  <FontAwesomeIcon icon={tool.overlayIcon} color={tool.overlayIconColor} size="sm" />
                )}
              </Tooltip>
            </Button>
            {tool.underlined && <HorizontalLine key={`${tool.name}-underline`} />}
          </React.Fragment>
        ))}
      </ToolsContainer>
    </Container>
  );
}

export default Toolbox;
