import React, {useState} from 'react';
import useEventCallback from 'use-event-callback';

function RemoveScrollWrapper({children, ...otherProps}) {
  const [mouseOver, changeMouseOver] = useState(false);
  const onMouseMove = useEventCallback((e) => {
    if (!mouseOver) changeMouseOver(true);
    if (otherProps.onMouseMove) {
      otherProps.onMouseMove(e);
    }
  });

  return (
    <div
      className="containerDiv"
      id={otherProps?.id}
      onMouseMove={onMouseMove}
      style={{width: '100%', height: '100%', position: 'absolute'}}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default RemoveScrollWrapper;
