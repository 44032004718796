import {ChangeEvent, ReactElement, useContext, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, TextForm} from 'components/forms';
import styled from 'styled-components';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDiagramProject} from '@fortawesome/pro-light-svg-icons';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';

const ElementContainer = styled.div`
  width: 450px;
`;
const MessageText = styled.p`
  svg {
    margin-right: 10px;
  }
`;
const FileName = styled.span`
  margin-right: 10px;
`;
const Message = styled.span`
  color: ${({theme}) => theme.color.info};
`;

function ProcessCanvasExportModal(): ReactElement {
  const {file, exportModalState, exportFile} = useContext(ProcessCanvasContext);
  const [, setIsShowExportModal] = exportModalState;
  const [name, setName] = useState<string>(file.fileName);
  const [step, setStep] = useState(0);

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onCancel() // todo meta pfd enter key 이미 사용중. 다른 key로 교체 필요
  });

  const onCancel = (): void => {
    setIsShowExportModal(false);
  };

  const onClickConfirm = async (): Promise<void> => {
    if (step === 0) {
      const blob = await exportFile(name);
      if (blob) setStep(1);
    } else {
      setIsShowExportModal(false);
    }
  };

  const onChangeFileName = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setName(value);
  };

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onCancel}>Export File</ModalHeader>
      <ModalBody>
        <StepContainer width={560}>
          <StepScroller total={2} step={step}>
            <Step>
              <ElementContainer>
                <MessageText>
                  <FontAwesomeIcon icon={faDiagramProject} size="lg" />
                  The name of Process Canvas file to export.
                </MessageText>
                <TextForm value={name} onChange={onChangeFileName} />
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <MessageText>
                  <FontAwesomeIcon icon={faDiagramProject} size="lg" />
                  <FileName>{name}</FileName>
                  <Message>Export successful.</Message>
                </MessageText>
              </ElementContainer>
            </Step>
          </StepScroller>
        </StepContainer>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onClickConfirm}>
          {step === 0 ? 'Export' : 'Confirm'}
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default ProcessCanvasExportModal;
