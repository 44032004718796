import {Children, ReactNode, useState} from 'react';
import styled from 'styled-components';
import AccordionHeader from 'components/common/arrocdion/AccordianHeader';
import AccordionDetails from 'components/common/arrocdion/AccordionDetails';
import classnames from 'classnames';
import {IUpdateResultStates} from 'components/pc/widgets/pythonEditor/PythonCodeResult';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: #f2f2f2;
  overflow-y: auto;
`;

type IProps = {
  children: ReactNode[];
  className?: string;
  headers: string[];
  updateResultStates: IUpdateResultStates;
  onClickOffUpdateDisplay(index: number): void;
};

function Accordion({children, className, headers, updateResultStates, onClickOffUpdateDisplay}: IProps): ReactNode {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const onToggleAccordion = (index: number): void => {
    setActiveIndices((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
    onClickOffUpdateDisplay(index);
  };

  return (
    <Container className={classnames(className, 'thin-scrollbar')}>
      {Children.map(children, (child, index) => {
        const isActive = activeIndices.includes(index);
        const isResultUpdate = [
          updateResultStates.installResultsUpdated,
          updateResultStates.printOutUpdated,
          updateResultStates.errorUpdated,
          updateResultStates.outflowResultsUpdated
        ][index];
        return (
          <>
            <AccordionHeader
              isResultUpdate={isResultUpdate}
              isActive={isActive}
              onToggle={() => onToggleAccordion(index)}
              header={headers?.[index] || 'Header_' + index}
            />
            <AccordionDetails isActive={isActive}>{child}</AccordionDetails>
          </>
        );
      })}
    </Container>
  );
}

export default Accordion;
