import {memo, ReactElement} from 'react';
import {INode} from 'api/data-types';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #ecf0f3;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 5px;
  margin: 5px;
`;
const Index = styled.div`
  background-color: #c7cfe1;
  font-size: 0.8em;
  padding: 0 3px;
  min-width: 22px;
  height: 22px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
const Database = styled.div`
  color: #324e8c;
  font-size: 0.9em;
`;
const Path = styled.div`
  color: #aaa;
  font-size: 0.9em;
`;
const Name = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 0 10px;
`;
const Unit = styled.div`
  color: #6693c0;
  font-size: 0.9em;
  margin-left: auto;
  margin-right: 10px;
  width: 80px;
`;
const Description = styled.div`
  width: 280px;
  color: #aaa;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type IProps = {
  data: INode;
  index: number;
};

function SeriesNodeItem({data, index}: IProps): ReactElement {
  const {database, node, unit, description} = data;
  const cloned = [...node];
  const name = cloned.pop();
  const path = cloned.join(', ');

  return (
    <Container>
      <Index>{index}</Index>
      <Database>{database},</Database>
      {path && <Path>{path}, </Path>}
      <Name>{name}</Name>
      <Unit>{unit}</Unit>
      <Description>{description}</Description>
    </Container>
  );
}

export default memo(SeriesNodeItem);
