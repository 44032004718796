import {Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {BasicSpinner, ModalBody, ModalHeader, ModalWrapper} from 'components/common';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';
import ServerItemList from 'components/pc/widgets/modelRunner/initialize-modal-parts/ServerItemList';
import {IModelRunnerProject, IModelRunnerServer} from 'components/pc/widgets/modelRunner/types';
import ServerInfo from 'components/pc/widgets/modelRunner/initialize-modal-parts/ServerInfo';
import {Button} from 'components/forms';
import PrevButton from 'components/mpfd/panel/modal/PrevButton';
import ConnectedMessage from 'components/pc/widgets/modelRunner/initialize-modal-parts/ConnectedMessage';
import ProjectItemList from 'components/pc/widgets/modelRunner/initialize-modal-parts/ProjectItemList';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import DisconnectModal from 'components/pc/widgets/modelRunner/initialize-modal-parts/DisconnectModal';

const ElementContainer = styled.div``;

type IProps = {
  serverListState: [IModelRunnerServer[], Dispatch<SetStateAction<IModelRunnerServer[]>>];
  selectedServerState: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
  customServerState: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
  initializeModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  projectList: IModelRunnerProject[];
  selectedProjectState: [IModelRunnerProject, Dispatch<SetStateAction<IModelRunnerProject>>];
  serverConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
  projectConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
  getServerList(): Promise<void>;
  connectToServer(): Promise<void>;
  disconnectFromServer(): void;
};

function ModelRunnerInitializeModal({
  serverListState,
  selectedServerState,
  customServerState,
  initializeModalState,
  projectList,
  selectedProjectState,
  serverConnectedState,
  projectConnectedState,
  getServerList,
  connectToServer,
  disconnectFromServer
}: IProps): ReactElement {
  const stepState = useState<number>(0);
  const [step, setStep] = stepState;
  const [loading, setLoading] = useState(true);

  const [, setIsShowInitializeModal] = initializeModalState;
  const [selectedServer] = selectedServerState;

  const [isServerConnected] = serverConnectedState;
  const [isProjectConnected] = projectConnectedState;

  const [isShowDisconnectModal, setIsShowDisconnectModal] = useState(false);

  const [disabled, setDisabled] = useState<boolean>(true);

  useModalKeyBoardEvent({
    onConfirm: () => !disabled && onClick(),
    onCancel: () => onClose()
  });

  useEffect(() => {
    if (step === 0) {
      getServerList().then(() => setStep(1));
    } else if (step === 3) {
      //todo isServerConnected 조건일 때와 아닐 때의 setTime 시간을 다르게 설정할 경우 step이 꼬이는 버그 해결 필요
      if (isServerConnected) {
        setTimeout(() => setStep(4), 3000);
      } else {
        setTimeout(() => setStep(4), 3000);
      }
    } else if (step === 6) {
      if (isProjectConnected) {
        setTimeout(() => setStep(7), 1500);
      } else {
        setTimeout(() => setStep(7), 5000);
      }
    }
  }, [isProjectConnected, isServerConnected, step]);

  // custom server select 시 보내는 정보가 비어있는지 확인 후 disabled 처리
  useEffect(() => {
    if (selectedServer && Object.keys(selectedServer).length > 0) {
      const isDisabled = Object.entries(selectedServer)
        // .filter(([key]) => key !== 'name') // name 키 제외
        .some(([, value]) => value === null || value === '');

      setDisabled(isDisabled);
    }
  }, [selectedServer]);

  const hideConfirmBtn =
    step === 2 || (step === 4 && isServerConnected) || (step === 7 && isProjectConnected) ? true : false;

  const confirmBtnTitle = step === 2 ? 'Connect' : 'Ok';

  const onClose = () => {
    setIsShowInitializeModal(false);
  };

  const onClickPrevButton = () => {
    if (step === 4) {
      setStep(2);
    } else if (step === 5) {
      setIsShowDisconnectModal(true);
    } else if (step === 7) {
      setStep(5);
    } else {
      setStep(step - 1);
    }
  };

  const onClick = () => {
    if (step === 2) {
      connectToServer();
      setStep(3);
    } else if (step === 4) {
      setStep(5);
    } else if (step === 7) {
      setIsShowInitializeModal(false);
    }
  };

  const onConfirm = () => {
    disconnectFromServer();
    setIsShowDisconnectModal(false);
    setStep(1);
  };

  const modalTitle = step < 5 ? 'Connect Model Manager Server' : 'Connect Model Manager Project';

  return (
    <ModalWrapper type="inner">
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalBody align="center" size="full-width" height={250}>
        <StepContainer width={560} height={280}>
          <StepScroller total={8} step={step}>
            <Step>
              <ElementContainer>
                <BasicSpinner isShow={loading} type="inline" margin="0 10px" position="center-center" size="md" />
                Searching Server
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <ServerItemList
                  stepState={stepState}
                  serverListState={serverListState}
                  selectedServerState={selectedServerState}
                  customServerState={customServerState}
                />
              </ElementContainer>
            </Step>
            <Step>
              <PrevButton onClick={onClickPrevButton}>Reselect</PrevButton>
              <ElementContainer>
                <ServerInfo selectedServerState={selectedServerState} />
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <BasicSpinner isShow={loading} type="inline" margin="0 10px" position="center-center" size="md" />
                Connecting Server
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                {!isServerConnected && <PrevButton onClick={onClickPrevButton}>Reselect</PrevButton>}
                <ConnectedMessage selectedServerState={selectedServerState} isServerConnected={isServerConnected} />
              </ElementContainer>
            </Step>
            <Step>
              <PrevButton onClick={onClickPrevButton}>Reselect</PrevButton>
              <ElementContainer>
                <ProjectItemList
                  stepState={stepState}
                  projectList={projectList}
                  selectedProjectState={selectedProjectState}
                  projectConnectedState={projectConnectedState}
                />
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <BasicSpinner isShow={loading} type="inline" margin="0 10px" position="center-center" size="md" />
                Opening Project
              </ElementContainer>
            </Step>
            <Step>
              {!isProjectConnected && <PrevButton onClick={onClickPrevButton}>Reselect</PrevButton>}
              <ElementContainer>
                <ConnectedMessage selectedProjectState={selectedProjectState} isProjectConnected={isProjectConnected} />
              </ElementContainer>
            </Step>
          </StepScroller>
        </StepContainer>
        {hideConfirmBtn && (
          <Button disabled={disabled} style={{marginLeft: 'auto'}} width={120} onClick={onClick}>
            {confirmBtnTitle}
          </Button>
        )}
        {isShowDisconnectModal && (
          <DisconnectModal onClose={() => setIsShowDisconnectModal(false)} onConfirm={onConfirm} />
        )}
      </ModalBody>
    </ModalWrapper>
  );
}

export default ModelRunnerInitializeModal;
