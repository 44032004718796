import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common/index';
import {Button} from 'components/forms';
import BasicSpinner from 'components/common/BasicSpinner';
import styled from 'styled-components';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';
import {CommonContext} from 'components/common/CommonProvider';

const LoadingSymbolWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

function DetectionProcessModal(): ReactElement {
  const {uploadDetectionFile, onChangeDetectProgress, hideMPfdModal, isDetectProgress} = useContext(MetaPfdContext);
  const {showModal} = useContext(CommonContext);
  const [complete, setComplete] = useState(false);
  const [step, setStep] = useState(0);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirmModal(),
    onCancel: () => onCancelModal()
  });

  const onCloseModal = () => {
    if (isDetectProgress) return;
    hideMPfdModal();
  };

  const onCancelModal = () => {
    if (isDetectProgress) return;
    hideMPfdModal();
  };

  const onProgressModal = async () => {
    onChangeDetectProgress(true);
    try {
      const isSuccess = await uploadDetectionFile();

      if (!isSuccess) {
        onChangeDetectProgress(false);
        setComplete(true);
        hideMPfdModal();
        showModal({
          title: 'Warning',
          content: 'Fail to detect file.'
        });
        return;
      } else {
        onChangeDetectProgress(false);
        setComplete(true);
      }
    } catch (e) {
      hideMPfdModal();
      showModal({
        title: 'Warning',
        content: 'Fail to detect file.'
      });
      return;
    }
  };

  useEffect(() => {
    if (!isDetectProgress && !complete) {
      setStep(0);
    } else if (isDetectProgress && !complete) {
      setStep(1);
    } else if (!isDetectProgress && complete) {
      setStep(2);
    }
  }, [isDetectProgress, complete]);

  const onConfirmModal = () => {
    if (complete) {
      onCloseModal();
    } else {
      !isDetectProgress && onProgressModal();
    }
  };

  return (
    <ModalWrapper>
      <ModalHeader disableClose={isDetectProgress} onClose={onCloseModal}>
        Line Detection Tool
      </ModalHeader>
      <ModalBody>
        <StepContainer width={560}>
          <StepScroller total={3} step={step}>
            <Step>
              The Line Detection Tool will analyze the PFD file <br /> and automatically create and tag lines.
              <br />
              This action can take around 20 to 30 seconds.
              <br />
              To proceed, please click the 'Run' button
            </Step>
            <Step>Detecting lines in the PFD file. This may take around 20 to 30 seconds.</Step>
            <Step>
              Line Detection Complete.
              <br />
              Please click on the automatically generated lines to list it.
            </Step>
          </StepScroller>
        </StepContainer>
      </ModalBody>
      <ModalFooter>
        <LoadingSymbolWrapper>
          {isDetectProgress && <BasicSpinner size="md" isShow={isDetectProgress} margin={0} />}
        </LoadingSymbolWrapper>
        <Button width={100} disabled={isDetectProgress} onClick={onConfirmModal}>
          {complete ? 'Ok' : 'Run'}
        </Button>
        {step < 2 && (
          <Button variant="optional" disabled={isDetectProgress} onClick={onCancelModal}>
            Cancel
          </Button>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
}

export default DetectionProcessModal;
