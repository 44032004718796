import React from 'react'
import { useGLTF } from '@react-three/drei'

export function ModelParts({ visibility }) {
  const { nodes, materials } = useGLTF('./3D/Parts/Parts.gltf')
  return (
    <group dispose={null} scale={0.2}>
      {nodes[`${visibility}_Parts_1`] && visibility === '01' ? (
        <mesh
          key={visibility}
          name={`Parts_${visibility}`}
          geometry={nodes[`${visibility}_Parts_1`].geometry}
          material={materials.Simacro_Parts}
          position={[0,16,0]}
          rotation={nodes[`${visibility}_Parts`].rotation}
          scale={nodes[`${visibility}_Parts`].scale}
        />
      ) : null}
    </group>
  )
}

useGLTF.preload('./3D/Parts/Parts.gltf')