import {ChangeEvent, useContext} from 'react';
import {SwitchForm} from 'components/forms';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';

type IProps = {
  name: string;
  voiceIsOn: boolean;
  onToggle: (name: string, value: boolean) => void;
};

function VoiceModeToggle({name, voiceIsOn, onToggle}: IProps) {
  const {setVoiceIsOn} = useContext(CopilotContext);

  const toggleHandler = () => {
    onToggle(name, !voiceIsOn);
    setVoiceIsOn(voiceIsOn);
  };

  const voiceType = voiceIsOn ? 'On' : 'Off';
  return (
    <SwitchForm
      name="voice-mode-toggle"
      id="voice-mode-toggle"
      label={voiceType}
      labelPosition="left"
      checked={voiceIsOn}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        toggleHandler();
      }}
    />
  );
}

export default VoiceModeToggle;
