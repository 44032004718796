import React, {ReactElement, useContext, useState} from 'react';
import styled from 'styled-components';
import {IChatBody} from 'components/menu/copilot/types';
import CopilotImageCard from 'components/menu/copilot/CopilotImageCard';
import RecordPlayer from 'components/menu/copilot/RecordPlayer';
import {faUser, faRobot} from '@fortawesome/pro-light-svg-icons';
import {faVolume} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import copilotProvider, {CopilotContext} from 'components/menu/copilot/CopilotProvider';

const Container = styled.div`
  margin-bottom: 35px;
`;

const Message = styled.div`
  padding: 0 16px;
`;

const MessageText = styled.div`
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 0;
  word-break: break-word;
  white-space: pre-line;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  h4 {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }
`;

const CopilotProfileBackground = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: #171523;
`;

const UserProfileBackground = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: #32499a;
`;

/*export type IChatBodyData = {
  id: string;
  create_at: number;
  text: string;
  image?: string;
  role: string;
};*/

function ChatMessageItem({message}: IChatBody): ReactElement {
  const {voiceIsOn} = useContext(CopilotContext);
  const currentUser = 'user';
  const [isShowPlaying, setIsShowPlaying] = useState(false);

  return (
    <Container>
      <Message>
        <div className={`message ${message.role === currentUser ? 'user' : 'copilot'}`}>
          <Profile>
            {message.role === currentUser ? (
              <>
                <UserProfileBackground>
                  <FontAwesomeIcon icon={faUser} size="lg" />
                </UserProfileBackground>
                <h4>You</h4>
                {message.voice && voiceIsOn && (
                  <RecordPlayer message={message} isShowPlaying={isShowPlaying} setIsShowPlaying={setIsShowPlaying} />
                )}
              </>
            ) : (
              <>
                <CopilotProfileBackground>
                  <FontAwesomeIcon icon={faRobot} size="lg" />
                </CopilotProfileBackground>
                <h4>Copilot</h4>
                {message.voice && voiceIsOn && (
                  <RecordPlayer isShowPlaying={isShowPlaying} message={message} setIsShowPlaying={setIsShowPlaying} />
                )}
              </>
            )}
          </Profile>
          {message.image && <CopilotImageCard message={message} />}
          <MessageText>{message.text}</MessageText>
        </div>
      </Message>
    </Container>
  );
}

export default ChatMessageItem;
