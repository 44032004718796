import {useContext} from 'react';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {AuthContext} from 'components/auth/AuthProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {getApiHost} from 'api/function';

type ContentTypes = 'application/json' | undefined;

export type IApi = {
  get: <T>(url: string, options?: IOptions<T>) => Promise<T>;
  post: <T>(url: string, payload: object, options?: IOptions<T>) => Promise<T>;
  getImgBlob: (url: string) => Promise<Blob>;
  exportFile: (url: string, payload: object) => Promise<Response>;
};

const getHeaders = <T>(withToken?: boolean, options?: IOptions<T>, contentType: ContentTypes = 'application/json') => {
  const token = LocalStorageManager.getItem<string>('PROCESSMETAVERSE_LOGIN_TOKEN');
  const Authorization = withToken ? `Bearer ${token}` : undefined;
  switch (options?.type) {
    case 'binary':
      return {
        Authorization
      };
    case 'formData':
      return {
        Authorization
      };
  }
  return {
    Authorization,
    'Content-Type': contentType
  };
};

type IOptions<T> = {
  type?: 'default' | 'binary' | 'blob' | 'formData'; // binary 는 2024-06-18 현재 VoiceCommand 에서만 사용
  errorReturn?: T;
};

type MethodTypes = 'GET' | 'POST' | 'PUT' | 'DELETE';
type IRequestParams = {
  response?: Response;
  url: string;
  method: MethodTypes;
  message: string;
};

function useApi() {
  const {userProfile} = useContext(AuthContext);
  const {showModal} = useContext(CommonContext);
  const API_HOST = getApiHost();

  const showErrorModal = (params: IRequestParams): void => {
    showModal({
      title: `${params.response?.status || 'API'} Error`,
      content: `Bad response from server\n${API_HOST}${params.url}`,
      message: params.message
    });
  };
  /**
   * api 응답 성공
   * @param response
   * @param url
   * @param method
   * @param options
   * @param isBlob
   */
  const onSuccess = <T>(
    response: Response,
    url: string,
    method: MethodTypes,
    options?: IOptions<T>,
    isBlob?: boolean
  ) => {
    if (response.status >= 400 && response.status < 600) {
      if (options.errorReturn) return options.errorReturn as T;
      const message = 'Bad response from server';
      showErrorModal({url, method, response, message});

      throw new Error(`${response.status} Error : ${message}`);
    }
    if (isBlob) {
      return response.blob() as T;
    }
    return response.json() as T;
  };

  /**
   * api 응답 실패
   * @param error
   * @param url
   * @param method
   */
  const onError = (error: Error, url: string, method: MethodTypes) => {
    const message = 'API Error';
    showErrorModal({url, method, message});

    throw new Error('API Error.', error);
  };

  /**
   * API Get
   * @param url API 주소
   * @param options
   */
  const get = async <T>(url: string, options?: IOptions<T>): Promise<T> => {
    return fetch(`${API_HOST}${url}`, {method: 'GET', headers: getHeaders(true)})
      .then((response) => onSuccess(response, url, 'GET', options))
      .then((response) => response)
      .catch((error) => onError(error, url, 'GET'));
  };

  /**
   * API Post
   * @param url API 주소
   * @param payload 전송할 정보 객체
   * @param options
   */
  const post = async <T>(url: string, payload: object, options?: IOptions<T>): Promise<T> => {
    // console.log('API_HOST_TYPE', LocalStorageManager.getItem('API_HOST_TYPE'));
    // 사용자 정보가 없으면 중단

    if (!userProfile) {
      showErrorModal({url, method: 'POST', message: 'Not found user information.'});
      return;
    }

    const headers = getHeaders(true, options);
    const body =
      options?.type === 'binary' || options?.type === 'formData' ? (payload as BodyInit) : JSON.stringify(payload);
    return fetch(`${API_HOST}${url}`, {method: 'POST', headers, body})
      .then((response) => onSuccess<T>(response, url, 'POST', options))
      .then((response) => response)
      .catch((error) => onError(error, url, 'POST'));
  };

  /**
   * API img Get
   * @param url API 주소
   */
  const getImgBlob = async (url: string): Promise<Blob> => {
    return fetch(`${API_HOST}${url}`, {method: 'GET', headers: getHeaders()})
      .then((response) => onSuccess<Blob>(response, url, 'GET', undefined, true))
      .then((response) => response)
      .catch((error) => onError(error, url, 'GET'));
  };

  const exportFile = async (url: string, payload: object): Promise<Response> => {
    return fetch(`${API_HOST}${url}`, {
      method: 'POST',
      headers: getHeaders(true),
      body: JSON.stringify(payload)
    }).then(async (response) => response);
  };

  return {get, post, getImgBlob, exportFile};
}

export default useApi;
