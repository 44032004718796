import {ReactElement, useContext} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import {ICopilotThread} from 'components/menu/copilot/types';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';

type IProps = {
  onClose(): void;
  item: ICopilotThread;
};

function OptionDelete({onClose, item}: IProps): ReactElement {
  const copilotContext = useContext(CopilotContext);
  const onConfirm = (): void => {
    copilotContext.remove(item.id);
  };

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>Confirm Delete</ModalHeader>
      <ModalBody>This will delete "{item.title}"</ModalBody>
      <ModalFooter>
        <Button width={100} variant="danger" onClick={onConfirm}>
          Delete
        </Button>
        <Button variant="cancel" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default OptionDelete;
