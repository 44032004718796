import {BasicSpinner, ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import Button from 'components/forms/Button';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {Link, useNavigate} from 'react-router-dom';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import FormRow from 'components/forms/FormRow';
import {useContext} from 'react';
import {AuthContext} from 'components/auth/AuthProvider';
import ApiHostTypeDisplay from 'components/common/ApiHostTypeDisplay';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {MetaPfdContext} from 'components/mpfd';

type IProps = {
  onClose(): void;
  // isLoading: boolean;
};

function UserProfileModal({onClose}: IProps) {
  const {userProfile, isLoading} = useContext(AuthContext);
  const pc = useContext(ProcessCanvasContext);
  const mpfd = useContext(MetaPfdContext);

  const navigate = useNavigate();

  useModalKeyBoardEvent({
    onCancel: () => onClose()
  });

  const logout = () => {
    LocalStorageManager.removeItem('PROCESSMETAVERSE_LOGIN_TOKEN');
    navigate('/login');
    pc.close();
    mpfd.close();
  };

  return (
    <ModalWrapper type="user-info">
      <ModalHeader onClose={onClose}>User Profile</ModalHeader>
      <ModalBody align="top" size="full-width">
        <ApiHostTypeDisplay />
        {!isLoading && (
          <>
            <FormRow header="User ID :">{userProfile?.username}</FormRow>
            <FormRow header="User Name :">
              {userProfile?.firstName} {userProfile?.lastName}
            </FormRow>
            <FormRow header="Email :">{userProfile?.email}</FormRow>
          </>
        )}
        <BasicSpinner isShow={isLoading} size="md" type="overlay" position="center-center" margin={2} />
      </ModalBody>
      <ModalFooter>
        <Button width={100} onClick={logout}>
          Logout
        </Button>
        <Link to={'/login/password/recover'}>
          <Button variant="warning">Reset Password</Button>
        </Link>
        <Button variant="cancel" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default UserProfileModal;
