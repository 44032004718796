import {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {getApiHost} from 'api/function';
import {AuthContext} from 'components/auth/AuthProvider';
import {
  IModelRunnerProject,
  IModelRunnerServer,
  IModelRunnerVariable,
  IValueGroupObj
} from 'components/pc/widgets/modelRunner/types';
import {IDatasheetSubject, IWidgetSubject} from 'api/LocalDatabaseProvider';
import useApi from 'api/useApi';
import {IApiReturnBasic} from 'api/data-types';

type IReturn = {
  logData: string;
  messages: string;
  projectList: IModelRunnerProject[];
  serverName: string;
  sendMessage({type, cmd, receiver, data}): Promise<void>;
  setProjectList: Dispatch<SetStateAction<any[]>>;
  sendProjectInput(projectName, ioName): Promise<void>;
  connectToServer(): Promise<void>;
  disconnectFromServer(): void;
  getServerList(): Promise<void>;
};

type IProps = {
  focusedRowDataRef: MutableRefObject<{}>;
  variableTableRef: MutableRefObject<IModelRunnerVariable[]>;
  sourceDataBaseRef: MutableRefObject<IWidgetSubject<IDatasheetSubject>>;
  isRunState: [boolean, Dispatch<SetStateAction<boolean>>];
  valueGroupObjectState: [IValueGroupObj, Dispatch<SetStateAction<IValueGroupObj>>];
  selectedServerState: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
  serverConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
  // projectConnectedState: [boolean, Dispatch<SetStateAction<boolean>>];
  serverListState: [IModelRunnerServer[], Dispatch<SetStateAction<IModelRunnerServer[]>>];
  runModel(projectName: string): Promise<void>;
};

const API_HOST = getApiHost();

function useModelRunnerLoader({
  focusedRowDataRef,
  variableTableRef,
  sourceDataBaseRef,
  isRunState,
  valueGroupObjectState,
  selectedServerState,
  serverConnectedState,
  serverListState,
  runModel
}: IProps): IReturn {
  const webSocket = useRef(null);
  const {userProfile, token} = useContext(AuthContext);
  const api = useApi();

  const [serverName, setServerName] = useState('');

  const [, setIsServerConnected] = serverConnectedState;
  const [logData, setLogData] = useState('');
  const [messages, setMessages] = useState('');

  const [, setServerList] = serverListState;
  const [selectedServer] = selectedServerState;

  const [projectList, setProjectList] = useState<IModelRunnerProject[]>([]);
  const [, setIsRun] = isRunState;

  useEffect(() => {
    return () => {
      webSocket?.current?.close();
    };
  }, []);

  useEffect(() => {
    setServerName(selectedServer?.name);
  }, [selectedServer]);

  const getWebsocketHost = (url) => {
    // console.log(url);
    if (url.startsWith('https://')) {
      // For HTTPS URLs, use WSS (WebSocket Secure)
      return url.replace('https://', 'wss://');
    } else if (url.startsWith('http://')) {
      // For HTTP URLs, use WS (WebSocket)
      return url.replace('http://', 'ws://');
    }
  };

  const getServerList = async () => {
    return await api.get<IApiReturnBasic>('/model_manager/server_list').then((res) => {
      if (res.success) {
        setServerList(res.data as IModelRunnerServer[]);
      }
    });
  };

  const connectToServer = async () => {
    console.log('Connect Rquest!!');
    const MY_TOKEN = token;

    return await api
      .post<IApiReturnBasic>(`/model_manager/connect`, {
        pmv_id: userProfile.username,
        server_name: selectedServer.name,
        server_ip: selectedServer.ip,
        server_port: selectedServer.port
      })
      .then((res) => {
        if (res.success) {
          const websocketHost = getWebsocketHost(API_HOST);
          webSocket.current = new WebSocket(`${websocketHost}/ws?token=${MY_TOKEN}&pmv_id=${userProfile.username}`);
          webSocket.current.onopen = () => {
            console.log('Connection opened');
            setIsServerConnected(true);
            addMessage('Successfully connected to Model Manager Server');
            requestProjectList();
            setLogData((prevLogData) => prevLogData + 'Connected to PMv server with ID:');
          };
          webSocket.current.onclose = (event) => {
            if (event.wasClean) {
              console.log(' Websocket closed successfully');
              webSocket.current = null;
            }
          };
          webSocket.current.onmessage = (msg) => {
            try {
              const res = JSON.parse(msg.data);
              let dynamicMessage;
              const newLog = `Received response: ${JSON.stringify(res, null, 2)}\n`;
              setLogData((prevLogData) => prevLogData + newLog);

              if (res.Cmd === 'ReqProjectList') {
                setProjectList((prevProjects) =>
                  res['Data']['ProjectList'].map((projectName) => {
                    requestProjectInfo(projectName);
                    const existingProject = prevProjects.find((p) => p.projectName === projectName);
                    if (existingProject) {
                      return existingProject;
                    } else {
                      return {projectName: projectName, modelInfo: [], seqInfo: [], ioInfo: [], model_list: []};
                    }
                  })
                );
                dynamicMessage = res.Data.Notify.Message;
              } else if (res.Cmd === 'ReqProjectInfo') {
                setProjectList((prevProjects) =>
                  prevProjects.map((project) => {
                    if (project.projectName === res.Data.ProjectName) {
                      if (res.Data.SeqInfo[0].Status === 'Completed') {
                        if (project.isBatch && project.isRun) {
                          runModel(project.projectName);
                        } else {
                          project.isRun = false;
                          setIsRun(false);
                        }
                      }

                      return {
                        ...project,
                        modelInfo: res.Data.ModelInfo.length === 0 ? project.modelInfo : res.Data.ModelInfo,
                        seqInfo: res.Data.SeqInfo.length === 0 ? project.seqInfo : res.Data.SeqInfo,
                        ioInfo: res.Data.IOInfo.length === 0 ? project.ioInfo : res.Data.IOInfo
                      };
                    }
                    return project;
                  })
                );
                dynamicMessage = `Project ${res.Data.ProjectName}: Information has been updated.`;
              } else if (res.Cmd === 'SendProjectInput') {
                const projectName = res.Data.ProjectName;
                dynamicMessage = `Project ${projectName}: Data Successfully imported.`;
              } else if (res.Cmd === 'ExportReady') {
                const [server, projectName, ioType, ioName] = res.Sender.split('::');
                dynamicMessage = `Project ${projectName}: Export for "${ioName}" ready.`;

                setTimeout(() => {
                  setProjectList((prevProjects) =>
                    prevProjects.map((project) => {
                      if (project.projectName === projectName) {
                        requestProjectOutput(projectName);
                      }
                      return project;
                    })
                  );
                }, 100);
              } else if (res.Cmd === 'ReqProjectOutput') {
                let data = res['Data'];
                if (data['Notify']['ErrorCode'] === 0) {
                  const projectName = res.Data.ProjectName;
                  requestSeqInfo(res.Sender, res.Data.ProjectName);
                  dynamicMessage = `Project ${projectName}: Data has been successfully exported.`;

                  const convertOATimeToDate = (oaTime) => {
                    const OADateEpoch = new Date(Date.UTC(1899, 11, 30));
                    const msPerDay = 24 * 60 * 60 * 1000;
                    const dateInMs = OADateEpoch.getTime() + oaTime * msPerDay;
                    const date = new Date(dateInMs);

                    // Format the date as 'YYYY-MM-DD HH:MM:SS'
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');

                    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                  };

                  const newRow = {};

                  data['OutputList'].forEach((item) => {
                    if (item.Tag !== 'TimeTag') {
                      newRow[item.Tag] = item.Value;
                    } else {
                      newRow[item.Tag] = convertOATimeToDate(item.Value);
                    }
                  });

                  sourceDataBaseRef?.current?.ref?.current?.getOutflowResult({outflowResult: newRow});
                }
              } else if (res.Cmd === 'SendProjectAction') {
                if (res.Data?.Action === 'RunSequence') {
                  if (res.Data?.Notify?.Message === 'Sequence ASDT: SeqStart') {
                    setProjectList((prevProjects) =>
                      prevProjects.map((project) => {
                        if (project.projectName === res.Data.ProjectName) {
                          sendProjectInput(res.Data.ProjectName, project.seqInfo[0]?.Name);
                        }
                        return project;
                      })
                    );
                  }
                  requestSeqInfo(res.Sender, res.Data.ProjectName);
                }
              }
              if (dynamicMessage) {
                addMessage(dynamicMessage);
              }
            } catch (error) {
              console.error('수신한 메시지를 처리하는 중 오류 발생:', error);
            }
          };
        } else {
          if (webSocket?.current) {
            setIsServerConnected(false);
            webSocket.current?.close();
          }
        }
      });
  };

  const disconnectFromServer = () => {
    let dynamicMessage;
    if (webSocket.current) {
      webSocket.current.close();
      setIsServerConnected(false);
      webSocket.current = null;
      setLogData((prevLogData) => prevLogData + 'Disconnected from server\n');
      dynamicMessage = `Disconnected from server`;
    } else {
      setLogData((prevLogData) => prevLogData + 'No active connection to disconnect\n');
      dynamicMessage = 'No active connection to disconnect';
    }
    if (dynamicMessage) {
      addMessage(dynamicMessage);
    }
  };

  const addMessage = (message) => {
    setMessages((prevMessages) => prevMessages + message + '\n');
  };

  const sendMessage = async ({type, cmd, receiver, data}) => {
    webSocket.current.send(
      JSON.stringify({
        Type: type,
        Cmd: cmd,
        Sender: userProfile.username,
        Receiver: receiver,
        Data: data
      })
    );
  };

  const requestProjectOutput = (projectName) => {
    setProjectList((prevProjects) =>
      prevProjects.map((project) => {
        if (project.projectName === projectName) {
          let ioName = project.modelInfo[0]?.Name;
          let ioData = project.ioInfo.find((io) => io.Type === 'Export' && io.Name === ioName)?.Data;
          sendMessage({
            type: 'Cmd',
            cmd: 'ReqProjectOutput',
            receiver: serverName + '::' + projectName,
            data: {ProjectName: projectName, ExportName: ioName, OutputList: ioData}
          });
          return project;
        }
      })
    );
  };

  const sendProjectInput = async (projectName, ioName) => {
    const inflowForLog = {};

    function convertToOATime(value) {
      const date = new Date(value);
      return date.getTime() / (1000 * 60 * 60 * 24) + 25569;
    }

    let variableInput = await Promise.all(
      variableTableRef.current.map(async (row) => {
        if (row.flowType === 'Import') {
          const searchResult = valueGroupObjectState?.[0]?.[row.keys]?.value?.[0]?.[1];
          const searchResultFormDatasheet = focusedRowDataRef.current?.[row?.path?.[1]];
          if (searchResult) {
            inflowForLog[row?.variableName] = searchResult;
            return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify(searchResult || '')}`;
          } else if (searchResultFormDatasheet) {
            inflowForLog[row?.variableName] = searchResultFormDatasheet;
            return {
              Tag: row?.variableName,
              Value:
                row?.variableName === 'TimeTag' ? convertToOATime(searchResultFormDatasheet) : searchResultFormDatasheet
            };
          }
        }
      })
    );

    variableInput = variableInput.filter((item) => item !== undefined);

    sendMessage({
      type: 'Cmd',
      cmd: 'SendProjectInput',
      receiver: serverName + '::' + projectName,
      data: {ProjectName: projectName, InputList: variableInput, ImportName: ioName}
    });
  };

  const requestProjectList = () => {
    sendMessage({type: 'Cmd', cmd: 'ReqProjectList', receiver: serverName, data: {}});
  };

  const requestProjectInfo = (projectName) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo',
      receiver: serverName + '::' + projectName,
      data: {ProjectName: projectName, ModelInfo: true, SeqInfo: true, IOInfo: true}
    });
  };

  const requestSeqInfo = (sender, projectName) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo',
      receiver: sender,
      data: {ProjectName: projectName, SeqInfo: true}
    });
  };

  return {
    logData,
    messages,
    projectList,
    serverName,
    sendMessage,
    setProjectList,
    sendProjectInput,
    connectToServer,
    disconnectFromServer,
    getServerList
  };
}

export default useModelRunnerLoader;
