import styled from 'styled-components';
import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'components/forms';
import {capitalize} from 'utils/commons';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {IManagementAsideInfo, IEditUserInfo} from 'components/auth/management/type';
import AssignedItemsList from 'components/auth/management/parts/AssignedItemsList';
import {RoleInfo, GroupInfo} from 'components/auth/management/management-api-function';
import ManagementRegister from 'components/auth/management/user/ManagementRegister';

type WrapperStyleProps = {
  $isMiddle: boolean;
};

const Wrapper = styled.div<WrapperStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: ${(props) => (props.$isMiddle ? 0 : 1)};
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  .common-button {
    font-weight: 600;
    color: white;
  }
`;

const ErrorMessage = styled.div`
  color: ${({theme}) => theme.color.invalid} !important;
  font-weight: 400;
  font-size: 15px;
`;

type IProps = {
  currentRoleList?: RoleInfo[];
  currentGroupList?: GroupInfo[];
  asideInfo: IManagementAsideInfo;
  activateCancelButton?: boolean;
  onClose(): void;
  onClickCreate?(
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    groups?: string,
    roles?: string
  ): void;
  onClickDelete?(
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    groups?: string[],
    roles?: string[]
  ): void;
  assignGroupsAndRolesToUser?(groups: string[], roles: string[], user_name: string): void;
  removeGroupsAndRolesFromUser?(groups: string[], roles: string[], user_name: string): void;
  activateUser?(user_name: string): void;
  deactivateUser?(user_name: string): void;
};

function UserManagementAside({
  currentRoleList,
  currentGroupList,
  asideInfo,
  activateCancelButton = true,
  onClose,
  onClickCreate,
  onClickDelete,
  assignGroupsAndRolesToUser,
  removeGroupsAndRolesFromUser,
  activateUser,
  deactivateUser
}: IProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [isActive, setIsActive] = useState<boolean>();
  const [isCreateSuccess, setIsCreateSuccess] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const textareaRef = useRef(null);
  const [roleName, setRoleName] = useState('');
  const [groupName, setGroupName] = useState('');
  const [noAssignChangeMade, setNoAssignChangeMade] = useState<boolean>(false);

  useModalKeyBoardEvent({
    onConfirm: () => {
      const activeElement = document.activeElement;
      if (textareaRef.current !== activeElement) {
        onClickCreateBtn();
      }
    },
    onCancel: () => onClose()
  });

  useEffect(() => {
    if (asideInfo?.info) {
      const userInfo = asideInfo.info as IEditUserInfo;
      if ('username' in userInfo) setUserName(userInfo.username || '');
      if ('firstName' in userInfo) setFirstName(userInfo.firstName || '');
      if ('lastName' in userInfo) setLastName(userInfo.lastName || '');
      if ('email' in userInfo) setEmail(userInfo.email || '');
      if ('groups' in userInfo) setGroups(userInfo.groups || []);
      if ('roles' in userInfo) setRoles(userInfo.roles || []);
      if ('is_active' in userInfo) setIsActive(userInfo.is_active || false);
    }
  }, [asideInfo]);

  const onClickCreateBtn = () => {
    if (asideInfo?.type) {
      if (asideInfo?.type === 'create') {
        if (!isCreateSuccess) return;
        onClickCreate?.(userName, firstName, lastName, email);
      } else if (asideInfo?.type === 'edit' && asideInfo?.info) {
        const isActivateStateChanged = isActive !== (asideInfo?.info as IEditUserInfo).is_active;

        if (isActivateStateChanged) {
          if (isActive) activateUser?.(userName);
          else deactivateUser?.(userName);
        }

        const initialFirstName = (asideInfo?.info as IEditUserInfo).firstName || '';
        const initialLastName = (asideInfo?.info as IEditUserInfo).lastName || '';
        const initialEmail = (asideInfo?.info as IEditUserInfo).email || '';

        const isInitialStateChanged =
          firstName !== initialFirstName || lastName !== initialLastName || email !== initialEmail;

        if (isInitialStateChanged) {
          onClickCreate?.(userName, firstName, lastName, email);
        } else if (!isActivateStateChanged) {
          onClickCreate?.(userName, firstName, lastName, email);
        } else {
          onClose();
        }
      } else if (asideInfo?.type === 'detail' && asideInfo?.info) {
        const initialRoles = (asideInfo?.info as IEditUserInfo).roles || [];
        const initialGroups = (asideInfo?.info as IEditUserInfo).groups || [];

        const rolesToAssign = roles.filter((role) => !initialRoles.includes(role));
        const rolesToRemove = initialRoles.filter((role) => !roles.includes(role));
        const groupsToAssign = groups.filter((group) => !initialGroups.includes(group));
        const groupsToRemove = initialGroups.filter((group) => !groups.includes(group));

        assignGroupsAndRolesToUser(groupsToAssign, rolesToAssign, userName);
        removeGroupsAndRolesFromUser(groupsToRemove, rolesToRemove, userName);

        if (
          (rolesToAssign && rolesToAssign.length > 0) ||
          (rolesToRemove && rolesToRemove.length > 0) ||
          (groupsToAssign && groupsToAssign.length > 0) ||
          (groupsToRemove && groupsToRemove.length > 0)
        ) {
          setNoAssignChangeMade(false);
          onClose();
        } else {
          setNoAssignChangeMade(true);
        }
      }
    }
  };

  const onClickCancelBtn = () => {
    onClose();
  };
  const currentRoleNameList = currentRoleList?.map((role) => ({value: role.name, label: role.name}));

  const currentGroupNameList = currentGroupList?.map((group) => ({value: group.name, label: group.name}));

  const descriptionOfRole = (targetRole: string) => {
    if (!currentRoleList || currentRoleList.length === 0) return;
    const matchingRole = currentRoleList.find((role) => role.name === targetRole);
    return matchingRole?.description;
  };

  const descriptionOfGroup = (targetGroup: string) => {
    if (!currentGroupList || currentGroupList.length === 0) return;
    const matchingGroup = currentGroupList.find((group) => group.name === targetGroup);
    return matchingGroup?.description;
  };

  const isDetailView = asideInfo?.type && asideInfo?.type === 'detail';

  return (
    <>
      {!isDetailView && (
        <Wrapper $isMiddle={false}>
          <ManagementRegister
            setIsCreateSuccess={setIsCreateSuccess}
            setUserName={setUserName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setIsActive={setIsActive}
            isEdit={asideInfo?.type && asideInfo?.type === 'edit'}
            currentInfo={asideInfo?.info as IEditUserInfo}
          />
          <ErrorMessage>{asideInfo?.errorMsg}</ErrorMessage>
        </Wrapper>
      )}
      {isDetailView && (
        <Wrapper $isMiddle={false}>
          <Wrapper $isMiddle={true}>
            <AssignedItemsList
              title="Assign groups"
              currentList={currentGroupList}
              currentNameList={currentGroupNameList}
              nameType="group"
              placeholderName="Assign Group to User"
              selectedName={groupName}
              setSelectedName={setGroupName}
              items={groups}
              setItems={setGroups}
              descriptionOfItem={descriptionOfGroup}
            />
          </Wrapper>
          <Wrapper $isMiddle={true}>
            <AssignedItemsList
              title="Assign roles"
              currentList={currentRoleList}
              currentNameList={currentRoleNameList}
              nameType="role"
              placeholderName="Assign Role to User"
              selectedName={roleName}
              setSelectedName={setRoleName}
              items={roles}
              setItems={setRoles}
              descriptionOfItem={descriptionOfRole}
            />
          </Wrapper>
          {noAssignChangeMade && <ErrorMessage> No changes made </ErrorMessage>}
          <ErrorMessage>{asideInfo?.errorMsg} </ErrorMessage>
        </Wrapper>
      )}
      <ButtonContainer>
        <Button
          width={108}
          variant={asideInfo?.createVariant}
          className={asideInfo?.createLabel}
          onClick={onClickCreateBtn}
        >
          {capitalize(asideInfo?.createLabel)}
        </Button>
        {activateCancelButton && (
          <Button variant={'cancel'} className={'cancel'} onClick={onClickCancelBtn}>
            {capitalize('Cancel')}
          </Button>
        )}
      </ButtonContainer>
    </>
  );
}

export default UserManagementAside;
