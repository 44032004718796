import styled from 'styled-components';
import {Button} from 'components/forms';
import {ChangeEvent, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faChevronLeft, faChevronRight} from '@fortawesome/pro-light-svg-icons';
import {ITableField} from 'components/table/DefaultTable';
import ComponentTable from 'components/table/ComponentTable';
import {RoleInfo, GroupInfo, UserInfo} from 'components/auth/management/management-api-function';
import ReactPaginate from 'react-paginate';

const Container = styled.div`
  padding-top: 44px;
  height: calc(100% - 100px);
  box-sizing: border-box;
  display: flex;
  gap: 22px;
  flex-direction: column;
`;
const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftDiv = styled.div`
  display: flex;
  gap: 18px;
  .common-button {
    width: 108px;

    font-weight: 600;
    color: white;
  }
`;
const RightDiv = styled.div`
  .pagination {
    display: flex;
    user-select: none;
    .active {
      background-color: #a7cbff;
      color: white;
      border-radius: 5px;
    }
    .disabled {
      a {
        cursor: default;
      }
    }
    .previous-page-link,
    .next-page-link,
    .page-link {
      cursor: pointer;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      width: 30px;
      height: 30px;
    }
  }
`;
const SearchContainer = styled.div`
  width: 312px;
  box-sizing: border-box;
  position: relative;
  color: #b6b4c0;
  input {
    border: 1px solid #b6b4c0;
    border-radius: 5px;
    box-sizing: border-box;
    height: 40px;
    padding: 12px 0 12px 34px;
    font-size: 15px;
    background-color: transparent;
    width: 312px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 18px;
  left: 12px;
`;

const TableBodyContainer = styled.div`
  height: calc(100% - 40px);
`;

type IInfoList = RoleInfo[] | GroupInfo[] | UserInfo[];

type IProps = {
  fields: ITableField[];
  list: IInfoList;
  listType: string;
  onClickCreate?(): void;
  onClickRow(rowKey: string, columnKey?: string): void;
  rowKeyName?: string;
  needColumnKey?: boolean;
};

function ManagementTable({
  fields,
  list,
  listType,
  onClickCreate,
  onClickRow,
  rowKeyName,
  needColumnKey = false
}: IProps) {
  const [searchValue, setSearchValue] = useState('');
  const [itemOffset, setItemOffset] = useState(0);
  const [copyList, setCopyList] = useState<IInfoList>([]);
  const [selectedPage, setSelectedPage] = useState(0);

  const itemsPerPage = 10;

  const endOffset = itemOffset + itemsPerPage;
  useEffect(() => {
    if (Array.isArray(list)) {
      const filteredList = () => {
        if (listType === 'role') {
          return (list as RoleInfo[]).filter(
            (item) =>
              item.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.description?.toLowerCase().includes(searchValue.toLowerCase())
          );
        }
        if (listType === 'group') {
          return (list as GroupInfo[]).filter(
            (item) =>
              item.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
              (item.roles && item.roles.some((role) => role?.toLowerCase().includes(searchValue.toLowerCase())))
          );
        }
        if (listType === 'user') {
          return (list as UserInfo[]).filter(
            (item) =>
              item.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.lastName?.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
              (item.roles && item.roles.some((role) => role?.toLowerCase().includes(searchValue.toLowerCase()))) ||
              (item.groups && item.groups.some((group) => group?.toLowerCase().includes(searchValue.toLowerCase())))
          );
        }
        return list;
      };
      setCopyList(searchValue ? filteredList() : list);
      setSelectedPage(0);
      setItemOffset(0);
    }
  }, [list, searchValue, listType]);

  const currentItems = [...copyList].slice(itemOffset, endOffset);
  const pageCount = Math.ceil([...copyList].length / itemsPerPage);

  const onChangeSearchValue = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setSearchValue(value);
    setItemOffset(0);
  };

  const onPageChange = (event) => {
    setSelectedPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % list?.length;
    setItemOffset(newOffset);
  };

  return (
    <Container>
      <TableHeaderContainer>
        <LeftDiv>
          {onClickCreate && (
            <Button variant="create" onClick={onClickCreate}>
              Create
            </Button>
          )}
          <SearchContainer>
            <IconWrapper>
              <FontAwesomeIcon icon={faSearch} />
            </IconWrapper>
            <input value={searchValue} onChange={onChangeSearchValue} />
          </SearchContainer>
        </LeftDiv>
        <RightDiv>
          <ReactPaginate
            onPageChange={onPageChange}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            pageCount={pageCount}
            forcePage={selectedPage}
            renderOnZeroPageCount={null}
            previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
            nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="previous-page-link"
            nextClassName="page-item"
            nextLinkClassName="next-page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        </RightDiv>
      </TableHeaderContainer>
      <TableBodyContainer>
        <ComponentTable
          fields={fields}
          rows={currentItems}
          rowKey={rowKeyName}
          needColumnKey={needColumnKey}
          onClick={onClickRow}
          onMouseUp={(e) => e.stopPropagation()}
          headerCssProperties={{color: '#739AD0', backgroundColor: '#D1E4FF'}}
        />
      </TableBodyContainer>
    </Container>
  );
}

export default ManagementTable;
