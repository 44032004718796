import {toSignificantDigit} from 'utils/number-utils';

export const DEFAULT_FEATURE = [
  'Temperature',
  'Pressure',
  'MolarFlowrate',
  'MassFlowrate',
  'VolumeFlowrate',
  'LiqVolFlow@StdCond',
  'CCR'
];

export const defaultUpdateData = [
  ['Temperature', 'C'],
  ['Pressure', 'bar_g'],
  ['MolarFlowrate', 'kmol/hr'],
  ['MassFlowrate', 'kg/s'],
  ['VolumeFlowrate', 'm3/hr'],
  ['LiqVolFlow@StdCond', 'm3/h'],
  ['CCR', '']
];

export const defaultHmbConfig = {
  autoUpdate: true,
  updateIntervalUnit: 1000,
  updateIntervalVal: '15',
  hiddenComponentList: [],
  significantLevel: 5,
  isShowConfig: false,
  isDataLoading: false,
  isHideComponent: false
};

export const defaultHmbStatus = {
  time: 0,
  isUpdateHappen: false
};

export const defaultHmbContent = {
  selectedStreamList: [{}],
  descriptions: null
};

export const SignificantLevelOptions = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8}
];

export const TimeUnitOptions = [
  {label: 'seconds', value: 1000},
  {label: 'minutes', value: 60000},
  {label: 'hours', value: 3600000}
];

export const hmbNormalCell = {
  renderer: 'HMBRenderer',
  contextMenu: false,
  readOnly: true
};

export const hmbDescriptionCell = {
  renderer: 'HMBRenderer',
  contextMenu: false,
  readOnly: false
};

export const checkIsStreamDropdownCell = (row: number, col: number) => {
  return col > 1 && row === 0;
};

export const checkIsFixedCellRightEndCol = (row: number, col: number) => {
  return col === 1;
};

export const checkIsStreamDescriptionCell = (row: number, col: number, colLength: number) => {
  return col > 1 && row === 1 && col < colLength - 1;
};

export const checkIsDefaultRow = (row: number) => {
  return row < 10;
};

export const checkIsValidStreamColumn = (col: number, colLength: number) => {
  return col < 2 || col === colLength - 1;
};

export const hmbStyle = (
  row: number,
  column: number,
  significantLevel: number,
  TD: any,
  dataLoading: boolean,
  isHideComponent: boolean,
  updateHappen: boolean
) => {
  TD.className = 'htMiddle';
  TD.style.whiteSpace = 'nowrap';
  TD.style.overflow = 'hidden';
  TD.style.textOverflow = 'ellipsis';

  if (column === 1) {
    TD.style.width = '100px';
  }
  if (row === 0) {
    TD.style.fontWeight = '400';
    TD.style.background = '#e3e3e3';
    if (column > 1) {
      TD.className = 'htMiddle htRight';
      TD.style.color = '#544fff';
      TD.style.fontWeight = '600';
    }
    if (column > 2) {
      TD.style.borderLeft = '1px solid white';
    }
    if (dataLoading && column > 1) {
      TD.style.opacity = 0.4;
    }
  } else {
  }

  if (checkIsFixedCellRightEndCol(row, column)) {
    TD.style.borderRight = '2px solid #bababb';
  }
  if (column > 1 && row === 1) {
    TD.style.paddingRight = '12px';
    TD.className = 'htMiddle htRight';
  }
  if (column < 2) {
    TD.className = 'htMiddle htLeft';
  }
  if (column > 1 && row > 1) {
    TD.style.color = '#544fff';
    if (updateHappen) {
      TD.className = 'htMiddle htRight update-color';
    } else {
      TD.className = 'htMiddle htRight ';
    }

    const previousValue = TD.innerHTML;
    const newValue = toSignificantDigit(previousValue, significantLevel);
    TD.innerHTML = `${newValue}`;
    TD.style.paddingRight = '12px';
  }
  if (row % 2 === 0 && row !== 0) {
    TD.style.background = '#f4f9fd';
  }
  if (column === 0 && row > 9) {
    TD.style.paddingLeft = '16px';
    TD.style.paddingRight = '4px';
  }

  if (row === 9) {
    TD.style.fontWeight = '400';
    TD.style.background = '#DDDDDD';
    if (column === 0) {
      if (isHideComponent) {
        TD.innerHTML = `${TD.innerHTML} <div class="arrow-down-green"></div>`;
      } else {
        TD.innerHTML = `${TD.innerHTML} <div class="arrow-up-green"></div>`;
      }
    }
  }
};
