import React, {Dispatch, ReactElement, SetStateAction} from 'react';
import styled from 'styled-components';
import {IModelRunnerProject, IModelRunnerServer} from 'components/pc/widgets/modelRunner/types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLaptopBinary, faFolderOpen} from '@fortawesome/pro-light-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

const Container = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 16px;
  }
`;

type IProps = {
  selectedServerState?: [IModelRunnerServer, Dispatch<SetStateAction<IModelRunnerServer>>];
  selectedProjectState?: [IModelRunnerProject, Dispatch<SetStateAction<IModelRunnerProject>>];
  isServerConnected?: boolean;
  isProjectConnected?: boolean;
};

function ConnectedMessage({
  selectedServerState,
  selectedProjectState,
  isServerConnected,
  isProjectConnected
}: IProps): ReactElement {
  const [selectedServer] = selectedServerState || [];
  const [selectedProject] = selectedProjectState || [];

  return (
    <Container>
      {/*project connect 조건*/}
      {isProjectConnected && selectedProject ? (
        <>
          <FontAwesomeIcon icon={faFolderOpen as IconProp} size="2x" />
          <p>Successfully opened {<strong>{selectedProject.projectName}</strong>}</p>
        </>
      ) : !isProjectConnected && selectedProject ? (
        <p>No projects available to open</p>
      ) : null}
      {/*server connect 조건*/}
      {isServerConnected && selectedServer ? (
        <>
          <FontAwesomeIcon icon={faLaptopBinary as IconProp} size="2x" />
          <p>Successfully connected to {<strong>{selectedServer.name}</strong>}</p>
        </>
      ) : !isServerConnected && selectedServer ? (
        <p>No server connections available</p>
      ) : null}
    </Container>
  );
}

export default ConnectedMessage;
