import styled from 'styled-components';
import {ReactElement} from 'react';

const Container = styled.div`
  width: 312px;
  display: flex;
  flex-direction: column;
  gap: 33px;
  .common-button {
    font-size: 15px;
  }
  a {
    font-size: 14px;
    text-decoration: none;
    color: #b6b4c0;
    &:nth-of-type(1) {
      &:has(img) {
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
    }
    &:hover {
      color: #171523;
    }
  }
`;

type IProps = {
  children?: ReactElement | ReactElement[];
};

function LoginScreenContainer({children}: IProps) {
  return <Container>{children}</Container>;
}

export default LoginScreenContainer;
