type InputValues = '0' | '' | '-' | undefined | number | string;

/**
 * 인자로 받은 값을 significant digit 으로 변환하여 리턴
 * @param value
 * @param digits
 */
export const toSignificantDigit = (value: InputValues, digits: number) => {
  if (value === 0 || value === '0') {
    return 0;
  } else if (value === '-') {
    return '-';
  } else if (value === undefined || value === '' || isNaN(Number(value))) {
    return '';
  } else if (Math.floor(Math.log10(Number(value)) + 1) < -3) {
    if (String(value).charAt(0) === '0') {
      return Number(value).toExponential(digits);
    } else {
      if (digits === 0) {
        return Number(value);
      } else {
        return Number(value).toExponential(digits - 1);
      }
    }
  } else {
    if (digits === 0) {
      return Number(value);
    } else {
      return Number(value).toPrecision(digits);
    }
  }
};
