import {useCallback, useContext, useEffect} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

function useMPfdKeyBoardEvent() {
  const {state, dispatchToReducer, isMetaPfdModalOpened, utilAction} = useContext(MetaPfdContext);

  const escKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        utilAction.cancel();
      }
    },
    [dispatchToReducer]
  );
  const delKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        dispatchToReducer({type: 'KEYPRESS_DELETE'});
      }
    },
    [dispatchToReducer]
  );

  useEffect(() => {
    if (isMetaPfdModalOpened) {
      return;
    }
    // const mode = state?.mode;
    // if (!mode) return;
    // if (mode?.mode === 'DRAW_EXPANDING_LINE') {
    document.addEventListener('keydown', escKeyPress);
    // }
    return () => {
      document.removeEventListener('keydown', escKeyPress);
    };
  }, [escKeyPress, isMetaPfdModalOpened]);

  useEffect(() => {
    if (isMetaPfdModalOpened) {
      return;
    }
    const selectedRegions = state?.regions.map((item) => item.highlighted);
    if (selectedRegions.length === 0) return;
    document.addEventListener('keydown', delKeyPress);
    return () => {
      document.removeEventListener('keydown', delKeyPress);
    };
  }, [state.regions, delKeyPress, isMetaPfdModalOpened]);
}

export default useMPfdKeyBoardEvent;
