import React, {MouseEvent, ReactElement, useState} from 'react';
import styled from 'styled-components';
import {getImage} from 'assets/images/svg-image';
import OptionDelete from 'components/menu/copilot/OptionDeleteModal';
import OptionDeleteAllModal from 'components/menu/copilot/OptionDeleteAllModal';
import {faTrashCan} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Container = styled.div`
  width: 125px;
  background-color: #f2f2f2;
  //padding: 5px 15px;
  border-radius: 5px;
  z-index: 1;
  position: fixed;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
`;

const DropMenu = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #575660;
  margin: 5px 5px;
  padding: 10px 10px;
  flex-direction: row;
  border-radius: 5px;

  svg {
    width: 15px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;

type IProps = {
  setIsOpen(bool: boolean): void;
};

function HistoryAllOption({setIsOpen}: IProps): ReactElement {
  const [isShow, setIsShow] = useState(false);

  const openDropMenu = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsShow(true);
  };

  const onClose = () => {
    setIsShow(true);
    setIsOpen(false);
  };
  return (
    <>
      <Container>
        <DropMenu onClick={openDropMenu} style={{color: '#D93939'}}>
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
          Delete All
        </DropMenu>
      </Container>
      {isShow && <OptionDeleteAllModal onSelect={() => {}} onClose={onClose} />}
    </>
  );
}

export default HistoryAllOption;
