import {NodeChange} from 'reactflow';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 4;
`;

type ChangeInfoProps = {
  change: NodeChange;
};

function ChangeInfo({change}: ChangeInfoProps) {
  const id = 'id' in change ? change.id : '-';
  const {type} = change;

  return (
    <Container>
      <div>node id: {id}</div>
      <div>
        {type === 'add' ? JSON.stringify(change.item, null, 2) : null}
        {type === 'dimensions' ? `dimensions: ${change.dimensions?.width} × ${change.dimensions?.height}` : null}
        {type === 'position' ? `position: ${change.position?.x.toFixed(1)}, ${change.position?.y.toFixed(1)}` : null}
        {type === 'remove' ? 'remove' : null}
        {type === 'select' ? (change.selected ? 'select' : 'unselect') : null}
      </div>
    </Container>
  );
}

export default ChangeInfo;
