import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {IProfileChartLoader, IProfileSeries, ISeriesPointData} from 'components/pc/widgets/profile-chart/types';
import styled from 'styled-components';
import {ColorPicker} from 'components/table/parts';
import {Button, TextForm} from 'components/forms';
import ActiveCheckbox from 'components/table/parts/ActiveCheckbox';
import SeriesGraphTypeSelector from 'components/table/parts/SeriesGraphTypeSelector';
import classNames from 'classnames';
import ParallelSelector from 'components/table/parts/ParallelSelector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleRight, faTrashCan, faTags} from '@fortawesome/pro-regular-svg-icons';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {INode, INodeDataReturn, INodeParams, INodeRecordParams} from 'api/data-types';
import SeriesNodeItem from 'components/pc/widgets/profile-chart/SeriesNodeItem';
import useApi from 'api/useApi';
import NodesSelectButton from 'components/pc/common/NodesSelectButton';

const Container = styled.div`
  min-height: 30px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow:
    0 2px 10px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.3);
`;
const BasicInfo = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
`;
const AdditionalInfo = styled.div`
  background-color: #d9d7da;
  overflow-y: auto;
  max-height: 400px;
  height: 0;

  &.open {
    height: auto;
  }
`;
const EmptyNode = styled.div`
  background-color: rgba(227, 228, 236, 0.5);
  border: 1px solid #a5a7b9;
  margin: 5px auto;
  width: 100px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #9496a6;
`;

const getNodeParamList = (nodes: string[][]): INodeParams[] => {
  // param 목록 구성
  return nodes?.map((node) => {
    const [database, ...rest] = node;
    const merged: INodeParams = {
      node: [...rest],
      database,
      latest_count: 1
    };
    return merged;
  });
};

type IProps = {
  loader: IProfileChartLoader;
  series: IProfileSeries;
  onOpen(id: string, bool: boolean): void;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onChangeSeriesData(id: string, seriesData: ISeriesPointData[], resultNodeList: INode[]): void;
  onDelete(id: string): void;
};

function ProfileChartSeriesItem({
  loader,
  series,
  onOpen,
  onChange,
  onChangeSeriesData,
  onDelete
}: IProps): ReactElement {
  const api = useApi();
  const {key, color, active, opened, name, graphType, parallel, nodeList} = series;
  const [open, setOpen] = useState(opened);

  const [isShowModal, setIsShowModal] = useState(false);
  const [featureList, setFeatureList] = useState<string[][]>([]);
  const [nodes, setNodes] = useState<INode[]>(nodeList);
  const [isLoading, setIsLoading] = useState(false);

  const onClickOpenModal = (): void => {
    const bool = !open;
    setOpen(bool);
    onOpen(key, bool);
  };

  const onSelectNodes = (nodes: string[]): void => {
    const parsed = nodes.map((item) => JSON.parse(item));
    setFeatureList(parsed);
    load(parsed);
    setOpen(true);
    onOpen(key, true);
  };

  const getNodeList = async (params: INodeRecordParams): Promise<INode[]> => {
    const result = await api.post<INodeDataReturn>('/node_manage/get_node_data_list', params);
    const {success, data} = result;
    if (success) {
      return data;
    }
    return null;
  };

  const load = (featureList: string[][]) => {
    setIsLoading(true);
    const params = {node_infos: getNodeParamList(featureList)};
    getNodeList(params).then((resultNodeList) => {
      const refined = resultNodeList.map((item, index) => ({
        x: index,
        y: item.records?.[0]?.[1],
        name: item.node.join(', ')
      }));
      onChangeSeriesData(key, refined, resultNodeList);
      setNodes(resultNodeList);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setNodes(nodeList);
  }, [nodeList]);

  return (
    <Container>
      <BasicInfo>
        {/*<FontAwesomeIcon icon={faGripLines} size="1x" color="#aaa" />*/}
        <ActiveCheckbox flattenKeys={key} active={active} onChange={onChange} />
        <ColorPicker flattenKeys={key} width={30} color={color} onChange={onChange} />
        <TextForm
          name={key}
          title="name"
          value={name}
          height={30}
          autoFocus
          onChange={onChange}
          onFocus={(e) => e.target.select()}
        />
        {/*<ParallelSelector
          flattenKeys="key"
          parallel={parallel}
          rows={new Array(loader.series.length)}
          height={30}
          disabled
          onChange={onChange}
        />*/}
        <SeriesGraphTypeSelector flattenKeys={key} graphType={graphType} onChange={onChange} />
        <NodesSelectButton height={30} isLoading={isLoading} onClick={() => setIsShowModal(true)} />
        <Button variant="none" width={30} onClick={() => onDelete(key)}>
          <FontAwesomeIcon icon={faTrashCan} size="sm" />
        </Button>
        <Button variant="none" width={20} onClick={onClickOpenModal}>
          <FontAwesomeIcon icon={open ? faAngleDown : faAngleRight} />
        </Button>
      </BasicInfo>
      <AdditionalInfo className={classNames({open}, 'thin-scrollbar md')}>
        {nodes.map((node, index) => (
          <SeriesNodeItem key={node.node_id} data={node} index={index} />
        ))}
        {nodes?.length === 0 && <EmptyNode>Empty Nodes</EmptyNode>}
      </AdditionalInfo>
      {isShowModal && (
        <NodeSelectorRevision
          title="Select Nodes"
          selectedNodes={featureList}
          onClose={() => setIsShowModal(false)}
          onSelect={onSelectNodes}
        />
      )}
    </Container>
  );
}

export default ProfileChartSeriesItem;
