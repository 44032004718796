/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/VesselWithBoot/VesselWithBoot.gltf
*/

import React from 'react';
import {useGLTF} from '@react-three/drei';

export function VesselWithBoot() {
  const {nodes, materials} = useGLTF('./3D/VesselWithBoot/VesselWithBoot.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._VesselWithBoot_1.geometry}
        material={materials.Simacro_VesselWithBoot}
        position={[-12, 10, 0]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
      />
    </group>
  );
}

useGLTF.preload('./3D/VesselWithBoot/VesselWithBoot.gltf');
