import {
  IDataBindingTagDataInfo,
  IDataBindingType,
  IShapeStyle,
  TagDataListReturn
} from 'components/pc/common/shapes/type';
import {api} from 'api/api';
import {ITagHierarchy} from 'api/data-types';

export const makeHtml = (arr: string[]) => {
  let htmlString = '';
  // 아무것도 써지지 않았을때 글씨 못쓰는 문제 해결
  // 모든 배열이 빈 스트링 배열일때를 확인하고 그럴때는 '' return
  let isBlank: boolean = true;
  if (!arr || arr?.length === 0) {
    return '';
  }
  for (const str of arr) {
    if (str) {
      isBlank = false;
      break;
    }
  }
  if (isBlank) {
    return '';
  }
  for (const str of arr) {
    htmlString += '<div>' + str + '</div>';
  }
  return htmlString;
};

export const rotateCalc = (id: string, evt) => {
  const cn = 'shape' + id;
  const element = document.querySelector('.' + cn) as HTMLElement;
  if (element === null) {
    return;
  }
  const {top: divTop, bottom: divBottom, left: divLeft, right: divRight} = element.getBoundingClientRect();
  const divCenterY = divTop + (divBottom - divTop) / 2;
  const divCenterX = divLeft + (divRight - divLeft) / 2;
  // drag event abs pos
  const eventAbsX = divLeft + evt.x;
  const eventAbsY = divTop + evt.y;
  const rad = Math.atan2(eventAbsX - divCenterX, eventAbsY - divCenterY);
  const deg = rad * (180 / Math.PI);
  return 180 - deg;
};

export const getStrokeDashArr = (shapeStyle: IShapeStyle) => {
  try {
    return shapeStyle?.strokeStyle.map((item) => item * shapeStyle?.strokeWidth).join(',');
  } catch (e) {
    return '';
  }
};

export const getUnit = async (category: string, rest: string[]) => {
  return api
    .post<ITagHierarchy[]>('/db_manage/get_hierarchy', {category: category?.toLowerCase()}, {errorReturn: []})
    .then(function (res) {
      let a = res;
      let unit: string;
      for (let i = 0; i < rest.length; i++) {
        if (i === rest.length - 1) {
          unit = a?.find((item) => item.name === rest[i]).unit;
        }
        a = a?.find((item) => item.name === rest[i])?.subnode;
      }
      return unit;
    });
};

export const getUnitTemp = (category: string, rest: string[], hierarchyJon: ITagHierarchy[]) => {
  let a = hierarchyJon;
  let unit: string;
  for (let i = 0; i < rest.length; i++) {
    if (i === rest.length - 1) {
      unit = a?.find((item) => item.name === rest[i]).unit;
    }
    a = a?.find((item) => item.name === rest[i])?.subnode;
  }
  return unit;
};

export const getTagInfo = async (dbt: IDataBindingType) => {
  // const [category, ...rest] = dbt?.tagData?.hierarchyInfo;
  // const unit = (await getUnit(category, rest)) as unknown;
  // const now = Math.floor(+new Date() / 1000);
  // if (isNaN(now)) return;
  // return api
  //   .post<TagDataListReturn[]>('/tags/get_tag_data_list', {
  //     tag_infos: [
  //       {
  //         tag: [...rest],
  //         category: category?.toLowerCase(),
  //         latest_count: 1
  //       }
  //     ]
  //   })
  //   .then(function (res) {
  //     const [single] = res;
  //     if (!single) return;
  //     if (!single?.data[single?.data?.length - 1]) return;
  //     let tagValue = Number(single?.data[single?.data?.length - 1][1]);
  //     if (isNaN(tagValue)) return;
  //     let bgColor = null;
  //     let fontColor = null;
  //     let borderColor = null;
  //     for (let i = 0; i < dbt.liveDisplay.list.length; i++) {
  //       const condition = dbt.liveDisplay.list[i];
  //       console.log(condition?.max);
  //       if (
  //         tagValue <
  //           Number(condition?.max === 0 ? Number.MAX_VALUE : condition?.max ? condition?.max : Number.MAX_VALUE) &&
  //         tagValue > (Number(condition?.min) || 0)
  //       ) {
  //         bgColor = condition?.bgColor;
  //         fontColor = condition?.fontColor;
  //         borderColor = condition?.borderColor;
  //         break;
  //       }
  //     }
  //
  //     const tdi: IDataBindingTagDataInfo = {
  //       tagHierarchy: dbt?.tagData?.hierarchyInfo,
  //       tagName: dbt?.tagData?.name,
  //       tagUnit: unit as string,
  //       tagValue: single?.data[single?.data?.length - 1][1],
  //       bgColor: bgColor,
  //       fontColor: fontColor,
  //       borderColor: borderColor,
  //       updateTime: now
  //     };
  //     // console.log(tdi);
  //     return tdi;
  //   });
  const [database, ...rest] = dbt?.tagData?.hierarchyInfo;
  const unit = (await getUnit(database, rest)) as unknown;
  const now = Math.floor(+new Date() / 1000);
  if (isNaN(now)) return;
  return api
    .post<TagDataListReturn[]>('/tags/get_tag_data_list', {
      node_infos: [
        {
          tag: [...rest],
          category: database?.toLowerCase(),
          latest_count: 1
        }
      ]
    })
    .then(function (res) {
      const [single] = res;
      if (!single) return;
      if (!single?.data[single?.data?.length - 1]) return;
      let tagValue = Number(single?.data[single?.data?.length - 1][1]);
      if (isNaN(tagValue)) return;
      let bgColor = null;
      let fontColor = null;
      let borderColor = null;
      for (let i = 0; i < dbt.liveDisplay.list.length; i++) {
        const condition = dbt.liveDisplay.list[i];
        console.log(condition?.max);
        if (
          tagValue <
            Number(condition?.max === 0 ? Number.MAX_VALUE : condition?.max ? condition?.max : Number.MAX_VALUE) &&
          tagValue > (Number(condition?.min) || 0)
        ) {
          bgColor = condition?.bgColor;
          fontColor = condition?.fontColor;
          borderColor = condition?.borderColor;
          break;
        }
      }

      const tdi: IDataBindingTagDataInfo = {
        tagHierarchy: dbt?.tagData?.hierarchyInfo,
        tagName: dbt?.tagData?.name,
        tagUnit: unit as string,
        tagValue: single?.data[single?.data?.length - 1][1],
        bgColor: bgColor,
        fontColor: fontColor,
        borderColor: borderColor,
        updateTime: now
      };
      // console.log(tdi);
      return tdi;
    });
};
