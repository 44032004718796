import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRaindrops} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import {getCelsius, getFahrenheit} from 'components/pc/widgets/weather/const';
import {ISizeType} from 'components/common/types';

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  background-color: #dde5ff;

  &.md {
    padding: 0.6rem 0.8rem;
    border-radius: 0.6rem;
    font-size: 16px;
  }
  &.sm {
    padding: 0.4rem 0.6rem;
    border-radius: 0.4rem;
    font-size: 13px;
  }
`;

const Day = styled.div`
  // margin-right: 10%;
  //width: 40%;
`;

const Rain = styled.div`
  width: 28%;
  flex-shrink: 0;
  font-size: 18px;
  white-space: nowrap;
  margin-left: auto;
  display: flex;
  align-items: flex-end;

  .unit {
    font-size: 0.8em;
  }

  svg {
    margin-right: 0.5rem;
    width: 30px;
    height: 30px;
  }

  &.md {
    font-size: 16px;

    svg {
      margin-right: 0.4rem;
      width: 20px;
      height: 20px;
    }
  }
  &.sm {
    font-size: 13px;

    svg {
      margin-right: 0.3rem;
      width: 16px;
      height: 16px;
    }
  }
`;

const TemperatureWrapper = styled.div`
  width: 24%;
  flex-shrink: 0;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //width: 30%;
  margin-left: 10px;

  &.md {
    font-size: 16px;
  }
  &.sm {
    font-size: 13px;
  }
`;

const TemperatureMin = styled.div`
  //font-size: 21px;
`;
const TemperatureDivision = styled.div`
  opacity: 60%;
  margin: 0 5px;
`;
const TemperatureMax = styled.div`
  opacity: 60%;
`;

type IProps = {
  idx: number;
  historyData: IWeatherHistoryData;
  fontSize: string;
  isCelsius: boolean;
  darkModeChange: boolean;
  size: ISizeType;
};

function WeatherHistoryDailyResult({idx, darkModeChange, historyData, fontSize, isCelsius, size}: IProps) {
  const fMin = getFahrenheit(historyData[idx]?.data?.temperature.min);
  const fMax = getFahrenheit(historyData[idx]?.data?.temperature.max);
  const cMin = getCelsius(historyData[idx]?.data?.temperature.min);
  const cMax = getCelsius(historyData[idx]?.data?.temperature.max);

  return (
    <Container className={size.width} style={darkModeChange === false ? {backgroundColor: '#3f425f'} : {}}>
      {/* <div className="weatherHistoryIcon">{WeatherIcon(dummy[0].data[0].weather[0].icon)}</div> */}
      {/* <div className="weather-history-day">{dayList[(new Date((forecastResult?.data?.daily?.[0]?.dt)*1000)).getDay()]}</div> */}
      <Day>{historyData[idx]?.data.date}</Day>
      <Rain className={size.width}>
        <FontAwesomeIcon icon={faRaindrops} />
        {historyData[idx]?.data.precipitation.total
          ? Math.round(historyData[idx]?.data.precipitation.total)
          : undefined}
        <span className="unit">{historyData[idx]?.data.precipitation.total && `mm`}</span>
      </Rain>
      <TemperatureWrapper className={size.width}>
        <TemperatureMin className={fontSize}>
          {isCelsius ? (
            <>{historyData[idx]?.data?.temperature.min ? cMin : undefined}</>
          ) : (
            <>{historyData[idx]?.data?.temperature.min ? fMin : undefined}</>
          )}
        </TemperatureMin>
        <TemperatureDivision className={fontSize}>{historyData[idx]?.data?.temperature.min && `/`}</TemperatureDivision>
        <TemperatureMax className={fontSize}>
          {isCelsius ? (
            <>
              {historyData[idx]?.data?.temperature.max ? cMax : undefined}
              {historyData[idx]?.data?.temperature.max && `°`}
            </>
          ) : (
            <>
              {historyData[idx]?.data?.temperature.max ? fMax : undefined}
              {historyData[idx]?.data?.temperature.max && `°`}
            </>
          )}
        </TemperatureMax>
      </TemperatureWrapper>
    </Container>
  );
}

export default WeatherHistoryDailyResult;
