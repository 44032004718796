import {PropsWithChildren, ReactElement} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlobeAmericas} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {IconDefinition, SizeProp} from '@fortawesome/fontawesome-svg-core';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ContentsBox = styled.div`
  //background-color: #fff;
  margin: auto;
  padding: 20px;
  color: #aaa;
  border-radius: 5px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.3em;
  }
`;
const TextDisplay = styled.div`
  h1 {
    margin: 0;
    font-size: 1.4em;
    font-weight: normal;
  }
  p {
    margin: 0;
    color: #ccc;
  }
`;

type IProps = PropsWithChildren & {
  title?: string;
  icon?: IconDefinition;
  iconSize?: SizeProp;
  description?: string;
};

function NoDataDisplay({children, title, icon, iconSize = '3x', description}: IProps): ReactElement {
  return (
    <Container>
      <ContentsBox>
        {children || (
          <>
            <FontAwesomeIcon icon={icon || faGlobeAmericas} size={iconSize} />
            <TextDisplay>
              {title && <h1>{title}</h1>}
              {description && <p>{description}</p>}
            </TextDisplay>
          </>
        )}
      </ContentsBox>
    </Container>
  );
}

export default NoDataDisplay;
