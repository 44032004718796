import {generatePath} from 'components/pc/common/shapes/parts/svg/utils';
import {IShapeProps} from 'components/pc/common/shapes/type';

function Arrow({width, height, ...svgAttributes}: IShapeProps) {
  const arrowPath = generatePath([
    [0, height / 3],
    [width * (2 / 3), height / 3],
    [width * (2 / 3), 0],
    [width, height / 2],
    [width * (2 / 3), height],
    [width * (2 / 3), (height * 2) / 3],
    [0, (height * 2) / 3]
  ]);

  return <path d={arrowPath} {...svgAttributes} />;
}

export default Arrow;
