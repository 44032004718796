import {ChangeEvent, CSSProperties, FunctionComponent, PropsWithChildren, ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

const Container = styled.div`
  overflow: auto;
  height: 100%;

  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    background-color: ${({theme}) => theme.table.background.color};

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      background-color: ${({theme}) => theme.table.background.color};
    }
    tr {
      th,
      td {
        padding: 2px 5px;
      }
    }

    > thead {
      //background-color: #363779;

      tr {
        th {
          height: 30px;
          box-sizing: border-box;
          //max-height: 30px;
          border: 1px solid ${({theme}) => theme.table.border.color};
          //background-color: #5556a9;
          background-color: #eee;
          //color: #b0b1ea;
          white-space: nowrap;
          position: sticky;
          z-index: 1;
          top: 0;
          font-size: 0.92em;
          font-weight: 400;
        }
      }
    }

    > tbody {
      //background-color: #363779;

      tr {
        width: 100%;

        &.draggable {
          //todo important 나중에 수정 필요 (CommoditySelectItemList 와 동일) https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem
          left: auto !important;
          top: auto !important;
        }

        .draggable {
          color: red !important;
        }
        //height: 30px !important;
        td {
          background-color: #fff;
          border: 1px solid ${({theme}) => theme.table.border.color};

          &.no-data {
            text-align: center;
            padding: 35px;
            color: #aaa;
            //background-color: #e5e5f6;
            background-color: #ffffff;
            border: none;
          }

          &.nowrap {
            white-space: nowrap;
          }

          &.no-padding {
            padding: 0;
          }

          &.checkbox {
            //padding: 0;
            // todo: 테이블 내부 스타일 수정 필요
          }

          &.ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /*
                        // 
                        &.multiline {
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                          line-height: 1.5em;
                          max-height: 3em;
                        }*/
          }
        }
      }
    }
  }
`;

const portal: HTMLElement = document.createElement('div');
portal.classList.add('my-super-cool-portal');
if (!document.body) {
  throw new Error('body not ready for portal creation!');
}
document.body.appendChild(portal);

const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export type ITableField = {
  key?: string;
  label?: string;
  style?: {
    header?: CSSProperties;
    body?: CSSProperties;
  };
  css?: {
    header?: string;
    body?: string;
  };
  toFixed?: number;
  component?: FunctionComponent;
  icon?: {
    component?: ReactNode;
    actionType?: string;
  };
  keyOfMinMax?: {
    min?: string; // 'bottom'
    max?: string; // 'top
  };

  // [key: string]: string | number | object | null | undefined;
};

type IProps<T> = PropsWithChildren & {
  loadingComponent?: ReactElement;
  fields: ITableField[];
  fieldKey: keyof ITableField;
  fieldLabelKey: keyof ITableField;
  rows: T[];
  rowKey: string;
  noDataText?: string;
  onChangeRow?(e: ChangeEvent): void;
  onReorder?(list: T[]): void;
};

function DefaultTable<T>({
  children,
  loadingComponent,
  fields,
  fieldKey,
  fieldLabelKey,
  rows,
  rowKey,
  noDataText,
  onReorder
}: IProps<T>): ReactElement {
  const getTd = (field: ITableField, row: T, rowIndex: number): ReactElement => {
    let value = row[field[fieldKey] as string];

    if (field.component) {
      const CustomComponent = field.component as FunctionComponent;
      const refinedRow = {...row, rows, field, rowIndex};
      return <CustomComponent {...(refinedRow as T)} />;
    }

    return <>{value}</>;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = reorder(rows, result.source.index, result.destination.index);
    onReorder?.(items);
  };
  return (
    <Container className="thin-scrollbar md">
      <table>
        <thead>
          <tr>
            {fields?.map((field) => (
              <th key={field[fieldKey] as string} style={field?.style?.header}>
                {field[fieldLabelKey] as string}
              </th>
            ))}
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {rows?.map((row, index) => (
                  <Draggable
                    isDragDisabled={!onReorder}
                    key={row[rowKey] as string}
                    index={index}
                    draggableId={row[rowKey]}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        className="draggable"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {fields?.map((field) => (
                          <td key={field[fieldKey] as string} style={field?.style?.body} className={field?.css?.body}>
                            {getTd(field, row, index)}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {noDataText && rows?.length === 0 && (
                  <tr>
                    <td colSpan={fields?.length} className="no-data">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      {loadingComponent}
      {children}
    </Container>
  );
}

export default DefaultTable;
