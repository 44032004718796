import {useContext, useEffect} from 'react';
import {CommonContext} from 'components/common/CommonProvider';

function useServerEvent(): void {
  const {showModal} = useContext(CommonContext);

  useEffect(() => {
    const onServerEvent = (e: CustomEvent) => {
      const {detail} = e;
      showModal({title: 'Server Error', content: detail});
    };
    document.addEventListener('ServerEvent', onServerEvent);
    return () => {
      document.removeEventListener('ServerEvent', onServerEvent);
    };
  }, [showModal]);
}

export default useServerEvent;
