/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/HeatExchanger/HeatExchanger.gltf 
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function HeatExchanger() {
  const {nodes, materials} = useGltfCustom('/gltf/HeatExchanger/HeatExchanger.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[28, 11.017, -23]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[31.8, 11.017, 43]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[28.1, 11.017, 26]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[21.8, 11.017, 26.2]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[17.35, 11.15, 26.3]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[28.1, 11.017, 13.5]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[21.5, 11.15, 8]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[54.3, 10.8, -2.5]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[54.3, 10.8, -7.1]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[58.2, 11.017, -16.8]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-9, 11.017, -49.75]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-2, 11.017, -76.5]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-25, 11.017, -78]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-20, 11.017, -78]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-35, 11.017, -69]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-59, 11.017, -73.7]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-59, 11.017, -56.5]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-69.6, 11.017, -48.9]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-92.5, 11.017, -57.7]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-107.1, 11.017, -63.1]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-114.2, 11.017, -64]}
        rotation={[0, 0, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-98, 11.017, -27]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-110.5, 11.017, -31.75]}
        rotation={[0, -1.571, 0]}
      />
      <mesh
        geometry={nodes._HeatExchanger_1.geometry}
        material={materials.Simacro_HeatExchanger}
        position={[-76.4, 11.017, -69]}
        rotation={[0, 0, 0]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/HeatExchanger/HeatExchanger.gltf');
