import {Node as TreeNode} from 'react-checkbox-tree';

export const keywordFilter = (nodeArr: TreeNode[], keyword: string, checkedArray: string[]) => {
  let newNodes = [];
  let nodes = [...nodeArr];
  for (let node of nodes) {
    let n = {...node};
    if (n.children?.length > 0) {
      const nextNodes = keywordFilter(n.children, keyword, checkedArray);
      if (nextNodes.length > 0) {
        n.children = nextNodes;
      }
      if (n.value?.toLowerCase().includes(keyword.toLowerCase())) {
        n.children = nextNodes.length > 0 ? nextNodes : [];
      }
      if (nextNodes.length > 0 || n.value?.toLowerCase().includes(keyword.toLowerCase())) {
        newNodes.push(n);
      }
    } else {
      if (n.value?.toLowerCase().includes(keyword.toLowerCase())) {
        newNodes.push(n);
      } else if (checkedArray.includes(n.value.toString())) {
        newNodes.push(n);
      }
    }
  }
  // console.log(newNodes);
  return newNodes;
};

export const getAllValuesFromNodes = (nodes: TreeNode[], firstLevel: boolean) => {
  const values = [];
  for (let n of nodes) {
    values.push(n.value);
    if (n.children) {
      values.push(...getAllValuesFromNodes(n.children, false));
    }
  }
  return values;
};
