import {useState, useEffect, useCallback, RefObject} from 'react';

function useScrollLoader(ref: RefObject<HTMLDivElement>) {
  const [isShowScroll, setIsShowScroll] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        if (ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight + 5) {
          setIsShowScroll(false);
        } else {
          setIsShowScroll(true);
        }
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      if (ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight + 5) {
        setIsShowScroll(false);
      } else {
        setIsShowScroll(true);
      }
    }
  }, [ref]);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      setIsShowScroll(false);
      return;
    }

    setIsShowScroll(true);

    element.addEventListener('scroll', handleScroll);
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return [isShowScroll];
}

export default useScrollLoader;
