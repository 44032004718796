import React, {useContext} from 'react';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import AnnotationList from 'components/mpfd/annotation/AnnotationList';
import classnames from 'classnames';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

type IProps = {
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  id?: string;
};

const StyledSvg = styled.svg`
  position: absolute;
  z-index: ${META_PFD_zINDEX.REGION_SHAPE_LAYER};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  g {
    pointer-events: all;
  }
  polyline,
  rect,
  path,
  circle,
  text,
  path {
    z-index: ${META_PFD_zINDEX.REGION_SHAPE};
  }

  .annot-label {
    pointer-events: none;
  }
`;

function AnnotationLayer({imagePosition, id = 'none-widget'}: IProps) {
  const {annotationAction} = useContext(MetaPfdContext);
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;

  if (isNaN(iw) || isNaN(ih)) return null;

  return (
    <StyledSvg
      className={classnames(`regionWrapper${id}`, 'annotation-layer')}
      onMouseDown={annotationAction.unSelectAnnotation}
    >
      <AnnotationList iw={iw} ih={ih} imagePosition={imagePosition} />
    </StyledSvg>
  );
}

export default AnnotationLayer;
