import {NodeChange} from 'reactflow';
import ChangeInfo from 'components/pc/common/devTool/ChangeInfo';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {useContext} from 'react';

const Container = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50px;
  left: 20px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

type IProps = {
  changes: NodeChange[];
};

function ChangeLogger({changes}: IProps) {
  const {file} = useContext(ProcessCanvasContext);
  return (
    <Container>
      <Title>IsChanged</Title>
      {file?.localInfo?.isChanged ? 'True' : 'False'}
      <Title>Change Logger</Title>
      {changes.length === 0 ? (
        <>no changes triggered</>
      ) : (
        changes.map((change, index) => <ChangeInfo key={index} change={change} />)
      )}
    </Container>
  );
}

export default ChangeLogger;
