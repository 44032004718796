import {ChangeEvent, ReactNode, useContext} from 'react';
import {Button, SelectForm} from 'components/forms';
import {DataType, SensorType} from 'components/mpfd/panel/const';
import PropertyRow from 'components/mpfd/panel/parts/property/PropertyRow';
import {IAnnotation} from 'components/mpfd/type';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

type IProps = {
  selectedAnnot: IAnnotation;
  children: ReactNode;
  onClickAddTagBtn(): void;
};

function TagProperty({selectedAnnot, children, onClickAddTagBtn}: IProps) {
  const {dispatchToReducer} = useContext(MetaPfdContext);

  const onChangeSensorType = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    dispatchToReducer({type: 'CHANGE_REGION', region: {...selectedAnnot, cls: value}});
  };

  const onChangeDataType = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {...selectedAnnot, dataType: value, label: value}
    });
  };

  return (
    <>
      <PropertyRow rowHeader="Name">{children}</PropertyRow>
      <PropertyRow rowHeader="Sensor type">
        <SelectForm name="Sensor type" options={SensorType} onChange={onChangeSensorType} value={selectedAnnot?.cls} />
      </PropertyRow>
      <PropertyRow rowHeader="Data type">
        <SelectForm
          name="Data type"
          options={DataType}
          onChange={onChangeDataType}
          value={selectedAnnot.dataType}
          placeholder="Select Data Type"
          disabledPlaceholder={true}
        />
      </PropertyRow>
      <PropertyRow>
        <Button variant="info" height={30} width="100%" onClick={onClickAddTagBtn}>
          Select Node
        </Button>
      </PropertyRow>
    </>
  );
}
export default TagProperty;
