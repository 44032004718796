import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  width: 100%;
`;

const Title = styled.div`
  font-size: 36px;
  display: flex;
  align-items: center;
  height: 60px;
`;

const Description = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 40px;
`;

type IProps = {
  title: string;
  description: string;
};

function ManagementHeader({title, description}: IProps) {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}

export default ManagementHeader;
