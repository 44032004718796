import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function ModelPipeArrow() {
  const {nodes, materials} = useGltfCustom('/gltf/PipeArrow/Pipes_Arrow.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[-2.18, 10.13, 42.353]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[28.1, 10.13, 50.6]}
        rotation={[-Math.PI / 2, 0, 3.12]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[76.5, 10.13, -2.4]}
        rotation={[-Math.PI / 2, 0, -1.58]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[61.8, 10.13, -12.7]}
        rotation={[-Math.PI / 2, 0, -1.58]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[39, 10.13, -68.2]}
        rotation={[-Math.PI / 2, 0, 1.58]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[-39.4, 10.13, -78.2]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[-63.3, 10.13, -80.2]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <mesh
        geometry={nodes.Line032_1.geometry}
        material={materials.Pipe_Arrow}
        position={[-100.7, 10.13, -18.5]}
        rotation={[-Math.PI / 2, 0, 3.12]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/PipeArrow/Pipes_Arrow.gltf');
