import {MouseEvent, ReactElement, useContext, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {IContextMenuItem} from 'components/common/context-menu/ContextMenu';
import {CommonContext} from 'components/common/CommonProvider';

// 메뉴 하나당 높이
const MENU_HEIGHT = 40;

const Container = styled.div`
  color: #ccc;
  position: relative;
  width: 180px;
  height: ${MENU_HEIGHT}px;
  max-width: 180px;

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: #272546;
    color: #fff;
  }
  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
const MenuTitle = styled.div`
  white-space: nowrap;
  height: ${MENU_HEIGHT}px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  box-sizing: border-box;
`;
const MenuLayer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c9c9c9;
  background-color: rgba(39, 37, 70, 0.8);
  padding: 5px 0;
  position: absolute;
  top: 0;
`;

type IProps = {
  parentY: number;
  data: IContextMenuItem;
  isOpposite?: boolean;
};

function ContextMenuItem({data, parentY, isOpposite}: IProps): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const {showContextMenu} = useContext(CommonContext);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [isShowSubMenu, setIsShowSubMenu] = useState<boolean>(false);
  const appContainer = document.getElementById('pm-app-container') as HTMLDivElement;
  const rect = appContainer.getBoundingClientRect();

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>): void => {
    const {clientWidth, offsetTop} = containerRef.current;
    if (clientWidth) setLeft(isOpposite ? -clientWidth : clientWidth);
    // 부모로의 좌표 + 현재 메뉴의 위치 + 하위 메뉴의 크기 + 하위 메뉴의 padding 상 5px + 하 5px 에 하단 띄움 5px 3종 15 의 합과 캔버스의 크기를 비교하여 위치를 획득
    const shiftY = -(parentY + offsetTop + (data?.children?.length ?? 0) * MENU_HEIGHT + 15 - rect.height);

    setTop(Math.min(0, shiftY));
    setIsShowSubMenu(true);
  };

  const onMouseLeave = (): void => {
    setIsShowSubMenu(false);
  };

  const onClick = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    if (data.disabled) return;

    data?.callback();

    if (data.callback) {
      showContextMenu(null);
    }
  };

  return (
    <Container
      ref={containerRef}
      className={data.disabled && 'disabled'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onClick}
    >
      <MenuTitle>
        {data.label}
        {data.children && <FontAwesomeIcon icon={faAngleRight} />}
      </MenuTitle>
      {isShowSubMenu && (
        <MenuLayer style={{left, top}}>
          {data?.children?.map((menu) => <ContextMenuItem key={menu.value} parentY={top} data={menu} />)}
        </MenuLayer>
      )}
    </Container>
  );
}

export default ContextMenuItem;
