import {AnnotationTypes, IAnnotation} from 'components/mpfd/type';
import {compose, rotate, scale, translate} from 'transformation-matrix';

export const getMatchNumber = (str: string, type: AnnotationTypes) => {
  const regex = /^(.*?)-(\d+)$/;
  if (!str) {
    switch (type) {
      case 'box':
        return {name: 'region', id: 0};
      case 'point':
        return {name: 'point', id: 0};
      case 'expanding-line':
        return {name: 'line', id: 0};
      case 'port':
        return {name: 'port', id: 0};
      default:
        return {name: 'unknown', id: 0};
    }
  }
  const match = str.match(regex);

  if (match) {
    return {name: match[1], id: parseInt(match[2])};
  } else {
    return {name: str, id: 0};
  }
};

export function mergeCommonArrays(arr) {
  let result = [];
  let origin = [...arr];
  if (arr?.length === 0) {
    return result;
  }

  while (arr.length > 0) {
    let first = arr.pop();
    let rest = arr.filter((set) => !set.some((val) => first.some((i) => i.x === val.x && i.y === val.y)));
    let merged = arr
      .filter((set) => set.some((val) => first.some((i) => i.x === val.x && i.y === val.y)))
      .reduce((acc, set) => acc.concat(set), first);
    result.push(merged);
    arr = rest;
  }

  // minimize 안될때까지 반복
  if (origin?.length !== result?.length) {
    return mergeCommonArrays([...result]);
  } else {
    return result;
  }
}

type polyLineInfo = {
  polyLineString: string;
  isArrow: boolean;
  startArrow: boolean;
  endArrow: boolean;
};

export function convertPoint2String(r: IAnnotation, iw, ih) {
  const {points, candidatePoint} = r;

  const arrowPoints = [...(r?.arrowPoints || [])];
  const pointsArray = points.concat(candidatePoint ? [candidatePoint] : []);
  const pointStringList = pointsArray.map((p) => `${p.x * iw} ${p.y * ih}`);
  const polyLineList: polyLineInfo[] = [];
  const uniquePointList = [];

  const startPointsArray = pointsArray.filter((item, idx) => idx % 2 === 0);
  const endPointsArray = pointsArray.filter((item, idx) => idx % 2 === 1);

  for (let i = 0; i < pointsArray?.length; i = i + 2) {
    const startP = pointsArray[i];
    const endP = pointsArray[i + 1];
    let isArrow = false;
    let polyLineString = pointStringList[i] + ' ' + pointStringList[i + 1];
    let startArrow = false;
    let endArrow = false;
    // 연결되어있지 않으면서 시작점인경우
    if (
      !endPointsArray.some((p) => p?.x === startP?.x && p?.y === startP?.y) &&
      arrowPoints.some((item) => item.x === startP.x && item.y === startP.y)
    ) {
      // polyLineString = pointStringList[i + 1] + ' ' + pointStringList[i];
      isArrow = true;
      startArrow = true;
    }
    // 연결되어있지않으면서 끝점인경우
    if (
      !startPointsArray.some((p) => p?.x === endP?.x && p?.y === endP?.y) &&
      arrowPoints.some((item) => item.x === endP.x && item.y === endP.y)
    ) {
      isArrow = true;
      endArrow = true;
    }

    polyLineList.push({isArrow, polyLineString, startArrow, endArrow});
  }
  for (let i = 0; i < points.length; i++) {
    if (!uniquePointList.some((item) => item.x === points[i].x && item.y === points[i].y)) {
      uniquePointList.push(points[i]);
    }
  }
  return [pointStringList, polyLineList, uniquePointList];
}

export function getModeInfo(mode, region: IAnnotation) {
  let pointMoveMode = false;
  let drawExpandingLine = false;
  let annotationCircle = undefined;
  let lineGroupIdx = -1;
  let GroupOfLine = [];
  let GroupOfLineTemp = [];
  let moveTargetIsEndPoint = false;
  let {points} = region;
  let mergeCandidatePointBorderColor = '#ee5c84';
  let annotationCircleBorderColor = '#ee5c84';

  switch (mode?.mode) {
    case 'MOVE_POINT_OF_LINE': {
      const {regionId, targetPointsIdx, annotationPointCenter, moveTargetPointIsEndPoint} = mode;
      // const targetRegion = annotation
      pointMoveMode = true;
      moveTargetIsEndPoint = moveTargetPointIsEndPoint;
      let gli = 0;
      while (gli < points.length - 1) {
        if (points.length === 0) break;
        const p1 = points[gli];
        const p2 = points[gli + 1];
        if (gli === 0) {
          GroupOfLineTemp.push([p1, p2]);
          gli = gli + 2;
        } else {
          const lineGroupIdx = GroupOfLineTemp.findIndex((item) =>
            item.some((p) => (p.x === p1.x && p.y === p1.y) || (p.x === p2.x && p.y === p2.y))
          );

          if (lineGroupIdx === -1) {
            GroupOfLineTemp.push([p1, p2]);
          } else {
            GroupOfLineTemp[lineGroupIdx] = GroupOfLineTemp[lineGroupIdx].concat([p1, p2]);
          }
          gli = gli + 2;
        }
      }
      GroupOfLine = mergeCommonArrays(GroupOfLineTemp);

      if (region?.id === regionId) {
        if (targetPointsIdx.length > 0) {
          lineGroupIdx = GroupOfLine.findIndex((item) =>
            item.some((p) => p?.x === points[targetPointsIdx[0]].x && p?.y === points[targetPointsIdx[0]].y)
          );
        }
        // 하나만 렌더링
        if (annotationPointCenter) {
          annotationCircle = annotationPointCenter;
        }
      } else {
        mergeCandidatePointBorderColor = '#29b6f2';
      }

      if (mode?.mergeTargetPointAnnotId && mode?.mergeTargetPointAnnotId !== region?.id) {
        annotationCircleBorderColor = '#29b6f2';
      }

      break;
    }
    case 'DRAW_EXPANDING_LINE': {
      drawExpandingLine = true;
      break;
    }
    default:
  }
  return {
    pointMoveMode,
    drawExpandingLine,
    annotationCircle,
    lineGroupIdx,
    GroupOfLine,
    moveTargetIsEndPoint,
    mergeCandidatePointBorderColor,
    annotationCircleBorderColor
  };
}

export function getMouseHoverType(selectedTool: string) {
  let point = false;
  let box = false;
  let polyLine = false;
  let pointOfLine = false;
  switch (selectedTool) {
    case 'select': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'pan': {
      point = false;
      box = false;
      polyLine = false;
      pointOfLine = false;
      break;
    }
    case 'create-point': {
      point = false;
      box = false;
      polyLine = false;
      pointOfLine = false;
      break;
    }
    case 'create-box': {
      point = false;
      box = false;
      polyLine = false;
      pointOfLine = false;
      break;
    }
    case 'create-expanding-line': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'line-detection': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'line-eraser': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = false;
      break;
    }
    case 'line-splitter': {
      point = false;
      box = false;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'line-splitter-2': {
      point = false;
      box = false;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    default:
  }
  return {pointHoverType: point, boxHoverType: box, polyLineHoverType: polyLine, pointOfLineHoverType: pointOfLine};
}

export function removeFileExtension(filename: string) {
  if (!filename) {
    return '';
  }
  return filename.replace(/\.[^/.]+$/, '');
}

export const getRegionColor = (region: IAnnotation) => {
  let color: string;
  const {cls, type} = region;

  if (type === 'port') {
    return '#19CC81';
  }

  if (cls === undefined) {
    color = '#808080';
  } else if (cls === 'Soft Sensor') {
    color = '#f44336';
  } else if (cls === 'Physical Sensor') {
    color = '#2196f3';
  }
  return color;
};

export const hexToRGBA = (hex: string, opacity: number) => {
  const r = parseInt(hex?.slice(1, 3), 16);
  const g = parseInt(hex?.slice(3, 5), 16);
  const b = parseInt(hex?.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// export const getDefaultMat = () => Matrix.from(1, 0, 0, 1, -10, -10);
export const getDefaultMat = () => compose(translate(0, 0), rotate(0), scale(1, 1));

export const defaultMat = compose(translate(0, 0), rotate(0), scale(1, 1));

export const findMinValueAndIndex = (arr) => {
  let minValue = Infinity;
  let idx = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > 0 && arr[i] < minValue) {
      minValue = arr[i];
      idx = i;
    }
  }

  return minValue === Infinity ? null : {minValue, idx};
};

export const pointDistance = (x1: number, y1: number, x2: number, y2: number) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};
