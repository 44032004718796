import React, {ChangeEvent, MouseEvent, CSSProperties, FunctionComponent, ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import Tooltip from 'components/common/Tooltip';

type StyledProps = {
  $headerCssProperties?: {
    color?: string;
    backgroundColor?: string;
    display?: string;
  };
};

const Container = styled.div<StyledProps>`
  overflow: auto;
  height: 100%;
  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    background-color: #363779;
    tr {
      th,
      td {
        padding: 2px 5px;
      }
      ,
      th {
        z-index: 1;
      }
    }
    > thead {
      background-color: #363779;
      //display: ${(props) => (props?.$headerCssProperties?.display ? props?.$headerCssProperties?.display : '')};
      tr {
        th {
          height: 30px;
          max-height: 30px;
          border: 1px solid #363779;
          background-color: ${(props) =>
            props?.$headerCssProperties?.backgroundColor ? props?.$headerCssProperties?.backgroundColor : '#5556a9'};
          color: ${(props) => (props?.$headerCssProperties?.color ? props?.$headerCssProperties?.color : '#b0b1ea')};
          white-space: nowrap;
          position: sticky;
          top: -1px;
        }
      }
    }
    > tbody {
      background-color: #363779;
      tr {
        td {
          background-color: #fff;
          border: 1px solid #363779;
          &.nowrap {
            white-space: nowrap;
          }
          &.no-padding {
            padding: 0;
          }
          &.ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

export type IComponentTableField = {
  key?: string;
  label?: string;
  style?: {
    header?: CSSProperties;
    body?: CSSProperties;
  };
  css?: {
    header?: string;
    body?: string;
  };
  component?: FunctionComponent;
  icon?: {
    component?: ReactNode;
    actionType?: string;
  };
  keyOfMinMax?: {
    min?: string; // 'bottom'
    max?: string; // 'top
  };
  headerTooltip?: string;
  customHeaderComponent?: boolean;
};

type IProps<T> = {
  children?: ReactElement;
  headerCssProperties?: {
    color?: string;
    backgroundColor?: string;
    display?: string;
  };
  loadingComponent?: ReactElement;
  fields: IComponentTableField[];
  fieldKey?: keyof IComponentTableField;
  fieldLabelKey?: keyof IComponentTableField;
  rows: T[];
  rowKey?: string;
  needColumnKey?: boolean;
  onClickOnlyInComponent?: boolean;
  onChange?(e: ChangeEvent): void;
  onClick?(rowKey: string, columnKey?: string): void;
  onMouseUp?(e: MouseEvent, rowKey?: string): void;
};

function ComponentTable<T>({
  children,
  headerCssProperties,
  loadingComponent,
  fields,
  rows,
  fieldKey = 'key',
  fieldLabelKey = 'label',
  rowKey = 'flattenKeys',
  needColumnKey = false,
  onClickOnlyInComponent = false,
  onChange,
  onClick,
  onMouseUp
}: IProps<T>): ReactElement {
  const getTd = (field: IComponentTableField, row: T): ReactElement => {
    let value = row[field[fieldKey] as string];

    if ((field.key === 'roles' || field.key === 'groups') && Array.isArray(value)) {
      value = (value as string[]).join('\n');
    }

    if (field.key === 'is_active') {
      value = value ? 'active' : 'not active';
    }

    if (field.component) {
      const CustomComponent = field.component as FunctionComponent;
      const refinedRow = {
        ...row,
        field,
        rowKey,
        value,
        onChange,
        onClick
      };
      return <CustomComponent {...(refinedRow as T)} />;
    }

    return <>{value}</>;
  };

  const getTh = (field: IComponentTableField): ReactElement => {
    let value = field[fieldLabelKey] as string;
    if (field?.component && field.customHeaderComponent) {
      const CustomComponent = field.component as FunctionComponent;
      const refinedRow = {
        field,
        rows,
        th: true,
        onChange,
        value,
        onClick
      };
      return <CustomComponent {...(refinedRow as T)} />;
    }
    return <>{value}</>;
  };

  const onMouseUpRow = (e: MouseEvent, rowKey: string) => {
    onMouseUp?.(e, rowKey);
  };

  const onClickRow = (row: T, field: IComponentTableField) => {
    if (onClickOnlyInComponent) {
      return;
    }
    if (needColumnKey) onClick?.(row[rowKey] as string, field[fieldKey] as string);
    else onClick?.(row[rowKey] as string);
  };

  return (
    <Container className="thin-scrollbar md" $headerCssProperties={headerCssProperties}>
      <table>
        <thead style={headerCssProperties}>
          <tr>
            {fields?.map((field) => {
              return (
                <th key={field[fieldKey] as string} style={field?.style?.header}>
                  {field?.headerTooltip ? (
                    <Tooltip content={field?.headerTooltip}>{getTh(field)}</Tooltip>
                  ) : (
                    getTh(field)
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => (
            <tr key={row[rowKey] as string}>
              {fields?.map((field) => (
                <td
                  key={field[fieldKey] as string}
                  style={field?.style?.body}
                  className={field?.css?.body}
                  onClick={() => onClickRow(row, field)}
                  onMouseUp={(e) => onMouseUpRow(e, row[rowKey] as string)}
                >
                  {getTd(field, row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {loadingComponent}
      {children}
    </Container>
  );
}

export default ComponentTable;
