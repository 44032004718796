import React, {useContext} from 'react';
import useMetaPfdCommand from 'components/menu/pulldown/useMetaPfdCommand';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import ZoomScaleViewer from 'components/common/zoom-scale-viewer/ZoomScaleViewer';

function MetaPfdZoomScaleViewer() {
  const {zoomTo, zoomIn, zoomOut} = useMetaPfdCommand();
  const {matrix, isShowPanelLayout} = useContext(MetaPfdContext);
  const checkMiniMap = isShowPanelLayout === true ? 'on' : 'off';
  const zoomPercentage = Math.round(((1 / matrix.a / 9) * 900) / 5) * 5;

  return (
    <ZoomScaleViewer
      zoomPercentage={zoomPercentage}
      checkMiniMap={checkMiniMap}
      type="mpfd"
      zoomIn={zoomIn}
      zoomOut={zoomOut}
      zoomTo={zoomTo}
    />
  );
}

export default MetaPfdZoomScaleViewer;
