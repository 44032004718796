import React, {ReactElement} from 'react';
import {IAppMenu} from 'components/menu/types';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type IProps = {
  data: IAppMenu;
  isActive: boolean;
  onSelect(data: IAppMenu): void;
};

function AppMenuItem({data, isActive, onSelect}: IProps): ReactElement {
  const navigate = useNavigate();

  const onClick = () => {
    if (data?.url) {
      navigate(data.url);
    }
    onSelect(data);
  };

  return (
    <li key={data.id} className={classNames('app-menu-item', {active: isActive})} onClick={onClick}>
      <FontAwesomeIcon icon={data.icon} size="2x" />
      <div className="menu-label">{data.name}</div>
    </li>
  );
}

export default AppMenuItem;
