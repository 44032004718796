import styled from 'styled-components';
import {CheckboxForm} from 'components/forms';
import {ChangeEvent} from 'react';
import Badge from 'components/forms/Badge';

const Container = styled.div`
  display: flex;
  padding: 0 1rem;
  position: relative;
  height: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const CheckboxFormWrap = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
  }
`;

type IProps = {
  isCheckedPublic: boolean;
  isCheckedOwner: boolean;
  onChangePublic: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeOwner: (e: ChangeEvent<HTMLInputElement>) => void;
};

function FileViewOption({isCheckedPublic, isCheckedOwner, onChangePublic, onChangeOwner}: IProps) {
  return (
    <Container>
      <CheckboxFormWrap>
        <CheckboxForm name="show-public-btn" checked={isCheckedPublic} onChange={onChangePublic} />
        <Badge type="public">Public</Badge>
      </CheckboxFormWrap>
      <CheckboxFormWrap style={{marginLeft: '16px'}}>
        <CheckboxForm name="show-owner-btn" checked={isCheckedOwner} onChange={onChangeOwner} />
        <Badge type="owner">Owner</Badge>
      </CheckboxFormWrap>
    </Container>
  );
}

export default FileViewOption;
