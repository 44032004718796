import {ReactElement} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;

type IProps = {
  onClose(): void;
  onConfirm(): void;
};
function DisconnectModal({onClose, onConfirm}: IProps): ReactElement {
  return (
    <ModalWrapper type="inner">
      <ModalHeader onClose={onClose}>Disconnect Server</ModalHeader>
      <ModalBody>
        <Content> Do you want to disconnect from the Model Manager Server?</Content>

        <ModalFooter>
          <Button onClick={onConfirm}>Disconnect</Button>
          <Button variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalWrapper>
  );
}

export default DisconnectModal;
