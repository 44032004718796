import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import React, {useContext} from 'react';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import styled from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';

const ModalBodyMessage = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  display: flex;
  justify-content: center;
`;

function ProcessCanvasChangeAccessibilityModal() {
  const {
    file,
    accessibilityModalState,
    saveLoadingModalState,
    changeAccessibility,
    getFileList,
    isPublic,
    setIsPublic
  } = useContext(ProcessCanvasContext);
  const {showModal} = useContext(CommonContext);

  const [, setIsShowChangeAccessibilityModal] = accessibilityModalState;
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onClose()
  });

  const onClose = (): void => {
    setIsShowChangeAccessibilityModal(false);
  };

  // todo : res 값이 실패할 경우 메세지 전달 필요
  const onClickConfirm = async () => {
    const successStatus = await changeAccessibility();
    if (successStatus) {
      setIsShowSaveLoadingModal(true);
      setIsPublic(!isPublic); // change isPublic state here
      setIsShowChangeAccessibilityModal(false);
    } else {
      showModal({
        title: `Response Error`,
        content: `Response is failed`
      });
    }
    getFileList();
  };

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>Change File Access</ModalHeader>
      <ModalBody align="center" size="full-width">
        <ModalBodyMessage>
          Set accessibility of this file to {file.public ? 'private' : 'public'}.
          <br />
          By saving, {file.public ? 'only you' : 'all users'} will be able to view and access this file.
        </ModalBodyMessage>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onClickConfirm}>
          Save as {file.public ? 'Private' : 'Public'} File
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default ProcessCanvasChangeAccessibilityModal;
