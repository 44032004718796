import {IBusyFile} from 'components/common/types';
import {useReducer} from 'react';

type BusyFileActions = {type: 'INIT'} | {type: 'ADD'; info: IBusyFile} | {type: 'REMOVE'; id: string};

function reduce(state: IBusyFile[], action: BusyFileActions): IBusyFile[] {
  switch (action.type) {
    case 'INIT':
      return [];
    case 'ADD':
      return [...state, action.info];
    case 'REMOVE':
      return state.filter((busy) => busy.id !== action.id);
  }
}

const defaultState: IBusyFile[] = [];

type IReturn = [IBusyFile[], (busyFile: IBusyFile) => void, (id: string) => void, () => void];

function useBusyFileReducer(): IReturn {
  const [list, dispatch] = useReducer(reduce, defaultState);

  const add = (busyFile: IBusyFile): void => {
    dispatch({type: 'ADD', info: busyFile});
  };

  const remove = (id: string): void => {
    dispatch({type: 'REMOVE', id});
  };

  const init = (): void => {
    dispatch({type: 'INIT'});
  };

  return [list, add, remove, init];
}

export default useBusyFileReducer;
