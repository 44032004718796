import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 560px;
  height: 150px;
  overflow: hidden;
`;

type IProps = PropsWithChildren & {
  width: number;
  height?: number;
};

function StepContainer({children, width, height = 150}: IProps): ReactElement {
  return <Container style={{width, height}}>{children}</Container>;
}

export default StepContainer;
