import React from 'react'
import { useGLTF } from '@react-three/drei'

export function COBoiler() {
  const { nodes, materials } = useGLTF('./3D/COBoiler/COBoiler.gltf')
  return (
    <group dispose={null} scale={0.2}>
      <mesh geometry={nodes._COBoiler_1.geometry} material={materials.Simacro_COBoiler} position={[0,10,0]} rotation={[Math.PI, 0, -Math.PI]} />
    </group>
  )
}

useGLTF.preload('./3D/COBoiler/COBoiler.gltf')
