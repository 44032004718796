import {dayList, getCelsius, getFahrenheit} from 'components/pc/widgets/weather/const';
import WeatherIcon from 'components/pc/widgets/weather/WeatherIcon';
import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRaindrops} from '@fortawesome/pro-light-svg-icons';
import {ISizeType} from 'components/common/types';

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  background-color: #dde5ff;

  &.md {
    padding: 0.6rem 0.8rem;
    border-radius: 0.6rem;
    font-size: 16px;
  }
  &.sm {
    padding: 0.4rem 0.6rem;
    border-radius: 0.4rem;
    font-size: 13px;
  }
`;

const WeatherIconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  //padding-right: 4%;
  //padding-left: 3%;
  svg {
    width: 36px;
    height: 36px;
  }
  &.md {
    svg {
      width: 30px;
      height: 30px;
    }
  }
  &.sm {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Day = styled.div`
  //width: 40%;
`;

const Rain = styled.div`
  width: 28%;
  flex-shrink: 0;
  font-size: 18px;
  white-space: nowrap;
  margin-left: auto;
  display: flex;
  align-items: flex-end;

  .unit {
    font-size: 0.8em;
  }

  svg {
    margin-right: 0.5rem;
    width: 30px;
    height: 30px;
  }

  &.md {
    font-size: 16px;

    svg {
      margin-right: 0.4rem;
      width: 20px;
      height: 20px;
    }
  }
  &.sm {
    font-size: 13px;

    svg {
      margin-right: 0.3rem;
      width: 16px;
      height: 16px;
    }
  }
`;

const TemperatureWrapper = styled.div`
  width: 24%;
  flex-shrink: 0;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //width: 30%;
  margin-left: 10px;

  &.md {
    font-size: 16px;
  }
  &.sm {
    font-size: 13px;
  }
`;
const TempMin = styled.div``;

const TempDivision = styled.div`
  opacity: 60%;
  margin: 0 5px;
`;

const TempMax = styled.div`
  opacity: 60%;
`;

type IProps = {
  i: number;
  forecastData: IWeatherForcastData;
  fontSize: string;
  isCelsius: boolean;
  darkModeChange: boolean;
  size: ISizeType;
};

function WeatherForecastDailyResult({forecastData, i, fontSize, isCelsius, darkModeChange, size}: IProps) {
  const fMin = getFahrenheit(forecastData.daily?.[i].temp.min);
  const fMax = getFahrenheit(forecastData.daily?.[i].temp.max);
  const cMin = getCelsius(forecastData.daily?.[i].temp.min);
  const cMax = getCelsius(forecastData.daily?.[i].temp.max);
  return (
    <Container className={size.width} style={!darkModeChange ? {backgroundColor: '#3f425f'} : {}}>
      <WeatherIconWrapper className={size.width}>
        {WeatherIcon(forecastData.daily?.[i].weather[0].icon)}
      </WeatherIconWrapper>
      <Day className="weather-forecast-day">{dayList[new Date(forecastData.daily?.[i]?.dt * 1000).getDay()]}</Day>
      <Rain className={size.width}>
        <FontAwesomeIcon icon={faRaindrops} />
        {Math.round(forecastData.daily?.[i].pop * 100)}
        <span className="unit">%</span>
      </Rain>
      <TemperatureWrapper className={size.width}>
        <TempMin className={fontSize}>{isCelsius ? <>{cMin}</> : <>{fMin}</>}</TempMin>
        <TempDivision className={fontSize}>/</TempDivision>
        <TempMax className={fontSize}>{isCelsius ? <>{cMax}°</> : <>{fMax}°</>}</TempMax>
      </TemperatureWrapper>
    </Container>
  );
}

export default WeatherForecastDailyResult;
