import {useViewport} from 'reactflow';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import React from 'react';
import ZoomScaleViewer from 'components/common/zoom-scale-viewer/ZoomScaleViewer';

type IProps = {
  hasMiniMap: boolean;
};

function ProcessCanvasZoomScaleViewer({hasMiniMap}: IProps) {
  const {zoomTo, zoomIn, zoomOut} = useProcessCanvasCommand();
  const viewport = useViewport();
  const checkMiniMap = hasMiniMap === true ? 'on' : 'off';
  const zoomPercentage = Math.round(((viewport.zoom / 4) * 400) / 5) * 5;

  return (
    <ZoomScaleViewer
      zoomPercentage={zoomPercentage}
      checkMiniMap={checkMiniMap}
      type="pc"
      zoomIn={zoomIn}
      zoomOut={zoomOut}
      zoomTo={zoomTo}
    />
  );
}

export default ProcessCanvasZoomScaleViewer;
