import {ChangeEvent, CSSProperties, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;

  > input[type='color'] {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: auto;
    padding: 0;
    border: none;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: none;
    }
  }
`;
const InputElement = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
`;

type IProps = {
  flattenKeys: string;
  color: string;
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
  onChange(e: ChangeEvent): void;
};

/**
 * DefaultTable 내부에서 색상을 선택
 * @param flattenKeys
 * @param color
 * @param backgroundColor
 * @param width
 * @param height
 * @param style
 * @param onChange
 * @constructor
 */
function ColorPicker({
  flattenKeys,
  width,
  height = 30,
  color,
  backgroundColor = 'transparent',
  style,
  onChange
}: IProps): ReactElement {
  return (
    <Container style={{...style, width, height, backgroundColor}}>
      <InputElement type="color" name={flattenKeys} title="color" value={color} onChange={onChange} />
    </Container>
  );
}

export default ColorPicker;
