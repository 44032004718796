import {MouseEvent, useState} from 'react';

type IReturn = [string | undefined, (e: MouseEvent<HTMLDivElement>) => void, (e: MouseEvent<HTMLDivElement>) => void];

/**
 * 좌측 Action Menu 에 mouse enter/leave 이벤트를 감지하고 해당 메뉴 아이템의 id 의 state 를 변경
 */
function useRollActionMenu(): IReturn {
  const [expandedItemId, setExpandedItemId] = useState<string | undefined>();

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>): void => {
    const {id} = e.currentTarget;
    setExpandedItemId(id);
  };

  const onMouseLeave = (): void => {
    setExpandedItemId(undefined);
  };

  return [expandedItemId, onMouseEnter, onMouseLeave];
}

export default useRollActionMenu;
