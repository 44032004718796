import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight, faMinusSquare, faSquareCheck} from '@fortawesome/pro-solid-svg-icons';
import {faSquare} from '@fortawesome/pro-regular-svg-icons';
import * as React from 'react';
import {useEffect, useState} from 'react';

const defaultColor = '#AAAAAA';
const check = <FontAwesomeIcon icon={faSquareCheck} color="#3D51FE" />;
const uncheck = <FontAwesomeIcon icon={faSquare} color={defaultColor} />;
const halfCheck = <FontAwesomeIcon icon={faMinusSquare} color="#ACB3FF" />;
const expandClose = <FontAwesomeIcon icon={faChevronRight} color={defaultColor} />;
const expandOpen = <FontAwesomeIcon icon={faChevronDown} color={defaultColor} />;
const expandAll = <FontAwesomeIcon icon={faChevronDown} color={defaultColor} />;

function useNodeMenuIcons(iconOption: 'modal' | 'normal') {
  const [icons, setIcons] = useState({});
  useEffect(() => {
    switch (iconOption) {
      case 'modal':
        setIcons({check, uncheck, halfCheck, expandClose, expandOpen, expandAll});
        break;
      case 'normal':
        setIcons({check: <></>, uncheck: <></>, halfCheck: <></>, expandClose, expandOpen, expandAll});
    }
  }, [iconOption]);

  return icons;
}

export default useNodeMenuIcons;
