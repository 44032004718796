import {useContext, useEffect, useState} from 'react';
import {DataContext} from 'api/DataProvider';

type IReturn = [string[], string, (envName: string) => void, () => void];

function usePythonEnv(): IReturn {
  const {pythonEnvList, getPythonEnvList} = useContext(DataContext);
  const [selectedEnv, setSelectedEnv] = useState<string>();

  useEffect(() => {
    if (pythonEnvList?.length === 0) {
      getPythonEnvList();
    } else {
      const [firstEnv] = pythonEnvList;
      setSelectedEnv(selectedEnv || firstEnv); // 없으면 undefined 할당됨;
    }
  }, [pythonEnvList, selectedEnv]);

  const reload = (): void => {
    getPythonEnvList();
  };

  const changeEnv = (envName: string): void => {
    // todo: 해당 env 가 없으면 모달로 알려줘야 할 것 같음
    setSelectedEnv(pythonEnvList?.includes(envName) ? envName : undefined);
  };

  return [pythonEnvList, selectedEnv, changeEnv, reload];
}

export default usePythonEnv;
