import {ReactElement, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFlag, faLightbulb, faMessageCheck, faMessageExclamation} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {INotice} from 'components/common/CommonProvider';

const Container = styled.div`
  width: 100%;
  height: 40px;
  overflow: hidden;
  font-size: 14px;
  padding: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  line-height: 1.1em;
  color: #ffc980;
  flex-shrink: 0;

  &.hide {
    height: 0;
    transition: height 0.5s;
  }
`;
const Icon = styled.div`
  width: 40px;
  height: 100%;
  display: flex;

  > svg {
    margin: auto;
  }
`;
const Text = styled.div`
  max-height: 2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

type IProps = {
  data: INotice;
  isShow: boolean;
  onFinish(id: string): void;
};

function NoticeItem({data, isShow, onFinish}: IProps): ReactElement {
  const getIcon = (type: string): IconDefinition => {
    switch (type) {
      case 'success':
        return faMessageCheck;
      case 'error':
        return faMessageExclamation;
      case 'warning':
        return faFlag;
      case 'info':
        return faLightbulb;
    }
  };

  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        setIsHide(true);

        setTimeout(() => {
          onFinish(data.id);
        }, 1000);
      }, data.delay);
    }
  }, [isShow]);

  return (
    <Container className={isHide && 'hide'}>
      <Icon>
        <FontAwesomeIcon icon={getIcon(data.type)} size="lg" />
      </Icon>
      <Text>{data?.text}</Text>
    </Container>
  );
}

export default NoticeItem;
