import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {Button} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinus, faPlus} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 180px;
  height: 40px;
  padding: 5px 10px;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  border: 2px solid #aaa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  user-select: none;

  &.pc {
    right: 0;
    bottom: 60px;
    &.on {
      bottom: 230px;
    }
  }
  &.mpfd {
    z-index: 10;
    right: 415px;
    bottom: 15px;
    &.off {
      right: 45px;
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  span {
    flex-shrink: 0;
    width: 73px;
    font-size: 14px;
  }
`;

const ScaleBtnWrap = styled.div`
  flex: 1;
`;

type IProps = {
  zoomPercentage: number;
  checkMiniMap?: string;
  type: 'pc' | 'mpfd';
  zoomIn(): void;
  zoomOut(): void;
  zoomTo(scale: number): void;
};

function ZoomScaleViewer({zoomPercentage, checkMiniMap, type, zoomIn, zoomOut, zoomTo}: IProps): ReactElement {
  return (
    <Container className={classNames('zoom-panel', type, checkMiniMap)}>
      <ContentWrap>
        <span>{zoomPercentage}%</span>
        <ScaleBtnWrap>
          <Button variant="none" className="zoom-in-btn" onClick={zoomIn}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button variant="none" className="zoom-out-btn" onClick={zoomOut}>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </ScaleBtnWrap>
        <Button variant="reset" onClick={() => zoomTo(1)}>
          Reset
        </Button>
      </ContentWrap>
    </Container>
  );
}

export default ZoomScaleViewer;
