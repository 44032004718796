import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

const positions = [

]

function AirCoolerMesh({ position }) {
  const groupRef = useRef()
  const { nodes, materials, animations } = useGLTF('./3D/AirCooler/AirCooler.gltf')
  const { actions } = useAnimations(animations, groupRef)

  React.useEffect(() => {
    Object.values(actions).forEach(action => action.play())
  }, [actions])

  return (
    <group ref={groupRef} position={position}>
      <mesh name="_AirCooler" geometry={nodes._AirCooler.geometry} material={materials.Simacro_AirCooler} position={[0, 0, 0]} />
      <mesh name="_AirCooler_Fan1" geometry={nodes._AirCooler_Fan1.geometry} material={materials.Simacro_AirCooler} position={[2.4, 1.25, 0]} />
      <mesh name="_AirCooler_Fan2" geometry={nodes._AirCooler_Fan2.geometry} material={materials.Simacro_AirCooler} position={[-2.3, 1.25, 0]} />
    </group>
  )
}

export function AirCooler() {
  return (
    <group dispose={null} scale={0.2}>
      <AirCoolerMesh position={[0, 0, 0]} />
    </group>
  )
}

useGLTF.preload('./3D/AirCooler/AirCooler.gltf')
