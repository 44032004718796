import {IShapeProps} from 'components/pc/common/shapes/type';

function Text({width, height, ...svgAttributes}: IShapeProps) {
  return (
    <path
      d="M0 0H1.71429H10.2857H13.7143H22.2857H24V1.71429V5.14286V6.85714H20.5714V5.14286V3.42857H13.7143V20.5714H16.2857H18V24H16.2857H7.71429H6V20.5714H7.71429H10.2857V12V3.42857H3.42857V5.14286V6.85714H0V5.14286V1.71429V0Z"
      fill="#e2e8f0"
    />
  );
}

export default Text;
