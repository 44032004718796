import {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  padding-top: 5px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.08);

  &:before,
  &:after {
    content: '';
    min-width: 10px;
    display: block;
    border-bottom: 1px solid #b2b2b2;
  }
  &:after {
    width: 100%;
  }
`;
const TabItem = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #b2b2b2;
  background-color: rgba(255, 255, 255, 0.2);
  color: #aaa;
  font-size: 15px;
  cursor: default;

  &.active {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0.6);
    color: #444;
  }
  &:not(:last-child) {
    border-right: none;
  }
`;

type IProps<T> = {
  height?: number;
  valueKey: string;
  labelKey: string;
  value?: T[keyof T];
  options: T[];
  className?: string;
  onSelect(type: T[keyof T]): void;
};

function Tab<T>({
  height = 40,
  valueKey = 'value',
  labelKey = 'label',
  value,
  options,
  onSelect,
  className
}: IProps<T>): ReactElement {
  const [selected, setSelected] = useState<T[keyof T]>();

  useEffect(() => {
    if (value) setSelected(value);
  }, [value]);

  const onSelectTab = (select: T[keyof T]): void => {
    setSelected(select);
    onSelect(select);
  };

  return (
    <Container style={{height}} className={className}>
      {options.map((item) => (
        <TabItem
          key={item[valueKey]}
          className={classnames(item[valueKey] === selected && 'active', 'tab-item')}
          onClick={() => onSelectTab(item[valueKey])}
        >
          {item[labelKey]}
        </TabItem>
      ))}
    </Container>
  );
}

export default Tab;
