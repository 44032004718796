import {MouseEvent, KeyboardEvent, Dispatch, SetStateAction} from 'react';
import styled, {css} from 'styled-components';
import classnames from 'classnames';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import {checkIsFixedCellRightEndCol} from 'components/pc/widgets/hmb/const';
import {faChevronUp, faPlus} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type StyledProps = {
  $row: number;
  $column: number;
  $dataLoading: boolean;
  $isHideComponent: boolean;
  $updateHappen: boolean;
  $seperatorRowIdx: number;
};

const CellWrapper = styled.div<StyledProps>`
  width: calc(100% - 8px);
  height: 100%;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;

  ${(props) =>
    props.$column === 1 &&
    css`
      width: 100px;
    `}

  ${(props) =>
    props.$row === 0 &&
    css`
      font-weight: 400;
      background: #e3e3e3;
      ${props.$column > 1 &&
      css`
        color: #544fff;
        font-weight: 600;
        text-align: center;
      `}
      ${props.$column > 2 &&
      css`
        border-left: 1px solid white;
      `}
      ${props.$dataLoading &&
      props.$column > 1 &&
      css`
        opacity: 0.4;
      `}
    `}

  ${(props) =>
    checkIsFixedCellRightEndCol(props.$row, props.$column) &&
    css`
      border-right: 2px solid #bababb;
    `}

  ${(props) =>
    props.$column > 1 &&
    props.$row > 0  &&
    css`
      //padding-right: 12px;
      text-align: right;
      //width: calc(100% - 16px);
    `}

  ${(props) =>
    props.$column < 2 &&
    css`
      text-align: left;
    `}

  ${(props) =>
    props.$column > 1 &&
    props.$row > 0 &&
    css`
      color: #544fff;
      padding-right: 12px;
      width: calc(100% - 16px);
      ${props.$updateHappen &&
      css`
        background-color: yellow; /* or any update color */
      `}
    `}

  ${(props) =>
    props.$row % 2 === 0 &&
    props.$row !== 0 &&
    css`
      background: #f4f9fd;
    `}

  ${(props) =>
    props.$column === 0 &&
    props.$row > props.$seperatorRowIdx &&
    props.$seperatorRowIdx !== -1 &&
    css`
      padding-left: 16px;
      padding-right: 4px;
      width: calc(100% - 20px);
    `}

  ${(props) =>
    props.$row === props.$seperatorRowIdx &&
    props.$seperatorRowIdx !== -1 &&
    css`
      font-weight: 400;
      background: #dddddd;
      ${props.$column === 0 &&
      css`
        ::after {
          content: '${props.$isHideComponent ? '▼' : '▲'}';
          color: #555555;
        }
      `}
    `}
`;

type IProps = {
  TD?: HTMLTableCellElement;
  col?: number;
  row?: number;
  value?: string | null;
  cellInfo?: ISpreadSheetCellInfo[][];
  seperatorRowIdx?: number;
  onContextMenuPane?(event: MouseEvent, row: number, col: number): void;
  onKeyDownCell?(event: KeyboardEvent): void;
  onClickAddStreamButton?(row: number, col: number): void;
  // onClickHideComponentRowsBtn?(): void;
  isHideComponentsState: [boolean, Dispatch<SetStateAction<boolean>>];
};

function HmbDataRenderer(props: IProps) {
  const [isHideComponentRows, setIsHideComponentRows] = props?.isHideComponentsState;
  const onClickHideComponentRowsBtn = () => {
    setIsHideComponentRows((prev) => !prev);
  };
  if (props?.col === 0 && props?.row === props?.seperatorRowIdx) {
    return (
      <CellWrapper
        $row={props?.row}
        $column={props?.col}
        $dataLoading={false}
        $isHideComponent={isHideComponentRows}
        $updateHappen={false}
        $seperatorRowIdx={props?.seperatorRowIdx}
        // className={classnames(props?.cellInfo?.[props?.row]?.[props?.col] && 'tag-connected-cell')}
        onContextMenu={(e) => props?.onContextMenuPane?.(e, props.row, props.col)}
        // title={props?.cellInfo?.[props?.row]?.[props?.col]?.tag?.join(', ') || ''}
        onClick={(e) => {
          e.stopPropagation();
          if (props?.col === 0 && props?.row === props?.seperatorRowIdx) {
            onClickHideComponentRowsBtn();
          }
        }}
        style={{display: 'flex', justifyContent: 'space-between'}}
      >
        {props?.value || ''}
        <div></div>
      </CellWrapper>
    );
  }

  return (
    <CellWrapper
      $row={props?.row}
      $column={props?.col}
      $dataLoading={false}
      $isHideComponent={false}
      $updateHappen={false}
      $seperatorRowIdx={props?.seperatorRowIdx}
      // className={classnames(props?.cellInfo?.[props?.row]?.[props?.col] && 'tag-connected-cell')}
      onContextMenu={(e) => props?.onContextMenuPane?.(e, props.row, props.col)}
      // title={props?.cellInfo?.[props?.row]?.[props?.col]?.tag?.join('-') || ''}
    >
      {props?.value || ''}
    </CellWrapper>
  );
}

export default HmbDataRenderer;

// export default memo(NormalDataRenderer, (prevProps, nextProps) => {
//   return prevProps.value === nextProps.value;
// });
