/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/gltf/VesselWithoutBoot/VesselWithoutBoot.gltf
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function VesselWithoutBoot() {
  const {nodes, materials} = useGltfCustom('/gltf/VesselWithoutBoot/VesselWithoutBoot.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._VesselWithOutBoot_1.geometry}
        material={materials.Simacro_VesselWithoutBoot}
        position={[-42.218, 11.784, -0.4]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
        scale={[0.436, 0.436, 1]}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/VesselWithoutBoot/VesselWithoutBoot.gltf');
