import React, {ChangeEvent} from 'react';
import {ITableField} from 'components/table/DefaultTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';

type IProps = {
  field: ITableField;
  keys: string;
  onChange(e: ChangeEvent, th?: boolean): void;
  th?: boolean;
  onClick?(rowKey: string, columnKey?: string, key?: string, value?: string[]): void;
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

function MetaPfdPropertyDeleteButton({field, keys, onChange, onClick, th, ...rest}: IProps) {
  if (th) {
    const rows = rest['rows'];
    if (!rows) {
      return null;
    }

    return <></>;
  } else {
    return (
      <ButtonContainer onClick={() => onClick(field?.key, 'delete_row', keys)}>
        <FontAwesomeIcon icon={faTrash} />
      </ButtonContainer>
    );
  }
}

export default MetaPfdPropertyDeleteButton;
