import styled from 'styled-components';
import {FormEvent} from 'react';
import classnames from 'classnames';
import {IShapeStyle} from 'components/pc/common/shapes/type';
import {ToolName} from 'components/pc/common/shapes/Toolbar';
import {getRgbaFromColorResult} from 'components/pc/common/shapes/parts/svg/utils';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
`;

type TextBoxProps = {
  $selected: boolean;
  $shapeStyle: IShapeStyle;
};

const TextBox = styled.div<TextBoxProps>`
  text-align: center;
  padding: 0 10px;
  white-space: nowrap;
  vertical-align: center;
  font-size: ${(props) => (props.$shapeStyle.fontSize ? props.$shapeStyle.fontSize : 1)}px;
  color: ${(props) => (props.$shapeStyle.fontColor ? getRgbaFromColorResult(props.$shapeStyle.fontColor) : 0)};
  &:focus {
    outline: 0 solid transparent;
  }
  line-height: 2;
  min-width: 25px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

type IProps = {
  selected: boolean;
  shapeStyle: IShapeStyle;
  initialText: string;
  onChangeStyle?(key: ToolName, value: string | number | number[]): void;
};

function ShapeTextBox({selected, shapeStyle, initialText, onChangeStyle}: IProps) {
  const onInput = (e: FormEvent<HTMLDivElement>) => {
    try {
      let entireTextContent = e.currentTarget.textContent?.split('')?.reverse()?.join('');
      const textArr = [];
      for (let i = 0; i < e.currentTarget?.children.length; i++) {
        textArr.push(e.currentTarget?.children[i]?.innerHTML);
        const reverseText = e.currentTarget?.children[i]?.innerHTML.split('')?.reverse()?.join('');
        entireTextContent = entireTextContent.replace(reverseText, '');
      }
      textArr.push(entireTextContent.split('')?.reverse()?.join(''));
      onChangeStyle('text', textArr);
    } catch (e) {}
  };

  return (
    <Container>
      <TextBox
        onInput={(e) => onInput(e)}
        className={classnames(selected && 'nowheel nodrag', 'test')}
        contentEditable={true}
        suppressContentEditableWarning
        $selected={selected}
        $shapeStyle={shapeStyle}
        dangerouslySetInnerHTML={{__html: initialText}}
      ></TextBox>
    </Container>
  );
}

export default ShapeTextBox;
