import {ReactElement} from 'react';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import {faPlus} from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  background-color: #fafafa;
`;

const BtnContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #373737;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #373737;
  cursor: pointer;
  padding-left: 1px;
`;

const BtnWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  right: 16px;
  gap: 10px;
`;

const IconContainer = styled.div`
  width: 16px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  color: #373737;
  //margin-right: 10px;
`;

type IProps = {
  title: string;
  isActive: boolean;
  onClickMapping?(): void;
  onClickPlus?(): void;
  onToggle(): void;
};

function TableHeader({title, isActive, onClickMapping, onClickPlus, onToggle}: IProps): ReactElement {
  return (
    <Container>
      {title}
      <BtnWrapper>
        {/*   <Button variant="secondary" onClick={onClickMapping} disabled={true}>
          Auto Mapping
        </Button>
        <BtnContainer onClick={onClickPlus}>
          <FontAwesomeIcon icon={faPlus} />
        </BtnContainer>*/}
        <IconContainer className={classnames(isActive && 'active')} onClick={onToggle}>
          <FontAwesomeIcon icon={isActive ? faChevronDown : faChevronRight} />
        </IconContainer>
      </BtnWrapper>
    </Container>
  );
}

export default TableHeader;
