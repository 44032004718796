import styled from 'styled-components';
import {useEffect, useState} from 'react';
import NewAccordion from 'components/common/arrocdion/NewAccordion';

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;

  pre {
    font-family: consolas;
    font-size: 14px;
    white-space: pre-line;
  }
`;

export type IAccordionItem = {
  title: string;
  value: string;
  isActive: boolean;
  updatedAt?: Date;
};

type IProps = {
  messages?: string;
  logData?: string;
};

function ModelRunnerResult({messages, logData}: IProps) {
  const [options, setOptions] = useState<IAccordionItem[]>([
    {title: 'Log', value: '', isActive: false},
    {title: 'Message', value: '', isActive: false}
  ]);

  useEffect(() => {
    setOptions((prevState) => {
      return prevState.map((option) => {
        if (option.title === 'Log') {
          option.value = logData;
          option.updatedAt = new Date();
        }
        return option;
      });
    });
  }, [logData]);

  useEffect(() => {
    setOptions((prev) => {
      return prev.map((option) => {
        if (option.title === 'Message') {
          option.value = messages;
          option.updatedAt = new Date();
        }
        return option;
      });
    });
  }, [messages]);

  return (
    <Container>
      <NewAccordion options={options} />
    </Container>
  );
}

export default ModelRunnerResult;
