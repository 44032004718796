import {HotColumn, HotTable} from '@handsontable/react';
import {useEffect, useState} from 'react';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {SpreadsheetRendererComponent} from 'components/spreadsheet/const';
import styled from 'styled-components';
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .handsontable {
    .wtHolder {
      &::-webkit-scrollbar {
        background: rgba(0, 0, 0, 0.08);
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
`;

type IProps = {
  spreadsheetMetaData: IHandsonTableSpreadSheetData;
  afterGetColHeader?(column: number, TH: HTMLTableCellElement, headerLevel: number): void;
};

function BasicSpreadsheet({spreadsheetMetaData, afterGetColHeader}: IProps) {
  const [spreadsheetData, setSpreadsheetData] = useState<IHandsonTableSpreadSheetData>({
    colHeaders: null,
    rowHeaders: null,
    colWidth: 100,
    data: [],
    colLengths: 0,
    rendererType: 'NormalDataRenderer'
  });

  useEffect(() => {
    setSpreadsheetData(spreadsheetMetaData);
  }, [spreadsheetMetaData]);

  const tempColumnArr = Array.from({length: spreadsheetData?.colLengths}, (_, index) => index);
  const RendererComponent = SpreadsheetRendererComponent[spreadsheetData.rendererType];

  return (
    <Wrapper>
      <HotTable
        colHeaders={spreadsheetData?.colHeaders || true}
        rowHeaders={spreadsheetData?.rowHeaders || true}
        data={spreadsheetData?.data}
        colWidths={spreadsheetData?.colWidth ?? 200}
        height="100%"
        autoRowSize={false}
        autoColumnSize={false}
        autoWrapRow={true}
        autoWrapCol={true}
        licenseKey="non-commercial-and-evaluation"
        afterGetColHeader={afterGetColHeader}
      >
        {RendererComponent &&
          tempColumnArr.map((_, idx) => (
            <HotColumn key={idx}>
              <RendererComponent hot-renderer />
            </HotColumn>
          ))}
      </HotTable>
    </Wrapper>
  );
}

export default BasicSpreadsheet;
