/**
 * Process Canvas, Meta PFD 영역이 아닌 왼쪽 메뉴등의 UI 에서 불필요한 브라우저의 zoom 동작을 막기 위해
 * Ctrl + Wheel 이벤트를 캡쳐하여 중단 시킴
 */
export function preventWheelEvent() {
  document.getElementById('root').addEventListener(
    'wheel',
    (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    },
    true
  );
}
