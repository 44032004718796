import styled from 'styled-components';
import {useContext} from 'react';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: auto;
  margin-right: 5px;
  //margin-bottom: 4px;
  width: 44px;
  height: 45px;
  border: none;
  background-color: #ee5c5c;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &.loading {
    cursor: auto;
  }
`;

const ModeShape = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 26px;
  background-color: #fff;
  transition: all 0.2s;

  &.stop {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: #fff;
  }

  &.loading {
    opacity: 1;
    display: inline-block;
    background-color: #fff;
    border: 4px solid #decaff;
    border-top-color: #203366;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export type UiModeTypes = 'record' | 'loading' | 'stop';

type IProps = {
  mode: UiModeTypes;
  onClick(): void;
};

function VoiceModeButton({mode, onClick}: IProps) {
  const {isPlaying} = useContext(CopilotContext);

  const changeMode = () => {
    onClick();
  };

  return (
    <Container disabled={isPlaying} className={mode} onClick={changeMode}>
      <ModeShape className={mode} />
    </Container>
  );
}

export default VoiceModeButton;
