import {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  overflow-y: auto;
  padding: 10px 0 0;
  color: #575660;
  font-size: 15px;
  flex: 1;
`;

type IProps = {
  children: ReactNode;
};

function PopupBody({children}: IProps): ReactElement {
  return <Container>{children}</Container>;
}

export default PopupBody;
