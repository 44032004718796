import useEventCallback from 'use-event-callback';
import React from 'react';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';

const Container = styled.div`
  background-color: white;
  z-index: ${META_PFD_zINDEX.IMAGE_BACKGROUND} !important;
  img {
    user-select: none;
  }
`;

type imageSize = {naturalWidth: number; naturalHeight: number};

type IProps = {
  image: {imageSrc?: string};
  snapShotImage?: any;
  selected?: boolean;
  layoutChanging?: boolean;
  onLoad(imageSizeInfo: imageSize): void;
};

function MetaPfdWidgetImageLayer({image, snapShotImage, selected, layoutChanging, onLoad}: IProps) {
  const onImageLoaded = useEventCallback((event) => {
    const imageElm = event.currentTarget;
    if (onLoad) onLoad({naturalWidth: imageElm.naturalWidth, naturalHeight: imageElm.naturalHeight});
  });

  const stylePosition = {
    zIndex: 1,
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  };

  if (snapShotImage || layoutChanging) {
    return (
      <>
        <Container style={stylePosition}>
          <img
            src={selected ? image?.imageSrc : snapShotImage ? snapShotImage : image?.imageSrc}
            style={{width: '100%', height: '100%'}}
            onLoad={onImageLoaded}
            alt="..."
            className="image-layer"
            draggable={false}
          />
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container style={stylePosition}>
          <img
            src={image?.imageSrc}
            // style={{width: '100%', height: '100%', objectFit: 'cover'}}
            onLoad={onImageLoaded}
            alt="..."
            className="image-layer"
            draggable={false}
          />
        </Container>
      </>
    );
  }
}

export default MetaPfdWidgetImageLayer;
