import {ITableField} from 'components/table/DefaultTable';
import ManagementTableCell from 'components/table/parts/ManagementTableCell';
import {IManagementAsideInfo, IEditGroupInfo, IEditRoleInfo, IEditUserInfo} from 'components/auth/management/type';

export const managementConst = {
  role: {
    roleManagementHeader: {
      title: 'Role Management',
      description: 'View and manage your organization role.'
    },
    roleFields: [
      {
        key: 'name',
        label: 'Role name',
        style: {header: {width: 400}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },

      {
        key: 'description',
        label: 'Description',
        style: {body: {cursor: 'pointer'}},
        component: ManagementTableCell
      }
    ] as ITableField[],
    roleAsideInfo: {
      create: {
        type: 'create',
        size: 'sm',
        header: {
          title: 'Create Role ',
          description: 'Add new roles to your organization'
        },
        createVariant: 'create',
        createLabel: 'create',
        info: null as IEditRoleInfo
      } as IManagementAsideInfo,
      edit: {
        type: 'edit',
        size: 'sm',
        header: {
          title: 'Edit Role ',
          description: 'Edit roles to redefine description of role'
        },
        createLabel: 'save',
        createVariant: 'primary',
        // deleteLabel: 'delete',
        // deleteVariant: 'danger',
        info: null as IEditRoleInfo
      } as IManagementAsideInfo
    }
  },
  group: {
    groupManagementHeader: {
      title: 'Group Management',
      description: 'View and manage your organization group.'
    },
    groupFields: [
      {
        key: 'name',
        label: 'Group name',
        style: {header: {width: 200}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'description',
        label: 'Description',
        style: {body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'roles',
        label: 'Role',
        style: {header: {width: 400}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      }
    ] as ITableField[],
    groupAsideInfo: {
      create: {
        type: 'create',
        size: 'sm',
        header: {
          title: 'Create Group ',
          description: 'Add new groups to your organization'
        },
        createVariant: 'create',
        createLabel: 'create',
        info: null as IEditGroupInfo
      } as IManagementAsideInfo,
      edit: {
        type: 'edit',
        size: 'sm',
        header: {
          title: 'Edit Group ',
          description: 'Edit groups to redefine description of group'
        },
        createLabel: 'save',
        createVariant: 'primary',
        deleteLabel: 'delete',
        deleteVariant: 'danger',
        info: null as IEditGroupInfo
      } as IManagementAsideInfo
    }
  },
  user: {
    userManagementHeader: {
      title: 'User Management',
      description: 'View and manage your organization users.'
    },
    userFields: [
      {
        key: 'firstName',
        label: 'First name',
        style: {header: {width: 150}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'lastName',
        label: 'Last name',
        style: {header: {width: 150}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'username',
        label: 'ID',
        style: {body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'is_active',
        label: 'Activated',
        style: {header: {width: 120}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'email',
        label: 'Email Address',
        style: {body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'groups',
        label: 'Group',
        style: {header: {width: 200}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      },
      {
        key: 'roles',
        label: 'Role',
        style: {header: {width: 400}, body: {cursor: 'pointer'}},
        component: ManagementTableCell
      }
    ] as ITableField[],
    userAsideInfo: {
      create: {
        type: 'create',
        size: 'sm',
        header: {
          title: 'Create User ',
          description: 'Add new users to your organization'
        },
        createVariant: 'create',
        createLabel: 'create',
        info: null as IEditUserInfo
      } as IManagementAsideInfo,
      edit: {
        type: 'edit',
        size: 'sm',
        header: {
          title: 'Edit ',
          isDynamicTitle: true,
          description: 'Change user account details'
        },
        createLabel: 'save',
        createVariant: 'primary',
        deleteLabel: 'delete',
        deleteVariant: 'danger',
        info: null as IEditUserInfo
      } as IManagementAsideInfo,
      detail: {
        type: 'detail',
        size: 'lg',
        header: {
          title: "'s detail",
          isDynamicTitle: true,
          description: 'Change user account details',
          isDynamicDescription: true
        },
        createLabel: 'save',
        createVariant: 'primary',
        deleteLabel: 'delete',
        deleteVariant: 'danger',
        info: null as IEditUserInfo
      } as IManagementAsideInfo
    }
  }
};

const app = {
  pc: {
    create: {path: 'app.pc.create', description: 'Create a ProcessCanvas file'},
    delete: {path: 'app.pc.delete', description: 'Delete a ProcessCanvas file'},
    edit: {path: 'app.pc.edit', description: 'Edit a ProcessCanvas file'},
    read: {path: 'app.pc.read', description: 'Read a ProcessCanvas file'},
    widget: {
      commodity: {
        create: {
          path: 'app.pc.widget.commodity.create',
          description: 'Create the commodity widget on the ProcessCanvas'
        },
        delete: {
          path: 'app.pc.widget.commodity.delete',
          description: 'Delete the commodity widget from the ProcessCanvas'
        },
        edit: {path: 'app.pc.widget.commodity.edit', description: 'Edit the commodity widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.commodity.read', description: 'Read the commodity widget on the ProcessCanvas'}
      },
      weather: {
        create: {path: 'app.pc.widget.weather.create', description: 'Create the weather widget on the ProcessCanvas'},
        delete: {path: 'app.pc.widget.weather.delete', description: 'Delete the weather widget from the ProcessCanvas'},
        edit: {path: 'app.pc.widget.weather.edit', description: 'Edit the weather widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.weather.read', description: 'Read the weather widget on the ProcessCanvas'}
      },
      hmb: {
        create: {path: 'app.pc.widget.hmb.create', description: 'Create the HMB widget on the ProcessCanvas'},
        delete: {path: 'app.pc.widget.hmb.delete', description: 'Delete the HMB widget from the ProcessCanvas'},
        edit: {path: 'app.pc.widget.hmb.edit', description: 'Edit the HMB widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.hmb.read', description: 'Read the HMB widget on the ProcessCanvas'}
      },
      web: {
        create: {path: 'app.pc.widget.web.create', description: 'Create the web widget on the ProcessCanvas'},
        delete: {path: 'app.pc.widget.web.delete', description: 'Delete the web widget from the ProcessCanvas'},
        edit: {path: 'app.pc.widget.web.edit', description: 'Edit the web widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.web.read', description: 'Read the web widget on the ProcessCanvas'}
      },
      youtube: {
        create: {path: 'app.pc.widget.youtube.create', description: 'Create the YouTube widget on the ProcessCanvas'},
        delete: {path: 'app.pc.widget.youtube.delete', description: 'Delete the YouTube widget from the ProcessCanvas'},
        edit: {path: 'app.pc.widget.youtube.edit', description: 'Edit the YouTube widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.youtube.read', description: 'Read the YouTube widget on the ProcessCanvas'}
      },
      threed: {
        create: {path: 'app.pc.widget.threed.create', description: 'Create the 3D widget on the ProcessCanvas'},
        delete: {path: 'app.pc.widget.threed.delete', description: 'Delete the 3D widget from the ProcessCanvas'},
        edit: {path: 'app.pc.widget.threed.edit', description: 'Edit the 3D widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.threed.read', description: 'Read the 3D widget on the ProcessCanvas'}
      },
      mpfd: {
        create: {path: 'app.pc.widget.mpfd.create', description: 'Create the MPFD widget on the ProcessCanvas'},
        delete: {path: 'app.pc.widget.mpfd.delete', description: 'Delete the MPFD widget from the ProcessCanvas'},
        edit: {path: 'app.pc.widget.mpfd.edit', description: 'Edit the MPFD widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.mpfd.read', description: 'Read the MPFD widget on the ProcessCanvas'}
      },
      stickynote: {
        create: {
          path: 'app.pc.widget.stickyNote.create',
          description: 'Create the sticky note widget on the ProcessCanvas'
        },
        delete: {
          path: 'app.pc.widget.stickyNote.delete',
          description: 'Delete the sticky note widget from the ProcessCanvas'
        },
        edit: {path: 'app.pc.widget.stickyNote.edit', description: 'Edit the sticky note widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.stickyNote.read', description: 'Read the sticky note widget on the ProcessCanvas'}
      },
      timeseries: {
        create: {
          path: 'app.pc.widget.timeseries.create',
          description: 'Create the timeseries widget on the ProcessCanvas'
        },
        delete: {
          path: 'app.pc.widget.timeseries.delete',
          description: 'Delete the timeseries widget from the ProcessCanvas'
        },
        edit: {path: 'app.pc.widget.timeseries.edit', description: 'Edit the timeseries widget on the ProcessCanvas'},
        read: {path: 'app.pc.widget.timeseries.read', description: 'Read the timeseries widget on the ProcessCanvas'}
      }
    },
    copilot: {
      create: {path: 'app.copilot.create', description: 'Create a Copilot Thread'},
      delete: {path: 'app.copilot.delete', description: 'Delete a Copilot Thread'},
      message: {path: 'app.copilot.message', description: 'Send Message in existing Copilot Thread'},
      read: {path: 'app.copilot.read', description: 'Read messages in a Copilot Thread'}
    }
  },
  mpfd: {
    create: {path: 'app.mpfd.create', description: 'Create a Meta PFD file'},
    delete: {path: 'app.mpfd.delete', description: 'Delete a Meta PFD file'},
    edit: {path: 'app.mpfd.edit', description: 'Edit a Meta PFD file'},
    detection: {path: 'app.mpfd.detection', description: 'Detect a Meta PFD file'},
    read: {path: 'app.mpfd.read', description: 'Read a Meta PFD file'}
  },
  admin: {
    management: {
      role: {path: 'app.admin.management.role', description: 'Manage Role'},
      user: {path: 'app.admin.management.user', description: 'Manage User'},
      group: {path: 'app.admin.management.group', description: 'Manage Group'}
    }
  }
};
