import React, {ReactElement, useState} from 'react';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import {faUser, faObjectGroup, faUserGroup} from '@fortawesome/pro-light-svg-icons';
import {ActionMenuBody, ActionMenuItem, ActionMenuItemLabel} from 'components/menu/action';
import {useLocation, useNavigate} from 'react-router-dom';

/**
 * MetaP&ID 의 저장 파일 목록을 표시
 * @constructor
 */
function ManagementList(): ReactElement {
  const [managementList] = useState([
    {
      id: 'user',
      name: 'User management',
      description: 'Create/Edit/Delete user',
      icon: faUser
    },
    {
      id: 'role',
      name: 'Role management',
      description: 'Create/Edit/Delete role',
      icon: faObjectGroup
    },
    {
      id: 'group',
      name: 'Group management',
      description: 'Create/Edit/Delete group',
      icon: faUserGroup
    }
  ]);
  const location = useLocation();
  const navigate = useNavigate();

  const onClick = (id: string): void => {
    navigate(`/manage/${id}`);
  };

  return (
    <>
      <ActionMenuHeader main="Manage" sub="" description="Create, Edit, Delete Users/Roles/Groups"></ActionMenuHeader>
      <ActionMenuBody>
        {managementList.map((item) => (
          <ActionMenuItem key={item.id}>
            <ActionMenuItemLabel
              isActive={location.pathname.startsWith('/manage/' + item.id)}
              icon={item?.icon}
              title={item?.name}
              userName={item?.description}
              onClick={() => onClick(item.id)}
            />
          </ActionMenuItem>
        ))}
      </ActionMenuBody>
    </>
  );
}

export default ManagementList;
