import {PropsWithChildren, ReactElement, useEffect} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  transition: transform 0.2s;

  &.selected {
    transform: translateX(-50%);
  }
`;

type IProps = PropsWithChildren & {
  total: number;
  step: number;
};

function StepScroller({children, total, step}: IProps): ReactElement {
  useEffect(() => {}, [step]);

  return (
    <Container style={{width: `${total * 100}%`, transform: `translateX(-${(100 / total) * step}%)`}}>
      {children}
    </Container>
  );
}

export default StepScroller;
