/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/Pump/Pump.gltf 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Pump() {
  const { nodes, materials } = useGLTF('./3D/Pump/Pump.gltf')
  return (
    <group dispose={null} scale={0.4}>
      <mesh geometry={nodes._Pump_1.geometry} material={materials.Simacro_Pump} position={[-3,1,0]} rotation={[0, 1.571, 0]} scale={[0.506, 0.506, 1]} />
    </group>
  )
}

useGLTF.preload('./3D/Pump/Pump.gltf')
