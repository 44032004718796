import {ForwardedRef, forwardRef, MouseEvent, PropsWithChildren, ReactElement, useContext, useEffect} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';

const Container = styled.div<{$actionMenuHeight?: number}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  background-color: #ffffff;
  height: calc(100% - ${(props) => props.$actionMenuHeight + 40 || 40}px);
  cursor: initial;
  z-index: 0;

  &.scrollable {
    overflow: auto;
  }

  &.on {
    pointer-events: none;
  }
`;

type IProps = PropsWithChildren & {
  type?: 'nowheel' | 'scrollable';
  className?: string;
  actionMenuHeight?: number;
};

const WidgetBody = forwardRef(function WidgetBody(
  {children, className, type, actionMenuHeight}: IProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const {enteredSpacebarState, canvasEnteredState} = useContext(ProcessCanvasContext);
  const [enteredSpacebar] = enteredSpacebarState;
  const [, setCanvasEntered] = canvasEnteredState;

  const enterSpacebar = enteredSpacebar === true ? 'on' : 'off';

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setCanvasEntered(true);
  };

  const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    setCanvasEntered(false);
  };

  return (
    <Container
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      ref={ref}
      className={classNames('widget-body nodrag nowheel', className, type, enterSpacebar)}
      $actionMenuHeight={actionMenuHeight}
    >
      {children}
    </Container>
  );
});

WidgetBody.displayName = 'WidgetBody';
export default WidgetBody;
