import {IPythonEditorRunConfig, PythonLogActionType} from 'components/pc/widgets/pythonEditor/types';

export const pythonEditorDefaultStateValue = {
  variableTableState: [
    {
      variableName: 'Inflow1',
      flowType: 'Inflow',
      variableValue: '',
      path: ['plant_fcc', 'FCC', 'E19FC305'],
      keys: 'key_45487742_1722315123250',
      variableDbType: 'cloud'
    },
    {
      variableName: 'Outflow1',
      flowType: 'Outflow',
      variableValue: '',
      path: [],
      keys: 'key_25084688_1722315224134'
    },
    {
      variableName: 'Outflow2',
      flowType: 'Outflow',
      variableValue: '',
      path: [],
      keys: 'key_8529194_1722315234460'
    }
  ],
  codeState:
    'import numpy as np\n' +
    'import random\n' +
    '\n' +
    '# Define two numpy arrays\n' +
    'array1 = np.array([1, 2, 3, 4, 5])\n' +
    'array2 = np.array([10, 20, 30, 40, 50])\n' +
    '\n' +
    '# Perform basic arithmetic operations\n' +
    'addition = np.add(array1, array2)\n' +
    'subtraction = np.subtract(array1, array2)\n' +
    'multiplication = np.multiply(array1, array2)\n' +
    'division = np.divide(array1, array2)\n' +
    '\n' +
    '# Display the results\n' +
    'print("Array 1:", array1)\n' +
    'print("Array 2:", array2)\n' +
    'print("\\nAddition:", addition)\n' +
    'print("Subtraction:", subtraction)\n' +
    'print("Multiplication:", multiplication)\n' +
    'print("Division:", division)\n' +
    'Outflow1 = 1234+ random.random()*100\n' +
    'Outflow2 = 4567 + random.random()*100',
  runConfigState: {
    autoRun: false,
    batchRun: false,
    intervalUnit: 1000,
    intervalValue: 1,
    environment: ''
  } as IPythonEditorRunConfig
};

export const pythonEditorLogMsg: {[key in PythonLogActionType]: string} = {
  start: 'Start Code',
  end: 'End Code',
  stop: 'Stop Code'
};
