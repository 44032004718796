import React, {ReactElement, useState, MouseEvent, useContext, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {getImage} from 'assets/images/svg-image';
import OptionRenameModal from 'components/menu/copilot/OptionRenameModal';
import OptionDelete from 'components/menu/copilot/OptionDeleteModal';
import {CopilotContext} from './CopilotProvider';
import {ICopilotThread} from './types';
import {faTrashCan, faPen} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Container = styled.div`
  width: 120px;
  background-color: #f2f2f2;
  border-radius: 5px;
  z-index: 1;
  position: fixed;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
`;

const Rename = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #575660;
  margin: 5px 5px;
  padding: 10px 10px;
  flex-direction: row;
  border-radius: 5px;

  svg {
    width: 15px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #d9d9d9;
    cursor: pointer;
  }
`;
const Delete = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #d93939;
  margin: 5px 5px;
  padding: 10px 10px;
  flex-direction: row;
  border-radius: 5px;

  svg {
    color: #d93939;
    width: 15px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #d9d9d9;
    cursor: pointer;
  }
`;

type IProps = {
  item: ICopilotThread;
  setIsOpen(bool: boolean): void;
};

function HistoryOption({item, setIsOpen}: IProps): ReactElement {
  const [isShowRename, setIsShowRename] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const {isWaiting} = useContext(CopilotContext);

  const clickRename = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsShowRename(true);
  };
  const clickRemove = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsShowDelete(true);
  };

  const onClose = () => {
    setIsShowRename(false);
    setIsOpen(false);
  };

  return (
    <>
      <Container>
        <Rename onClick={clickRename}>
          <FontAwesomeIcon icon={faPen} size="lg" />
          Rename
        </Rename>
        <Delete onClick={clickRemove}>
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
          Delete
        </Delete>
        {isShowRename && <OptionRenameModal item={item} onClose={onClose} />}
        {isShowDelete && <OptionDelete item={item} onClose={onClose} />}
      </Container>
    </>
  );
}

export default HistoryOption;
