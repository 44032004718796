/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 public/3D/Compressor/Compressor.gltf 
*/

import React, {useRef} from 'react';
import {useGLTF} from '@react-three/drei';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';

export function Compressor() {
  const {nodes, materials} = useGltfCustom('/gltf/Compressor/Compressor.gltf');
  return (
    <group dispose={null} scale={0.2}>
      <mesh
        geometry={nodes._Compressor_1.geometry}
        material={materials.Simacro_Compressor}
        position={[2.5, 3.7, -58.584]}
        rotation={[0, 1.571, 0]}
        scale={1.764}
      />
    </group>
  );
}

// useGLTF.preload('/gltf/Compressor/Compressor.gltf');
