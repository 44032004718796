import {ChangeEvent, ReactElement, useId} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {VariantTypes} from 'components/forms/types';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  //padding-top: 2px;
  input {
    appearance: none;
    transition: background 0.3s;
    width: 38px;
    height: 20px;
    border-radius: 100px;
    background-repeat: no-repeat;
    cursor: pointer;
    border-color: #b6b4c0;
    background-position: calc(100%);
    background-color: #b6b4c0;

    /*&:checked {
      border-color: #525f7f;
      background-position: calc(100%);
      background-color: #525f7f;
    }*/
    &:checked {
      background-position: calc(100%);
      &.primary {
        background-color: ${({theme}) => theme.color.primary};
        color: #ffffff;
      }
    }
    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
  .switch-circle {
    position: relative;
    //top: 1px;
    left: -39px;
    width: 16px;
    height: 16px;
    border-radius: 48%;
    background-color: #fff;
    cursor: pointer;
    transition:
      left 0.3s,
      background-color 0.3s;
    &.checked {
      left: -21px;
    }
  }

  label {
    //margin-bottom: 1px;
    white-space: nowrap;
  }
  .disabled {
    opacity: 0.4;
  }
  .read-only {
    opacity: 1;
  }
`;

type IProps = {
  name: string;
  id?: string;
  value?: string | number | null;
  checked?: boolean;
  checkedValue?: string | number | boolean;
  uncheckedValue?: string | number | boolean;
  label?: string | ReactElement;
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
  readOnly?: boolean;
  variant?: VariantTypes;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
};

function SwitchForm({
  name,
  id,
  onChange,
  value,
  checkedValue = true,
  uncheckedValue = false,
  label,
  labelPosition = 'left',
  checked,
  disabled,
  readOnly,
  variant = 'primary'
}: IProps): ReactElement {
  const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;
    const newValue = e.target.checked ? (checkedValue as string) : (uncheckedValue as string);
    onChange?.({...e, target: {...e.target, value: newValue}});
  };

  const genId = useId();

  return (
    <Container>
      {label && labelPosition === 'left' && (
        <label style={{marginRight: 6}} className={classNames({disabled})} htmlFor={id || genId}>
          {label}
        </label>
      )}
      <SwitchWrap>
        <input
          type="checkbox"
          name={name}
          id={id || genId}
          className={classNames('input', variant)}
          onChange={onChangeEvent}
          checked={typeof checked === 'boolean' ? checked : checkedValue === value}
          disabled={readOnly || disabled}
        />
        <label
          className={classNames('switch-circle', {
            checked: checked || (typeof checked === 'undefined' && value === checkedValue)
          })}
          htmlFor={id || genId}
        />
      </SwitchWrap>
      {label && labelPosition === 'right' && (
        <label style={{marginLeft: 6}} className={classNames({disabled})} htmlFor={id || genId}>
          {label}
        </label>
      )}
    </Container>
  );
}

export default SwitchForm;
