import React, {memo, ReactElement, useState} from 'react';
import {NodeProps} from 'reactflow';
import {IHmbConfig, IHmbContent, IHmbResult, IHmbStatus, IStreamAPI, IStreamInfo} from 'components/pc/widgets/hmb/type';
import WidgetContainer from 'components/pc/widgets/parts/WidgetContainer';
import {WidgetActionPanel, WidgetBody, WidgetHeader} from 'components/pc/widgets/parts';
import HmbSettingPanel from 'components/pc/widgets/hmb/HmbSettingPanel';
import HmbTable from 'components/pc/widgets/hmb/HmbTable';
import BasicSpinner from 'components/common/BasicSpinner';
import {defaultHmbConfig, defaultHmbContent, defaultHmbStatus} from 'components/pc/widgets/hmb/const';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import useApiTimer from 'components/common/useApiTimer';
import {faTable} from '@fortawesome/pro-light-svg-icons';
import {SwitchForm} from 'components/forms';
import dayjs from 'dayjs';
import styled from 'styled-components';
import classnames from 'classnames';
import useHmbWidgetHandler from 'components/pc/widgets/hmb/useHmbWidgetHandler';
import useHmbWidgetEffects from 'components/pc/widgets/hmb/useHmbWidgetEffects';
import {IWidgetNodeData} from 'components/pc/types';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const UpdateTimeDisplay = styled.div`
  font-size: 14px;
  color: #888;
  align-self: center;
  margin-right: 15px;
  flex-shrink: 0;
`;
const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #98a9d5;

  &.active {
    color: #6287e8;
  }
`;

function HmbWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const [config, setConfig] = useState<IHmbConfig>(defaultHmbConfig);
  const [content, setContent] = useState<IHmbContent>(defaultHmbContent);
  const [status, setStatus] = useState<IHmbStatus>(defaultHmbStatus);
  const [streamInfo, setStreamInfo] = useState<IStreamInfo>({streamHierarchyList: [], streamList: []});
  const [hmbResults, setHmbResults] = useState<IHmbResult>(); // {Feed : {temperature: 15.45, pressure : 155.24, ... }
  const [timerApiList, setTimerApiList] = useState<IStreamAPI[]>([]);
  const [apiResults, , addApiAsync, removeApi, changeDuration, changeStatus] = useApiTimer(15000);

  const [tableData] = useHmbWidgetEffects(
    data,
    hmbResults,
    content,
    config,
    apiResults,
    setContent,
    setConfig,
    setTimerApiList,
    setHmbResults,
    setStreamInfo,
    setStatus,
    addApiAsync,
    changeDuration,
    changeStatus
  );

  const {onChangeConfig, onChangeStream, onChangeContent, onChangeLive, closeConfig, openConfig} = useHmbWidgetHandler(
    id,
    config,
    content,
    streamInfo,
    timerApiList,
    setConfig,
    setContent,
    setHmbResults,
    setTimerApiList,
    addApiAsync,
    removeApi,
    changeDuration,
    changeStatus
  );

  return (
    <WidgetContainer {...rest} data={data} type="HmbWidget">
      <WidgetHeader
        {...rest}
        type="HmbWidget"
        id={id}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'HmbWidget', titleData: content, data})}
        suffix="- HMB Table"
        icon={faTable}
        // hasDocking
        onConfig={() => openConfig()}
      />
      <WidgetActionPanel align="right">
        <UpdateTimeDisplay>
          Last updated {status?.time !== 0 && dayjs(status?.time).format('YYYY-MM-DD HH:mm:ss')}
        </UpdateTimeDisplay>
        <SwitchForm
          name={'hmb-live-' + id}
          id={'hmb-live-switch-' + id}
          label={<SwitchLabel className={classnames(config?.autoUpdate && 'active')}>Live</SwitchLabel>}
          onChange={onChangeLive}
          checked={config?.autoUpdate}
          checkedValue={true}
          uncheckedValue={false}
        />
      </WidgetActionPanel>
      <WidgetBody actionMenuHeight={42}>
        <BasicSpinner isShow={config.isDataLoading} type="overlay" position="top-right" />
        <HmbTable
          config={config}
          content={content}
          status={status}
          streamInfo={streamInfo}
          tableData={tableData}
          hmbResults={hmbResults}
          onChangeContent={onChangeContent}
          onChangeConfig={onChangeConfig}
          onChangeStream={onChangeStream}
        />
      </WidgetBody>
      <WidgetConfigLayer title="Settings" isShow={config.isShowConfig} onClose={() => closeConfig()}>
        <HmbSettingPanel config={config} onChangeConfig={onChangeConfig} closeConfig={closeConfig} />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}

export default memo(HmbWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});

// const {convertTags2Stream} = useTransferData();
// const onDropItem = (item: IDragItem) => {
//   const ct2s = convertTags2Stream(item.dragTransferData.metaPfdTags.hierarchyInfo);
//   const validCt2s: ISelectedStream[] = [];
//   const selectedStreamNameList = content?.selectedStreamList;
//   for (let i = 0; i < ct2s.length; i++) {
//     if (selectedStreamNameList.some((item) => item.stream === ct2s[i].name)) {
//       continue;
//     }
//     validCt2s.push({stream: ct2s[i].name});
//   }
//   if (validCt2s.length === 0) {
//     const text = 'Already exist ' + ct2s.map((item) => item.name).join(' ') + ' in HMB widget';
//     addNotice({type: 'warning', text});
//     return;
//   }
//   const newStreamList = [...(content?.selectedStreamList || []), ...validCt2s].filter((item) => item?.stream);
//   onChangeContent({...content, selectedStreamList: newStreamList});
//   onChangeStream(newStreamList).then(() => {
//     const text = 'Add column ' + validCt2s.map((item) => item.stream).join(' ') + ' in HMB widget';
//     addNotice({type: 'info', text});
//   });
// };

// const [isOver, isAccept, dropRef, id2] = useDrop(['tags', 'stream'], onDropItem);

// console.log(isOver, isAccept, id2);
