import {SelectForm, TextForm} from 'components/forms';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRepeat} from '@fortawesome/sharp-light-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import GroupDivider from 'components/forms/GroupDivider';
import {faWindowRestore} from '@fortawesome/pro-light-svg-icons';
import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {IDatasheetWidgetAutoRunConfig} from 'components/pc/widgets/datasheet/types';
import classnames from 'classnames';
import classNames from 'classnames';
import Tooltip from 'components/common/Tooltip';
import {ISize} from 'components/common/types';
import {useReactFlow} from 'reactflow';

const FormWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  height: 38px;

  &.on {
    flex-direction: column;
    height: 100%;
    row-gap: 6px;
  }

  .common-select-form {
    height: 38px;
    padding: 0 10px;
  }

  .common-text-form {
    input {
      border-radius: 5px;
    }
  }

  svg {
    //margin-left: 10px;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    padding: 5px;
    color: #888;
    opacity: 0.4;
    &:not(.disabled) {
      opacity: 1;
      &:hover {
        color: #1f1f1f;
        background-color: #d5e1f3;
        cursor: pointer;
      }
    }
    &.isRepeat {
      color: #1f1f1f;
      background-color: #d5e1f3;
    }
  }
`;

const DataWrap = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
`;

const TextFormWrap = styled.div`
  display: flex;
  height: 38px;
`;

const RowIndexForm = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;

  &.on {
    height: 35px;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid #acbcc0;
  }

  input {
    margin-left: 10px;
    width: 52px;
  }
`;

const BtnWrap = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
  margin-left: auto;
  display: flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: -2px;
  border-radius: 3px;
  padding: 5px;
  color: #888;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1f1f1f;
    background-color: #d5e1f3;
  }

  &.selected {
    background-color: #d5e1f3;
    margin-right: 1px;
    svg {
      color: #1f1f1f;
    }
  }
`;

type IProps = {
  widgetSize: ISize;
  iconMode: boolean;
  selectedRowIdxState: [number, Dispatch<SetStateAction<number>>];
  autoRunConfigState: [IDatasheetWidgetAutoRunConfig, Dispatch<SetStateAction<IDatasheetWidgetAutoRunConfig>>];
  readOnlyMode: boolean;
  maxRow: number;
  isSubscriberBatchRun: boolean;
  id: string;
  next(): Promise<boolean>;
  onClickIconModeIcon(): void;
};

function DatasheetWidgetActionPanel({
  widgetSize,
  iconMode,
  selectedRowIdxState,
  autoRunConfigState,
  readOnlyMode,
  maxRow,
  isSubscriberBatchRun,
  id,
  next,
  onClickIconModeIcon
}: IProps) {
  const [autoRunConfig, setAutoRunConfig] = autoRunConfigState;
  const [selectedRowIdx, setSelectedRowIdx] = selectedRowIdxState;
  const disabled = readOnlyMode;
  const [tempRowIdx, setTempRowIdx] = useState(selectedRowIdx);
  const reactFlow = useReactFlow();

  const iconModeToggle = iconMode ? 'on' : 'off';

  useEffect(() => {
    let timerId;
    if (autoRunConfig?.intervalValue && !isSubscriberBatchRun) {
      timerId = setInterval(() => next(), autoRunConfig?.intervalUnit * autoRunConfig?.intervalValue);
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [autoRunConfig, maxRow, isSubscriberBatchRun]);

  const afterChangeNodeUpdate = (name: string, n: any) => {
    reactFlow.setNodes((nodes) =>
      nodes.map((node) => {
        if (node?.id !== id) return node;
        const {data} = node;
        const {metaData} = data || {};
        const {autoRunConfig} = metaData || {};
        return {
          ...node,
          data: {
            ...data,
            metaData: {
              ...(metaData || {}),
              autoRunConfig: {
                ...autoRunConfig,
                [name]: n
              }
            }
          }
        };
      })
    );
  };

  const onChange = (e: ChangeEvent) => {
    const {name, value} = e.target as HTMLInputElement;
    switch (name) {
      case 'intervalValue': {
        let n = Number(value);
        if (isNaN(n)) {
          n = null;
        }
        setAutoRunConfig((prev) => ({...prev, [name]: n}));
        afterChangeNodeUpdate(name, n);
        break;
      }
      case 'intervalUnit': {
        const n = Number(value);
        if (!isNaN(n)) {
          setAutoRunConfig((prev) => ({...prev, [name]: n}));
        }
        afterChangeNodeUpdate(name, n);
        break;
      }
      case 'selectedRowIdx': {
        let n = Number(value);
        if (isNaN(n)) {
          n = null;
        }
        afterChangeNodeUpdate(name, n);
        setSelectedRowIdx(n);
      }
    }
  };

  const onClickAutoRunButton = () => {
    setAutoRunConfig((prev) => ({
      ...prev,
      isRepeat: !prev?.isRepeat
    }));
  };

  return (
    <>
      <FormWrap className={classNames(iconModeToggle)}>
        <DataWrap className={classNames(iconModeToggle)}>
          <TextFormWrap className="text-form-wrap">
            <TextForm
              name="intervalValue"
              value={autoRunConfig.intervalValue}
              onChange={(e) => onChange(e)}
              disabled={disabled}
              width={40}
              height={35}
            />
          </TextFormWrap>
          <SelectForm
            name="intervalUnit"
            options={TimeUnitOptions}
            value={autoRunConfig.intervalUnit}
            onChange={(e) => onChange(e)}
            disabled={disabled}
            width={120}
            height={35}
          />
          <FontAwesomeIcon
            className={classnames({disabled}, autoRunConfig?.isRepeat && 'isRepeat')}
            icon={faArrowsRepeat as IconProp}
            size="lg"
            onClick={onClickAutoRunButton}
          />
        </DataWrap>
        {!iconMode && <GroupDivider />}
        <RowIndexForm className={classNames(iconModeToggle)}>
          <div className="text-form-title">{widgetSize?.width < 610 ? 'row' : 'Selected row index :'}</div>
          <TextForm
            // type="number"
            name="selectedRowIdx"
            value={selectedRowIdx}
            onChange={onChange}
            height={35}
            disabled={disabled || isSubscriberBatchRun}
          />

          <Tooltip content={!iconMode ? 'Icon Mode On' : 'Icon Mode Off'} place="left">
            <BtnWrap
              className={classnames(iconMode && 'selected', iconModeToggle)}
              onClick={() => onClickIconModeIcon()}
            >
              <FontAwesomeIcon className={classnames({disabled})} icon={faWindowRestore} size="lg" onClick={() => {}} />
            </BtnWrap>
          </Tooltip>
        </RowIndexForm>
      </FormWrap>
    </>
  );
}

export default DatasheetWidgetActionPanel;
