import React, {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {ColorResult, SketchPicker} from 'react-color';
import {compactColors} from 'components/pc/common/shapes/const';
import {ITableField} from 'components/table/DefaultTable';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
`;

type IProps = {
  field: ITableField;
  keys: string;
  onChangeColorPicker(rowKey: string, key: any, val: ColorResult): void;
};

function ReactColorPicker({onChangeColorPicker, field, keys, ...rest}: IProps): ReactElement {
  const [changeColor, setChangeColor] = useState(false);

  const onChangeColor = useCallback(
    (color: ColorResult) => {
      onChangeColorPicker(keys, field?.key, color);
    },
    [onChangeColorPicker]
  );
  const color = rest[field?.key] as ColorResult;

  const [eventReady, setEventReady] = useState<boolean>(false);
  const eventRef = useRef(null);
  eventRef.current = eventReady;

  useEffect(() => {
    if (changeColor) {
      setEventReady(true);
      const wrapper = document.querySelector('.modal-wrapper');
      const wrapper2 = document.querySelector('.colorPicker');
      wrapper.addEventListener(
        'click',
        (e) => {
          setChangeColor(false);
          setTimeout(() => setEventReady(false), 100);
        },
        {once: true}
      );
      wrapper2.addEventListener('click', (e) => {
        e.stopPropagation();
      });
    }
  }, [changeColor]);

  return (
    <>
      <Container
        onClick={(e) => {
          eventRef.current && e.stopPropagation();
          if (eventRef.current) return;
          setChangeColor((prev) => !prev);
        }}
      >
        <div style={{backgroundColor: color?.hex, border: '1px solid #f8f8f8'}}></div>
        {changeColor && (
          <div
            style={{position: 'absolute'}}
            onMouseUp={(e) => console.log(e)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SketchPicker
              className="colorPicker"
              presetColors={compactColors}
              color={color?.hsl}
              onChange={onChangeColor}
            />
          </div>
        )}
      </Container>
    </>
  );
}

export default ReactColorPicker;
