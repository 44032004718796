import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import {ReactNode} from 'react';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;

  padding: 20px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  width: 300px;
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

type IProps = {
  title: string;
  children: ReactNode;
  onClose(): void;
  onConfirm?(): void;
};
function WidgetModal({title, children, onClose, onConfirm}: IProps) {
  useModalKeyBoardEvent({
    onConfirm: () => onConfirmModal(),
    onCancel: () => onCloseModal()
  });

  const onCloseModal = () => {
    onClose();
  };

  const onConfirmModal = () => {
    onConfirm?.();
  };

  return (
    <Container>
      <Wrapper>
        <ModalHeader onClose={onCloseModal}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {onConfirm && (
            <Button className="modal-btn" variant="primary" size="sm" onClick={onConfirmModal}>
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Wrapper>
    </Container>
  );
}

export default WidgetModal;
