import {ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import ComponentTable, {IComponentTableField} from 'components/table/ComponentTable';
import {IModelRunnerVariable, IoInfoData} from 'components/pc/widgets/modelRunner/types';
import TableHeader from 'components/pc/widgets/modelRunner/node-list-table-parts/TableHeader';
import classnames from 'classnames';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-weight: 500;
`;

const TableBody = styled.div`
  height: 0;
  overflow: hidden;
  transition: none;
  border-bottom: 1px solid #a6a6a6;
  &.active {
    height: 100%;
    border-bottom: 1px solid #a6a6a6;
    transition: max-height 0.2s;
  }
`;

type IProps = {
  height?: number;
  title: string;
  fields: IComponentTableField[];
  rows: IModelRunnerVariable[];
  // rows: IoInfoData[];
  openState?: [boolean, Dispatch<SetStateAction<boolean>>];
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onClick(rowKey: string, columnKey?: string, key?: string, path?: string[]): void;
};

function ModelRunnerImportTable({height, title, fields, rows, openState, onChange, onClick}: IProps): ReactElement {
  const [isActive, setIsActive] = useState(true);
  const [isOpen, setIsOpen] = openState;

  useEffect(() => {
    if (isOpen) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isOpen]);

  const onToggle = () => {
    setIsActive(!isActive);
    setIsOpen(!isOpen);
  };

  return (
    <Container style={{height: height}}>
      <TableHeader title={title} isActive={isActive} onToggle={onToggle} onClickPlus={() => onClick('Import', '')} />
      <TableBody className={classnames(isActive && 'active', 'thin-scrollbar md')}>
        <ComponentTable
          fields={fields}
          rows={rows}
          rowKey="keys"
          onChange={onChange}
          onClick={onClick}
          onClickOnlyInComponent={true}
        />
      </TableBody>
    </Container>
  );
}

export default ModelRunnerImportTable;
