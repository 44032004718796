import {type DragEvent, ReactElement, useContext, useState} from 'react';
import styled from 'styled-components';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsUpDownLeftRight, faCheck} from '@fortawesome/pro-light-svg-icons';
import {IFile} from 'api/data-types';
import {AuthContext} from 'components/auth/AuthProvider';
import Badge from 'components/forms/Badge';
import classNames from 'classnames';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  position: relative;

  &:hover {
    .draggable-hand {
      color: #fff;
    }
  }
  .draggable-hand {
    color: transparent;
    transition: color 0.3s;
    &:not(.disabled):hover {
      cursor: grab;
    }
  }

  &.disabled {
    cursor: default;
    &:hover {
      .draggable-hand {
        color: transparent;
      }
    }
  }
`;
const Thumbnail = styled.div`
  width: 2.875rem;
  height: 2.875rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.08);
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const ActiveIcon = styled.div`
  position: absolute;
  background-color: rgba(34, 187, 151, 0.9);
  width: 100%;
  height: 100%;
  display: flex;

  > svg {
    margin: auto;
  }
`;
const Label = styled.div`
  width: 100%;
`;

const MainLabel = styled.div`
  font-size: calc(1 * 1rem);
  line-height: 1.5;
  font-weight: 500;
  color: #ffffff;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: height 1s;

  &.expanded {
    display: block;
  }

  span {
    float: right;
  }
  .owner {
    margin-left: 5px;
  }
  svg.draggable-hand {
    float: right;
  }
`;
const SubLabel = styled.div`
  font-size: calc(0.875 * 1rem);
  line-height: 1.5;
  font-weight: 300;
  color: #a2a2a2;
`;

type IProps = {
  icon?: IconDefinition;
  title: string;
  userName: string;
  disabled?: boolean;
  isActive?: boolean;
  draggable?: boolean;
  expanded?: boolean;
  item?: IFile;
  onClick(): void;
  onDragStart?(e: DragEvent<HTMLDivElement>): void;
};

function ActionMenuItemLabel({
  icon,
  title,
  disabled,
  isActive,
  draggable,
  expanded,
  userName,
  onClick,
  onDragStart,
  item
}: IProps): ReactElement {
  const {userProfile} = useContext(AuthContext);

  const onDragStartItem = (e: DragEvent<HTMLDivElement>) => {
    onDragStart?.(e);
  };

  const isOwner = item?.owner === userProfile?.username;
  const isFileType = item?.fileType === 'process_canvas' || item?.fileType === 'meta_pfd';

  return (
    <Container onClick={onClick} draggable={draggable} className={classNames({disabled})} onDragStart={onDragStartItem}>
      <Thumbnail className={isActive && 'active'}>
        {icon && <FontAwesomeIcon icon={icon} size="xl" />}
        {isActive && (
          <ActiveIcon>
            <FontAwesomeIcon icon={faCheck} size="lg" />
          </ActiveIcon>
        )}
      </Thumbnail>
      <Label>
        <MainLabel className={classNames({expanded: expanded})}>
          {isOwner && isFileType && (
            <Badge className="owner" type="owner">
              Owner
            </Badge>
          )}
          {item?.public === true && <Badge type="public">Public</Badge>}
          {draggable && (
            <FontAwesomeIcon
              icon={faArrowsUpDownLeftRight}
              className={classNames('draggable-hand', {disabled})}
              size="lg"
            />
          )}
          {title}
        </MainLabel>
        <SubLabel>{userName}</SubLabel>
      </Label>
    </Container>
  );
}

export default ActionMenuItemLabel;
