import {useGLTF} from '@react-three/drei';
import {GLTFResult} from 'components/pc/widgets/three/types';
import {getApiHost} from 'api/function';

function useGltfCustom(path: string) {
  const API_HOST = getApiHost();

  useGLTF.preload(API_HOST + path);
  const gltf = useGLTF(API_HOST + path) as unknown;
  return gltf as GLTFResult;
}

export default useGltfCustom;
